'use strict';
import humps from 'humps';
import axios from 'axios';
import store from 'store';
import { Buffer } from 'buffer';
import { History } from './history';
import auth from '../stores/AuthStore';
import config from '../config';

export class Http {
  constructor() {
    // Custom axios instance defaults config
    // skip validate http status for manually
    this.httpReq = axios.create({
      validateStatus: status => {
        return true;
      },
    });
    this.StatusOK = 200;
  }

  isValidStatus(status) {
    return status >= 200 && status < 300;
  }

  getConfigWithAuthorization(config = {}) {
    if (!config.headers) config.headers = {};

    const token = store.get('TOKEN');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }

  async checkStatusCode(httpStatus) {
    if (httpStatus === 401) {
      const responseToken = await auth.setAccessTokenWithRefresh();
      if (!responseToken) {
        auth.signOut();
      } else {
        return responseToken;
      }
    } else if (httpStatus === 403) {
      History.replace(`${config.publicUrl}/403`);
    }
  }

  transformResponse(response) {
    return {
      status: response.status,
      statusText: response.statusText,
      data: response.data,
    };
  }

  async get(url, options = {}) {
    let response = await this.httpReq.get(
      url,
      { ...options, ...this.getConfigWithAuthorization() }
    );
    const responseToken = await this.checkStatusCode(response.status);
    if (responseToken) {
      return this.get(url, options);
    } else {
      return this.transformResponse(response);
    }
  }

  async post(url, data = {}, dataWithoutDecamel = {}) {
    const payload = Array.isArray(data)
      ? humps.decamelizeKeys(data)
      : { ...humps.decamelizeKeys(data), ...dataWithoutDecamel };
  
    let response = await this.httpReq.post(
      url,
      payload,
      this.getConfigWithAuthorization()
    );
    const responseToken = await this.checkStatusCode(response.status);
    if (responseToken) {
      return this.post(url, data, dataWithoutDecamel);
    } else {
      return this.transformResponse(response);
    }
  }

  async patch(url, data = {}, dataWithoutDecamel = {}) {
    const payload = Array.isArray(data)
      ? humps.decamelizeKeys(data)
      : { ...humps.decamelizeKeys(data), ...dataWithoutDecamel };
  
    let response = await this.httpReq.patch(
      url,
      payload,
      this.getConfigWithAuthorization()
    );
    
    const responseToken = await this.checkStatusCode(response.status);
    if (responseToken) {
      return this.patch(url, data, dataWithoutDecamel);
    } else {
      return this.transformResponse(response);
    }
  }

  async postMultipartFormData(url, fromData, config) {
    config = this.getConfigWithAuthorization(config);
    let response = await this.httpReq.post(url, fromData, config);
    const responseToken = await this.checkStatusCode(response.status);
    if (responseToken) {
      return this.postMultipartFormData(url, fromData, config);
    } else {
      return this.transformResponse(response);
    }
  }

  async put(url, data = {}, dataWithoutDecamel = {}) {
    let response = await this.httpReq.put(
      url,
      { ...humps.decamelizeKeys(data), ...dataWithoutDecamel },
      this.getConfigWithAuthorization()
    );
    const responseToken = await this.checkStatusCode(response.status);
    if (responseToken) {
      return this.put(url, data, dataWithoutDecamel);
    } else {
      return this.transformResponse(response);
    }
  }

  async delete(url, data = {}) {
    let deleteConfig = this.getConfigWithAuthorization();
    deleteConfig.data = humps.decamelizeKeys(data);
    let response = await this.httpReq.delete(
      url,
      deleteConfig
    );
    const responseToken = await this.checkStatusCode(response.status);
    if (responseToken) {
      return this.delete(url, data);
    } else {
      return this.transformResponse(response);
    }
  }


  getBasicAuth(username, password) {
      if (username && password) {
        return (
          'Basic ' + Buffer.from(username + ':' + password).toString('base64')
        );
      }
      return '';
  }

  async auth(url, username, password) {
    let response = await this.httpReq.post(
      url,
      {},
      {
        headers: {
          Authorization: this.getBasicAuth(username, password),
        },
      }
    );
    return this.transformResponse(response);
  }

  async getAccessToken(data = {}) {
    const refreshResponse = await this.httpReq.post(
      `${config.api.sims}/v1/authServer/getAccessToken`,
      data,
      {
        headers: { "content-type": "application/json" },
      }
    );
    return refreshResponse;
  }

  parseQueryString = (params) => {
    var queryString = [];
    for (var key in params) {
      if (params.hasOwnProperty(key) && params[key] !== undefined && params[key] !== null && params[key] !== '') {
        queryString.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
      }
    }
    queryString = queryString.join('&');
    return queryString;
  };
}

export const http = new Http();
export default http;

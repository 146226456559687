import { ArrowDropDown } from '@mui/icons-material';
import { Box, Button, Menu } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

const SelectDropDown = ({ disabled, title, onChange, children, sx }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [prevTitle, setPrevTitle] = useState(title);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuWidth, setMenuWidth] = useState(null);

  const handleClick = (event) => {
    setIsOpen(!isOpen);
    setAnchorEl(event.currentTarget);
    setMenuWidth(event.currentTarget.offsetWidth);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {

    if (title !== prevTitle) {
      setPrevTitle(title);
      if(onChange){
        onChange();
      }
    }

  }, [title, prevTitle]);

  return (
    <Box display={'flex'} justifyContent={'center'} alignContent={'center'}>
      <Button
        id="basic-button"
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        size="large"
        variant="contained"
        disabled={disabled}
        sx={{
          ...sx,
          '& .MuiButton-endIcon': {
            margin: 0,
            padding: 0,
          },
        }}
        endIcon={<ArrowDropDown/>}
      >
        {title}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        slotProps={{
          paper: {
            style: {
              width: menuWidth,
            }
          }
        }}
      >
        {children}
      </Menu>
    </Box>
  );
};

export default SelectDropDown;

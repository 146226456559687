import moment from 'moment';
import momentTz from 'moment-timezone';
import swal from 'sweetalert2';
import * as _ from 'lodash';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
// import Swal from 'sweetalert2';
// import queryString from 'query-string';
import { TypeOfJobs, ProductList, AvailableTime } from '../../utils/data';
import { required } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import FormGroup from '../../components/form/FormGroup';
import EnButton from '../../components/form/EnButton';
import EnDropDown from '../../components/form/EnDropDown';
import Select from 'react-select';
// import common from '../../utils/common';
import datetime from '../../utils/datetime';
import { toListItemData, initListItemHeaderOption, onUploadItemClick, getListItem, listItemHeaderOpts, getDropZone, doUploadFiles, onUploadComplete, onError } from '../calendar/step/stepCommon';
import ScrollButton from '../../components/common/ScrollButton';
import CallingButton from '../../components/common/CallingButton';
import AddressForm from '../../components/address/AddressForm';
import { toast } from 'react-toastify';
// import config from '../../config';
// import aa from '../../components'
// import Container from '../../layouts/ContainerContractor';

export class ContractorCreateJob extends Component {
  constructor(props) {
    super(props);
    let contractorId;
    if (this.props.contractorId) {
      contractorId = this.props.contractorId;
    }
    this.state = {
      files: 'files',
      startDate: (this.props && this.props.start) ? moment(this.props.start, 'YYYYMMDD') : '',
      endDate: (this.props && this.props.end) ? moment(this.props.end, 'YYYYMMDD') : '',
      contractorId: contractorId || '',
      jobType: this.props && this.props.type ? this.props.type : undefined,
    };
  }

  async componentWillMount() {
    const isUpdate = this.props.updateMode;
    if (isUpdate) {
      const jobStore = this.props.job.toJS().info;
      this.props.job.saveMultiInfo({
        startDate: jobStore.startDate,
        endDate: jobStore.endDate,
      });
      this.props.job.setInfo('jobType', jobStore.jobType);
    } else {
      this.props.job.clearInfo();
      const { startDate, endDate } = this.state;
      this.props.job.saveMultiInfo({
        startDate,
        endDate,
      });
      this.props.job.setInfo('jobType', this.state.jobType);

      // this.props.job.setInfo('firstName', 'kookoo');
      // this.props.job.setInfo('lastName', 'kookoo');
      // this.props.job.setInfo('phone', '00000');
    }
  }

  componentWillUnmount() {
    this.props.job.clearInfo();
  }

  saveInfo(key, e) {
    switch (key) {
      case 'available':
        this.props.job.setInfo(key, e.value);
        this.onCriteriaChange();
        break;
      case 'typeOfWork':
      case 'jobType':
      case 'startDate':
      case 'endDate':
      case 'sku':
        if (e.target) {
          this.props.job.setInfo(key, e.target.value);
        } else {
          this.props.job.setInfo(key, e);
        }
        this.onCriteriaChange();
        break;
      default:
        if (e.target) {
          this.props.job.setInfo(key, e.target.value);
        } else {
          this.props.job.setInfo(key, e);
        }
        break;
    }
  }
  saveAddress(e) {
    if (e.target) {
      this.saveInfo('address', e);
    }
  }

  checkDayBlock(date) {
    if (this.props.checkDayBlock) {
      return this.props.checkDayBlock(date);
    } else {
      const { unavailableDate } = this.props;
      if (Array.isArray(unavailableDate) && unavailableDate.length) {
        const dateBlock = unavailableDate.find(u => datetime.isEqual(date, u));
        return !!dateBlock;
      } else {
        return false;
      }
    }
  }

  onCriteriaChange(eventName) {
    if (this.props.onCriteriaChange) {
      const jobInfo = this.props.job.toJS().info;
      this.props.onCriteriaChange({
        eventName,
        workTypeId: jobInfo.typeOfWork[0],
        productId: jobInfo.typeOfWork.indexOf(1) >= 0 ? ProductList[0].id : '',
        jobType: jobInfo.jobType,
        available: jobInfo.available,
        startDate: jobInfo.startDate,
        endDate: jobInfo.endDate,
        contractorId: jobInfo.contractorId,
        districtCode: jobInfo.addressInfo.districtCode,
        skuCode: jobInfo.sku,
      });
    }
  }

  onUploadError(err) {
    toast.error(err);
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  async onSubmitValue() {
    try {
      const response = await this.props.job.createContractorJob(this.state.contractorId);
      if (response && response.status === 'success') {
        toast.success('บันทึกข้อมูลคิวช่างเรียบร้อย');
        setTimeout(() => {
          this.props.onCancel();
        }, 2000);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async validateJobPerDay() {
    const jobInfo = this.props.job.toJS().info;
    const contractor = this.props.auth.getContractor();
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const { available } = jobInfo;
    let isUnavailable = false;
    const jobOption = {
      contractorId: this.state.contractorId,
      startDate: moment(startDate).add(-1, 'month').startOf('days'),
      endDate: moment(endDate).add(1, 'month').endOf('days'),
      jobType: this.state.jobType,
      contractorInfo: contractor,
    };
    const dateRange = datetime.GetBetweenDates(startDate, endDate, { format: 'YYYYMMDD' });

    const jobs = await this.props.contractor.getJob(jobOption);
    let jobDateRange = [];
    dateRange.map((checkDate) => {
      jobs.map((job) => {
        const dateRange = datetime.GetBetweenDates(job.start_date, job.end_date, { format: 'YYYYMMDD' });
        dateRange.map((date) => {
          if (checkDate === datetime.formatWithOffset(date, 'YYYYMMDD', 7)) {
            jobDateRange = _.concat(jobDateRange, {
              start_date: momentTz(date).utcOffset(7).toISOString(),
              end_date: momentTz(date).utcOffset(7).toISOString(),
              available: +job.available,
              type_of_job: job.type_of_job,
              contractor_id: job.contractor_id,
              start_date_text: datetime.formatWithOffset(date, 'YYYYMMDD', 7),
              end_date_text: datetime.formatWithOffset(date, 'YYYYMMDD', 7),
              start_end_text: datetime.formatWithOffset(date, 'YYYYMMDD', 7).concat(datetime.formatWithOffset(date, 'YYYYMMDD', 7)),
            });
          }
        });
        return jobDateRange;
      });
    });
    let jobGroupDate = _.orderBy(jobDateRange, 'format');
    jobGroupDate = _.groupBy(jobGroupDate, 'start_end_text');

    jobGroupDate = Object.values(jobGroupDate);

    const unavailableDate = await Promise.all(dateRange.map(async (date) => {
      const options = {
        contractorId: this.state.contractorId,
        startDate: moment(date).startOf('days'),
        endDate: moment(date).endOf('days'),
        jobType: this.state.jobType,
        contractorInfo: contractor,
      };
      const workCalendar = await this.props.contractor.getContractorWorkCalendar(options);
      const filterJob = jobGroupDate.filter((job) => {
        return job[0].start_date_text === date;
      });
      const flatJob = filterJob.flat();

      let jobMorning = 0;
      let jobAfternoon = 0;
      if (flatJob && flatJob.length) {
        flatJob.map(job => {
          jobMorning += job.available === 0 || job.available === 1 ? 1 : 0;
          jobAfternoon += job.available === 0 || job.available === 2 ? 1 : 0;
        });
        if ((available === 1 && jobMorning >= workCalendar.jobPerDay.morning) ||
          (available === 2 && jobAfternoon >= workCalendar.jobPerDay.afternoon) ||
          (available === 0 && (jobMorning >= workCalendar.jobPerDay.morning || jobAfternoon >= workCalendar.jobPerDay.afternoon))) {
          jobMorning += jobInfo.available === 0 || jobInfo.available === 1 ? 1 : 0;
          jobAfternoon += jobInfo.available === 0 || jobInfo.available === 2 ? 1 : 0;
          isUnavailable = isUnavailable || true;

          let surveyPerDay = workCalendar.surveyPerDay;
          let installationPerDay = workCalendar.installationPerDay;
          if (options.jobType === 'S') {
            surveyPerDay = { morning: jobMorning, afternoon: jobAfternoon };
          } else {
            installationPerDay = { morning: jobMorning, afternoon: jobAfternoon };
          }
          return {
            contractorId: options.contractorId,
            startDate: options.startDate,
            endDate: options.endDate,
            surveyPerDay: surveyPerDay,
            installationPerDay: installationPerDay,
          };
        }
      }
    }));
    if (isUnavailable) {
      let confirmDialogOptions = {
        title: 'ยืนยันการทำรายการ',
        text: 'จำนวนงานต่อวันของท่านเต็มแล้ว ท่านต้องการทำรายการต่อหรือไม่',
        // ระบบจะเพิ่มจำนวนงานที่รับได้เพิ่มให้อัตโนมัติ
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !swal.isLoading(),
      };

      let result = await swal.fire(confirmDialogOptions);
      if (result.value) {
        try {
          await this.UpdateWorkPerDay(unavailableDate);
          return true;
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  async UpdateWorkPerDay(unavailableDate) {
    try {
      await Promise.all(unavailableDate.map(async (data) => {
        if (data) {
          const options = {
            contractorId: data.contractorId,
            startDate: moment(data.startDate).startOf('day').toISOString(),
            endDate: moment(data.endDate).startOf('day').toISOString(),
            surveyPerDay: data.surveyPerDay,
            installationPerDay: data.installationPerDay,
          };
          await this.props.contractor.updateContractorWorkCalendarByDate(options);
        }
      }));
      return true;
    } catch (error) {
      throw error;
    }
  }

  async onSubmit() {
    try {
      const isAvailable = await this.validateJobPerDay();
      if (!isAvailable) {
        return;
      }
      if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
        doUploadFiles(this.dropzone, this.onSubmitValue.bind(this));
      } else {
        onUploadComplete([], this.state, this.props.contractorMode, this.onSubmitValue.bind(this));
      }
    } catch (error) {
      onError(error.message);
    }
  }

  changeAddressSet = (value) => {
    this.props.job.saveAddressData(value);
    this.onCriteriaChange('FILTER_CODE');
  }

  validateSubmitButton() {
    let reqInfo = this.props.job.toJS().info;
    let isValid =
      reqInfo.firstName &&
      // reqInfo.lastName &&
      reqInfo.phone;
    return isValid;
  }

  getAvailableTime() {
    const jobInfo = this.props.job.toJS().info;
    if (jobInfo && !datetime.isEqual(jobInfo.startDate, jobInfo.endDate)) {
      return AvailableTime.filter(a => a.id === 0);
    } else {
      return AvailableTime;
    }
  }

  conChageTime(e) {
    this.saveInfo('available', e);
    this.props.job.setInfo('timeLabel', e.label);
    this.setState({ editTime: false });
  }

  render() {
    const { contractorMode } = this.props;
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;

    let disabled = false;
    let showDate = datetime.ConvertISO2DisplayFullMonth(jobInfo.startDate || this.state.startDate);
    if (!datetime.isEqual(jobInfo.startDate || this.state.startDate, jobInfo.endDate || this.state.endDate)) {
      showDate += ` - ${datetime.ConvertISO2DisplayFullMonth(jobInfo.endDate || this.state.endDate)}`;
    }

    let typeOfJobs = [];
    typeOfJobs = TypeOfJobs.map((item, idx) => {
      return <option key={idx} value={item.id}>{item.label}</option>;
    });
    typeOfJobs = typeOfJobs.slice(1);

    let itemHeader = initListItemHeaderOption('ไฟล์เพิ่มเติม ( อัพโหลดได้สูงสุด 20 ไฟล์ต่อครั้ง )');
    let itemData = toListItemData(jobInfo.files);
    if (!contractorMode) {
      itemHeader.push(listItemHeaderOpts);
    }
    let listItem = getListItem(itemHeader, itemData, onUploadItemClick.bind(this, 'files', jobInfo));
    let dropzone = getDropZone(e => { this.dropzone = e; }, this.props.jobId, (e) => {
      onUploadComplete(e, this.state, this.props.contractorMode, this.props.onSubmit);
    });

    const timeSlot = this.getAvailableTime();
    let availableTime;
    if (timeSlot) {
      availableTime = timeSlot.map((item, index) => {
        return { value: item.id, label: `${item.title}` };
      });
    }

    return (
      <div>
        {/* <Container> */}
        {/* <FormValidation> */}
        {/* <div className="card"> */}
        {/* <div className="card-content"> */}
        <h4>วันที่ {showDate}</h4>
        <div className="row">
          <EnTextFormGroup id="customer-firstname" disabled={disabled} containerClassName="col-md-6" label="ชื่อลูกค้า" value={jobInfo.firstName} onChange={this.saveInfo.bind(this, 'firstName')} validations={[required]} />
          <EnTextFormGroup id="customer-lastname" disabled={disabled} containerClassName="col-md-6" label="นามสกุล" value={jobInfo.lastName} onChange={this.saveInfo.bind(this, 'lastName')} />
        </div>
        <div className="row">
          <EnTextFormGroup
            id="customer-phone"
            disabled={disabled}
            containerClassName="col-md-6"
            label="เบอร์ติดต่อ"
            value={jobInfo.phone}
            onChange={this.saveInfo.bind(this, 'phone')}
            validations={[required]}>
            <CallingButton phone={jobInfo.phone} isButtonGroup />
          </EnTextFormGroup>
          <EnTextFormGroup id="customer-email" disabled={disabled} containerClassName="col-md-6" label="อีเมล" value={jobInfo.email} onChange={this.saveInfo.bind(this, 'email')} />
        </div>
        <div className="row">
          <AddressForm
            disabled={false}
            addressData={jobInfo.addressInfo}
            onChange={(addrData) => { this.props.job.clearAddressData(); }}
            onAddressSelected={(addressObject) => this.changeAddressSet(addressObject)}
          />
        </div>
        <div className="row">
          <EnTextFormGroup id="address" disabled={disabled} containerClassName="col-md-12" label="ที่อยู่ (บ้านเลขที่ ซอย ถนน)" value={jobInfo.address} onChange={this.saveAddress.bind(this)} />
          {/* <EnTextFormGroup id="landmark" disabled={disabled} containerClassName="col-md-6" label="จุดสังเกต" value={jobInfo.addressInfo.landmark} onChange={this.saveLandmark.bind(this)} /> */}
        </div>

        <div className="row">
          <FormGroup containerClassName="col-md-6" label="ลักษณะงาน">
            <EnDropDown id="job-type" value={jobInfo.jobType} onChange={this.saveInfo.bind(this, 'jobType')}>
              {typeOfJobs}
            </EnDropDown>
          </FormGroup>
          <EnTextFormGroup id="other-work" containerClassName="col-md-6" label="ประเภทงาน" value={jobInfo.otherWork} onChange={this.saveInfo.bind(this, 'otherWork')} />
        </div>
        <div className="row">
          <FormGroup containerClassName="col-md-12" label="ช่วงเวลา">
            <Select
              multi={false}
              searchable={false}
              clearable={false}
              value={jobInfo.available}
              options={availableTime}
              onChange={this.conChageTime.bind(this)}
            />
          </FormGroup>
        </div>
        <div className="row">
          <EnTextFormGroup id="description" disabled={disabled} containerClassName="col-md-12" label="รายละเอียดเพิ่มเติม" value={jobInfo.description} onChange={this.saveInfo.bind(this, 'description')} />
        </div>

        <div className="row">
          <div className="col-md-12">
            {listItem}
            {dropzone}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12">
            <EnButton id="submit-button" className="btn-info pull-right" disabled={!this.validateSubmitButton()} onClick={this.onSubmit.bind(this)}>
              <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
            </EnButton>
            <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
              <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
            </EnButton>
          </div>
        </div>
        <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
        {/* </div> */}
        {/* </div> */}
        {/* </FormValidation > */}
        {/* </Container> */}
      </div>
    );
  }
}

export default inject('auth', 'job', 'typeofwork', 'sku', 'calendar', 'contact', 'contractor')(observer(ContractorCreateJob));

import React from 'react';
import _ from 'lodash';
import swal from 'sweetalert2';
import Autosuggest from 'react-autosuggest';
import imageExtensions from 'image-extensions';
import { observer, inject } from 'mobx-react';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnButton from '../../../components/form/EnButton';
import { getListOnsitePicture, onDeleteOnsite, contractorQuotationFileCounts, getDropZoneOnsitePicture, getContractorQuotationDropzone, onUploadOnsiteComplete, getDisplayStatusButton, showBasicInfo, toListItemData, initListItemHeaderOption, onUploadItemClick, saveInfo, getListItem, listItemHeaderOpts, getDropZone, doUploadFiles, onUploadComplete, uploadAndNextStep, onError } from './stepCommon';
import FormGroup from '../../../components/form/FormGroup';
import Modal from 'react-responsive-modal';
import { isAllow } from '../../../utils/permission';
import TextArea from '../../../componentsV2/input/textArea';

const theme = {
  container: {
    position: 'relative',
  },
  input: {
    width: '100%',
    height: 40,
    padding: '6px 12px',
    fontFamily: 'DB Helvethaica X',
    fontWeight: 300,
    fontSize: 18,
    border: '1px solid #ccc',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: 'none',
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: 'none',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 51,
    width: '100%',
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    fontFamily: 'DB Helvethaica X',
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
};
class EnStepSurveyStart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNextStep: false,
      currentStep: 'survey_started',
      nextStep: 'survey_finished',
      remark: 'surveyRemark',
      files: 'surveyFiles',
      chargeFile: 'additionalCharge',
      quotationFiles: [],
      contractorQuotationBeforeUpload: { file: 0, image: 0 },
      contractorQuotationImagesModal: false,
      remarkTitle: 'หมายเหตุการสำรวจ',
      openModal: false,
      suggestions: [],
      productInfo: [],
      addedOnsiteFiles: [],
      value: '',
      showChargeDropzone: false,
      openModalOnsite: false,
    };
  }

  async componentWillMount() {
    const { info } = this.props.job.toJS();
    await this.props.sku.getSkuByType(info.typeOfWork.toString());
    const { items } = this.props.sku.toJS();
    this.setState({ productInfo: items });
  }

  componentDidMount() {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const jobId = jobInfo.id;
    this.getMapFilesCustomer(jobId);
  }

  async validateProductInput() {
    const jobInfo = this.props.job.toJS().info;
    const data = this.props.job.toJS().data;
    let flag = true;
    if ((jobInfo.surveyCheck.submit === false) && !(jobInfo.surveyCheck.remark)) {
      swal.fire({
        title: 'กรุณาระบุสาเหตุที่ไม่สามารถติดตั้งได้',
        icon: 'error',
        // html: '<small style="color: red">กรุณาปุ่มกดบันทึกด้านล่างเพื่อบันทึกข้อมูล</small>',
      });
      flag = false;
    } else if (data.product.point !== '' || data.product.area !== '' || data.product.name !== '') {
      const { value } = await swal.fire({
        title: 'ยังไม่ได้กด"เพิ่ม"รายการสินค้า',
        text: 'คุณต้องการทำต่อหรือไม่?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
      });
      if (value) {
        swal.close();
      }
      flag = value;
    }
    return flag;
  }

  onOpenShowOnsite() {
    try {
      this.setState({ openModalOnsite: true });
    } catch (error) {
      // onError(error.message);
    }
  }

  async onSubmit() {
    try {
      const flag = await this.validateProductInput();
      if (flag) {
        if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
          doUploadFiles(this.dropzone);
        } else {
          onUploadComplete([], this.state, this.props.contractorMode, this.props.onSubmit);
        }
      }
    } catch (error) {
      onError(error.message);
    }
  }

  async onClickNextState(nestStatus) {
    const jobInfo = this.props.job.toJS().info;
    if (!jobInfo.surveyFiles || jobInfo.surveyFiles.length === 0) {
      await swal.fire({
        title: 'กรุณาอัพโหลดรูปถ่ายหรือไฟล์แนบในการสำรวจอย่างน้อย 1 รูป',
        icon: 'error',
      });
      return;
    }
    await this.onUploadAndNext(nestStatus);
  }

  async onUploadAndNext(nestStatus) {
    try {
      const flag = await this.validateProductInput();
      if (flag) {
        this.setState({
          isNextStep: true,
        });
        uploadAndNextStep(this.dropzone, nestStatus, this.props.contractorMode);
      }
    } catch (error) {
      onError(error.message);
    }
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onOpenAll(itemData) {
    try {
      this.setState({ openModal: true });
    } catch (error) {
      onError(error.message);
    }
  }

  onOpenContractorQuotationImagesModal() {
    try {
      this.setState({ contractorQuotationImagesModal: true });
    } catch (error) {
      onError(error.message);
    }
  }
  onCloseModal = () => {
    this.setState({ openModal: false, openModalOnsite: false, contractorQuotationImagesModal: false });
  };

  onSubmitSurveyClick(e) {
    this.props.job.setSurveyCheck('submit', e.target.value === 'true' || false);
  }

  saveSurveyCheckInfo = (key, e) => {
    if (e.target) {
      this.props.job.setSurveyCheck(key, e.target.value);
    } else {
      this.props.job.setSurveyCheck(key, e);
    }
  }

  saveProductData = (key, e) => {
    if (e.target) {
      this.props.job.setProductData(key, e.target.value);
    } else {
      this.props.job.setProductData(key, e);
    }
  }

  addProduct() {
    const { status, message } = this.props.job.addProduct();
    if (status) {
      swal.fire({
        title: 'เพิ่มรายการสินค้าเรียบร้อย',
        icon: 'success',
        html: '<small style="color: red">กรุณาปุ่มกดบันทึกด้านล่างเพื่อบันทึกข้อมูล</small>',
      });
      this.setState({ value: '' });
      this.props.job.clearData();
    } else {
      swal.fire('กรุณากรอกข้อมูลให้ครบถ้วน', message, 'error');
    }
  }

  onProductDelete(index, e) {
    e.preventDefault();
    this.props.job.deleteProduct(index);
  }

  getTableProductRows(items) {
    const productRows = items.map((item, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td className="text-center">{item.point}</td>
          <td>{item.name}</td>
          <td className="text-center">{item.area}</td>
          <td className="text-center">
            <button
              className="btn btn-xs btn-danger"
              title="ลบ"
              data-tip="ลบ"
              onClick={this.onProductDelete.bind(this, index)}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover has-pagination">
              <thead>
                <tr>
                  <th width="10%" className="text-center">
                    ลำดับที่
                  </th>
                  <th width="20%" className="text-center">
                    จุดติดตั้ง
                  </th>
                  <th width="40%" className="text-center">
                    รายการ
                  </th>
                  <th width="20%" className="text-center">
                    พื้นที่ (ตร.ม.)
                  </th>
                  <th width="10%" className="text-center">
                    ลบ
                  </th>
                </tr>
              </thead>
              <tbody>{productRows}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  getSurveysContent(jobInfo, typeofworkInfo, data, suggestions, theme, inputProps) {
    const productRows = (jobInfo.surveyCheck.products && jobInfo.surveyCheck.products.length) ? this.getTableProductRows(jobInfo.surveyCheck.products) : '';
    if (typeofworkInfo && typeofworkInfo.surveys && typeofworkInfo.surveys.length) {
      let listCheck = (typeofworkInfo.surveys.map((info, index) => {
        return (
          <p key={index}>{index + 1}. {info}</p>
        );
      }));
      return (
        <div className="job-info">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>รายการที่ต้องสำรวจ</label>
                <ul>
                  {listCheck}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{ paddingTop: 11 }}>
              <label style={{ paddingRight: 40 }}>ข้อมูลที่สำรวจถูกต้องตามรายการ ทีมช่างสามารถเข้าติดตั้งได้ใช่หรือไม่</label>
              <div className="radio" style={{ display: 'inline-block' }}>
                <label className="radio-inline"><input type="radio" value checked={jobInfo.surveyCheck.submit === true} onChange={this.onSubmitSurveyClick.bind(this)} />ใช่</label>
              </div>
              <div className="radio" style={{ display: 'inline-block', paddingLeft: 35 }} >
                <label className="radio-inline"><input type="radio" value={false} checked={jobInfo.surveyCheck.submit === false} onChange={this.onSubmitSurveyClick.bind(this)} />ไม่ใช่</label>
              </div>
            </div>
          </div>
          {jobInfo.surveyCheck.submit !== undefined &&
            <div className="row">
              <div className="col-md-12">
                <TextArea
                  label={jobInfo.surveyCheck.submit === true ? 'รายละเอียดเพิ่มเติม' : 'ระบุสาเหตุที่ไม่สามารถติดตั้งได้'}
                  rows={5}
                  value={jobInfo.surveyCheck.remark}
                  onChange={this.saveSurveyCheckInfo.bind(this, 'remark')}
                />
              </div>
            </div>
          }
          {jobInfo.surveyCheck.submit === true &&
            (<div>
              <div className="row" style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div className="col-md-6">
                  <EnTextFormGroup defaultValue={'1'} containerClassName="label-inline" labelClassName="label-inline" className="input-number" type={'number'} min={1} value={jobInfo.surveyCheck.installationDays} label={'ใช้เวลาสำหรับงานติดตั้ง'} onChange={this.saveSurveyCheckInfo.bind(this, 'installationDays')} />
                  <label>วัน</label>
                </div>
                <div className="col-md-6" />
              </div>
              <div className="row">
                <div className="col-md-12" style={{ paddingBottom: 10 }}>
                  <div className="survey">
                    <div className="header-survey">
                      <span>รายการสินค้าสำหรับติดตั้ง</span>
                    </div>
                    <div className="survey-form">
                      <div className="row">
                        <EnTextFormGroup
                          containerClassName="col-md-2"
                          type="text"
                          label="จุดติดตั้ง"
                          value={data.product.point}
                          onChange={this.saveProductData.bind(this, 'point')}
                        />
                        <FormGroup containerClassName="col-md-6" label="รายการ">
                          <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={this.setSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            inputProps={inputProps}
                            theme={theme}
                          />
                        </FormGroup>                    {/* <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="รายการ"
                      value={data.product.name}
                      onChange={this.saveProductData.bind(this, 'name')}
                    /> */}
                        <EnTextFormGroup
                          containerClassName="col-md-2"
                          type="number"
                          label="พื้นที่ (ตร.ม.)"
                          value={data.product.area}
                          onChange={this.saveProductData.bind(this, 'area')}
                        />
                        <div className="form-group col-md-2">
                          <EnButton
                            style={{ marginTop: '27px' }}
                            className="btn btn-success"
                            onClick={this.addProduct.bind(this)}
                            disabled={data.product.point === '' || data.product.name === '' || data.product.area === ''}
                          >
                            <i
                              className="fa fa-plus btn-icon"
                              aria-hidden="true"
                            />
                            เพิ่ม
                          </EnButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {productRows}
            </div>)
          }
          <hr />
        </div>
      );
    }
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : (this.state.productInfo || []).filter(c =>
      ((c.id.toLowerCase().slice(0, inputLength) === inputValue) || (c.name.th.toLowerCase().slice(0, inputLength) === inputValue))
    );
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  renderSuggestion = (suggestion) => (
    <div>
      {`${suggestion.name.th}`}
    </div>
  );

  setSuggestionValue = (suggestion) => {
    this.props.job.setProductData('name', suggestion.name);
    this.props.job.setProductData('code', suggestion.code);
    return suggestion.name.th;
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onChange = async (event, { newValue }) => {
    // console.log('newValue ==> ', newValue);
    this.props.job.setProductData('name', newValue);
    // this.props.job.setProductData('code', newValue);
    this.setState({
      value: newValue,
    });
  };

  onToggleDropZone = () => {
    this.setState({ showChargeDropzone: !this.state.showChargeDropzone });
  }


  onAddedOnsiteFile() {
    if (this.dropzone) {
      this.setState({
        addedOnsiteFiles: this.dropzone.getFiles(),
      });
    }
  }

  async getMapFilesCustomer(jobId) {
    const jobStore = this.props.job.toJS();
    await jobStore.getOrderByJobId(jobId);
  }

  async allCompletedContractorQuotation(uploadedData) {
    await this.props.job.saveContractorQuotationFiles(uploadedData);
    this.setState({
      contractorQuotationBeforeUpload: {
        file: 0,
        image: 0,
      },
    });
  }

  acceptContractorQuotation(file, done) {
    try {
      const {
        imagesUploaded,
        otherFilesUploaded
      } = contractorQuotationFileCounts();

      if (file.type.includes('image/')) {
        this.setState(prevState => {
          return {
            contractorQuotationBeforeUpload: {
              file: prevState.contractorQuotationBeforeUpload.file,
              image: prevState.contractorQuotationBeforeUpload.image + 1,
            },
          }
        });
      } else {
        this.setState(prevState => {
          return {
            contractorQuotationBeforeUpload: {
              file: prevState.contractorQuotationBeforeUpload.file + 1,
              image: prevState.contractorQuotationBeforeUpload.image,
            },
          }
        });
      }
      const isValidImages = ((imagesUploaded || 0) + this.state.contractorQuotationBeforeUpload.image) <= 10;
      const isValidFiles = ((otherFilesUploaded || 0) + this.state.contractorQuotationBeforeUpload.file) <= 10;
      if (!(isValidImages && isValidFiles)) {
        done('ไฟล์รูปสูงสุด 10 ไฟล์​ และไฟล์อื่น ๆ สูงสุด 10 ไฟล์');
      } else {
        done();
      }
    } catch (e) {
      console.log(JSON.stringify(e))
    }
  }

  onRemoveContractorQuotation(file) {
    if (file.type.includes('image/')) {
      this.setState(prevState => {
        return {
          contractorQuotationBeforeUpload: {
            file: prevState.contractorQuotationBeforeUpload.file,
            image: prevState.contractorQuotationBeforeUpload.image - 1,
          },
        }
      });
    } else {
      this.setState(prevState => {
        return {
          contractorQuotationBeforeUpload: {
            file: prevState.contractorQuotationBeforeUpload.file - 1,
            image: prevState.contractorQuotationBeforeUpload.image,
          },
        }
      });
    }
  }

  render() {
    let { contractorMode, isReadOnly } = this.props;
    const { value, suggestions, showChargeDropzone } = this.state;
    const productData = this.props.job.toJS().data;

    const inputProps = {
      placeholder: 'กรอกชื่อสินค้า',
      value: productData.name || value,
      onChange: this.onChange.bind(this),
    };
    // console.log('inputProps ==> ', inputProps);
    if (isReadOnly) {
      contractorMode = false;
    }
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const orderId = jobStore.orderId;
    // const userInfo = this.props.auth.getUserInfo();
    // const channel = userInfo.channel ? userInfo.channel.channelName : '';
    const channel = this.props.auth.getChannelName();
    let isSCGHome = (channel === 'SCGHOME Online');
    const disable = jobInfo.status === 'deleted'

    const data = jobStore.data;
    const store = this.props.typeofwork.toJS().items;
    const typeofworkInfo = store.find(t => jobInfo.typeOfWork[0] === t.value);

    let itemHeader = initListItemHeaderOption('รูปถ่ายหรือไฟล์แนบในการสำรวจ ( อัพโหลดได้สูงสุด 20 ไฟล์ต่อครั้ง )');
    let itemData = toListItemData(jobInfo[this.state.files]);
    let listItem = getListItem(itemHeader, itemData, onUploadItemClick.bind(this, this.state.files, jobInfo));
    itemHeader.push(listItemHeaderOpts);

    let itemChargeHeader = initListItemHeaderOption('');
    let itemChargeData = toListItemData(jobInfo[this.state.chargeFile]);
    let listChargeItem = getListItem(itemChargeHeader, itemChargeData, onUploadItemClick.bind(this, this.state.chargeFile, jobInfo));
    itemChargeHeader.push(listItemHeaderOpts);

    let contractorQuotationHeader = initListItemHeaderOption('ใบเสนอราคาจากทีมช่าง (อัพโหลดได้สูงสุดไม่เกิน 20 ไฟล์)');
    let contractorQuotationData = toListItemData(jobInfo['contractorQuotationFiles']);
    let contractorQuotationList = getListItem(contractorQuotationHeader, contractorQuotationData, onUploadItemClick.bind(this, 'contractorQuotationFiles', jobInfo));
    contractorQuotationHeader.push(listItemHeaderOpts);

    let onsitePictureHeader = initListItemHeaderOption(
      'รูปภาพและวีดีโอหน้างานจากลูกค้า ( อัพโหลดได้สูงสุดไม่เกิน 10 ไฟล์ )'
    );
    const contractorQuotationImages = jobInfo.contractorQuotationFiles || [];
    const imagesOnsite = jobStore.onsitePicture ? jobStore.onsitePicture[0].file : [];
    const videosOnsite = jobStore.onsitePicture ? jobStore.onsitePicture[1].file : [];
    const allFiles = [...imagesOnsite, ...videosOnsite];
    let itemFileNameCustomer = toListItemData(allFiles);
    let listOnsitePicture = getListOnsitePicture(
      onsitePictureHeader,
      itemFileNameCustomer,
      onDeleteOnsite.bind(this, orderId)
    );
    onsitePictureHeader.push(listItemHeaderOpts);
    // dropzone for upload file
    let dropzone = getDropZone(e => { this.dropzone = e; },
      this.props.jobId, (e) => {
        onUploadComplete(e, this.state, this.props.contractorMode, this.props.onSubmit);
      }, undefined, undefined, disable);
    let dropzoneOnsite = getDropZoneOnsitePicture(
      e => { this.dropzone = e; },
      (e) => {
        onUploadOnsiteComplete(e, orderId);
      },
      this.onAddedOnsiteFile.bind(this),
      'กรุณาเลือกรูปภาพและวีดีโอหน้างานจากลูกค้า',
      disable,
    );
    let contractorQuotationDropzone = getContractorQuotationDropzone(
      e => { this.dropzone = e; },
      this.allCompletedContractorQuotation.bind(this),
      this.onRemoveContractorQuotation.bind(this),
      this.acceptContractorQuotation.bind(this),
      jobStore.info.id,
      disable,
    );

    const notiAdditionChargeToAdmin = true;
    const dropzoneChargeMessage = 'กรุณาเลือกใบเสร็จค่าใช้จ่าย';
    let dropzoneCharge = getDropZone(e => { this.dropzone = e; }, this.props.jobId, (e) => {
      onUploadComplete(e, this.state, this.props.contractorMode, this.props.onSubmit, this.state.chargeFile, notiAdditionChargeToAdmin);
    }, undefined, dropzoneChargeMessage, false, false);

    const { openModal, openModalOnsite, contractorQuotationImagesModal } = this.state;
    const imgs = itemData.map((item, idx) => {
      return (
        <div className="row col-md-12">
          <div className="row" style={{ paddingLeft: '16px' }}>
            <h4>{(idx + 1)}</h4>
          </div>
          <div className="row col-md-12" style={{ textAlign: 'center' }}>
            <img src={item.url} alt="" style={{ textAlign: 'center' }} width={400} height={300} mode="fill" />
          </div>
        </div>
      );
    });
    const showImgsOnsite = imagesOnsite.map((item, idx) => {
      return (
        <div className="row col-md-12">
          <div className="row" style={{ paddingLeft: '16px' }}>
            <h4>{(idx + 1)}</h4>
          </div>
          <div className="row col-md-12" style={{ textAlign: 'center' }}>
            <img src={item.url} alt="" style={{ textAlign: 'center' }} width={400} height={300} mode="fill" />
          </div>
        </div>
      );
    });

    const contractorQuotationImagesJsx = contractorQuotationImages.map((item, idx) => {
      if (item.type !== 'image' && !imageExtensions.some(ext => item.name.includes(ext))) {
        return (<></>);
      }
      return (
        <div className="row col-md-12">
          <div className="row" style={{ paddingLeft: '16px' }}>
            <h4>{(idx + 1)}</h4>
          </div>
          <div className="row col-md-12" style={{ textAlign: 'center' }}>
            <img src={item.url} alt="" style={{ textAlign: 'center' }} width={400} height={300} mode="fill" />
          </div>
        </div>
      );
    });

    return (
      <div>
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={openModal} onClose={this.onCloseModal} center>
            {imgs}
          </Modal>
        </div>
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={openModalOnsite} onClose={this.onCloseModal} center>
            {showImgsOnsite}
          </Modal>
        </div>
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={contractorQuotationImagesModal} onClose={this.onCloseModal} center>
            {contractorQuotationImagesJsx}
          </Modal>
        </div>
        {showBasicInfo(jobInfo, contractorMode)}
        {this.getSurveysContent(jobInfo, typeofworkInfo, data, suggestions, theme, inputProps)}
        {isAllow("UPLOAD_AND_VIEW_ADDITIONAL_FILES") && (
          <div className="row">
            <div className='col-md-12'>
              {listOnsitePicture}
              {
                // contractorMode && (
                dropzoneOnsite
                // )
              }
            </div>
          </div>
        )}
        {isAllow("UPLOAD_AND_VIEW_ADDITIONAL_FILES") && (
          <div className="row">
            <div className='col-md-12'>
              <EnButton className="btn-info pull-left" onClick={this.onOpenShowOnsite.bind(this)}>
                <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
              </EnButton>
            </div>
          </div>
        )}
        <hr />
        {isAllow("UPLOAD_AND_VIEW_ADDITIONAL_FILES") && (
          <div className="row">
            <div className="col-md-12">
              {listItem}

              {/* {
              contractorMode && (
                dropzone
              )
            } */}
              {dropzone}

              <EnButton className="btn-info pull-left" onClick={this.onOpenAll.bind(this, itemData)}>
                <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
              </EnButton>
            </div>
          </div>
        )}
        <hr />

        {(
          <div className="row">
            <div className="col-md-12">
              {contractorQuotationList}
              {contractorQuotationDropzone}

              <EnButton className="btn-info pull-left" onClick={this.onOpenContractorQuotationImagesModal.bind(this)}>
                <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
              </EnButton>
            </div>
          </div>
        )}
        <hr />
        &nbsp;
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div><b>ค่าใช้จ่ายเพิ่มเติม และเอกสารอื่น ๆ</b></div>
                <EnButton className={'btn-success'} onClick={this.onToggleDropZone.bind(this)}>
                  เพิ่มค่าใช่จ่ายอื่นๆ
                </EnButton>
              </div>
            </div>
            {listChargeItem}
            {showChargeDropzone && dropzoneCharge}
          </div>
        </div>
        &nbsp;
        {
          // contractorMode && (
          <div className="row">
            <div className="col-md-12">
              <TextArea
                label='หมายเหตุการสำรวจ'
                rows={5}
                value={jobInfo[this.state.remark]}
                onChange={saveInfo.bind(this, this.state.remark)}
              />
            </div>
          </div>
          // )
        }
        {/* {
          !contractorMode && (
            <div className="row">
              <div className="col-md-12">
                <FormGroup label="หมายเหตุหลังสำรวจ">
                  {jobInfo[this.state.remark] ? <div>{jobInfo[this.state.remark]}</div> : getDisplayEmpty('-')}
                </FormGroup>
              </div>
            </div>
          )
        } */}
        <div className="row">
          <div className="col-md-12">
            {
              (!isSCGHome) && (jobInfo.status === this.state.currentStep &&
                getDisplayStatusButton(jobInfo.jobType, jobInfo.status, contractorMode, async () => { await this.onClickNextState(this.state.nextStep); }, '', true, undefined, true))
            }
            {
              <EnButton id="submit-button" className="btn-info pull-right" onClick={this.onSubmit.bind(this)}
                disabled={disable}
              >
                <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
              </EnButton>
            }
            <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
              <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
            </EnButton>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('auth', 'job', 'typeofwork', 'sku')(observer(EnStepSurveyStart));

import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, TextField, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { get, uniqBy } from 'lodash';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(99, 173, 253, 0.2)',
    },
  },
  typography: {
    fontFamily: '"DB Helvethaica X", sans-serif',
    fontSize: '8px',
  },
});

const checkBoxStyle = {
  marginRight: "8px",
  "&.Mui-checked": {
    backgroundColor: "transparent",
    color: "#1976d2",
  },
}

const liStyle ={
  '&:hover': {
    backgroundColor: '#f4f6f6',
  },
}

const tagStyle = {
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(248, 217, 215, 0.4)',
  color: 'rgba(250, 63, 49, 0.8)',
  border: '1px solid rgba(250, 63, 49, 0.8)',
  borderRadius: '3px',
  padding: '1px',
  margin: '2px',
  fontSize: '18px',
}

const removeTagStyle = {
  background: 'transparent',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
  display: 'flex',
}

const selectAllOption = {label: 'เลือกทั้งหมด', value: 'Select All'};

const SelectWithCheckbox = ({
  options = [],
  placeholder = "",
  onTagChange,
  value,
}) => {

  // const [opt, setOpt] = useState([]);
  const [selectOpt, setSelectedOpt] = useState([]);
  const [isFistLoad, setIsFistLoad] = useState(true);

  const opt = useMemo(() => [selectAllOption, ...uniqBy(selectOpt.concat(options), 'value')], [options, selectOpt]);

  const handleSelectAll = (searchKeyWord) => {
    if (searchKeyWord && searchKeyWord.length > 0) {
      const searchValue = searchOptions(opt, searchKeyWord);
      setSelectedOpt((prev) => [...uniqBy(prev.concat(searchValue), 'value')]);
    } else if (selectOpt.length === opt.length-1) {
      setSelectedOpt([]);
    } else {
      const filteredValue = opt.filter((item) => item.value !== 'Select All');
      setSelectedOpt(filteredValue);
    }
  };

  const handleOptClick = (isSelected, selectOption) => {
    if (isSelected) {
      setSelectedOpt(selectOpt.filter((item) => item.value !== selectOption.value));
    } else {
      setSelectedOpt([...selectOpt, selectOption]);
    }
  }

  const handleRemoveTag = (event, option) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedOpt(selectOpt.filter((item) => item.value !== option.value));
  }

  const searchOptions = (options, searchKeyWord) => {
    return options.filter((item) => item.label.toLowerCase().includes(searchKeyWord.toLowerCase()));
  }

  useEffect(() => {

    // default select first option in create page
    if(!value && selectOpt.length === 0 && opt.length > 2 && isFistLoad) {
      setSelectedOpt([opt[1]]);
      setIsFistLoad(false);
    }
  
    if (onTagChange) {
      const selectedConcat = selectOpt.map((item) => item.value).join(',');
      onTagChange(selectedConcat);
    };
  }, [selectOpt, opt]);

  useEffect(() => {
    if (Array.isArray(value) && value.length > 0) {
      const defaultSelected = value.map((item) => ({label: item, value: item}));
      setSelectedOpt(defaultSelected);
    }
  }, [value]);

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        multiple
        id="checkboxes-tags"
        options={opt}
        disableCloseOnSelect
        value={selectOpt}
        clearOnEscape={false}
        disableClearable
        getOptionLabel={(option) => option.label}
        filterOptions={(options = [], { inputValue }) => {

          if (!inputValue) return options;

          const foundOption = searchOptions(options, inputValue);

          if (foundOption.length === 0) return foundOption;

          return [selectAllOption, ...foundOption];

        }}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <div
              {...getTagProps}
              style={tagStyle}
              key={option.value}
            >
              <button
                onClick={(e) => handleRemoveTag(e,option)}
                style={removeTagStyle}
              >
                <CloseIcon sx={{ fontSize: '12px',color: 'rgba(250, 63, 49, 0.8)' }}/>
              </button>
              <span style={{ marginRight: '6px' }}>{option.label}</span>
            </div>
          ))
        }}
        renderOption={(props, option, { inputValue }) => {
          const { key, ...optionProps } = props;
          const isSelectAll = get(optionProps, 'data-option-index', null) === 0;
          const isSelected = selectOpt.findIndex((item) => item.value === option.value) !== -1;

          let checked;
          if(inputValue !== '' && isSelectAll){
            const search = searchOptions(opt, inputValue).length
            const selectSearch = searchOptions(selectOpt, inputValue).length
            checked = search === selectSearch;
          } else {
            checked = isSelectAll ? selectOpt.length === opt.length-1 : isSelected;
          }

          return (
            <li
              key={key}
              sx={liStyle}
              {...optionProps}
              onClick={(e) => {
                e.preventDefault();
                if(isSelectAll){
                  handleSelectAll(inputValue);
                } else {
                  handleOptClick(isSelected, option);
                }
              }}
            >
              <Checkbox
                checked={checked}
                sx={checkBoxStyle}
              />
              {option.label} 
            </li>
          );

        }}
        renderInput={(params) => {
            return (
              <TextField
                placeholder={ selectOpt.length === 0 ? placeholder : '' }
                {...params}
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1,
                    padding: '0px 3px',
                  },
                }}
              />
            );
          }
        }
        sx={{
          width: '100%',
          '&.Mui-focused':{
            boxShadow: '0 0 0 0.25rem rgba(99, 173, 253, 0.1)',
          } ,
        }}
      />
    </ThemeProvider>
  );
}

export default SelectWithCheckbox
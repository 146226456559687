import React from 'react';
import swal from 'sweetalert2';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import { FormValidation, required } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnButton from '../../../components/form/EnButton';
import EnTextArea from '../../../components/form/EnTextArea';
import { config } from '../../../config';
// import { EnTagSelectValidation } from '../../../components/form/EnTagSelect';

export class GuaranteePage extends React.Component {
  constructor(props) {
    super(props);
    const guaranteeCode = this.props.match.params.id;
    // console.log('guaranteeCode ==> ', guaranteeCode);
    this.state = {
      guaranteeCode,
      isEdit: guaranteeCode && guaranteeCode.length,
      guaranteeInfo: [],
      notGuaranteeInfo: [],
    };
  }

  async componentWillMount() {
    let guaranteeInfo = [];
    let notGuaranteeInfo = [];
    if (this.state.isEdit) {
      const getGuaranteeData = await this.props.guarantee.getGuaranteeByCode(this.state.guaranteeCode);
      guaranteeInfo = (getGuaranteeData.guaranteeInfo || []).map((g, index) => {
        return {
          key: index,
          value: g.value,
        };
      });
      notGuaranteeInfo = (getGuaranteeData.notGuaranteeInfo || []).map((g, index) => {
        return {
          key: index,
          value: g.value,
        };
      });
    } else {
      guaranteeInfo.push({
        key: (new Date()).getTime(),
        value: '',
      });
      notGuaranteeInfo.push({
        key: (new Date()).getTime(),
        value: '',
      });
    }
    this.setState({
      guaranteeInfo,
      notGuaranteeInfo,
    });
  }

  componentWillUnmount() {
    this.props.guarantee.clearInfo();
  }

  mapValueToDb = (info) => {
    return (info || []).map(i => {
      return {
        value: i.value,
      };
    });
  }

  beforeSubmit = async (previewPdf = false) => {
    const confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขใบรับประกัน' : 'กรุณายืนยันการเพิ่มใบรับประกัน',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => await this.onSubmit(previewPdf),
      allowOutsideClick: () => !swal.isLoading(),
    };
  
    const result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      setTimeout(this.onCancel, 500);
    }
  }

  onSubmit = async (previewPdf = false) => {
    try {
      const { guaranteeInfo, notGuaranteeInfo, isEdit } = this.state;
      const { guarantee } = this.props;

      const notGuaranteeRemoveEmpty = notGuaranteeInfo.filter((g) => g.value !== '');

      const guaranteeData = {
        guaranteeInfo: this.mapValueToDb(guaranteeInfo),
        notGuaranteeInfo: this.mapValueToDb(notGuaranteeRemoveEmpty),
      };
      if (isEdit) {
        await guarantee.updateGuarantee(guaranteeData, previewPdf);
      } else {
        await guarantee.createGuarantee(guaranteeData, previewPdf);
      }
      return true;
    } catch (error) {
      swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
    }
  }

  onCancel = () => {
    const { guarantee, history } = this.props
    guarantee.clearInfo();
    history.push(`${config.publicUrl}/qchang/guarantee`);
  }

  deleteGuarantee(selectedItem, e) {
    let guaranteeInfo = [...this.state.guaranteeInfo];
    if (e && e.target) {
      let item = (guaranteeInfo || []).filter((r) => {
        return r.key !== selectedItem.key;
      });
      this.setState({
        guaranteeInfo: item,
      });
    }
  }

  deleteNotGuarantee(selectedItem, e) {
    let notGuaranteeInfo = [...this.state.notGuaranteeInfo];
    if (e && e.target) {
      let item = (notGuaranteeInfo || []).filter((r) => {
        return r.key !== selectedItem.key;
      });
      this.setState({
        notGuaranteeInfo: item,
      });
    }
  }

  onAddGuaranteeItems = () => {
    const guaranteeInfo = _.cloneDeep(this.state.guaranteeInfo);
    guaranteeInfo.push({
      key: (new Date()).getTime(),
      value: '',
    });
    this.setState({
      guaranteeInfo,
    });
  }

  onAddNotGuaranteeItems = () => {
    const notGuaranteeInfo = _.cloneDeep(this.state.notGuaranteeInfo);
    notGuaranteeInfo.push({
      key: (new Date()).getTime(),
      value: '',
    });
    this.setState({
      notGuaranteeInfo,
    });
  }

  onNotGuaranteeChange = (range, e) => {
    const notGuaranteeInfo = _.cloneDeep(this.state.notGuaranteeInfo);
    if (e && e.target) {
      let item = (notGuaranteeInfo || []).filter((r) => {
        if (r.key === range.key) {
          r.value = e.target.value;
        }
        return r;
      });
      this.setState({ notGuaranteeInfo });
    }
  }

  onGuaranteeChange = (range, e) => {
    const guaranteeInfo = _.cloneDeep(this.state.guaranteeInfo);
    if (e && e.target) {
      let item = (guaranteeInfo || []).filter((r) => {
        if (r.key === range.key) {
          r.value = e.target.value;
        }
        return r;
      });
      this.setState({ guaranteeInfo });
    }
  }

  onNameChange = (e) => {
    if (e && e.target) {
      this.props.guarantee.saveInfo('name', e.target.value);
    }
  }
  onDateChange = (e) => {
    if (e && e.target) {
      this.props.guarantee.saveInfo('totalDate', +e.target.value);
    }
  }

  isValid = () => {
    const { info } = this.props.guarantee.toJS();
    const { guaranteeInfo, notGuaranteeInfo } = this.state;
    const validateGuarantee = guaranteeInfo.find(g => _.isEmpty(g.value));
    // QBC-2238 remove notGuarantee validation
    // const validatenotGuarantee = notGuaranteeInfo.find(g => _.isEmpty(g.value));
    // const result = !!(info.name && info.totalDate && !validateGuarantee && !validatenotGuarantee);
    const result = !!(info.name && info.totalDate && !validateGuarantee);
    return result;
  }

  render() {
    const { notGuaranteeInfo, guaranteeInfo } = this.state;
    const { info } = this.props.guarantee.toJS();
    const formTitle = this.state.isEdit ? 'แก้ไขกลุ่มสินค้า' : 'เพิ่มข้อมูลใบรับประกัน';
    const guarantee = (guaranteeInfo || []).map((r, indexRange) => {
      const inputClass = (guaranteeInfo.length > 1) ? 'col-md-10 col-xs-8' : 'col-md-11 col-xs-9';
      return (
        <div key={indexRange}>
          <div className="row">
            <div style={{ marginTop: '50px', paddingLeft: '40px' }} className="col-md-1 col-xs-1"> {indexRange + 1}.</div>
            <div className={inputClass} style={{ marginTop: '40px' }}>
              <EnTextArea value={r.value} rows="1" onChange={this.onGuaranteeChange.bind(this, r)} />
            </div>
            {
              (guaranteeInfo.length > 1) &&
              <EnButton className="col-xs-1 col-md-1 btn-danger" onClick={this.deleteGuarantee.bind(this, r)} style={{ margin: '40px 0 0 0', padding: '9px 0px' }}>
                <i className="fa fa-trash" aria-hidden="true" />
              </EnButton>
            }
          </div>
        </div>
      );
    });
    const notGuarantee = (notGuaranteeInfo || []).map((r, indexRange) => {
      const inputClass = (notGuaranteeInfo.length > 1) ? 'col-md-10 col-xs-8' : 'col-md-11 col-xs-9';
      return (
        <div key={indexRange}>
          <div className="row">
            <div style={{ marginTop: '50px', paddingLeft: '40px' }} className="col-md-1 col-xs-1"> {indexRange + 1}.</div>
            <div className={inputClass} style={{ marginTop: '40px' }}>
              <EnTextArea value={r.value} rows="1" onChange={this.onNotGuaranteeChange.bind(this, r)} />
            </div>
            {
              (notGuaranteeInfo.length > 1) &&
              <EnButton className="col-xs-1 col-md-1 btn-danger" onClick={this.deleteNotGuarantee.bind(this, r)} style={{ margin: '40px 0 0 0', padding: '9px 0px' }}>
                <i className="fa fa-trash" aria-hidden="true" />
              </EnButton>
            }
          </div>
        </div >
      );
    });

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{formTitle}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <div className="card-content">
                  <FormValidation>
                    <div className="row">
                      <EnTextFormGroup
                        containerClassName="col-md-6"
                        type="text"
                        label="ชื่อใบรับประกัน"
                        value={info.name}
                        onChange={this.onNameChange.bind(this)}
                        validations={[required]} />
                      <EnTextFormGroup
                        containerClassName="col-md-6"
                        type="number"
                        label="จำนวนวันที่รับประกัน"
                        value={info.totalDate}
                        onChange={this.onDateChange.bind(this)}
                        validations={[required]} />
                    </div>
                    <div className="header-duration" style={{ height: '70px', marginTop: '20px' }}>
                      เงื่อนไขและขอบเขตการรับประกัน
                      <EnButton className="btn-success pull-right" onClick={this.onAddGuaranteeItems.bind(this, guaranteeInfo)} style={{ margin: '0 10px 0 0' }}>
                        <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่ม
                      </EnButton>
                    </div>
                    {guarantee}
                    <br />
                    <div className="header-duration" style={{ height: '70px' }}>
                      ขอสงวนสิทธิ์ไม่รับประกันในกรณีต่อไปนี้
                      <EnButton className="btn-success pull-right" onClick={this.onAddNotGuaranteeItems.bind(this, notGuaranteeInfo)} style={{ margin: '0 10px 0 0' }}>
                        <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่ม
                      </EnButton>
                    </div>
                    {notGuarantee}
                    <br />
                    <div className="row">
                      <EnButton className="btn-danger" onClick={this.onCancel}>
                        <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                      </EnButton>
                      <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={() => this.beforeSubmit(false)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-primary pull-right" disabled={!this.isValid()} onClick={() => this.beforeSubmit(true)}>
                        <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />บันทึกและดูตัวอย่าง
                      </EnButton>
                    </div>
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container >
    );
  }
}

export default inject('auth', 'guarantee')(observer(GuaranteePage));

import * as _ from 'lodash';
import BaseStore from './BaseStore';
import config from '../config';
// import datetime from '../utils/datetime';
import http from '../utils/http';
// import common from '../utils/common';

export const vatRate = 7;

export class InvoiceStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      isSubmitting: false,
      info: this.createDefaultInfo(),
      invoices: [],
      selectedInvoiceIndex: 0,
      infoChange: {},
      initInfo: {}
    });
  }

  createDefaultInfo() {
    return {
      type: 'individual',
      name: '',
      address: {
        no: '',
        province: {
          name: '',
          code: '',
        },
        district: {
          name: '',
          code: '',
        },
        subDistrict: {
          name: '',
          code: '',
        },
        postcode: '',
      },
      taxId: '',
      branch: '',
      phone: '',
      details: [this.createDefaultDetail()],
      remark: '',
      subTotal: 0.0,
      discountTotal: 0.0,
      netTotal: 0.0,
      // subTotalExcludeVat: 0.00,
      vat: 0.0,
      vatRate,
      netExcludeVat: 0.0,
      grandTotal: 0.0,
      paymentChannel: 'Credit and debit cards',
      paymentOther: '',
      promotionText: '',
    };
  }

  createDefaultDetail() {
    return {
      code: '',
      name: '',
      unit: '',
      qty: 1,
      price: 0.0,
      discount: 0.0,
      amount: 0.0,
    };
  }

  toAddressData(address) {
    return {
      subDistrict: address.subDistrict.name,
      subDistrictCode: address.subDistrict.code,
      district: address.district.name,
      districtCode: address.district.code,
      province: address.province.name,
      provinceCode: address.province.code,
      postCode: address.postcode,
    };
  }

  clearInvoices() {
    this.invoices = [];
  }

  resetSelectedInvoiceIndex() {
    this.selectedInvoiceIndex = 0;
  }

  saveAddressObject(obj) {
    this.info.address.province.name = obj.pname;
    this.info.address.province.code = obj.pcode;
    this.info.address.district.name = obj.aname;
    this.info.address.district.code = obj.acode;
    this.info.address.subDistrict.name = obj.dname;
    this.info.address.subDistrict.code = obj.dcode;
    this.info.address.postcode = obj.zcode;
  }

  saveInfo(key, value) {
    this.info[key] = value;
    this.infoChange[key] = value;
  }

  saveAddressInfo(key, value) {
    this.info.address[key] = value;
  }

  saveDetail(index, key, value) {
    this.info.details[index][key] = value;
  }

  saveDetailAndCalculate(index, key, value) {
    const info = this.info;
    const detail = info.details[index];
    detail[key] = !isNaN(value) ? value : 0;
    detail.amount = (detail.price * detail.qty) - detail.discount;
    this.calculateTotal();
  }

  saveDiscountCoupon(value) {
    this.info.discountCoupon = value;
    this.info.grandTotal = this.info.netTotal - this.info.discountCoupon;
  }

  saveSelectedInvoiceIndex(index) {
    this.selectedInvoiceIndex = index;
    const { invoices } = this.toJS();
    const invoice = this.getSelectedInvoice(invoices, index);
    this.setInvoiceToInfoAndCalculate(invoice);
    this.initInfo = invoice
    this.initInfo.discountCoupon = invoice.discountCoupon || 0
  }

  calculateTotal() {
    const info = this.info;
    const result = info.details.reduce((acc, cur) => {
      return {
        subTotal: cur.price ? acc.subTotal + (parseFloat(cur.qty || '1') * parseFloat(cur.price || 0)) : acc.subTotal,
        discountTotal: cur.discount ? acc.discountTotal + parseFloat(cur.discount) : acc.discountTotal,
      };
    },
      {
        subTotal: 0.00,
        discountTotal: 0.00,
      });
    info.subTotal = result.subTotal;
    info.discountTotal = result.discountTotal;
    info.netTotal = info.subTotal - info.discountTotal;
    info.netExcludeVat = parseFloat((info.netTotal / 1.07).toFixed(2));
    info.vat = parseFloat((info.netTotal - info.netExcludeVat).toFixed(2));
    info.grandTotal = (info.netTotal || 0) - (info.discountCoupon || 0) - (info.advancePayment || 0);
  }

  addDetail() {
    this.info.details.push(this.createDefaultDetail());
  }

  deleteDetail(index) {
    const info = this.toJS().info;
    const details = _.cloneDeep(info.details);
    details.splice(index, 1);
    this.info.details = details;
    this.calculateTotal();
  }

  async generatePdf() {
    const { info } = this.toJS();
    let body = this.toSaveData(info);
    body.job_info = info.jobInfo;
    try {
      const res = await http.post(`${config.api.sims}/v1/pdf/invoice`, body);
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  parseFloatDetails(details) {
    for (let i = 0; i < details.length; i++) {
      const detail = details[i];
      if (typeof detail.qty !== 'number') detail.qty = parseFloat(detail.qty);
      if (typeof detail.price !== 'number') detail.price = parseFloat(detail.price);
      if (typeof detail.discount !== 'number') detail.discount = parseFloat(detail.discount);
      if (typeof detail.amount !== 'number') detail.amount = parseFloat(detail.amount);
    }
  }

  getSelectedInvoice(invoices, index) {
    if (Array.isArray(invoices) && invoices.length) {
      return invoices[index];
    }
    return null;
  }

  getInvoiceAsArray(invoice) {
    return Array.isArray(invoice) ? invoice : invoice ? [invoice] : [];
  }

  calculateAdvancePayment(invoice) {
    if (invoice.quotation_no && invoice.is_final_payment) {
      let advancePayment = 0;
      const invQuotation = this.invoices.filter((inv) => inv.quotation_no === invoice.quotation_no && !inv.is_final_payment);
      for (let i = 0; i < invQuotation.length; i++) {
        const inv = invQuotation[i];
        advancePayment += inv.grand_total;
      }
      return advancePayment || 0
    }
    return 0
  }
  
  setInvoiceToInfoAndCalculate(invoice) {
    if (invoice) {
      this.info.details = invoice.items;
      this.info.subTotal = invoice.sub_total;
      this.info.discountTotal = invoice.discount_total;
      // this.info.subTotalExcludeVat = invoice.sub_total_exclude_vat;
      this.info.vat = invoice.vat;
      this.info.vatRate = invoice.vat_rate;
      this.info.netExcludeVat = invoice.net_exclude_vat;
      this.info.discountCoupon = invoice.discount_coupon;
      this.info.grandTotal = invoice.grand_total;
      this.info.remark = invoice.remark;
      this.info.advancePayment = this.calculateAdvancePayment(invoice) || 0;
      this.info.paymentChannel = invoice.payment_channel;
      this.info.paymentOther = invoice.payment_other;
      this.info.promotionText = invoice.promotion_text;
      this.info.no = invoice.no;
      this.calculateTotal();
      this.initInfo = invoice
      this.initInfo.discountCoupon = invoice.discountCoupon || 0
    }
  }

  toInvoiceInfo(job) {
    if (job) {
      const customerTax = job.customer_tax;
      if (job.customer_tax) {
        const { address } = customerTax;
        this.info.type = customerTax.type;
        this.info.name = customerTax.name;
        this.info.taxId = customerTax.tax_id;
        this.info.branch = customerTax.branch;
        this.info.phone = customerTax.phone;
        this.info.address.no = address.no;
        this.info.address.province.code = address.province.code;
        this.info.address.province.name = address.province.name;
        this.info.address.district.code = address.district.code;
        this.info.address.district.name = address.district.name;
        this.info.address.subDistrict.code = address.sub_district.code;
        this.info.address.subDistrict.name = address.sub_district.name;
        this.info.address.postcode = address.postcode;
      }

      const invoices = this.getInvoiceAsArray(job.invoice);
      this.invoices = invoices;
      const { selectedInvoiceIndex } = this.toJS();
      const invoice = this.getSelectedInvoice(invoices, selectedInvoiceIndex);
      this.setInvoiceToInfoAndCalculate(invoice);
      console.log('this.info ------------->>', this.info);
    } else {
      this.info = this.createDefaultInfo();
    }
    this.info.jobInfo = job;
    this.info.requestTax = job && job.request_tax ? job.request_tax : false;
  }

  toSaveData(info) {
    this.parseFloatDetails(info.details);
    return {
      request_tax: info.requestTax,
      customer_tax: {
        type: info.type,
        name: info.name,
        tax_id: info.taxId,
        branch: info.branch,
        phone: info.phone,
        address: {
          no: info.address.no,
          province: {
            code: info.address.province.code,
            name: info.address.province.name,
          },
          district: {
            code: info.address.district.code,
            name: info.address.district.name,
          },
          sub_district: {
            code: info.address.subDistrict.code,
            name: info.address.subDistrict.name,
          },
          postcode: info.address.postcode,
        },
      },
      invoice: {
        items: info.details,
        sub_total: info.subTotal,
        discount_total: info.discountTotal,
        net_total: info.netTotal,
        // sub_total_exclude_vat: info.subTotalExcludeVat,
        vat: info.vat,
        vat_rate: vatRate,
        net_exclude_vat: info.netExcludeVat,
        discount_coupon: info.discountCoupon,
        grand_total: info.grandTotal,
        remark: info.remark,
        payment_channel: info.paymentChannel,
        payment_other: info.paymentOther,
        no: info.no,
        promotion_text: info.promotionText,
      },
      update_iv_no: info.no,
    };
  }

  async loadInvoiceByJobId(jobId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const res = await http.get(`${config.api.sims}/v1/jobs/${jobId}`, {
        params: {
          is_show_contractor_cost: true,
        },
      });
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        this.toInvoiceInfo(data);
      } else {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async saveInvoice(jobId) {
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    const { info } = this.toJS();
    const body = this.toSaveData(info);
    try {
      const res = await http.put(`${config.api.sims}/v1/jobs/${jobId}/invoice`, body);
      this.isSubmitting = false;
      if (res && res.data) {
        return res.data
      }
    } catch (error) {
      this.isSubmitting = false;
      throw new Error(error);
    }
  }
}

export default new InvoiceStore();

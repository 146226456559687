import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import moment from 'moment';
import { get, uniq } from 'lodash';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import EnDatePicker from '../../components/form/EnDatePicker';
import { AvailableTime, JobStatuses } from '../../utils/data';

import '../../assets/css/search-jobs-page.css';
import EnButton from '../../components/form/EnButton';
import EmptyBox from '../../assets/img/empty-box.svg';

const JOB_TYPE_OPTIONS = [
  { label: 'งานติดตั้ง', value: 'I' },
  { label: 'งานสำรวจ', value: 'S' },
];

const SALE_MODEL_OPTIONS = [
  { label: 'MarketPlace', value: 'MarketPlace' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Marketplace Credit', value: 'MarketplaceCredit' },
  { label: 'Claim', value: 'Claim' },
];

const DEFAULT_FILTER_FORM_VALUE = {
  keyword: '',
  startDateFrom: null,
  startDateTo: null,
  saleModels: [],
  jobTypes: [],
  workTypes: [],
  contractors: [],
  statuses: [],
  withoutContractor: false,
};
const DEFAULT_SORT = { job_code: 'desc', start_date: 'desc' };
const DISPLAY_DATE_FORMAT = 'ddd DD/MM/YYYY';

class SearchJobsV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isCollapsed: false,
      workTypeOptions: [],
      contractorOptions: [],
      filterFormValue: DEFAULT_FILTER_FORM_VALUE,
      tableSort: {},
      jobs: [],
      nextCursor: null,
    };
  }

  componentDidMount = () => {
    this.initWorkTypeOptions();
    this.initContractorOptions();
    this.initConfigGradeMap();
  };

  initWorkTypeOptions = async () => {
    const { auth: authStore, typeofwork: workTypeStore } = this.props;

    const storeId = authStore.getStoreId();
    const workTypes = await workTypeStore.getTypeOfWorks({ store_id: storeId, use_projection: true });
    const workTypeOptions = workTypes.map(({ name, value }) => ({ label: name, value }));

    this.setState({ workTypeOptions });
  };

  initContractorOptions = async () => {
    const { auth: authStore, contractor: contractorStore } = this.props;

    const storeId = authStore.getStoreId();
    const contractors = await contractorStore.getContractors({ storeId });
    const contractorOptions = contractors.map(({ _id, team_name }) => ({ label: team_name, value: _id }));

    this.setState({ contractorOptions });
  };

  initConfigGradeMap = async () => {
    const { contractorgrade: contractorGradeStore } = this.props;

    if (!contractorGradeStore.toJS().gradeMap) {
      await contractorGradeStore.initGradeMap();
    }
    this.setState({ isLoading: false });
  };

  toggleFilterPanel = () => {
    const { isCollapsed } = this.state;
    this.setState({ isCollapsed: !isCollapsed });
  };

  handleInputChange = (key, newValue) => {
    const { filterFormValue } = this.state;
    this.setState({ filterFormValue: { ...filterFormValue, [key]: newValue } });
  };

  hasFilterValue = () => {
    const { filterFormValue } = this.state;
    return Boolean(
      filterFormValue.keyword ||
      filterFormValue.startDateFrom ||
      filterFormValue.startDateTo ||
      filterFormValue.saleModels.length ||
      filterFormValue.jobTypes.length ||
      filterFormValue.workTypes.length ||
      filterFormValue.contractors.length ||
      filterFormValue.statuses.length ||
      filterFormValue.withoutContractor,
    );
  };

  clearFilterFormValue = () => {
    this.setState({ filterFormValue: DEFAULT_FILTER_FORM_VALUE, jobs: [], nextCursor: null });
  };

  prioritizeWithDefaultSort = (tableSort) => {
    if (get(tableSort, 'start_date')) {
      return { ...tableSort, job_code: DEFAULT_SORT.job_code };
    }
    return { ...tableSort, ...DEFAULT_SORT };
  };

  handleSortField = (key) => {
    const { tableSort, jobs } = this.state;
    const currentDirection = tableSort[key];
    let newTableSort = { ...tableSort };

    if (currentDirection) {
      newTableSort[key] = currentDirection === 'asc' ? 'desc' : 'asc';
    } else {
      newTableSort = { [key]: 'asc' };
    }

    for (const sortKey of Object.keys(tableSort)) {
      if (sortKey !== key) {
        delete newTableSort[sortKey];
      }
    }

    if (key === 'customer_firstname') {
      newTableSort = { ...newTableSort, job_code: 'desc', start_date: 'desc' };
    } else if (key === 'job_code') {
      let idDirection = 'desc';
      if (currentDirection == 'asc') {
        idDirection = 'asc';
      }
      newTableSort = { ...newTableSort, _id: idDirection };
    } else if (key === 'start_date' || key === 'end_date') {
      newTableSort = { ...newTableSort, job_code: 'desc' };
    }

    this.setState({ tableSort: newTableSort });
    this.searchJobs({ tableSort: newTableSort });
  };

  getSortIconClassName = (key) => {
    const { tableSort } = this.state;
    const directionClassNameMap = { desc: '-down', asc: '-up' };
    const primarySortKey = Object.keys(tableSort)[0];
    const directionClassName = primarySortKey == key ? directionClassNameMap[tableSort[key]] : '';

    return `fa fa-sort${directionClassName}`;
  };

  getBadgeColor = ({ status, is_paid, contractor }) => {
    if (status === 'created') {
      return '#e0faff';
    } else if ('claim deleted'.includes(status)) {
      return '#EF4E30';
    } else if (!is_paid) {
      return '#D0B3E1';
    }
    return '#8be28b';
  };

  getJobHref = (jobId, status) => {
    const { auth: authStore } = this.props;
    if (authStore.isAdminQChang()) {
      return `/sims/admin/calendar/jobs/${jobId}?status=${status}`;
    }
    return `/sims/superadmin/jobs/${jobId}?status=${status}`;
  };

  convertSortToQueryParam = (sort) => {
    if (Object.keys(sort).length) {
      return Object.entries(sort)
        .map(([key, value]) => `${key}|${value}`)
        .join(',');
    }
    return undefined;
  };

  searchJobs = async ({ tableSort = {}, isNextCursor }) => {
    this.setState({ isLoading: true });
    const storeId = this.props.auth.getStoreId();
    const { filterFormValue } = this.state;
    const sort = tableSort;
    const res = await this.props.job.getJobBySearch({
      store_id: storeId || undefined,
      q: filterFormValue.keyword || undefined,
      sale_models: filterFormValue.saleModels.length ? filterFormValue.saleModels.map(({ value }) => value) : undefined,
      start_date_from: filterFormValue.startDateFrom ? filterFormValue.startDateFrom.startOf('day').toISOString() : undefined,
      start_date_to: filterFormValue.startDateTo ? filterFormValue.startDateTo.endOf('day').toISOString() : undefined,
      type_of_jobs: filterFormValue.jobTypes.length ? filterFormValue.jobTypes.map(({ value }) => value) : undefined,
      type_of_works: filterFormValue.workTypes.length ? filterFormValue.workTypes.map(({ value }) => value) : undefined,
      contractor_ids: filterFormValue.contractors.length ? filterFormValue.contractors.map(({ value }) => value) : undefined,
      statuses: filterFormValue.statuses.length ? filterFormValue.statuses.map(({ value }) => value) : undefined,
      has_no_contractor: filterFormValue.withoutContractor || undefined,
      sort: this.convertSortToQueryParam(sort),
      limit: 20,
      nextCursor: isNextCursor ? this.state.nextCursor : undefined,
    });

    if (res.data) {
      this.setState({ tableSort, jobs: isNextCursor ? [...this.state.jobs, ...res.data] : res.data, nextCursor: res.next_cursor });
    }

    this.setState({ isLoading: false });
  };

  render = () => {
    const { contractorgrade: contractorGradeStore, auth } = this.props;
    const { isLoading, workTypeOptions, contractorOptions, isCollapsed, filterFormValue, tableSort, jobs, nextCursor, a } = this.state;

    const isInitLoading = !workTypeOptions.length || !contractorOptions.length;
    const filterBtnIconClassName = `fa fa-chevron-${isCollapsed ? 'down' : 'up'}`;
    const filterBtnActive = isCollapsed && this.hasFilterValue();
    const checkboxLabelClassName = filterFormValue.contractors.length ? 'search-jobs-page__disabled' : undefined;
    const hasJobs = Array.isArray(jobs) && jobs.length > 0;
    const jobsNotFound = Array.isArray(jobs) && jobs.length === 0;
    return (
      <Container isAdmin>
        <Loader show={isInitLoading || isLoading} />
        <div className="card">
          <div className="card-header" data-background-color="orange">
            <h4 className="title search-jobs-page__card-header-title">ค้นหางาน</h4>
          </div>
          <div className="card-content search-jobs-page__card-content">
            <div className="search-jobs-page__filter-row">
              <div className="input-group search-jobs-page__input-group">
                <input
                  className="form-control"
                  placeholder="ค้นหาด้วย ชื่อ, นามสกุล, ชื่อ-นามสกุล, เบอร์โทรศัพท์, Email, Job ID, Q Number, SO Number, CO Number, Partner Order ID"
                  type="text"
                  value={filterFormValue.keyword}
                  onChange={(event) => this.handleInputChange('keyword', event.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && this.searchJobs({})}
                />
                <div className="input-group-btn">
                  <button className="search-jobs-page__search-btn" type="button" onClick={() => this.searchJobs({})}>
                    ค้นหา
                  </button>
                </div>
              </div>
              <button
                className="search-jobs-page__toggle-filter-btn"
                active={filterBtnActive.toString()}
                type="button"
                onClick={this.toggleFilterPanel}
              >
                <span>ฟิลเตอร์</span>
                <i className={filterBtnIconClassName} />
              </button>
            </div>
            <div className="search-jobs-page__filter-row" visible={isCollapsed.toString()}>
              <div className="search-jobs-page__filter-label-col">วันที่เริ่มงาน</div>
              <div className="search-jobs-page__filter-input-col">
                <EnDatePicker
                  id="search-jobs-page__start-date-from"
                  initialDate={filterFormValue.startDateFrom}
                  isOutsideRange={(day) => filterFormValue.startDateTo && filterFormValue.startDateTo.isBefore(day)}
                  isPlaceholder="ตั้งแต่วันที่"
                  onDateChange={(date) => this.handleInputChange('startDateFrom', date)}
                />
                <EnDatePicker
                  id="search-jobs-page__start-date-to"
                  initialDate={filterFormValue.startDateTo}
                  isOutsideRange={(day) => filterFormValue.startDateFrom && filterFormValue.startDateFrom.isAfter(day)}
                  isPlaceholder="ถึงวันที่"
                  onDateChange={(date) => this.handleInputChange('startDateTo', date)}
                />
              </div>
              <div className="search-jobs-page__filter-label-col">Sale Model</div>
              <div className="search-jobs-page__filter-input-col">
                <Select
                  multi
                  options={SALE_MODEL_OPTIONS}
                  placeholder=""
                  style={{ height: 38, paddingBottom: 4 }}
                  value={filterFormValue.saleModels}
                  onChange={(options) => this.handleInputChange('saleModels', options)}
                />
              </div>
            </div>
            <div className="search-jobs-page__filter-row" visible={isCollapsed.toString()}>
              <div className="search-jobs-page__filter-label-col">ลักษณะงาน</div>
              <div className="search-jobs-page__filter-input-col">
                <Select
                  multi
                  options={JOB_TYPE_OPTIONS}
                  placeholder=""
                  style={{ height: 38, paddingBottom: 4 }}
                  value={filterFormValue.jobTypes}
                  onChange={(options) => this.handleInputChange('jobTypes', options)}
                />
              </div>
              <div className="search-jobs-page__filter-label-col">ประเภทงาน</div>
              <div className="search-jobs-page__filter-input-col">
                <Select
                  multi
                  options={workTypeOptions}
                  placeholder=""
                  style={{ height: 38, paddingBottom: 4 }}
                  value={filterFormValue.workTypes}
                  onChange={(options) => this.handleInputChange('workTypes', options)}
                />
              </div>
            </div>
            <div className="search-jobs-page__filter-row" visible={isCollapsed.toString()}>
              <div className="search-jobs-page__filter-label-col">ทีมช่าง</div>
              <div className="search-jobs-page__filter-input-col">
                <Select
                  disabled={filterFormValue.withoutContractor}
                  multi
                  options={contractorOptions}
                  placeholder=""
                  style={{ height: 38, paddingBottom: 4 }}
                  value={filterFormValue.contractors}
                  onChange={(options) => this.handleInputChange('contractors', options)}
                />
              </div>
              <div className="search-jobs-page__filter-label-col">สถานะ</div>
              <div className="search-jobs-page__filter-input-col">
                <Select
                  labelKey="name"
                  multi
                  options={JobStatuses}
                  placeholder=""
                  style={{ height: 38, paddingBottom: 4 }}
                  value={filterFormValue.statuses}
                  onChange={(options) => this.handleInputChange('statuses', options)}
                />
              </div>
            </div>
            <div className="search-jobs-page__filter-row search-jobs-page__space-between" visible={isCollapsed.toString()}>
              <div className="search-jobs-page__checkbox">
                <input
                  id="search-jobs-page__no-contractor-checkbox"
                  checked={filterFormValue.withoutContractor}
                  disabled={filterFormValue.contractors.length}
                  type="checkbox"
                  onChange={() => this.handleInputChange('withoutContractor', !filterFormValue.withoutContractor)}
                />
                <label className={checkboxLabelClassName} htmlFor="search-jobs-page__no-contractor-checkbox">
                  งานที่ไม่มีช่าง
                </label>
              </div>
              <button
                className="search-jobs-page__clear-filter-btn"
                disabled={!this.hasFilterValue()}
                type="button"
                onClick={this.clearFilterFormValue}
              >
                ล้างตัวกรอง
              </button>
            </div>
            <div className="search-job-table">
              <table className="search-jobs-page__table">
                <thead>
                  <tr>
                    <th className="text-center">
                      <span>No.</span>
                    </th>
                    <th className="search-jobs-sticky-header">
                      <div className="search-jobs-page__clickable-table-header" onClick={() => hasJobs && this.handleSortField('job_code')}>
                        <span>เลขอ้างอิง</span>
                        <i className={this.getSortIconClassName('job_code')} />
                      </div>
                    </th>
                    <th>
                      <div className="search-jobs-page__clickable-table-header" onClick={() => hasJobs && this.handleSortField('customer_firstname')}>
                        <span>ข้อมูลลูกค้า</span>
                        <i className={this.getSortIconClassName('customer_firstname')} />
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>ประเภทงาน</span>
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>ลักษณะงาน</span>
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>ข้อมูลช่าง</span>
                      </div>
                    </th>
                    <th>
                      <div className="search-jobs-page__clickable-table-header" onClick={() => hasJobs && this.handleSortField('start_date')}>
                        <span>วันที่เริ่ม</span>
                        <i className={this.getSortIconClassName('start_date')} />
                      </div>
                    </th>
                    <th>
                      <div className="search-jobs-page__clickable-table-header" onClick={() => hasJobs && this.handleSortField('end_date')}>
                        <span>วันสิ้นสุด</span>
                        <i className={this.getSortIconClassName('end_date')} />
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>สถานะ</span>
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>วันที่ช่างได้รับเงิน</span>
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>Model การขาย</span>
                      </div>
                    </th>
                    <th>
                      <div>
                        <span>แบ่งงวด</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {hasJobs &&
                    jobs.map((job, index) => (
                      <tr key={job._id}>
                        <td className="text-center" style={{ borderLeft: `5px solid ${this.getBadgeColor(job)}`, width: '40px' }}>
                          <div>{index + 1}</div>
                        </td>
                        <td className="search-jobs-sticky-header">
                          <div className={`search-jobs-page__badge`} />
                          <a className="search-jobs-page__job-link" href={this.getJobHref(job._id, job.status)} target="_blank">
                            {job.job_code}
                          </a>
                        </td>
                        <td className="search-jobs-page__job-customer">
                          <div>
                            {job.customer_firstname} {job.customer_lastname}
                          </div>
                          <div className="search-jobs-page__table-small-text">{job.customer_phone}</div>
                          <div className="search-jobs-page__table-small-text">
                            {Array.isArray(job.invoice)
                              ? uniq(job.invoice.map(({ payment_channel }) => payment_channel)).join(',')
                              : get(job, 'invoice.payment_channel')}
                          </div>
                        </td>
                        <td className="search-jobs-page__job-description">
                          <div>{job.description}</div>
                        </td>
                        <td className="search-jobs-page__job-type">
                          <div>
                            {get(
                              JOB_TYPE_OPTIONS.find(({ value }) => value === job.type_of_job),
                              'label',
                            )}
                          </div>
                          <div className="search-jobs-page__table-small-text">
                            {get(
                              AvailableTime.find(({ id }) => id === job.available),
                              'title',
                            )}
                          </div>
                        </td>
                        <td className="search-jobs-page__job-constractor">
                          <div>{get(job, 'contractor.team_name')}</div>
                          <div className="search-jobs-page__table-small-text">{get(job, 'contractor.phone')}</div>
                          <div className="search-jobs-page__table-small-text" hidden={!get(job, 'contractor')}>
                            เกรดทีม : {contractorGradeStore.getDisplayedGrade(get(job, 'contractor.team_grade'))}
                          </div>
                        </td>
                        <td>
                          <div hidden={!job.start_date}>{moment(job.start_date).format(DISPLAY_DATE_FORMAT)}</div>
                        </td>
                        <td>
                          <div hidden={!job.end_date}>{moment(job.end_date).format(DISPLAY_DATE_FORMAT)}</div>
                        </td>
                        <td>
                          <div>
                            {get(
                              JobStatuses.find(({ value }) => value === job.status),
                              'name',
                            )}
                          </div>
                        </td>
                        <td className="search-jobs-page__transfer">
                          <div hidden={!get(job, 'transfer.effective_date')}>
                            {moment(get(job, 'transfer.effective_date')).format(DISPLAY_DATE_FORMAT)}
                          </div>
                          <div className="search-jobs-page__table-small-text">{get(job, 'transfer.payout_channel')}</div>
                        </td>
                        <td className="search-jobs-page__sale-model">
                          <div>{job.sale_model}</div>
                        </td>
                        <td className="search-jobs-page__installment">
                          <div hidden={!job.installment}>งวด {job.installment}</div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {nextCursor ? (
              <div className="row">
                <div className="col-md-12 text-center">
                  <EnButton onClick={() => this.searchJobs.bind(this)({ tableSort, isNextCursor: true })}>แสดงเพิ่มเติม</EnButton>
                </div>
              </div>
            ) : null}

            {jobsNotFound && (
              <div className="search-jobs-page__empty-container">
                <img className="search-jobs-page__empty-img" src={EmptyBox} alt='EmptyBox' />
                <div className="search-jobs-page__empty-text">ไม่มีงานที่ค้นหา</div>
              </div>
            )}
          </div>
        </div>
      </Container>
    );
  };
}

export default inject('auth', 'typeofwork', 'job', 'contractor', 'contractorgrade')(observer(SearchJobsV2));

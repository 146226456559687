import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/ContainerContractor';
import Pagination from '../../../components/common/Pagination';
import Loader from '../../../components/common/Loader';
import { toast } from 'react-toastify';

export class Grade extends React.Component {
  constructor(props) {
    super(props);

    let id = this.props.match.params.id;
    this.state = {
      file: undefined,
      id: id,
    };
  }

  async componentWillMount() {
    try {
      this.props.contractor.getContractor({
        id: this.state.id,
      });
      this.props.contractorgrade.setQueryValue('contractor_id', this.state.id);
      await this.props.contractorgrade.searchContractorGrade();
    } catch (error) {
      toast.error(error.message);
    }
  }

  componentDidMount = async () => {
    const { contractorgrade: contractorGradeStore } = this.props;
    await contractorGradeStore.initGradeMap();
  };

  displayTableRows(data) {
    const { contractorgrade: contractorGradeStore } = this.props;

    if (data.grade && data.grade.length) {
      return data.grade.map((d, index) => {
        return (
          <tr key={index}>
            <td className="text-center">{d.type_of_work_name}</td>
            <td className="text-center">{contractorGradeStore.getDisplayedGrade(d.grade)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center"></td>
          <td className="text-center"></td>
        </tr>
      );
    }

  }

  async onPageClick(pageNum) {
    this.props.contractorgrade.setQueryValue('page', pageNum);
    await this.props.contractorgrade.searchContractorGrade();
  }

  render() {
    const { data, isLoading } = this.props.contractorgrade.toJS();
    return (
      <Container>
        <Loader show={isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">เกรด</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover ">
                        <thead>
                          <tr>
                            <th width="70%" className="text-center">ประเภทงาน</th>
                            <th width="30%" className="text-center">เกรด</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayTableRows(data)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('contractor', 'contractorgrade')(observer(Grade));

import React from 'react';
// import swal from 'sweetalert2';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import { FormValidation, required, password, passwordConfirm } from '../../../components/form/FormValidation';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnButton from '../../../components/form/EnButton';
import EnToggle from '../../../components/form/EnToggle';
import EnTagSelect from '../../../components/form/EnTagSelect';
import config from '../../../config';
import { Checkbox } from 'react-bootstrap/lib';
import EnDropDown from '../../../components/form/EnDropDown';
import NotiEmail from './NotiEmail'
import { toast } from 'react-toastify';



export class StoreAddForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCancel = () => {
    this.props.history.push(`${config.publicUrl}/superadmin/store`);
  }

  async componentWillMount() {
    try {
      this.props.admin.setDefaultInfo();
      await this.props.typeofwork.getTypeOfWorks({ sort: 'name' });
      await this.props.channel.getAllChannel();
      let listChanel = this.props.channel.toJS().info;
      this.setDefChannel(listChanel[0].channel_name)
    } catch (error) {
      toast.error(error.message || 'เกิดข้อผิดพลาด');
    }
  }

  onSubmit = async () => {
    try {
      await this.props.admin.saveAdminStore();
      toast.success('สร้างร้านเรียบร้อย');
      this.props.history.push(`${config.publicUrl}/superadmin/store`);
    } catch (error) {
      if (error.message === 'EXISTING_USER') {
        toast.error('มีชื่อ user นี้ในระบบแล้ว');
      } else {
        toast.error(error.message);
      }
    }
  }

  saveInfo = (key, e) => {
    if (e.target.value) {
      this.props.admin.setStoreInfo(key, e.target.value);
    }
  }
  setBuAccount = (key, e) => {
    const info = toJS(this.props.admin.storeInfo);
    if (e.target.value) {
      this.props.admin.setStoreInfo(key, !info.isBuAccount);
    }
  }
  setAttendSCGFamily = (key, e) => {
    const info = toJS(this.props.admin.storeInfo);
    if (e.target.value) {
      this.props.admin.setStoreInfo(key, !info.isAttendSCGFamily);
    }
  }
  onToggle = (e) => {
    this.props.admin.togglePayment(e);
  }

  onToggleShare = (e) => {
    this.props.admin.toggleShare(e);
  }

  onTogglePO = (e) => {
    this.props.admin.onToggleKey('poRequire', e);
  }

  onToggleRequestPayment = (e) => {
    this.props.admin.onToggleKey('requestPayment', e);
  }

  onToggleRequestStorePayment = (e) => {
    this.props.admin.onToggleKey('requestStorePayment', e);
  }

  onToggleQuotation = (e) => {
    this.props.admin.onToggleKey('quotationRequire', e);
  }

  onToggleReceipt = (e) => {
    this.props.admin.onToggleKey('receiptRequire', e);
  }

  onToggleTracking = (e) => {
    this.props.admin.onToggleKey('trackingRequire', e);
  }

  onTypeOfWorkTagChange(value) {
    const ids = value.split(',');
    this.props.admin.setStoreInfo('selectedTypeOfWorks', ids);
  }

  onToggleLineOA = (e) => {
    this.props.admin.toggleLineOA(e);
  }

  onChangeChannel(value) {
    this.props.admin.setStoreInfo('channel', value);
  }

  setDefChannel(value) {
    
    this.props.admin.setStoreInfo('channel', value);
  }

  showGroupID(){
    const info = toJS(this.props.admin.storeInfo);
    if(info.groupID) {
      return (
        <div>
          {'มี group_id'}
        </div>
      )
    }
    return <div>{'ไม่มี group_id'}</div>;
  }

  render() {
    let listChanel = this.props.channel.toJS().info;
    const isLoading = toJS(this.props.admin.isLoading);
    const info = toJS(this.props.admin.storeInfo);
    const typeOfWorks = toJS(this.props.typeofwork.items);
    const towOptions = (typeOfWorks || []).map(tow => {
      return {
        label: `${tow.name}`,
        value: `${tow.value}`,
        disabled: _.indexOf(info.selectedTypeOfWorks, tow.value) >= 0,
      };
    });

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">เพิ่มร้านค้าใหม่</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                
                
                <FormValidation>
                    <div className="row" style={{ marginLeft: '20px' }}>
                        <div className="col-md-2 col-sm-3 col-xs-6">
                        <Checkbox
                          id="requestTaxBuAccount"
                          name="requestTaxBuAccount"
                          title="Business Account"
                          checked={info.isBuAccount}
                          onClick={this.setBuAccount.bind(this, 'isBuAccount')}>Business Account</Checkbox>
                        </div>
                        <div className="col-xs-6">
                          <Checkbox
                            id="requestTaxSCGFamily"
                            name="requestTaxSCGFamily"
                            title="SCG Family"
                            checked={info.isAttendSCGFamily}
                            onClick={this.setAttendSCGFamily.bind(this, 'isAttendSCGFamily')}>เข้าร่วม SCG Family</Checkbox>
                        </div>
                    </div>
                  <EnTextFormGroup containerClassName="col-md-6" type="text" value={info.name} label="ชื่อร้าน" onChange={this.saveInfo.bind(this, 'name')} validations={[required]} />
                  <FormGroup label="channel" containerClassName="col-md-6">
                    <EnDropDown
                      value={info.channel}
                      onChange={e => { this.onChangeChannel(e.target.value) }}
                      >
                        {
                            _.map(listChanel, c => {
                                return (
                                    <option key={c.channel_name} value={c.channel_name}>{c.channel_name}</option>
                                )
                            })
                        }
                    </EnDropDown>
                  </FormGroup>
                  <EnTextFormGroup containerClassName="col-md-12" type="text" value={info.userName} label="ชื่อผู้ใช้" onChange={this.saveInfo.bind(this, 'userName')} validations={[required]} />
                  <EnTextFormGroup containerClassName="col-md-12" type="password" name="confirm" label="รหัสผ่าน" onChange={this.saveInfo.bind(this, 'password')} validations={[required, password]} />
                  <EnTextFormGroup containerClassName="col-md-12" type="password" label="ยืนยันรหัสผ่าน" onChange={this.saveInfo.bind(this, 'confirmPassword')} validations={[required, passwordConfirm, password]} />
                  <FormGroup containerClassName="col-md-12" label="ประเภทงาน">
                    <EnTagSelect
                      withHeight
                      placeholder="กรุณาเลือกประเภทงาน"
                      closeOnSelect={false}
                      options={towOptions}
                      value={info.selectedTypeOfWorks}
                      onTagChange={this.onTypeOfWorkTagChange.bind(this)}
                      searchable={false}
                    />
                  </FormGroup>
                  {/* Line OA */}
                  <div className='col-md-12'>
                      <div className='line-oa-contrainer'>
                        <span className="form-label">Line OA Bot</span>
                        <EnToggle noPadding={true} checked={info.lineOARequire} onChange={this.onToggleLineOA.bind(this)} />
                      </div>
                      {this.showGroupID()}
                  </div>
                  {/* End Of Section Line OA */}
                  <div className="row">
                    <div className="col-md-3" style={{ marginLeft: '5px' }}>
                      <FormGroup containerClassName="col-md-12">
                        <span id="register-type" className="form-label">Payment Require</span>
                        <EnToggle checked={info.paymentRequire} onChange={this.onToggle.bind(this)} />
                        <span id="share" className="form-label">Shareable</span>
                        <EnToggle checked={info.shareable} onChange={this.onToggleShare.bind(this)} />
                        <span id="po" className="form-label">PO Require</span>
                        <EnToggle checked={info.poRequire} onChange={this.onTogglePO.bind(this)} />
                        <span id="quotation" className="form-label">Quotation Require</span>
                        <EnToggle checked={info.quotationRequire} onChange={this.onToggleQuotation.bind(this)} />
                        <span id="receipt" className="form-label">Receipt Require</span>
                        <EnToggle checked={info.receiptRequire} onChange={this.onToggleReceipt.bind(this)} />
                        <span id="tracking" className="form-label">Tracking Require</span>
                        <EnToggle checked={info.trackingRequire} onChange={this.onToggleTracking.bind(this)} />
                      </FormGroup>
                    </div>
                    <div className="col-md-8">
                      <div className="col-md-12" style={{ marginLeft: '5px' }}>
                        <div className="row">
                          <FormGroup containerClassName="col-md-12">
                            <span id="register-type" className="form-label">Marketplace เงินสด</span>
                            <EnToggle checked={info.requestPayment} onChange={this.onToggleRequestPayment.bind(this)} />
                          </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup containerClassName="col-md-4">
                              <span id="share" className="form-label">Marketplace เงินเชื่อ</span>
                              <EnToggle checked={info.requestStorePayment} onChange={this.onToggleRequestStorePayment.bind(this)} />
                            </FormGroup>
                            {
                              info.requestStorePayment && (
                                <NotiEmail info={info}/>
                              )
                            }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div_EnButton row">
                    <div className="col-md-12">
                      <EnButton className="btn-info pull-right" validations={[required]} onClick={this.onSubmit.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                        <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                      </EnButton>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('admin', 'typeofwork','channel')(observer(StoreAddForm));

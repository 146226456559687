const EmojiList = [
  '😀',
  '😃',
  '😄',
  '😁',
  '😆',
  '🥹',
  '😅',
  '😂',
  '🤣',
  '🥲',
  '😊',
  '😇',
  '🙂',
  '🙃',
  '😉',
  '😌',
  '😍',
  '🥰',
  '😘',
  '😗',
  '😙',
  '😚',
  '😋',
  '😛',
  '😝',
  '😜',
  '🤪',
  '🤨',
  '🧐',
  '🤓',
  '😎',
  '🥸',
  '🤩',
  '🥳',
  '😏',
  '😒',
  '😞',
  '😔',
  '😟',
  '😕',
  '🙁',
  '😣',
  '😖',
  '😫',
  '😩',
  '🥺',
  '😢',
  '😭',
  '😤',
  '😠',
  '😡',
  '🤬',
  '🤯',
  '😳',
  '🥵',
  '🥶',
  '😶‍🌫️',
  '😱',
  '😨',
  '😰',
  '😥',
  '😓',
  '🤗',
  '🤔',
  '🫣',
  '🤭',
  '🫢',
  '🫡',
  '🤫',
  '🫠',
  '🤥',
  '😶',
  '🫥',
  '😐',
  '🫤',
  '😑',
  '🫨',
  '🙂‍↔️',
  '🙂‍↕️',
  '😬',
  '🙄',
  '😯',
  '😧',
  '😦',
  '😮',
  '😲',
  '🥱',
  '😴',
  '🤤',
  '😪',
  '😮‍💨',
  '😵',
  '😵‍💫',
  '🤐',
  '🥴',
  '🤢',
  '🤮',
  '🤧',
  '😷',
  '🤒',
  '🤕',
  '🤑',
  '🤠',
  '😈',
  '👿',
  '👹',
  '👺',
  '🤡',
  '💩',
  '👻',
  '💀',
  '☠️',
  '👽',
  '👾',
  '🤖',
  '🎃',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '😿',
  '😾',
  '🫶',
  '🤲',
  '👐',
  '🙌',
  '👏',
  '🤝',
  '👍',
  '👎',
  '👊',
  '✊',
  '🤛',
  '🤜',
  '🫷',
  '🫸',
  '🤞',
  '🫰',
  '🤟',
  '🤘',
  '👌',
  '🤌',
  '🤏',
  '🫳',
  '🫴',
  '👈',
  '👉',
  '👆',
  '👇',
  '✋',
  '🤚',
  '🖐️',
  '🖖',
  '👋',
  '🤙',
  '🫲',
  '🫱',
  '💪',
  '🙏',
  '🫵',
];

export const EmojiMapped = EmojiList.map((emoji) => ({ title: emoji, character: emoji }));

// Define the custom special characters
export const EmojiPlugin = (editor) => {
  editor.plugins.get('SpecialCharacters').addItems('emoji', EmojiMapped);
};
import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { Tab } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select';
import MaskInput from 'react-text-mask';

import config from '../../../config';

import Container from '../../../layouts/Container';

import ContractorCalendar from '../../admin/AdminViewContractorCalendar';
import SetUpGrade from '../../admin/AdminSetupGradeContractor';
import AdminVaccineApproove from '../../admin/AdminVaccineApproove';
import AdminBlockPaymentContractors from '../../admin/AdminBlockPaymentContractors';
import AdminTypeOfWorkContractorV2 from '../../admin/approveTypeOfWorksV2';
import AdminAreaOfWorkContractorV2 from '../../admin/approveAreaOfWorksV2';
import AdminPauseMatchingContractor from '../../admin/AdminPauseMatchingContractor';

import { FormValidation, phone, idcard, email, postcode } from '../../../components/form/FormValidation';
import Loader from '../../../components/common/Loader';
import EnButton from '../../../components/form/EnButton';
import EnDropDown from '../../../components/form/EnDropDown';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import { EnTab } from '../../../components/form/EnTab';
import EnDatePicker from '../../../components/form/EnDatePicker';

import { isAllow, isRole, ROLE } from '../../../utils/permission';
import common from '../../../utils/common';
import { 
  getDayOfBirthOptions,
  getMonthOfBirthOptions, 
  getYearOfBirthOptions, 
  getDateOfBirthFlag
} from '../../../utils/data';
import UploadFileV2 from '../../../components/form/UploadFileV2';
import EnToggle from '../../../components/form/EnToggle';
import { ToggleApprove } from '../../admin/AdminApproveContractor';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

export class TaxAddressV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      editMode: isRole(ROLE.INSTALLER_MANAGEMENT),
      disabledSaveButton: true,
      disabledApproveRejectDraft: true,
      disabledAllField: true,
      activeIndex: 0,
      isLoading: true,
      checked: false,
      miscData: {
        province: [],
        district: [],
        subDistrict: [],
      },
      optionShippingAddress: {
        province: [],
        district: [],
        subDistrict: [],
      },
      dayOfBirth: '',
      monthOfBirth: '',
      yearOfBirth: '',
      draftMode: false,
      isShippingSameAddress: false,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const isSuperAdmin = this.props.auth.isSuperAdmin();
      const contractor = await this.props.contractor.getContractor({
        id: this.state.id,
        isAdmin: isSuperAdmin,
      });
      const province = this.props.misc.getAllProvince();
      const banks = this.props.bank.getAllBank();
      const shirtSize = this.props.shirt.getAllShirtSize();
      const result = await Promise.all([contractor, province, banks, shirtSize]);
      const data = result[0];

      // get tax address
      if (data && data.tax && data.tax.address) {
        const { province, district } = data.tax.address;
        await this.props.misc.getDistrictByCode(province.code);
        await this.props.misc.getSubDistrictByCode(district.code);
      } else if (data) {
        await this.props.misc.getDistrictByCode(data.province_id);
        await this.props.misc.getSubDistrictByCode(data.district_id);

        const { miscData } = this.props.misc.toJS();
        const province = Array.isArray(miscData.province) ? miscData.province.find((p) => p.code === data.province_id) : undefined;
        const provinceName = province ? province.name : '';
        const districtName = Array.isArray(miscData.district) && miscData.district.length ? miscData.district[0].name : '';
        const subDistrictName = Array.isArray(miscData.subDistrict) && miscData.subDistrict.length ? miscData.subDistrict[0].name : '';

        this.props.contractor.saveMultiInfo({
          taxProvinceCode: data.province_id,
          taxProvinceName: provinceName,
          taxDistrictCode: data.district_id,
          taxDistrictName: districtName,
          taxSubDistrictCode: data.sub_district_id,
          taxSubDistrictName: subDistrictName,
          taxZipCode: data.zip_code,
          taxAddress: data.address,
        });
      }

      // Force to get all province
      const { miscData } = this.props.misc.toJS();
      // get shipping address
      if (data && data.shipping_address && data.shipping_address.province) {
        const { province, district } = data.shipping_address;
        const districtResponse = await this.props.misc.getProvinceDistricts(province.code);
        const subDistrictResponse = await this.props.misc.getProvinceSubDistricts(district.code);
        miscData.district = districtResponse;
        miscData.subDistrict = subDistrictResponse;
      }

      const validate = !this.isValidInfo();

      this.setState({
        disabledAllField: isAllow('VIEW_CONTRACTOR_DATA') && !isAllow('EDIT_CONTRACTOR_DATA'),
        disabledSaveButton: validate || !isAllow('EDIT_CONTRACTOR_DATA'),
        disabledApproveRejectDraft: !isAllow('APPROVE_OR_REJECT_CONTRACTOR_DATA'),
        miscData: {
          province: miscData.province,
          district: miscData.district,
          subDistrict: miscData.subDistrict,
        },
        optionShippingAddress: {
          province: miscData.province,
          district: miscData.district,
          subDistrict: miscData.subDistrict,
        },
        dayOfBirth: data.date_of_birth_flag === 1 || data.date_of_birth_flag === 2 ? '--' : moment(data.date_of_birth).format('DD'),
        monthOfBirth: data.date_of_birth_flag === 2 || data.date_of_birth_flag === 3 ? '--' : moment(data.date_of_birth).format('MM'),
        yearOfBirth: data.date_of_birth ? moment(data.date_of_birth).format('YYYY') : '',
        draftMode: data.has_draft,
        isShippingSameAddress: _.get(data, 'shipping_address.is_same_address', false),
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // EVENT
  changeQueryString(props) {
    const obj = {
      job_type: props.jobType,
    };

    if (props.currentDate) {
      obj.date = moment(props.currentDate).format('YYYY-MM');
    }

    const qs = common.urlBuilder(obj);
    this.props.history.push(`${config.publicUrl}/superadmin/contractors/${this.state.id}/taxaddress?${qs}`);
  }

  isValidInfo() {
    const { info, fileTypes } = this.props.contractor.toJS();

    // validate for juristic
    if (info.isJuristic) {
      const companyCert = info.file.filter((item) => item.type === fileTypes.company_cert);
      if (!companyCert.length) {
        toast.error('กรุณาเลือกไฟล์ หนังสือรับรองบริษัท');
        return false;
      }

      const vat = info.file.filter((item) => item.type === fileTypes.vat);
      if (!vat.length) {
        toast.error('กรุณาเลือกไฟล์ ภพ.20');
        return false;
      }

      if (!info.taxPhone) {
        toast.error('กรุณากรอกเบอร์บัญชีผู้ใช้งาน');
        return false;
      }

      if (!info.taxBranchNo) {
        toast.error('กรุณากรอกรหัสสาขา');
        return false;
      }

      if (!info.taxNo) {
        toast.error('กรุณากรอกเลขประจำตัวผู้เสียภาษี');
        return false;
      }
    }

    if (!info.firstName) {
      toast.error('กรุณากรอกชื่อ');
      return false;
    }

    if (!info.lastName) {
      toast.error('กรุณากรอกนามสกุล');
      return false;
    }

    if (!info.idCardNo) {
      toast.error('กรุณากรอกเลขบัตรประชาชน');
      return false;
    }

    if (!info.profilePic) {
      toast.error('กรุณาเลือกรูปประจำตัว');
      return false;
    }

    if (!info.teamName) {
      toast.error('กรุณากรอกชื่อทีม');
      return false;
    }

    if (!info.email) {
      toast.error('กรุณากรอกอีเมล');
      return false;
    }

    if (!info.taxAddress || !info.taxProvinceCode || !info.taxDistrictCode || !info.taxSubDistrictCode || !info.taxZipCode) {
      toast.error('กรุณากรอกที่อยู่บริษัท');
      return false;
    }

    if (
      !info.shippingAddress.address ||
      !info.shippingAddress.province.code ||
      !info.shippingAddress.district.code ||
      !info.shippingAddress.subDistrict.code ||
      !info.shippingAddress.zipCode
    ) {
      toast.error('กรุณากรอกที่อยู่จัดส่งเอกสาร');
      return false;
    }

    if (!info.bankCode || !info.bankAccountNo || !info.bankAccountName) {
      toast.error('กรุณากรอกข้อมูลธนาคารให้ครบถ้วน');
      return false;
    }

    const fileBookBank = info.file.filter((item) => item.type === fileTypes.book_bank);
    if (!fileBookBank.length) {
      toast.error('กรุณาเลือกไฟล์ สมุดบัญชีธนาคาร');
      return false;
    }

    if (!info.nationality) {
      toast.error('กรุณาเลือกสัญชาติ');
      return false;
    }

    if (!info.contactPhoneNo) {
      toast.error('กรุณากรอกเบอร์ติดต่อ');
      return false;
    }

    if (!info.criminalRecordCheckStatus) {
      toast.error('กรุณาเลือกสถานะการตรวจสอบประวัติ');
      return false;
    }

    if (info.criminalRecordCheckStatus === '0') {
      const fileCriminalRecordCheck = info.file.filter((item) => item.type === fileTypes.criminal_record_check);
      if (!fileCriminalRecordCheck.length) {
        toast.error('กรุณาเลือกไฟล์ หลักฐานการตรวจประวัติอาชญากรรม');
        return false;
      }
    }

    const fileIdCard = info.file.filter((item) => item.type === fileTypes.id_card);
    if (!fileIdCard.length) {
      toast.error('กรุณาเลือกไฟล์ บัตรประชาชน');
      return false;
    }

    // validate format
    let regex = new RegExp('^[0-9]{10,10}$');
    if (!regex.test(info.contactPhoneNo)) {
      return false;
    }

    regex = new RegExp('^[0-9]{13,13}$');
    if (!regex.test(info.idCardNo)) {
      return false;
    }

    regex = new RegExp('^[0-9]{13,13}$');
    if (!regex.test(info.taxNo)) {
      return false;
    }

    regex = new RegExp('^[0-9]{5,5}$');
    if (!regex.test(info.taxZipCode)) {
      return false;
    }

    regex = new RegExp('^[0-9]{5,5}$');
    if (!regex.test(info.shippingAddress.zipCode)) {
      return false;
    }

    const filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    const emails = info.email.split(',');
    if (emails.length > 5) {
      return false;
    }
    for (let i = 0; i < emails.length; i++) {
      if (String(emails[i]).search(filter) === -1) {
        return false;
      }
    }

    return true;
  }

  async onSaveInfo() {
    try {
      const validate = this.isValidInfo();
      if (!validate) return;

      const { dayOfBirth, monthOfBirth } = this.state;
      await this.props.contractor.setInfo('dateOfBirthFlag', getDateOfBirthFlag(dayOfBirth, monthOfBirth));
      this.state.dayOfBirth = this.state.dayOfBirth === '--'? '01': this.state.dayOfBirth;
      this.state.monthOfBirth = this.state.monthOfBirth === '--'? '01': this.state.monthOfBirth;
      const dateOfBirth = moment(`${this.state.dayOfBirth}-${this.state.monthOfBirth}-${this.state.yearOfBirth}`, 'DD-MM-YYYY');
      await this.props.contractor.setInfo('dateOfBirth', dateOfBirth.format('YYYY-MM-DD'));

      await this.props.contractor.updateContractorInfo(this.state.id, 'taxAddress');
      await toast.success('บันทึกข้อมูลเรียบร้อย');
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  }

  async onApproveDraft(id) {
    const result = await Swal.fire({
      title: 'อนุมัติการแก้ไข',
      text: 'ท่านต้องการอนุมัติการแก้ไขข้อมูลของช่างคนดังกล่าวหรือไม่',
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    })

    if (result.isConfirmed) {
      try {
        await this.props.contractor.updateDraft(id, 'approved');
        toast.success('อนุมัติการแก้ไขเรียบร้อย')
        window.location.reload();
      } catch (error) {
        toast.error(error.message);
      }  
    }
  }

  async onRejectDraft(id) {
    const result = await Swal.fire({
      title: 'ปฏิเสธการแก้ไข',
      text: 'กรุณาระบุเหตุผลในการปฏิเสธการแก้ไขข้อมูลของช่างคนดังกล่าว',
      input: 'textarea',
      inputPlaceholder: 'ระบุเหตุผลปฏิเสธการแก้ไข',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      cancelButtonColor: '#d33',
      onRender: (dom) => {
        // if textarea is empty, disable confirm button
        const textarea = dom.querySelector('textarea');
        const confirmButton = dom.querySelector('.swal2-confirm');
        confirmButton.setAttribute('disabled', 'disabled');

        textarea.addEventListener('input', () => {
          if ( textarea.value.trim().length >= 5) {
            confirmButton.removeAttribute('disabled');
          } else {
            confirmButton.setAttribute('disabled', 'disabled');
          }
        });
      },
    })

    if(result.isConfirmed) {
      try {
        const reason = result.value;
        await this.props.contractor.updateDraft(id, 'rejected', reason);
        toast.success('ปฏิเสธการแก้ไขสำเร็จ')
        window.location.reload();
      } catch (error) {
        toast.error(error.message);
      }
    }
  }

  onGoBack() {
    if (isAllow('SEARCH_AND_VIEW_A_LIST_OF_CONTRACTORS')) {
      //this.props.history.push(`${config.publicUrl}/superadmin/approve`);
      this.props.history.go(-1);
    } else {
      //Go Back to Main Menu
      this.props.history.push(`${config.publicUrl}`);
    }
  }

  async onChangeAddress(event, data, type, isShipping = false) {
    const { info } = this.props.contractor.toJS();
    const value = event.target ? event.target.value : event;
    // TAX ADDRESS
    if (!isShipping) {
      if (type === 'province') {
        const provinceSelected = data.find((item) => item.code === value);
        if (provinceSelected) {
          this.props.misc.getDistrictByCode(value);
          this.props.misc.getSubDistrictByCode(value);
        }

        this.props.contractor.setInfo('taxProvinceCode', provinceSelected ? provinceSelected.code : '');
        this.props.contractor.setInfo('taxProvinceName', provinceSelected ? provinceSelected.name : '');

        this.props.contractor.setInfo('taxDistrictCode', '');
        this.props.contractor.setInfo('taxDistrictName', '');

        this.props.contractor.setInfo('taxSubDistrictCode', '');
        this.props.contractor.setInfo('taxSubDistrictName', '');
      }

      if (type === 'district') {
        const districtSelected = data.find((item) => item.code === value);
        if (districtSelected) {
          this.props.misc.getSubDistrictByCode(value);
        }

        this.props.contractor.setInfo('taxDistrictCode', districtSelected ? districtSelected.code : '');
        this.props.contractor.setInfo('taxDistrictName', districtSelected ? districtSelected.name : '');

        this.props.contractor.setInfo('taxSubDistrictCode', '');
        this.props.contractor.setInfo('taxSubDistrictName', '');
      }

      if (type === 'subDistrict') {
        const subDistrictSelected = data.find((item) => item.code === value);
        this.props.contractor.setInfo('taxSubDistrictCode', subDistrictSelected ? subDistrictSelected.code : '');
        this.props.contractor.setInfo('taxSubDistrictName', subDistrictSelected ? subDistrictSelected.name : '');
      }

      if (type === 'address') {
        this.props.contractor.setInfo('taxAddress', value);
      }

      if (type === 'zipCode') {
        this.props.contractor.setInfo('taxZipCode', value);
      }
    }

    // SHIPPING ADDRESS
    if (info.isShippingSameAddress || isShipping) {
      if (type === 'province') {
        const provinceSelected = data.find((item) => item.code === value);
        if (!provinceSelected) {
          return this.props.contractor.setInfo('shippingAddress', {
            ...info.shippingAddress,
            province: { code: '', name: '' },
            district: { code: '', name: '' },
            subDistrict: { code: '', name: '' },
          });
        } else {
          const district = await this.props.misc.getProvinceDistricts(provinceSelected.code);
          const subDistrict = await this.props.misc.getProvinceSubDistricts(provinceSelected.code);

          this.props.contractor.setInfo('shippingAddress', {
            ...info.shippingAddress,
            province: { code: provinceSelected.code, name: provinceSelected.name },
            district: { code: '', name: '' },
            subDistrict: { code: '', name: '' },
          });

          this.setState({
            optionShippingAddress: {
              ...this.state.optionShippingAddress,
              district: district,
              subDistrict: subDistrict,
            },
          });
        }
      }

      if (type === 'district') {
        const districtSelected = data.find((item) => item.code === value);
        if (!districtSelected) {
          return this.props.contractor.setInfo('shippingAddress', {
            ...info.shippingAddress,
            district: { code: '', name: '' },
            subDistrict: { code: '', name: '' },
          });
        } else {
          const subDistrict = await this.props.misc.getProvinceSubDistricts(districtSelected.code);

          this.props.contractor.setInfo('shippingAddress', {
            ...info.shippingAddress,
            district: { code: districtSelected.code, name: districtSelected.name },
            subDistrict: { code: '', name: '' },
          });

          this.setState({
            optionShippingAddress: {
              ...this.state.optionShippingAddress,
              subDistrict: subDistrict,
            },
          });
        }
      }

      if (type === 'subDistrict') {
        const subDistrictSelected = data.find((item) => item.code === value);
        this.props.contractor.setInfo('shippingAddress', {
          ...info.shippingAddress,
          subDistrict: {
            code: subDistrictSelected ? subDistrictSelected.code : '',
            name: subDistrictSelected ? subDistrictSelected.name : '',
          },
        });
      }

      if (type === 'address') {
        this.props.contractor.setInfo('shippingAddress', {
          ...info.shippingAddress,
          address: value,
        });
      }

      if (type === 'zipCode') {
        this.props.contractor.setInfo('shippingAddress', {
          ...info.shippingAddress,
          zipCode: value,
        });
      }
    }
  }

  onChange(key, event) {
    const value = event.target ? event.target.value : event;

    if (key === 'dateOfBirth') {
      this.props.contractor.setInfo(key, moment(value).format('YYYY-MM-DD'));
    } else {
      this.props.contractor.setInfo(key, value);
    }

    if (value.length <= 1) {
      const validate = this.isValidInfo();
      this.setState({ disabledSaveButton: !validate });
    }
  }

  onSelect(key, value) {
    if(key === 'dayOfBirth' || key === 'monthOfBirth' || key === 'yearOfBirth') {
      this.setState({[key]: value});
    } else if (key === 'carIdProvince') {
      const { miscData } = this.props.misc.toJS();
      const provinceSelected = miscData.province.find((item) => item.code === value);
      this.props.contractor.setInfo('carIdProvince', {
        code: provinceSelected ? provinceSelected.code : '',
        name: provinceSelected ? provinceSelected.name : '',
      });
    } else {
      this.props.contractor.setInfo(key, value);
    }

    const validate = this.isValidInfo();
    this.setState({ disabledSaveButton: !validate });
  }

  onChecked(event) {
    const value = event.target ? event.target.checked : event;

    if (value) {
      const { taxProvinceCode, taxProvinceName, taxDistrictCode, taxDistrictName, taxSubDistrictCode, taxSubDistrictName, taxZipCode, taxAddress } =
        this.props.contractor.toJS().info;

      this.setState({
        optionShippingAddress: {
          province: this.props.misc.toJS().miscData.province,
          district: this.props.misc.toJS().miscData.district,
          subDistrict: this.props.misc.toJS().miscData.subDistrict,
        },
      });

      this.props.contractor.setInfo('shippingAddress', {
        province: { code: taxProvinceCode, name: taxProvinceName },
        district: { code: taxDistrictCode, name: taxDistrictName },
        subDistrict: { code: taxSubDistrictCode, name: taxSubDistrictName },
        zipCode: taxZipCode,
        address: taxAddress,
      });
    } else {
      this.props.contractor.setInfo('shippingAddress', {
        province: { code: '', name: '' },
        district: { code: '', name: '' },
        subDistrict: { code: '', name: '' },
        zipCode: '',
        address: '',
      });
    }

    this.props.contractor.setInfo('isShippingSameAddress', value);
  }

  onAddFile(files, type) {
    if (!files || !files.length) return;

    const isOverLimit = this.limitFileSize(files);
    if (isOverLimit) {
      return toast.error('ไฟล์ทั้งหมดมีขนาดใหญ่เกิน 10 MB');
    }

    for (const file of files) {
      const reader = new FileReader();
      reader.onload = async () => {
        await this.props.contractor.uploadFile(file, type);

        const validate = this.isValidInfo();
        this.setState({ disabledSaveButton: !validate });
      };
      reader.readAsDataURL(file);
    }

    //set toggle to false if upload new profile pic
    if (type === this.props.contractor.fileTypes.profile_pic ) {
      this.props.contractor.setInfo('isApproveProfilePic', false);
    }
    
  }

  onDeleteFile(file) {
    const { info } = this.props.contractor.toJS();
    const files = info.file.filter((item) => item.url !== file.url);
    this.props.contractor.removeFile(files);

    // clear file input value
    const input = document.getElementsByName(file.type);
    if (input && input.length) {
      input[0].value = '';
    }

    const validate = this.isValidInfo();
    this.setState({ disabledSaveButton: !validate });
  }

  limitFileSize(files) {
    // 10 mb limit
    const limitSize = 10 * 1024 * 1024;
    let isOverLimit = 0;

    for (const file of files) {
      isOverLimit += file.size;
    }

    return isOverLimit > limitSize;
  }

  onBlur() {
    const validate = this.isValidInfo();
    this.setState({ disabledSaveButton: !validate });
  }

  // SAVE
  saveRegisterInfo(event, key) {
    this.props.contractor.saveInfo(key, event.target.value);
  }

  saveNetsuiteData = async () => {
    try {
      const { contractor: contractorStore } = this.props;
      const { id: contractorId } = this.state;
      const contractor = contractorStore.toJS().info;

      await contractorStore.updateNetsuiteData({
        contractorId,
        code: contractor.netsuiteCustomerCode,
        internalId: contractor.netsuiteInternalId,
      });

      await toast.success('แก้ไขข้อมูลเรียบร้อย');
      await this.setState({ activeIndex: 2 });
      window.location.reload();
    } catch ({ message }) {
      toast.error(message);
    }
  };

  onApproveProfilePic = (value) => {
    this.props.contractor.setInfo('isApproveProfilePic', value);
  }

  // RENDER
  getDisplayEmpty = (message) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50px',
          width: '100%',
        }}
      >
        <em>
          <small>{message}</small>
        </em>
      </div>
    );
  }

  renderFile = (files, type) => {
    // RENDER
    if (!files || !files.length) {
      return (
        <>
          {this.getDisplayEmpty('ไม่มีเอกสาร')}
          <hr />
        </>
      );
    }

    const prepareFile = files.filter((item) => item.type === type);
    if (!prepareFile.length) {
      return (
        <>
          {this.getDisplayEmpty('ไม่มีเอกสาร')}
          <hr />
        </>
      );
    }

    const { editMode } = this.state;

    return prepareFile.map((item, index) => {
      const lastIndex = prepareFile.length - 1 === index;
      return (
        <div key={`${item.type}-${index}`}>
          <div className="col-md-12">
            <span className="col-md-1 text-center" style={{ paddingLeft: '0px' }}>
              <i className="fa fa-file" aria-hidden="true" />
            </span>

            <span className="col-md-9 text-left" style={{ paddingLeft: '0px', wordWrap: 'break-word' }}>
              <a href={item.url || ''} target="_blank">
                {item.name}
              </a>
            </span>

            {editMode && (
              <span className="col-md-2 text-right" style={{ paddingRight: '0px' }}>
                <i className="fa fa-times" style={{ cursor: 'pointer' }} aria-hidden="true" onClick={() => this.onDeleteFile(item)} />
              </span>
            )}
          </div>

          {lastIndex && (
            <div className="col-md-12">
              <hr />
            </div>
          )}
        </div>
      );
    });
  }

  showJuristicInfo = (contractor) => {
    const { editMode } = this.state;
    const { fileTypes, draft } = this.props.contractor.toJS();
    const contractorDraft = draft || {};
    const draftFiles = _.get(contractorDraft, 'file', []);
 
    return (
      <>
        <div className="row">
          <EnTextFormGroup
            icon={ _.get(contractorDraft, 'teamName', false) && 'fa fa-thumb-tack'}
            containerClassName="col-md-6"
            type="text"
            label="ชื่อบริษัท *"
            value={contractor.teamName}
            onChange={(event) => this.saveRegisterInfo(event, 'teamName')}
            onBlur={() => this.onBlur()}
            disabled={this.isDisabled('teamName')}
          />
          <EnTextFormGroup
            icon={ _.get(contractorDraft, 'taxNo', false) && 'fa fa-thumb-tack'}
            containerClassName="col-md-6"
            type="text"
            label="เลขประจำตัวผู้เสียภาษี"
            value={contractor.taxNo}
            onChange={(event) => this.saveRegisterInfo(event, 'taxNo')}
            onBlur={() => this.onBlur()}
            disabled={this.isDisabled('taxNo')}
            validations={[idcard]}
          />
        </div>

        <div className="row">
          <EnTextFormGroup
            icon={ _.get(contractorDraft, 'taxBranchNo', false) && 'fa fa-thumb-tack'}
            containerClassName="col-md-6"
            type="text"
            label="รหัสสาขา *"
            value={contractor.taxBranchNo}
            onChange={(event) => this.saveRegisterInfo(event, 'taxBranchNo')}
            onBlur={() => this.onBlur()}
            disabled={this.isDisabled('taxBranchNo')}
          />
          <EnTextFormGroup
            icon={ _.get(contractorDraft, 'taxPhone', false) && 'fa fa-thumb-tack'}
            containerClassName="col-md-6"
            type="text"
            label="เบอร์บัญชีผู้ใช้งาน *"
            value={contractor.taxPhone}
            onChange={(event) => this.saveRegisterInfo(event, 'taxPhone')}
            onBlur={() => this.onBlur()}
            disabled
          />
        </div>

        <div className="row">
          <EnTextFormGroup
            icon={  _.get(contractorDraft, 'email', false) && 'fa fa-thumb-tack'}
            containerClassName="col-md-6"
            type="text"
            label="อีเมล"
            value={contractor.email}
            onChange={(event) => this.saveRegisterInfo(event, 'email')}
            onBlur={() => this.onBlur()}
            disabled={this.isDisabled('email')}
            validations={[email]}
          />
          <div className="col-md-6"></div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <UploadFileV2
                label="ไฟล์ หนังสือรับรองบริษัท *"
                selectedInfo={contractor}
                editMode={editMode}
                draftData={contractorDraft}
                hasDraft={this.hasDraftFileType(fileTypes.company_cert)}
                isContractor
                fileType={fileTypes.company_cert}
                onAddFile={(files, type) => this.onAddFile(files, type)}
                onDeleteFile={(file) => this.onDeleteFile(file)}
              />
            </div>
            <div className="col-md-6">
              <UploadFileV2
                label="ไฟล์ ภพ.20 *"
                selectedInfo={contractor}
                editMode={editMode}
                draftData={contractorDraft}
                hasDraft={this.hasDraftFileType(fileTypes.vat)}
                isContractor
                fileType={fileTypes.vat}
                onAddFile={(files, type) => this.onAddFile(files, type)}
                onDeleteFile={(file) => this.onDeleteFile(file)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  showIndividualInfo = (contractor) => {
    const { draft } = this.props.contractor.toJS();
    const contractorDraft = draft || {};

    return (
      <>
        <div className="row" style={{ padding: '0px' }}>
          <EnTextFormGroup
            icon={ _.get(contractorDraft, 'teamName', false) && 'fa fa-thumb-tack'}
            containerClassName="col-md-6"
            type="text"
            label="ชื่อทีม *"
            value={contractor.teamName}
            onChange={(event) => this.saveRegisterInfo(event, 'teamName')}
            onBlur={() => this.onBlur()}
            // disabled
            disabled={this.isDisabled('teamName')}
          />

          <EnTextFormGroup
            icon={ _.get(contractorDraft, 'phone', false) && 'fa fa-thumb-tack'}
            containerClassName="col-md-6"
            type="text"
            label="เบอร์บัญชีผู้ใช้งาน *"
            value={contractor.phone}
            onChange={(event) => this.saveRegisterInfo(event, 'phone')}
            onBlur={() => this.onBlur()}
            // disabled
            disabled={this.isDisabled('phone')}
          />
        </div>

        <div className="row" style={{ padding: '0px' }}>
          <EnTextFormGroup
            icon={ _.get(contractorDraft, 'email', false) && 'fa fa-thumb-tack'}
            containerClassName="col-md-6"
            type="text"
            label="อีเมล"
            value={contractor.email}
            onChange={(event) => this.saveRegisterInfo(event, 'email')}
            onBlur={() => this.onBlur()}
            disabled={this.isDisabled('email')}
            validations={[email]}
          />

          <div className="col-md-6"></div>
        </div>

        <div className="row" style={{ padding: '0px' }}>
          <div className="col-md-6">
            <label>ลายเซ็น</label>
            <div className="form-group col-md-12">
              {contractor.signature ? (
                <img style={{ width: '200px', height: '50px', border: '1px solid #ccc', borderRadius: '4px' }} src={contractor.signature} />
              ) : (
                this.getDisplayEmpty('ไม่มีลายเซ็น')
              )}
            </div>
            {/* !_.isEmpty(contractorDraft.taxProvinceCode) */}
            {this.showPinIcon(!_.isEmpty(contractorDraft.signature))}
          </div>

          <div className="col-md-6"></div>
        </div>
      </>
    );
  }

  showApproveToggle = (contractor) => {
    if (!contractor || contractor.isApproveProfilePic === undefined) return;

    return (
      <ToggleApprove
        isApprove={contractor.isApproveProfilePic}
        disabled={!isAllow('APPROVE_OR_REJECT_PROFILE_PIC')}
        checked={contractor.isApproveProfilePic}
        onChange={this.onApproveProfilePic.bind(this)}
      />
    )
  }

  showPinIcon = (isShow) => {
    if (!isShow) return;
    return <i style={{ paddingLeft: '5px' }} className="fa fa-thumb-tack" aria-hidden="true"></i>
  }

  mapCriminalStatusType = (option, status) => {
    return option.find((item) => item.value === status);
  }

  // OPTIONS
  genBankOptions = () => {
    const bank = this.props.bank.toJS().info;
    return (bank || []).map((b, index) => {
      return {
        // label: (
        //   <div>
        //     <img src={b.image_url} alt="" style={{ backgroundColor: b.image_color, height: '30px', width: '30px' }} />
        //     {b.bank_name}
        //   </div>
        // ),
        label: b.bank_name,
        value: b.bank_code,
      };
    });
  }

  genShirtSizeOptions = () => {
    const { firstSelectShirt } = this.props.contractor.toJS().info;
    if( _.isUndefined(firstSelectShirt) ) return;

    const options = (this.props.shirt.toJS().info || []).map((s) => {
      return {
        label: `${s.size} ( ${s.desc} )`,
        value: s.size,
      };
    });

    return [
      firstSelectShirt && { label: 'กรุณาระบุไซส์เสื้อ', value: '' },
      ...options,
    ];
  }

  getProvinceOptions = (type, selectType) => {
    let province = [];

    if (selectType === 'taxAddress') {
      province = [{ code: '', name: 'กรุณาเลือกจังหวัด' }, ...this.props.misc.toJS().miscData.province];
    } else {
      province = [{ code: '', name: 'กรุณาเลือกจังหวัด' }, ...this.state.optionShippingAddress.province];
    }

    if (type === 'option') {
      return (province || []).map((item, idx) => (
        <option key={idx} value={item.code}>
          {item.name}
        </option>
      ));
    } else {
      return (province || []).map((item) => ({ label: item.name, value: item.code }));
    }
  }

  getDistrictOptions = (selectType) => {
    let district = [];

    if (selectType === 'taxAddress') {
      district = [{ code: '', name: 'กรุณาเลือกอำเภอ' }, ...this.props.misc.toJS().miscData.district];
    } else {
      district = [{ code: '', name: 'กรุณาเลือกอำเภอ' }, ...this.state.optionShippingAddress.district];
    }

    return (district || []).map((item, idx) => (
      <option key={idx} value={item.code}>
        {item.name}
      </option>
    ));
  }

  getSubDistrictOptions = (selectType) => {
    let subDistrict = [];

    if (selectType === 'taxAddress') {
      subDistrict = [{ code: '', name: 'กรุณาเลือกตำบล' }, ...this.props.misc.toJS().miscData.subDistrict];
    } else {
      subDistrict = [{ code: '', name: 'กรุณาเลือกตำบล' }, ...this.state.optionShippingAddress.subDistrict];
    }

    return (subDistrict || []).map((item, idx) => (
      <option key={idx} value={item.code}>
        {item.name}
      </option>
    ));
  }

  hasDraftFileType = (findType) =>{
    const { draft } = this.props.contractor.toJS();
    const draftFiles = _.get(draft, 'file', []); 

    return draftFiles.some((item) => item.type === findType) || this.state.draftMode;
  }

  isDisabled = (key) => {
    const { draft } = this.props.contractor.toJS();
    const { draftMode, disabledAllField } = this.state;

    if (!draft) return disabledAllField;

    // const findDraft = _.get(draft, key, false);
    // if(findDraft) return false

    // return !editMode || draftMode;
    return draftMode || disabledAllField;
  }

  render() {
    const { editMode } = this.state;
    const { options, fileTypes, info, isLoading, isSumitting, draft } = this.props.contractor.toJS();
    const { miscData } = this.props.misc.toJS();
    const contractor = info;
    const contractorDraft = draft || {};

    const { optionShippingAddress } = this.state;

    const showVaccine = (contractor && contractor.vaccine && contractor.vaccine.items) || false;
    const isNetsuiteCustomerCodeValid = /^[A-Z0-9]+$/.test(contractor.netsuiteCustomerCode);
    const isNetsuiteInternalIdValid = /^[0-9]+$/.test(contractor.netsuiteInternalId);

    const saveDisabled = isLoading || isSumitting || this.state.isLoading || this.state.disabledSaveButton;
    const draftFiles = _.get(contractorDraft, 'file', []); 

    
    if (this.state.isLoading) {
      return (
        <Container isAdmin>
          <div className="row">
            <Loader show={true} />
          </div>
        </Container>
      )
    }


    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading || isSumitting || this.state.isLoading} />
          <div className="col-md-12">
            <div className="card col-md-12">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แก้ไขข้อมูลช่าง</h4>
              </div>
              <div className="card-content">
                <FormValidation>
                  <EnTab index={this.state.activeIndex} lazyLoad={true}>
                    {/* INFO */}
                    <Tab title="ข้อมูลส่วนตัว">
                      <div className="card-content" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        {/* ข้อมูลทีม */}
                        <div className="seo">
                          <div className="header-seo">ข้อมูลทีม</div>
                          <div className="seo-form">
                            {contractor.isJuristic ? this.showJuristicInfo(contractor) : this.showIndividualInfo(contractor)}
                          </div>
                        </div>

                        {/* ที่อยู่บริษัท */}
                        <div className="seo" style={{ marginTop: '20px' }}>
                          <div className="header-seo">
                            {contractor.isJuristic ? 'ที่อยู่บริษัท (สำหรับออกใบกำกับภาษี)' : 'ที่อยู่ปัจจุบัน (สำหรับออกใบกำกับภาษี)'}
                          </div>
                          <div className="seo-form">
                            <div className="row">
                              <EnTextFormGroup
                                icon={ !_.isEmpty(contractorDraft.taxAddress) && 'fa fa-thumb-tack'}
                                containerClassName="col-md-6"
                                label="บ้านเลขที, ถนน, ซอย *"
                                rows="2"
                                value={contractor.taxAddress}
                                onChange={(event) => this.onChangeAddress(event, null, 'address')}
                                onBlur={() => this.onBlur()}
                                disabled={this.isDisabled('taxAddress')}
                              />

                              <FormGroup containerClassName="col-md-6" label="จังหวัด *">
                                {this.showPinIcon(!_.isEmpty(contractorDraft.taxProvinceCode))}
                                <EnDropDown
                                  onChange={(event) => this.onChangeAddress(event, miscData.province, 'province')}
                                  value={contractor.taxProvinceCode}
                                  disabled={this.isDisabled('taxProvinceCode')}
                                >
                                  {this.getProvinceOptions('option', 'taxAddress')}
                                </EnDropDown>
                              </FormGroup>
                            </div>

                            <div className="row">
                              <FormGroup containerClassName="col-md-6" label="อำเภอ / เขต *">
                                {this.showPinIcon(!_.isEmpty(contractorDraft.taxDistrictCode))}
                                <EnDropDown
                                  onChange={(event) => this.onChangeAddress(event, miscData.district, 'district')}
                                  value={contractor.taxDistrictCode}
                                  disabled={this.isDisabled('taxDistrictCode')}
                                >
                                  {this.getDistrictOptions('taxAddress')}
                                </EnDropDown>
                              </FormGroup>

                              <FormGroup containerClassName="col-md-6" label="ตำบล *">
                                {this.showPinIcon(!_.isEmpty(contractorDraft.taxSubDistrictCode))}
                                <EnDropDown
                                  onChange={(event) => this.onChangeAddress(event, miscData.subDistrict, 'subDistrict')}
                                  value={contractor.taxSubDistrictCode}
                                  disabled={this.isDisabled('taxSubDistrictCode')}
                                >
                                  {this.getSubDistrictOptions('taxAddress')}
                                </EnDropDown>
                              </FormGroup>
                            </div>

                            <div className="row">
                              <FormGroup containerClassName="col-md-6">
                                <EnTextFormGroup
                                  icon={ !_.isEmpty(contractorDraft.taxZipCode) && 'fa fa-thumb-tack'}
                                  label="รหัสไปรษณีย์"
                                  value={contractor.taxZipCode}
                                  onChange={(event) => this.onChangeAddress(event, null, 'zipCode')}
                                  onBlur={() => this.onBlur()}
                                  validations={[postcode]}
                                  disabled={this.isDisabled('taxZipCode')}
                                />
                              </FormGroup>

                              <div className="col-md-6"></div>
                            </div>
                          </div>
                        </div>

                        {/* ที่อยู่จัดส่งเอกสาร */}
                        <div className="seo" style={{ marginTop: '20px' }}>
                          <div className="header-seo">ที่อยู่จัดส่งเอกสาร</div>
                          <div className="seo-form">
                            <div className="row">
                              <div className="col-md-6 d-flex" style={{ justifyContent: 'flex-start', alignItems: 'normal' }}>
                                <label
                                  className="custom-checkbox size-20x20"
                                  style={{
                                    display: 'block',
                                    cursor: editMode ? 'pointer' : 'not-allowed',
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={contractor.isShippingSameAddress}
                                    onChange={(event) => this.onChecked(event)}
                                    disabled={this.isDisabled('isShippingSameAddress')}
                                  />
                                  <span className="checkmark" />
                                </label>
                                <div style={{ paddingLeft: '30px' }}>
                                  <span style={{ display: 'block', fontWeight: '700' }}>
                                    {contractor.isJuristic ? 'ใช้ที่อยู่เดียวกับที่อยู่บริษัท' : 'ใช้ที่อยู่เดียวกับที่อยู่ปัจจุบัน'}
                                  </span>
                                </div>
                                {this.showPinIcon(_.get(contractorDraft, 'isJuristic', false))}
                              </div>
                              <div className="col-md-6"></div>
                            </div>

                            <div className="row">
                              <EnTextFormGroup
                                icon={ _.get(contractorDraft, 'shippingAddress.address', false) && 'fa fa-thumb-tack'}
                                // disabled={(contractor.isShippingSameAddress || !editMode || _.get(contractorDraft, 'shippingAddress.address', false))}                               
                                disabled={this.isDisabled('shippingAddress.address', contractor.isShippingSameAddress) || contractor.isShippingSameAddress}
                                containerClassName="col-md-6"
                                label="บ้านเลขที, ถนน, ซอย *"
                                rows="2"
                                value={contractor.shippingAddress.address}
                                onChange={(event) => this.onChangeAddress(event, null, 'address', true)}
                                onBlur={() => this.onBlur()}
                              />

                              <FormGroup containerClassName="col-md-6" label="จังหวัด *">
                                {this.showPinIcon( _.get(contractorDraft, 'shippingAddress.province.code', false) )}
                                <EnDropDown
                                  // disabled={contractor.isShippingSameAddress || !editMode}
                                  disabled={this.isDisabled('shippingAddress.province.code') || contractor.isShippingSameAddress}
                                  value={contractor.shippingAddress.province.code}
                                  onChange={(event) => this.onChangeAddress(event, optionShippingAddress.province, 'province', true)}
                                >
                                  {this.getProvinceOptions('option', 'shippingAddress')}
                                </EnDropDown>
                              </FormGroup>
                            </div>

                            <div className="row">
                              <FormGroup containerClassName="col-md-6" label="อำเภอ / เขต *">
                                {this.showPinIcon( _.get(contractorDraft, 'shippingAddress.district.code', false) )}
                                <EnDropDown
                                  // disabled={contractor.isShippingSameAddress || !editMode}
                                  disabled={this.isDisabled('shippingAddress.district.code') || contractor.isShippingSameAddress}
                                  value={contractor.shippingAddress.district.code}
                                  onChange={(event) => this.onChangeAddress(event, optionShippingAddress.district, 'district', true)}
                                >
                                  {this.getDistrictOptions('shippingAddress')}
                                </EnDropDown>
                              </FormGroup>

                              <FormGroup containerClassName="col-md-6" label="ตำบล *">
                                {this.showPinIcon( _.get(contractorDraft, 'shippingAddress.sub_district.code', false) )}
                                <EnDropDown
                                  // disabled={contractor.isShippingSameAddress || !editMode}
                                  disabled={this.isDisabled('shippingAddress.subDistrict.code') || contractor.isShippingSameAddress}
                                  value={contractor.shippingAddress.subDistrict.code}
                                  onChange={(event) => this.onChangeAddress(event, optionShippingAddress.subDistrict, 'subDistrict', true)}
                                >
                                  {this.getSubDistrictOptions('shippingAddress')}
                                </EnDropDown>
                              </FormGroup>
                            </div>

                            <div className="row">
                              <FormGroup containerClassName="col-md-6">
                                <EnTextFormGroup
                                  icon={ _.get(contractorDraft, 'shippingAddress.zip_code', false) && 'fa fa-thumb-tack' }
                                  label="รหัสไปรษณีย์"
                                  value={contractor.shippingAddress.zipCode}
                                  onChange={(event) => this.onChangeAddress(event, null, 'zipCode', true)}
                                  onBlur={() => this.onBlur()}
                                  validations={[postcode]}
                                  // disabled={ this.state.isShippingSameAddress ||  this.isDisabled('shippingAddress.zipCode')}
                                  disabled={this.isDisabled('shippingAddress.zipCode') || contractor.isShippingSameAddress}
                                />
                              </FormGroup>

                              <div className="col-md-6"></div>
                            </div>
                          </div>
                        </div>

                        {/* บัญชีธนาคาร */}
                        <div className="seo" style={{ marginTop: '20px' }}>
                          <div className="header-seo">บัญชีธนาคาร</div>
                          <div className="seo-form">
                            <div className="row">
                              <FormGroup label="เลือกธนาคาร *" containerClassName="col-md-6">
                                {this.showPinIcon( _.get(contractorDraft, 'bankCode', false) )}
                                <Select
                                  searchable={false}
                                  clearable={false}
                                  style={{ height: '40px' }}
                                  placeholder="กรุณาเลือก"
                                  options={this.genBankOptions()}
                                  value={contractor.bankCode}
                                  onChange={(event) => this.onSelect('bankCode', event.value)}
                                  disabled={this.isDisabled('bankCode')}
                                />
                              </FormGroup>
                              <div className="col-md-6">
                                <label>เลขบัญชีรับเงิน *</label>
                                {this.showPinIcon( _.get(contractorDraft, 'bankAccountNo', false) )}
                                <MaskInput
                                  mask={[/\d/, /\d/, /\d/, '-', /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                  className="contractor-mask-input"
                                  guide={false}
                                  value={contractor.bankAccountNo}
                                  onChange={(event) => this.onChange('bankAccountNo', event)}
                                  onBlur={(event) => this.onChange('bankAccountNo', event)}
                                  disabled={this.isDisabled('bankAccountNo')}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <EnTextFormGroup
                                icon={ _.get(contractorDraft, 'bankAccountName', false) && 'fa fa-thumb-tack'}
                                containerClassName="col-md-6"
                                label="ชื่อบัญชี *"
                                value={contractor.bankAccountName}
                                onChange={(event) => this.onChange('bankAccountName', event)}
                                onBlur={() => this.onBlur()}
                                disabled={this.isDisabled('bankAccountName')}
                              />

                              <div className="col-md-6"></div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <UploadFileV2
                                  label="ไฟล์สมุดบัญชีธนาคาร *"
                                  selectedInfo={contractor}
                                  editMode={editMode}
                                  draftData={contractorDraft}
                                  hasDraft={ this.hasDraftFileType(fileTypes.book_bank) }
                                  isContractor
                                  fileType={fileTypes.book_bank}
                                  onAddFile={(files, type) => this.onAddFile(files, type)}
                                  onDeleteFile={(file) => this.onDeleteFile(file)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ข้อมูลส่วนตัว */}
                        <div className="seo" style={{ marginTop: '20px' }}>
                          <div className="header-seo">ข้อมูลส่วนตัว</div>
                          <div className="seo-form">
                            <div className="row">
                              <div className="col-md-5 form-group">
                                <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <label className="control-label">รูปประจำตัว *</label>
                                  {editMode && (
                                    <label className="control-label" style={{ cursor: 'pointer', color: '#265ed6' }}>
                                      <span>แก้ไข </span>
                                      <i className="fa fa-pencil" aria-hidden="true" />
                                      <input
                                        name={fileTypes.profile_pic}
                                        type="file"
                                        multiple={false}
                                        accept="image/*,image/x-raw,image/heic,.raw"
                                        style={{ display: 'none' }}
                                        onChange={(event) => this.onAddFile(event.target.files, fileTypes.profile_pic)}
                                      />
                                    </label>
                                  )}
                                </div>
                                <div className="" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                  <div style={{ width: '110px' }}>{this.showApproveToggle(contractor)}</div>
                                  <img style={{ width: '250px', height: '250px' }} src={contractor.profilePic} className="" alt="profile" />
                                </div>
                              </div>

                              <div className="col-md-1"></div>

                              <div className="col-md-6">
                                <div className="form-group col-md-12" style={{ padding: '0px' }}>
                                  <EnTextFormGroup
                                    icon={ _.get(contractorDraft, 'prefix', false) && 'fa fa-thumb-tack'}
                                    style={{ padding: '0px' }}
                                    type="text"
                                    containerClassName="col-md-12"
                                    label="เลขประจำตัวช่าง"
                                    value={contractor.ctrCode || ''}
                                    disabled
                                  />
                                </div>

                                <EnTextFormGroup
                                  icon={ _.get(contractorDraft, 'firstName', false) && 'fa fa-thumb-tack'}
                                  style={{ padding: '0px' }}
                                  type="text"
                                  containerClassName="col-md-12"
                                  label="คำนำหน้า *"
                                  value={contractor.prefix}
                                  disabled
                                  onChange={(event) => this.onChange('prefix', event)}
                                  onBlur={() => this.onBlur()}
                                />

                                <EnTextFormGroup
                                  style={{ padding: '0px' }}
                                  type="text"
                                  containerClassName="col-md-12"
                                  label="ชื่อ *"
                                  value={contractor.firstName}
                                  onChange={(event) => this.onChange('firstName', event)}
                                  onBlur={() => this.onBlur()}
                                  disabled={this.isDisabled('firstName')}
                                />

                                <EnTextFormGroup
                                  icon={ _.get(contractorDraft, 'lastName', false) && 'fa fa-thumb-tack'}
                                  style={{ padding: '0px' }}
                                  type="text"
                                  containerClassName="col-md-12"
                                  label="นามสกุล *"
                                  value={contractor.lastName}
                                  onChange={(event) => this.onChange('lastName', event)}
                                  onBlur={() => this.onBlur()}
                                  disabled={this.isDisabled('lastName')}
                                />
                              </div>
                            </div>

                            {/* <div className="row">
                              <EnTextFormGroup
                                type="text"
                                containerClassName="col-md-6"
                                label="หมายเลขบัตรประชาชน"
                                value={contractor.idCardNo}
                                onChange={(event) => this.onChange('idCardNo', event)}
                                onBlur={() => this.onBlur()}
                                disabled={!editMode || draftMode}
                                validations={[idcard]}
                              />

                              <div className="form-group col-md-6">
                                <label>วัน/เดือน/ปีเกิด *</label>
                                <EnDatePicker
                                  id="date_of_birth"
                                  containerClassName="col-md-6"
                                  initialDate={contractor.dateOfBirth ? moment(contractor.dateOfBirth) : undefined}
                                  onDateChange={(event) => this.onChange('dateOfBirth', event)}
                                  isOutsideRange={() => false}
                                  isBuddhistYear={false}
                                  disabled
                                />
                              </div>
                            </div> */}

                            <div className="row">
                              <EnTextFormGroup
                                icon={ _.get(contractorDraft, 'idCardNo', false) && 'fa fa-thumb-tack'}
                                type="text"
                                containerClassName="col-md-6"
                                label="หมายเลขบัตรประชาชน"
                                value={contractor.idCardNo}
                                onChange={(event) => this.onChange('idCardNo', event)}
                                onBlur={() => this.onBlur()}
                                disabled={this.isDisabled('idCardNo')}
                                validations={[idcard]}
                              />
                              <div className="form-group col-md-2">
                                <label>วันเกิด *</label>
                                {this.showPinIcon( _.get(contractorDraft, 'dateOfBirth', false) )}
                                <Select
                                  className="approve-tow-select"
                                  searchable={false}
                                  options={getDayOfBirthOptions(this.state.monthOfBirth, this.state.yearOfBirth)}
                                  value={this.state.dayOfBirth}
                                  onChange={(event) => this.onSelect('dateOfBirth', event.value)}
                                  disabled={this.isDisabled('dateOfBirth')}
                                />
                              </div>
                              <div className="form-group col-md-2">
                                <label>เดือนเกิด *</label>
                                {this.showPinIcon( _.get(contractorDraft, 'dateOfBirth', false) )}
                                <Select
                                  className="approve-tow-select"
                                  searchable={false}
                                  options={getMonthOfBirthOptions()}
                                  value={this.state.monthOfBirth}
                                  onChange={(event) => this.onSelect('monthOfBirth', event.value)}
                                  disabled={this.isDisabled('dateOfBirth')}
                                />
                              </div>
                              <div className="form-group col-md-2">
                                <label>ปีเกิด *</label>
                                {this.showPinIcon( _.get(contractorDraft, 'dayOfBirth', false) )}
                                <Select
                                  className="approve-tow-select"
                                  searchable={false}
                                  options={getYearOfBirthOptions()}
                                  value={this.state.yearOfBirth}
                                  onChange={(event) => this.onSelect('yearOfBirth', event.value)}
                                  disabled={this.isDisabled('dateOfBirth')}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="form-group col-md-6">
                                <label>สัญชาติ *</label>
                                {this.showPinIcon( _.get(contractorDraft, 'nationality', false) )}
                                <Select
                                  className="approve-tow-select"
                                  searchable={false}
                                  options={options.nationality}
                                  value={contractor.nationality}
                                  onChange={(event) => this.onSelect('nationality', event.value)}
                                  disabled={this.isDisabled('nationality')}
                                ></Select>
                              </div>

                              <EnTextFormGroup
                                icon={ _.get(contractorDraft, 'teamName', false) && 'fa fa-thumb-tack'}
                                type="text"
                                containerClassName="col-md-6"
                                label="ชื่อทีม/ชื่อบริษัท *"
                                value={contractor.teamName}
                                // onChange={(event) => this.onChange('teamName', event)}
                                // onBlur={() => this.onBlur()}
                                disabled
                              />
                            </div>

                            <div className="row">
                              <EnTextFormGroup
                                icon={ _.get(contractorDraft, 'phone', false) && 'fa fa-thumb-tack'}
                                type="text"
                                containerClassName="col-md-6"
                                label="เบอร์โทรศัพท์บัญชีผู้ใช้งาน *"
                                value={contractor.phone}
                                // onChange={(event) => this.onChange('phone', event)}
                                // onBlur={() => this.onBlur()}
                                disabled
                              />

                              <EnTextFormGroup
                                icon={ _.get(contractorDraft, 'contactPhoneNo', false) && 'fa fa-thumb-tack'}
                                type="text"
                                containerClassName="col-md-6"
                                label="เบอร์ติดต่อ"
                                value={contractor.contactPhoneNo}
                                onChange={(event) => this.onChange('contactPhoneNo', event)}
                                onBlur={() => this.onBlur()}
                                validations={[phone]}
                                disabled={this.isDisabled('contactPhoneNo')}
                              />
                            </div>

                            <div className="row">
                              <EnTextFormGroup
                                icon={ _.get(contractorDraft, 'carId', false) && 'fa fa-thumb-tack'}
                                type="text"
                                containerClassName="col-md-6"
                                label="ทะเบียนรถ"
                                value={contractor.carId}
                                onChange={(event) => this.onChange('carId', event)}
                                onBlur={() => this.onBlur()}
                                disabled={this.isDisabled('carId')}
                              />

                              <div className="form-group col-md-6">
                                <label>จังหวัดทะเบียนรถ</label>
                                {this.showPinIcon( _.get(contractorDraft, 'carIdProvince', false) || _.get(contractorDraft, 'carIdProvince.code', false) )}
                                <Select
                                  className="approve-tow-select"
                                  searchable={false}
                                  options={this.getProvinceOptions('selected', 'taxAddress')}
                                  value={contractor.carIdProvince ? contractor.carIdProvince.code : ''}
                                  onChange={(event) => this.onSelect('carIdProvince', event.value)}
                                  disabled={this.isDisabled('carIdProvince')}
                                ></Select>
                              </div>
                            </div>

                            <div className="row">
                              <div className="form-group col-md-6">
                                <label>สถานะการตรวจประวัติอาชญากรรม *</label>
                                {this.showPinIcon( _.get(contractorDraft, 'criminalRecordCheckStatus', false) )}  
                                <Select
                                  className="approve-tow-select"
                                  searchable={false}
                                  options={options.criminal_status.filter((item) => item.value !== '')}
                                  value={contractor.criminalRecordCheckStatus}
                                  onChange={(event) => this.onSelect('criminalRecordCheckStatus', event.value)}
                                  disabled={this.isDisabled('criminalRecordCheckStatus')}
                                ></Select>
                              </div>
                              <FormGroup label="ไซส์เสื้อ" containerClassName="col-md-6">

                                {this.showPinIcon( _.get(contractorDraft, 'shirtSize', false) )}
                                <Select
                                  searchable={false}
                                  clearable={true}
                                  style={{ height: '40px' }}
                                  placeholder="กรุณาเลือก"
                                  options={this.genShirtSizeOptions()}
                                  value={contractor.shirtSize || ''}
                                  onChange={(event) => this.onSelect('shirtSize', event.value)}
                                  disabled={this.isDisabled('shirtSize')}
                                />
                              </FormGroup>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <UploadFileV2
                                  label="รูปบัตรประชาชน *"
                                  selectedInfo={contractor}
                                  editMode={editMode}
                                  draftData={contractorDraft}
                                  hasDraft={this.hasDraftFileType(fileTypes.id_card)}
                                  isContractor
                                  fileType={fileTypes.id_card}
                                  onAddFile={(files, type) => this.onAddFile(files, type)}
                                  onDeleteFile={(file) => this.onDeleteFile(file)}
                                />
                              </div>
                              <div className="col-md-6">
                                <UploadFileV2
                                  label={`หลักฐานการตรวจประวัติอาชญากรรม ${contractor.criminalRecordCheckStatus === '0' ? '*' : ''}`}
                                  selectedInfo={contractor}
                                  editMode={editMode}
                                  draftData={contractorDraft}
                                  hasDraft={this.hasDraftFileType(fileTypes.criminal_record_check)}
                                  isContractor
                                  fileType={fileTypes.criminal_record_check}
                                  onAddFile={(files, type) => this.onAddFile(files, type)}
                                  onDeleteFile={(file) => this.onDeleteFile(file)}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <UploadFileV2
                                  label="หลักฐานงานไฟฟ้า"
                                  selectedInfo={contractor}
                                  editMode={editMode}
                                  draftData={contractorDraft}
                                  hasDraft={this.hasDraftFileType(fileTypes.electricity_certificate)}
                                  isContractor
                                  fileType={fileTypes.electricity_certificate}
                                  onAddFile={(files, type) => this.onAddFile(files, type)}
                                  onDeleteFile={(file) => this.onDeleteFile(file)}
                                />
                              </div>
                              <div className="col-md-6">
                                <UploadFileV2
                                  label="เอกสารอื่นๆ"
                                  selectedInfo={contractor}
                                  editMode={editMode}
                                  draftData={contractorDraft}
                                  hasDraft={this.hasDraftFileType(fileTypes.other_certificate)}
                                  isContractor
                                  fileType={fileTypes.other_certificate}
                                  onAddFile={(files, type) => this.onAddFile(files, type)}
                                  onDeleteFile={(file) => this.onDeleteFile(file)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 d-flex" style={{ justifyContent: 'space-between' }}>
                          <EnButton className="btn btn-default" type="button" onClick={() => this.onGoBack()}>
                            ย้อนกลับ
                          </EnButton>        

                          {
                            this.state.draftMode ? 
                            <div>
                              <EnButton 
                                className="btn btn-danger" 
                                type="submit" 
                                onClick={() => this.onRejectDraft(this.state.id)} 
                                // disabled={saveDisabled || !editMode}
                                disabled={this.state.disabledApproveRejectDraft}
                              >
                                ปฏิเสธการแก้ไข
                              </EnButton>
                              <EnButton 
                                className="btn btn-primary" 
                                type="submit" 
                                onClick={() => this.onApproveDraft(this.state.id)} 
                                // disabled={saveDisabled || !editMode}
                                disabled={this.state.disabledApproveRejectDraft}
                              >
                                อนุมัติการแก้ไข
                              </EnButton>
                            </div>
                            :
                            <EnButton className="btn btn-primary" type="submit" onClick={() => this.onSaveInfo()} disabled={saveDisabled || !editMode}>
                              บันทึก
                            </EnButton>
                          }

                        </div>
                      </div>
                    </Tab>

                    {/* NETSUITE */}
                    <Tab title="Netsuite">
                      <div className="card-content">
                        <div className="row">
                          <EnTextFormGroup
                            containerClassName="col-md-6"
                            disabled={!isAllow('EDIT_NETSUITE_CODE')}
                            errorMessage="กรุณากรอกตัวเลข และตัวหนังสือภาษาอังกฤษเท่านั้น"
                            isError={contractor.netsuiteCustomerCode && !isNetsuiteCustomerCodeValid}
                            label="Netsuite Customer Code *"
                            type="text"
                            value={contractor.netsuiteCustomerCode}
                            onChange={(event) => this.props.contractor.saveInfo('netsuiteCustomerCode', event.target.value.toUpperCase())}
                          />
                        </div>
                        <div className="row">
                          <EnTextFormGroup
                            containerClassName="col-md-6"
                            disabled={!isAllow('EDIT_NETSUITE_CODE')}
                            errorMessage="กรุณากรอกตัวเลขเท่านั้น"
                            isError={contractor.netsuiteInternalId && !isNetsuiteInternalIdValid}
                            label="Netsuite Internal ID *"
                            type="text"
                            value={contractor.netsuiteInternalId}
                            onChange={(event) => this.props.contractor.saveInfo('netsuiteInternalId', event.target.value)}
                          />
                        </div>
                        <div className="row">
                          <FormGroup containerClassName="col-md-6">
                            <EnButton
                              className="btn-info pull-right"
                              disabled={!isAllow('EDIT_NETSUITE_CODE') || !isNetsuiteCustomerCodeValid || !isNetsuiteInternalIdValid}
                              onClick={this.saveNetsuiteData}
                            >
                              <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
                              บันทึก
                            </EnButton>
                          </FormGroup>
                        </div>
                      </div>
                    </Tab>

                    {/* TYPE_OF_WORK */}
                    {
                      (isAllow('VIEW_WORK_TYPE') || isAllow('EDIT_WORK_TYPE')) ? (
                        <Tab title="ประเภทงาน">
                          <AdminTypeOfWorkContractorV2 id={this.state.id} />
                        </Tab>
                      ) : <></>
                    }

                    {/* AREA_OF_WORK */}
                    {
                      (isAllow('VIEW_AREA_OF_WORK') || isAllow('EDIT_AREA_OF_WORK')) ? (
                        <Tab title="พื้นที่รับงาน">
                          <AdminAreaOfWorkContractorV2 id={this.state.id} />
                        </Tab>
                      ) : <></>
                    }

                    {/* VIEW_CONTRACTOR_CALENDAR */}
                    {isAllow('VIEW_CONTRACTOR_CALENDAR') ? (
                      <Tab title="ปฏิทิน">
                        <ContractorCalendar changeQueryString={(props) => this.changeQueryString(props)} id={this.state.id} />
                      </Tab>
                    ) : (
                      <div></div>
                    )}

                    <Tab title="ตั้งค่าเกรด">
                      <SetUpGrade changeQueryString={(props) => this.changeQueryString(props)} id={this.state.id} />
                    </Tab>

                    {/* SHOW_VACCINE */}
                    {showVaccine ? (
                      <Tab title="Vaccine" disable>
                        <AdminVaccineApproove vaccine={contractor.vaccine} id={this.state.id} history={this.props.history} />
                      </Tab>
                    ) : (
                      <div></div>
                    )}

                    {/* VIEW_BLOCK_PAYMENT */}
                    {isAllow('VIEW_BLOCK_PAYMENT') && contractor.status === 'approved' ? (
                      <Tab title="ระงับการจ่ายเงิน">
                        <AdminBlockPaymentContractors id={this.state.id} history={this.props.history} />
                      </Tab>
                    ) : (
                      <div></div>
                    )}

                    {/* VIEW_PAUSE_MATCHING */}
                    {isAllow('VIEW_PAUSE_MATCHING') ? (
                      <Tab title="พักงานช่าง">
                        <AdminPauseMatchingContractor id={this.state.id} history={this.props.history} />
                      </Tab>
                    ) : (
                      <div></div>
                    )}
                  </EnTab>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('contractor', 'typeofwork', 'misc', 'bank', 'auth', 'shirt', 'contractorblockedpayment')(observer(TaxAddressV2));

import React from 'react';
import { FormControl, Typography, TextField } from '@mui/material';

const TextArea = ({
  label = '',
  value = '',
  disabled = false,
  required = false,
  placeholder = '',
  rows = 3,
  resizeAble = true,
  onChange,
}) => {
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');

  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormControl fullWidth>
      <Typography variant="body1" gutterBottom fontWeight={700}>
        {label || ''} {label && required && '*'}
      </Typography>
      <TextField
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        multiline
        minRows={rows}
        maxRows={rows}
        value={value}
        variant="outlined"
        fullWidth
        error={error}
        helperText={helperText}
        onChange={handleChange}
        sx={{
          '& textarea': {
            resize: resizeAble ? 'vertical' : 'none',
            overflow: 'auto',
          },
        }}
      />
    </FormControl>
  );
};

export default TextArea;

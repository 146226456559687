import React from 'react';
import swal from 'sweetalert2';
import _, { isNumber } from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import { FormValidation } from '../../../components/form/FormValidation';
import EnButton from '../../../components/form/EnButton';
import { EnTab } from '../../../components/form/EnTab';
import { Tab } from 'react-bootstrap';
import FormGroup from '../../../components/form/FormGroup';
import EnTagSelect from '../../../components/form/EnTagSelect';
import { config } from '../../../config';

import '../../../assets/css/enquill.css';
import './style/product.css';

import BasicInfoSection from './components/BasicInfoSection';
import SeoSection from './components/SeoSection';
import DescriptionSection from './components/DescriptionSection';
import DescriptionOtherSection from './components/DescriptionOtherSection';
import SellingPointSection from './components/SellingPointSection';
import InformationSection from './components/InformationSection';
import InformationWithTimerSection from './components/InformationWithTimerSection';
import ImageSection from './components/ImageSection';
import InstallmentSection from './components/InstallmentSection';
import SkuMappingSection from './components/SkuMappingSection';
import ContractorGradeSection from './components/ContractorGradeSection';
import BannerSection from './components/BannerSection';
import { toast } from 'react-toastify';

export class ProductPage extends React.Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    this.state = {
      id: id,
      isEdit: id && id.length,
      files: [],
      activeIndex: 0,
      skuCode: '',
      allTypeOfWorks: [],
      typeOfWorkItems: []
    };
  }

  async componentWillMount() {
    try {
      this.props.product.clearItems();
      this.props.typeofwork.getTypeOfWorks({ visible: true });
      this.props.sku.getSkuList();
      this.props.guarantee.getAllGuarantee();
      await this.props.channel.getAllChannel();
      if (this.state.isEdit) {
        this.props.product.clearInfo();
        await this.props.product.getProductForEdit(this.state.id);
        const product = this.props.product.toJS();
        const info = product.info;
        this.setState({
          skuCode: info.skuCode,
        });
      } else {
        this.props.product.clearInfo();
        this.props.product.info.channel = this.props.auth.getChannelName();
      }

      await this.loadTypeOfWork();

    } catch (err) {
      toast.error(err.message);
    }
  }

  validationTypeOfWork() {
    let check = this.state.typeOfWorkItems.map(d => {
      return !(_.isEmpty(d['value']) || d['value'][0] === '');
    });
    let isValid = _.indexOf(check, false);
    return isValid < 0;
  }

  isValid() {
    const product = this.props.product.toJS();
    const info = product.info;
    const isValidName = !_.isEmpty(info.name.th);
    const isValidProductCode = !_.isEmpty(info.skuCode);
    // const isValidTypeOfWork = +info.typeOfWork !== 0;
    const isValidTypeOfWork = info.typeOfWork !== '';
    const isValidPrice = +info.price >= 0;
    const isValidUnitPrice = +info.calculator.unitPrice > 0;
    const isValidUnit = !_.isEmpty(info.calculator.unit.th);
    const isValidDescription = !_.isEmpty(info.description);
    const isValidInstallment = !info.allowInstallment || +info.installmentPeriod > 0;
    const isValidLockDays = info.lockDays && +info.lockDays >= 0;
    const isValidSkuMapping = !_.isEmpty(info.skuMapping);
    let validImage;
    if (!_.isEmpty(info.images) || !_.isEmpty(this.state.files)) {
      validImage = true;
    } else {
      validImage = false;
    }

    const isValidInformations = info.informations.every(information => {
      return isNumber(information.seqNo) && !_.isEmpty(information.title);
    });

    // console.log(`isValidName: ${isValidName},
    // isValidTypeOfWork: ${isValidTypeOfWork},
    // isValidPrice: ${isValidPrice},
    // isValidUnitPrice: ${isValidUnitPrice},
    // isValidUnit: ${isValidUnit},
    // validImage: ${validImage},
    // isValidDescription: ${isValidDescription}`);
    return isValidName && isValidProductCode && isValidTypeOfWork && isValidPrice && isValidUnitPrice && isValidUnit && isValidDescription && validImage && isValidInstallment && isValidLockDays && isValidInformations && isValidSkuMapping;
  }

  async loadTypeOfWork() {
    try {
      //get mapTypeOfWorks
      const productInfo = this.props.product.toJS().info;
      const typeOfWorks = this.props.typeofwork.mapIdAndLabel();
      let mapTypeOfWorks = productInfo.mapTypeOfWorks;
      //check mapTypeOfWorks
      if (mapTypeOfWorks && mapTypeOfWorks.length) {
        this.props.misc.saveId('miscData.mapTypeOfWorks', mapTypeOfWorks);
      } else {
        this.props.misc.saveId('miscData.mapTypeOfWorks', typeOfWorks);
      }

      const misc = this.props.misc.toJS().miscData;

      // initial Setup Store From database
      if (productInfo && productInfo.typeOfWorkProductMapping && productInfo.typeOfWorkProductMapping.length) {
        misc.mapTypeOfWorks['selected_id'] = productInfo.typeOfWorkProductMapping;
      } else {
        misc.mapTypeOfWorks['selected_id'] = [];
      }

      this.setState({
        typeOfWorkItems: [misc.mapTypeOfWorks],
        allTypeOfWorks: misc.mapTypeOfWorks
      });

    } catch (error) {
      throw error;
    }
  }

  async onSubmit() {
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขสินค้า' : 'กรุณายืนยันการเพิ่มสินค้าใหม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const infoWithTimer = this.props.product.info.informationsWithTimer;
        const bannerWithTimer = this.props.product.info.bannerWithTimers;
        if (this.state.typeOfWorkItems[0] && this.state.typeOfWorkItems[0].selected_id) {
          const selectedType = this.state.typeOfWorkItems[0].selected_id
          const typeOfWorks = _.map(selectedType, type => {
            return parseInt(type);
          })
          this.props.product.saveInfo('typeOfWorkProductMapping', typeOfWorks);
        }

        let checkRepeatDate = this.validationsCheckRepeatDate(infoWithTimer, bannerWithTimer);
        let result = this.validationsUpdate(this.props.product.info.informations);
        if (result) {
          swal.showValidationMessage(`การบันทึกล้มเหลว เนื่องจากมีลำดับคอนเทนต์ซ้ำกัน`);
        } else if (checkRepeatDate) {
          swal.showValidationMessage(`การบันทึกล้มเหลว เนื่องจากมีวันอัพเดทและวันลบข้อมูลซ้ำกัน`);
        } else {
          try {
            await this.uploadAndSubmit();
            return true;
          } catch (error) {
            swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
          }
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      }).then((result) => {
        if (result.value) {
          setTimeout(() => {
            window.scrollTo(0, 0);
            window.location.reload();
          }, 500);
        } else {
          setTimeout(() => {
            window.scrollTo(0, 0);
            window.location.reload();
          }, 500);
        }
      });
      // this.props.product.clearInfo();

      // setTimeout(() => {
      //   this.onCancel();
      // }, 500);
    }
  }

  validationsUpdate(informations) {
    for (let i = 0; i < informations.length; i++) {
      let isDup = informations.filter((info) => Math.round(info.seqNo) === Math.round(informations[i].seqNo));
      if (isDup.length > 1) {
        return 1;
      }
    }
  }

  validationsCheckRepeatDate(informations, banner) {
    let InfoRepeatDate = informations.filter((findRepeat) => findRepeat.dateDelete && findRepeat.dateUpload && findRepeat.dateDelete === findRepeat.dateUpload);
    let bannerRepeatDate = banner.filter((findRepeat) => findRepeat.dateDelete && findRepeat.dateUpload && findRepeat.dateDelete === findRepeat.dateUpload);
    if (InfoRepeatDate.length > 0 || bannerRepeatDate.length > 0) {
      return 1;
    }
  }

  onCancel() {
    this.props.history.push(`${config.publicUrl}/qchang/product`);
  }

  async uploadAndSubmit() {
    if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
      return this.doUploadFiles(this.dropzone);
    } else {
      return this.submitForm();
    }
  }

  async doUploadFiles(dropzone) {
    return new Promise((resolve, reject) => {
      let files = dropzone.getFiles();
      for (let file of files) {
        if (file.status === 'error') {
          reject(new Error('ไม่สามารถอัพโหลดไฟล์ได้'));
        }
      }
      dropzone.upload(async () => {
        try {
          await this.submitForm();
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    });
  }

  async submitForm() {
    try {
      if (this.state.isEdit) {
        return this.props.product.updateProduct(this.state.id);
      } else {
        return this.props.product.createProduct();
      }
    } catch (error) {
      throw error;
    }
  }

  onSkuChanged(e) {
    this.props.product.saveInfo('skuCode', e.target.value);
  }

  onTypeOfWorkChange(selectedItem, e) {
    let typeOfWorkItems = _.cloneDeep(this.state.typeOfWorkItems);
    if (e.indexOf('ALL') >= 0) {
      const typeId = this.state.allTypeOfWorks.map(b => b.id);
      let item = typeOfWorkItems.find(i => i.key === selectedItem.key);
      item['selected_id'] = typeId;
    } else {
      let typeId = e.split(',');
      let item = typeOfWorkItems.find(i => i.key === selectedItem.key);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item['selected_id'] = typeId;
      }
    }
    this.setState({
      typeOfWorkItems,
    });

  }


  showTypeOfWorkSelectors() {
    let typeOfWorkOptions;
    let items = (this.state.typeOfWorkItems || []).map((item, index) => {
      typeOfWorkOptions = (item || []).map(d => {
        return ({
          label: d['label'],
          value: d['id'],
        });
      });

      if (this.state.allTypeOfWorks.length !== this.state.typeOfWorkItems[0].selected_id.length) {
        typeOfWorkOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...typeOfWorkOptions];
      }

      return (
        <div key={`item-${index}`}>
          <div>
            <FormGroup label="เลือกประเภทงาน *">
              <EnTagSelect
                closeOnSelect={false}
                options={typeOfWorkOptions}
                onTagChange={this.onTypeOfWorkChange.bind(this, item)}
                value={item['selected_id']}
                searchable
              />
            </FormGroup>
          </div>
        </div>
      );
    });
    return items;
  }

  render() {
    const product = this.props.product.toJS();
    const { info, isLoading } = product;
    const formTitle = this.state.isEdit ? 'แก้ไขสินค้า' : 'เพิ่มสินค้าใหม่';
    const { id } = this.state

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{formTitle}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <div className="card-content">
                  <EnTab index={this.state.activeIndex}>
                    <Tab title="ข้อมูลทั่วไป">
                      {this.state.isEdit && <h3>PRODUCT ID : {id}</h3>}
                      {this.state.isEdit && <h3>PRODUCT CODE : {this.state.skuCode}</h3>}
                      <FormValidation>
                        <BasicInfoSection info={info} />
                        <SeoSection seo={info.seo} />
                        <DescriptionSection info={info} />
                        <hr />
                        <DescriptionOtherSection descriptions={info.descriptions} />
                        <hr />
                        <SellingPointSection sellingPoints={info.sellingPoints} />
                        <hr />
                        <BannerSection info={info} />
                        <hr />
                        <InformationSection informations={info.informations} />
                        <hr />
                        <InformationWithTimerSection informationsWithTimer={info.informationsWithTimer} />
                        <hr />
                        <ImageSection images={info.images} />
                        <hr />
                        <InstallmentSection allowInstallment={info.allowInstallment} installmentPeriod={info.installmentPeriod} />
                        <SkuMappingSection skuMapping={info.skuMapping} />
                        <ContractorGradeSection contractorGrade={info.contractorGrade} />
                        <div className="row">
                          <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={this.onSubmit.bind(this)}>
                            <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
                            บันทึก
                          </EnButton>
                          <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                            <i className="fa fa-times btn-icon" aria-hidden="true" />
                            ยกเลิก
                          </EnButton>
                        </div>
                      </FormValidation>
                    </Tab>
                    <Tab title="ประเภทงาน Pay at Site">
                      <div className="card-content">
                        <div className="row">
                          <FormGroup containerClassName="col-md-12">
                            {this.showTypeOfWorkSelectors()}
                            <br />
                            <br />
                            <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={this.onSubmit.bind(this)}>
                              <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
                              บันทึก
                            </EnButton>
                          </FormGroup>
                        </div>
                      </div>
                    </Tab>
                  </EnTab>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'joblist', 'typeofwork', 'sku', 'product', 'guarantee', 'channel', 'misc')(observer(ProductPage));

import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import * as _ from 'lodash';
import { Buffer } from 'buffer';

import config from '../../../../config';
import infoCheck from '../../../../assets/img/icn_check.svg';
import infoWarning from '../../../../assets/img/icn_warning.svg';

import Loader from '../../../../components/common/Loader';
import { checkEmoji, FormValidation, maxLength, required, min } from '../../../../components/form/FormValidation';
import EnTextFormGroup from '../../../../components/form/EnTextFormGroup';
import EnButton from '../../../../components/form/EnButton';
import EnTextAreaV2 from '../../../../components/form/EnTextAreaV2';
import EnTextAreaEditorFormGroup from '../../../../components/form/EnTextAreaEditorFormGroup';
import { EnTagSelectValidation } from '../../../../components/form/EnTagSelect';

import WarrantyTemplateStore from '../../../../stores/WarrantyTemplateStore';

import history from '../../../../utils/history';
import { defaultConfirmOption } from '../../../../utils/common';
import { isRole, ROLE } from '../../../../utils/permission';

import SaleModelToggleButtonGroup from './SaleModelToggle';
import { PreviewPDF } from './PreviewPDF';
import { toast } from 'react-toastify';

export class WarrantyForm extends React.Component {
  constructor(props) {
    super(props);
    const formValues = {
      name: '',
      sale_model: 'marketplace',
      warranty_period: {
        days: '',
        months: '',
        years: '',
      },
      warrantyTerms: [],
      warrantyExclusions: [],
    };
    let mode = 'create';
    if (props.id && props.readOnly === true) {
      mode = 'view';
    } else if (props.id && props.addSubWarranty === true) {
      mode = 'add_sub_warranty';
    } else if (props.id) {
      mode = 'edit';
    }

    this.state = {
      isLoading: false,
      // create, add_sub_warranty, view, edit
      mode,
      config: {},
      pdf: '',
      showPreview: false,
      codeOptions: [],
      versionOptions: [],
      formValues,
      originalWarranty: {},
      originalFormValues: formValues,
    };
  }

  async componentWillMount() {
    await this.initialLoad();
  }

  async initialLoad() {
    if (this.props.id) {
      await this.fetchWarranty(this.props.id);
    } else {
      this.setState({
        isLoading: true,
      });
      const configResponse = await WarrantyTemplateStore.getWarrantyTemplateConfig();

      this.setState({
        config: configResponse && configResponse.warranty_template_config,
        isLoading: false,
      });
    }
  }

  async fetchWarranty(id) {
    let warranty = {};
    let warrantyTerms = [];
    let warrantyExclusions = [];
    this.setState({
      isLoading: true,
      formValues: {
        name: '',
        sale_model: 'marketplace',
        warranty_period: {
          days: '',
          months: '',
          years: '',
        },
        warrantyTerms: [],
        warrantyExclusions: [],
      },
    });
    const response = await WarrantyTemplateStore.get(id);

    warranty = {
      name: response.name,
      code: response.code,
      version: response.version,
      sale_model: 'marketplace',
      warrantyTerms,
      warrantyExclusions,
    };
    let warranty_period = {
      days: '0',
      months: '0',
      years: '0',
    };
    if (response.warranty_period) {
      const { days = 0, months = 0, years = 0 } = response.warranty_period;
      warranty_period = {
        days: String(days || ''),
        months: String(months || ''),
        years: String(years || ''),
      };
    }
    warranty.warranty_period = warranty_period;
    if (response.content && response.content.warranty_terms && response.content.warranty_exclusions) {
      warranty.warrantyTerms = response.content.warranty_terms.map((t, i) => ({ key: new Date().getTime() + i, value: t }));
      warranty.warrantyExclusions = response.content.warranty_exclusions.map((t, i) => ({ key: new Date().getTime() + i, value: t }));
    }

    let codeOptions = [];
    let versionOptions = [];
    if (response.related_templates) {
      const allCodeOptions = response.related_templates.map((t) => ({ label: t.code, value: t.code }));
      allCodeOptions.push({
        label: response.code,
        value: response.code,
      });
      allCodeOptions.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
      codeOptions = [...new Map(allCodeOptions.map((item) => [item['label'], item])).values()];

      versionOptions = response.related_templates
        .filter((t) => t.code === response.code)
        .map((t) => ({ label: t.version, value: t.version }))
        .sort((a, b) => {
          if (a.value > b.value) return -1;
          if (a.value < b.value) return 1;
          return 0;
        });
    }

    this.setState({
      config: response.template,
      isLoading: false,
      formValues: warranty,
      originalFormValues: warranty,
      codeOptions,
      versionOptions,
      originalWarranty: response,
    });
  }

  async onSubmit() {
    try {
      this.setState({ isLoading: true });
      const { formValues, mode } = this.state;

      const payload = {
        name: formValues.name,
        warranty_period: {
          days: Number(formValues.warranty_period.days || 0),
          months: Number(formValues.warranty_period.months || 0),
          years: Number(formValues.warranty_period.years || 0),
        },
        warranty_terms: formValues.warrantyTerms.map((t) => t.value),
        warranty_exclusions: formValues.warrantyExclusions.map((t) => t.value),
      };

      if (mode === 'edit') {
        payload['_id'] = this.state.originalWarranty._id;
      } else if (mode === 'add_sub_warranty') {
        payload['parent_code'] = this.state.originalWarranty.code;
      }

      const response = await WarrantyTemplateStore.create(payload);
      this.setState({ isLoading: false });

      toast.success('บันทึกข้อมูลสำเร็จแล้ว')
      history.push(`${config.publicUrl}/qchang/warranty/${response._id}`);
    } catch (error) {
      this.setState({ isLoading: false });
      toast.error(error.message || error || 'เกิดข้อผิดพลาด')
      return false;
    }
  }

  async onCancel() {
    // history.goBack();
    const path = `${config.publicUrl}/qchang/warranty`;
    if (this.isDirty()) {
      const result = await swal.fire({
        ...defaultConfirmOption({
          customClass: {
            title: 'primary-text-color',
          },
        }),
        title: 'ออกจากหน้านี้',
        html: 'หากคุณออกจากหน้านี้<br/> การเปลี่ยนแปลงข้อมูลของคุณจะถูกยกเลิก',
        imageWidth: 60,
        imageHeight: 60,
        width: 400,
        height: 300,
        showCancelButton: true,
        showConfirmButton: true,
        imageUrl: infoWarning,
        confirmButtonText: 'ออกและไม่บันทึก',
        cancelButtonText: 'อยู่ต่อ',
      });
      if (result.value) {
        history.push(path);
      }
    } else {
      history.push(path);
    }
  }

  onAddSubWarranty() {
    history.push(`${config.publicUrl}/qchang/warranty/${this.state.originalWarranty._id}/edit?add-sub=true`);
  }

  handleChangFieldForm(key, value) {
    const { originalWarranty } = this.state;
    if (key == 'code' || key == 'version') {
      const code = key == 'code' ? value : this.state.formValues.code;
      let version = 1;
      if (key == 'version') {
        version = value;
      } else {
        const versionOptions = originalWarranty.related_templates
          .filter((t) => t.code === code)
          .map((t) => ({ label: t.version, value: t.version }))
          .sort((a, b) => {
            if (a.value > b.value) return -1;
            if (a.value < b.value) return 1;
            return 0;
          });
        version = versionOptions[0].value;
      }

      const result = originalWarranty.related_templates.find((t) => t.code === code && t.version === version);
      if (result) {
        this.fetchWarranty(result._id);
      }
    }
    this.setState((prevState) => {
      const updatedFormValues = _.cloneDeep(prevState.formValues);
      _.set(updatedFormValues, key, value);

      return { formValues: updatedFormValues };
    });
  }

  onAddGuaranteeItems = () => {
    const warrantyTerms = _.cloneDeep(this.state.formValues.warrantyTerms);
    warrantyTerms.push({
      key: new Date().getTime(),
      value: '',
    });
    this.setState({
      formValues: {
        ...this.state.formValues,
        warrantyTerms,
      },
    });
  };

  onAddNotGuaranteeItems = () => {
    const warrantyExclusions = _.cloneDeep(this.state.formValues.warrantyExclusions);
    warrantyExclusions.push({
      key: new Date().getTime(),
      value: '',
    });
    this.setState({
      formValues: {
        ...this.state.formValues,
        warrantyExclusions,
      },
    });
  };

  isValid = () => {
    const { formValues: info, mode } = this.state;
    const { days, months, years } = info.warranty_period;

    if (mode == 'view') return true;
    const requiredFields = [
      required(info.name),
      checkEmoji(info.name),
      maxLength(150)(info.name),
      maxLength(3)(days),
      min(0)(days),
      maxLength(2)(months),
      min(0)(months),
      maxLength(2)(years),
      min(0)(years),
    ];

    // at least one field inside warranty_period is not empty and more than 0
    if (!Number(days) && !Number(months) && !Number(years)) {
      requiredFields.push(true);
    }

    if (info.warrantyTerms.length) {
      info.warrantyTerms.forEach((item) => {
        requiredFields.push(required(item.value));
        requiredFields.push(checkEmoji(item.value));
      });
    }
    if (info.warrantyExclusions.length) {
      info.warrantyExclusions.forEach((item) => {
        requiredFields.push(required(item.value));
        requiredFields.push(checkEmoji(item.value));
      });
    }

    if ((mode === 'edit' || mode === 'add_sub_warranty') && !this.isDirty()) {
      requiredFields.push(true);
    }

    return !requiredFields.some((field) => field);
  };

  isDirty() {
    const { formValues: info, originalFormValues } = this.state;
    function isEqual(value1, value2) {
      if (_.isObject(value1) && _.isObject(value2)) {
        const keys1 = Object.keys(value1);
        const keys2 = Object.keys(value2);

        if (keys1.length !== keys2.length) {
          return false;
        }

        return keys1.every((key) => isEqual(value1[key], value2[key]));
      }

      return value1 === value2;
    }

    const excludes = ['sale_model'];
    return Object.keys(info).some((key) => !isEqual(info[key], originalFormValues[key]) && !excludes.includes(key));
  }

  onTermsChange = (range, e) => {
    const warrantyTerms = _.cloneDeep(this.state.formValues.warrantyTerms);
    if (e && e.target) {
      let item = (warrantyTerms || []).filter((r) => {
        if (r.key === range.key) {
          r.value = e.target.value;
        }
        return r;
      });
      this.setState({
        formValues: {
          ...this.state.formValues,
          warrantyTerms,
        },
      });
    }
  };

  onExclusionsChange = (range, e) => {
    const warrantyExclusions = _.cloneDeep(this.state.formValues.warrantyExclusions);
    if (e && e.target) {
      let item = (warrantyExclusions || []).filter((r) => {
        if (r.key === range.key) {
          r.value = e.target.value;
        }
        return r;
      });
      this.setState({
        formValues: {
          ...this.state.formValues,
          warrantyExclusions,
        },
      });
    }
  };

  deleteItem(selectedItem, e, stateKey) {
    if (e && e.target) {
      let items = [...this.state.formValues[stateKey]];
      let filteredItems = items.filter((r) => r.key !== selectedItem.key);
      this.setState({
        formValues: {
          ...this.state.formValues,
          [stateKey]: filteredItems,
        },
      });
    }
  }

  deleteGuarantee(selectedItem, e) {
    this.deleteItem(selectedItem, e, 'warrantyTerms');
  }

  deleteNotGuarantee(selectedItem, e) {
    this.deleteItem(selectedItem, e, 'warrantyExclusions');
  }

  renderRow(info, onChangeFunc, deleteFunc) {
    const readOnly = this.state.mode == 'view';
    return info.map((r, index) => {
      const inputClass = info.length > 1 ? 'col-md-10 col-xs-8' : 'col-md-11 col-xs-9';
      let value = r.value;
      if (!r.key) {
        const redText = r.replace(/\{([^}]+)\}/g, (_, content) => `<span style="color: red;">${content}</span>`);
        value = redText;
      }

      function onChange(e) {
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';

        onChangeFunc(r, e);
      }

      return (
        <div key={r.key || index}>
          <div className="row">
            <div style={{ marginTop: '8px' }} className="col-md-1 col-xs-1">
              {index + 1}
            </div>
            <div className={inputClass} style={{ marginTop: '8px' }}>
              {r.key ? (
                <EnTextAreaV2
                  style={{
                    resize: 'none',
                    overflow: 'hidden',
                  }}
                  rows="1"
                  placeholder="กรุณาระบุ"
                  value={r.value}
                  onChange={onChange}
                  disabled={readOnly}
                  autoHeight={true}
                  validations={[required, checkEmoji]}
                />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: value }}></div>
              )}
            </div>
            {info.length > 1 && r.key && !readOnly && (
              <EnButton className="col-xs-1 col-md-1 btn-danger" onClick={deleteFunc.bind(this, r)} style={{ margin: '16px 0 0 0' }}>
                <i className="fa fa-trash" aria-hidden="true" />
              </EnButton>
            )}
          </div>
        </div>
      );
    });
  }

  getTitle() {
    let title = 'สร้างใบรับประกันใหม่';
    if (this.state.mode === 'view') {
      title = 'ดูข้อมูลใบรับประกัน';
    }

    return title;
  }

  beforeSubmit = async () => {
    const result = await swal.fire({
      title: 'ยืนยันการบันทึกข้อมูล',
      html: 'คุณต้องการบันทึกข้อมูลหรือไม่?',
      imageWidth: 60,
      imageHeight: 60,
      width: 400,
      height: 400,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      ...defaultConfirmOption({
        customClass: {
          title: 'primary-text-color',
        },
      }),
    });

    if (!result.value) return;
    await this.onSubmit();
  };

  getWarrantyList(saleModel) {
    const { formValues, config } = this.state;
    if (!config || !config[formValues.sale_model]) return { terms: [], exclusions: [] };
    const { warranty_terms, warranty_exclusions } = config[saleModel || formValues.sale_model];

    return {
      terms: [...warranty_terms.header, ...formValues.warrantyTerms, ...warranty_terms.footer],
      exclusions: [...warranty_exclusions.header, ...formValues.warrantyExclusions],
    };
  }

  getBlobUrl(base64) {
    const binaryData = Buffer.from(base64, 'base64').toString('binary');
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }

  async previewPDF() {
    const { formValues } = this.state;
    const marketplaceList = this.getWarrantyList('marketplace');
    const retailList = this.getWarrantyList('retail');

    let payload = {
      name: formValues.name,
      warranty_period: {
        days: Number(formValues.warranty_period.days || 0),
        months: Number(formValues.warranty_period.months || 0),
        years: Number(formValues.warranty_period.years || 0),
      },
    };

    this.setState({ isLoading: true });
    const marketplace = await WarrantyTemplateStore.preview({
      ...payload,
      sale_model: 'marketplace',
      warranty_terms: marketplaceList.terms.map((t) => (t.key ? t.value : t)),
      warranty_exclusions: marketplaceList.exclusions.map((t) => (t.key ? t.value : t)),
    });
    const retail = await WarrantyTemplateStore.preview({
      ...payload,
      sale_model: 'retail',
      warranty_terms: retailList.terms.map((t) => (t.key ? t.value : t)),
      warranty_exclusions: retailList.exclusions.map((t) => (t.key ? t.value : t)),
    });

    this.setState({
      showPreview: true,
      isLoading: false,
      pdf: {
        marketplace: this.getBlobUrl(marketplace),
        retail: this.getBlobUrl(retail),
      },
    });
  }

  render() {
    const { formValues, showPreview, pdf, originalWarranty, codeOptions, versionOptions, isLoading, mode } = this.state;
    const readOnly = mode === 'view';
    const isEdit = mode === 'edit';

    const warranties = this.getWarrantyList.bind(this)();
    const guarantee = this.renderRow(warranties.terms, this.onTermsChange, this.deleteGuarantee);
    const notGuarantee = this.renderRow(warranties.exclusions, this.onExclusionsChange, this.deleteNotGuarantee);
    return (
      <div className="row">
        <PreviewPDF
          show={showPreview}
          onClose={() => this.setState({ showPreview: false })}
          pdf={pdf}
          formValues={formValues}
          handleChangFieldForm={this.handleChangFieldForm.bind(this)}
        />
        <Loader show={isLoading} />
        <div className="col-md-12">
          <div className="card">
            <div className="card-header" data-background-color="orange">
              <h4 className="title">{this.getTitle()}</h4>
            </div>
            <div className="card-content">
              <div className="card-content">
                <FormValidation>
                  {readOnly || isEdit ? (
                    <div
                      style={{
                        backgroundColor: '#fdead6',
                        borderRadius: '8px',
                        padding: '16px 16px',
                        marginBottom: '12px',
                      }}
                    >
                      <div className="row" style={{ height: '100%' }}>
                        <div className="col-md-3" style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                          <span>รหัส</span>
                          <div style={{ width: '100%' }}>
                            <EnTagSelectValidation
                              placeholder="กรุณาเลือก"
                              closeOnSelect={true}
                              multi={false}
                              searchable
                              options={codeOptions}
                              value={formValues['code']}
                              onTagChange={(e) => this.handleChangFieldForm('code', e)}
                              validations={[required]}
                            />
                          </div>
                        </div>
                        <div className="col-md-2" style={{ display: 'flex', alignItems: 'center', gap: '20px', height: '58px' }}>
                          <span>วันที่เริ่มต้น</span>
                          <span style={{ fontWeight: 'bold' }}>
                            {originalWarranty.started_date && moment(originalWarranty.started_date).add(543, 'year').format('DD MMM YYYY')}
                          </span>
                        </div>
                        <div className="col-md-2" style={{ display: 'flex', alignItems: 'center', gap: '20px', height: '58px' }}>
                          {originalWarranty.ended_date ? (
                            <>
                              <span>วันที่สิ้นสุด</span>
                              <span style={{ fontWeight: 'bold' }}>
                                {originalWarranty.ended_date && moment(originalWarranty.ended_date).add(543, 'year').format('DD MMM YYYY')}
                              </span>
                            </>
                          ) : null}
                        </div>
                        <div className="col-md-3" style={{ display: 'flex', alignItems: 'center', gap: '20px', height: '58px' }}>
                          สร้างโดย <span style={{ fontWeight: 'bold' }}> {originalWarranty.created_by}</span>
                        </div>
                        <div
                          className="col-md-2"
                          style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '20px', height: '58px' }}
                        >
                          <span>เวอร์ชัน</span>
                          <div style={{ width: '100%' }}>
                            {isEdit ? (
                              formValues['version']
                            ) : (
                              <EnTagSelectValidation
                                placeholder="กรุณาเลือก"
                                closeOnSelect={true}
                                multi={false}
                                searchable
                                options={versionOptions}
                                value={formValues['version']}
                                onTagChange={(e) => this.handleChangFieldForm('version', e)}
                                validations={[required]}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-md-6">
                      <span>
                        ชื่อใบรับประกัน <span className="text-danger">*</span>
                      </span>
                      <div style={{ marginTop: '6px' }}>
                        <EnTextAreaEditorFormGroup
                          maxLength={150}
                          showMaxLength
                          value={formValues['name']}
                          onChange={(e) => this.handleChangFieldForm('name', e.target.value)}
                          validations={[required, checkEmoji, maxLength(150)]}
                          placeholder="กรุณาระบุ"
                          disabled={readOnly}
                        />
                      </div>
                    </div>

                    <span>
                      จำนวนวันที่รับประกัน <span className="text-danger">*</span>
                    </span>
                    <div className="col-md-6 row" style={{ paddingRight: 0 }}>
                      <EnTextFormGroup
                        containerClassName="col-md-4"
                        className="no-arrow"
                        type="number"
                        noDecimals
                        value={formValues.warranty_period['years']}
                        suffix="ปี"
                        onChange={(e) => this.handleChangFieldForm('warranty_period.years', e.target.value)}
                        validations={[maxLength(2), min(0)]}
                        placeholder="0"
                        disabled={readOnly}
                      />
                      <EnTextFormGroup
                        containerClassName="col-md-4"
                        className="no-arrow"
                        type="number"
                        noDecimals
                        value={formValues.warranty_period['months']}
                        suffix="เดือน"
                        onChange={(e) => this.handleChangFieldForm('warranty_period.months', e.target.value)}
                        validations={[maxLength(2), min(0)]}
                        placeholder="0"
                        disabled={readOnly}
                      />
                      <EnTextFormGroup
                        containerClassName="col-md-4"
                        className="no-arrow"
                        type="number"
                        noDecimals
                        style={{ paddingRight: 0 }}
                        value={formValues.warranty_period['days']}
                        suffix="วัน"
                        onChange={(e) => this.handleChangFieldForm('warranty_period.days', e.target.value)}
                        validations={[maxLength(3), min(0)]}
                        placeholder="0"
                        disabled={readOnly}
                      />
                    </div>
                  </div>
                  <div
                    className="header-duration"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 8px', minHeight: '66px' }}
                  >
                    เงื่อนไขและขอบเขตการรับประกัน
                    {!readOnly ? (
                      <EnButton className="btn-success pull-right" onClick={this.onAddGuaranteeItems.bind(this, formValues.warrantyTerms)}>
                        <i className="fa fa-plus btn-icon" aria-hidden="true" />
                        เพิ่ม
                      </EnButton>
                    ) : null}
                  </div>
                  <SaleModelToggleButtonGroup formValues={formValues} handleChangFieldForm={this.handleChangFieldForm.bind(this)} />
                  {guarantee}
                  <br />
                  <div
                    className="header-duration"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 8px', minHeight: '66px' }}
                  >
                    ขอสงวนสิทธิ์ไม่รับประกันในกรณีต่อไปนี้
                    {!readOnly ? (
                      <EnButton className="btn-success pull-right" onClick={this.onAddNotGuaranteeItems.bind(this, formValues.warrantyExclusions)}>
                        <i className="fa fa-plus btn-icon" aria-hidden="true" />
                        เพิ่ม
                      </EnButton>
                    ) : null}
                  </div>
                  <SaleModelToggleButtonGroup formValues={formValues} handleChangFieldForm={this.handleChangFieldForm.bind(this)} />
                  {notGuarantee}
                  <br />
                  <div className="row">
                    {!originalWarranty.parent_code && readOnly && isRole(ROLE.INSTALLER_SUPER_ADMIN) ? (
                      <EnButton className="btn-success" onClick={this.onAddSubWarranty.bind(this)}>
                        + Sub warranty
                      </EnButton>
                    ) : !readOnly ? (
                      <EnButton className="btn-danger" onClick={this.onCancel.bind(this)}>
                        ยกเลิก
                      </EnButton>
                    ) : null}

                    {readOnly ? (
                      <EnButton
                        className="btn-info pull-right"
                        style={{
                          backgroundColor: 'var(--text-info-color)',
                          color: 'white',
                        }}
                        onClick={this.onCancel.bind(this)}
                      >
                        กลับ
                      </EnButton>
                    ) : (
                      <EnButton
                        className="btn-info pull-right"
                        style={{
                          backgroundColor: 'var(--text-info-color)',
                          color: 'white',
                        }}
                        disabled={!this.isValid()}
                        onClick={() => this.beforeSubmit(false)}
                      >
                        บันทึก
                      </EnButton>
                    )}
                    <EnButton
                      className="btn-primary pull-right"
                      style={{
                        background: 'none',
                        boxShadow: 'none',
                        color: 'var(--text-info-color)',
                        border: '1px solid var(--text-info-color)',
                      }}
                      onClick={this.previewPDF.bind(this)}
                    >
                      <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />
                      ดูตัวอย่าง
                    </EnButton>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject()(observer(WarrantyForm));

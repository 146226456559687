import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import EnButton from '../../components/form/EnButton';
import { checkEmoji, FormValidation, maxLength, required } from '../../components/form/FormValidation';
import { EnDatePickerValidation } from '../../components/form/EnDatePicker';
import FormGroup from '../../components/form/FormGroup';
import EnDropDown from '../../components/form/EnDropDown';
import moment from 'moment';
import _ from 'lodash';
import config from '../../config';
import swal from 'sweetalert2';
import QocBroadcastStore from '../../stores/QocBroadcastStore';
import { broadcastRecipientType } from '../../utils/data';
import '../../assets/css/iphone.css';
import { EnFileInputValidation } from '../../components/form/EnFileInput';
import EnTextAreaEditorFormGroup from '../../components/form/EnTextAreaEditorFormGroup';
import { EnTagSelectValidation } from '../../components/form/EnTagSelect';
import IphoneBroadcastPreview from './components/IphoneBroadcastPreview';
import { defaultConfirmOption } from '../../utils/common';
import infoWarning from '../../assets/img/icn_warning.svg';
import infoCheck from '../../assets/img/icn_check.svg';
import { isCampaignManager } from '../../utils/permission';
import { toast } from 'react-toastify';

class BroadcastCreatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEdit: false,
      formValues: {
        name: '',
        title: '',
        detail: '',
        destinationID: '',
        couponGroupID: '',
        destination: null,
        recipientType: '',
        publishType: 'NOW',
      },
      originalFormValues: {
        name: '',
        title: '',
        detail: '',
        destinationID: '',
        couponGroupID: '',
        destination: null,
        recipientType: '',
        publishType: 'NOW',
      },
      selectOptions: {
        destinationOptions: [],
        destinationDetailOptions: [],
        argumentOptions: [],
        couponGroupOptions: [],
      },
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  async componentWillMount() {
    const id = this.props.match.params.id;
    await this.defaultSelectOption();
    if (id) {
      this.setState({ isEdit: true, isLoading: true });
      await this.fetchBroadcast(id);
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.formValues.recipientType !== this.state.formValues.recipientType && this.state.formValues.recipientType === 'COUPON_GROUP') {
      this.fetchCouponGroupOptions();
    }

    if (this.hasDestinationDetail() && prevState.formValues.destinationID !== this.state.formValues.destinationID) {
      this.fetchDestinationDetailOptions();
    }
  }

  async fetchBroadcast(id) {
    const data = await QocBroadcastStore.get(id);
    const argumentOptions = this.state.selectOptions.argumentOptions;

    let titleArguments = data.titleArgumentIDs ? data.titleArgumentIDs.map((id) => argumentOptions.find((arg) => arg.id === id)) : [];
    let detailArguments = data.detailArgumentIDs ? data.detailArgumentIDs.map((id) => argumentOptions.find((arg) => arg.id === id)) : [];

    titleArguments = titleArguments.filter((arg) => arg);
    detailArguments = detailArguments.filter((arg) => arg);
  
    const titleArgumentContent = titleArguments.map((arg) => this.getArgumentElement(arg));
    const detailArgumentContent = detailArguments.map((arg) => this.getArgumentElement(arg));

    if (titleArgumentContent.length) {
      data.title = data.title.replace(/{{}}/g, () => titleArgumentContent.shift() || '');
    }
    if (detailArgumentContent.length) {
      data.detail = data.detail.replace(/{{}}/g, () => detailArgumentContent.shift() || '');
    }

    const formValues = {
      name: data.name,
      title: data.title,
      detail: data.detail,
      destinationID: data.destinationID,
      destination: data.destination,
      couponGroupID: data.couponGroupID,
      recipientType: data.recipientType,
      publishType: data.publishType,
      publishedAt: data.publishedAt,
      publishTime: moment(data.publishedAt).format('HH:mm'),
      titleArgumentIDs: data.titleArgumentIDs,
      detailArgumentIDs: data.detailArgumentIDs,
      isPublish: data.isPublish,
      isActive: data.isActive,
      receiverUrl: data.receiverUrl,
      thumbnailUrl: data.thumbnailUrl,
    };

    this.setState({
      formValues: formValues,
      originalFormValues: formValues,
    });
  }

  handleDocumentClick = (event) => {
    if (event.target.classList.contains('remove-badge') && !this.state.formValues.isPublish && isCampaignManager()) {
      this.handleRemoveBadgeClick(event);
    }
  };

  resetFormValueAndSelectOption() {
    this.setState({
      formValues: {
        name: '',
        title: '',
        detail: '',
        destinationID: '',
        recipientType: '',
        publishType: 'NOW',
      },
      selectOptions: {
        destinationOptions: [],
        destinationDetailOptions: [],
        argumentOptions: [],
        couponGroupOptions: [],
      },
    });
  }

  handleRemoveBadgeClick = (event) => {
    // Find the parent div of the clicked "x"
    const badgeDiv = event.target.closest('.argument-badge');

    if (badgeDiv) {
      badgeDiv.remove();
      //  Get the current title from the state
      let title = this.state.formValues.title;

      //  Create a temporary DOM element to manipulate the HTML string
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = title;

      // Update the state with the new title
      this.setState({
        formValues: {
          ...this.state.formValues,
          title: tempDiv.innerHTML,
        },
      });
    }
  };

  async fetchCouponGroupOptions() {
    const data = await QocBroadcastStore.getCouponGroupArguments();
    const groups = data.couponsGroups.map((item) => ({ value: item.id, label: item.name }));
    this.setState({ selectOptions: { ...this.state.selectOptions, couponGroupOptions: groups } });
  }

  async fetchDestinationDetailOptions() {
    let options = [];

    const result =
      this.state.selectOptions.destinationOptions &&
      this.state.selectOptions.destinationOptions.find((item) => item.value === this.state.formValues.destinationID);

    if (result.key === 'PROMOTION_DETAIL') {
      const data = await QocBroadcastStore.getPromotions();
      options = data.promotions.map((item) => ({ value: item.id, label: item.name }));
    } else if (result.key === 'PRODUCT_LIST') {
      const data = await QocBroadcastStore.getCategoryViews();
      options = data.categories.map((item) => ({ value: item.id, label: item.name }));
    } else if (result.key === 'PRODUCT_DETAIL') {
      const data = await QocBroadcastStore.getProducts();
      options = data.product.map((item) => ({ value: item.id, label: item.name }));
    }

    this.setState({ selectOptions: { ...this.state.selectOptions, destinationDetailOptions: options } });
  }

  async defaultSelectOption() {
    this.setState({ isLoading: true });

    const data = await QocBroadcastStore.getDesignations();
    const argData = await QocBroadcastStore.getArguments();

    const destinationOptions = data.results.map((item) => ({ value: item.id, label: item.label, key: item.value }));
    const argumentOptions = argData.results;
    this.setState({ isLoading: false, selectOptions: { destinationOptions, argumentOptions } });
  }

  handleChangFieldForm(key, value) {
    let newState = { ...this.state.formValues, [key]: value };
    if (key == 'publishedAt') {
      const availableTime = this.getFirstAvailableTime(value);
      if (availableTime) {
        newState.publishTime = availableTime;
      }
    }

    if (key == 'recipientType' && this.state.formValues.recipientType && this.state.formValues.recipientType !== value) {
      newState.title = '';
      newState.detail = '';
      newState.destinationID = '';
      newState.couponGroupID = '';
      newState.receiverFile = null;
      newState.destination = null;
    }

    if (key == 'thumbnailFile') {
      newState.thumbnailUrl = value ? URL.createObjectURL(value) : '';
    }

    if (key == 'destinationID') {
      newState.destination = null;
    }

    this.setState({ formValues: newState });
  }

  getArgumentElement(arg) {
    return `<div class="argument-badge" key="${arg.id}">${arg.label} <span class="remove-badge">x</span></div>&nbsp;`;
  }

  handleArgumentSelect(arg) {
    if (this.state.formValues.isPublish) return null;
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      let node = range.startContainer;
      const element = this.getArgumentElement(arg);

      if (node.nodeName !== 'DIV' && node.parentNode.contentEditable == 'true' && node.parentNode.classList.contains('arg-addable')) {
        const parentNode = node.parentNode;
        node.parentNode.innerHTML = node.parentNode.innerHTML + element;

        setTimeout(() => {
          if (parentNode) {
            // Ensure the element is focusable
            parentNode.setAttribute('tabindex', '-1');

            // Focus the node
            parentNode.focus();

            // Move the cursor to the end of the content
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(parentNode);
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }, 50);
      } else if (node.contentEditable == 'true' && node.classList.contains('arg-addable')) {
        node.innerHTML = node.innerHTML + element;
        setTimeout(() => {
          // Ensure the element is focusable
          node.setAttribute('tabindex', '-1');

          // Focus the node
          node.focus();

          // Move the cursor to the end of the content
          const range = document.createRange();
          const selection = window.getSelection();
          range.selectNodeContents(node);
          range.collapse(false);
          selection.removeAllRanges();
          selection.addRange(range);
        }, 50);
      }
    }
  }

  isFormValid() {
    const { formValues: info } = this.state;
    const requiredFields = [
      required(info.name),
      required(info.title),
      required(info.detail),
      required(info.destinationID),
      required(info.recipientType),
      required(info.publishType),
      maxLength(150)(info.name),
      maxLength(45)(info.title),
      maxLength(90)(info.detail),
    ];

    if (info.publishType === 'SCHEDULE') {
      requiredFields.push(required(info.publishedAt), required(info.publishTime));
    }

    if (info.recipientType === 'COUPON_GROUP') {
      requiredFields.push(required(info.couponGroupID));
    }

    if (info.recipientType === 'CUSTOMERS' && !this.state.isEdit) {
      requiredFields.push(required(info.receiverFile));
    }

    if (this.hasDestinationDetail()) {
      requiredFields.push(required(info.destination));
    }

    return !requiredFields.some((field) => field);
  }

  async handleSubmit() {
    try {
      const result = await swal.fire({
        title: 'ยืนยันการบันทึกข้อมูล',
        html: 'คุณต้องการบันทึกข้อมูลหรือไม่?',
        imageWidth: 60,
        imageHeight: 60,
        width: 400,
        height: 400,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        ...defaultConfirmOption(),
      });
      if (!result.value) return;
      const { formValues, isEdit } = this.state;
      this.frm.isValid();
      const isFormValid = this.isFormValid();

      if (!isFormValid) {
        swal.fire({
          icon: 'error',
          title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
          confirmButtonText: 'ตกลง',
        });
        return;
      }

      const removeTag = /<[^>]*>[^<]*<\/[^>]*>/g;
      const removeExceptContent = /<[^>]*>/g;
      const removeDynamicField = /<div.*?<\/div>/g;
      const title = formValues.title
        .replace(removeDynamicField, '{{}}')
        .replace(/&nbsp;/g, '')
        .replace(removeExceptContent, '')
        .replace(removeTag, '');
      const detail = formValues.detail
        .replace(removeDynamicField, '{{}}')
        .replace(/&nbsp;/g, '')
        .replace(removeExceptContent, '')
        .replace(removeTag, '');
      const titleArgumentIDs = formValues.title.match(/(?<=<div.*?key=")(.*?)(?=">)/g);
      const detailArgumentIDs = formValues.detail.match(/(?<=<div.*?key=")(.*?)(?=">)/g);

      let payload = {
        name: formValues.name,
        recipientType: formValues.recipientType,
        title,
        detail,
        destinationID: formValues.destinationID,
        titleArgumentIDs,
        detailArgumentIDs,
        publishType: formValues.publishType,
      };

      if (formValues.publishType == 'SCHEDULE') {
        const publishedAtDate = new Date(formValues.publishedAt);
        const [hours, minutes] = formValues.publishTime.split(':').map(Number);
        publishedAtDate.setHours(hours);
        publishedAtDate.setMinutes(minutes);
        payload.publishedAt = publishedAtDate.toISOString();
        payload.isActive = false;
      } else if (formValues.publishType == 'NOW') {
        payload.isActive = true;
      }

      if (formValues.recipientType === 'COUPON_GROUP') {
        payload.couponGroupID = formValues.couponGroupID;
      }

      if (this.hasDestinationDetail()) {
        payload.destination = formValues.destination;
      }

      // Upload thumbnail
      if (formValues.thumbnailFile) {
        const response = await QocBroadcastStore.uploadThumbnail(formValues.thumbnailFile);
        payload.thumbnailUrl = response.path;
      }

      if (formValues.receiverFile && formValues.recipientType === 'CUSTOMERS') {
        const response = await QocBroadcastStore.uploadReceivers(formValues.receiverFile);
        payload.receiverUrl = response.path;
      }

      if (isEdit) {
        const id = this.props.match.params.id;
        await QocBroadcastStore.update(id, payload);
      } else {
        await QocBroadcastStore.create(payload);
      }

      const successTitle = isEdit ? 'บันทึกข้อมูลสำเร็จแล้ว' : 'สร้างประกาศใหม่สำเร็จแล้ว';
      toast.success(successTitle)
      if (result.value) {
        this.props.history.push(`${config.publicUrl}/superadmin/qoc-broadcasts`);
      }
    } catch (error) {
      toast.error(error.message || 'เกิดข้อผิดพลาด');
    }
  }

  async onCancel() {
    const { formValues } = this.state;
    const initialFormValues = this.state.originalFormValues;
    const isDirty = Object.keys(formValues).some((key) => formValues[key] !== initialFormValues[key]);
    if (isDirty) {
      const result = await swal.fire({
        ...defaultConfirmOption(),
        title: 'ออกจากหน้านี้',
        html: 'หากคุณออกจากหน้านี้ การเปลี่ยนแปลงข้อมูลของคุณจะถูกยกเลิก',
        imageWidth: 60,
        imageHeight: 60,
        width: 400,
        height: 300,
        showCancelButton: true,
        showConfirmButton: true,
        imageUrl: infoWarning,
        confirmButtonText: 'ออกและไม่บันทึก',
        cancelButtonText: 'อยู่ต่อ',
      });
      if (result.value) {
        this.props.history.push(`${config.publicUrl}/superadmin/qoc-broadcasts`);
      }
    } else {
      this.resetFormValueAndSelectOption();
      this.props.history.push(`${config.publicUrl}/superadmin/qoc-broadcasts`);
    }
  }

  timePickerOption() {
    const { formValues } = this.state;
    const currentDate = new Date().getDate();
    const selectedDate = new Date(formValues.publishedAt).getDate();
    const minCurrent = new Date().getMinutes();
    const hourCurrent = minCurrent > 45 ? new Date().getHours() + 2 : new Date().getHours() + 1;
    const timeOption = _.range(0, 24).map((number) =>
      currentDate === selectedDate && hourCurrent > number ? (
        <option disabled value={moment({ hour: number }).format('HH:mm')}>
          {moment({ hour: number }).format('HH:mm')}
        </option>
      ) : (
        <option value={moment({ hour: number }).format('HH:mm')}>{moment({ hour: number }).format('HH:mm')}</option>
      ),
    );
    return timeOption;
  }

  hasDestinationDetail() {
    const result =
      this.state.selectOptions.destinationOptions &&
      this.state.selectOptions.destinationOptions.find((item) => item.value === this.state.formValues.destinationID);

    if (!result) return false;
    return ['PROMOTION_DETAIL', 'PRODUCT_LIST', 'PRODUCT_DETAIL'].includes(result.key);
  }

  getFirstAvailableTime(publishedAt) {
    const { formValues } = this.state;
    const currentDate = new Date().getDate();
    const selectedDate = new Date(publishedAt).getDate();
    const minCurrent = new Date().getMinutes();
    const hourCurrent = minCurrent > 45 ? new Date().getHours() + 2 : new Date().getHours() + 1;

    let firstAvailableTime = null;

    for (let number = 0; number < 24; number++) {
      const time = moment({ hour: number }).format('HH:mm');
      const isDisabled = currentDate === selectedDate && hourCurrent > number;

      if (!isDisabled && firstAvailableTime === null) {
        firstAvailableTime = time;
      }
    }

    if (firstAvailableTime && (!formValues.publishTime || formValues.publishTime < firstAvailableTime)) {
      return firstAvailableTime;
    }
    return false;
  }

  render() {
    const { formValues, selectOptions, isLoading, isEdit } = this.state;
    const { argumentOptions, couponGroupOptions, destinationOptions, destinationDetailOptions } = selectOptions;
    const title = isEdit ? 'แก้ไข ประกาศ' : 'สร้างประกาศใหม่';
    const isPublish = formValues.isPublish || !isCampaignManager();

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{title}</h4>
              </div>
              <div className="card-content">
                <FormValidation
                  ref={(el) => {
                    this.frm = el;
                  }}
                >
                  <div style={{ marginTop: '2rem' }} />
                  <div className="row">
                    <div className="col-md-6 control-label">
                      <div className="row">
                        <label className="col-md-4 control-label">
                          หัวข้อประกาศ <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <EnTextAreaEditorFormGroup
                            disabled={isPublish}
                            maxLength={150}
                            showMaxLength
                            value={formValues['name']}
                            containerClassName="w-100"
                            onChange={(e) => this.handleChangFieldForm('name', e.target.value)}
                            validations={[required, checkEmoji, maxLength(150)]}
                            placeholder="กรุณาระบุ"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <label className="col-md-4 control-label">
                          ผู้รับประกาศ <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <EnDropDown
                            id="recipientType"
                            onChange={(e) => {
                              this.handleChangFieldForm('recipientType', e.target.value);
                            }}
                            disabled={isPublish}
                            validations={[required]}
                            value={formValues['recipientType']}
                          >
                            <option value={''}>กรุณาเลือก</option>
                            {broadcastRecipientType.map((option) => (
                              <option key={`recipientOption-${option.value}`} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </EnDropDown>
                        </div>
                      </div>
                    </div>
                  </div>

                  {formValues.recipientType === 'COUPON_GROUP' && (
                    <div className="row">
                      <label className="col-md-2 control-label">
                        ชื่อกลุ่มรหัสส่วนลด <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-10">
                        <EnTagSelectValidation
                          disabled={isPublish}
                          placeholder="กรุณาเลือก"
                          closeOnSelect={true}
                          multi={false}
                          searchable
                          options={couponGroupOptions}
                          value={formValues.couponGroupID}
                          onTagChange={(e) => this.handleChangFieldForm('couponGroupID', e)}
                          validations={[required]}
                        />
                      </div>
                    </div>
                  )}

                  {formValues.recipientType === 'CUSTOMERS' && (
                    <div className="row">
                      <label className="col-md-2 control-label">
                        ไฟล์ ID ลูกค้า <span className="text-danger">*</span>
                      </label>
                      <FormGroup containerClassName="col-md-4" label="">
                        <EnFileInputValidation
                          design="button"
                          disabled={isPublish}
                          acceptType="excel"
                          accept="รองรับไฟล์ XLSX ไม่เกิน 20 MB"
                          onAddedFile={(file) => {
                            this.handleChangFieldForm('receiverFile', file);
                          }}
                          previewUrl={formValues['receiverUrl']}
                          value={formValues['receiverFile']}
                          validations={isEdit ? [] : [required]}
                        />
                      </FormGroup>
                    </div>
                  )}
                  <hr className="m-0" />
                  <div className="row">
                    <div className="col-md-6">
                      <h4>จัดการข้อความ</h4>
                      <FormGroup containerClassName="col-md-12" label="">
                        Thumbnail
                        <EnFileInputValidation
                          acceptType="image"
                          disabled={isPublish}
                          accept="รองรับไฟล์ .PNG, .JPG, JPEG ขนาด 900 x 900 px"
                          dimensions={{ width: 900, height: 900 }}
                          onAddedFile={(file) => this.handleChangFieldForm('thumbnailFile', file)}
                          previewUrl={formValues['thumbnailUrl']}
                          value={formValues['thumbnailFile']}
                        />
                      </FormGroup>
                      {!isPublish && (
                        <>
                          <h5>ตัวเลือกข้อความ</h5>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              flexWrap: 'wrap',
                              gap: '8px',
                              margin: '8px 0px',
                            }}
                          >
                            {argumentOptions &&
                              argumentOptions
                                .filter((arg) => {
                                  if (formValues.recipientType === 'COUPON_GROUP') {
                                    return true;
                                  } else if (formValues.recipientType === 'CUSTOMERS' || formValues.recipientType === 'ALL') {
                                    return arg.label === 'Customer name';
                                  }
                                  return false;
                                })
                                .map((arg) => (
                                  <div className="argument-badge" onMouseDown={() => this.handleArgumentSelect(arg)}>
                                    {arg.label}
                                  </div>
                                ))}
                          </div>
                        </>
                      )}
                      <div className="row">
                        <label className="col-md-4 control-label">
                          หัวข้อความ <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <EnTextAreaEditorFormGroup
                            disabled={isPublish}
                            maxLength={45}
                            showMaxLength
                            value={formValues['title']}
                            containerClassName="w-100"
                            onChange={(e) => this.handleChangFieldForm('title', e.target.value)}
                            validations={[required, maxLength(45)]}
                            placeholder="กรุณาระบุ"
                            argAddable="true"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <label className="col-md-4 control-label">
                          ข้อความย่อ <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <EnTextAreaEditorFormGroup
                            disabled={isPublish}
                            maxLength={90}
                            showMaxLength
                            value={formValues['detail']}
                            containerClassName="w-100"
                            onChange={(e) => this.handleChangFieldForm('detail', e.target.value)}
                            validations={[required, maxLength(90)]}
                            placeholder="กรุณาระบุ"
                            argAddable="true"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <label className="col-md-4 control-label">
                          ปลายทาง <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <EnTagSelectValidation
                            disabled={isPublish}
                            closeOnSelect={true}
                            multi={false}
                            searchable={true}
                            options={destinationOptions}
                            onTagChange={(e) => this.handleChangFieldForm('destinationID', e)}
                            placeholder="กรุณาเลือก"
                            value={formValues['destinationID']}
                            validations={[required]}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ display: this.hasDestinationDetail() ? 'block' : 'none' }}>
                        <label className="col-md-4 control-label">
                          รายละเอียดปลายทาง <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <EnTagSelectValidation
                            disabled={isPublish}
                            closeOnSelect={true}
                            multi={false}
                            searchable={true}
                            options={destinationDetailOptions}
                            onTagChange={(e) => this.handleChangFieldForm('destination', e)}
                            placeholder="กรุณาเลือก"
                            value={formValues['destination']}
                            validations={[required]}
                          />
                        </div>
                      </div>

                      <hr className="m-0" />
                      <h5>วันที่มีผล</h5>
                      <div className="row">
                        <div
                          className="col-md-8"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: '12px',
                          }}
                        >
                          <label>
                            <input
                              disabled={isPublish}
                              type="radio"
                              value="NOW"
                              name="publishType"
                              checked={formValues.publishType === 'NOW'}
                              onChange={(e) => {
                                this.handleChangFieldForm('publishType', e.target.value);
                              }}
                            />
                            &nbsp;&nbsp;ส่งข้อความทันที
                          </label>

                          <label>
                            <input
                              disabled={isPublish}
                              type="radio"
                              value="SCHEDULE"
                              name="publishType"
                              checked={formValues.publishType === 'SCHEDULE'}
                              onChange={(e) => {
                                this.handleChangFieldForm('publishType', e.target.value);
                              }}
                            />
                            &nbsp;&nbsp;กำหนดวัน เวลาส่งข้อความ
                          </label>
                        </div>
                      </div>

                      {formValues.publishType === 'SCHEDULE' ? (
                        <>
                          <div className="row">
                            <label className="col-md-4 control-label">
                              วันที่ส่งข้อความ <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-8">
                              <EnDatePickerValidation
                                id="publishedAt"
                                disabled={isPublish}
                                initialDate={formValues.publishedAt ? moment(formValues.publishedAt) : undefined}
                                onDateChange={(value) => {
                                  this.handleChangFieldForm('publishedAt', value);
                                }}
                                openDirection="up"
                                value={formValues['publishedAt']}
                                isOutsideRange={(date) => {
                                  const today = new Date();
                                  today.setHours(0, 0, 0, 0);
                                  return date < today;
                                }}
                                isPlaceholder="กรุณาเลือก"
                                validations={[required]}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <label className="col-md-4 control-label">
                              เวลาส่งข้อความ <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-8">
                              <EnDropDown
                                disabled={isPublish}
                                id="publishTime"
                                value={formValues['publishTime']}
                                onChange={(e) => {
                                  this.handleChangFieldForm('publishTime', e.target.value);
                                }}
                              >
                                {this.timePickerOption()}
                              </EnDropDown>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="col-md-6" style={{ backgroundColor: '#F8F8F8', padding: '14px' }}>
                      <h4>ตัวอย่างข้อความ</h4>
                      <IphoneBroadcastPreview {...formValues} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {isPublish ? (
                        <EnButton
                          className="pull-right"
                          style={{
                            background: 'var(--text-info-color)',
                            boxShadow: 'none',
                            color: 'white',
                          }}
                          onClick={() => this.props.history.push(`${config.publicUrl}/superadmin/qoc-broadcasts`)}
                        >
                          กลับ
                        </EnButton>
                      ) : (
                        <>
                          <EnButton
                            className="pull-right"
                            style={{
                              backgroundColor: 'var(--text-info-color)',
                              color: 'white',
                            }}
                            onClick={() => this.handleSubmit()}
                            disabled={!this.isFormValid()}
                          >
                            บันทึก
                          </EnButton>
                          <EnButton
                            disabled={isPublish}
                            className="pull-right"
                            style={{
                              background: 'none',
                              boxShadow: 'none',
                              color: 'var(--text-info-color)',
                            }}
                            onClick={() => this.onCancel()}
                          >
                            ยกเลิก
                          </EnButton>
                        </>
                      )}
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject()(observer(BroadcastCreatePage));

import * as _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';
import swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
// import Popup from 'reactjs-popup';
import { observer, inject } from 'mobx-react';
import { FormValidation, required, email, phone, branchcode, taxid } from '../../../components/form/FormValidation';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnButton from '../../../components/form/EnButton';
import Loader from '../../../components/common/Loader';
import EnStickyFooter from '../../../components/form/EnStickyFooter';
// import EnDropZone from '../../../components/form/EnDropZone';
import EnDropzoneDialog from '../../../components/form/EnDropzoneDialog';
// import ImagePicker from '../../../components/form/ImagePicker';
import ListItem from '../../../components/common/ListItem';
import common from '../../../utils/common';
import config from '../../../config';
import MaskInput from 'react-text-mask';
import { Address } from './Address';
// import AuthStore from '../../../stores/AuthStore';

import '../style/contractor.css';
import { toast } from 'react-toastify';
export class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      stepName: 'ข้อมูลส่วนตัว',
      isValid: '',
      showInValidBankAccountNo: false,
      showEmptyBankAccountNo: false,
      showEmptyBankCode: false,
      signatureUrl: '',
      showModal: false,
      files: [],
      dropzoneBookBankOpen: false,
      dropzoneIdCardOpen: false,
      dropzoneVatOpen: false,
      dropzoneCompanyCertOpen: false,
      dropzoneLicenseCardOpen: false,
    };
  }

  async setInfo(key, e) {
    if (e.target && e.target.value) {
      this.props.contractor.saveInfo(key, e.target.value);
    } else {
      this.props.contractor.saveInfo(key, e);
    }
  };

  // onDropZoneComplete(resultFiles) {
  //   const contractor = this.props.contractor.toJS().info;
  //   let files = contractor.file || [];
  //   files = _.concat(files, resultFiles.map(f => {
  //     return {
  //       name: f.res.data.name,
  //       url: f.res.data.path,
  //       date: f.res.data.date,
  //     };
  //   }));
  //   this.setInfo('file', files);
  // }

  initListItemHeaderOption = (title) => {
    return [
      {
        title: title,
        size: 9,
        key: 'filename',
        showIcon: true,
        downloadLink: true,
        appendDescription: true,
        descriptionKey: 'date',
      }, {
        title: '',
        size: 3,
        key: 'delete',
        className: 'text-right',
        itemClassName: 'text-right',
      },
    ];
  };

  toListItemData(files, longFileName) {
    if (Array.isArray(files) && files.length) {
      return files.filter(f => (f && f.name)).map((f, idx) => {
        return {
          filename: (idx + 1) + '_' + (longFileName ? f.name : f.name.substring(14, f.name.length)),
          name: f.name,
          url: f.url,
          date: f.date ? `${moment(f.date).format('DD/MM/YYYY HH:mm:ss')}` : '',
          delete: <i className="fa fa-trash" />,
          icon: common.getFileIcon(f.name),
          downloadHref: f.url,
          type: f.type,
        };
      });
    }
    return [];
  };

  getListItem = (itemHeader, itemData, type, callback) => {
    const data = itemData.filter(i => i.type === type);
    return (
      <div>
        <ListItem
          header={itemHeader}
          data={data}
          onClick={callback}
          target="_blank" />
        {!data || !data.length ? this.getDisplayEmpty('ไม่มีรูปถ่ายหรือไฟล์แนบ') : ''}
      </div>
    );
  };

  getDisplayEmpty = (message) => {
    return <div><em><small>{message}</small></em></div>;
  };

  onAddedFile() {
    if (this.dropzone) {
      this.setState({
        files: this.dropzone.getFiles(),
      });
    }
  }

  // getDropZone(ref, callback) {
  //   const url = `${config.api.sims}/v1/contractors/upload/file`;
  //   const token = AuthStore.getToken();
  //   return (
  //     <EnDropZone
  //       ref={ref}
  //       url={url}
  //       token={token}
  //       acceptedFiles={'image/*,.pdf,.pptx,.ppt,.doc,.docx,.xls,.xlsx,.txt,.zip,.7z,.rar'}
  //       onAllComplete={callback}
  //       maxFileSize={10}
  //       onAddedFile={this.onAddedFile.bind(this)}
  //     />
  //   );
  // };

  async onProvinceChanged(e) {
    const { miscData } = this.props.misc.toJS();
    const code = e.target.value;
    if (miscData && miscData.province) {
      const provinceName = miscData.province.find(p => p.code === code);
      this.props.contractor.saveMultiInfo({
        taxProvinceCode: code,
        taxProvinceName: provinceName.name,
      });
    }
    await this.props.misc.getDistrictByCode(code);
    await this.saveDistrict();
    await this.saveSubDistrict();
  }

  async saveDistrict() {
    const { miscData } = this.props.misc.toJS();
    const { code, name } = miscData.district[0];
    await this.props.misc.getSubDistrictByCode(code);
    this.props.contractor.saveMultiInfo({
      taxDistrictCode: code,
      taxDistrictName: name,
    });
  }

  async saveSubDistrict() {
    const { miscData } = this.props.misc.toJS();
    const { code, name, zipcodes } = miscData.subDistrict[0];
    const taxZipCode = zipcodes[0].zipcode;
    this.props.contractor.saveMultiInfo({
      taxSubDistrictCode: code,
      taxSubDistrictName: name,
      taxZipCode,
    });
  }

  setDefaultValue() {
    this.props.contractor.saveMultiInfo({
      provinceId: '10',
      taxProvinceCode: '10',
      districtId: '1001',
      taxDistrictCode: '1001',
      subDistrictId: '100101',
      taxSubDistrictCode: '100101',
      zipCode: '10200',
      taxZipCode: '10200',
      taxProvinceName: 'กรุงเทพมหานคร',
      taxDistrictName: 'พระนคร',
      taxSubDistrictName: 'พระบรมมหาราชวัง',
    });
  }

  async onDistrictChanged(e) {
    const { miscData } = this.props.misc.toJS();
    const code = e.target.value;
    if (miscData && miscData.district) {
      const districtName = miscData.district.find(d => d.code === code);
      this.props.contractor.saveMultiInfo({
        taxDistrictCode: code,
        taxDistrictName: districtName.name,
      });
    }
    await this.props.misc.getSubDistrictByCode(code);
    await this.saveSubDistrict();
  }

  saveRegisterInfo(key, e) {
    this.props.contractor.saveInfo(key, e.target.value);
  }

  async onSubDistrictChanged(e) {
    let miscStore = this.props.misc.toJS();
    let miscData = miscStore.miscData;
    let code = e.target.value;
    const subDistrictName = miscData.subDistrict.find(s => s.code === code);
    await this.props.contractor.saveMultiInfo({
      taxSubDistrictCode: code,
      taxSubDistrictName: subDistrictName.name,
    });
  }

  saveInfo(key, e) {
    if (e.target.value && key === 'bankAccountNo') {
      this.setState({ showInValidBankAccountNo: false, showEmptyBankAccountNo: false });
      this.setState({ isValid: '' });
    }
    this.props.contractor.saveInfo(key, e.target.value);
  }

  onChangeType(e) {
    let value = false;
    if (e.target.value === 'juristic') {
      value = true;
    }
    this.props.contractor.saveInfo('isJuristic', value);
  }

  saveTypeOfWork(item, e) {
    if (item) {
      this.props.contractor.saveInfo('typeOfWork', item.id);
    }
  }

  onFocusBankCode() {
    this.nameInput.focus();
  }

  genBankOptions = () => {
    const bank = this.props.bank.toJS().info;
    return (bank || []).map((b, index) => {
      return {
        label: <div><img src={b.image_url} alt="" style={{ backgroundColor: b.image_color, height: '30px', width: '30px' }} />&nbsp;&nbsp;&nbsp;{b.bank_name}</div>,
        value: b.bank_code,
      };
    });
  };

  async clickNext() {
    try {
      const contractor = this.props.contractor.toJS().info;

      if (contractor && contractor.bankAccountNo) {
        const bankAccountNo = contractor.bankAccountNo.replace(/-/g, '');
        if (new RegExp('(^$|[0-9]{10})').test(bankAccountNo)) {
          this.setState({ isValid: '' });
          this.setState({ showInValidBankAccountNo: false });
        } else {
          this.setState({ isValid: 'is-invalid-input' });
          this.setState({ showInValidBankAccountNo: true });
        }
      } else {
        this.setState({ isValid: 'is-invalid-input' });
        this.setState({ showEmptyBankAccountNo: true });
      }

      if (!contractor.bankCode) {
        this.setState({ showEmptyBankCode: true });
      }

      this.props.contractor.validateInput(this, this.onFocusBankCode())
        .then(async () => {
          await this.props.contractor.validateTeamName();
          await this.props.contractor.checkMobileAvailable();
          this.props.history.push(`${config.publicUrl}/contractors/register/typeofwork`);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (err) {
      toast.error(err.message);
    }
  }

  validation() {
    return this.props.contractor.isValidRegisterInfo(1);
  }

  onSelectChange = (e) => {
    if (e.value) {
      this.setState({ showEmptyBankCode: false });
    }
    this.props.contractor.saveInfo('bankCode', e.value);
  }

  async componentWillMount() {
    this.props.contractor.clearInfo();
    await this.props.bank.getAllBank();
    await this.props.misc.getAllProvince();
    await this.props.misc.getDistrictByCode('10');
    await this.props.misc.getSubDistrictByCode('1001');
    await this.setDefaultValue();
  }

  async componentDidMount() {
    await this.props.typeofwork.getTypeOfWorks({ sort: 'name' });
  }

  async clickSubmitSignature() {
    this.props.contractor.saveInfo('signature', this.sigCanvas.getTrimmedCanvas().toDataURL('image/png'));
    this.setState({ showModal: false });
  }

  clearSignature = () => {
    this.sigCanvas.clear();
  }

  onHide = () => {
    this.setState({ showModal: false });
  }
  onOpenModal = () => {
    this.setState({ showModal: true });
  }

  showSignatureModal = () => (<div>
    <Modal
      className="custom-modal"
      show={this.state.showModal}
      onHide={this.onHide}>
      <Modal.Header closeButton>
        <Modal.Title
          id="ModalHeader">เซ็น</Modal.Title>
      </Modal.Header>
      <Modal.Body
      >
        <div className="container-canvas">
          <div className="canvas-sig">
            <SignatureCanvas ref={(ref) => { this.sigCanvas = ref; }} canvasProps={{ className: 'canvas-pad' }} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ textAlign: 'center', paddingBottom: 20, paddingTop: 50 }}>
        <EnButton className="btn-success" onClick={this.clickSubmitSignature.bind(this)}>
          <i className="fa fa-pencil-square-o" aria-hidden="true" />  บันทึกลายเซ็น
        </EnButton>
        <EnButton className="btn btn-warning" onClick={this.clearSignature.bind(this)}>
          <i className="fa fa-eraser" aria-hidden="true" /> ลบ
        </EnButton>
      </Modal.Footer>
    </Modal>
  </div>)

  onUploadItemClick(propName, contractor, key, data) {
    if (key === 'delete') {
      let result = contractor[propName].filter(f => f.name !== data.name);
      this.setInfo(propName, result);
    }
  };

  async onSaveDropzone(type, dropzoneFile) {
    try {
      const result = await this.props.contractor.uploadContractorFile(dropzoneFile);
      const contractor = this.props.contractor.toJS().info;
      let files = contractor['file'] || [];
      files = _.concat(files, result.map(f => {
        return {
          name: f.name,
          type,
          url: f.path,
          date: f.date,
        };
      }));
      this.setInfo('file', files);
    } catch (error) {
      swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
    } finally {
      this.setState({
        dropzoneBookBankOpen: false,
        dropzoneIdCardOpen: false,
        dropzoneCompanyCertOpen: false,
        dropzoneVatOpen: false,
        dropzoneLicenseCardOpen: false,
      });
    }
  }

  onOpenDropzone(key) {
    this.setState({
      [`${key}`]: true,
    });
  }

  closeDropzone(key) {
    this.setState({
      [`${key}`]: false,
    });
  }

  render() {
    let contractorStore = this.props.contractor.toJS();
    let contractor = contractorStore.info;
    let miscStore = this.props.misc.toJS();
    let miscData = miscStore.miscData;
    const { signature } = this.props.contractor.toJS().info;
    let provinceName = miscData.province.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });
    let districtName = miscData.district.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });
    let subDistrictName = miscData.subDistrict.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });
    let emailRequired = false;
    const fileData = this.toListItemData(contractor.file);
    const bookBankHeader = this.initListItemHeaderOption('ไฟล์ บุ้คแบ้งค์');
    const idCardHeader = this.initListItemHeaderOption('ไฟล์ สำเนาบัตรประชาชน');
    const companyCertHeader = this.initListItemHeaderOption('ไฟล์ หนังสือรับรองบริษัท');
    const vatHeader = this.initListItemHeaderOption('ไฟล์ ภพ.20');
    const licenseCard = this.initListItemHeaderOption('รูปถ่ายบัตรประจำตัวช่าง');
    const bookBankFile = this.getListItem(bookBankHeader, fileData, 'book_bank', this.onUploadItemClick.bind(this, 'file', contractor));
    const idCardFile = this.getListItem(idCardHeader, fileData, 'id_card', this.onUploadItemClick.bind(this, 'file', contractor));
    const vatFile = this.getListItem(vatHeader, fileData, 'vat', this.onUploadItemClick.bind(this, 'file', contractor));
    const companyCertFile = this.getListItem(companyCertHeader, fileData, 'company_cert', this.onUploadItemClick.bind(this, 'file', contractor));
    const licenseCardFile = this.getListItem(licenseCard, fileData, 'license_card', this.onUploadItemClick.bind(this, 'file', contractor));

    let button = (
      <EnButton id="register-nextbutton" className="btn btn-info btn-next" onClick={this.clickNext.bind(this)}>
        <i className="fa fa-arrow-right btn-icon" aria-hidden="true" />ถัดไป
      </EnButton>
    );
    // let dropzone = this.getDropZone(e => { this.dropzone = e; }, (e) => {
    //   this.onDropZoneComplete(e);
    // });

    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <Loader show={contractorStore.isLoading} />
            <div className="col-md-offset-2 col-md-8">
              <div className="card info">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">ลงทะเบียน</h4>
                  <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
                </div>
                <div className="card-content">
                  <FormValidation ref="FormValidation">
                    {this.showSignatureModal()}
                    <div className="row" style={{ backgroundColor: '#F2F3F4', paddingLeft: '15px' }} >
                      <h4>ข้อมูลส่วนตัว</h4>
                    </div>
                    <div className="row">
                      <EnTextFormGroup id="register-name" ref={e => { this.firstName = e; }} containerClassName="col-md-6" label="ชื่อ" value={contractor.firstName} onChange={this.saveInfo.bind(this, 'firstName')} validations={[required]} />
                      <EnTextFormGroup id="register-surname" containerClassName="col-md-6" label="นามสกุล" value={contractor.lastName} onChange={this.saveInfo.bind(this, 'lastName')} validations={[required]} />
                    </div>
                    <div className="row">
                      <EnTextFormGroup id="register-phone" containerClassName="col-md-6" label="เบอร์โทรศัพท์" value={contractor.phone} size="10" maxLength="10" onChange={this.saveInfo.bind(this, 'phone')} validations={[required, phone]} />
                      <EnTextFormGroup id="register-email" containerClassName="col-md-6" label="อีเมล" notRequired={!emailRequired} value={contractor.email} onChange={this.saveInfo.bind(this, 'email')} validations={[email]} />
                    </div>
                    <div className="row">
                      <EnTextFormGroup id="register-car-id" containerClassName="col-md-6" label="หมายเลขทะเบียนรถ" value={contractor.carId} size="10" maxLength="10" onChange={this.saveInfo.bind(this, 'carId')} />
                      <div />
                    </div>
                    <EnDropzoneDialog
                      dialogTitle="รูปถ่ายบัตรประจำตัวช่าง"
                      dropzoneText="เลือกไฟล์"
                      open={this.state.dropzoneLicenseCardOpen}
                      onSave={this.onSaveDropzone.bind(this, 'license_card')}
                      onClose={this.closeDropzone.bind(this, 'dropzoneLicenseCardOpen')}
                    />
                    <div className="row">
                      <div className="col-md-12">
                        {licenseCardFile}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-12">
                        <EnButton
                          className="btn-secondary"
                          onClick={this.onOpenDropzone.bind(this, 'dropzoneLicenseCardOpen')}>
                          อัพโหลดรูปถ่ายบัตรประจำตัวช่าง
                        </EnButton>
                      </div>
                    </div>
                    <div className="row" style={{ backgroundColor: '#F2F3F4', paddingLeft: '15px' }} >
                      <h4>ที่อยู่สำหรับออกใบกำกับภาษี</h4>
                    </div>
                    <div className="row">
                      <div style={{ paddingLeft: '15px', marginBottom: '5px', marginTop: '5px' }}>
                        <label>
                          <input
                            type="radio"
                            value="juristic"
                            checked={contractor.isJuristic === true}
                            onClick={this.onChangeType.bind(this)}
                            readOnly />&nbsp;&nbsp;นิติบุคคล/บริษัท
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label>
                          <input
                            type="radio"
                            value="individual"
                            checked={contractor.isJuristic === false}
                            onClick={this.onChangeType.bind(this)}
                            readOnly />&nbsp;&nbsp;บุคคลธรรมดา
                        </label>

                      </div>
                    </div>
                    {contractor.isJuristic &&
                      <div className="row">
                        <EnTextFormGroup id="register-team" containerClassName="col-md-12" label={'ชื่อบริษัท'} value={contractor.teamName} onChange={this.saveInfo.bind(this, 'teamName')} validations={[required]} />
                      </div>
                    }
                    <Address
                      provinceName={provinceName}
                      districtName={districtName}
                      subDistrictName={subDistrictName}
                      contractor={contractor}
                      onProvinceChanged={this.onProvinceChanged.bind(this)}
                      onDistrictChanged={this.onDistrictChanged.bind(this)}
                      onSubDistrictChanged={this.onSubDistrictChanged.bind(this)}
                      saveRegisterInfo={(key) => this.saveRegisterInfo.bind(this, key)}
                    />
                    <div className="row">
                      <EnTextFormGroup maxLength={13}
                        containerClassName="col-md-12"
                        label={contractor.isJuristic ? 'หมายเลขประจำตัวผู้เสียภาษีอากร' : 'หมายเลขบัตรประชาชน'}
                        value={contractor.taxNo}
                        onChange={this.saveRegisterInfo.bind(this, 'taxNo')}
                        validations={[required, taxid]} />
                    </div>

                    {
                      contractor.isJuristic && <div>
                        <div className="row">
                          <EnTextFormGroup id="register-team"
                            placeholder="กรณีสำนักงานใหญ่ให้ระบุเป็น 00000 หรือ 00001"
                            containerClassName="col-md-12"
                            label={'หมายเลขสำนักงานใหญ่ / สาขา'}
                            maxLength={5}
                            value={contractor.taxBranchNo}
                            onChange={this.saveRegisterInfo.bind(this, 'taxBranchNo')}
                            validations={[required, branchcode]} />
                        </div>
                        <EnDropzoneDialog
                          dialogTitle="หนังสือรับรองบริษัท"
                          dropzoneText="เลือกไฟล์"
                          open={this.state.dropzoneCompanyCertOpen}
                          onSave={this.onSaveDropzone.bind(this, 'company_cert')}
                          onClose={this.closeDropzone.bind(this, 'dropzoneCompanyCertOpen')}
                        />
                        <div className="row">
                          <div className="col-md-12">
                            {companyCertFile}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-12">
                            <EnButton
                              className="btn-secondary"
                              onClick={this.onOpenDropzone.bind(this, 'dropzoneCompanyCertOpen')}
                            >
                              อัพโหลดหนังสือรับรองบริษัท
                            </EnButton>
                          </div>
                        </div>
                        <EnDropzoneDialog
                          dialogTitle="ภพ.20"
                          dropzoneText="เลือกไฟล์"
                          open={this.state.dropzoneVatOpen}
                          onSave={this.onSaveDropzone.bind(this, 'vat')}
                          onClose={this.closeDropzone.bind(this, 'dropzoneVatOpen')}
                        />
                        <div className="row">
                          <div className="col-md-12">
                            {vatFile}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-12">
                            <EnButton
                              className="btn-secondary"
                              onClick={this.onOpenDropzone.bind(this, 'dropzoneVatOpen')}>
                              อัพโหลด ภพ.20
                            </EnButton>
                          </div>
                        </div>
                      </div>
                    }
                    {
                      !contractor.isJuristic && <div>
                        <div className="row">
                          <div className="form-group col-md-12">
                            <label>ลายเซ็น</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-12">
                            {signature && <img style={{ width: '200px', height: '50px', border: '1px solid #ccc', borderRadius: '4px' }} src={signature} />
                            }
                          </div>
                        </div>
                        <EnButton className={signature ? 'btn btn-danger' : 'btn-secondary'} onClick={this.onOpenModal.bind(this)}>
                          {signature ? 'แก้ไข' : 'เพิ่ม'}ลายเซ็น
                        </EnButton>
                        <EnDropzoneDialog
                          dialogTitle="สำเนาบัตรประชาชน"
                          dropzoneText="เลือกไฟล์"
                          open={this.state.dropzoneIdCardOpen}
                          onSave={this.onSaveDropzone.bind(this, 'id_card')}
                          onClose={this.closeDropzone.bind(this, 'dropzoneIdCardOpen')}
                        />
                        <div className="row">
                          <div className="col-md-12">
                            {idCardFile}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-12">
                            <EnButton
                              className="btn-secondary"
                              onClick={this.onOpenDropzone.bind(this, 'dropzoneIdCardOpen')}
                            >
                              อัพโหลดสำเนาบัตรประชาชน
                            </EnButton>
                          </div>
                        </div>
                      </div>
                    }

                    <div>
                      <div className="row" style={{ backgroundColor: '#F2F3F4', paddingLeft: '15px' }} >
                        <h4>บัญชีธนาคาร</h4>
                      </div>
                      <div className="row">
                        <FormGroup validations={[required]} label="เลือกธนาคาร" containerClassName="col-md-12">
                          <Select
                            className={this.state.showEmptyBankCode ? 'is-invalid-input' : ''}
                            searchable={false}
                            ref={(input) => { this.nameInput = input; }}
                            clearable={false}
                            onChange={this.onSelectChange.bind(this)}
                            placeholder="กรุณาเลือก"
                            options={this.genBankOptions()}
                            value={contractor.bankCode} />
                          {this.state.showEmptyBankCode && <span className="input-error">กรุณาเลือกธนาคาร</span>}
                        </FormGroup>
                      </div>
                      <div className="row">
                        <EnTextFormGroup containerClassName="col-md-6" label="ชื่อบัญชี" value={contractor.bankAccountName} validations={[required]} onChange={this.saveInfo.bind(this, 'bankAccountName')} />
                        <div className="col-md-6">
                          <label>เลขบัญชี</label>
                          <MaskInput
                            mask={[/\d/, /\d/, /\d/, '-', /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                            placeholder=""
                            className={`contractor-mask-input ${this.state.isValid}`}
                            guide={false}
                            value={contractor.bankAccountNo}
                            onChange={this.saveInfo.bind(this, 'bankAccountNo')}
                            ref={(input) => { this.textInput = input; }}
                          />
                          {this.state.showEmptyBankAccountNo && <span className="input-error">กรุณากรอกข้อมูล</span>}
                          {this.state.showInValidBankAccountNo && <span className="input-error">หมายเลขบัญชีไม่ถูกต้อง</span>}

                        </div>
                        {/* <EnTextFormGroup containerClassName="col-md-6" label="เลขบัญชี" value={contractor.bankAccountNo} size="10" maxLength="10" validations={[required, bankAccountNo]} onChange={this.saveInfo.bind(this, 'bankAccountNo')} /> */}
                      </div>
                    </div>
                    <EnDropzoneDialog
                      dialogTitle="อัพโหลดบุ้คแบ้งค์"
                      dropzoneText="เลือกไฟล์"
                      open={this.state.dropzoneBookBankOpen}
                      onSave={this.onSaveDropzone.bind(this, 'book_bank')}
                      onClose={this.closeDropzone.bind(this, 'dropzoneBookBankOpen')}
                    />
                    <div className="row">
                      <div className="col-md-12">
                        {bookBankFile}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-12">
                        <EnButton
                          className="btn-secondary"
                          onClick={this.onOpenDropzone.bind(this, 'dropzoneBookBankOpen')}
                        >
                          อัพโหลดบุ้คแบ้งค์
                        </EnButton>
                      </div>
                    </div>
                    &nbsp;
                    <EnStickyFooter step={this.state.step} stepName={this.state.stepName} button={button} />
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default inject('contractor', 'typeofwork', 'misc', 'bank')(observer(Info));

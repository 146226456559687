import * as _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import Loader from '../../../components/common/Loader';
import Container from '../../../layouts/ContainerContractor';
import { FormValidation, required } from '../../../components/form/FormValidation';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnToggle from '../../../components/form/EnToggle';
import EnButton from '../../../components/form/EnButton';
import common from '../../../utils/common';
import config from '../../../config';
import { toast } from 'react-toastify';

export class Info extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
    };
  }

  saveInfo(key, e) {
    this.props.contractor.saveInfo(key, e.target.value);
  }

  saveTypeOfWork(item, e) {
    if (item) {
      this.props.contractor.saveInfo('typeOfWork', item.id);
    }
  }

  validation(typeOfWork) {
    return typeOfWork.length > 0;
  }

  onSubmit() {
    const contractorId = this.state.id;
    const calendarFilter = this.props.calendar.toJS().calendarFilter;
    let qs = {};
    if (calendarFilter) {
      qs = calendarFilter;
    }
    toast.success('แก้ไขข้อมูลเรียบร้อย');
    setTimeout(() => {
      this.props.history.push(`${config.publicUrl}/contractors/${contractorId}/calendar?${common.urlBuilder(qs)}`);
    }, 1000);
  }

  async clickSubmit() {
    try {
      await this.props.contractor.validateTeamName(this.state.id);
      await this.props.contractor.updateContractorInfo(this.state.id, 'typeOfWork');
      this.onSubmit();
    } catch (error) {
      toast.error(error.message);
    }
  }

  async componentDidMount() {
    try {
      await this.props.contractor.getContractor({
        id: this.state.id,
      });
      await this.props.typeofwork.getTypeOfWorks({ sort: 'name' });
    } catch (error) {
      toast.error(error.message);
    }
  }

  render() {
    let contractorStore = this.props.contractor.toJS();
    let contractor = contractorStore.info;
    const { typeOfWork: conTypeOfWorks = [] } = contractor;
    const checkHideContractor = true;
    let typeOfWorks = this.props.typeofwork.mapIdAndLabel(checkHideContractor);
    typeOfWorks = _.sortBy(typeOfWorks, 'label');

    let options = typeOfWorks.map((item, index) => {
      return (
        <EnToggle
          key={index}
          checked={_.indexOf(conTypeOfWorks, item.id) >= 0}
          label={item.label}
          onChange={this.saveTypeOfWork.bind(this, item)}
        />
      );
    });
    let otherWork = conTypeOfWorks.filter((i) => i === 0);

    return (
      <Container>
        <div className="row">
          <Loader show={contractorStore.isLoading || contractorStore.isSumitting} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แก้ไขข้อมูลส่วนตัว</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="row">
                    <FormGroup containerClassName="col-md-12">
                      <span className="form-label">ประเภทงานที่รับ (เลือกได้มากกว่า 1 ข้อ)</span>
                      {options}
                    </FormGroup>
                  </div>
                  <div className="row">
                    {
                      otherWork.length > 0 &&
                      <EnTextFormGroup id="contractor-remark" containerClassName="col-md-12" label="เพิ่มเติม" value={contractor.otherWork} onChange={this.saveInfo.bind(this, '')} validations={[required]} />
                    }
                  </div>
                  <div className="row">
                    <EnButton id="contractor-edit" className="btn-info pull-right" disabled={!this.validation(conTypeOfWorks)} onClick={this.clickSubmit.bind(this)}>
                      <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                    </EnButton>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('contractor', 'auth', 'calendar', 'typeofwork')(observer(Info));

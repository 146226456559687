import React from 'react';
import iconUpload from '../../assets/img/icn_upload.svg';
import '../../assets/css/file-input.css';
import swal from 'sweetalert2';
import { control } from 'react-validation';
import infoError from '../../assets/img/icn_error.svg';
import _ from 'lodash';
import { toast } from 'react-toastify';

// Props
// - acceptType: 'image' | 'excel'
// - accept: string
// - onFileChange: (file: File) => void
// - design: 'box' | 'button'
// - type: 'excel' | 'image'
// - dimensions: { width: number, height: number }
// - onAddedFile: (file: File) => void

const accepts = [
  {
    type: 'image',
    accept: '.png, .jpeg, .jpg',
  },
  {
    type: 'excel',
    accept: '.xlsx',
  },
];

export class EnFileInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      fileUpload: null,
      fileUrl: null,
    };
  }

  handleClickUpload = () => {
    if (this.props.disabled) return;
    this.inputRef.current.click();
  };

  handleDeleteFile = () => {
    this.setState({ fileUpload: null, fileUrl: null });
    this.inputRef.current.value = '';
    this.props.onAddedFile(null);
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];

    // check file size (max 20MB)
    if (file.size > 20 * 1024 * 1024) {
      toast.error('ขนาดไฟล์เกิน 20 MB กรุณาอัปโหลดใหม่อีกครั้ง')
      return null;
    }

    // allow xlsx, xls
    if (this.props.acceptType == 'excel' && (!file || !file.name.match(/\.(xlsx)$/))) {
      toast.error('อัพโหลดไฟล์ไม่สำเร็จ กรุณาอัปโหลดใหม่อีกครั้ง')
      this.inputRef.current.value = '';
      return;
    } else if (this.props.acceptType == 'image' && (!file || !file.name.match(/\.(png|jpeg|jpg)$/))) {
      toast.error('รองรับรับเฉพาะไฟล์ .PNG, .JPG, JPEG กรุณาอัปโหลดใหม่อีกครั้ง')
      this.inputRef.current.value = '';
      return;
    }

    // If it's an image, check dimensions
    if (this.props.acceptType === 'image') {
      this.checkImageDimensions(file);
    } else {
      // For non-image files, proceed as before
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.setState({ fileUpload: { file } });
      };
      reader.readAsArrayBuffer(file);
      this.props.onAddedFile(file);
    }
  };

  checkImageDimensions = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (!this.props.dimensions || (img.width === this.props.dimensions.width && img.height === this.props.dimensions.height)) {
          this.setState({ fileUpload: { file } });
          this.props.onAddedFile(file);
        } else {
          toast.error('ขนาดรูปเกิน 900px x 900px กรุณาอัปโหลดใหม่อีกครั้ง')
          this.inputRef.current.value = '';
          return null;
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value) {
      this.setState({ fileUpload: { file: this.props.value } });
    }

    if (prevProps.previewUrl !== this.props.previewUrl && this.props.previewUrl) {
      this.setState({ fileUrl: this.props.previewUrl });
    }
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({ fileUpload: { file: this.props.value } });
    }
    if (this.props.previewUrl) {
      this.setState({ fileUrl: this.props.previewUrl });
    }
  }

  render() {
    const { fileUpload, fileUrl } = this.state;
    const { acceptType, design, disabled } = this.props;
    const regex = /\/([^\/?#]+)\.xlsx/;
    const match = fileUrl && fileUrl.match(regex);
    const name = match ? match[1] + '.xlsx' : null;

    const fileName = fileUpload ? fileUpload.file.name : name;

    return (
      <>
        <input
          type="file"
          ref={this.inputRef}
          accept={accepts.find((a) => a.type === acceptType) ? accepts.find((a) => a.type === acceptType).accept : ''}
          style={{ display: 'none' }}
          onChange={this.handleFileChange}
        />
        {design === 'button' ? (
          fileUpload || fileUrl ? (
            <div className="upload-button-preview">
              <span
                className="upload-button-preview-title cursor-pointer"
                onClick={() => {
                  if (!fileUpload) {
                    window.open(fileUrl);
                  }
                }}
              >
                {fileName}
              </span>
              {!disabled && <span className="fa fa-trash upload-preview-icon" aria-hidden="true" onClick={this.handleDeleteFile} />}
            </div>
          ) : (
            <div className="upload-button" onClick={this.handleClickUpload}>
              <img src={iconUpload} alt="upload" />
              อัพโหลดไฟล์
            </div>
          )
        ) : (
          <>
            {fileUpload || fileUrl ? (
              <div
                className="upload-preview-container"
                style={{
                  backgroundImage: `url(${fileUrl ? fileUrl : URL.createObjectURL(fileUpload.file)})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                {!disabled && (
                  <div className="upload-box-preview-content" onClick={this.handleDeleteFile}>
                    <span className="fa fa-trash upload-box-preview-icon" aria-hidden="true" />
                  </div>
                )}
              </div>
            ) : (
              <div className="upload-container" onClick={this.handleClickUpload}>
                <div className="upload-content text-center">
                  <img src={iconUpload} alt="upload" />
                  อัพโหลดไฟล์
                </div>
              </div>
            )}
          </>
        )}
        {!disabled && <div className="upload-accept">{this.props.accept}</div>}
      </>
    );
  }
}

export const EnFileInputValidation = control(({ error, isChanged, isUsed, ...props }) => {
  if ((error && isChanged, isUsed)) {
    props.className = `${props.className || ''} is-invalid-input`;
  }

  return (
    <div>
      <EnFileInput {...props} />
      {(!_.isNil(error) && !_.isEmpty(error) && isChanged, isUsed ? error : null)}
    </div>
  );
});

import { useState, useEffect, useRef } from 'react';
import { isNil } from 'lodash';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomEditor from './ckeditor';

import { EmojiMapped } from './plugins/emoji';
import { UploadAdapter } from './plugins/upload';

import FormGroup from '../FormGroup';
import config from '../../../config';

const MyEditor = ({
  label = '',
  disabled = false,
  validations,
  containerClassName,
  type = '',
  value,
  onChange,
}) => {
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);
    return () => setIsLayoutReady(false);
  }, []);

  const editorConfig = {
    toolbar: {
      shouldNotGroupWhenFull: false,
      items: [
        'removeFormat',
        'heading',
        '|',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'subscript',
        'superscript',
        'bulletedList',
        'numberedList',
        '|',
        'alignment',
        'indent',
        'outdent',
        '|',
        'insertTable',
        'link',
        'imageUpload',
        'mediaEmbed',
        '|',
        'highlight',
        'specialCharacters',
        'blockQuote',
        'htmlEmbed',
        'horizontalLine',
        'pageBreak',
        '|',
        'undo',
        'redo',
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      decorators: {
        toggleDownloadable: {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'file',
          },
        },
      },
    },
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: ['default', 12, 14, 16, 18, 20, 22],
      supportAllValues: true,
    },
    image: {
      toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageStyle:breakText',
        '|',
        'resizeImage',
      ],
    },
    mediaEmbed: {
      previewsInData: true,
    },
  };

  const handleReady = (editor) => {
    // setup emoji plugin
    editor.plugins.get('SpecialCharacters').addItems('Emojis', EmojiMapped);

    // setup upload adapter
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      const uploadUrl = `${config.api.sims}/v1/editor/upload?path=${type}`;
      return UploadAdapter(loader, uploadUrl);
    };

    if (disabled) {
      editor.enableReadOnlyMode('1');
    } else {
      editor.disableReadOnlyMode('1');
    }
  };

  const handleChange = (event, editor) => {
    if (onChange) {
      onChange(event, editor);
    }
  };

  let options = {
    label,
    validations: !isNil(validations) && Array.isArray(validations),
  };

  return (
    <FormGroup containerClassName={containerClassName} {...options}>
      <div className="main-container">
        <div className="editor-container editor-container_classic-editor" ref={editorContainerRef}>
          <div className="editor-container__editor">
            <div ref={editorRef}>
              <CKEditor
                editor={CustomEditor}
                config={editorConfig}
                onReady={handleReady}
                onChange={handleChange}
                data={value}
              />
            </div>
          </div>
        </div>
      </div>
    </FormGroup>
  );
};

export default MyEditor;

import _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import queryString from 'query-string';
import Select from 'react-select';

import Container from '../../layouts/Container';
import FormGroup from '../../components/form/FormGroup';
import EnDropDown from '../../components/form/EnDropDown';
import Calendar from '../../components/common/Calendar';
import Loader from '../../components/common/Loader';
import datetime from '../../utils/datetime';
import common from '../../utils/common';
import { TypeOfJobs } from '../../utils/data';
import { CustomCellWrapper, CustomCalendarEvent, CustomEndAccessor, CustomEventView, CustomDateHeader } from './CustomCalendar';
import SearchJob from './search/SearchJob';
import config from '../../config';
import AddressForm from '../../components/address/AddressForm';
import Swal from 'sweetalert2';
import AuthStore from '../../stores/AuthStore';
import EnButton from '../../components/form/EnButton';
import { toast } from 'react-toastify';
// import EnButton from '../../components/form/EnButton';
export class Admin extends React.Component {
  constructor(props) {
    super(props);

    const parsed = queryString.parse(this.props.location.search);

    let currentDate;
    if (parsed.date && parsed.date.length === 7) {
      currentDate = moment(`${parsed.date}-01`, 'YYYY-MM-DD');
    } else {
      currentDate = moment(`${moment().format('YYYY-MM-01')}`, 'YYYY-MM-DD');
    }

    const storeId = AuthStore.getStoreId();

    this.state = {
      selectedDate: undefined,
      currentDate: currentDate,
      workType: '',
      jobType: '',
      contractorId: '',
      query: '',
      results: [],
      showCalendar: false,
      calendarAddress: {},
      storeId,
      jobs: [],
      typeOfWorks: [],
      isAdminCalendar: true
    };

    this.debounceTimeout = null;
  }

  async componentDidMount() {
    try {
      await this.props.typeofwork.getTypeOfWorksByStoreId(this.state.storeId, this.state.isAdminCalendar);

      const typeOfWorks = this.props.typeofwork.mapIdAndLabel();
      const workTypes = _.orderBy(typeOfWorks, ['label']).map((item) => ({ label: item.label, value: item.id }));
      
      // let typeOfJobs = TypeOfJobs.map((item, idx) => (<option key={`typeOfJobs-${idx}`} value={item.id}>{item.label}</option>));
      // typeOfJobs = typeOfJobs.slice(1);

      const typeOfJobs = TypeOfJobs.map((item) => ({ label: item.label, value: item.id })).slice(1);
      this.setState({ typeOfWorks: workTypes, typeOfJobs });

      // this.setState({
      //   typeOfWorks: workTypes,
      //   typeOfJobs,
      //   workType: typeOfWorks && this.state.workType ? this.state.workType : typeOfWorks[0].id,
      // });

      this.changeQueryString();

      // const calendarAddress = this.props.job.toJS().calendarAddress;
      // if (calendarAddress && calendarAddress.districtCode) {
      //   this.state.calendarAddress = calendarAddress;
      //   this.props.job.setInfo('addressInfo', calendarAddress);
      // }
      // await this.loadData({ not_search_job: true });
    } catch (error) {
      toast.error(error.message || 'เกิดข้อผิดพลาด');
    }
  }

  async loadData(options) {
    const store = this.props.auth.getStore();
    if (_.isNil(store) || _.isEmpty(store) || !Array.isArray(store.area) || !store.area.length) {
      throw new Error('ไม่สามารถดึงข้อมูลพื้นที่การขายได้ กรุณาเข้าสู่ระบบใหม่อีกครั้ง');
    }
    const startDate = moment(this.state.currentDate).startOf('month').subtract(5, 'days');
    const endDate = moment(this.state.currentDate).endOf('month').add(5, 'days');
    const objFilter = {
      isAdminCalendar: this.state.isAdminCalendar,
      isAdmin: true,
      startDate,
      endDate,
      workTypeId: this.state.workType,
      jobType: this.state.jobType,
      shareable: store.shareable,
      aCode: this.state.calendarAddress ? this.state.calendarAddress.districtCode : '',
      subDistrictCode: this.state.calendarAddress ? this.state.calendarAddress.subDistrictCode : '',
      ...options,
    };
    if (this.state.contractorId) {
      objFilter.contractorId = this.state.contractorId;
    }
    const typeOfWork = this.props.typeofwork.toJS();
    const channel_name = this.props.auth.getChannelName() || '';

    if (channel_name) {
      objFilter.channel = channel_name
    }

    const jobs = await this.props.calendar.getAdminCalendar(objFilter, typeOfWork);////get jobs
    this.setState({
      jobs,
    });

  }

  async handleInputChange(value) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(async () => {
      if (value.length >= 3) {
        await this.loadData({ not_search_job: true, teamName: value });
      }
    }, 1000);
  }

  customDateHeader(obj) {
    return CustomDateHeader(obj, this.state.selectedDate, (date) => {
      this.setState({
        selectedDate: date,
      });
    });
  }

  customCellWrapper(obj) {
    let calendarStore = this.props.calendar.toJS();
    return CustomCellWrapper(obj, calendarStore.holidays, calendarStore.vacation);
  }

  keepStateFilter() {
    let filter = {
      date: moment(this.state.currentDate).format('YYYY-MM'),
      workTypeId: this.state.workType,
      jobType: this.state.jobType,
      aCode: this.state.calendarAddress ? this.state.calendarAddress.districtCode : '',
    };

    if (this.state.contractorId) {
      filter.contractorId = this.state.contractorId;
    }

    this.props.calendar.setCalendarFilter(filter);
  }

  changeQueryString() {
    const obj = {
      work_type_id: this.state.workType,
      job_type: this.state.jobType,
    };

    if (this.state.currentDate) {
      obj.date = moment(this.state.currentDate).format('YYYY-MM');
    }

    if (this.state.contractorId) {
      obj.contractor_id = this.state.contractorId;
    }

    if (this.state.calendarAddress) {
      obj.acode = this.state.calendarAddress.districtCode;
    }

    obj.isAdminCalendar = this.state.isAdminCalendar;

    const qs = common.urlBuilder(obj);
    this.props.history.push(`${config.publicUrl}/admin/calendar?${qs}`);
  }

  getJobUrl = (event) => {
    if (event && event.isJob) {
      return this.getUpdateJobUrl(event);
    } else if (event && event.isHoliday) {
      return this.getCreateJobUrl(event);
    }
    return undefined;
  }

  onSelectSlot(event) {
    let swalOption = {
      title: 'ท่านยังไม่ได้ระบุพื้นที่หน้างาน',
      text: 'ท่านยืนยันที่จะดำเนินการต่อหรือไม่?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
    };
    if (!(this.state.calendarAddress && this.state.calendarAddress.districtCode)) {
      Swal.fire(swalOption).then((result) => {
        if (result.value) {
          this.createJobRequest(event);
        }
      });
    } else {
      this.createJobRequest(event);
    }
  }

  getUpdateJobUrl = (event) => {
    if (event && event.id) {
      return `${config.publicUrl}/admin/calendar/jobs/${event.id}`;
    }
    return undefined;
  }

  getCreateJobUrl = (event) => {
    if (event && event.start && event.end) {
      const calendarStore = this.props.calendar.toJS();
      const vacationEvent = calendarStore.vacation
        .find(v => datetime.isBetween(event.start, v.start, v.end) &&
          datetime.isBetween(event.end, v.start, v.end));

      if (vacationEvent && vacationEvent.isVacation && vacationEvent.allDay) {
        return undefined;
      }

      const qs = {
        workTypeId: this.state.workType,
        jobType: this.state.jobType,
        contractorId: this.state.contractorId,
        start: moment(event.start).format('YYYYMMDD'),
        end: moment(event.end).format('YYYYMMDD'),
        acode: this.state.calendarAddress ? this.state.calendarAddress.districtCode : '',
      };

      return `${config.publicUrl}/admin/calendar/jobs?${common.urlBuilder(qs)}`;
    }
  }

  createJobRequest = (event) => {
    const url = this.getCreateJobUrl(event);
    if (url) {
      this.keepStateFilter();
      this.props.history.push(url);
    }
  }

  async onWorkTypeChanged(selected) {
    const workType = selected ? selected.value : '';
    this.setState({ workType }, () => {
      this.changeQueryString();
      // this.loadData();
    });
  }

  async onJobTypeChanged(e) {
    this.setState({ jobType: e.value }, () => {
      this.changeQueryString();
      // this.loadData();
    });
  }

  async onContractorChanged(selected) {
    const contractorId = selected ? selected.value : '';
    this.setState({ contractorId }, () => {
      this.changeQueryString();
      // this.loadData({ filterContractorsOnly: true });
    });
  }

  async onMonthNext(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
    });
  }

  async onMonthBack(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
    });
  }

  async onDateCurrent(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
    });
  }

  async onMoveMonth(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
    });
  }

  getJobUrl = ({ id, status, isActive }) => {
    const url = `${config.publicUrl}/admin/calendar/jobs/${id}`
    const qs = common.urlBuilder({ status: status });

    if (status == 'claim' && !isActive) {
      return `${url}?${qs}`;
    }
    return url;
  }

  handleToggleSearchResult = (showResult) => {
    this.setState({
      showCalendar: !showResult,
    });
  }

  onLoadCalendar = () => {
    this.loadData();
    this.setState({
      showCalendar: true,
    });
  }

  clearAddressData = (e) => {
    if (!e.value) {
      this.props.job.clearAddressData();
      this.props.job.clearCalendarAddressData();
      this.setState({ calendarAddress: {} });
      this.changeQueryString();
      // this.loadData();
    }
  }

  changeAddressSet = (value) => {
    this.props.job.saveAddressData(value);
    this.props.job.saveCalendarAddressData(value);

    const calendarAddress = this.props.job.calendarAddress;
    this.setState({ calendarAddress });
    // this.loadData();
  };

  async onTestSendSMS() {
    try {
      const confirmDialogOptions = {
        icon: 'question',
        title: `ส่ง SMS`,
        text: `เบอร์โทร`,
        input: 'text',
        // footer: '<i style="color: rgb(221, 51, 51);">เมื่อกดยืนยันแล้วจะไม่สามารถยกเลิกได้',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ส่ง',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          return !value && 'ใส่เบอร์โทร';
        },
        preConfirm: async (telNo) => {
          try {
            const message = 'test send sms';
            await this.props.job.testSendSMS(telNo, message);
            return true;
          } catch (error) {
            throw error;
          }
        },

        allowOutsideClick: () => !Swal.isLoading(),
      };

      await Swal.fire(confirmDialogOptions);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `${error.message}`,
      });
    }
  }

  getContractorList(contractorStore) {
    let contractorList = _.sortBy(contractorStore.list, 'team_name');
    // push in first index
    contractorList.unshift({ _id: '', team_name: 'ทุกทีม' });
    return contractorList.map((item) => ({ label: item.team_name, value: item._id }));

    // contractorList = contractorList.map((item) => {
    //   return {
    //     label: item.team_name,
    //     value: item._id,
    //   };
    // });
    // contractorList = [{ label: 'ทุกทีม', value: '' }].concat(contractorList);
    // return contractorList
  }

  async clearFilter() {
    await this.setState({
      workType: '',
      jobType: '',
      contractorId: '',
      query: '',
      results: [],
      calendarAddress: {},
      jobs: [],
    });
    this.loadData({ not_search_job: true });
  }

  render() {
    const calendarStore = this.props.calendar.toJS();
    const contractorStore = this.props.contractor.toJS();
    const exportdata = this.props.exportdata.toJS();

    const events = _.concat(calendarStore.vacation, calendarStore.holidays, this.state.jobs);
    let contractorList = this.getContractorList(contractorStore);

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={calendarStore.isCalendarLoading || contractorStore.isLoading || exportdata.isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ปฏิทิน</h4>
                <p className="category">รายละเอียดการให้บริการ</p>
              </div>
              <div className="card-content">
                <div className="row">
                  <FormGroup label="ประเภทงาน:" containerClassName="col-md-6">
                    <Select
                      multi={false}
                      clearable={false}
                      value={this.state.workType}
                      options={this.state.typeOfWorks}
                      className="select-custom1"
                      onChange={(item) => { this.onWorkTypeChanged(item); }}
                      placeholder="เลือก..."
                    />
                  </FormGroup>

                  <FormGroup label="ลักษณะงาน:" containerClassName="col-md-6">
                    <Select
                      multi={false}
                      clearable={false}
                      value={this.state.jobType  || ''}
                      options={this.state.typeOfJobs}
                      className="select-custom1"
                      onChange={(item) => this.onJobTypeChanged(item) }
                      placeholder="เลือก..."
                    />
                  </FormGroup>


                  {/* <FormGroup label="ลักษณะงาน:" containerClassName="col-md-6">
                    <EnDropDown id="job-type" value={this.state.jobType  || ''} onChange={this.onJobTypeChanged.bind(this)}>
                      {this.state.typeOfJobs}
                    </EnDropDown>
                  </FormGroup> */}

                  <AddressForm
                    addressData={this.state.calendarAddress}
                    onChange={(e) => { this.clearAddressData(e); }}
                    onAddressSelected={(addressObject) => this.changeAddressSet(addressObject)}
                  />

                  <FormGroup label="ทีมช่าง:" containerClassName="col-md-6">
                    <Select
                      multi={false}
                      value={this.state.contractorId}
                      options={contractorList}
                      className="select-custom1"
                      onChange={(item) => { this.onContractorChanged(item); }}
                      placeholder="เลือก..."
                      onInputChange={this.handleInputChange.bind(this)}
                    />
                  </FormGroup>

                  <FormGroup label="" containerClassName="col-md-6" containerStyle={{ paddingTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                    <EnButton className="btn-success" onClick={this.onLoadCalendar.bind(this)}>
                      <i className="fa fa-search" aria-hidden="true" />
                      <span> ค้นหาปฏิทิน </span>
                    </EnButton>
                    <EnButton className="btn-warning" onClick={this.clearFilter.bind(this)}>
                      <i className="fa fa-refresh" aria-hidden="true" />
                      <span> เคลียร์ </span>
                    </EnButton>
                  </FormGroup>


                  <FormGroup label="ค้นหางาน:" containerClassName="col-md-12">
                    <SearchJob getHref={this.getJobUrl} onToggle={this.handleToggleSearchResult} disabled={false} />
                  </FormGroup>

                </div>

                {this.state.showCalendar &&
                  (contractorList.length ? (
                    <Calendar
                      events={events}
                      date={moment(this.state.currentDate).toDate()}
                      getEventHref={this.getJobUrl}
                      onSelectSlot={this.onSelectSlot.bind(this)}
                      endAccessor={CustomEndAccessor}
                      eventWrapper={CustomCalendarEvent}
                      eventView={CustomEventView}
                      dateHeader={this.customDateHeader.bind(this)}
                      cellWrapper={this.customCellWrapper.bind(this)}
                      customNext={this.onMonthNext.bind(this)}
                      customBack={this.onMonthBack.bind(this)}
                      customCurrent={this.onDateCurrent.bind(this)}
                      customMoveMonth={this.onMoveMonth.bind(this)}
                    />
                  ) : (
                    <p
                      style={{
                        display: 'flex',
                        color: 'red',
                        justifyContent: 'center',
                        fontSize: '30px',
                      }}
                    >
                      ไม่มีช่างพร้อมให้บริการ
                    </p>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('calendar', 'contractor', 'auth', 'exportdata', 'typeofwork', 'job')(observer(Admin));

import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseOutlined';

const DragAndDropImages = ({ images: initialImages, onChange, onDelete }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (initialImages && initialImages.length) {
      setImages(initialImages);
    }
  }, [initialImages]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setImages(items);

    if (onChange) {
      onChange(items);
    }
  };

  const handleRemoveImage = (alt) => {
    const filterOutImage = images.filter((image) => image.alt !== alt);
    setImages(filterOutImage);

    if (onDelete) {
      onDelete(filterOutImage);
    }
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="images" direction="horizontal">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              display: 'flex',
              overflowX: 'auto',
              gap: 2,
              p: 2,
              bgcolor: '#f9f9f9',
              borderRadius: 2,
              boxShadow: 2,
              width: '100%',
              height: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {!images || !images.length ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  bgcolor: '#f9f9f9',
                  borderRadius: 2,
                  border: '2px dashed #ccc',
                }}
              >
                <span>ยังไม่มีรูปสินค้า</span>
              </Box>
            ) : (
              images.map((image, index) => (
                <Draggable key={image.alt} draggableId={image.alt} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        position: 'relative',
                        flexShrink: 0,
                        borderRadius: 2,
                        overflow: 'hidden',
                        cursor: 'grab',
                        width: 'auto',
                        height: 200,
                      }}
                    >
                      <img
                        src={image.thumbnail || image.url}
                        alt={image.alt}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '8px',
                        }}
                      />

                      <IconButton
                        onClick={() => handleRemoveImage(image.alt)}
                        sx={{
                          position: 'absolute',
                          padding: 0,
                          top: 8,
                          right: 8,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                          },
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                </Draggable>
              ))
            )}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropImages;

import React from 'react';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import { isAllow } from '../../../utils/permission';
import config from '../../../config';
import { toast } from 'react-toastify';

export class ManageChannel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onAddNew() {
    this.props.history.push(`${config.publicUrl}/superadmin/channel/add`);
  }

  async onDelete(item) {
    const confirmDialogOptions = {
      title: 'ลบข้อมูล',
      text: 'คุณแน่ใจที่จะลบ Channel นี้',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.props.channel.deleteChannel(item.channel_name);
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };
    const result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }

  onEdit(item) {
    if (item) {
      this.props.history.push(`${config.publicUrl}/superadmin/channel/${item.channel_name}/edit`);
    }
  }

  async componentDidMount() {
    if (!isAllow('VIEW_A_LIST_OF_CHANNEL')) {
      return;
    }
    try {
      const pathname = this.props.history.location.pathname;
      if (pathname.includes('/superadmin/channel') && !isAllow('VIEW_A_LIST_OF_CHANNEL')) {
        this.props.history.push(`${config.publicUrl}`);
      }
      await this.props.channel.getAllChannel();
    } catch (err) {
      toast.error(err.message);
    }
  }

  getTableRows(items) {
    return _.orderBy(items, ['code']).map((item, index) => {
      return (
        <tr key={item.channel_name}>
          <td className="text-center">{index + 1}</td>
          <td>{item.channel_name}</td>
          <td className="text-center">
            <button
              className="btn btn-xs"
              title="แก้ไข" data-tip="แก้ไข"
              onClick={this.onEdit.bind(this, item)}
              disabled={!isAllow('EDIT_CHANNEL_DETAILS')}
            >
              <span className="fa fa-pencil" aria-hidden="true" />
            </button>
          </td>
          <td className="text-center">
            <button className="btn btn-danger btn-xs"
              title="ลบ"
              data-tip="ลบ"
              onClick={this.onDelete.bind(this, item)}
              disabled={!isAllow('DELETE_A_CHANNEL')}
            >
              <span className="fa fa-trash" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  render() {
    let items = this.props.channel.toJS().info;
    let rows = this.getTableRows(items);

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายการ Channel</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="10%" className="text-center">ลำดับที่</th>
                            <th width="60%" className="text-center">ชื่อ Channel</th>
                            <th width="5%" className="text-center">
                              <button
                                className="btn btn-success"
                                title="สร้างใหม่"
                                data-tip="สร้างใหม่"
                                onClick={this.onAddNew.bind(this)}
                                disabled={!isAllow('CREATE_A_NEW_CHANNEL')}
                              >
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                              </button>
                            </th>
                            <th width="10%" className="text-center" />
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('channel')(observer(ManageChannel));

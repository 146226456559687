import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import { FormValidation, required, isPositiveNumber, wagesErrMsg } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnButton from '../../../components/form/EnButton';
import EnToggle from '../../../components/form/EnToggle';
import Select from 'react-select';
import config from '../../../config';
import _, { filter } from 'lodash';
import { toast } from 'react-toastify';

const theme = {
  activityLogTextArea: {
    display: 'block',
    height: '152px',
    overflowY: 'scroll',
    padding: '6px 12px',
    fontSize: '19.2px',
    lineHeight: 1.42857143,
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)'
  }
};
export class SkuPage extends React.Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    this.state = {
      id: id,
      isEdit: id && id.length,
      isDuplicated: false,
      skuCodeEdit: undefined,
      open: false,
      previousInfo: undefined,
    };
  }


  async componentWillMount() {
    try {
      await this.props.typeofwork.getTypeOfWorks();
      await this.props.channel.getAllChannel();
      await this.props.sku.getSkuList();
      if (this.state.isEdit) {
        await this.props.sku.getSkuForEdit(this.state.id);
        this.setState({
          skuCodeEdit: this.props.sku.toJS().info.code,
          previousInfo: this.props.sku.toJS().info
        })
      } else {
        this.props.sku.clearInfo();
        // const auth = this.props.auth.getUserInfo();
        // this.props.sku.info.channel = auth.channel ? auth.channel.channelName : '';
        const channel = this.props.auth.getChannelName() || '';
        this.props.sku.info.channel = channel;
      }
      // this.onCodeChanged(null);
    } catch (err) {
      toast.error(err.message);
    }
  }

  activityLogInTextarea() {
    let { activityLogs } = this.props.sku.toJS().info;
    let results = [];
    if (activityLogs && activityLogs.length) {
      results = activityLogs.filter(function (log) { if (log.remark != '') return log; })
        .map((log) => {
          return log.remark;
        })
        .join('<br />')
    }
    return results;
  }

  getTypeOfWorkOptions() {
    const typeOfWorks = this.props.typeofwork.mapIdAndLabel() || [];
    return typeOfWorks.map((item) => ({
      label: item.label,
      value: item.id,
    })
    );
  }

  onCodeChanged(e) {
    // if (e) {
    //   this.props.sku.saveInfo('code', e.target.value)
    // };
    this.props.sku.saveInfo('code', e.target.value);
    this.checkSkuDuplicate();
  }

  onNameChanged(e) {
    this.props.sku.saveInfo('name', { th: e.target.value });
  }

  onWorkTypeChanged(option) {
    this.props.sku.saveInfo('typeOfWork', _.get(option, 'value', null));
  }

  onCostTypeChanged(checked) {
    const value = checked ? 1 : 0;
    this.props.sku.saveInfo('costType', value);
    this.props.sku.saveInfo('wageWithPrice', "");
  }

  onSelectChannel = (option) => {
    this.props.sku.saveInfo('channel', _.get(option, 'value', null));
  }

  checkSkuDuplicate() {
    const skuList = this.props.sku.toJS().items;
    const skuCode = this.props.sku.toJS().info.code;
    if (skuCode) {
      const duplicateCodes = filter(skuList, item => {
        if (this.state.skuCodeEdit) {
          return item.code === skuCode && item.code !== this.state.skuCodeEdit
        } else {
          return item.code === skuCode && item.code
        }
      })
      const duplicated = duplicateCodes.length ? true : false;
      this.setState({ isDuplicated: duplicated });
    }
  }

  getChannelOptions = () => {
    const channel = this.props.channel.toJS().info;
    return (channel || []).map((c, index) => {
      return {
        label: c.channel_name,
        value: c.channel_name,
      };
    });
  };

  isValid() {
    const sku = this.props.sku.toJS();
    const info = sku.info;
    const validCode = info.code && info.code.length;
    const validName = info.name && info.name.th && info.name.th.length;
    const validType = info.typeOfWork > 0;
    const validSurveyCost = isPositiveNumber(info.surveyCost) || !info.surveyCost;
    const validWage = isPositiveNumber(info.wage) || !info.wage;
    const validWageWithPrice = isPositiveNumber(info.wageWithPrice) || !info.wageWithPrice;
    return validCode && validName && validType && !this.state.isDuplicated
      && validSurveyCost && validWage && validWageWithPrice;
  }

  checkChangeData() {
    const { initialData, info } = this.props.sku.toJS()
    let infoChange = { ...info }
    const { initInfo } = this.props.permission.toJS()
    const permission = [
      { key: 'typeOfWork', name: 'Type of work' },
      { key: 'surveyCost', name: 'Survey fee (included VAT)' },
      { key: 'wage', name: 'Installation fee (included VAT)' },
      { key: 'costType', name: 'Toggle material cost' }]
    // { key:'wage_with_price', name: 'Toggle material cost' }]
    if (!this.state.isEdit) {
      initInfo.event = "create_sku"
      initInfo.function_name = "create_sku"
      initInfo.reference_code = info.code
      initInfo.remark = `SKU ${info.code} is created by `
      this.props.permission.setInfoLogActivity([initInfo])
      this.onSubmit(true)
      return
    } else {
      const permissionFilter = permission.map((data) => {
        const dataPermission = {}
        let key = data.key
        if (initialData[key] !== infoChange[key]) {
          if (key === 'typeOfWork') {
            const typeOfWorks = this.props.typeofwork.mapIdAndLabel();
            initialData.typeOfWork = typeOfWorks.find((item) => item.id === initialData[key]).label
            infoChange.typeOfWork = typeOfWorks.find((item) => item.id === infoChange[key]).label
          }
          if (key === 'costType') {
            initialData.costType = initialData.costType === 1 ? 'on' : 'off';
            infoChange.costType = infoChange.costType === 1 ? 'on' : 'off';
          }
          dataPermission.event = "update_sku"
          dataPermission.function_name = "update_sku"
          dataPermission.reference_code = initialData.code
          dataPermission.remark = `SKU ${initialData.code} is edited from “${data.name}: ${initialData[key]} to ${infoChange[key]}” by `
          return dataPermission
        }
      }).filter(notUndefined => notUndefined !== undefined) || [];
      if (permissionFilter.length > 0) {
        this.props.permission.setInfoLogActivity(permissionFilter)
        this.onSubmit(true)
      } else {
        this.onSubmit(false)
      }
    }
  }

  async onSubmit(verify) {
    let status = false
    if (verify) {
      status = this.props.permission.getUserFromEmail()
      if (!status) {
        return
      }
    }
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไข SKU' : 'กรุณายืนยันการเพิ่ม SKU ใหม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          if (this.state.isEdit) {
            await this.props.sku.updateSku(this.state.id);
            await this.props.sku.createSkuHistory(
              this.state.previousInfo,
              this.props.sku.toJS().info,
              this.props.permission.userName
            );
          } else {
            await this.props.sku.createSku();
          }
          this.props.permission.saveUamActivityLogs()
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      this.props.sku.clearInfo();

      setTimeout(() => {
        this.onCancel();
      }, 500);
    }
  }

  onCancel() {
    this.props.history.push(`${config.publicUrl}/superadmin/sku`);
  }

  onWageChange(key, e) {
    if (e && e.target) {
      this.props.sku.saveInfo(key, e.target.value);
    }
  }

  render() {
    const SkuJs = this.props.sku.toJS();
    const item = SkuJs.info;
    const title = this.state.isEdit ? 'แก้ไข SKU' : 'เพิ่ม SKU ใหม่';
    const costType = item.costType === 1;
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{title}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="row">
                    <EnTextFormGroup
                      className={this.state.isDuplicated ? 'is-invalid-input' : ''}
                      containerClassName="col-md-6 pb-0 mb-0"
                      type="text"
                      label="SKU Code"
                      value={item.code}
                      onChange={this.onCodeChanged.bind(this)}
                      isError={this.state.isDuplicated}
                      errorMessage='ข้อมูลซ้ำ'
                      validations={[required]} />
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="ชื่อ SKU"
                      value={item.name.th}
                      onChange={this.onNameChanged.bind(this)}
                      validations={[required]} />
                  </div>
                  <div className="row">
                    <FormGroup label="ช่องทางการขาย" containerClassName="col-md-6">
                      <Select
                        searchable={true}
                        clearable={false}
                        onChange={this.onSelectChannel}
                        options={this.getChannelOptions()}
                        value={item.channel} />
                    </FormGroup>
                  </div>
                  <div className="row">
                    <FormGroup containerClassName={`col-md-6`} label="ประเภทงาน" validations={[required]}>
                      <Select
                        searchable={true}
                        clearable={false}
                        onChange={this.onWorkTypeChanged.bind(this)}
                        options={this.getTypeOfWorkOptions()}
                        value={item.typeOfWork} />
                    </FormGroup>
                    <FormGroup containerClassName="col-md-1" label="ค่าแรง">
                      <EnToggle checked disabled />
                    </FormGroup>
                    <FormGroup containerClassName="col-md-1" label="ค่าของ">
                      <EnToggle checked={costType} onChange={this.onCostTypeChanged.bind(this)} />
                    </FormGroup>
                  </div>
                  <div className="row">
                    <FormGroup>
                      <EnTextFormGroup
                        label="ค่าแรงสำรวจ (รวม VAT)"
                        containerClassName={costType ? 'col-md-4' : 'col-md-6'}
                        type="text"
                        value={item.surveyCost}
                        onChange={this.onWageChange.bind(this, 'surveyCost')}
                        validations={[wagesErrMsg]}
                      />
                      <EnTextFormGroup
                        label="ค่าแรงติดตั้ง (รวม VAT)"
                        containerClassName={costType ? 'col-md-4' : 'col-md-6'}
                        type="text"
                        value={item.wage}
                        onChange={this.onWageChange.bind(this, 'wage')}
                        validations={[wagesErrMsg]}
                      />
                      {costType &&
                        <EnTextFormGroup
                          label="ค่าของพร้อมติดตั้ง (รวม VAT)"
                          containerClassName="col-md-4"
                          type="text"
                          value={item.wageWithPrice}
                          onChange={this.onWageChange.bind(this, 'wageWithPrice')}
                          validations={[wagesErrMsg]}
                        />

                      }
                    </FormGroup>
                  </div>
                  <div className="row">
                    <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={this.checkChangeData.bind(this)}>
                      <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                    </EnButton>
                    <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                      <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                    </EnButton>
                  </div>

                  <div className="duration">
                    <div className="header-duration">
                      <span>ประวัติการทำรายการ</span>
                    </div>
                    <div className="duration-form">
                      <div
                        style={theme.activityLogTextArea}
                        dangerouslySetInnerHTML={{ __html: this.activityLogInTextarea() }}
                      />
                    </div>
                  </div>

                </FormValidation>
              </div>

            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'typeofwork', 'sku', 'channel', 'permission')(observer(SkuPage));

import React, { Component } from 'react';
import { control } from 'react-validation';
import * as _ from 'lodash';

const CustomInput = control(
  React.forwardRef(({ error, isChanged, isUsed, ...props }, ref) => {
    const propInput = _.cloneDeep(props);
    let className = propInput.className || '';
    delete propInput.className;

    if (error) {
      // className += ' is-invalid-input';
    }

    const handleSelect = () => {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        let node = range.startContainer;

        // Traverse up the DOM tree to find if we're inside a <p> tag
        while (node !== ref) {
          if (node.nodeName === 'DIV' && node.classList.contains('argument-badge')) {
            node.remove();
            break;
          }
          node = node.parentNode;
        }
      }
    };

    return (
      <>
        <div style={{ position: 'relative' }}>
          <div
            ref={ref}
            contentEditable={!propInput.disabled}
            dangerouslySetInnerHTML={{ __html: propInput.value }}
            placeholder={propInput.placeholder || ' '}
            onBlur={(e) => {
              const newValue = e.target.innerHTML;

              let modifiedHtml = newValue.replace(/(<div class="argument-badge"[\s\S]*?<\/div>)|<[^>]*>/g, (match, p1) => {
                return p1 || '';
              });

              if (propInput.onChange) {
                propInput.onChange({
                  persist: () => {},
                  target: {
                    value: modifiedHtml,
                  },
                });
              }
            }}
            onInput={(e) => {
              propInput.adjustHeight();
              handleSelect();
              const newValue = e.target.innerHTML;

              if (propInput.calculateRemainingLength) {
                propInput.calculateRemainingLength(newValue);
              }
            }}
            className={`custom-textarea ${propInput.argAddable ? 'arg-addable' : ''}`}
            style={{
              borderColor: propInput.isOverMaxLength || (isChanged && error) ? 'red' : '#ced4da',
              backgroundColor: propInput.disabled ? '#fff5d940' : '',
            }}
          />
          {propInput.maxLength && propInput.showMaxLength && (
            <span
              className="custom-remaining-length"
              style={{
                color: propInput.isOverMaxLength ? 'red' : 'gray', // Change color if over max length
              }}
            >
              {Math.abs(propInput.remainingLength)}
            </span>
          )}
        </div>
        {isChanged && error ? (
          isChanged && error && <span style={{ color: 'red' }}>{error.props.children}</span>
        ) : propInput.isOverMaxLength ? (
          <span style={{ color: 'red' }}>ข้อความเกินกำหนด กรุณาใส่ข้อมูลให้ถูกต้อง</span>
        ) : null}
      </>
    );
  }),
);

export class EnTextAreaEditor extends Component {
  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
    this.state = {
      remainingLength: props.maxLength - this.getModifiedLength(props.value),
    };
  }

  componentDidMount() {
    this.adjustHeight();
  }

  componentDidUpdate(prevProps) {
    if (this.props && this.props.value !== prevProps.value) {
      this.calculateRemainingLength(this.props.value);
    }
  }

  value() {
    return this.input.value;
  }

  clear() {
    this.input.value = '';
  }

  getModifiedLength = (html) => {
    if (!html) return 0;
    // Replace the specific HTML structure with a single character
    const removeTag = /<[^>]*>[^<]*<\/[^>]*>/g;
    const removeExceptContent = /<[^>]*>/g;
    const removeDynamicField = /<div.*?<\/div>/g;
    const modifiedHtml = html
      .replace(removeDynamicField, '|')
      .replace(removeExceptContent, '')
      .replace(/&nbsp;/g, '')
      .replace(/&amp;/g, '&')
      .replace(removeTag, '');

    // const modifiedHtml = html.replace(/<div.*?<\/div>&nbsp;/g, 'X');

    // Remove all other HTML tags
    const textContent = modifiedHtml.replace(/<[^>]*>/g, '');
    return textContent.length;
  };

  adjustHeight = () => {
    const textareaRef = _.get(this.textareaRef, 'current._reactInternalFiber.child', null)
    if (!textareaRef) return;

    const textarea = _.get(textareaRef, 'child.child.stateNode', null);
    if (!textarea) return;

    textarea.style.height = 'auto';
    const newHeight = Math.min(textarea.scrollHeight, 3 * 1 * parseInt(getComputedStyle(textarea).lineHeight));

    textarea.style.height = `${newHeight}px`;
  };

  calculateRemainingLength = (html) => {
    this.setState({
      remainingLength: this.props.maxLength - this.getModifiedLength(html),
    });
  };

  render() {
    let propInput = Object.assign({}, this.props);
    propInput.className = `${propInput.className ? propInput.className : ''} form-control`;

    const isOverMaxLength = this.state.remainingLength < 0;

    return (
      <CustomInput
        ref={this.textareaRef}
        adjustHeight={this.adjustHeight}
        remainingLength={this.state.remainingLength}
        isOverMaxLength={isOverMaxLength}
        calculateRemainingLength={this.calculateRemainingLength}
        {...propInput}
      />
    );
  }
}

export default EnTextAreaEditor;

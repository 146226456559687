/* global FileReader */

import React from 'react';
import fixOrientation from 'fix-orientation';

export class EnPicture extends React.Component {
  defaultImage = `data:image/png;base64, /9j/4AAQSkZJRgABAQIADQANAAD/2wBDAAUDBAQEAwUEBAQFBQUGBwwIBwcHBw8LCwkMEQ8SEhEP
  ERETFhwXExQaFRERGCEYGh0dHx8fExciJCIeJBweHx7/2wBDAQUFBQcGBw4ICA4eFBEUHh4eHh4e
  Hh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh7/wAARCAJmAlgDASIA
  AhEBAxEB/8QAHQABAAIDAQEBAQAAAAAAAAAAAAcIBQYJBAMBAv/EAFoQAAIBAwIDAwkDBggJCgMJ
  AAABAgMEEQUGBxIhCBMxFBgiQVFVYZTTMnGBFSNCgpGhFlJicpKxtME3RFNzdaKywtEJJCUzNDiE
  k8PhQ1SDY2R0dqOz0vDx/8QAGwEBAAIDAQEAAAAAAAAAAAAAAAMEAQIFBgf/xAAzEQEAAgECBAQE
  BAYDAQAAAAAAAQIDBBESITFRBRVBkRMyYdEGInHwFDOBocHhQrHxYv/aAAwDAQACEQMRAD8AuWAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAaru3iPsTanlUNf3XpVncWvJ31r36qXMebl5fz
  MM1HlSi+kfB58OpiZiOrMRM9G1AgDdvar2JpvlVHb+m6rr1xT5O5qcitratnlcvTnmpHCcl1p9XH
  Hg+Y0XXe13rVazjHQtl6fY3KqJyqXt7O5g4YeUowjTaecdeZ+D6dcrSctI9U0abJPotyClfnY8Rf
  cu1flbj6w87HiL7l2r8rcfWNfj1bfwmRdQFK/Ox4i+5dq/K3H1jZbPtfXMLSjG82DSrXKpxVapS1
  V04Tnj0nGLpScU3nCcnj2vxM/GoxOlyR6LXgrhoPa32pWtJy13autWNyqjUKdlUpXUHDCw3KbptP
  OenK/BPPXC2DQe1Fwv1G7nQvJa1o1ONNzVe9slKEnlLlSoyqSz1b6pLo+ucJ7fEp3aTgyR6JvBGu
  kceOEmqahSsbbedpTq1c8srq3rW1NYTfWpVhGEfD1tZeEurRtWk732Xq+oUtO0nd+39Qva2e6t7X
  UqNWpPCcniMZNvCTfT1Jm0WifVrOO0dYbAADLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAahxH4lbN4f2bq7k1ilRuXT56NjS/OXNbpLl5
  aa6pNwlFTliGejkisvEDtV7q1Gvc2uzdNtNEsn6NK6uIKvd9Jt8+H+bjzR5U4OM8ZliT6NaWyVr1
  S48N79IWv3ZuzbO07Pyrcmu6fpVOVOpUpq4rxjOqoJOSpw+1UayvRim+qWMtFf8AiJ2sNLtqVS12
  JotW+uVUlDy3Uounb4jKOJRpxlzzUo82OZ03H0W0+sSqGralqOr6hV1HVr+71C9rY7y4uq0qtWeE
  orMpNt4SS6+pI8pXtntPRdx6Stedubf988ZOI+8O+papua7oWVXvYuysX5NR7up9qlJQw6kMeilU
  cnjPXq86AAQzMz1Wa1isbQAAw2AAAAAAAAAAB79C1rWNBvpX2hatf6VdypOjKvZXE6NRwbTcOaDT
  5W4xePDMV7CRtpdoPipt7yWl/CH8r2tvzf8AN9Toxr95zZ+3V6VXhvK9P1JeHQioG0WmOjS1K26w
  tfsvtb27pRo7z2tVhUjTk5XOkTUlOfN6KVGq1yrlfV95LqvDD6TLsfjJw43g6VHS9z2lG9qd1FWd
  6/JqzqVOkacVPCqTz0apuSzjr1WedYJIz2jqgvpKT05OqYOa20uI2+9qeSx0DderWdC05+4tfKHO
  2jzc3N+Znmm8uUn1j4vPj1Jq2N2stetO5tt4bftNUpLuqcruxk6FZRXSpUlB5hUm1hqMe7jlNdE1
  yzVz1nqrX0l46c1vwRzw+42cOt6Uaasdeo6dfVKsKMbDU5Rt68pzm4QjBN8tSUmlhU5SfpRTw3gk
  YliYnnCtas1naQAGWAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAI17QHFWz4XbXpXEbbyzWdQ56em20k+7copc1So1+hHmj6KfNJtJYWZRxMxEby2rWbTtDIcVO
  K+zeG9KnHcN7VnfVqfe0NPtKfeXFSHMouWG1GK8es5RzyyUctNFUOJvaR33uvntNFqfwW0149Cxq
  t3MvsP0q+FJYlF45FDpJxlzES7j1rVdxa5d63rd9VvtRvKneV69R9ZPwSwuiSSSUVhJJJJJJGPKd
  8s26dHSxaatOvOX5GMYRUYxUYpYSSwkj9AIlkAAAAAAAAAAAAAAAAAAAAAAAAAAAnXg/2kd1bVrx
  sd21Lvc2kS5YqVWqnd2+Z5lONSSzV6Sl6E36opSgk04KBtW01neGl6VvG1odPtrbj0LdOkU9X27q
  trqdlUx+doTUuVuKlyyXjCaUlmMkpLPVIypzh4PcSNd4abojq2ky7+0rcsL+wnNqndU0/B/xZrLc
  Z4zFt+Kcoyv9w+3hoO+tsW+4dvXff2lb0ZwkkqlCokualUjn0ZrK6eDTTTaabt48nH+rmZsE45+j
  YAASoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAYXfO59K2btPU
  Nza1OrGxsaanUVKHPOTclGMYr2ylKMVlpZfVpZZzr4m7y1Xfu87/AHJqtWq5V6jVtQnU51a0FJuF
  GLSSxFPxSWXmT6ybJQ7YPEmrure9TaVjLl0fb9xOlJxlNeUXSSjUlKLwvzbUqcej/Tak1NJQUU8t
  +Kdo6OnpsPBHFPWQAEK0AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEi8AeJ1zww3m9Snb1bzSbymq
  Go2sKjTlDmTVSCyoupDrjm6NSnHMebmUdAzE7TvDW1YtG0up1nc217Z0byzuKVzbV6catGtSmpwq
  QksxlGS6NNNNNeJ9SsHYk4kUq+nT4a6k+Wvbd7daXVlKEYzpuSlUoJdJOalKdRfabTn9lQWbPl+l
  otG7kZcc47cIADZGAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABFXaj36
  tjcLruNpculrOrqVjYd3V5KlPmX5ytFxlGceSLeJxzyzlTz4kqlBu1bva53dxa1CzTq09O0KpPTb
  ajPK9OEmq1Rx5msymmuZYzCNPKyiPLbhqn0+Pjvz6IlABRdYAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAZXaGv6jtXc+nbi0mr3d7p9xGtSzKSjPHjCXK03CSzGSTWYtr1nSnaGv6dunbGnbi0mp
  3llqFvGvTzKLlDK6wlytpTi8xksvEk16jmCW/wCw3vry/b19sC9nmvpnNeWHTxt5z/OR6Rx6NSSl
  mUm332EsQJ8Ftp2VNXj3rxR6LKAAtuaAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAANE4+7zq7D4Vaxr1nUow1Hkjb2CqVYwbrVJKKlFSUlOUE5VOTD5lTaeFlrnOWU7ce+vL9
  w2OwLKebfS+W8v8Ap43E4fm49Y59GnJyzGTT77DWYFaynmtvbZ1NLj4abz6gAIVkAAABJt4Sy2br
  tThZvvcsYVdO2/c07eSTVxdfmKbT9ac8cy/m5EzEdRpQLEbc7MtzJRqbh3LSpdetKxoueV/PnjD/
  AFWSDoXAThzpqbr6fd6pPOVK8uZdPwhyr9qZFOekNopKmy6vCMjpeg65qs+TTNH1C9l7KFtOf9SL
  36VtHaulShLTduaTazgsRnStIKa/WxkzZHOo7Q2+GozpvCjiLqEsUdpalT+NxBUf9toz1lwB4k3E
  1GtptnaL+NVvabX+o5FyAaTqLM8EKsab2aN1Va0FqGv6PaUXnmlRVStOPTp6LUU+v8pGao9l9L/r
  t7Sf8zTUv66jLGgx8e/c4IV5h2YLHPp7yu2vhYxX+8J9mCxz6G87xL42MH/vFhgY+NfuzwQrlW7L
  8f8A4O9pr+fpqf8AVURhNR7NG6qVaasNe0e6or7EqqqUpy6euKjJL+ky1AHx792OCFOL3gDxJt5u
  NHTbK7X8alewS/13Ewmp8IuI+n9a21L6ov8A7u41v9hsvEDaNRY4Ic+9V2xuTSVnVNA1SyXtr2k4
  L9rRiZJxeJJp+xnRoxmq7e0DVnzapomm30vbcWsKj/a0bxqO8MfDc9wXV1rglw31OFT/AKB8iqze
  e8tK86bj90cuP7jQtw9mbTqnPPQNyXNu8ehSvKKqJv4zjy4X6rN4z0lrwSrOCTtz8CuIWixnUpab
  S1ahBZc7Crzt/dB4m/wTI51CyvNPup2t/aV7WvTeJ061NwlF/FMli0W6NZjZ5wAZAAADdeCG8Kux
  uJ+i675X5NZK4jQ1FyU3B2s2o1eaMHmXKvTS6+lCLw8YNKBmJ2ndi0RaNpdUwQ/2Rd30tz8H7DT6
  146+paG3Y3MJqnGUaabdBqMOvJ3fLBSaTbpz8Wm3MB0KzvG7i3rNbTEgAMtQAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA8usajZ6RpF5q2o1u4srK3ncXFXlcuSnCLlKWEm3hJvCT
  Z6iGu2PuG50HgrdW9qqsamsXdLTpVadZ03Tg1KpPwXpKUaUqbjlZU3n2PFp2jdtSvFaIUm3fr+o7
  q3PqO4tWq95e6hcSr1MSk4wy+kI8zbUIrEYpt4ikvUYoA57tRGwAZbaG3tT3VuG10PSKKq3VzLCy
  8RhFdXKT9SS6mJnZljKNKrXrQo0ac6lSbUYwgsuTfqS9ZNHDns+bg1unSv8Ac9d6JZyw1Q5ea5mu
  njHwh6/Hqv4pOPCrhXt3YlpCrSpRvtXa/O39WC5k8Yapr9CPj4dXnqzfyrfP6VSRTu07ZXDLZe0Y
  wnpWjUZ3UMPyu5Xe1sr1qT+y/wCakbiAV5mZ6t9gAGGQAAAAAAAAAAAAAAAAAAAAAMdr+haLr9o7
  TWtLtNQo4eI16Sly59ab6p/FdTIgyIK3z2ctCv41Lnal/U0q4eWreu3VoP2JP7cfv9L7iAN9bC3T
  su57vXdLqUqMpYp3NP06NTx8JrpnpnDw/gX0PjfWlrfWlS0vbajc29WPLUpVYKcJr2NPoyWme0de
  bSaRLnUCxXGTgHGhQuNd2NCbhCLnW0ttyeF4uk31f819fY30RXVpp4aw0W6Xi0bwjmNgAGzCY+yH
  vOltTi1b2N7VrxsdegtOlGM58ka8pxdCbhFPmfN+bTeOVVZPKXNm+By30fUbzR9Xs9W06t3F7ZXE
  Li3qcqlyVISUoyw008NJ4aaOnG2tWttf27pmu2cKsLbUbSld0Y1UlOMKkFOKkk2k8NZw395awW5b
  OdrKbWizIAAsKYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAVA7e2sd/u7bWge
  T8vkVhVvO+588/f1OTl5cdOXyfOcvPN4LHW35zx7S2rW2tcdN13lrCrCnTu42jVRJPnoU4UZvo30
  cqcmvhjOPAhzztXZa0ld8m/ZHQAKbphaTsg7VVltq93Xc0cV9QqOhbSa6qjB+k0/ZKfT9RFZdIsL
  nVdVtNMs6bqXN3WhRpRXrlJpJftZf/a2j2239uafolml3NlbwoxeMc2F1k/i3lv4sg1Fto2b0jmy
  QAKaUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAKX9pHa0Ns8TLudtSVOy1OPllBR8IuTanH4emm8e
  pNF0CHu1ftn8s8PIazQhzXOjVe9eM5dGeIzWPv5H8FFkuG3DZreN4VFABeQhe3sZ6pa3/ArT7K2p
  VIT0u8ubWu5RSjKcqjr5jh9Vy1o9Xh5UvvdEi1XYE1Ppu3Rqt/8A/K3NvZyrf5yNWpGGf8ypSS/i
  J+omwztZW1Vd8e/ZakAFxywAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA5ebl1
  a51/cep67eQpU7nUburd1o0k1CM6k3OSim20st4y3950o4gatdaDsPcGu2MaMrvTtLubuhGtFypu
  dOlKcVJJpuOUspNPHrRzHK2o9F7RR1kABWX0wdlDbf5X4jS1etT5rbSKDrZccxdWfowX3/akv5pb
  siHsn6BHSuGf5VnCKr6tcSrc3r7uD5IJ/ipv9Yl4o5rb3TVjaAAETYAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAPNq1jb6npd3pt3Fyt7ujOhVinjMZRcX+5npAHPPcel3Gia/qGj3WO/sridCePBuMms
  r4dDwEydrXb/AOTOI1PWKUJKhq1tGo5YxHvYehJL8FB/rENnRpbirEoJjaQm7sU6vb6bxshZVqVa
  dTVtNuLOjKmk4wnHlrtzy1iPLRkumXlx6Yy1CJJ3ZWu7Wy4+7XrXlzRtqUqtejGdWooRc6lvVhCC
  b/SlOUYpeLckl1ZLT5oQ5o3xy6CgAvuOAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAA07jfd2tlwd3hWvLmjbU5aLdUYzq1FCLnUpShCKb/AEpTlGKXi3JJdWc3S/3a1/7vm5v/AAn9
  rolASrqJ/NDo6OPyTIfW0oVbq7o2tCDnVrTjThFLLcm8JHyN+7Pmkflni5oVGSl3dtWd3NpZx3Sc
  1n4OSivxK1p2jdchc3bel0tE29p2j0GnTsranQi8YzyxSz+OMmQAOasAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAACG+1voS1HhxR1enTUq2lXUZuWfs0qnoS/wBbu/2FSDoBv3R1r+ytZ0bu41J3
  dnUp01Lw7zlfI/wlh/gUAknGTi1hp4Zc08712RXjm/DauDv+FzZv+nrH+0QNVNq4O/4XNm/6esf7
  RAs16orfLLpSADoOIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAirta/8Ad83N
  /wCE/tdEoCX+7Wv/AHfNzf8AhP7XRKAlTP8AM6Wj+Sf1CfOxnpbq7l13WXjltrSFul8ak+bP7Kf7
  yAy0vY1sFS2drWp+u5v40fwpwT/9RlTNO1JXadU7AAoJgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAKDcTdMho3EPX9MpQ7ulQv6qpR9kHJuP7mi/JTDtO23k/GXVpKHLGvToVY/H81FN/tTLGnn
  80w0v0RmbVwd/wALmzf9PWP9ogaqbVwd/wALmzf9PWP9ogXa9UF/ll0pAB0HEAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAaB2i9H/AC5wQ3ZZeUeT93YSvOfk5s+TyVflxlfa7vlz
  6s5w8YOdZ0u4rW1ze8Lt2Wdnb1bm5r6LeUqNGlBznUnKhNRjGK6tttJJeJzRKuo6w6Ojn8swFwuy
  faO24SUqz/xq9rVl+DUP9wp6XZ7OMOTgvt5Yw3CtL9teoUNR8q/TqkIAFNKAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAABU7th0I0+JNhWiutbSqbl96qVF/VgtiVj7Z9ry7g29e4/wCstatJ/qzT
  /wB8mwfO0v0QAbXwd/wubN/09Y/2iBqhKHZUtre74/bXpXVvSr041Liqo1IKSU4W1WcJYfrjKMZJ
  +ppNdUX6xvMK+SdqzLoGADoOKAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4tVv
  4WVLCxKtJejH+9/A1veKRxWbUpN54avZUnCnBzqSjCK8XJ4SOWd7a3Vjd1LS9tq1tcUpctSlWg4T
  g/Y0+qZ0ZuK9a4qd5WqOcvaymHai0+Vlxh1Gs/s3tCjcR+7kUP64M538T8a222zrYdN8GOc9UXl6
  uCVDybhNtqnjGbCE/wCl6X95RUv1wzgqfDjbUUsY0m1//aiQajpCxTq2EAFRKAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAABXXtqRXk+1p46890v3UixRX/toUXLQtu18dIXVaH9KMX/ukuH54a36
  KyE19jLRZX/Gahq867t7fR7OvcSlKnmFSU49yqbllKLaqykvHPdtY9ahQtR2N7KNLY+r3/JidfUe
  75vbGFOLX75yLlsnw44kE044mq1UZKUVKLTTWU14M/TTba5r20+ahUlBvxx4P716zZdK1CF7Sw8R
  rRXpR/vXwLODV1yztPKXOz6S2KN45w9oALaoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAD+a1RUqM6sk2oRcnjx6GnXdedzcTrT8ZPw9i9SNh3HUcNN5VjE5qL/AK/7jWTla/JvaK9nV0GO
  IrNwq52y9PVLduh6ov8AGbKdB/8A055/9QtGQL2zLCFTauhap+nQvp0F91SDl/6aKmGdrwvX6KvH
  QDYNN0ti7fpPxhpltF/hSiUASy0l6zododJW+iWNBLCp21OGPuikS6npDTG9gAKqUAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAACEO2PS5uH+lVsfY1OMf20p/wDAm8hrtfxzwttX/F1ak/8A9Oqi
  TF88NbdFSS6XZnsXY8HNHcqfJO4lWry/lc1SXK/6KiUtL+cOLKem8P8Ab9hUWJ0NNoQmv5SprP7y
  xqJ/LENKdWfPtZ15W1zTrx6uL8PavWj4gqRMxO8JJiJjaW7UpxqUo1I/ZklJfcz+jGbbq8+n923H
  NOTSS8cPr1/HJkz0GK/HSLPP5acF5r2AASIwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAG
  D3V/i36/9xgzObq/xb9b+4wZw9Z/On9+juaP+TH79QhntfyguGNopL0papSUfv7uoTMQV2yriEdl
  aNacy56mo94lnriNOa/3iLF88J7dFXbZc1xTj7ZpfvOi0YqMVFLCSwjnrtq2d5uPTLRde+u6VP8A
  bNL+86Fk2p9GuMABVSAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAARR2rbfvuENzU/wAheUKn
  +s4/7xK5HXaUpqpwX15vxgqEl/59P/ib4/mhi3RSqEXKaivFvCOi1tFQtqUF4Rgkv2HOy3n3dxTq
  P9GSf7zorReaUH7Yon1Po0xv6ABVSM5tX/Gf1f7zOGD2r/jP6v8AeZw7mj/kx+/Vw9Z/On9+gACy
  rAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMduOnz6a5c2O7mpff6v7zWDdq1NVaU6Us8s
  4uLx8TS6kJU6kqc1iUW017Gjla+m1ot3dbQX3pNez+Sp/bLuqj4k6LZ8z7uOkd4o/F1ZrP8Aqotg
  VA7YNR1OMVlDOe60emvuzUqP+8rYPnXL9EebGuKFpvXQ7q5ko0KOoUJ1G/VFVIts6BHORNppp4aL
  58J9f/hPw70XWZzc61W2jCu2+rqw9Cbf3yi3+JvqI6SxSW0AAqpAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAI17TV5RteDWsU6s1GVzKhRpJ/pS72MsfsjJ/gSUVu7ZW4XKvou1qM3yxjK+uIp9
  G3mFP8Vip/SRJije8NbTyV1OitjLnsqEvbTi/wBxzqOiGi5/I9lnx8np/wCyibU+jXG9YB+pNvCi
  5P1JLLf4FVI2XbdOUNO5pYxObksezov7jJHytKEba2p0IqOIRw3GOE363j4vqfU9DipwUirz2W/H
  ebAAJEYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa7uS2dO6VxFehV8fhJf8A9/rNiPPq
  Fsru0nReMtZi/Y/UQanF8Wkx6p9Pl+FkifRp5TntaNvjW0/COk0cf0pFx5RcZOMk1JPDT9TKedru
  HJxmoS/j6RSf+vP/AIHI0/zu3foiYsz2NtwKrpOs7Yqzjz29WN5Qj63GSUZ/gmof0isxIHZ83D/B
  3irpNapUcLa8m7Kv1wmqnSOfgp8r/As5a8VZR1naV2wAc9OAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAABRDi/uFbo4ka1q8JqdCdw6Vu14OlD0IP8VFP8S33GjcP8GOGms6nCpyXDoOhb4lh95U9
  BNfFZcv1Sipa09eso7z6B0U02PJp1tBfo0or9yOd9tSlXuKVGP2qk1Ffe3g6K0Y8tKEfZFIan0Mb
  +jJ7dt3Wvu9aThSWXlZ6vw/4/gY2MXKSjFNtvCSXVm3aZaqztI0spyzzSa9bM6PF8S+89IQazLwY
  9vWXpAB2nFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGE3FYuT8soxbePzqz+x/8
  f/8ASlXbJp44s6TUS+1osM/+dVL3ySlFxkk01hp+spf246GnWvEPRrehTreWfkvvJzlJcipSqzUY
  r15Uozbb9Tj8ShmwcOT4lfV0tLn4q/Dn0V9P2EpQnGcG1KLymvUz8Botr98ONfjujYuj66pJzura
  LrYWMVV6NRY/nKRsBAHY53Eq+i6ttetUXPa1Vd28W3lwn6M0vgpKL++ZP5z714bTCes7wAA0ZAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABXHtlbhfNo21aM3hKV9cR9T8YU/8A1P2orkbbxh3Et0cS
  NZ1enNTt5XDpW7Xg6UPQg/xSz97NSOhjrw1iEFp3lnOH1urrfu37aUeaNXU7aEl7U6sc/uL/AJSL
  s82SvuMW36coOUKdadZ/Dkpykn+1IvXpNhO8rZaxRi/Sl7fgviQ5qze8Vr1Zi8UrNrPbt2xy1eVV
  Fx692vj7f6zOn5FKMVGKSSWEl6j9OthxRirww4ubLOW3FIACVEAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAABUXt8aRb0dy7W16NWs7i8s69nUptru4wozjOLSxnmbuJ5y2sKOEuubdFd
  e3lp1nV4daFq06Ob221dW9KpzP0adWjUlOOM4eXRpvLWVy9MZeY8sb0lPpp2yQpsACi6zeuA+5f4
  L8TtKvKtXu7S4n5JctyxHkqdMv4Rlyy/VLwnOVNppp4a8C9vB/ci3Xw60jV51Oe5dFUbrLTfew9G
  Tf345vukitqK9LJKT6NtABVSAAAAAADRt9cV9lbPnO31DVFc3sOjtLNd7VTz4Pryxfwk0yHNx9pj
  V6tRw2/t6ztafVc95UlVk/Y8R5Uvu6klcVrdIazaIWcBS+6468Ta8pY16nRi3nlp2dFY+5uLf7zy
  0+NHEyEuZborP4St6TX+yb/w9mOOF2gU90ntBcRbOtz3V1p+pR/iXFpGK/bT5WSHtLtK6XcSp0Nz
  aHWspNJSuLSfewz7XB4aX3OTMTgvBF4T+DE7Y3JoW59P8v0HVLe/oeEnSl6UH7JRfWL+DSMsRbbN
  wGlb84o7M2ZKdDVNTVa+iv8Asdqu8q/ivCPt9JohrcXaZ1SpU5dv7ctLemsrnvakqsn7HiPKl+1m
  9cdrdIazaIWaBTC747cTK8pOOuUqEZPPLSs6SS+Cbi3+88sONPEyE+dbnqt+x29Jr9nKb/w9mOOF
  2QU80ntA8RbOtz3V3YajD+JcWkYr9tPlZIe0+0rpleUKO5tCrWcmkpXFnPvIZ9rhLDS+5yZicF4I
  vCwAMPtbdG390WXlmgatbX9JfaVOXpw/nReJR8PWkZgimNm4ADAAAAaZxr3Gtr8NNY1KFRQuZ0Xb
  W3pYfeVPRTXxSbl+qbmVq7Y+5O8vtI2pQqPlowd7cxUlhylmME17UlJ/rIkx14rRDW07QryAC+hS
  52RaF1c8cdLo0bepVt5W9w7ucKbl3VJU21Jv9FOapxz/AC8eLRfaEIU4KEIxjFeCSwkVB7BOj9/u
  /cuv+U8vkVhSs+55M8/f1Ofm5s9OXyfGMPPN4rHW35bwViI3c3V3mb7b9AAE6oAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEVdrPT/wAocBdwOFg7yvbO3uKXLQdSdLlrw56kcJuO
  Kbqc0vVHmy8ZJVMVvLR3uHaGs7fVz5K9TsK9mq/d8/dd5TlDm5crmxzZxlZx4oxaN4mG1J4bRLmC
  ADnO2FiOxxuXku9W2nXqejVir22TaxzLEaiXtbXI/wBVldzYOHW4qu1N7aVr1Ny5bW4Tqxj4ypv0
  Zx/GLaNcleKswzWdpX7B/FvWpXFvTuKFSNSlVipwnF5UotZTXwwf2c5OAGH3luTStp7eudc1mv3V
  tQXRLrOpJ+EIr1yf/u8JNmYjcfXc+v6RtrR6ura3e07O0peM5vrJ+qMV4yb9iKrcVuOev7nqVdO2
  /Kto2kPMfQli4rx/lyX2U/4sfa02zTuKO/tZ39r0r/UJulaUm42lnGWYUIf3yfTMvX8EklqJcx4Y
  rznqitbcbbbbeW/WACZoALq8In7g/wAAquqW9DW96urbWs0p0dPg+WrUXjmo/wBFP2Lr18YmtrxW
  N5ZiN0F6Xp2oapdws9Nsbm9uJvEaVCk5yf3JdTftE4IcSNUhTq/kNWVKf6V3XhTcfvjnmX7C4O3t
  B0bb1hGx0TTLWwt1j0KNNR5sLGZPxk/i8syRXtqJ9IbxRWTa3AfiRt/U6OqaVunSdOuoYfPRrVW8
  fxWuTEl7U+jJW3pofFHW9sW2l6fuHRdLunDF9dUFVhOs89FB4bgseOOufBpdCRQRTkmZ3ltFYhUz
  UuzlvylTnWo3+jX1Tx5YXE4yl+MoJfvNK3Fws3/oMHUv9sXsqUYuUqlulXhFLxbdNvH4l6AbxqLe
  rHBDnLJOLakmmvFM/C9e/OGu0N50Z/lbS6dO7l4XtulTrp+1yS9Lw8JZRVHi3wv1vh9exnXflulV
  pctC+pwwm/4s1+jLHq8H6m8PE9MsX5NJrMNCABK1e3RdV1LRdQp6hpN9cWV1SeYVaM3GS/Z6vgWT
  4QcfbbU50dG3s6NndyahS1CK5aVR+H5xeEHn1r0ev6OCr4Nb0i/VmJmHRtNNJp5T8GCrfZ74xVNH
  rW+1d03Tnpc2qdpd1JdbV+qMn/k/j+j93haRNNZXVFG9JpO0poncABoy/JyjCDnOSjGKy23hJFCO
  Ju4Zbq35q+uuUnTubh9zlYapR9GC/opFs+0VuX+DfC3UZUp8t1qGLGh0z9tPnf8AQU+vtwUpLWnr
  1lHefQABZRr09i7R/wAmcELa98o738rX9xecnJy91yyVDlzl83/Uc2en2sY6Zc1Gl8CdOs9M4M7Q
  trGj3NKekW9xKPM3mpVgqtSXVvxnOTx4LOFhdDdDoUjasOLkne8yAA2aAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA5y8f9JutF41bus7yVGVWpqlW7i6UnKPJXffQTyl6XJUjl
  eCeUm11ejFle3tozobu21uDyjmV5YVLPueTHJ3FTn5ubPXm8oxjCxyeLz0rUULxtaYdjDbipEgAN
  Eq5HZj3T/CLhrQsq9XnvNIl5JUTeW6eM0pfdy+j+oyUymvZr3lQ2nv8AjQ1C5jQ0zVKfk9ec5YhT
  nnNOb9Xj6OX4KTZcpPKyvAo5q8Nk1Z3h+TlGEJTnJRjFZbbwkvaUu4+8RK2+d0yoWdWS0Owk4WcM
  YVR+Eqr+L9XsWPXnMzdqXiBT0Tbj2nptwvynqUMXPI+tG39afsc/D7ub2oqgS4Mf/KWl59AAFloA
  H7CLnNQisyk8JATV2WuH1LcOu1N0atQVTTdMqKNCEl6Na48Vn2qKaePa4+KyWwNZ4XbahtLYelaE
  opVqNFSuGselWl6U+vr6tpfBI2YoZL8Vt01Y2gABG2AAAAAA8G4tH0/X9Eu9G1ShGvZ3VN06kWv2
  Nexp4afqaR7wBQPiDti82du6+0C9zKVvP81UxhVab6wmvvWPueV6jAFne2JtmFxoembroU131rV8
  kuGk8unPLg38FJNfrlYjoY7cVd0Fo2kABuwFpOy1xHlq+nrZesV+a+s6ebCpOXWtRXjT6+LivD+T
  /NKtnv2/q19oWt2esabWdK7tKsatKS9qfg/an4NetM0yU442ZidpdDAYDh/unT95bVs9d06ceWtH
  FakpZdGqvtQfxT/asP1no3juLTNq7du9c1asqdvbwbUc+lUl+jCPtk30/wDYobTvsm3Vn7XO5vyn
  ve129QnmhpNHNTHrrVEpP78R5F8HkhM9uvanc6zrd7q15JSuLyvOvUaWFzSbb/rPEdCleGsQhmd5
  AAbMOlvCa3uLThZtK1u7etbXFHRLKnVo1qbp1Kc1QgnGUZYcZJ5TT6pmzAHSjk4czvO4AAwAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACJe1rtipuXgpqc7eFWpc6PUhqlKEJxi
  mqakqrlzeKVKdWWE024rGfB0GOp15bW17aVrO8t6VzbV6cqVajVgpwqQksSjKL6NNNpp+JzB3JpN
  zoG4tS0K8nSnc6dd1bStKk24SnTm4ScW0m1lPGUvuKuevOJdDR23iaseACuuhtOlcRd86Xpi02w3
  RqdC0UVGFNVm+RL1Rb6xX3YNWAmInqPte3VzfXdW7vLirc3FWTnUq1ZuUpt+LbfVs+IAAAADZuFN
  rTveJe27WrBTpz1Ohzxfg0pptfuNZM/w51GjpG/tB1O5k4ULbUKNSrJLwgprm/dkxboQv4ADmrAA
  AAAAAAAAANJ47WK1DhFuOg3jltHWTx66clP/AHSjReLj5qUNL4R7grTmoyrW3k8E3hydSShhfg2/
  wZR0uaf5ZRX6gAJ2gAAMvtvc24Nt1p1tB1i806VTHedxVcYzx4cy8H+J/W5907i3NWhV1/WbzUHD
  PIq1TMYZ8eWPgvBeCMMBtG+4AAAAbLwqtra94o7Us7y3pXNtX1qzpVqNWCnCpCVeClGUX0aabTT8
  TMMTO0bulwAOi4YAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHNbjF/hc
  3l/p6+/tEzpSc2+NdtcWvGHeNK6t61CpLW7uoo1YODcJ1pShLD9UoyjJPwaaa6Mr6jpC7ovmlqAA
  KroAAAAAAAAAAAuR2dOIVDd+0qWlXleK1vTKUadaEpelWprpGqs+PqUvj19aJTOeWg6vqWhatb6r
  pN3UtLy3lzU6sH1T9nxT8Gn0aLT8LOPOg7gpUtP3RKjo2qfZ72TxbVvipP7D+Eunx64KmXDMTvCS
  tvSUzA/Kc4VKcalOUZwkk4yi8pp+tM/SukAAAAAAGN3Fr2jbe0+Woa3qVtYW0c+nWnjmaWcRXjJ/
  BZZWrjJx4utdoV9D2hGtY6dPMK15L0a1ePsiv0Iv9rXs6o3pjm/RrNoh8O1HxEt9x6tS2vo9dVdO
  06o53FWLzGtXxjo/WoptZ9bb8VhkJAF+tYrG0IpncABlgAAAAAAAAJF7NOk22tcdNqWd1OrCnTu3
  dp0mk+ehTnWguqfRypxT+DeGn1I6Jv7E+k22o8a1eV51Y1NL0y4u6Cg0lKbcKLUsrquWtJ9MdUuu
  Mp70je0I8s7UmV5AAX3GAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAoh
  2ydJutO466jeV50ZU9UtLa7t1BtuMFTVFqWUsPmozfTPRrrnKV7yq3b227021u2haf5TTru473/6
  lCHI3/8AiHlL4N/ZIc8b1WdJbbJ+qqoAKbqAAAAAAAAAAAAADZ9n7/3htPEdD1y6t6Cbfk8mqlHr
  4vklmOfjjJLO3u0xqtGKhr23LS78F3lpWdF/FtS5k/wwV/BrbHW3WGYtMLY2naS2TUpJ3Oma5Qqe
  uMaNOaX486/qPRLtG7ASTVvrkvgrWH/8yowI/gUZ45Wl1btL7aox/wCi9v6rdy/+3nCiv2pz/qNB
  3P2i956jGdLR7Ww0am30nCHfVUvZmfo/siiGAbRhpHoTaXv1zWdW1y9le6xqN1f3MujqV6rm8exZ
  8F8DwAEjUAAAAAAAAAAAAACz/YE06zq6vu7Vp0c3ttb2ttSq8z9GnVlUlOOM4eXRpvLWVy9MZeaw
  F3+xDp15Y8Ga1zc0uSlqGr17m1lzJ89NQp0m+j6enSmsPD6Z8GiXDG91fVTtjlOgALrlAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAARr2nNv/wAIuCG47enStZXFnQV/RnXj
  nu+4kqk3B4bU3TjUisePPhtJskoGLRvGzNbcMxMOVgN144bPq7G4n61oXkvk9kriVfT0udwdrNuV
  PllPrLlXoN9fShJZeMmlHPmNp2dusxaN4AAYZAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOjPZ
  90q20bgntG0tKlWpTq6ZSu26jTfPXXfTSwl0UqkkvhjLfic+9naP/CHd2jaB5R5N+U7+hZ99yc/d
  95UjDm5crOObOMrPtR0+LOnjrKjrbcogABZUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAFau3HsRX+gWXEGyg3caYo2V/6fR285vu5YcsLlqTccRi5S77LeIdKgnUnWNO
  s9X0i80nUaPf2V7bzt7inzOPPTnFxlHKaaym1lNM5wcV9kajw83xe7Z1Gp3/AHPLUt7pUpQhc0ZL
  MZxT/GLw2lKMll4yVc9dp3dHSZN44JaqACuuAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAmrsXa
  P+U+OFte+Ud1+SbC4vOTk5u95oqhy5yuX/r+bPX7OMdcq9JXvsM7YqaZw81Pc1eFWFTW7tQop1Iu
  E6FDmjGSS6xfeTrRfM/0Y4SXV2ELuGu1XK1NuLJP0AASq4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAQf2teFlTfG1ae4tFoVq2v6LSl3dvQowlK9oOUXKm39pyhiU4JN
  +M4qLc01OANbVi0bS2peaW3hysBOnax4Sy2VuKe69FoWdHbWq3ChTt6C5PI7hwzKnytvMZcs5xcc
  KPWPLFRi5QWUbVms7S7FLxeu8AANW4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAHo0uxu9U1Sz0vT6Pf
  3t7cU7a2pc8Yd5VqSUYRzJqKbk0stpdfE85ansN7A/7dxE1K2/jWWk95T/8AOrR5o/dTUoy/y0Wj
  eleK2yPLfgpNlldobf07au19O27pNLu7LT7eNGnmMVKeF1nLlSTnJ5lJpLMm36zKgF9xpncAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY/cei6VuLQ7vRNbsaV9p
  15T7uvQqLpJeK6rqmmk1JYaaTTTSZzx4wcN9e4Z7nekavHv7Wtmdjf04ONK7prxaWXyzWUpQbbi2
  urTjKXR40rjDw20HiXth6Tq0e4u6PNOwv4QTq2tRrxXhzQeEpQbxJJeElGUYsuPjjl1WNPm+HO09
  HOEGU3doOo7X3NqO3tWpOneWFxOhU9CUYz5XhTjzJNwksSjLCzFprozFlPo6kTvG8AAMMgAAAAAA
  AAAAAAAAAAAAAAAAAAB9bO2ub27o2dnb1bm5r1I0qNGlBznUnJ4jGMV1bbaSS8QNp4O7LuN/8RNK
  21TjWVtWqd5e1qcX+Zt4elUlzKMlFtLli5Ll55QT8To3o2nWej6RZ6Tp1HuLKyt4W9vT5nLkpwio
  xjltt4SSy22R/wBnThvS4cbAo2txBvWtR5LrU5ShT5qdRwWKClDPNCn1S9KScnOSwpYUlF3FThhy
  tRl+JbaOkAAJVcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGJ3
  Dubbe3O4/hDuHSdH8o5u48uvKdDveXHNy87Wcc0c48Mr2nrutRtLZPnqqUk2uWHV5X9X4mLzFI4r
  cobVpa07Vh6wYC512rLKt6MYLquaTy/g/h+8xlzc17mSderKeHlJ+CeMZS8EU767HX5ea5j0OS3z
  cmr9ofhjt7iPofeKvQsty2dOSsbxLPNjL7itjq6bbeH4wbbWcyjKke9to69s7VvyZr9k7etKPPTk
  nzQqR9sZLo8ev2F/jXt/bP0Xeug1NI1q354P0qNaPSpQn6pQfqf7n4Mp21U2tvML+PB8Ou0TuoKD
  auJmxNa2Hr0tN1Sn3lCpmVrdwj+buIe1eyS9cfFfFNN6qTxMTG8AAAAAAAAAAAAAAAAAAAAAAAH0
  t6Na5uKdvb0p1q1WShTpwi5SnJvCSS8W36gPmXD7H3CKWh2NtxH1ydGpealZqWlW8VGoqFvVSkqz
  l1xUnHolFrlhKSeXJxj8Oz/wmpbOso67rtKFXcFxD0YPEo2cH+ivbN+uX4LpluaaGoXlCPLTuJpY
  SSeJYx7M+BpTU0pbnDTNhveu1ZbcDAW+vVVUflFGDg8Y5Mpr2+Pj6vYZO11OzuGoxq8s34Rn0fjj
  7i9TU479JczJpslOsPYDE6bubbep6xc6Npu4NJvdTtOfymzt7ynUr0eSSjLnhFuUcSaTyujeDLFi
  YmOqAABgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAabvfilw92XVlQ3JuvTr
  S6hUjTqWsJOvcU3KHPHmpU1KcU44fM0l1XXqswLvjtf26oyo7I2rVnUlCLjdaxNRjCfP6UXRpSfM
  uVdH3ker8Gl6VvBoc+f5K8u/oLWGsb34gbK2VSlLdG5dO02pGnGqredXmuJwlLkUo0Y5qSWU+sYv
  GG/BPFCt28cuKm5b1XFzvHUrCEKlSdKhplR2cKam0+T81iU1HCUe8cmlnrltuODrYfAZ65be33/0
  xuuRvvtdaBZ99a7M27d6pWXfU43d9JW9BSXSnUjBZnUg3luMu6lhJdG3ywVvftA8VN01ZKe5K2jW
  zqRqQttIzaqDUeXCqRfetPLbjKbWX4dFiLAdbD4dpsPy13n68x+ptSUl4p5OhXDfX47o2Ho2vKSl
  O7tISqtLC71ejUX4TUkc9C0/Yx3R5VoOqbSuKuatlU8rtk5Nt0p9JpexKWH98zj/AIp0vxdLGWOt
  J/tPL7LugycOTh7rCAA+duyAADC702xo+7tBraNrdsq1vU6xkuk6U/VOD9Ul/wCzym0Uy4r8O9Z2
  BrTtryLuNPrSfkl7GPo1V7H/ABZL1r9nQvOY7cmh6VuPRq+kazZ07uzrxxOE14exp+Ka9TXVEuPJ
  NJ+jW1d3PUEicZeFuqbA1J1qfeXuh154trvl6x/kVMeEvj4PxXrSjsuxaLRvCGY2AAZAAAAAAAAA
  AAAAAAPdoOkalrurUNK0izq3l5Xly06VNdX8X6kl62+i9YHmtLe4u7qla2tGpXr1ZqFOnTi5SnJv
  CSS6tls+AnB+jtGjT3BuGnTr6/UjmnT6SjZprwXqc8eMvV4L1t5Hgpwh07Y1CGp6i6V9r84YlWSz
  C3TXWNPP7HLxfwXQlIqZc2/KEla7c5AAV0geDcWq2uhaDf6zevFvZW869TDSbUU3hZ9bxhfFnvIE
  7Y271p21rTaNrVSuNUkq1yk+saEH0T/nTS/oMueH6SdXqaYY9Z5/p6/2RZskY6TZVvXdRuNY1u+1
  a7cXcXtxUuKrSwnKcnJ9PvZI+yO0DxU2tViobkrazbd5KpO21jN0ptx5cOpJ96ksJqMZpZXh1eYs
  B9Zvgx3rw2rEw89uuRsTtdaBedza7z27d6XWfc05XdjNXFByfSpUlB4nTgnhqMe9lhtdWlzTpsji
  BsretKMtr7l07Uqkqcqrt4VeW4jCMuRylRlipFZaWZRWcp+DWeYYOXm8EwX50nh/vH7/AKm7rGDn
  TtXj1xY26qdO33feX1vG4jXqUtSjG773HLmDqVE6ig1HDUZRxltYbyTTsntgUHSjR3ttOrGpGnNy
  utHqKSqT5/RiqNWS5VyvrLvZdY+GJejycvg2pp8u0/p/s3WtBpmyOKnDzelaNvtzdmnXd1OrKlTt
  aknQuKkow55clKqozklHL5kmukuvovG5nMvjtjna8bT9WQAGgAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAiHe/aO4V7Yqyt4azV165hUjCdLSKSrpKUebnVVuNKSXRPlm2m8Y6PEC737Wu9dUpSt9r6P
  p23ac6cU6035ZcQmpZbjKSjTw44jiVOWOrzlrF/B4Zqc3OK7R9eQuwR5vzjVw12Yq1LVdz2txfUu
  +i7GwflNfvKX2qUlDKpzbfKlUcFnPXo8UF3xv/em9q0p7p3JqGpU3UjVVvOpy28JxhyKUKMcU4S5
  W1mMU3zS9bedZOrh8BiOeW39I+/+mN1t979sC2jSlR2TtStOpKnFxutXqKKhPm9JOjSb5lyro+8j
  1fhhelBe++NXEveXfUtV3Rd0LKr30XZWL8mod3U6SpSUMOpDHopVHN4z16vMeA6uHQafD8tefuAA
  LjAAAAAAG4cG92T2XxD0zWpTkrXvO5vEs+lRn0l0Xjj7SXtijTwR5sVc2O2O/SY2bVtNZiYdKoSj
  OCnCSlGSymnlNH6RF2WN6/wn4fQ0m7q82o6Ly288+M6OPzUvwScf1U34kunyLV6a+lzWw36xL0WO
  8ZKxaPUABWbgAA82qWFlqmn1tP1G1pXVrXi4VaVWPNGS+KKm8ceDV7tGrW1vb8Kt5oEm5Tj1lUs/
  hL2w9kvV6/a7dn5OMZwcJxUoyWGmspokpkmk8ms13c5QWU4x8AlcVK2t7Fp06dSTc6ultqMW/W6T
  fRfzX09jXRFcb60urG7qWd7bVra4pS5alKrBwnB+xp9UXaXi8ckUxMPiADZgAAAAAAAAAJR4PcHd
  Z3vUp6lf95pugqXW4lHFSuvWqSf+0+n34aMWtFY3kiN2p8Ptka/vjWFp+iWrlGGHXuJ9KVCL9cn/
  AFJdX+DLicLeHWhbB0lW+n01cX1SK8qvqkfzlV+xfxY+yK/HL6me2vt/R9s6PS0nRLGnZ2lPwjBd
  ZP1yk/GTftZlCnkyzfl6Jq12AAQtgAAfK8uaFnaVru6qwo29CnKpVqTeIwjFZbb9iSKB8V92197b
  71HXqjkqFSpyWtOX/wAOjHpBYz0eOrx62yw/a637+Sdv0tmadW5b3U495eOLw6dun0j+u1+yL9pU
  497+FvD/AIeOdTeOduUfp/v/AA5OvzcVuCPQAB61zgAAAAAJB2Hxo4lbM7mjpO6LytY0u5grG+fl
  NBUqX2aUVPLpQa9F924PGOqxFqPgaXx0yRw3jeBbfY/a/t3RjR3vtSrCrGnJyutHmpRnPn9GKo1Z
  LlXK+r7yXVeCT9Gddp8XOGm6O7jo289JqVatwralQuKvk1erUeMRhSqqM5Zckk0mm+i6po5pg5Wb
  wXT350/Kzu6xg5hbI4gb02TVjPa25NQ02mqkqrt4VOa3nOUORylRlmnN8qSzKLa5Y+tLEy7J7Wu9
  dLpRobo0fTtxU4U5JVoPyO4nNyypSlFSp4SzHEacfU85TzzM3geenyTFv7T+/wCpuuwCJ9g9obhf
  u7vaa1v8g3FPL7nWnC25orl9KM+Z03lywo83N6Mny4WSWDk5cOTFO142ZAARgAAAAAAAAAAAAAAA
  AAAAMRuzc239p6PPV9yavZ6XZRylUuKijzyUZS5ILxnNxjJqEU5PDwmUb45doLdG/wCtcaVo9Wto
  W21VmqdChUlTuLulKHJi5lGWJJpzfdr0fTw+dxjIu6PQZdVP5eUdxYDjL2mNp7Ut3Y7Pq2e6dXnz
  Rc6NZu0ts0+aM3UimqvWUfQg/VNOUGknUniXxO3rxDvHV3NrNWtbRqd5RsKX5u1oPM+Xlpro2lOU
  VOXNPHRyZpoPVaXw/Dpo/LG8956sAALzAAAAAAAAAAAAAAAADcuDm9K+xN92WtRcpWkn3F7Tj+nR
  k1zfisKS+MUX0s7m3vLSjd2taFa3r041KVSDzGcZLKafrTTObJZ7sj8RlXtnsLV6/wCdpKVTS5y/
  Sh4ypZ9q6yXw5l6kjyf4n8M+Lj/iccc69f07/wBP+v0dDQ5+GeCfVY0AHgXXAAAAAA1bfmwNrb1t
  u71zTYTrxjy07ql6Fan44xJeK65w8r4G0gzEzHRhVXfHZz3Hp0p3G17yjrFtluNCq1SrxWei6+jL
  p68x+4iDXtB1vQbnyfWtKvdPqZaSuKMoc2PY30a+KOhJ8rq3t7qjKjc0KVelJYlCpBSi17GmT11F
  o6tZpDnSC8mtcJeHWrYdztWxpNeu1Tt2/v7trJrGodnfh9cybt/yrZZ9VK6Ukv6cZEkairXglUIF
  r12atlJ5/LG4GvZ31H6Zk9O7PnDu1/7Rb6jff567cf8AYUTPx6McEqemd2xs/c+5qsYaFod7fKUu
  XvIU2qSfxm8RX4sufofDLYOi8jsdq6bzweY1K1Lvpp+1Snlm3QhGEVGEYxivBJYSNJ1HaG0UQLwq
  7PlnptSlqm9alK/uYtShYU3mhB/y3+n6un2fH7RPNOEKdONOnGMIRWIxisJI/oFe15tO8t4iIAAa
  sgAAGF3vuTTto7XvtwanPFC1p8yin6VSb6Rgvi3hfv8ABGZk1GLlJpJdW36imPaU4lveu5fyRpVe
  T0LTZuNJxl6NzV8JVfivVH4ZfTmaOr4R4bbX54p/xjnM/T7yr6jPGGm/qjneG4NQ3TuW+1/VKnPd
  XlVzljOIrwjFZ9SSSXwRiQD6nSlaVitY2iHBmZmd5AAbMAAAAAAAAAAAAAASHwu4y7+4d8lvomre
  U6ZHP/Rl8nWtv036McqVP0puT7uUeZ45s+BHgNMmOmSvDeN4F+eEXaP2Vvq9tNGv6dbbuu3VVUqF
  rcS7yjXm3PljTrJJZxGPSahmU1GPMyajk4TpwO7R25Ni0rfRNxU6u4NvUadOhQpucY3FlBT6unNr
  84lFtKnN49GCjKCTT8/rPBf+WD2+0/dnde8GJ2nubb+7NHhq+29Xs9UspYTqW9RS5JOMZck14wml
  KLcJJSWVlIyx561ZrO0wyAAwAAAAAAAAAAAGvcQ95aBsPa1zuPcd33FpR9GEIpOrcVGny0qccrmm
  8Pp0SSbbUU2vtvzdOk7K2jqO6NbnVjYWFNTqKlT55zbkoxhFe2UpRistLL6tLLXO/jLxM1/ifumW
  r6vLuLSjzQ0/T6c26VpTb8F4c03hOU2k5NLooqMY9Hw/w+2qtvPKsdfsHGXiZr/E/dMtX1eXcWlH
  mhp+n05t0rSm34Lw5pvCcptZk0vCKjGOjgHsceOuOsVrG0QwAA3YAAAAAAAAAAAAAAAAAAAPvp95
  c6ff0L6yrzoXNvUjVpVYPEoSi8pp+3J8AYmImNpZXt4I8RbTiFtSF0+Sjq1qlTv7dPwl6pxX8WWG
  17HlerL34568PN36tsjdFtruk1MVKfo1qTfoV6b+1CS9j/c0muqL17A3bpG9dtW+u6PW5qVRctWk
  36dGovtQkvU1+9YfrPm3jvg86LJ8THH5J/t9Ps7Wk1Pxa7T1hnwAefXAAAAAAAAAAAAAAAAAAAAA
  AAIZ7RHF+hs6xqbe0GtGruG4hic4vKsoNfaf8trwXq8X6s2dJpMuryxixRvM/veWmTJXHXis1rtT
  cV4W1vX2Jt26buai5NUuKculOProp+1/pexej62lV8/qtUqVqs6tWcp1JycpSk8uTfi2z+T6n4do
  Mehwxip/We8uDmzTltxSAAvIQAAAAAAAAAAAAAAAAAAAABvHBriZr/DDdMdX0iXf2lblhqGn1JtU
  rumn4Px5ZrLcZpNxbfjFyjLoVw73noG/drW249uXff2lb0ZwkkqtvUSXNSqRy+WayunVNNNNxab5
  em8cGuJmv8MN0x1fSJd/aVuWGoafUm1Su6afg/Hlmstxmk3Ft+MXKMuV4j4dGpjjpytH92XSsGvc
  O956Bv3a1tuPbl339pW9GcJJKrb1ElzUqkcvlmsrp1TTTTcWm9hPI2rNJmto2mGQAGoAAAAAABW7
  tucTK+39v0eH+ktRvNbtnVv6ynUhOha86UYxwkpd641Iy9J4jGScXzpqfTYLZ8kY6+ogvtN8W7ri
  Ru+rZaXfVXtPT6iWnUe6dLvpqOJV5xbzJtuSjnHLBr0YylPMQgHucOKuGkUp0hgABKwAAAAAAAAA
  AAAAAAAAAAAAAAAG48KOIOs8PdxR1HTpOtaVcRvLOUsQrwX9Ul1xL1fFNp6cCPNhpmpOPJG8S2ra
  azvDofsjdWi7y2/Q1vQ7pVreqsSi+k6M/XCa9Ul/7rKaZnDn9w033ruwtejqej1s054Vzazb7qvH
  2SXt9j8V+0ulwv4h7f4gaMr3Sa3dXVNLyqyqSXe0Jf70fZJdH8HlL5v4x4Jk0NuOnOnft9J+7tab
  VVyxtPVt4AOCtgAAAAAAAAAAAAAAAAPyUoxi5SajFLLbfRIrpx04+UbelX27sS6VS4eadxqkOsaa
  8GqL9b/l+C9WejV3Q6DNrcnw8UfrPpH6osuWuKu9mzcfuM1rs23q6Dt6rSudw1I4nPpKFkn65epz
  9kfV4v2OoF7c3F7d1ry7r1Li4rTdSrVqScpTk3ltt+LbP4rValarOtWqSqVJycpTk8uTfi2z+D6X
  4Z4Zi8Px8NOcz1nv/pxM+e2a28gAOkgAAAAAAAAAAAAAAAAAAAAAAAAAABLHZw4vanw03RRtLi4V
  Xa+oXEI6lb1XJxoZai7mmoptTjHq0k+dR5X1UZR6DWdzbXtpRvLO4pXNtXpxq0a1KanCpCSzGUZL
  o000014nKEuP2I+KH5T0itw93BqvPqFm1PRvKKvp1bdR9KhD0evdcvMk5OXLNpJRp9OB4xoeKvx6
  Rzjr9/36MrOAA8yyAAAAAPJrWpWWjaPe6vqVbuLKxt6lzc1eVy5KcIuUpYim3hJvCTZzI4k7rvd8
  b61fdV/Du6uoXDqRpZT7qmko06eVGPNywjGPNhN8uX1bLWdvHetXTdpaXsiyq0efWajuL9KrF1I0
  KMounFwabSnU6qacetBrqnLFMj1Pgmm4Mc5p6z0/T/1iQAHcYAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAyG3db1Xb2r0NW0W+rWV7QlmFWm8P7mvBp+DT6Mx4NbVi0TW0bxLMTtzhb3g9x90fccKOk7snQ
  0jV/sxrt8tvcP739iXwfT2Prgm5dVlHNQlHhbxt3XsmNKwrz/LGjw6K0uZvmpr2U59XHw8HlfA8f
  4n+F4tM5NJy/+ftP+JdLBrtuWT3XaBo/Dvips3e9OnT0zUo29/JdbG6ap1k+v2VnE/DPot9PHBvB
  4zNgyYL8GSsxP1dKtotG9ZAARNgAAAAAAPPqV/ZaZZVL3Uby3s7WkszrV6ihCP3t9DMRMztDD0GD
  3pu3b+z9Ilqe4NRpWlJJ8kG81KrX6MI+Mn1X3evCIX4ndpHTrKNXT9j2yv7jDj5fcRcaMH7YQ6Of
  3vCyvBorXuXX9Z3JqtTVNc1GvfXdTxnVlnCz4RXhFexLCR6bw38NZs+19R+Wvb1n7f19lLNrq05U
  5ykfjHxu1zezq6XpaqaToLk13MZfnbhervJL1evlXT25wmRKAe702lxaXHGPFXaHJvktkne0gALD
  QAAAAAAAAAAAAAAAAAAAAAAAAAAAAADL7N3DqW091abuTSKvd3un3Ea9PMpKM8PrCXK03CSzGSTW
  Yya9ZiAYtEWjaR1N2duDTd17W03cekVO8stQt416WZRcoZXWEuVtKcXmMkm8STXqMsVj7CO/fyht
  6+4eX1TNxpfNe6f6PjbTn+cj0jhctWalmUm332EsQLOHhNXp50+a2Of3DYABWAA17iXuH+CfD7X9
  yRq2lOrp+n1q9DyqWKU6yg+6g+qzzT5YpJptySXVo2rWbWiseooV2n91327ONWv1LyCpUtKuJ6Ta
  0lJS5KVCpKOcqKb5p88+uWufly1FMjIA9/ixxipFI9GoACQAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAB+xlKMlKMnGS6pp4aJT2Hx431tju7e6u465Yxwu5vsymln9Gp9rw6LOUvYRWCvqNLh1NeHL
  WJj6t6ZLUnes7LnbJ7QexNeVOjqdWtoN5LCcLpc1Jyb8FUj0x8ZKJK2n3tnqFpC7sLuhd21RZhVo
  VFOEvukujObZk9B3BrugXDuNE1i+06o1hytq8qeV7Hh9V955nV/hPDfngvw/Secff/tex+IWj543
  dGAUy0DtE8RdNSjd3Gn6tBLCV3bJNfjTcW/xybRU7UmtPT+SntTT43v+UlcTdP8AoYT/ANY4mT8M
  a+s7RET+k/fZajXYpjmtKYLdm8NsbUt+/wBw61aWCazGE55qTXh6MFmUvwRT3dfHPiNr/PTWs/kq
  3k891p0O5x+v1n/rEb3Nevc15V7mtUrVZvMp1JOUpP2ts6Gl/CWSeeovt9I+/wD6hyeIRHyQsvvr
  tN0KfPbbN0Z1peCvL/pH1/ZpxeX6mm2viiAt47x3Nu698q3DrFzfSTzCEpYp0/5sFiMfBeCMCD1G
  j8L0uj/lV59+s+6hkz5MnzSAA6KEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAASH2ct2fwN4y
  bf1WrX7myrXCsr1yuu4pdzW9ByqS8HCDcamH0zTXVeK6QHJw6hcNNw/wr4faBuOVW0qVtQ0+jXuP
  JZZpQrOC72C6vHLPmjhttOLT6pnm/HsO01yR+n2/yzDYQAeeZCC+3Fq9zpvA+VnQhSlT1XVLezru
  abcYJTrpxw+j5qMV1z0b6Zw1OhUL/lB9Tsqus7Q0enWbvrW3urmtT5JLlp1ZU405c2MPLo1FhPK5
  euMrN/wzHx6qkf19hVkAHtmoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABe/sO6vc6lwPjZV4UY09J1S4s6DgmnKDUK7csv
  q+atJdMdEumct0QLY/8AJ8aos7v0WrqPX/mt1b2Uq/8AnI1asIZ/zMZSS/iJ+o5fjFOLSzPbaf8A
  H+WYWyAB45kKQ9vj/DDpP/5fo/2i4LvEN8Ruznsjfe9NQ3Xq+qbhoX1/3fewtbijGkuSnCmsKVKT
  +zBeLfXJ0PDdRj0+bjyTy2FAAXe80Xht773Z81b/AEB5ovDb33uz5q3+gd/znS959mNlIQXe80Xh
  t773Z81b/QHmi8Nvfe7Pmrf6A850vefY2UhBd7zReG3vvdnzVv8AQHmi8Nvfe7Pmrf6A850vefY2
  UhBd7zReG3vvdnzVv9AeaLw2997s+at/oDznS959jZSEF3vNF4be+92fNW/0B5ovDb33uz5q3+gP
  OdL3n2NlIQXe80Xht773Z81b/QHmi8Nvfe7Pmrf6A850vefY2UhBd7zReG3vvdnzVv8AQHmi8Nvf
  e7Pmrf6A850vefY2UhBd7zReG3vvdnzVv9AeaLw2997s+at/oDznS959jZSEF3vNF4be+92fNW/0
  B5ovDb33uz5q3+gPOdL3n2NlIQXe80Xht773Z81b/QHmi8Nvfe7Pmrf6A850vefY2UhBd7zReG3v
  vdnzVv8AQHmi8Nvfe7Pmrf6A850vefY2UhBd7zReG3vvdnzVv9AeaLw2997s+at/oDznS959jZSE
  F3vNF4be+92fNW/0B5ovDb33uz5q3+gPOdL3n2NlIQXe80Xht773Z81b/QHmi8Nvfe7Pmrf6A850
  vefY2UhBd7zReG3vvdnzVv8AQHmi8Nvfe7Pmrf6A850vefY2UhBd7zReG3vvdnzVv9AeaLw2997s
  +at/oDznS959jZSEF3vNF4be+92fNW/0B5ovDb33uz5q3+gPOdL3n2NlIQXe80Xht773Z81b/QHm
  i8Nvfe7Pmrf6A850vefY2UhBd7zReG3vvdnzVv8AQHmi8Nvfe7Pmrf6A850vefY2UhBd7zReG3vv
  dnzVv9AeaLw2997s+at/oDznS959jZSEF3vNF4be+92fNW/0B5ovDb33uz5q3+gPOdL3n2NlIQXe
  80Xht773Z81b/QHmi8Nvfe7Pmrf6A850vefY2UhBd7zReG3vvdnzVv8AQHmi8Nvfe7Pmrf6A850v
  efY2UhBd7zReG3vvdnzVv9AeaLw2997s+at/oDznS959jZSEF3vNF4be+92fNW/0B5ovDb33uz5q
  3+gPOdL3n2NlIQXe80Xht773Z81b/QHmi8Nvfe7Pmrf6A850vefY2UhBd7zReG3vvdnzVv8AQHmi
  8Nvfe7Pmrf6A850vefY2UhBd7zReG3vvdnzVv9AeaLw2997s+at/oDznS959jZSEF3vNF4be+92f
  NW/0B5ovDb33uz5q3+gPOdL3n2NlIQXe80Xht773Z81b/QHmi8Nvfe7Pmrf6A850vefY2UhBd7zR
  eG3vvdnzVv8AQHmi8Nvfe7Pmrf6A850vefY2UhBd7zReG3vvdnzVv9AeaLw2997s+at/oDznS959
  jZSEF3vNF4be+92fNW/0B5ovDb33uz5q3+gPOdL3n2NlIQXe80Xht773Z81b/QHmi8Nvfe7Pmrf6
  A850vefY2UhBd7zReG3vvdnzVv8AQHmi8Nvfe7Pmrf6A850vefY2UhJ/7B93a2/Ga9o3FzRo1LrR
  K9K3hOooutNVaM3GKf2pcsJywuuIyfgmS/5ovDb33uz5q3+gbDw47Omydh7zsN16Rqm4a97Y953V
  O6uKMqT56cqbyo0ovwm8Ya64INV4ppsuG1Imd5jsbJkAB5dkAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf/Z`;

  constructor(props) {
    super(props);

    this.state = {
      file: undefined,
      imagePreviewUrl: '',
    };
  }

  selecteFile(e) {
    this.fileInput.click();
  }

  selectedFile(e) {
    e.preventDefault();

    if (e.target.files) {
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        let bthis = this;
        fixOrientation(reader.result, { image: true }, function (fixed) {
          bthis.setState({
            file: file,
            imagePreviewUrl: fixed,
          });
          if (bthis.props.onChange) {
            bthis.props.onChange(file);
          }
        });
      };
      reader.readAsDataURL(file);
    }
  }

  getFile() {
    return this.state;
  }

  render() {
    return (
      <div>
        <input ref={(e) => { this.fileInput = e; }} type="file" style={{ display: 'none' }} accept="image/*" onChange={this.selectedFile.bind(this)} />
        <a onClick={this.selecteFile.bind(this)}>
          <img className="img" alt="" src={this.state.imagePreviewUrl || this.defaultImage} />
        </a>
      </div>
    );
  }
}

export default EnPicture;

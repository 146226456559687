import React from 'react';
import moment from 'moment';
import numeral from 'numeral';

import { observer, inject } from 'mobx-react';
import EnButton from '../../../components/form/EnButton';
import EnDateRangePicker from '../../../components/form/EnDateRangePicker';
import FormGroup from '../../../components/form/FormGroup';
import datetime from '../../../utils/datetime';
import history from '../../../utils/history';
import * as stCommon from './stepCommon';
import config from '../../../config';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

class EnStepSurveyFinished extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: ['survey_finished', 'paid_to_contractor'],
      nextStep: 'quotation_management',
      jobInsId: undefined,
      isQuotationExpired: false,
      stateReasonNoQuotation: true,
    };
  }

  async componentWillMount() {
    const jobInfo = this.props.job.toJS().info;
    if (jobInfo.reasonNoQuotation) {
      this.setState({
        stateReasonNoQuotation: false,
      });
    }
    if (jobInfo.jobType === 'S') {
      const jobI = await this.props.job.getInstallationJobInfo(jobInfo.jobCode, false);
      if (jobI) {
        this.setState({
          installationUrl: `${config.publicUrl}/superadmin/jobs/${jobI._id.toString()}`,
          installationJob: jobI,
          installationJobId: jobI._id.toString(),
        });
      }
      if (jobInfo.jobCode) {
        const jobInsInActive = await this.props.job.getInstallationJobInactive(jobInfo.jobCode);
        if (jobInsInActive && jobInsInActive.length > 0) {
          this.setState({
            isQuotationExpired: true,
          });
        }
      }
    }
  }

  onRecoverJob = async () => {
    // e.preventDefault();
    try {
      Swal.fire({
        title: 'คุณต้องการกู้คืนคิวช่างนี้ใช่หรือไม่?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        if (result.value) {
          const jobStore = this.props.job.toJS();
          const jobInfo = jobStore.info;
          const jobInsInActive = await this.props.job.getInstallationJobInactive(jobInfo.jobCode);
          const response = await this.props.job.cancelAndRecoverJob(jobInfo.jobCode, true);
          if (response.message === 'success') {
            toast.success('กู้คืนสำเร็จ');
            setTimeout(() =>
              window.location.reload()
              , 2000);
          } else {
            toast.error('พบข้อผิดพลาด');
          }
        }
      });
    } catch (error) {
      toast.error(error.message || 'พบข้อผิดพลาด');
    }
  }

  onShowNoQuotation() {
    const jobInfo = this.props.job.toJS().info;
    const regexUrl = /^(http|https):\/\/[^ "]+$/;

    Swal.fire({
      title: 'ระบุสาเหตุ',
      html:
        `
        <h4> เหตุผล<label style="color:red">*</label> </h4>
        <input id="swal-input1" class="swal2-input">

        <h4> กรุณาแนบ Link URL Ticket<label style="color:red">*</label> </h4>
        <input id="swal-input2" class="swal2-input">
        `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
      preConfirm: () => {
        const reason = document.getElementById('swal-input1').value;
        const url = document.getElementById('swal-input2').value;

        if (reason.trim() === '' || url.trim() === '' ) {
          Swal.showValidationMessage('กรุณากรอกข้อมูลให้ครบ');
          return
        }

        if (reason.length < 1 || !regexUrl.test(url)) {
          Swal.showValidationMessage('กรุณากรอกข้อมูลให้ครบ');
          return
        }

        return { reason: reason ? reason : '-', url: url };
      }
    }).then((result) => {
      if (result.value) {
        const { reason, url } = result.value;
        const emailUser = this.props.auth.getUsernameOrEmail();
        const dateTimeFormat = moment().format('HH:mm DD/MM/YYYY');
        const activityLog = {
          event: "admin_no_quotation",
          function_name: "jobs",
          reference_code: jobInfo.jobCode || jobInfo.id,
          type_of_job: 'S',
          user: emailUser,
          reason: reason,
          link_ticket: url,
          remark: `[${dateTimeFormat}] : ${emailUser} ไม่สร้างใบเสนอราคา ด้วยเหตุผล ${reason} ${url}`,
          created_date: dateTimeFormat,
        }

        this.props.permission.setInfoLogActivity([activityLog]);
        this.props.permission.saveUamActivityLogs();

        const toDay = moment().format();
        this.props.job.saveReasonNoQuotation(reason);
        this.props.job.saveCustomerApprovedDate(toDay);
        this.onSubmit();
      };
    });
  }

  // async gotoQuotationPage() {
  //   const jobInfo = this.props.job.toJS().info;
  //   const jobI = await this.props.job.getInstallationJobInfo(jobInfo.jobCode, true);
  //   this.setState({ installationUrl: `${config.publicUrl}/superadmin/jobs/${jobI._id.toString()}` });
  //   history.push(`${config.publicUrl}/superadmin/jobs/${jobI._id.toString()}/quotation/${jobInfo.id}`);

  // }
  onChangeDateRange(installationStartDate, installationEndDate) {
    const dateRange = datetime.GetBetweenDates(installationStartDate, installationEndDate);
    const unavailableInstDate = dateRange.filter(d => { return this.checkInstDayBlock(d); });
    if (unavailableInstDate && unavailableInstDate.length > 0) {
      installationStartDate = undefined;
      installationEndDate = undefined;
    }
    this.props.job.saveMultiInfo({
      installationStartDate,
      installationEndDate,
    });
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onSubmit() {
    try {
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  checkInstDayBlock(date) {
    if (this.props.checkInstDayBlock) {
      return this.props.checkInstDayBlock(date);
    } else {
      const { unavailableInstDate } = this.props;
      if (Array.isArray(unavailableInstDate) && unavailableInstDate.length) {
        const dateBlock = unavailableInstDate.find(u => datetime.isEqual(date, u));
        return !!dateBlock;
      } else {
        return false;
      }
    }
  }

  async onNextStatus(status) {
    try {
      if (this.props.job.toJS().info.status === status) {
        setTimeout(() => {
          window.location.reload();
        }, 200);
        return;
      }
      await this.props.job.saveAndNextState(status, {
        isContractor: this.props.contractorMode,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      stCommon.onError(error.message);
    }
  }

  onclickCreateQuotation() {
    this.props.hasClickedCreateQuatation(true);
  }

  getUrl(contractorMode, superAdminMode, jobId, contractorId) {
    let url = config.publicUrl;
    if (contractorMode) {
      url += '/contractors/' + contractorId + '/calendar';
    } else if (superAdminMode) {
      url += '/superadmin';
    } else {
      url += '/admin/calendar';
    }
    url += '/jobs/' + jobId;
    return url;
  }

  render() {
    const { jobId, contractorMode, isReadOnly, superAdminMode, transfer } = this.props;
    // const { jobId, contractorMode, superAdminMode, transfer } = this.props;
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const contractorId = jobInfo.contractorId;
    // const userInfo = this.props.auth.getUserInfo();
    // const channel = userInfo.channel ? userInfo.channel.channelName : '';
    const channel = this.props.auth.getChannelName();
    let isSCGHome = (channel === 'SCGHOME Online');
    const enableNextStep = this.state.currentStep.filter((status) => status === jobInfo.status);
    const isQuotationExpired = this.state.isQuotationExpired && !contractorMode;
    // enableNextStep = enableNextStep && (!this.state.installationJob)
    const isShowNoQuotationButton = (jobInfo.jobType === 'S') && this.state.stateReasonNoQuotation && !isSCGHome;
    const isShowNoQuotationAlert = !isSCGHome && (jobInfo.jobType === 'S') && !this.state.stateReasonNoQuotation;
    const isInstallment = this.state.installationJob? this.state.installationJob.installment? true:false  :  false;
    const installationDateRangePicker = (
      <EnDateRangePicker
        withPortal
        numberOfMonths={1}
        disabled={(jobInfo.status !== 'survey_finished' && jobInfo.status !== 'paid_to_contractor') || isReadOnly}
        ref={(e) => { this.instDateRange = e; }}
        openDirection="up"
        minimumNights={0}
        startDate={jobInfo.installationStartDate ? moment(jobInfo.installationStartDate) : null}
        endDate={jobInfo.installationEndDate ? moment(jobInfo.installationEndDate) : null}
        isDayBlocked={this.checkInstDayBlock.bind(this)}
        onDateChange={this.onChangeDateRange.bind(this)}
        startDatePlaceholderText="วันที่เริ่มต้น"
        endDatePlaceholderText="วันที่สิ้นสุด" />
    );
    let stepDetail = <div />;
    if (jobInfo.customer_approved === true) {
      if (transfer && transfer.effective_date) {
        const transferAmount = transfer && transfer.amount_exclude_wht ? transfer.amount_exclude_wht : '0';
        const amountTxt = numeral(transferAmount).format('0,0.00');
        let title = 'เตรียมการจ่ายเงินให้ช่าง';
        let textDate = '';
        const today = moment(moment().format('YYYY/MM/DD'));
        const compareDate = moment(transfer.effective_date).add('days', 1).format('YYYY/MM/DD');
        if (today.isSameOrAfter(compareDate)) {
          title = 'จ่ายเงินให้ช่างเรียบร้อยแล้ว';
          textDate = `เมื่อวันที่ ${datetime.formatWithOffset(transfer.effective_date, 'DD/MM/YYYY', 7)}`;
        } else {
          textDate = `ช่างจะได้รับเงินภายในวันที่ ${datetime.formatWithOffset(transfer.effective_date, 'DD/MM/YYYY', 7)}`;
        }
        let textSpan = <div><p>จำนวนเงิน <b>{amountTxt}</b> บาท</p><p>{textDate}</p></div>;
        stepDetail = stCommon.showContentStepStatus('fa fa-usd', title, textSpan, 'orange');
      } else {
        // step customer review finished
        stepDetail = stCommon.showStepStatus('fa fa-check-circle-o', 'ลูกค้ายอมรับการสำรวจเรียบร้อย', jobInfo.customer_approved_date ? stCommon.formatDate(jobInfo.customer_approved_date) : '', '#0fad00');
      }
    }
    const installationUrl = this.getUrl(contractorMode, superAdminMode, this.state.installationJobId, contractorId); // this.state.installationUrl;
    // const isShowinstallationDateRangePicker = this.state.installationJob ?
    //   (this.state.installationJob.quotation && this.state.installationJob.quotation.length) : true;
    return (
      <div>
        {stepDetail}
        <div className="row" style={{ paddingLeft: '15px' }}>
          {
            isShowNoQuotationAlert && (
              <div className="alert alert-warning col-md-4" style={{ backgroundColor: '#ae5d0e', padding: '6px 15px' }}>
                <span><i className="fa fa-exclamation-circle" aria-hidden="true" /> ไม่ต้องกรอกใบเสนอราคา</span>
              </div>
            )
          }
          {(isQuotationExpired) && (
            <div className="alert alert-warning col-md-4" style={{ backgroundColor: '#ae5d0e', padding: '6px 15px' }}>
              <span><i className="fa fa-exclamation-circle" aria-hidden="true" /> ใบเสนอราคาหมดอายุ</span>
            </div>
          )}
        </div>
        {stCommon.showBasicInfo(jobInfo, contractorMode)}
        {/* {(installationUrl) && stCommon.showQuotation(this.state.installationJob, contractorMode)}

        {
          (isShowinstallationDateRangePicker && !contractorMode && !isInstallment) && (<div className="row">
            <FormGroup containerClassName="col-md-12" label="วันที่ติดตั้ง" >
              {installationDateRangePicker}
            </FormGroup>
          </div>)
        } */}
        <div className="row">
          <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 20px 0 20px'
          }}>
            {/* 
            {(isQuotationExpired) && (
              <EnButton id="open-quotation-expired-button" className="btn-primary pull-left" onClick={this.onRecoverJob.bind(this)}>
                <i className="fa fa-refresh btn-icon" aria-hidden="true" />กู้คืนงานติดตั้ง
              </EnButton>
            )}
            {(!contractorMode) && (<EnButton
              id="open-quotation-button"
              className="btn-primary btn-quotation"
              onClick={this.gotoQuotationPage.bind(this)}>
              <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />ทำใบเสนอราคา
            </EnButton>)}
            {
              (this.state.installationJobId) && (
                <a href={installationUrl} target="_blank">
                  <EnButton id="back-to-installation" className="btn-warning pull-right">
                    <i className="fa fa-external-link btn-icon" aria-hidden="true" />ดูงานติดตั้ง
                  </EnButton>
                </a>
              )
            } */}
            {
              (!isSCGHome) && (!this.state.installationJob) &&
              ((enableNextStep && enableNextStep.length > 0 && !isReadOnly) &&
                stCommon.getDisplayStatusButton(jobInfo.jobType, jobInfo.status, contractorMode, async () => { 
                  await this.onNextStatus('installation_requested'); 
                }, '', true, jobInfo, true))
            }
            {/* {
              (enableNextStep && enableNextStep.length > 0 && !isReadOnly) &&
              <EnButton id="submit-button" className="btn-info pull-right" onClick={this.onSubmit.bind(this)}>
                <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
              </EnButton>
            } */}
            <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
              <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
            </EnButton>
            <div>
              {(isShowNoQuotationButton && !contractorMode) && (
                <EnButton id="open-no-quotation-button" className="btn-danger pull-left" onClick={this.onShowNoQuotation.bind(this)}>
                  <i className="fa fa-ban btn-icon" aria-hidden="true" />ไม่ต้องมีใบเสนอราคา
                </EnButton>
              )}
              {(isQuotationExpired) && (
                <EnButton id="open-quotation-expired-button" className="btn-primary pull-left" onClick={this.onRecoverJob.bind(this)}>
                  <i className="fa fa-refresh btn-icon" aria-hidden="true" />กู้คืนงานติดตั้ง
                </EnButton>
              )}
              <EnButton className="btn-primary pull-right" onClick={() => { this.onclickCreateQuotation() } }>
                <i className="fa fa-share btn-icon" aria-hidden="true" />สร้างใบเสนอราคา
              </EnButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('auth', 'job', 'permission')(observer(EnStepSurveyFinished));

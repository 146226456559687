import React from 'react';
import * as _ from 'lodash';
import momentTz from 'moment-timezone';
import { observer, inject } from 'mobx-react';
import EnToggle from '../../components/form/EnToggle';
import FormGroup from '../../components/form/FormGroup';
import moment from 'moment';
import EnTextArea from '../../components/form/EnTextArea';
import Pagination from '../../components/common/Pagination';
import Loader from '../../components/common/Loader';
import { isAllow } from '../../utils/permission';
import ModalBlockContractor from '../../components/modal/ModalBlockContractor';
import { toast } from 'react-toastify';

export class AdminPermitContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      isLoading: true,
      toggleEnabled: true,
      originalToggleEnabled: true,
      pageSize: 15,
      reasonRadios: this.initialReasonRadios(),
      indexRadioTextarea: this.initialReasonRadios().findIndex(radio => radio.name === 'show_textarea'),
      isVisibleModalBlockContractor: false,
      logsResponse: {}
    };
  }

  initialReasonRadios() {
    return [
      {
        name: "3_times_reports",
        message: "ช่างถูกแจ้งเตือนครบ 3 ครั้ง",
        selected: false
      },
      {
        name: "accident",
        message: "ช่างเกิดอุบัติเหตุ/ติดโควิด",
        selected: false
      },
      {
        name: "cant_contact",
        message: "ติดต่อช่างไม่ได้",
        selected: false
      },
      {
        name: "on_schedule",
        message: "พักงานช่างครบตามวันที่กำหนด",
        selected: false
      },
      {
        name: "contractor_can_come_back",
        message: "ช่างสามารถกลับมาทำงานได้ตามปกติ",
        selected: false
      },
      {
        name: "show_textarea",
        message: "อื่นๆ โปรดระบุเหตุผล",
        selected: false,
        textareaText: ""
      }
    ];
  }

  async componentWillMount() {
    await this.props.contractor.getContractor({
      id: this.state.id,
      isAdmin: true
    });
    const pauseMatchingResponse = await this.props.permission.getActivityLogs({
      page: 1,
      page_size: this.state.pageSize,
      event: 'contractor_pause_matching',
      contractor_id: this.state.id
    });
    this.setDefaultValue(pauseMatchingResponse);
    this.setState({ logsResponse: pauseMatchingResponse, isLoading: false });
  }

  setDefaultValue(pauseMatchingResponse) {
    if (_.get(pauseMatchingResponse, 'data.0.action') === 'พักงานชั่วคราว') {
      this.setState({
        toggleEnabled: false,
        originalToggleEnabled: false
      });
    } else if (_.get(pauseMatchingResponse, 'data.0.action') === 'เปิดรับงาน') {
      this.setState({
        toggleEnabled: true,
        originalToggleEnabled: true
      });
    }
    this.setState({ reasonRadios: this.initialReasonRadios() });
  }

  async onTogglePermitContractor(event) {
    this.setState({ toggleEnabled: event });
  }

  onSelectRadio(name, _e) {
    const reasonRadios = this.state.reasonRadios.map(radio => {
      if (radio.name === name) {
        radio.selected = !radio.selected;
      } else {
        radio.selected = false;
      }
      return radio;
    });
    this.setState({ reasonRadios });
  }

  onchangeReasonTextarea(e) {
    this.setState({
      reasonRadios: this.state.reasonRadios.map(radio => {
        if (radio.name === 'show_textarea') {
          radio.textareaText = e.target.value;
        }
        return radio;
      })
    });
  }

  onCancel(e) {
    e.preventDefault();
    if (_.get(this.state, 'logsResponse.data.0')) {
      this.setDefaultValue(this.state.logsResponse);
    } else {
      this.setState({
        toggleEnabled: true,
        originalToggleEnabled: true,
        reasonRadios: this.initialReasonRadios()
      });
    }
  }

  async onSave() {
    try {
      const isPermitted = this.state.toggleEnabled;
      this.props.contractor.setInfo('isPermitted', isPermitted);
      if (!isPermitted) {
        this.setState({ isVisibleModalBlockContractor: true });
      } else {
        this.props.contractor.setInfo('isPermittedRestartDate', null);
        await Promise.all([
          this.props.contractor.updateContractorInfo(this.state.id, 'pauseMatching'),
          this.logActivityPauseMatching()
        ]);
        toast.success('บันทึกข้อมูลสำเร็จ');
      }
    } catch (e) {
      toast.success('บันทึกล้มเหลว');
    }
  }

  async onSaveNotPermitted(event) {
    try {
      if (event.customRadioValue === 1) {
        this.props.contractor.setInfo('isPermittedRestartDate', null);
      } else {
        this.props.contractor.setInfo(
          'isPermittedRestartDate',
          moment()
            .add(+event.daySuspended, 'days')
            .startOf('day')
            .toDate()
        );
      }
      await Promise.all([
        this.props.contractor.updateContractorInfo(this.state.id, 'pauseMatching'),
        this.logActivityPauseMatching(event.customRadioValue === 1 ? null : event.daySuspended)
      ]);
      toast.success('บันทึกข้อมูลสำเร็จ');
    } catch (e) {
      toast.success('บันทึกล้มเหลว');
    } finally {
      this.setState({ isVisibleModalBlockContractor: false })
    }
  }

  async logActivityPauseMatching(daySuspended = null) {
    let reasons = [];
    const contractorInfo = this.props.contractor.toJS().info;
    for (let radio of this.state.reasonRadios) {
      if (!radio.selected) continue;
      if (radio.name === 'show_textarea') {
        reasons.push(radio.textareaText);
        continue;
      }
      reasons.push(radio.message);
    }
    const logs = reasons.map(reason => {
      let log = {};
      log.event = "contractor_pause_matching";
      log.function_name = "contractors";
      log.contractor_id = this.state.id;
      log.action = this.state.toggleEnabled ? "เปิดรับงาน" : "พักงานชั่วคราว";
      log.count_of_day = daySuspended;
      log.user = this.props.auth.getUsernameOrEmail();
      log.reason = reason;
      log.remark = `[${momentTz().utcOffset(7).format('HH:mm DD/MM/YYYY')}] : ${log.user} ${log.action}เนื่องจาก${log.reason}`;
      log.remark += contractorInfo.isPermittedRestartDate ? ` เป็นเวลา ${daySuspended} วัน` : '';
      log.created_date = new Date();
      return log;
    })
    this.props.permission.setInfoLogActivity(logs);
    await this.props.permission.saveUamActivityLogs();
    const pauseMatchingResponse = await this.props.permission.getActivityLogs({
      page: 1,
      page_size: this.state.pageSize,
      event: 'contractor_pause_matching',
      contractor_id: this.state.id
    });
    this.setState({
      logsResponse: pauseMatchingResponse,
      originalToggleEnabled: this.state.toggleEnabled
    })
    this.setDefaultValue(pauseMatchingResponse);
  }

  async onPageClick(pageNum) {
    this.setState({ isLoading: true });
    const pauseMatchingResponse = await this.props.permission.getActivityLogs({
      page: pageNum,
      page_size: this.state.pageSize,
      event: 'contractor_pause_matching',
      contractor_id: this.state.id
    });
    this.setState({ logsResponse: pauseMatchingResponse, isLoading: false });
  }

  render() {
    if (!_.get(this.state, 'logsResponse.data'))
      return <Loader show={true} />;
    const styleContainer = {
      padding: '10px 20px'
    }
    const styleToggleForm = {
      display: 'flex',
      gap: '15px',
    };
    const styleRadiosField = {
      display: 'flex',
      flexDirection: 'column',
      visibility: this.state.originalToggleEnabled != this.state.toggleEnabled ? "visible" : "hidden"
    }
    const styleReasonTextarea = {
      resize: "vertical",
      visibility: this.state.originalToggleEnabled != this.state.toggleEnabled &&
        this.state.reasonRadios[this.state.indexRadioTextarea].selected ? "visible" : "hidden",
      marginBottom: "25px"
    }
    const styleBlockingContractorForm = {
      visibility: this.state.originalToggleEnabled != this.state.toggleEnabled ? "visible" : "hidden",
    }
    const styleSubmitButtons = {
      display: "flex",
      padding: "0 15px 0 0",
      justifyContent: "end",
      visibility: this.state.originalToggleEnabled != this.state.toggleEnabled ? "visible" : "hidden",
    }
    return (
      <div className={styleContainer}>
        <ModalBlockContractor
          title="ระยะเวลาพักงานช่าง"
          isVisible={this.state.isVisibleModalBlockContractor}
          onSave={async event => { await this.onSaveNotPermitted(event) }}
          onCancel={_e => { this.setState({isVisibleModalBlockContractor: false}) }}
        />
        {isAllow('EDIT_PAUSE_MATCHING') &&
          <FormGroup>
            <div style={styleToggleForm} className="col-md-4">
              <EnToggle
                checked={this.state.toggleEnabled}
                onChange={this.onTogglePermitContractor.bind(this)}
                style={{
                  marginBottom: this.state.originalToggleEnabled == this.state.toggleEnabled ? '60px' : '0'
                }}
              />
              <span className="form-label">
                {this.state.toggleEnabled ? "เปิดรับงาน" : "พักงานชั่วคราว"}
              </span>
            </div>
            {
              this.state.originalToggleEnabled != this.state.toggleEnabled &&
              <div className="col-md-8">
                <div className="col-md-3 p-0" style={styleBlockingContractorForm}>
                  <div style={{ fontWeight: 700 }}>กรุณาระบุเหตุผล <span style={{ color: 'red' }}> *</span></div>
                </div>
                <div className="col-md-9 p-0">
                  <div style={styleRadiosField}>
                    {
                      this.state.reasonRadios.map(reasonRadio => {
                        return (
                          <label key={reasonRadio.name}>
                            <input
                              type="radio"
                              value={reasonRadio.message}
                              checked={reasonRadio.selected}
                              onClick={this.onSelectRadio.bind(this, reasonRadio.name)}
                              readOnly />&nbsp;&nbsp;{reasonRadio.message}
                          </label>
                        )
                      })
                    }
                  </div>
                  {(
                    <EnTextArea
                      id="reasonTextarea"
                      rows="3"
                      size="30"
                      placeholder=""
                      onChange={this.onchangeReasonTextarea.bind(this)}
                      value={this.state.reasonRadios[this.state.indexRadioTextarea].textareaText}
                      style={styleReasonTextarea}
                    />
                  )}
                  <div style={styleSubmitButtons}>
                    <button className="btn btn-danger" title="ยกเลิก" onClick={this.onCancel.bind(this)} >
                      <span className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                    </button>
                    <button
                      className="btn btn-info"
                      onClick={e => { e.preventDefault(); this.onSave(); }}
                      disabled={
                        !this.state.reasonRadios.find(reasonRadio => reasonRadio.selected) ||
                        this.state.toggleEnabled === this.state.originalToggleEnabled || (
                          this.state.reasonRadios[this.state.indexRadioTextarea].selected &&
                          this.state.reasonRadios[this.state.indexRadioTextarea].textareaText.length < 5
                        )
                      }
                    >
                      <span className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                    </button>
                  </div>
                </div>
              </div>
            }
          </FormGroup>
        }
        {isAllow('VIEW_PAUSE_MATCHING') &&
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-bordered table-hover has-pagination">
                    <thead>
                      <tr>
                        <th width="10%" className="text-center">ลำดับที่</th>
                        <th width="10%" className="text-center">สถานะ</th>
                        <th width="10%" className="text-center">เหตุผล</th>
                        <th width="10%" className="text-center">วันที่ทำรายการ</th>
                        <th width="10%" className="text-center">ทำรายการโดย</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        _.get(this.state, 'logsResponse.data') &&
                        this.state.logsResponse.data.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">{this.state.logsResponse.pagination.start_index + index + 1}</td>
                              <td className="text-center">{data.action}</td>
                              <td className="text-center">{data.reason}</td>
                              <td className="text-center">{
                                data.created_date ?
                                  moment(data.created_date).utcOffset(7).format('DD/MM/YYYY HH:mm:ss') + ' น.' :
                                  ''
                              }</td>
                              <td className="text-center">{data.user}</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {
              _.get(this.state, 'logsResponse.pagination') && (
                <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      pagination={this.state.logsResponse.pagination}
                      handlePageClick={this.onPageClick.bind(this)}
                    />
                  </div>
                </div>
              )
            }
          </div>
        }
      </div>
    );
  }
}

export default inject('contractor', 'permission', 'auth')(observer(AdminPermitContractor));

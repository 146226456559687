import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import config from '../../../config';
import { isAllow } from '../../../utils/permission';
import FormGroup from '../../../components/form/FormGroup';
import EnText from '../../../components/form/EnText';
import SimplePagination from '../../../components/common/SimplePagination';
import _ from 'lodash'
import { toast } from 'react-toastify';

export class ManageGroupProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      search: '',
      items: [],
      query: {
        search: '',
        limit: 10,
        page: 1,
        totalPages: 1,
        totalItems: 1
      },
    };
  }

  async componentDidMount() {
    try {
      this.getProductList()
    } catch (err) {
      toast.error(err.message);
    }
  }

  onAddNew() {
    this.props.history.push(`${config.publicUrl}/qchang/productgroup/add`);
  }

  onEdit(id) {
    if (id) {
      this.props.history.push(`${config.publicUrl}/qchang/productgroup/${id}/edit`);
    }
  }

  getProductList() {
    this.setState({ loading: true })

    return this.props.productgroup
      .getProductList(this.state.query)
      .then(({ items, limit, page, totalPages, totalItems }) => {
        this.setState({
          loading: false,
          items,
          query: {
            ...this.state.query,
            limit,
            page,
            totalPages,
            totalItems,
          },
        });
      });
  }

  onUpdateQuery = (key, value) => {
    const query = this.state.query
    _.set(query, key, value)
    this.setState(query)
  }

  onUpdateSearch = (search) => {
    this.setState({ search })
  }

  onClickSearch = () => {
    this.onUpdateQuery('search', this.state.search)
    this.onUpdateQuery('page', 1)
    this.getProductList()
  }

  onSetPage = (page) => {
    this.onUpdateQuery('page', page)
    this.getProductList()
  }


  getTableRows(items) {
    return items.map((item, index) => {
      const { id, name } = item;
      return (
        <tr key={id}>
          <td className="text-center">{((this.state.query.page - 1) * this.state.query.limit) + index + 1}</td>
          <td>{name ? name.th : ''}</td>
          <td className="text-center">
            <button disabled={isAllow("EDIT_PRODUCT_LIST_IN_EACH_GROUP") ? false:true} className="btn btn-xs" title="แก้ไข" data-tip="แก้ไข" onClick={this.onEdit.bind(this, id)}>
              <span className="fa fa-pencil" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  render() {
    let rows = this.getTableRows(this.state.items);

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.loading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">กลุ่มรายการสินค้า</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <FormGroup label="ค้นหา:" containerClassName="col-md-5">
                    <div className='form-group has-feedback has-clear'>
                      <div className='input-group'>
                        <EnText className='form-control' placeholder='ค้นหาด้วยชื่อกลุ่มสินค้า' value={this.state.search} onChange={(e) => this.onUpdateSearch(e.target.value)} onKeyPress={(e) => { if (e.key === 'Enter') this.onClickSearch() }} />
                        <span className='input-group-btn'>
                          <button
                            id='search-button'
                            type='button'
                            className='btn btn-primary btn-outline-secondary btn-custom'
                            onClick={() => { this.onClickSearch() }}
                          >
                            <i className='fa fa-search' aria-hidden='true' />
                          </button>
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="10%" className="text-center">ลำดับที่</th>
                            <th width="40%" className="text-center">ชื่อกลุ่มสินค้า</th>
                            <th width="5%" className="text-center">
                              <button 
                                disabled={isAllow("CREATE_PRODUCT_GROUP") ? false : true}
                                className="btn btn-success" 
                                title="สร้างใหม่" 
                                data-tip="สร้างใหม่" 
                                onClick={this.onAddNew.bind(this)}>
                                  <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <SimplePagination pagination={this.state.query} onSetPage={this.onSetPage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('productgroup')(observer(ManageGroupProduct));

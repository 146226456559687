import BaseStore from '../BaseStore';
import humps from 'humps';
import config from '../../config';
import { http } from '../../utils/http';
import { pick, pickBy } from 'lodash';
import qs from 'query-string'

export class Productgroup extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      info: this.initInfo(),
    });
  }

  initInfo() {
    return {
      name: '',
      description: {
        th: '',
      },
      shortDescription: {
        th: '',
      },
      images: [],
      productIds: [],
      bestSellerId: '',
      unit: {
        th: '',
      },
      seo: {
        title: '',
        slug: '',
        keywords: '',
        description: '',
      },
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  saveSeoInfo = (value, key) => {
    this.info.seo[key] = value;
  }

  async getProductList(query = { limit: 10, page: 1 }) {
    query = pickBy(query, (value) => !!value)
    const qstring = qs.stringify(pick(query, ['limit', 'page', 'search']))

    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.qchang}/v1/productgroup?${qstring}`);
      if (response.status === 200 && response.data) {
        const items = humps.camelizeKeys(response.data.items);
        this.items = items

        return Promise.resolve({
          ...response.data,
          items
        })
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  saveUnit(value) {
    this.info.unit.th = value;
  }

  async getProductById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.qchang}/v1/productgroup/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      return humps.camelizeKeys(response.data.data);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getProductForEdit(id) {
    try {
      const data = await this.getProductById(id);
      this.info = data[0];
    } catch (error) {
      throw error;
    }
  }

  getUpdateInfo(options) {
    const info = this.toJS().info;
    const { selectProductIds, selectBestSellerId } = options;
    if (Array.isArray(selectProductIds) && selectProductIds.length) {
      info.productIds = selectProductIds;
    }
    if (selectBestSellerId) {
      info.bestSellerId = selectBestSellerId;
    }

    return info;
  }

  async createProductGroup(options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.getUpdateInfo(options);
      const response = await http.post(`${config.api.qchang}/v1/productgroup`, info);
      if (response.status !== 200) {
        if (response.data && response.data.message === 'DUPLICATED_SLUG') {
          throw new Error('Slug ซ้ำ');
        }
        throw new Error('เพิ่มสินค้าไม่สำเร็จ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateProductGroup(id, options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.getUpdateInfo(options);
      const response = await http.put(`${config.api.qchang}/v1/productgroup/${id}`, info);
      if (response.status !== 200) {
        if (response.data && response.data.message === 'DUPLICATED_SLUG') {
          throw new Error('Slug ซ้ำ');
        }
        throw new Error('แก้ไขสินค้าไม่สำเร็จ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Productgroup();

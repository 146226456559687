import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import ReactSelect from 'react-select';
import swal from 'sweetalert2';
import _ from 'lodash'

import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import EnButton from '../../components/form/EnButton';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import { checkEmoji, FormValidation, required, validateUrl, } from '../../components/form/FormValidation';
import EnDatePicker from '../../components/form/EnDatePicker';
import FormGroup from '../../components/form/FormGroup';
import EnToggle from '../../components/form/EnToggle';
import EnDropDown from '../../components/form/EnDropDown';
import EnTagSelect from '../../components/form/EnTagSelect';
import moment from 'moment'
import http from '../../utils/http';
import config from '../../config';
import { isAllow } from '../../utils/permission';
import UploadImagesComponent from '../../components/uploads';
import EnCKEditor from '../../components/form/EnCKEditor';
import styled from 'styled-components';

import './custom.css'
import { toast } from 'react-toastify';

const Select = styled(ReactSelect)`
  .Select-control {
    padding-bottom: 5px;
  }
`

class BroadcastCreatePage extends Component {
  frm;
  constructor(props) {
    super(props);
    this.provincesOption = []
    this.gradesOption = []
    this.typeofworksOption = []
    this.typeofworksGroupOption = []
    this.isJuristicOption = []
    this.state = {
      isLoading: false,
      isEdit: false,
      formValues: {
        title: "",
        body: "",
        redirect_url: "",
        work_of_areas: [],
        grades: [],
        is_juristics: [],
        code_groups: [],
        type_of_works: [],
        announcement_time: '',
        announcement_date: '',
        cover_image: null,
        content_images: [],
        // new for broadcast group
        broadcast_now: true,
        broadcast_groups: 'selected_groups',
        types_of_contractors: 'contractor_only',
        contractor_list: [],
        subcontractor_list: [],
        // new for broadcast group
      },
      selectOptions: {
        provincesOption: [],
        gradesOption: [],
        typeofworksOption: [],
        typeofworksGroupOption: [],
        isJuristicOption: [],
        broadcastGroupOption: [
          { label: 'เผยแพร่เฉพาะกลุ่ม', value: 'selected_groups' },
          { label: 'เผยแพร่ทุกคน', value: 'all_contractor_and_subcontractor' },
          { label: 'เผยแพร่รายบุคคล', value: 'selected_individuals' },
        ],
        typesOfContractorOption: [
          { label: 'ส่งให้เฉพาะบัญชีหัวหน้าทีมช่าง ', value: 'contractor_only' },
          { label: 'ส่งให้บัญชีลูกทีมช่างทุกคน ', value: 'subcontractor_only' },
          { label: 'ส่งทั้งบัญชีหัวหน้าทีมช่างและลูกทีมช่าง ', value: 'contractor_and_subcontractor' },
        ],
        contractorOption: [],
        subContractorOption: [],
      },
    };

    this.debounceSearchOption = _.debounce(this.searchOption, 1000);
  }

  async componentWillMount() {
    const { contractorgrade: contractorGradeStore } = this.props;

    await contractorGradeStore.initGradeMap();
    this.resetFormValueAndSelectOption()
    const id = this.props.match.params.id;
    await this.defaultSelectOption()
    if (id) {
      this.setState({ isEdit: true, isLoading: true })
      await this.fetchBroadcast(id)
      this.setState({ isLoading: false })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { formValues: prevFormValues } = prevState
    const { formValues } = this.state
    if (formValues.code_groups !== prevFormValues.code_groups) {
      this.autoSetTypeofwork()
    }
  }

  async fetchBroadcast(id) {
    const { data } = await http.get(`${config.api.sims}/v1/broadcast/${id}`)
    const { work_of_areas, code_groups, grades, is_juristics, type_of_works, ...rest } = data
    const listProvinces = work_of_areas.map(item => item.province_id)
    this.handleChangeCodeGroup(code_groups)
    this.handleChangeGrades(grades)
    this.handleChangeIsJuristics(is_juristics)
    this.handleChangeTypeOfwork(type_of_works)
    this.handleChangeWorkofareas(listProvinces)
    this.setState({ formValues: { ...this.state.formValues, announcement_date: moment(data.announcement_date), ...rest } })
  }

  resetRecipientGroupForm() {
    this.setState({
      formValues: {
        ...this.state.formValues,
        work_of_areas: [],
        grades: [],
        is_juristics: [],
        code_groups: [],
        type_of_works: [],
      }
    })
  }

  resetFormValueAndSelectOption() {
    this.setState({
      formValues: {
        title: '',
        body: '',
        redirect_url: '',
        work_of_areas: [],
        grades: [],
        is_juristics: [],
        code_groups: [],
        type_of_works: [],
        announcement_date: '',
        announcement_time: '',
        cover_image: null,
        content_images: [],
        // new for broadcast group
        broadcast_now: true,
        broadcast_groups: 'selected_groups',
        types_of_contractors: 'contractor_only',
        contractor_list: [],
        subcontractor_list: [],
        // new for broadcast group
      },
      selectOptions: {
        ...this.state.selectOptions,
        provincesOption: [],
        gradesOption: [],
        typeofworksOption: [],
        typeofworksGroupOption: [],
        isJuristicOption: [],
      }
    })
  }

  async defaultSelectOption() {
    const { contractorgrade: contractorGradeStore } = this.props;
    const { gradeMap } = contractorGradeStore.toJS();

    this.setState({ isLoading: true })
    await this.props.misc.getAllProvince();
    await this.props.typeofwork.getTypeOfWorks()
    const miscStore = this.props.misc.toJS();
    const provincesOption = miscStore.miscData.province.map(province => ({ label: province.name, value: province.code }));
    const gradesOption = Object.entries(gradeMap).map(([originalGrade, displayedGrade]) => ({ label: displayedGrade, value: originalGrade }));
    const isJuristicOption = [
      { label: 'บุคคลธรรมดา', value: 'none-juristic' },
      { label: 'นิติบุคคล', value: 'juristic' },
    ]

    const filterTypeofworks = this.props.typeofwork.items.filter(typeofwork => typeofwork.code && typeofwork.name)
    const sortTypeofworks = filterTypeofworks.sort((a, b) => a.code < b.value ? -1 : (a.value > b.value ? 1 : 0))
    const typeofworksOption = sortTypeofworks.map(typeofwork => ({ label: `${typeofwork.name}`, value: typeofwork.value, code_group: typeofwork.code_group }))

    const filterTypeofworksGroup = this.props.typeofwork.items.filter(typeofwork => typeofwork.code_group)
    const uniqTypeofworksGrpup = [...new Map(filterTypeofworksGroup.map(item => [item['code_group'], item])).values()]
    const sortTypeofworksGroup = uniqTypeofworksGrpup.sort((a, b) => a.code_group < b.code_group ? -1 : (a.code_group > b.code_group ? 1 : 0))
    const typeofworksGroupOption = sortTypeofworksGroup.map(typeofwork => ({ label: `${typeofwork.group_name}`, value: typeofwork.code_group }))

    this.provincesOption = provincesOption
    this.gradesOption = gradesOption
    this.typeofworksOption = typeofworksOption
    this.typeofworksGroupOption = typeofworksGroupOption
    this.isJuristicOption = isJuristicOption
    this.setState({ selectOptions: { ...this.state.selectOptions, provincesOption, gradesOption, typeofworksOption, typeofworksGroupOption, isJuristicOption } })
    this.setState({ isLoading: false })
  }

  getAnnouncementTime() {
    const now = moment();
    const minute = now.minute();
    const announcementTime = now
      .minute(minute <= 15 ? 30 : minute <= 45 ? 0 : 30)
      .add(minute > 15 ? 1 : 0, 'hour')
      .format('HH:mm');

    return announcementTime;
  }

  handleChangFieldForm(key, e) {
    const value = e.target ? e.target.value : e

    if (key === 'broadcast_now') {
      if (value === true) {
        return this.setState({ formValues: { ...this.state.formValues, [key]: value, announcement_date: '', announcement_time: '' } })
      }

      return this.setState({
        formValues: {
          ...this.state.formValues,
          [key]: value,
          announcement_date: new Date(),
          announcement_time: this.getAnnouncementTime(),
        } })
    }

    if (key === 'announcement_date') {
      return this.setState({
        formValues: {
          ...this.state.formValues,
          ['announcement_date']: new Date(value.toDate()),
          ['announcement_time']: this.getAnnouncementTime(),
        }
      });
    }

    if (key === 'broadcast_groups') {
      const typesOfContractors = value === 'selected_groups' ? 'contractor_only' : 'contractor_and_subcontractor'
        return this.setState({
          formValues: {
            ...this.state.formValues,
            [key]: value,
            types_of_contractors: typesOfContractors,
            contractor_list: [],
            subcontractor_list: [],
            work_of_areas: [],
            grades: [],
            is_juristics: [],
            code_groups: [],
            type_of_works: [],
          }
        })
    }

    return this.setState({ formValues: { ...this.state.formValues, [key]: value } })
  }

  handleChangeWorkofareas = (values) => {
    const listValues = typeof values === 'string' ? values.split(",") : values
    const cloneOption = [...this.provincesOption]
    const optionSlected = cloneOption.filter(item => listValues.includes(item.value))
    const optionNoneSeleted = cloneOption.filter(item => !listValues.includes(item.value))
    return this.setState({
      selectOptions: { ...this.state.selectOptions, provincesOption: optionNoneSeleted },
      formValues: { ...this.state.formValues, work_of_areas: optionSlected }
    })
  }

  handleChangeGrades = (values) => {
    const listValues = typeof values === 'string' ? values.split(",") : values
    const cloneOption = [...this.gradesOption]
    const optionSlected = cloneOption.filter(item => listValues.includes(item.value))
    const optionNoneSeleted = cloneOption.filter(item => !listValues.includes(item.value))
    return this.setState({
      selectOptions: { ...this.state.selectOptions, gradesOption: optionNoneSeleted },
      formValues: { ...this.state.formValues, grades: optionSlected }
    })
  }

  handleChangeIsJuristics = (values) => {
    const listValues = typeof values === 'string' ? values.split(",") : values
    const cloneOption = [...this.isJuristicOption]
    const optionSlected = cloneOption.filter(isJuristic => listValues.includes(isJuristic.value))
    const optionNoneSeleted = cloneOption.filter(isJuristic => !listValues.includes(isJuristic.value))
    return this.setState({
      selectOptions: { ...this.state.selectOptions, isJuristicOption: optionNoneSeleted },
      formValues: { ...this.state.formValues, is_juristics: optionSlected }
    })
  }

  handleChangeCodeGroup = (values) => {
    const newSelectOption = { ...this.state.selectOptions }
    const newFormValue = { ...this.state.formValues }
    const listValues = typeof values === 'string' ? values.split(",") : values
    const cloneGroupTypeOfwork = [...this.typeofworksGroupOption]
    const groupTypeOfworkOptionSeleted = cloneGroupTypeOfwork.filter(item => listValues.includes(item.value))
    const optionNoneSeleted = cloneGroupTypeOfwork.filter(item => !listValues.includes(item.value))
    this.setState({
      selectOptions: { ...newSelectOption, typeofworksGroupOption: optionNoneSeleted },
      formValues: { ...newFormValue, code_groups: groupTypeOfworkOptionSeleted }
    })
  }

  autoSetTypeofwork() {
    const { selectOptions, formValues, ...rest } = { ...this.state }
    const { typeofworksOption } = selectOptions
    const { code_groups, type_of_works } = formValues
    const code_groupValues = code_groups.map(item => item.value)
    const selectTypeofwork = typeofworksOption.filter(item => code_groupValues.includes(item.code_group))
    const selectTypeofworkValue = selectTypeofwork.map(item => item.value)
    const selectOldTypeofworkValue = type_of_works.map(item => item.value)
    this.handleChangeTypeOfwork([...selectTypeofworkValue, ...selectOldTypeofworkValue])
  }

  handleChangeTypeOfwork = (values) => {
    const newSelectOption = { ...this.state.selectOptions }
    const newFormValue = { ...this.state.formValues }
    const listValues = typeof values === 'string' ? values.split(",").map(item => parseInt(item)) : values
    const cloneOption = [...this.typeofworksOption]
    const optionSelected = cloneOption.filter(item => listValues.includes(item.value))
    const optionNoneSelected = cloneOption.filter(item => !listValues.includes(item.value))
    this.setState({
      selectOptions: { ...newSelectOption, typeofworksOption: optionNoneSelected },
      formValues: { ...newFormValue, type_of_works: optionSelected }
    })
  }

  handleChangImage = (imageType, files) => {
    if (imageType === 'cover_image') {
      const file = files[0]
      this.setState({
        formValues: {
          ..._.get(this.state, 'formValues', {}),
          cover_image: file
        }
      })
    }

    if (imageType === 'content_images') {
      this.setState({
        formValues: {
          ..._.get(this.state, 'formValues', {}),
          content_images: files
        }
      })
    }
  }

  validateForm(info) {
    const isRequiredTitle = required(info.title)
    const isRequiredBody = required(info.body)
    const isEmojiTitle = checkEmoji(info.title)
    // const isEmojiBody = checkEmoji(info.body)
    const isValidateUrl = validateUrl(info.redirect_url)
    return !isRequiredBody && !isRequiredTitle && !isEmojiTitle && !isValidateUrl // && !isEmojiBody 
  }

  modalConfirmSendNotificationNow() {
    return swal.fire({
      title: 'เผยแพร่ประกาศทันที',
      text: 'โปรดตรวจสอบการประกาศดังกล่าว หากถูกต้องครบถ้วน กรุณากดปุ่ม เผยแพร่ทันที',
      showCancelButton: true,
      confirmButtonText: 'เผยแพร่ทันที',
      cancelButtonText: 'ยกเลิก',
      cancelButtonColor: '#f44336',
      confirmButtonColor: '#007bff',
      customClass: {
        confirmButton: 'broadcast-confirm-btn',
        cancelButton: 'broadcast-cancel-btn'
      }
    }).then((result) => {
      return result
    })
  }

  async handleSubmit() {
    try {
      const { formValues, isEdit } = this.state
      this.frm.isValid()
      const isFormError = this.validateForm(formValues)
      if (!isFormError) {
        swal.fire({
          icon: 'error',
          title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
          confirmButtonText: 'ตกลง',
        });
        return;
      }

      if (formValues.broadcast_now) {
        const confirmSendNotificationNow = await this.modalConfirmSendNotificationNow()
        if (!confirmSendNotificationNow.value) {
          return false
        }
      }

      const uploaded = await this.uploadImages();

      // contractor list
      if (formValues.contractor_list && formValues.contractor_list.length) {
        formValues.contractor_list = formValues.contractor_list.map(item => item.value)
      }

      // subcontractor list
      if (formValues.subcontractor_list && formValues.subcontractor_list.length) {
        formValues.subcontractor_list = formValues.subcontractor_list.map(item => item.value)
      }

      const _data = {
        ...formValues,
        work_of_areas: formValues.work_of_areas.map(item => ({ province_id: item.value })),
        grades: formValues.grades.map(item => item.value),
        code_groups: formValues.code_groups.map(item => item.value),
        is_juristics: formValues.is_juristics.map(item => item.value),
        type_of_works: formValues.type_of_works.map(item => item.value),
        ...uploaded,
      }

      if (formValues.broadcast_now) {
        _data.announcement_date = new Date();
        _data.announcement_time = moment().format('HH:mm');
      } else {

        if (!_data.announcement_date) {
          _data.announcement_date = new Date();
        }

        if (!_data.announcement_time) {
          _data.announcement_time = this.getAnnouncementTime();
        }
      }

      if (isEdit) {
        const id = this.props.match.params.id;
        await http.patch(`${config.api.sims}/v1/broadcast/${id}`, _data);
      } else {
        await http.post(`${config.api.sims}/v1/broadcast`, _data);
      }

      swal.fire({ title: 'บันทึกข้อมูลเรียบร้อย', icon: 'success', }).then(async (result) => {
        if (result.value) this.props.history.push(`${config.publicUrl}/superadmin/broadcasts`)
      });
    } catch (error) {
      toast.error(error.message || 'ตรวจสอบคำร้องขอ');
    }
  }

  async searchOption(key, value) {
    if (!key || !value || value.length < 3) return;

    const searchResponse = await this.props.broadcasts.broadcastSearch({ key, value });

    if (key === 'contractors') {
      this.setState({
        selectOptions: { ...this.state.selectOptions, contractorOption: searchResponse }
      });
    }

    if (key === 'sub_contractors') {
      this.setState({
        selectOptions: { ...this.state.selectOptions, subContractorOption: searchResponse }
      });
    }
  }

  isValid() {
    const { formValues } = this.state
    const validTitle = _.isEmpty(formValues.title)
    const validBody = _.isEmpty(formValues.body)

    if (validTitle || validBody || formValues.is_published) {
      return true
    }

    if (formValues.broadcast_groups === 'selected_groups') {
      // work_of_areas, grades, is_juristics, code_groups, type_of_works < 1 ให้ return true
      // if (formValues.types_of_contractors !== 'subcontractor_only' && !formValues.work_of_areas.length && !formValues.grades.length && !formValues.is_juristics.length && !formValues.code_groups.length && !formValues.type_of_works.length) {
      //   return true
      // }
    }

    if (formValues.broadcast_groups === 'selected_individuals') {
      // contractor_list or subcontractor_list < 1 ให้ return true
      if (!formValues.contractor_list.length && !formValues.subcontractor_list.length) {
        return true
      }
    }

    return false
  }

  onCancel() {
    this.resetFormValueAndSelectOption()
    this.props.history.push(`${config.publicUrl}/superadmin/broadcasts`)
  }

  timePickerOption() {
    const { formValues } = this.state;
    const selectedDate = moment(formValues.announcement_date);
  
    if (!selectedDate.isValid()) {
      // ถ้าวันที่ที่เลือกไม่ถูกต้อง ให้คืนค่าเป็น null หรือจัดการตามความเหมาะสม
      return null;
    }
  
    const now = moment();
    const minuteNow = now.get('minute');

    const timeOption = _.range(0, 24).flatMap((hour) =>
      _.range(0, 60, 30).map((minute) => {
        const time = moment({ hour, minute }).format('HH:mm');
  
        // สร้าง Moment object สำหรับเวลาของตัวเลือกนี้
        const optionTime = moment(selectedDate)
          .hour(hour)
          .minute(minute)
          .second(0);

        let isDisabled = false;

        if (minuteNow >= 0 && minuteNow <= 15) {
          isDisabled = optionTime.isSameOrBefore(now);
        } else if (minuteNow > 15 && minuteNow <= 45) {
          isDisabled = minute === 0
            ? optionTime.isSameOrBefore(now)
            : optionTime.clone().subtract(30, 'minutes').isBefore(now);
        } else {
          isDisabled = minute === 0
            ? optionTime.clone().subtract(30, 'minutes').isBefore(now)
            : optionTime.isBefore(now);
        }

        return (
          <option
            key={`${hour}-${minute}`}
            disabled={isDisabled}
            value={time}
            style={isDisabled ? { backgroundColor: '#f0f0f0', color: '#888' } : {}}
          >
            {time}
          </option>
        );
      })
    );
  
    return timeOption;
  }

  async uploadImages() {
    try {
      const { formValues } = this.state;
      const coverImage = _.get(formValues, 'cover_image', null);
      const contentImages = _.get(formValues, 'content_images', []);
  
      const contentImagesUrl = contentImages.filter(_.isString);
      const contentImagesFile = contentImages.filter(item => item instanceof File);
      const coverImageFile = coverImage instanceof File ? coverImage : null;
  
      const resultUpload = {
        cover_image: coverImage || config.broadcast.defaultCoverImage,
        content_images: contentImagesUrl,
      };
  
      if (coverImageFile || contentImagesFile.length) {
        this.setState({ isLoading: true });
  
        const formData = new FormData();
        if (coverImageFile) formData.append('cover_image', coverImageFile);
        contentImagesFile.forEach(image => formData.append('content_images', image));
  
        const response = await http.postMultipartFormData(
          `${config.api.sims}/v1/broadcast/upload-images`,
          formData,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'multipart/form-data',
            },
          }
        );
  
        const result = _.get(response, 'data.result', null);
        if (!result) return toast.error('อัพโหลดรูปไม่สำเร็จ');
  
        resultUpload.cover_image = _.get(result, 'cover_image.path', resultUpload.cover_image);
        resultUpload.content_images = [
          ...resultUpload.content_images,
          ..._.get(result, 'content_images', []).map(item => item.path),
        ];
  
        toast.success('อัพโหลดรูปภาพสำเร็จ');
      }
  
      return resultUpload;
    } catch (error) {
      toast.error('อัพโหลดรูปไม่สำเร็จ');
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onEditorChange = (value) => {
    this.setState({ formValues: { ...this.state.formValues, body: value } })
  }

  onCloseDropdown = () => {
    this.setState({
      selectOptions: {
        ...this.state.selectOptions,
        contractorOption: [],
        subContractorOption: [],
      }
    })
  }

  renderOptionWithGroupSelect() {
    const { broadcast_groups } = this.state.formValues;
    if (!broadcast_groups) return null;

    if (broadcast_groups === 'selected_groups') {
      return (
        <>
          <FormGroup label="ประเภทบัญชีผู้ใช้งาน" containerClassName="col-md-12">
            <Select
              disabled={this.state.formValues.is_published}
              options={this.state.selectOptions.typesOfContractorOption || []}
              value={this.state.formValues.types_of_contractors}
              onChange={(e) => this.handleChangFieldForm('types_of_contractors', e.value)}
            />
          </FormGroup>

          <FormGroup label="พื้นที่รับงาน" containerClassName="col-md-12">
            <Select
              multi
              searchable
              closeOnSelect={false}
              disabled={this.state.formValues.types_of_contractors === 'subcontractor_only' || this.state.formValues.is_published}
              options={this.state.selectOptions.provincesOption || []}
              value={this.state.formValues.work_of_areas}
              onChange={(option) => this.handleChangFieldForm('work_of_areas', option)}
            />
          </FormGroup>

          <FormGroup label="เกรดทีมช่าง" containerClassName="col-md-12">
            <Select
              multi
              searchable
              closeOnSelect={false}
              disabled={this.state.formValues.types_of_contractors === 'subcontractor_only' || this.state.formValues.is_published}
              options={this.state.selectOptions.gradesOption || []}
              value={this.state.formValues.grades}
              onChange={(option) => this.handleChangFieldForm('grades', option)}
            />
          </FormGroup>

          <FormGroup label="ประเภทช่าง" containerClassName="col-md-12">
            <Select
              multi
              searchable
              closeOnSelect={false}
              disabled={this.state.formValues.types_of_contractors === 'subcontractor_only' || this.state.formValues.is_published}
              options={this.state.selectOptions.isJuristicOption || []}
              value={this.state.formValues.is_juristics}
              onChange={(option) => this.handleChangFieldForm('is_juristics', option)}
            />
          </FormGroup>

          <FormGroup label="กลุ่มประเภทงาน" containerClassName="col-md-12">
            <Select
              multi
              searchable
              closeOnSelect={false}
              disabled={this.state.formValues.types_of_contractors === 'subcontractor_only' || this.state.formValues.is_published}
              options={this.state.selectOptions.typeofworksGroupOption || []}
              value={this.state.formValues.code_groups}
              onChange={(option) => this.handleChangFieldForm('code_groups', option)}
            />
          </FormGroup>

          <FormGroup label="ประเภทงาน" containerClassName="col-md-12">
            <Select
              multi
              searchable
              closeOnSelect={false}
              disabled={this.state.formValues.types_of_contractors === 'subcontractor_only' || this.state.formValues.is_published}
              options={this.state.selectOptions.typeofworksOption || []}
              value={this.state.formValues.type_of_works}
              onChange={(option) => this.handleChangFieldForm('type_of_works', option)}
            />
          </FormGroup>
        </>
      );
    }


    if (broadcast_groups === 'selected_individuals') {
      return (
        <>
          <FormGroup label="รายชื่อหัวหน้าทีม" containerClassName="col-md-12">
            <Select
              multi
              searchable
              closeOnSelect={false}
              disabled={this.state.formValues.is_published}
              options={this.state.selectOptions.contractorOption || []}
              value={this.state.formValues.contractor_list}
              onChange={(option) => {this.handleChangFieldForm('contractor_list', option)}}
              onInputChange={(value) => this.debounceSearchOption('contractors', value)}
              onClose={this.onCloseDropdown}
            />
          </FormGroup>

          <FormGroup label="รายชื่อลูกทีม" containerClassName="col-md-12">
            <Select
              multi
              searchable
              closeOnSelect={false}
              disabled={this.state.formValues.is_published}
              options={this.state.selectOptions.subContractorOption || []}
              value={this.state.formValues.subcontractor_list}
              onChange={(option) => this.handleChangFieldForm('subcontractor_list', option)}
              onInputChange={(value) => this.debounceSearchOption('sub_contractors', value)}
              onClose={this.onCloseDropdown}
            />
          </FormGroup>
        </>
      );
    }

    // all_contractor_and_subcontractor
    return null;
  }

  render() {
    const { formValues, isLoading, isEdit } = this.state
    const title = isEdit ? 'แก้ไข ประกาศ' : 'สร้างประกาศใหม่';

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{title}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation ref={(el) => { this.frm = el }}>
                  <div className="row">
                    <div style={{ marginTop: '2rem' }} />
                    <FormGroup validations label="หัวข้อ" containerClassName="col-md-12">
                      <EnTextFormGroup
                        disabled={formValues.is_published}
                        id="title"
                        type="text"
                        value={formValues['title']}
                        onChange={(e) => this.handleChangFieldForm('title', e)}
                        validations={[required, checkEmoji]}
                      />
                    </FormGroup>
                    <EnCKEditor
                      label='เนื้อหาข่าว'
                      type='broadcast'
                      containerClassName={'col-md-12'}
                      disabled={formValues.is_published}
                      value={formValues['body']}
                      onChange={(event, editor) => {this.onEditorChange(editor.getData())}}
                      validations={[required]}
                    />

                    <FormGroup containerClassName="col-md-12" label="ภาพหน้าปก">
                      <UploadImagesComponent
                        accept="image/jpeg, image/jpg, image/png, image/webp"
                        multiple={false}
                        maxFile={1}
                        files={this.state.formValues.cover_image}
                        onChange={(files) => this.handleChangImage('cover_image', files)}
                      />
                    </FormGroup>

                    <FormGroup containerClassName="col-md-12" label="รูปภาพที่เกี่ยวข้อง">
                      <UploadImagesComponent
                        accept="image/jpeg, image/jpg, image/png, image/webp"
                        multiple={true}
                        maxFile={10}
                        files={this.state.formValues.content_images}
                        onChange={(files) => this.handleChangImage('content_images', files)}
                      />
                    </FormGroup>

                    <FormGroup label="ลิ้งค์แนบ" containerClassName="col-md-12">
                      <EnTextFormGroup
                        placeholder="http://"
                        disabled={formValues.is_published}
                        id="redirect_url"
                        type="text"
                        value={formValues['redirect_url']}
                        onChange={(e) => this.handleChangFieldForm('redirect_url', e)}
                        validations={[validateUrl]}
                        notRequired
                      />
                    </FormGroup>

                    <FormGroup label="กำหนดกลุ่มผู้ได้รับประกาศ" containerClassName="col-md-12">
                      <Select
                        disabled={formValues.is_published}
                        options={this.state.selectOptions.broadcastGroupOption || []}
                        value={this.state.formValues.broadcast_groups}
                        onChange={(e) => this.handleChangFieldForm('broadcast_groups', e.value)}
                      />
                    </FormGroup>

                    {this.renderOptionWithGroupSelect()}

                    <FormGroup label="กำหนดเวลาการประกาศ **หากไม่กำหนดเวลาการประกาศ จะประกาศทันที" containerClassName="col-md-12">
                      <EnToggle
                        disabled={isAllow("SET_BROADCAST_DATE_AND_TIME") ? formValues.is_published : true}
                        checked={!formValues.broadcast_now}
                        onChange={() => this.handleChangFieldForm('broadcast_now', !formValues.broadcast_now)}
                      />
                    </FormGroup>
                    <FormGroup containerClassName="col-md-6" label="วันประกาศ">
                      <EnDatePicker
                        id="announcement_date"
                        isPlaceholder="กรุณาเลือกวันที่ประกาศ"
                        initialDate={formValues.announcement_date ? moment(formValues.announcement_date) : undefined}
                        disabled={formValues.broadcast_now || formValues.is_published}
                        onDateChange={(value) => this.handleChangFieldForm('announcement_date', value)}
                      />
                    </FormGroup>
                    <FormGroup containerClassName="col-md-6" label="เวลาประกาศ">
                      <EnDropDown
                        id="announcement_time"
                        placeholder="เลือกเวลา"
                        value={formValues['announcement_time']}
                        disabled={!formValues.announcement_date || formValues.broadcast_now || formValues.is_published}
                        onChange={value => { this.handleChangFieldForm('announcement_time', value) }}
                      >
                        {
                          formValues.broadcast_now 
                            ? (<option value="">กรุณาเลือกเวลาประกาศ</option>)
                            : this.timePickerOption()
                        }
                      </EnDropDown>
                    </FormGroup>
                  </div>
                  <FormGroup containerClassName="col-md-12">
                    {formValues.is_published
                      ? <EnButton
                        className="btn-danger pull-right"
                        onClick={() => this.props.history.push(`${config.publicUrl}/superadmin/broadcasts`)}
                      >
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                      </EnButton>
                      : <span>
                        <EnButton
                          className="btn-info pull-right"
                          disabled={this.isValid()}
                          onClick={() => this.handleSubmit()}
                        >
                          <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                        </EnButton>
                        <EnButton
                          disabled={formValues.is_published}
                          className="btn-danger pull-right"
                          onClick={() => this.onCancel()}
                        >
                          <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                        </EnButton>
                      </span>
                    }
                  </FormGroup>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container >
    );
  }
}

export default inject('misc', 'typeofwork', 'contractorgrade', 'broadcasts', 'contractor', 'subContractor')(observer(BroadcastCreatePage));

import * as _ from 'lodash';
import moment from 'moment';
import humps from 'humps';
import { Buffer } from 'buffer';
import { AvailableTime } from './data';
import infoIcon from '../assets/img/icn_info.svg';

export const KEY_NUMBERS = {
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  96: '0',
  97: '1',
  98: '2',
  99: '3',
  100: '4',
  101: '5',
  102: '6',
  103: '7',
  104: '8',
  105: '9',
};

export const customerReturn = [
  { key: 1, value: 'ไม่จ่ายเงินช่าง คืนเงินลูกค้าเต็มจำนวน' },
  // { key: 2, value: 'จ่ายเงินช่างเต็มจำนวน คืนเงินลูกค้าเต็มจำนวน' },
  // { key: 3, value: 'จ่ายเงินช่างเต็มจำนวน ไม่คืนเงินลูกค้า' },
  { key: 4, value: 'จ่ายเงินช่างบางส่วน คืนเงินลูกค้าเต็ม/บางส่วน' },
];

class Common {
  unavailableDate(contractors, dateList) {
    if (
      !(
        Array.isArray(contractors) &&
        contractors.length &&
        Array.isArray(dateList) &&
        dateList.length
      )
    ) {
      return [];
    }

    const unavailableDates = dateList.map((d) => moment(d).format('YYYYMMDD'));
    const dates = [];
    if (Array.isArray(unavailableDates) && unavailableDates.length) {
      let groupDate = _.groupBy(unavailableDates);
      let totalContractor = contractors.length;
      _.forEach(groupDate, (d) => {
        if (d.length >= totalContractor) {
          dates.push(moment(d[0], 'YYYYMMDD'));
        }
      });
    }

    return dates;
  }

  urlBuilder(obj) {
    return Object.keys(obj)
      .filter((key) => obj[key])
      .map((key) =>
        [humps.decamelize(key), obj[key]].map(encodeURIComponent).join('=')
      )
      .join('&');
  }

  getFileIcon(filename) {
    let icon = 'fa fa-file-o';
    let pattern = /\.[0-9a-z]+$/i;
    let ma = (filename || '').match(pattern);
    if (Array.isArray(ma) && ma.length) {
      let ext = (ma[0] || '').toLowerCase();

      switch (ext) {
        case '.doc':
        case '.docx':
          icon = 'fa fa-file-word-o';
          break;
        case '.ppt':
        case '.pptx':
          icon = 'fa fa-file-powerpoint-o';
          break;
        case '.xls':
        case '.xlsx':
          icon = 'fa fa-file-excel-o';
          break;
        case '.pdf':
          icon = 'fa fa-file-pdf-o';
          break;
        case '.jpg':
        case '.jpeg':
        case '.png':
          icon = 'fa fa-file-image-o';
          break;
        case '.zip':
        case '.rar':
        case '.7z':
          icon = 'fa fa-file-archive-o';
          break;
        case '.mp4':
        case '.avi':
        case '.mkv':
          icon = 'fa fa-file-video-o';
          break;
        case '.txt':
          icon = 'fa fa-file-text-o';
          break;
        default:
          break;
      }
    }

    return icon;
  }

  getAvailableTimeText(available) {
    const data = AvailableTime.find((at) => at.id === available);
    return data.title || '';
  }

  slugify(str) {
    return (
      str
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace('%', 'เปอร์เซนต์') // Translate some charactor
        // eslint-disable-next-line
        .replace(/[^\u0E00-\u0E7F\w\-]+/g, '') // Remove all non-word chars
        // eslint-disable-next-line
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '')
    );
  }

  isNumeric(value) {
    return /^-{0,1}\d+$/.test(value);
  }

  /**
   * Opens a PDF in a new browser tab using a Base64-encoded string.
   */
  openPdfInNewTab(base64) {
    const binaryData = Buffer.from(base64, 'base64').toString('binary')
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  }

  setNativeValue(element, value) {
    let lastValue = element.value;
    element.value = value;
    let event = new Event("input", { target: element, bubbles: true });
    // React 15
    event.simulated = true;
    // React 16
    let tracker = element._valueTracker;
    if (tracker) {
        tracker.setValue(lastValue);
    }
    element.dispatchEvent(event);
  }

  /**
   * Paginates an array of data.
   */
  paginate = (data, currentPage, itemsPerPage) => {
    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startItemIndex = (currentPage - 1) * itemsPerPage;
    const endItemIndex = startItemIndex + itemsPerPage;
    const paginatedData = data.slice(startItemIndex, endItemIndex);
    const hasPrevious = currentPage > 1;
    const hasNext = currentPage < totalPages;
    const previousPage = hasPrevious ? currentPage - 1 : null;
    const nextPage = hasNext ? currentPage + 1 : null;
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;
    const startPageRange = Math.max(currentPage - 2, 1);
    const endPageRange = Math.min(currentPage + 2, totalPages);

    return {
      paginated_data: paginatedData,
      pagination: {
        current_page: currentPage,
        previous_page: previousPage,
        next_page: nextPage,
        has_previous: hasPrevious,
        has_next: hasNext,
        is_first_page: isFirstPage,
        is_last_page: isLastPage,
        total_items: totalItems,
        total_pages: totalPages,
        start_page_range: startPageRange,
        end_page_range: endPageRange,
      },
    };
  };

  /**
   * Checks if a given string is a valid MongoDB ObjectId.
   * @param {string} str - The string to be checked.
   * @returns {boolean} - True if the string is a valid ObjectId, false otherwise.
   */
  isValidObjectId = (str) => {
    const objectIdRegex = /^[0-9a-fA-F]{24}$/;
    return _.isString(str) && objectIdRegex.test(str);
  };

  /**
   * Checks if a given string is a valid Thai phone number starting with "0".
   * @param {string} str - The string to be checked.
   * @returns {boolean} - True if the string is a valid Thai phone number, false otherwise.
   */
  isValidPhoneNumber(str) {
    const phoneNumberRegex = /^0[1-9]\d{8}$/;
    return _.isString(str) && phoneNumberRegex.test(str);
  }

  /**
   * Checks if two arrays have any common elements.
   *
   * @param {Array} firstArray - The first array to compare.
   * @param {Array} secondArray - The second array to compare.
   * @returns {boolean} Returns `true` if there is at least one common element, otherwise `false`.
   */
  hasCommonElements = (firstArray, secondArray) => {
    return firstArray.some((item) => secondArray.includes(item));
  };
}

export const defaultConfirmOption = (props = {
  customClass: {}
}) => ({
  imageUrl: infoIcon,
  reverseButtons: true,
  customClass: {
    actions: 'custom-actions-class',
    confirmButton: 'custom-confirm-button',
    cancelButton: 'custom-cancel-button',
    popup: 'custom-popup-class',
    ...props.customClass
  },
  onOpen: (popup) => {
    const buttonWidth = props && props.buttonWidth || 'auto';

    const actionsElement = popup.querySelector('.custom-actions-class');
    if (actionsElement) {
      actionsElement.style.justifyContent = 'flex-end';
    }

    const confirmButton = popup.querySelector('.custom-confirm-button');
    if (confirmButton) {
      confirmButton.style.padding = '6px 18px';
      confirmButton.style.width = buttonWidth;
      confirmButton.style.marginRight = '0';
      confirmButton.style.backgroundColor = 'var(--text-info-color)';
    }
    const cancelButton = popup.querySelector('.custom-cancel-button');
    if (cancelButton) {
      cancelButton.style.padding = '6px 18px';
      cancelButton.style.width = buttonWidth;
      cancelButton.style.marginRight = '0';
      cancelButton.style.color = 'var(--text-info-color)';
      cancelButton.style.backgroundColor = 'transparent';
    }
  },
});

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );
export const common = new Common();
export default common;

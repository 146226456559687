import React from 'react';
import { observer, inject } from 'mobx-react';
import { isEmpty } from 'lodash';
import Gallery from 'react-grid-gallery';
import Swal from 'sweetalert2';
import StarRatings from 'react-star-ratings';
import Loader from '../../components/common/Loader';
import EnTextLabel from '../../components/form/EnTextLabel';
import EnRadio from '../../components/form/EnRadio';
import EnButton from '../../components/form/EnButton';
import { History } from '../../utils/history';
import config from '../../config';
import '../../assets/css/homeowner-acceptance.css';
import TextArea from '../../componentsV2/input/textArea';

const STATUSES = {
  installation_accepted: {
    text: '&nbsp;&nbsp;ยอมรับผลการติดตั้ง',
    iconClass: 'fa fa-thumbs-o-up thumbs-up',
    result: 'accepted',
  },
  installation_rejected: {
    text: '&nbsp;&nbsp;ต้องการแก้ไขการติดตั้ง',
    iconClass: 'fa fa-thumbs-o-down thumbs-down',
    result: 'rejected',
  },
};

const statusOptions = [
  {
    label: 'ยอมรับการติดตั้ง',
    value: 'installation_accepted',
    icon: <i className="fa fa-thumbs-o-up fa-2x" aria-hidden="true" />,
    defaultClassName: 'btn-accept',
    selectedClassName: 'btn-accept-selected',
  },
  {
    label: 'ต้องการแก้ไข',
    value: 'installation_rejected',
    icon: <i className="fa fa-thumbs-o-down fa-2x" aria-hidden="true" />,
    defaultClassName: 'btn-reject',
    selectedClassName: 'btn-reject-selected',
  },
];

export class JobAcceptance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobId: this.props.match.params.jobId,
    };
  }

  async componentWillMount() {
    try {
      await this.props.homeowner.getJobForAcceptance(this.state.jobId);
      const { info, orderId } = this.props.homeowner.toJS();
      if (isEmpty(info) && orderId) {
        window.location.href = `${config.qchang.url}/tracking/${orderId}`;
      }
    } catch (error) {
      History.replace(`${config.publicUrl}/404`);
      // Swal.fire({
      //   icon: 'error',
      //   title: `${error.message}`,
      // });
    }
  }

  toImagesGallery(files) {
    if (!files || !files.length) {
      return [];
    }
    return files.map((f) => {
      return {
        src: f.url,
        thumbnail: f.url,
        thumbnailWidth: 320,
        thumbnailHeight: 175,
        caption: f.name,
      };
    });
  }

  validateForm({ status, rating, rejectMessage }) {
    if (!status) {
      throw new Error('กรุณาเลือก "ยอมรับการติดตั้ง" หรืิอ "ต้องการแก้ไข"');
    }
    if (status === 'installation_accepted' && !rating) {
      throw new Error('กรุณาให้คะแนนการบริการจากทีมช่าง');
    }
    if (status === 'installation_rejected' && !rejectMessage) {
      throw new Error('กรุณาระบุเหตุผลที่ต้องการแก้ไข');
    }
  }

  async onSubmitClick() {
    const { acceptance } = this.props.homeowner.toJS();
    const statusObj = STATUSES[acceptance.status];
    try {
      this.validateForm(acceptance);
      let confirmOptions = {
        icon: 'question',
        title: 'ยืนยันการส่งข้อมูล',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            await this.props.homeowner.sendAcceptanceData(this.state.jobId, acceptance);
            return true;
          } catch (err) {
            throw err;
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      };

      let result = await Swal.fire(confirmOptions);
      if (result.value) {
        Swal.fire({
          icon: 'success',
          title: `ส่งข้อมูลเรียบร้อยแล้ว`,
          confirmButtonText: 'ตกลง',
          preConfirm: async () => {
            this.props.history.push(`${config.publicUrl}/homeowner/job/${this.state.jobId}/result?r=${statusObj.result}`);
          },
          allowOutsideClick: () => false,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: `${err.message}`,
      });
    }
  }

  getDisplayGallery(images) {
    if (images && images.length) {
      return (
        <div>
          <Gallery
            images={images}
            showLightboxThumbnails />
        </div>
      );
    }
    return (
      <div>
        <em>
          ไม่มีรูปถ่าย
        </em>
      </div>
    );
  }

  getDisplayInstallationFinishedHistories(histories) {
    if (!histories || !histories.length) {
      return '';
    }

    const rows = [];
    for (let i = 0; i < histories.length; i++) {
      const images = this.toImagesGallery(histories[i].files);
      const displayGallery = this.getDisplayGallery(images);
      rows.push(
        <div key={`history-${i + 1}`}>
          <div className="row">
            <div className="col-md-12">
              <label>{`รูปภาพหลังติดตั้งครั้งที่ ${i + 1}`}</label>
              {displayGallery}
            </div>
          </div>
          <hr />
        </div>
      );
    }
    return rows;
  }

  onStatusChange(value) {
    this.props.homeowner.setAcceptanceInfo('status', value);
    window.scrollTo(0, document.body.scrollHeight);
  }

  onRatingChange(value) {
    this.props.homeowner.setAcceptanceInfo('rating', value);
  }

  onCommentChange(key, e) {
    e.preventDefault();
    this.props.homeowner.setAcceptanceInfo(key, e.target.value);
  }

  onAnonymousChange(value) {
    this.props.homeowner.setAcceptanceInfo('anonymous', value);
  }

  getRatingText(rating) {
    switch (rating) {
      case 1: return 'แย่มาก';
      case 2: return 'แย่';
      case 3: return 'พอใช้';
      case 4: return 'ดี';
      case 5: return 'ดีมาก';
      default: return 'ยังไม่ได้ให้คะแนน';
    }
  }

  getDisplayComment(key, acceptance) {
    return (
      <div className="row">
        <div className="col-md-12">
          <TextArea
            label="ความคิดเห็น"
            placeholder="ความคิดเห็น..."
            value={acceptance[key]}
            onChange={this.onCommentChange.bind(this, key)}
          />
        </div>
      </div>
    );
  }

  getDisplayAcceptForm(acceptance, channel, customerName) {
    let name = acceptance.anonymous ? `${customerName.substring(0, 1)}****` : customerName;

    return (
      <div>
        <div className="row">
          <div className="col-md-12 text-center">
            <h4>โปรดให้คะแนนการบริการจากทีมช่าง</h4>
            <StarRatings
              rating={acceptance.rating}
              starRatedColor="#f3873a"
              changeRating={this.onRatingChange.bind(this)}
              numberOfStars={5}
              name="rating"
              starDimension="35px"
            />
            <p>{this.getRatingText(acceptance.rating)}</p>
          </div>
        </div>
        {this.getDisplayComment('acceptMessage', acceptance)}
        {
          (channel !== 'SCGHOME Offline') && (
            <div className="row">
              <div className="col-md-12">
                <p>หมายเหตุ : ลูกค้าสามารถดาวน์โหลดใบรับประกันได้หลังจากกดยอมรับงานแล้ว</p>
              </div>
            </div>
          )
        }
        <div className="row" id="list-results">
          <div className="col-md-6 col-xs-3 text-center" >
            <div className="checkbox checkbox-circle checkbox-orange">
              <input
                id="anonymous" type="checkbox"
                checked={acceptance.anonymous}
                onChange={() => { this.onAnonymousChange(!acceptance.anonymous); }}
              />
              <label htmlFor="anonymous">ไม่ระบุตัวตน</label>
            </div>
          </div>
          {acceptance.anonymous && (
            <div className="col-md-6 col-xs-9 text-right" >
              <div className="customer-name">
                <label>ชื่อของคุณจะแสดงเป็น {name}</label>
              </div>
            </div>
          )}

        </div>
      </div>
    );
  }

  getDisplayRejectForm(acceptance) {
    return (
      <div>
        <div className="row">
          <div className="col-md-12- text-center">
            <h4>โปรดระบุเหตุผล</h4>
          </div>
        </div>
        {this.getDisplayComment('rejectMessage', acceptance)}
      </div>
    );
  }

  render() {
    const homeOwnerStore = this.props.homeowner.toJS();
    const { info, acceptance } = homeOwnerStore;
    const { status } = acceptance;
    const installationBeforeFiles = this.toImagesGallery(info.installationBeforeFiles);
    const installationBefore = this.getDisplayGallery(installationBeforeFiles);
    const displayInstFinishedHistories = this.getDisplayInstallationFinishedHistories(info.installationFinishedHistories);

    let form;
    if (status === 'installation_accepted') {
      form = this.getDisplayAcceptForm(acceptance, info.channel, info.customerName);
    } else if (acceptance.status === 'installation_rejected') {
      form = this.getDisplayRejectForm(acceptance);
    }

    return (
      <div className="content">
        <div className="container-fluid">
          <Loader show={homeOwnerStore.isLoading} />
          <div className="row">
            <div className="col-md-offset-2 col-md-8">
              <div className="card info">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">ตรวจรับงานติดตั้ง {info.typeOfWorkName}</h4>
                </div>
                <div className="card-content">
                  <div className="job-info">
                    <div className="title">
                      <p>รายละเอียดงานติดตั้ง</p>
                    </div>
                    <div className="row">
                      <EnTextLabel label="ชื่อลูกค้า" value={info.customerName} icon="fa fa-address-card-o" />
                    </div>
                    <div className="row">
                      <EnTextLabel label="วันที่ติดตั้ง" value={info.jobDate} icon="fa fa-calendar" />
                      <EnTextLabel label="ประเภทงาน" value={info.typeOfWorkName} icon="fa fa-cog" />
                    </div>
                    <div className="row">
                      <EnTextLabel label="ชื่อช่าง" value={info.contractorName} icon="fa fa-user" />
                      <EnTextLabel label="ชื่อทีมช่าง" value={info.contractorTeamName} icon="fa fa-users" />
                    </div>
                    <div className="row">
                      <EnTextLabel label="เบอร์ติดต่อ" value={info.contractorPhone} icon="fa fa-phone-square" />
                      <EnTextLabel label="อีเมล" value={info.contractorEmail} icon="fa fa-envelope" />
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <label>รูปภาพก่อนติดตั้ง</label>
                      {installationBefore}
                    </div>
                  </div>
                  <hr />
                  {displayInstFinishedHistories}
                  <div className="row">
                    {
                      // <div className="col-md-12 text-center button-wrapper">
                      //   <a id="accept-button" className="status-button" onClick={this.onAccept.bind(this)}>
                      //     <i className="fa fa-thumbs-o-up fa-2x" aria-hidden="true" /> ยอมรับ
                      //   </a>
                      //   <a id="reject-button" className="status-button" onClick={this.onReject.bind(this)}>
                      //     <i className="fa fa-thumbs-o-down fa-2x" aria-hidden="true" /> ต้องการแก้ไข
                      //   </a>
                      // </div>
                      <EnRadio
                        containerClassName="col-md-12 text-center"
                        label={''}
                        value={acceptance.status}
                        options={statusOptions}
                        onClick={this.onStatusChange.bind(this)}
                      />
                    }
                  </div>
                  {form}
                  {(status === 'installation_accepted' || status === 'installation_rejected') && (
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <EnButton
                          id="submit-button"
                          className="btn-success"
                          onClick={this.onSubmitClick.bind(this)}>
                          <i className="fa fa-share-square" aria-hidden="true" />&nbsp;&nbsp;ส่งข้อมูล
                        </EnButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('job', 'homeowner')(observer(JobAcceptance));

import React, { Component } from 'react';
import moment from 'moment';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import Picker from 'react-month-picker';
// import Draggable from 'react-draggable';
import DateUtils, { thaiDate } from '../../utils/datetime';
import Button from '../form/EnButton';

const localizer = momentLocalizer(moment);

export class MonthPicker extends Picker {
  optionPad(padIndex) {
    let values = this.state.values;
    let value = values[padIndex];
    let labelYears = this.state.labelYears;
    let labelYear = labelYears[padIndex] = labelYears[padIndex] || value.year;
    let ymArr = this.state.years;
    let lang = this.props.lang || [];
    let months = Array.isArray(lang) ? lang : (Array.isArray(lang.months) ? lang.months : []);
    let prevCss = '';
    let nextCss = '';
    let yearMaxIdx = ymArr.length - 1;
    let yearIdx = this.state.yearIndexes[padIndex];

    if (yearIdx === 0) prevCss = 'disable';
    if (yearIdx === yearMaxIdx) nextCss = 'disable';

    let yearActive = (labelYear === value.year);
    let atMinYear = (labelYear === ymArr[0].year);
    let atMaxYear = (labelYear === ymArr[yearMaxIdx].year);
    let otherValue = false;
    if (values.length > 1) {
      otherValue = values[1 - padIndex];
    }

    let labelTextKey = padIndex === 0 ? 'from' : 'to';
    let labelPreText;
    if (otherValue && this.props.lang[labelTextKey]) {
      labelPreText = <b>{this.props.lang[labelTextKey]}</b>;
    }

    let yearRender = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    return (
      <div className="rmp-pad" key={padIndex}>
        <div>
          <label>{labelPreText}{(+labelYear) + 543}</label>
          <i className={['rmp-tab', 'rmp-btn', 'prev', prevCss].join(' ')} data-id={padIndex} onClick={this.goPrevYear}>{'<'}</i>
          <i className={['rmp-tab', 'rmp-btn', 'next', nextCss].join(' ')} data-id={padIndex} onClick={this.goNextYear}>{'>'}</i>
        </div>
        <ul>
          {
            yearRender.map(i => {
              let css = '';
              let m = i + 1;
              if (yearActive && m === value.month) {
                css = 'active';
              }
              if (atMinYear && m < ymArr[0].min) {
                css = 'disable';
              }
              if (atMaxYear && m > ymArr[yearMaxIdx].max) {
                css = 'disable';
              }
              if (otherValue) {
                let y = otherValue.year;
                let m = otherValue.month || 0;
                let vy = labelYear;
                let vm = i + 1;
                if (y === vy && m && ((padIndex === 0 && vm > m) || (padIndex === 1 && vm < m))) {
                  css = 'disable';
                } else if ((y > vy && padIndex === 1) || (y < vy && padIndex === 0)) {
                  css = 'disable';
                }
              }
              let clickHandler = css !== 'disable' ? this.handleClickMonth : undefined;
              return (
                <li key={i} className={['rmp-btn', css].join(' ')}
                  data-id={padIndex + ':' + (i + 1)}
                  onClick={clickHandler}>{months.length > i ? months[i] : i}</li>
              );
            })
          }
        </ul>
      </div>
    );
  }
}

export class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultView: 'month',
      showMonthPicker: false,
    };
  }

  onViewChange(view) {
    this.setState({
      defaultView: view,
    });
  }

  onMonthChange(...e) {
    if (this.props.onMonthChange) {
      this.props.onMonthChange(e[0]);
    }
  }

  /**
   * If `getEventHref` is provided, the `handleSelectEvent` will not be executed.
   */
  handleSelectEvent = (event) => {
    const { getEventHref, onSelectEvent } = this.props;
    if (!getEventHref && onSelectEvent) {
      onSelectEvent(event);
    }
  }

  onSelectSlot(slotInfo) {
    if (slotInfo && slotInfo.start && slotInfo.end) {
      if (this.props.onSelectSlot) {
        this.props.onSelectSlot(slotInfo);
      }
    }
  }

  eventPropGetter(event, start, end, isSelected) {
    if (this.props.eventWrapper) {
      return this.props.eventWrapper(event, start, end, isSelected);
    } else {
      return {};
    }
  }

  handleClickMonthPicker(toggle) {
    this.setState({ showMonthPicker: toggle });
  }

  /**
   * Retrieves the href for an event that, when clicked, opens a link in a new tab.
   * The href is obtained from the `getEventHref` prop, which should be a function.
   */
  getEventHref = (event) => {
    const { getEventHref } = this.props;
    if (getEventHref) {
      return getEventHref(event);
    }
    return undefined;
  }

  render() {
    let formats = {
      monthHeaderFormat: (date, culture, localizer) => {
        return DateUtils.ConvertToBuddhistYear(moment(date), 'MMMM ');
      },
    };
    let currentYear = moment().year();
    let pickerOptions = {
      months: thaiDate.monthsShort,
      years: [-2, -1, 0, 1, 2].map(i => currentYear + i),
    };
    let componentOption = {
      components: {
        eventWrapper: ({ children, event }) => {
          return <a href={this.getEventHref(event)} target="_blank">{children}</a>
        },
        toolbar: (toolbar) => {
          const btnStyle = {};

          const goToBack = () => {
            toolbar.date.setMonth(toolbar.date.getMonth() - 1);
            if (this.props.customBack) {
              this.props.customBack(toolbar.date);
            }
            toolbar.onNavigate('prev');
          };

          const goToNext = () => {
            toolbar.date.setMonth(toolbar.date.getMonth() + 1);
            if (this.props.customNext) {
              this.props.customNext(toolbar.date);
            }
            toolbar.onNavigate('next');
          };

          const goToCurrent = () => {
            const now = new Date();
            toolbar.date.setMonth(now.getMonth());
            toolbar.date.setYear(now.getFullYear());
            if (this.props.customCurrent) {
              this.props.customCurrent();
            }
            toolbar.onNavigate('current');
          };

          const goToMonth = (y, m) => {
            toolbar.date.setMonth(m - 1);
            toolbar.date.setYear(y);
            if (this.props.customMoveMonth) {
              this.props.customMoveMonth(toolbar.date);
            }
            this.handleClickMonthPicker(false);
            toolbar.onNavigate();
          };

          return (
            <div className="">
              <div className="row">
                <div className="col-md-5 p-left">
                  <Button style={btnStyle} className="btn-info" onClick={goToCurrent}>วันที่ปัจจุบัน</Button>
                </div>
                <div className="col-md-7 p-right">
                  <div className="calendar-action">
                    <Button className="btn-info" onClick={goToBack}><i style={{ fontSize: 16 }} className="fa fa-caret-left" /></Button>
                    <Button className="btn-info" onClick={this.handleClickMonthPicker.bind(this, true)}><i style={{ fontSize: 16 }} />{toolbar.label}</Button>
                    <Button className="btn-info" onClick={goToNext}><i style={{ fontSize: 16 }} className="fa fa-caret-right" /></Button>
                  </div>
                  <div className="calendar-action-mobile">
                    <div className="row">
                      <Button className="btn-info" onClick={this.handleClickMonthPicker.bind(this, true)}><i style={{ fontSize: 16 }} />{toolbar.label}</Button>
                    </div>
                    <div className="r">
                      <Button className="btn-info" onClick={goToBack}><i style={{ fontSize: 16 }} className="fa fa-caret-left" /></Button>
                      <Button className="btn-info" onClick={goToNext}><i style={{ fontSize: 16 }} className="fa fa-caret-right" /></Button>
                    </div>
                  </div>
                  <div className="p4-calendar">
                    <MonthPicker
                      value={toolbar.date}
                      lang={pickerOptions.months}
                      years={pickerOptions.years}
                      onChange={goToMonth}
                      onDismiss={this.handleClickMonthPicker.bind(this, false)}
                      show={this.state.showMonthPicker} />
                  </div>
                </div>
              </div>
            </div >
          );
        },
      },
    };

    if (this.props.dateHeader) {
      componentOption.components['month'] = {
        dateHeader: (obj) => {
          return this.props.dateHeader(obj);
        },
      };
    }

    if (this.props.eventView) {
      if (componentOption.components.month) {
        componentOption.components.month['event'] = (obj) => {
          return this.props.eventView(obj);
        };
      } else {
        componentOption.components['month'] = {
          event: (obj) => {
            return this.props.eventView(obj);
          },
        };
      }
    }

    if (this.props.cellWrapper) {
      componentOption.components['dateCellWrapper'] = (obj) => {
        return this.props.cellWrapper(obj);
      };
    }

    if (this.props.date) {
      componentOption['date'] = this.props.date;
    }

    let view = this.state.defaultView;
    let selectable = view === 'month';

    return (
      <BigCalendar
        localizer={localizer}
        {...componentOption}
        className="p4-calendar"
        selectable={selectable}
        events={this.props.events}
        defaultView={view}
        messages={
          {
            showMore: (number) => {
              return `+${number} เพิ่มเติม`;
            },
          }
        }
        // , 'week', 'day'
        views={['month']}
        defaultDate={new Date()}
        formats={formats}
        popup
        onSelectEvent={this.handleSelectEvent}
        onSelectSlot={this.onSelectSlot.bind(this)}
        onNavigate={this.onMonthChange.bind(this)}
        eventPropGetter={this.eventPropGetter.bind(this)}
        endAccessor={obj => {
          if (this.props.endAccessor) {
            return this.props.endAccessor(obj);
          } else {
            return obj.end;
          }
        }}
        onView={this.onViewChange.bind(this)}
      />
    );
  }
}

export default Calendar;

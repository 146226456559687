import * as _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { FormValidation, required } from '../../../components/form/FormValidation';
import FormGroup from '../../../components/form/FormGroup';
import EnButton from '../../../components/form/EnButton';
import EnNumeric from '../../../components/form/EnNumeric';
import EnDropDown from '../../../components/form/EnDropDown';
import { EnTagSelectValidation } from '../../../components/form/EnTagSelect';
import EnStickyFooter from '../../../components/form/EnStickyFooter';
import config from '../../../config';
import { toast } from 'react-toastify';

export class WorkArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      step: 3,
      stepName: 'ระบุรายละเอียดการรับงาน',
    };
  }

  saveTimesOfWork(val) {
    this.props.contractor.saveInfo('timesOfWork', val);
  }

  // clickNext() {
  //   this.props.contractor.saveInfo('areaOfWork', this.state.items);
  //   this.props.history.push(`${config.publicUrl}/contractors/register/pin`);
  // }

  async clickNext() {
    try {
      this.props.contractor.validateInput(this, this.validation())
        .then(() => {
          this.props.contractor.saveInfo('areaOfWork', this.state.items);
          this.props.history.push(`${config.publicUrl}/contractors/register/pin`);
        })
        .catch((err) => {
          toast.error(err.message || 'เกิดข้อผิดพลาด');
        });
    } catch (err) {
      toast.error(err.message || 'เกิดข้อผิดพลาด');
    }
  }

  validation() {
    let check = this.state.items.map(d => {
      return !(_.indexOf(d.districtCode, '') >= 0 || (_.isEmpty(d.districtCode)));
    });
    let isValid = (_.indexOf(check, false)) < 0;
    return this.props.contractor.isValidRegisterInfo(3) && !!isValid;
  }

  async componentWillMount() {
    this.saveTimesOfWork(1);

    await this.props.misc.getAllProvince();
    let district = await this.props.misc.getDistrictByCode('10');
    this.setState({
      items: [
        {
          key: (new Date()).getTime(),
          provinceCode: '10',
          districtCode: [],
          districtMaster: district,
        },
      ],
    });
  }

  async onProvinceChanged(selectedItem, e) {
    let id = e.target.value;
    let items = _.cloneDeep(this.state.items);
    let dis = await this.props.misc.getDistrictByCode(id);
    let item = items.find(i => i.key === selectedItem.key);
    if (!_.isNil(item) && !_.isEmpty(item)) {
      item.provinceCode = id;
      item.districtCode = [];
      item.districtMaster = dis;
    }
    this.setState({
      items,
    });
  }

  onDistrictChanged(selectedItem, districtCode, e) {
    let items = _.cloneDeep(this.state.items);
    if (e.indexOf('ALL') >= 0) {
      let district = districtCode.map(d => d.value);
      let item = items.find(i => i.key === selectedItem.key);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item.districtCode = district;
      }
    } else {
      let district = e.split(',');
      let item = items.find(i => i.key === selectedItem.key);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item.districtCode = district;
      }
    }
    this.setState({
      items,
    });
  }

  getNotSelectedList(selectedId) {
    let miscStore = this.props.misc.toJS();
    let province = miscStore.miscData.province;
    return province.filter(c => {
      if (selectedId && c.code === selectedId) {
        return true;
      } else {
        let item = this.state.items.find(i => i.provinceCode === c.code);
        return _.isNil(item) || _.isEmpty(item);
      }
    });
  }

  async addNewItem() {
    let items = _.cloneDeep(this.state.items);
    if (this.getNotSelectedList().length > items.length) {
      items = [{
        key: (new Date()).getTime(),
        provinceCode: this.getNotSelectedList()[0].code,
        districtCode: [],
        districtMaster: await this.props.misc.getDistrictByCode(this.getNotSelectedList()[0].code),
      }, ...items];
      this.setState({
        items,
      });
    }
  }

  deleteItems(item, e) {
    let data = this.state.items.filter(d => d.key !== item.key);
    this.setState({
      items: data,
    });
  }

  showItem() {
    let items = (this.state.items || []).map((item, index) => {
      let provinceOptions = this.getNotSelectedList(item.provinceCode).map((c, index) => {
        return <option key={index} value={c.code}>{c.name}</option>;
      });

      let districtOptions = [];
      let districtCode = [];
      if (item.provinceCode) {
        districtOptions = item.districtMaster.map(d => {
          return ({
            label: d.name,
            value: d.code,
          });
        });
      }
      districtCode = districtOptions;
      if (item.districtMaster.length !== item.districtCode.length) {
        districtOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...districtOptions];
      }

      return (
        <div key={`item-${index}`} className="card item-card">
          <div className="card-content">
            <FormGroup label="จังหวัด">
              <EnDropDown value={item.provinceCode} onChange={this.onProvinceChanged.bind(this, item)}>
                {provinceOptions}
              </EnDropDown>
            </FormGroup>
            <FormGroup label="อำเภอ" id={`amphor_${index}`}>
              <EnTagSelectValidation
                closeOnSelect={false}
                options={districtOptions}
                onTagChange={this.onDistrictChanged.bind(this, item, districtCode)}
                value={item.districtCode}
                searchable={false}
                validations={[required]}
              />
            </FormGroup>
            {
              this.state.items.length > 1 &&
              <EnButton className="btn-danger pull-right" onClick={this.deleteItems.bind(this, item)}>
                <i className="fa fa-trash btn-icon" aria-hidden="true" />ลบ
              </EnButton>
            }
          </div>
        </div>
      );
    });
    return items;
  }

  saveInfo(key, val) {
    this.props.contractor.saveInfo(key, val);
  }

  render() {
    let showAddButton = '';
    if (this.state.items.length) {
      showAddButton = this.state.items[0].districtCode;
    }
    let contractor = this.props.contractor.toJS().info;
    const Button = () => <EnButton className="btn-info pull-right" onClick={this.clickNext.bind(this)}><i className="fa fa-arrow-right btn-icon" aria-hidden="true" />ถัดไป</EnButton>;
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-offset-2 col-md-8">
              <div className="card">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">ลงทะเบียน</h4>
                  <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
                </div>
                <div className="card-content">
                  <FormValidation ref="FormValidation">
                    {/* <div className="col-md-12" />
                    <FormGroup containerClassName="p4-font-input time-work col-md-6" label="ท่านสามารถรับงานสำรวจได้กี่งานต่อวัน">
                      <EnNumeric
                        min={1}
                        onChange={this.saveInfo.bind(this, 'surveyPerDay')}
                        value={contractor.surveyPerDay || 1} />
                    </FormGroup>
                    <FormGroup containerClassName="p4-font-input time-work col-md-6" label="ท่านสามารถรับงานติดตั้งได้กี่งานต่อวัน">
                      <EnNumeric
                        min={1}
                        onChange={this.saveInfo.bind(this, 'installationPerDay')}
                        value={contractor.installationPerDay || 1} />
                    </FormGroup> */}
                    <FormGroup containerClassName="p4-font-input time-work col-md-12" label="ท่านสามารถรับงานสำรวจได้กี่งานต่อวัน">
                      <div className="col-md-12" >
                        <div className="col-md-6" >
                          <center> <h7>ช่วงเช้า</h7></center>
                          <EnNumeric
                            min={1}
                            onChange={this.saveInfo.bind(this, 'morningSurveyPerDay')}
                            value={contractor.surveyPerDay.morning} />
                        </div>
                        <div className="col-md-6" >
                          <center> <h7>ช่วงบ่าย</h7></center>
                          <EnNumeric
                            min={1}
                            onChange={this.saveInfo.bind(this, 'afternoonSurveyPerDay')}
                            value={contractor.surveyPerDay.afternoon} />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup containerClassName="p4-font-input time-work col-md-12" label="ท่านสามารถรับงานติดตั้งได้กี่งานต่อวัน">
                      <div className="col-md-12" >
                        <div className="col-md-6" >
                          <center> <h7>ช่วงเช้า</h7></center>
                          <EnNumeric
                            min={1}
                            onChange={this.saveInfo.bind(this, 'morningInstallationPerDay')}
                            value={contractor.installationPerDay.morning} />
                        </div>
                        <div className="col-md-6" >
                          <center> <h7>ช่วงบ่าย</h7></center>
                          <EnNumeric
                            min={1}
                            onChange={this.saveInfo.bind(this, 'afternoonInstallationPerDay')}
                            value={contractor.installationPerDay.afternoon} />
                        </div>
                      </div>
                    </FormGroup>
                    <div className="col-md-12">
                      <span className="form-label">กรุณาระบุพื้นที่ที่ท่านสามารถรับงานได้</span>
                      <div className="card-content">
                        {
                          showAddButton.length > 0 && <EnButton className="btn btn-success pull-right" onClick={this.addNewItem.bind(this)}>
                            <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่มพื้นที่รับงาน
                          </EnButton>
                        }
                        {this.showItem()}
                      </div>
                    </div>

                    <EnStickyFooter step={this.state.step} stepName={this.state.stepName} button={<Button />} />
                  </FormValidation>
                </div>
              </div>
            </div>
          </div >
        </div>
      </div >
    );
  }
}

export default inject('contractor', 'misc')(observer(WorkArea));

import { Alert, AlertTitle, Snackbar } from '@mui/material';
import React, { Component } from 'react';

export class Notification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: '',
      title: '',
      severity: 'info',
    };
  }

  clear = () => {
    this.setState({ open: false });
  };

  showNotification = (message, title, severity) => {
    this.setState({
      open: true,
      message,
      title,
      severity,
    });
  };

  info = (message, title) => this.showNotification(message, title || 'Information', 'info');

  success = (message, title) => this.showNotification(message, title || 'Success', 'success');

  warning = (message, title) => this.showNotification(message, title || 'Warning', 'warning');

  error = (message, title) => this.showNotification(message, title || 'Error', 'error');

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.clear();
  };

  render() {
    const { open, message, severity, title } = this.state;

    return (
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={this.handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          minWidth: '400px',
          padding: '20px',
          borderRadius: '8px',
          '& .MuiAlert-icon': {
            marginRight: '12px',
            fontSize: '3rem',
            position: 'relative',
            top: '3px',
          },
        }}
      >
        <Alert
          onClose={this.handleClose} 
          severity={severity}
          sx={{ 
            width: '100%',
            fontFamily: '"DB Helvethaica X", sans-serif',
            fontSize: '3rem',
            fontWeight: '400',
          }}
        >
          <AlertTitle
            sx={{
              fontFamily: '"DB Helvethaica X", sans-serif',
              fontSize: '3rem',
              fontWeight: '600',
            }}
          >
            {title}
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    );
  }
}

export default Notification;

import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import EnToggle from '../../../components/form/EnToggle';
import config from '../../../config';
import { isAllow } from '../../../utils/permission';
import { toast } from 'react-toastify';

export class ManageSolution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onAddNew() {
    this.props.history.push(`${config.publicUrl}/qchang/solution/add`);
  }

  onEdit(id) {
    if (id) {
      this.props.history.push(`${config.publicUrl}/qchang/solution/${id}/edit`);
    }
  }

  onGoToProject(solutionId) {
    this.props.history.push(`${config.publicUrl}/qchang/project?solution_id=${solutionId}`);
  }

  onPublishToggle(index, id, value) {
    this.props.solution.togglePublish(index, id, value ? true : false);
  }

  async componentDidMount() {
    try {
      if (!isAllow('VIEW_A_LIST_OF_SOLUTIONS')) {
        return;
      }
      await this.props.solution.getSolutionList();
    } catch (err) {
      toast.error(err.message || 'เกิดข้อผิดพลาด');
    }
  }

  getTableRows(items) {
    return items.map((item, index) => {
      return (
        <tr key={item._id}>
          <td className="text-center">{index + 1}</td>
          <td>{item.name ? item.name.th : ''}</td>
          <td className="text-center"><img src={item.image} style={{width: '30%'}} alt="" /></td>
          <td className="text-center">
            <EnToggle
              checked={item.publish === true}
              onChange={this.onPublishToggle.bind(this, index, item._id)}
              disabled={!isAllow('EDIT_SOLUTIONS')} />
          </td>
          <td className="text-center">
            <button 
              className="btn btn-xs"
              title="แก้ไข"
              data-tip="แก้ไข"
              onClick={this.onEdit.bind(this, item._id)}
              disabled={!isAllow('EDIT_SOLUTIONS')}
              >
              <span className="fa fa-pencil" aria-hidden="true" />
            </button>
            <button
              className="btn btn-xs" 
              title="ผลงาน"
              data-tip="ผลงาน" 
              onClick={this.onGoToProject.bind(this, item._id)}
              disabled={!isAllow('VIEW_A_LIST_OF_PORTFOLIO_SOTLUIONS')}
              >
              <span className="fa fa-braille" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { items, isLoading } = this.props.solution.toJS();
    const rows = this.getTableRows(items);

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายการโซลูชั่น</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-bordered table-hover has-pagination">
                      <thead>
                        <tr>
                          <th width="10%" className="text-center">ลำดับที่</th>
                          <th width="35%" className="text-center">ชื่อ</th>
                          <th width="30%" className="text-center">รูปภาพ</th>
                          <th width="15%" className="text-center">แสดงบนเว็บคิวช่าง</th>
                          <th width="10%" className="text-center">
                            <button 
                              className="btn btn-success"
                              title="สร้างใหม่"
                              data-tip="สร้างใหม่"
                              onClick={this.onAddNew.bind(this)}
                              disabled={!isAllow('CREATE_SOLUTIONS')}
                            >
                              <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('solution', 'product')(observer(ManageSolution));

import React from 'react';
import swal from 'sweetalert2';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import { FormValidation, required } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnButton from '../../../components/form/EnButton';
import EnDropDown from '../../../components/form/EnDropDown';
import { config } from '../../../config';
import StarRatings from 'react-star-ratings';

export class AddReview extends React.Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    this.state = {
      id: id,
      isEdit: id && id.length,
      files: [],
      rating: 1,
    };
  }

  async componentWillMount() {
    this.props.typeofwork.getTypeOfWorks();
  }

  saveInfo = (key, e) => {
    if (e.target.value) {
      this.props.review.saveInfo(key, e.target.value);
    }
  }

  isValid() {
    const review = this.props.review.toJS();
    const info = review.info;
    const isValidName = !_.isEmpty(info.name);
    const isValidLastName = !_.isEmpty(info.lastname);
    const isValidMessage = !_.isEmpty(info.message);
    const isValidTypeOfWork = +info.typeOfWork !== 0;
    return isValidName && isValidTypeOfWork && isValidLastName && isValidMessage;
  }

  async onSubmit() {
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขสินค้า' : 'กรุณายืนยันการเพิ่มสินค้าใหม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.uploadAndSubmit();
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      this.props.product.clearInfo();

      setTimeout(() => {
        this.onCancel();
      }, 500);
    }
  }

  onCancel() {
    this.props.history.push(`${config.publicUrl}/qchang/product`);
  }

  async uploadAndSubmit() {
    if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
      return this.doUploadFiles(this.dropzone);
    } else {
      return this.submitForm();
    }
  }

  async submitForm() {
    try {
      if (this.state.isEdit) {
        return this.props.product.updateProduct(this.state.id);
      } else {
        return this.props.product.createProduct();
      }
    } catch (error) {
      throw error;
    }
  }

  displayTypeOfWorkSelector() {
    const defaultOpt = [
      <option key={0} value={0}>กรุณาเลือกประเภทสินค้า</option>,
    ];

    let typeOfWorkOpts = [];
    const typeOfWorks = this.props.typeofwork.mapIdAndLabel();
    if (Array.isArray(typeOfWorks) && typeOfWorks.length) {
      typeOfWorkOpts = typeOfWorks.map((item, idx) => {
        return <option key={++idx} value={item.id}>{item.label}</option>;
      });
    }
    return defaultOpt.concat(typeOfWorkOpts);
  }

  displayInstallmentPeriodSelector() {
    const monthOpts = [
      <option key={0} value={0}>กรุณาเลือกจำนวนเดือนสูงสุด</option>,
      <option key={3} value={3}>3 เดือน</option>,
      <option key={4} value={4}>4 เดือน</option>,
      <option key={6} value={6}>6 เดือน</option>,
      <option key={10} value={10}>10 เดือน</option>,
    ];
    return monthOpts;
  }

  changeRating(newRating, name) {
    this.setState({
      rating: newRating,
    });
  }

  render() {
    const review = this.props.review.toJS();
    const item = review.info;
    const formTitle = this.state.isEdit ? 'แก้ไขสินค้า' : 'เพิ่มความคิดเห็น';
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{formTitle}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <div className="card-content">
                  <FormValidation>
                    <div className="row">
                      <EnTextFormGroup
                        containerClassName="col-md-6"
                        type="text"
                        label="ชื่อลูกค้า"
                        value={item.name}
                        onChange={this.saveInfo.bind(this, 'name')}
                        validations={[required]} />
                      <EnTextFormGroup
                        containerClassName="col-md-6"
                        type="text"
                        label="นามสกุล"
                        value={item.lastname}
                        onChange={this.saveInfo.bind(this, 'lastname')}
                        validations={[required]} />
                    </div>
                    <div className="row">
                      <FormGroup containerClassName={`col-md-6`} label="ประเภทงาน" validations={[required]}>
                        <EnDropDown id="type-work" value={item.typeOfWork} onChange={this.saveInfo.bind(this, 'typeOfWork')}>
                          {this.displayTypeOfWorkSelector()}
                        </EnDropDown>
                      </FormGroup>
                      <FormGroup containerClassName={`col-md-6`} label="ประเภทสินค้า" validations={[required]}>
                        <EnDropDown id="type-product" value={item.typeOfJob} onChange={this.saveInfo.bind(this, 'typeOfJob')}>
                          <option value={'S'}>สำรวจ</option>
                          <option value={'I'}>ติดตั้ง</option>
                        </EnDropDown>
                      </FormGroup>
                    </div>
                    <div className="row">
                      <div class="form-group col-md-6">
                        <label class=""> คะแนนรีวิว </label> &nbsp;&nbsp;<br />
                        <StarRatings
                          rating={this.state.rating}
                          starRatedColor="#f3873a"
                          changeRating={this.changeRating.bind(this)}
                          numberOfStars={5}
                          name="rating"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <EnTextFormGroup
                        containerClassName="col-md-12"
                        type="text"
                        label="ความคิดเห็น"
                        value={item.message}
                        onChange={this.saveInfo.bind(this, 'message')}
                        validations={[required]} />
                    </div>
                    <div className="row">
                      <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={this.onSubmit.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                        <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                      </EnButton>
                    </div>
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'typeofwork', 'review', 'typeofwork')(observer(AddReview));

import auth from './AuthStore';
import misc from './MiscStore';
import contractor from './ContractorStore';
import subContractor from './SubContractorStore';
import benefit from './BenefitStore';

import calendar from './CalendarStore';
import job from './JobStore';
import sku from './SkuStore';
import material from './materialStore';
import admin from './AdminStore';
import exportdata from './ExportStore';
import typeofwork from './TypeOfWorkStore';
import homeowner from './HomeOwnerStore';
import reviewsku from './ReviewStore';
import quotation from './QuotationStore';
import invoice from './InvoiceStore';
import claim from './ClaimStore';
import creditProvider from './CreditProviderStore';
import joblist from './JobListStore';
import contractorlist from './ContractorListStore';
import contractorblockedpayment from './ContractorBlockedPaymentStore';
import jobnote from './JobNoteStore';
import po from './PurchaseOrderStore';
import contact from './ContactStore';
import ordersummary from './OrderSummaryStore';
import ordersummarySCB from './OrderSummarySCBStore';
import bank from './BankStore';
import channel from './ChannelStore';
import model from './ModelStore';
import download from './DownloadStore';
import nps from './NpsStore';
import employee from './EmployeeStore';
import broadcasts from './BroadcastStore';
import additionalProducts from './AdditionalProducts';
import modal from './ModalStore';

import product from './qchang/ProductStore';
import productgroup from './qchang/ProductgroupStore';
import review from './qchang/ReviewStore';
import solution from './qchang/SolutionStore';
import coupon from './qchang/CouponStore';
import coupongroup from './qchang/CouponGroupStore';
import project from './qchang/ProjectStore';
import projectconfig from './qchang/ProjectConfigStore';
import promotionconfig from './qchang/PromotionStore';
import guarantee from './GuaranteeStore';
import transfer from './TransferStore';
import transfersummary from './TransferSummaryStore';
import contractorgrade from './ContractorGradeStore';
import transferdetail from './TransferDetailStore';
import trainingcourse from './qchang/TrainingCourseStore';

import setupCalendarStore from './SetupCalendarStore'
import permission from './qchang/PermissionStore'
import customer from './CustomerStore';
import otherExpense from './OtherExpenseStore';
import upload from './UploadStore';
import shirt from './ShirtStore';


export default {
  auth,
  misc,
  contractor,
  subContractor,
  benefit,
  calendar,
  job,
  sku,
  material,
  admin,
  exportdata,
  typeofwork,
  homeowner,
  product,
  productgroup,
  review,
  reviewsku,
  solution,
  coupon,
  coupongroup,
  quotation,
  invoice,
  claim,
  creditProvider,
  po,
  joblist,
  contractorlist,
  jobnote,
  contact,
  ordersummary,
  ordersummarySCB,
  bank,
  shirt,
  project,
  projectconfig,
  promotionconfig,
  guarantee,
  transfer,
  transfersummary,
  contractorgrade,
  transferdetail,
  channel,
  model,
  trainingcourse,
  download,
  nps,
  employee,
  broadcasts,
  setupCalendarStore,
  permission,
  customer,
  contractorblockedpayment,
  otherExpense,
  upload,
  additionalProducts,
  modal
};

import React from 'react';
import { Fragment } from 'react'
import * as _ from 'lodash';
import swal from 'sweetalert2';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import JobSearchForm from './components/JobSearchForm'
import PromotionSearchForm from './components/PromotionSearchForm'
import EnButton from '../../../components/form/EnButton'
import EnDatePicker from '../../../components/form/EnDatePicker';
import EnTagSelect from '../../../components/form/EnTagSelect';
import { isAllow } from '../../../utils/permission';


export class ManageDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadType: this.props.match.params.type,
      storeId: this.props.match.params.storeid,
      channel: this.props.match.params.channel,
      buStoreId: this.props.match.params.storeid,
      isTranferMC: false,
      isChannelLoading: false,
      saleModelState: [
        { label: 'Retail', value: 'Retail' },
        { label: 'MarketPlace', value: 'MarketPlace' }
      ]
    };
    this.saleModelOptions = [
      { label: 'Retail', value: 'Retail' },
      { label: 'MarketPlace', value: 'MarketPlace' }
    ]
    this.channelPayment = [
      { label: 'Credit and debit cards', value: 'Credit and debit cards' },
      { label: 'QR Gateway -Thai QR', value: 'QR Gateway -Thai QR' },
      { label: 'Cash payment channel', value: 'Cash payment channel' },
      { label: 'Mobile Banking/Internet Banking', value: 'Mobile Banking/Internet Banking' },
      { label: 'Bank Counter', value: 'Bank Counter' },
      { label: 'others', value: 'others' },
    ]
  }
  async componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate() {
    if (this.props.match.params.type !== this.state.downloadType) {
      this.setState({ downloadType: this.props.match.params.type })
    }
  }

  async fetchData() {
    const { channel, downloadType, storeId, buStoreId } = this.state;
    const isBuAccount = this.props.auth.isBuAccount();
    switch (downloadType) {
      case 'job':
        if (!isAllow('VIEW_CONTRACTORS_SCHEDULES_LISTS')) {
          break;
        }
        await this.props.download.getDownloadFiles(downloadType);
        break;
      case 'jobbychannel':
        if (isBuAccount) {
          await this.props.download.getDownloadFiles(downloadType, channel, '', buStoreId);
        } else {
          await this.props.download.getDownloadFiles(downloadType, channel, storeId);
        }
        break;
      case 'contractor':
        if (!isAllow('VIEW_CONTRACTORS_FILE_LISTS')) {
          break;
        }
        await this.props.download.getDownloadFiles(downloadType);
        break;
      case 'vacation':
        if (!isAllow('VIEW_CONTRACTORS_VACATION_FILE_LISTS')) {
          break;
        }
        await this.props.download.getDownloadFiles(downloadType);
        break;
      case 'transfer':
        if (!isAllow('VIEW_UPLOAD_PAYMENT_LISTS')) {
          break;
        }
        await this.props.download.getDownloadFiles(downloadType);
        break;
      case 'vaccines':
        if (!isAllow('VIEW_CONTRACTORS_PROOF_OF_VACCINATION_LISTS')) {
          break;
        }
        await this.props.download.getDownloadFiles(downloadType);
        break;
      case 'other_expense':
        if (!isAllow('VIEW_UPLOAD_PAYMENT_LISTS')) {
          break;
        }
        await this.props.download.getDownloadFiles(downloadType);
        break;
      case 'promotions':
        await this.props.download.getDownloadFiles(downloadType);
        break;
      case 'retail_cash':
        await this.props.download.getDownloadFiles(downloadType);
        break;
      default:
        await this.props.download.getDownloadFiles();
        break;
    }
  }

  onQueryChange(key, value) {
    this.props.download.setQuery(key, value);
  }

  async onDownload(query = {}) {
    try {
      const options = this.props.download.prepareOptions(query);
      const isBuAccount = this.props.auth.isBuAccount();
      options.download_type = this.state.downloadType;
      options.channel = this.state.channel;
      options.store_id = this.state.storeId;
      options.isTranferMC = this.state.isTranferMC;
      if (isBuAccount) {
        options.bu_store_id = this.state.buStoreId;
      }
      if (this.state.downloadType === "promotions") {
        options.end_date = moment(options.end_date).endOf('month').format("YYYY-MM-DD")
      }

      let result = await this.props.download.createDownloadFiles(options);
      if (result) {
        swal.fire({
          title: 'ระบบกำลังดาวน์โหลดข้อมูล',
          icon: 'success',
        });
        this.fetchData();
      }
    } catch (error) {
      swal.fire({
        icon: 'error',
        title: error.message,
        confirmButtonText: 'ตกลง',
      });
    }
  }

  onClearSearch() {
    this.props.download.setDefaultQuery();
  }

  getTableRows(items) {
    return items.map((item, index) => {
      let status = <div />;
      switch (item.status) {
        case 'inprogress':
          status = <span className="label label-warning">กำลังดาวน์โหลด..</span>;
          break;
        case 'failed':
          status = <span className="label label-danger">ไม่สำเร็จ</span>;
          break;
        default:
          break;
      }
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td>{item.fileName || item.file_url}</td>
          {/* <td>{item.status}</td> */}
          <td>{moment(item.start_date).format('DD-MM-YYYY HH:mm:ss')}</td>
          <td>{item.end_date ? moment(item.end_date).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
          <td className="text-center" style={{ verticalAlign: 'middle' }}>
            <div style={{ paddingBottom: "5px" }}>
              {item.file_url && (
                (this.state.downloadType == 'contractor' && isAllow('DOWNLOAD_CONTRACTOR_LISTS')) ||
                (this.state.downloadType == 'vacation' && isAllow('DOWNLOAD_CONTRACTORS_VACATION')) ||
                (this.state.downloadType == 'job' && isAllow('DOWNLOAD_CONTRACTORS_SCHEDULES')) ||
                (this.state.downloadType == 'jobbychannel' && isAllow('VIEW_AND_DOWNLOAD_LIST_OF_Q_CHANG_FILES')) ||
                (this.state.downloadType == 'transfer' && isAllow('DOWNLOAD_PAYMENT_LISTS')) ||
                (this.state.downloadType == 'vaccines' && isAllow('DOWNLOAD_CONTRACTORS_PROOF_OF_VACCINATION_LISTS')) ||
                (this.state.downloadType == 'other_expense' && isAllow('DOWNLOAD_PAYMENT_LISTS')) ||
                (this.state.downloadType == 'promotions') ||
                (this.state.downloadType == 'retail_cash')
              ) ?
                (<a className="btn btn-xs" title="ดาวน์โหลด" data-tip="ดาวน์โหลด" href={item.file_url} target="_blank">
                  <span className="fa fa-download" aria-hidden="true" /></a>) : status}
            </div>
            {item.status === 'inprogress' ? <p className="label label-danger btn" onClick={this.updateStatusDownload.bind(this, item)}>ยกเลิก</p> : null}
            {item.status === 'cancel' && !item.file_url ? <p className="text-danger">ถูกยกเลิก</p> : null}
          </td>
        </tr>
      );
    });
  }

  async updateStatusDownload(item) {
    try {
      const options = {
        file_id: item._id,
        status: 'cancel'
      }
      let result = await this.props.download.updateStatusDownload(options)
      if (result) {
        this.fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  }

  checkLastDownloadeStatus(items) {
    let isDownloadBtn = true
    if (items.length) {
      if (items[0].status === 'inprogress') {
        isDownloadBtn = false
      }
    }
    return isDownloadBtn

  }

  displayDownloadButton(downloadType) {
    const { items } = this.props.download.toJS();
    const isDownloadBtn = this.checkLastDownloadeStatus(items)
    switch (downloadType) {
      case 'job':
      case 'jobbychannel':
      case 'vaccines':
      case 'promotions':
      case 'other_expense':
      case 'retail_cash':
        return (
          <div className="cursor-pointer" onClick={this.fetchData.bind(this)}>
            <i className="fa fa-refresh btn-icon" aria-hidden="true" />
          </div>
        )
      default:
        return (
          <button
            className="btn btn-success"
            title="ดาวน์โหลด"
            data-tip="ดาวน์โหลด"
            onClick={this.onDownload.bind(this)}
            disabled={!isDownloadBtn || (
              (this.state.downloadType == 'contractor' && !isAllow('DOWNLOAD_CONTRACTOR_LISTS')) ||
              (this.state.downloadType == 'vacation' && !isAllow('DOWNLOAD_CONTRACTORS_VACATION'))
            )}
          >
            <span className="fa fa-plus btn-icon" aria-hidden="true" />ดาวน์โหลด
          </button>
        )
    }
  }
  onDateChange(key, date) {
    this.props.download.setQuery(key, date.toDate());
  }

  onDateChangeWithValidation(key, date) {
    let errorMessage = "";
    // validate input date
    if (key === "start_date") {
      const { query } = this.props.download.toJS();
      if (!this.checkIsValidDateRange(date, query.end_date)) {
        errorMessage = "กรุณาตรวจสอบวันที่";
      }
    } else if (key === "end_date") {
      const { query } = this.props.download.toJS();
      if (!this.checkIsValidDateRange(query.start_date, date)) {
        errorMessage = "กรุณาตรวจสอบวันที่";
      }
    } else if (key === "create_start_date") {
      const { query } = this.props.download.toJS();
      if (!this.checkIsValidDateRange(date, query.create_end_date)) {
        errorMessage = "กรุณาตรวจสอบวันที่";
      }
    } else if (key === "create_end_date") {
      const { query } = this.props.download.toJS();
      if (!this.checkIsValidDateRange(query.create_start_date, date)) {
        errorMessage = "กรุณาตรวจสอบวันที่";
      }
    }

    // display error message
    if (!errorMessage) {
      this.props.download.setQuery(key, date);
    } else {
      swal.fire({
        icon: "error",
        title: errorMessage,
        confirmButtonText: "ตกลง",
      });
    }
  }

  checkIsValidDateRange(fromDate, toDate) {
    if (fromDate && toDate) {
      return moment(fromDate).valueOf() <= moment(toDate).valueOf();
    }
    return true;
  }

  changeType(event) {
    let is_freeze = event.target.value === 'freeze'
    this.setState({ isTranferMC: is_freeze ? false : true })
    if (is_freeze) {
      this.setState({ saleModelState: this.saleModelOptions })
    } else {
      this.setState({ saleModelState: [{ label: 'MarketPlaceCredit', value: 'MarketplaceCredit' }] })
    }
  }
  filterDownload(query) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-1" style={{ width: '12.66%' }}>
            <b>ประเภทไฟล์</b>
          </div>
          <div className="col-md-2" style={{ textAlign: 'start' }}>
            <input class="form-check-input" type="radio" name="radioOptions" id="radio1" value="freeze" onChange={this.changeType.bind(this)} checked={!this.state.isTranferMC} />
            <label class="form-check-label" for="inlineRadio1"><p className="pl-3"> &nbsp;&nbsp;Transfer (freeze)&nbsp;&nbsp;</p></label>
          </div>
          <div className="col-md-3" style={{ textAlign: 'start' }}>
            <input class="form-check-input" type="radio" name="radioOptions" id="radio2" value="mc" onChange={this.changeType.bind(this)} checked={this.state.isTranferMC} />
            <label class="form-check-label" for="inlineRadio2"><p >&nbsp;&nbsp;Transfer MC</p></label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2" style={{ width: '12.66%' }}>
            <b>ช่องทางการจ่ายเงิน</b>
          </div>
          <div className="col-md-4">
            <div style={{ paddingBottom: '2%' }}>
              <EnTagSelect
                multi={true}
                disabled={this.state.isChannelLoading}
                closeOnSelect
                options={this.channelPayment}
                onTagChange={(selectedItem) => {
                  this.onQueryChange('payment_channel', selectedItem);
                }}
                value={query.payment_channel}
                searchable
                className="coupon-search-select"
                placeholder={this.state.isChannelLoading ? 'กำลังโหลด...' : 'เลือก...'}
              />
            </div>
          </div>
          <div className="col-md-2" style={{ width: '12.66%' }}>
            <b>Model การขาย</b>
          </div>
          <div className="col-md-4">
            <div style={{ paddingBottom: '2%' }}>
              <EnTagSelect
                multi={true}
                closeOnSelect
                options={this.state.saleModelState}
                onTagChange={(selectedItem) => {
                  this.onQueryChange('sale_model_list', selectedItem);
                }}
                value={query.sale_model_list}
                searchable
                className="coupon-search-select"
                placeholder="เลือก..."
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2" style={{ width: '12.66%' }}>
            <b>วันที่ได้รับเงิน<b><br /></b>(จาก-ถึง):</b>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-5">
                <EnDatePicker id="startDate" initialDate={query.start_date ? moment(query.start_date) : undefined} onDateChange={this.onDateChange.bind(this, 'start_date')} isOutsideRange={(day) => query.end_date ? day.isBefore(moment(query.end_date).subtract(1, 'M')) : undefined} placeholder="เลือก.." />
              </div>
              <div className="col-md-2 text-center"><b>-</b></div>
              <div className="col-md-5">
                <EnDatePicker id="endDate" initialDate={query.end_date ? moment(query.end_date) : undefined} onDateChange={this.onDateChange.bind(this, 'end_date')} isOutsideRange={(day) => query.start_date ? day.isAfter(moment(query.start_date).add(1, 'M')) : undefined} placeholder="เลือก.." />
              </div>
            </div>
          </div>
          <div className="col-md-2" style={{ width: '12.66%' }}>
            <b>วันที่สร้างการจ่ายเงิน<b><br /></b>(จาก-ถึง):</b>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-5">
                <EnDatePicker id="createStartDate" initialDate={query.create_start_date ? moment(query.create_start_date) : undefined} onDateChange={this.onDateChange.bind(this, 'create_start_date')} isOutsideRange={(day) => query.create_end_date ? day.isBefore(moment(query.create_end_date).subtract(1, 'M')) : undefined} placeholder="เลือก.." />
              </div>
              <div className="col-md-2 text-center"><b>-</b></div>
              <div className="col-md-5">
                <EnDatePicker id="createEndDate" initialDate={query.create_end_date ? moment(query.create_end_date) : undefined} onDateChange={this.onDateChange.bind(this, 'create_end_date')} isOutsideRange={(day) => query.create_start_date ? day.isAfter(moment(query.create_start_date).add(1, 'M')) : undefined} placeholder="เลือก.." />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-11" style={{ textAlign: 'right' }}>
            <button
              className="btn btn-success"
              title="ดาวน์โหลด"
              data-tip="ดาวน์โหลด"
              // onClick={this.onSubmit.bind(this, query)}
              onClick={this.onDownload.bind(this, query)}
              disabled={!isAllow('DOWNLOAD_PAYMENT_LISTS')
                || ((!query.start_date || !query.end_date) && (!query.create_start_date || !query.create_end_date) && !this.state.isTranferMC)
                || ((!query.start_date && !query.end_date && !query.create_start_date && !query.create_end_date && !this.state.isTranferMC))
              }
            >
              <span className="fa fa-plus btn-icon" aria-hidden="true" />
              ดาวน์โหลด
            </button>
            <EnButton className="btn-warning" onClick={this.onClearSearch.bind(this)}>
              <i className="fa fa-refresh btn-icon" aria-hidden="true" />
              เคลียร์
            </EnButton>
          </div>
        </div>
      </div >
    )
  }
  filterRetailCashDownload(query) {
    return (
      <div className="row" style={{ margin: '10px 0' }}>
        <div className="col-md-2">
          <b>วันที่ได้รับเงิน<b><br /></b>(จาก-ถึง):</b>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-5">
              <EnDatePicker id="startDate" initialDate={query.start_date ? moment(query.start_date) : undefined} onDateChange={this.onDateChangeWithValidation.bind(this, 'start_date')} isOutsideRange={(day) => query.end_date ? day.isBefore(moment(query.end_date).subtract(1, 'M')) : undefined} placeholder="เลือก.." />
            </div>
            <div className="col-md-2 text-center"><b>-</b></div>
            <div className="col-md-5">
              <EnDatePicker id="endDate" initialDate={query.end_date ? moment(query.end_date) : undefined} onDateChange={this.onDateChangeWithValidation.bind(this, 'end_date')} isOutsideRange={(day) => query.start_date ? day.isAfter(moment(query.start_date).add(1, 'M')) : undefined} placeholder="เลือก.." />
            </div>
          </div>
        </div>
        <div className="col-md-6" style={{ textAlign: 'right', marginTop: '-10px' }}>
          <button
            className="btn btn-success"
            title="ดาวน์โหลด"
            data-tip="ดาวน์โหลด"
            // onClick={this.onSubmit.bind(this, query)}
            onClick={this.onDownload.bind(this, query)}
            disabled={!query.start_date || !query.end_date}
          >
            <span className="fa fa-plus btn-icon" aria-hidden="true" />
            ดาวน์โหลด
          </button>
          <EnButton className="btn-warning" onClick={this.onClearSearch.bind(this)}>
            <i className="fa fa-refresh btn-icon" aria-hidden="true" />
            เคลียร์
          </EnButton>
        </div>
      </div>
    )
  }

  filterOtherExpenseDownload(query) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-2" style={{ width: '12.66%' }}>
            <b>วันที่ได้รับเงิน<b><br /></b>(จาก-ถึง):</b>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-5">
                <EnDatePicker id="startDate" initialDate={query.start_date ? moment(query.start_date) : undefined} onDateChange={this.onDateChangeWithValidation.bind(this, 'start_date')} isOutsideRange={(day) => query.end_date ? day.isBefore(moment(query.end_date).subtract(1, 'M')) : undefined} placeholder="เลือก.." />
              </div>
              <div className="col-md-2 text-center"><b>-</b></div>
              <div className="col-md-5">
                <EnDatePicker id="endDate" initialDate={query.end_date ? moment(query.end_date) : undefined} onDateChange={this.onDateChangeWithValidation.bind(this, 'end_date')} isOutsideRange={(day) => query.start_date ? day.isAfter(moment(query.start_date).add(1, 'M')) : undefined} placeholder="เลือก.." />
              </div>
            </div>
          </div>
          <div className="col-md-2" style={{ width: '12.66%' }}>
            <b>วันที่สร้างการจ่ายเงิน<b><br /></b>(จาก-ถึง):</b>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-5">
                <EnDatePicker id="createStartDate" initialDate={query.create_start_date ? moment(query.create_start_date) : undefined} onDateChange={this.onDateChangeWithValidation.bind(this, 'create_start_date')} isOutsideRange={(day) => query.create_end_date ? day.isBefore(moment(query.create_end_date).subtract(1, 'M')) : undefined} placeholder="เลือก.." />
              </div>
              <div className="col-md-2 text-center"><b>-</b></div>
              <div className="col-md-5">
                <EnDatePicker id="createEndDate" initialDate={query.create_end_date ? moment(query.create_end_date) : undefined} onDateChange={this.onDateChangeWithValidation.bind(this, 'create_end_date')} isOutsideRange={(day) => query.create_start_date ? day.isAfter(moment(query.create_start_date).add(1, 'M')) : undefined} placeholder="เลือก.." />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-11" style={{ textAlign: 'right' }}>
            <button
              className="btn btn-success"
              title="ดาวน์โหลด"
              data-tip="ดาวน์โหลด"
              onClick={this.onDownload.bind(this, query)}
              disabled={!isAllow('DOWNLOAD_PAYMENT_LISTS') ||
                ((!query.start_date || !query.end_date) && (!query.create_start_date || !query.create_end_date))
                || (!query.start_date && !query.end_date && !query.create_start_date && !query.create_end_date)
              }
            >
              <span className="fa fa-plus btn-icon" aria-hidden="true" />
              ดาวน์โหลด
            </button>
            <EnButton className="btn-warning" onClick={this.onClearSearch.bind(this)}>
              <i className="fa fa-refresh btn-icon" aria-hidden="true" />
              เคลียร์
            </EnButton>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { downloadType } = this.state;
    const { items, isLoading } = this.props.download.toJS();
    const isDownloadBtn = this.checkLastDownloadeStatus(items)
    const rows = this.getTableRows(items);
    const { query } = this.props.download.toJS();

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายการดาวน์โหลดคิวช่าง</h4>
              </div>
              <div className="card-content">
                <div>
                  {
                    ['transfer'].includes(downloadType) && this.filterDownload(query)
                  }
                  {
                    ['retail_cash'].includes(downloadType) && this.filterRetailCashDownload(query)
                  }
                  {
                    ['other_expense'].includes(downloadType) && this.filterOtherExpenseDownload(query)
                  }
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {['job', 'jobbychannel'].includes(downloadType) && <JobSearchForm
                      storeId={this.state.storeId}
                      channelName={this.state.channel}
                      disabledDownloadBtn={!isDownloadBtn}
                      channelOptions={this.state.channelOptions}
                      typeOfWorkOptions={this.state.typeOfWorkOptions}
                      contractorOptions={this.state.contractorOptions}
                      downloadType={this.state.downloadType}
                      onSubmit={(query) => { this.onDownload(query) }}
                      onClearSearch={() => { this.onClearSearch() }}
                    />}
                    {['vaccines'].includes(downloadType) && (
                      <div className="col-md-12">
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label className="col-md-3 control-label">วันที่เริ่ม</label>
                            <div className="col-md-9">
                              <EnDatePicker id="startDate" initialDate={query.start_date ? moment(query.start_date) : undefined} onDateChange={this.onDateChange.bind(this, 'start_date')} isOutsideRange={() => false} placeholder="เลือก.." />
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <label className="col-md-3 control-label">วันที่สิ้นสุด</label>
                            <div className="col-md-9">
                              <EnDatePicker id="endDate" initialDate={query.end_date ? moment(query.end_date) : undefined} onDateChange={this.onDateChange.bind(this, 'end_date')} isOutsideRange={() => false} placeholder="เลือก.." />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-12" style={{ textAlign: 'right' }}>
                            <button
                              className="btn btn-success"
                              title="ดาวน์โหลด"
                              data-tip="ดาวน์โหลด"
                              // onClick={this.onSubmit.bind(this, query)}
                              onClick={this.onDownload.bind(this, query)}
                              disabled={!isDownloadBtn || !isAllow('DOWNLOAD_CONTRACTORS_PROOF_OF_VACCINATION_LISTS')}
                            >
                              <span className="fa fa-plus btn-icon" aria-hidden="true" />
                              ดาวน์โหลด
                            </button>
                            <EnButton className="btn-warning" onClick={this.onClearSearch.bind(this)}>
                              <i className="fa fa-refresh btn-icon" aria-hidden="true" />
                              เคลียร์
                            </EnButton>
                          </div>
                        </div>
                      </div>
                    )}
                    {['promotions'].includes(downloadType) && (
                      <PromotionSearchForm
                        storeId={this.state.storeId}
                        channelName={this.state.channel}
                        disabledDownloadBtn={!isDownloadBtn}
                        channelOptions={this.state.channelOptions}
                        typeOfWorkOptions={this.state.typeOfWorkOptions}
                        contractorOptions={this.state.contractorOptions}
                        downloadType={this.state.downloadType}
                        onSubmit={(query) => { this.onDownload(query) }}
                        onClearSearch={() => { this.onClearSearch() }}
                      />
                    )}
                  </div>
                </div>
                {/* <div className="row"> */}
                {/* <div className="col-md-12"> */}
                <div className="table-responsive">
                  <table className="table table-bordered table-hover" width="100%">
                    <thead>
                      <tr>
                        <th width="10%" className="text-center">ลำดับที่</th>
                        <th width="50%" className="text-center">ชื่อไฟล์</th>
                        <th width="15%" className="text-center">เวลาเริ่ม</th>
                        <th width="15%" className="text-center">เวลาสิ้นสุด</th>
                        <th width="10%" className="text-center">
                          {
                            downloadType === 'transfer' ?
                              <div className="cursor-pointer" onClick={this.fetchData.bind(this)}>
                                <i className="fa fa-refresh btn-icon" aria-hidden="true" />
                              </div> :
                              this.displayDownloadButton(downloadType)
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows}
                    </tbody>
                  </table>
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('download', 'auth', 'channel')(observer(ManageDownload));

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import numeral from 'numeral';
import qs from 'query-string';
import Container from '../../../layouts/ContainerContractor';
import Loader from '../../../components/common/Loader';
import Pagination from '../../../components/common/Pagination';
import EnButton from '../../../components/form/EnButton';
import EnDatePicker from '../../../components/form/EnDatePicker';
import config from '../../../config';
import { toast } from 'react-toastify';

export class TransferSummary extends Component {
  constructor(props) {
    super(props);
    const parsed = qs.parse(this.props.location.search);
    this.state = {
      id: this.props.match.params.id,
      effectiveDate: parsed.effective_date,
      page: parsed.page,
    };
  }

  async componentWillMount() {
    try {
      this.props.transfersummary.setDefaultQuery();
      const { id, effectiveDate, page } = this.state;
      if (id) {
        this.props.transfersummary.setQueryValue('contractor_id', id);
      }
      if (effectiveDate) {
        this.props.transfersummary.setQueryValue('effective_date', moment(effectiveDate).toDate());
      }
      if (page) {
        this.props.transfersummary.setQueryValue('page', page);
      }
      await this.props.transfersummary.searchTranferSummary();
    } catch (err) {
      toast.error('เกิดข้อผิดพลาด');
    }
  }

  onViewDetailClick(date, gatewayName, query, e) {
    const params = {
      effective_date: moment(date).toISOString(),
      gateway_name: gatewayName,
      back_page: query.page,
      back_date: query.effective_date ? moment(query.effective_date).format('YYYY-MM-DD') : null,
    };
    const queryString = qs.stringify(params, { sort: false, skipNull: true });
    this.props.history.push(`${config.publicUrl}/contractors/${this.state.id}/transfer/detail?${queryString}`);
  }

  handleDateChange(key, date) {
    this.props.transfersummary.setQueryValue(key, date.toDate());
  }

  async onClearSearchClick(e) {
    this.props.transfersummary.setDefaultQuery();
    if (this.state.id) {
      this.props.transfersummary.setQueryValue('contractor_id', this.state.id);
    }
    await this.props.transfersummary.searchTranferSummary();
  }

  async onSearchClick(e) {
    this.props.transfersummary.setQueryValue('page', 1);
    await this.props.transfersummary.searchTranferSummary();
  }

  async onPageClick(pageNum) {
    this.props.transfersummary.setQueryValue('page', pageNum);
    await this.props.transfersummary.searchTranferSummary();
  }

  displaySearchForm(query) {
    return (
      <form className="form-horizontal">
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-md-2 control-label" htmlFor="effectiveDate">วันที่รับเงิน</label>
            <div className="col-md-10">
              <EnDatePicker id="effectiveDate"
                initialDate={query.effective_date ? moment(query.effective_date) : undefined}
                onDateChange={this.handleDateChange.bind(this, 'effective_date')}
                isOutsideRange={() => false}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="col-md-offset-2 col-md-10">
              <EnButton className="btn-warning" onClick={this.onClearSearchClick.bind(this)}>
                <i className="fa fa-refresh btn-icon" aria-hidden="true" />เคลียร์
              </EnButton>
              <EnButton className="btn-info" onClick={this.onSearchClick.bind(this)}>
                <i className="fa fa-search btn-icon" aria-hidden="true" />ค้นหา
              </EnButton>
            </div>
          </div>
        </div>
      </form>
    );
  }

  displayTableRows(data, query) {
    return data.map((d, index) => {
      return (
        <tr>
          <td className="text-center">{((query.page - 1) * query.page_size) + index + 1}</td>
          <td className="text-center">{momentTz(d.effective_date).utcOffset(7).format('ddd Do/M/YYYY')}</td>
          <td className="text-center">{d.bank_account_no}</td>
          <td className="text-right">{numeral(d.sum_amount_exclude_wht).format('0,0.00')}</td>
          <td className="text-right">{numeral(d.transfer_fee).format('0,0.00')}</td>
          <td className="text-right" style={{ textDecoration: 'underline' }}>
            {numeral(d.sum_amount_exclude_wht_exclude_fee).format('0,0.00')}
          </td>
          <td className="text-center">
            <EnButton
              className="btn-default btn-xs"
              onClick={this.onViewDetailClick.bind(this, d.effective_date, d.gateway_name, query)}
              style={{
                margin: '0',
                padding: '4px 10px',
              }}>
              <i className="fa fa-search" aria-hidden="true" />
            </EnButton>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { data, query, pagination, isLoading } = this.props.transfersummary.toJS();
    return (
      <Container>
        <Loader show={isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">สรุปเงินที่ได้รับ</h4>
              </div>
              <div className="card-content">
                {this.displaySearchForm(query)}
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="5%" className="text-center">ลำดับที่</th>
                            <th width="10%" className="text-center">วันที่ได้รับเงิน</th>
                            <th width="10%" className="text-center">เลขที่บัญชี</th>
                            <th width="10%" className="text-center">จำนวนเงินที่โอน</th>
                            <th width="10%" className="text-center">ค่าโอน</th>
                            <th width="10%" className="text-center">เงินที่ได้รับ</th>
                            <th width="10%" className="text-center">รายละเอียด</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayTableRows(data, query)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      pagination={pagination}
                      handlePageClick={this.onPageClick.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('transfersummary')(observer(TransferSummary));

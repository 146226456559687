import React from 'react';
import { observer, inject } from 'mobx-react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/material';
import history from '../utils/history';
import config from '../config';

export class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      displayName: '',
      anchorEl: null,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.setUsernameOnTopbar();
  }

  async setUsernameOnTopbar() {
    let auth = this.props.auth.getStore();
    let isSuperAdmin = this.props.auth.isSuperAdmin();
    let isAdminBU = this.props.auth.isAdminBU();
    let storeName = '';
    if (isSuperAdmin) {
      storeName = 'Super Admin';
    } else if (isAdminBU) {
      storeName = 'Admin BU';
    } else if (auth && auth.name) {
      storeName = auth.name || '';
    }

    let currentRole = this.props.auth.getCurrentRole();

    if (currentRole) {
      storeName = (currentRole && currentRole.name) || storeName;
      const userInfo = this.props.auth.getUserInfo();
      const userName = userInfo.displayName || userInfo.email || '';
      this.setState({ displayName: `${userName} (${storeName}) ` });
    }
  }

  async handleSwitchRole(uuid) {
    this.handleClose();
    const { auth: authStore } = this.props;
    let currentRole = this.props.auth.getCurrentRole();
    if (currentRole.uuid === uuid) return;
    await this.props.auth.setPermissions(uuid);
    currentRole = this.props.auth.getCurrentRole();

    if (authStore.isSuperAdmin()) {
      history.push(`${config.publicUrl}/superadmin/jobs`);
    } else {
      history.push(`${config.publicUrl}/admin/calendar`);
    }
    this.setUsernameOnTopbar();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, isOpen: !this.state.isOpen });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, isOpen: false });
  };

  render() {
    const currentRole = this.props.auth.getCurrentRole();
    const roles = this.props.auth.getUserRole();

    return (
      <nav className="navbar navbar-transparent navbar-absolute">
        <div className="container-fluid">
          <div
            className="navbar-header"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <a className="navbar-brand user-name-text">
              <i className="fa fa-user-circle" aria-hidden="true" />
              {this.state.displayName}
            </a>
            {currentRole && (
              <Box display={'flex'} justifyContent={'center'} alignContent={'center'}>
                <Button
                  id="basic-button"
                  aria-controls={this.state.isOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.isOpen ? 'true' : undefined}
                  onClick={this.handleClick}
                  size="large"
                  variant="outlined"
                  color="primary"
                >
                  SWITCH ROLE TO
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={this.state.anchorEl}
                  open={this.state.isOpen}
                  onClose={this.handleClose}
                  MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                >
                  {roles.map(
                    (role) =>
                      role && (
                        <MenuItem key={role.uuid} onClick={() => this.handleSwitchRole(role.uuid)}>
                          {role.name}
                        </MenuItem>
                      ),
                  )}
                </Menu>
              </Box>
            )}
          </div>
          <a align="left" className="navbar-brand">
            {this.state.version}
          </a>
        </div>
      </nav>
    );
  }
}

export default inject('auth')(observer(NavBar));

import React from 'react';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import EnButton from '../../../components/form/EnButton';
import { EnTextLabel } from '../../../components/form/EnTextLabel';
import jobStore from '../../../stores/JobStore';
import AuthStore from '../../../stores/AuthStore';
import typeofworkStore from '../../../stores/TypeOfWorkStore';
import common from '../../../utils/common';
import ListItem from '../../../components/common/ListItem';
import CallingButton from '../../../components/common/CallingButton';
import EnDropZone from '../../../components/form/EnDropZone';
import { config } from '../../../config';
import moment from 'moment';
import { datetime } from '../../../utils/datetime';
import momentTz from 'moment-timezone';
import { isAllow } from '../../../utils/permission';


export const getLastFiles = (jobInfo, propKey) => {
  const data = jobInfo[propKey];
  if (Array.isArray(data) && data.length) {
    return data.reduce((files, current) => {
      if (Array.isArray(current.files) && current.files.length) {
        const lastFile = current.files[current.files.length - 1];
        files.push(lastFile);
      }
      return files;
    }, []);
  }
  return data ? data.files || [] : [];
};

export const getLastRejectFiles = (historyData) => {
  const data = historyData ? historyData.reject_files : [];
  if (data) {
    return data;
  }
  return [];
};

export const showBasicInfo = (jobInfo, contractorMode) => {
  if (jobInfo) {
    jobInfo = {
      ...jobInfo,
      typeOfWorkName: getTypeName(jobInfo.typeOfWork),
    };
  }
  const typeOfJobLabel = jobInfo.jobType === 'S' ? 'วันนัดสำรวจ' : 'วันนัดติดตั้ง';
  const jobDateText = datetime.toTextString(jobInfo.startDate, jobInfo.endDate, 'DD/MM/YYYY');
  const jobTimeText = jobInfo.timeLabel ? jobInfo.timeLabel : common.getAvailableTimeText(jobInfo.available);
  let jobRemark;
  if (jobInfo.remark) {
    if (typeof jobInfo.remark === 'object' && jobInfo.remark.job_id) {
      jobRemark = `เลขอ้างอิงเดิม ${jobInfo.remark.job_id}`;
    }
  } else {
    jobRemark = '-';
  }

  if (jobInfo.old_remark && jobInfo.old_remark.length) {
    let remarkMap = jobInfo.old_remark.map((item) => {
      let checkTime = item.submited_at || item.status_history[0].date
      return ` ${item.remark || '-'} (${moment(checkTime).format('HH:mm DD/MM/YYYY')})`
    });

    jobRemark = remarkMap.toString();
  } else {
    let dateAt;
    if (jobInfo.statusHistory && jobInfo.statusHistory.length) {
      dateAt = `(${moment(jobInfo.statusHistory[0].date).format('HH:mm DD/MM/YYYY')})`
    }
    jobRemark = `${jobInfo.remark || '-'} ${dateAt ? dateAt : ''}`;
  }
  const fileInvoice = getLastFiles(jobInfo, 'invoice');
  const receiptFiles = []
  const pcFiles = []
  for (let i = 0; i < fileInvoice.length; i++) {
    if ((fileInvoice[i].name || '').slice(0, 2) === 'PC') {
      pcFiles.push(fileInvoice[i])
    } else {
      receiptFiles.push(fileInvoice[i])
    }
  }

  const receiptItemData = toListItemData(receiptFiles, true);
  const receiptItemHeader = initListItemHeaderOption('ใบรับเงิน');
  const receiptListItem = getListItem(receiptItemHeader, receiptItemData, () => { });
  const pcItemData = toListItemData(pcFiles, true);
  const pcItemHeader = initListItemHeaderOption('ใบยืนยันการชำระเงินจากร้านค้า');
  const pcListItem = getListItem(pcItemHeader, pcItemData, () => { });

  const joFiles = getLastFiles(jobInfo, jobInfo.jobType === 'S' ? 'po_survey' : 'po_installation');
  const joListItemData = toListItemData(joFiles, true);
  const joItemHeader = initListItemHeaderOption('ใบสั่งซื้อสินค้า (Purchase Order)');
  const joListItem = getListItem(joItemHeader, joListItemData, () => { });

  return (
    <div className="job-info" style={{ paddingLeft: '15px' }}>
      {
        !jobInfo.is_paid && (
          <div className="alert alert-info alert-dismissible alert-paid">
            <span><i className="fa fa-clock-o btn-icon" aria-hidden="true" /> รอการชำระเงินจากลูกค้า</span>
          </div>
        )
      }
      {isAllow("VIEW_CUSTOMER_INFORMATION") && (
        <div>
          <div className="title">
            <p>รายละเอียดลูกค้า</p>
          </div>
          <div className="row">
            <EnTextLabel label="รหัสอ้างอิง" value={jobInfo.jobCode || jobInfo.id} icon="fa fa-info-circle" />
            {
              contractorMode && <EnTextLabel label="ชื่อร้านค้า" value={jobInfo.storeName} icon="fa fa-institution" />
            }
          </div>
          <div className="row">
            <EnTextLabel label={typeOfJobLabel} value={jobDateText} icon="fa fa-calendar" />
            <EnTextLabel label="ช่วงเวลา" value={jobTimeText} icon="fa fa-clock-o" />
          </div>
          <div className="row">
            <EnTextLabel label="ประเภทงาน" value={jobInfo.typeOfWorkName} icon="fa fa-cog" />
            <EnTextLabel label="ชื่อ-นามสกุล" value={jobInfo.firstName + ' ' + jobInfo.lastName} icon="fa fa-address-card-o" />
          </div>
          <div className="row">
            <EnTextLabel label="เบอร์ติดต่อ" value={jobInfo.phone} icon="fa fa-phone-square" tailingButton={<CallingButton phone={jobInfo.phone} />} />
            <EnTextLabel label="อีเมล" value={(jobInfo.email) ? jobInfo.email : '-'} icon="fa fa-envelope" />
            <EnTextLabel label="เบอร์ติดต่อสำรอง" value={jobInfo.phone2} icon="fa fa-phone-square" notRequired tailingButton={<CallingButton phone={jobInfo.phone2} />} />
          </div>
          <div className="row">
            <EnTextLabel label="ที่อยู่" value={checkAddress(jobInfo)} icon="fa fa-map-marker" />
            <EnTextLabel label="จุดสังเกต" value={jobInfo.addressInfo.landmark} icon="fa fa-eye" />
            {
              jobInfo.addressInfo.requestLocation &&
              <a href={'https://maps.google.com/?q=' + jobInfo.addressInfo.location.lat + ',' + jobInfo.addressInfo.location.lng} target="_blank">
                <div className="col-md-6 col-sm-6">
                  <div className="row">
                    <div className="col-md-2 col-xs-2 text-center">
                      <img src="https://storage.googleapis.com/prod-qchang-v1/static/images/icons8-google-maps-96.png" alt="" style={{ width: '24px' }} />
                    </div>
                    <div className="col-md-4 col-xs-4">
                      <label>พิกัด</label>
                    </div>
                    <div className="col-md-6 col-xs-6">
                      ดูแผนที่
                    </div>
                  </div>
                </div>
              </a>
            }
          </div>
          <div className="row">
            <EnTextLabel label="รายละเอียดเพิ่มเติม" value={jobInfo.description} icon="fa fa-sticky-note" />
            <EnTextLabel label="หมายเหตุจากลูกค้า" value={jobRemark} icon="fa fa-bookmark-o" />
            <EnTextLabel label="หมายเหตุจากแอดมิน" value={(jobInfo.adminRemark) ? jobInfo.adminRemark : '-'} icon="fa fa-bookmark" />
          </div>
          {!_.isNil(_.get(jobInfo, 'physicalDetail')) && (
            <div>
              <div className="col-md-12 col-sm-12" style={{ padding: '15px' }}>
                <label>ข้อมูล Physical Detail</label>
              </div>
              <div className="row">
                <EnTextLabel label="ชื่อสินค้า" value={_.get(jobInfo, 'physicalDetail.name') ? _.get(jobInfo, 'physicalDetail.name') : '-'} />
                <EnTextLabel label="แบรนด์" value={_.get(jobInfo, 'physicalDetail.brand') ? _.get(jobInfo, 'physicalDetail.brand') : '-'} />
              </div>
              <div className="row">
                <EnTextLabel label="โมเดล" value={_.get(jobInfo, 'physicalDetail.model') ? _.get(jobInfo, 'physicalDetail.model') : '-'} />
                <EnTextLabel label="SKU" value={_.get(jobInfo, 'physicalDetail.sku') ? _.get(jobInfo, 'physicalDetail.sku') : '-'} />
              </div>
            </div>
          )}

        </div>
      )}
      <hr />
      <div className="row">
        <div className="col-md-12 col-sm-12">
          {receiptListItem}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          {pcListItem}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          {joListItem}
        </div>
      </div>

      <hr />
    </div>
  );
};

export const showQuotation = (jobInfo) => {
  if (jobInfo && jobInfo.quotation) {
    const quotationFiles = getLastFiles(jobInfo, 'quotation');
    const quotationItemData = toListItemData(quotationFiles, true);
    const quotationItemHeader = initListItemHeaderOption('ใบเสนอราคา');
    const quotationListItem = getListItem(quotationItemHeader, quotationItemData, () => { });

    let detailFileListItem;
    let quotationDetailFiles = jobInfo.quotation_detail_files;
    quotationDetailFiles = Array.isArray(quotationDetailFiles) ?
      quotationDetailFiles.filter(quotationDetailFile => quotationDetailFile.active) : undefined;

    if (quotationDetailFiles) {
      const detailFileData = toListItemData(quotationDetailFiles, true);
      const detailFileHeader = initListItemHeaderOption('รายละเอียดเพิ่มเติม');
      detailFileListItem = getListItem(detailFileHeader, detailFileData, () => { });
    }

    return (
      <div className="job-info" style={{ paddingLeft: '15px' }}>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            {quotationListItem}
          </div>
          {quotationDetailFiles && quotationDetailFiles.length > 0 && (
            <div className="col-md-12 col-sm-12">{detailFileListItem}</div>
          )}
        </div>
      </div>
    );
  }
};

export const checkAddress = (jobInfo) => {
  const { district, districtCode, postCode, province, provinceCode, subDistrict, subDistrictCode } = jobInfo.addressInfo;
  if (jobInfo.address && district && districtCode && postCode &&
    province && provinceCode && subDistrict && subDistrictCode) {
    return `${jobInfo.address} ${subDistrict} ${district} ${province} ${postCode}`;
  } else if (jobInfo.address) {
    return `${jobInfo.address}`;
  }
};

export const getTypeName = (typeofwork) => {
  const store = typeofworkStore.toJS().items;
  const typeofworkName = store.find(t => typeofwork[0] === t.value);
  if (typeofworkName) {
    return typeofworkName.name;
  }
};

export const showContentStepStatus = (icon, title, text, colorText) => {
  return (
    <div className="row">
      <div className="success-page">
        <i className={icon} style={{ fontSize: '60px', color: colorText }} />
        <h4>{title}</h4>
        {text}
      </div>
      <hr />
    </div>
  );
};

export const showStepStatus = (icon, title, date, colorText, span) => {
  return (
    <div className="row">
      <div className="success-page">
        <i className={icon} style={{ fontSize: '60px', color: colorText }} />
        <h4>{title}</h4>
        {span && (
          <p>{span}</p>
        )
        }
        {date && (
          <p>เมื่อวันที่ {date}</p>
        )}
      </div>
      <hr />
    </div>
  );
};

export const getStatusIcon = (statusName) => {
  let statusConfigIcon = {
    created: 'fa fa-pencil-square-o',
    assigned: 'fa fa-street-view',
    survey_started: 'fa fa-search',
    survey_finished: 'fa fa-flag-checkered',
    quotation_management: 'fa fa-file-lines',
    installation_accepted: 'fa fa-handshake-o',
    rejected: 'fa fa-close',
    paid: 'fa fa-credit-card',
    installation_requested: 'fa fa-calendar-plus-o',
    installation_confirmed: 'fa fa-asl-interpreting',
    installation_started: 'fa fa-tasks',
    installation_finished: 'fa fa-flag-checkered',
  };

  if (statusConfigIcon[statusName]) {
    return statusConfigIcon[statusName];
  }

  return '';
};

export const getStatusTitle = statusName => {
  const statusConfigName = {
    created: 'สร้างใหม่',
    assigned: 'มอบหมายงานให้ช่าง',
    survey_started: 'เริ่มการสำรวจ',
    survey_finished: 'สิ้นสุดการสำรวจ',
    quotation_management: 'ใบเสนอราคา',
    installation_accepted: 'ลูกค้ายอมรับหลังจากสิ้นสุดการติดตั้ง',
    rejected: 'ลูกค้าปฏิเสธหลังจากสิ้นสุดการติดตั้ง',
    paid: 'จ่ายเงินช่างแล้ว',
    installation_requested: 'ส่งคำขอนัดหมายการติดตั้ง',
    installation_confirmed: 'ยืนยันนัดหมายการติดตั้ง',
    installation_started: 'เริ่มการติดตั้ง',
    installation_finished: 'สิ้นสุดการติดตั้ง',
  };

  if (statusConfigName[statusName]) {
    return statusConfigName[statusName];
  }

  return '';
};

export const getStatusText = status => {
  switch (status) {
    case 'survey_started': return 'เริ่มสำรวจ';
    case 'survey_finished': return 'สิ้นสุดการสำรวจ';
    case 'quotation_management': return 'ใบเสนอราคา';
    case 'installation_requested': return 'นัดติดตั้ง';
    case 'installation_confirmed': return 'ยืนยันการติดตั้ง';
    case 'installation_started': return 'เริ่มการติดตั้ง';
    case 'installation_finished': return 'สิ้นสุดการติดตั้ง';
    default:
      return '';
  }
};

export const getDisplayStatusButton = (jobType, status, isContractor, callback, validation, reload, jobInfo, contractorAccept) => {
  let button = '';
  const onClick = async (status) => {
    const job = jobStore.toJS();
    if (await jobStore.showJobUpdatedDialog(job.info)) {
      return;
    }
    nextState(status, isContractor, callback, reload);
  };
  const validateInstallationDate = !(jobInfo && jobInfo.installationStartDate && jobInfo.installationEndDate);
  if (contractorAccept) {
    if (jobType === 'S') {
      if (isAllow("START_JOB") && status === 'assigned') {
        button = <EnButton id="start-survey" className="btn-warning pull-right" onClick={() => { onClick('survey_started'); }}>
          <i className="fa fa-play btn-icon" aria-hidden="true" />เริ่มสำรวจ
        </EnButton>;
      } else if (isAllow("END_JOB") && status === 'survey_started') {
        button = <EnButton id="survey-finished" className="btn-warning pull-right" onClick={() => { onClick('survey_finished'); }}>
          <i className="fa fa-flag-checkered btn-icon" aria-hidden="true" />สิ้นสุดการสำรวจ
        </EnButton>;
      } else if (isAllow("END_JOB") && status === 'quotation_management') {
        button = <EnButton id="quotation-management" className="btn-warning pull-right" onClick={() => { onClick('quotation_management'); }}>
          <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />ใบเสนอราคา
        </EnButton>;
      } else if (isAllow("END_JOB") && ((status === 'survey_finished' || status === 'paid_to_contractor') && !isContractor)) {
        // button = <EnButton id="installation-request" className="btn-warning pull-right" disabled={validateInstallationDate} onClick={() => { onClick('installation_requested'); }}>
        //   <i className="fa fa-calendar-plus-o btn-icon" aria-hidden="true" />นัดติดตั้ง
        // </EnButton>;
      }
    } else if (jobType === 'I') {
      if (isAllow("START_JOB") && (!isContractor && status === 'installation_requested')) {
        button = <EnButton id="installation-confirm" className="btn-warning pull-right" disabled={validation} onClick={() => { onClick('installation_confirmed'); }}>
          <i className="fa fa-check btn-icon" aria-hidden="true" />ยืนยันการติดตั้ง
        </EnButton>;
      } else if (isAllow("START_JOB") && (status === 'assigned' || status === 'installation_confirmed')) {
        button = <EnButton id="installation-start" className="btn-warning pull-right" onClick={() => { onClick('installation_started'); }}>
          <i className="fa fa-play btn-icon" aria-hidden="true" />เริ่มการติดตั้ง
        </EnButton>;
      } else if (isAllow("END_JOB") && status === 'installation_started') {
        button = <EnButton id="installation-finished" className="btn-warning pull-right" onClick={() => { onClick('installation_finished'); }}>
          <i className="fa fa-flag-checkered btn-icon" aria-hidden="true" />สิ้นสุดการติดตั้ง
        </EnButton>;
      }
    }
  }

  return button;
};

export const nextState = async (status, contractorMode, callback, reload) => {
  const statusText = getStatusText(status);
  try {
    const confirmDialogOptions = {
      icon: 'question',
      title: `${statusText}`,
      text: `กรุณายืนยันการเปลี่ยนสถานะเป็น ${statusText}`,
      footer: '<i style="color: rgb(221, 51, 51);">ข้อมูลจะถูกบันทึกพร้อมกับเปลี่ยนสถานะ<i>',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<div id="confirm">ยืนยัน<div>',
      cancelButtonText: '<div id="cancel">ยกเลิก<div>',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          if (callback) {
            await callback();
          } else {
            await jobStore.saveAndNextState(status, {
              isContractor: contractorMode,
            });
          }
          return true;
        } catch (error) {
          throw error;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    };

    const result = await Swal.fire(confirmDialogOptions);
    if (result.value) {
      // Swal.fire({
      //   icon: 'success',
      //   title: `เปลี่ยนสถานะเป็น ${statusText} เรียบร้อย`,
      // });
      if (!reload) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: `${error.message}`,
    });
  }
};

export const getDisplayEmpty = (message) => {
  return <div><em><small>{message}</small></em></div>;
};

export const toListItemData = (files, longFileName) => {
  if (Array.isArray(files) && files.length) {
    return files.filter(f => (f && f.name)).map((f, idx) => {
      return {
        filename: (idx + 1) + '_' + (longFileName ? f.name : f.name.substring(14, f.name.length)),
        name: f.name,
        url: f.url,
        date: f.date ? `${momentTz(f.date).utcOffset(7).format('DD/MM/YYYY HH:mm:ss')}` : '',
        delete: <i className="fa fa-trash" />,
        icon: common.getFileIcon(f.name),
        downloadHref: f.url,
      };
    });
  }
  return [];
};

export const initListItemHeaderOption = (title) => {
  return [
    {
      title: title,
      size: 9,
      key: 'filename',
      showIcon: true,
      downloadLink: true,
      appendDescription: true,
      descriptionKey: 'date',
    },
  ];
};

export const saveInfo = (key, e) => {
  if (e.target) {
    jobStore.setInfo(key, e.target.value);
  } else {
    jobStore.setInfo(key, e);
  }
};

export const setOnsite = (key, e) => {
  if (e.target) {
    jobStore.setOnsite(key, e.target.value);
  } else {
    jobStore.setOnsite(key, e);
  }
};

export const onUploadItemClick = (propName, jobInfo, key, data) => {
  if (key === 'delete') {
    let result = jobInfo[propName].filter(f => f.name !== data.name);
    saveInfo(propName, result);
  }
};

export const onDeletePartnerGuarantee = async (propName, jobInfo, key, data) => {
  if (key === 'delete' && propName === 'partnerGuarantee') {
    let result = jobInfo[propName].filter(f => f.name !== data.name);
    saveInfo(propName, result);

    const deleteFile = jobInfo[propName].find(f => f.name === data.name);
    if (deleteFile && deleteFile.name) {
      await jobStore.deletePartnerWarranty(deleteFile.name);
    }
  }
}

export const onDeleteOnsite = async (orderId, key, e) => {
  if (key === 'delete') {
    const data = e;
    const onsite = jobStore.toJS().onsitePicture;
    Swal.fire({
      title: 'ยืนยันการลบข้อมูล',
      text: 'กรุณายืนยันการลบข้อมูล',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.value) {
        let images = onsite ? onsite[0].file : [];
        let videos = onsite ? onsite[1].file : [];
        images = images.filter(f => f.url !== data.url);
        videos = videos.filter(f => f.url !== data.url);
        setOnsite('image', images);
        setOnsite('video', videos);
        await jobStore.saveOnsiteToOrder(orderId);
        Swal.fire({
          title: 'ลบข้อมูลเรียบร้อย',
          icon: 'success',
        }).then((result) => {
          if (result.value) {
            setTimeout(() => {
              window.location.reload();
            }, 200);
          } else {
            setTimeout(() => {
              window.location.reload();
            }, 200);
          }
        });
      }
    });
  }
}

export const getListItem = (itemHeader, itemData, callback) => {
  return (
    <div>
      <ListItem
        header={itemHeader}
        data={itemData}
        onClick={callback}
        target="_blank" />
      {!itemData || !itemData.length ? getDisplayEmpty('ไม่มีรูปถ่ายหรือไฟล์แนบ') : ''}
    </div>
  );
};

export const getListOnsitePicture = (itemHeader, itemData, callback) => {
  return (
    <div>
      <ListItem
        header={itemHeader}
        data={itemData}
        onClick={callback}
        target="_blank" />
      {!itemData || !itemData.length ? getDisplayEmpty('ไม่มีรูปถ่ายหรือไฟล์แนบ') : ''}
    </div>
  );
};

export const checkOverLimitFilesUpload = (limit) => {
  const filesUploaded = jobStore.onsitePicture[0].file.length + jobStore.onsitePicture[1].file.length;
  if (filesUploaded >= limit) return true;
  return false;
};

export const checkFilesCanUpload = (limit) => {
  const filesUploaded = jobStore.onsitePicture[0].file.length + jobStore.onsitePicture[1].file.length;
  const filesCanUpload = limit - filesUploaded;
  return filesCanUpload >= 0 ? filesCanUpload : 0;
};

export const contractorQuotationFileCounts = () => {
  const uploaded = _.get(jobStore, 'info.contractorQuotationFiles') || [];
  if (uploaded.length === 0) {
    return true;
  }
  const imagesUploaded = uploaded.filter(file => file.type === 'image').length;
  const otherFilesUploaded = uploaded.filter(file => file.type === 'file').length;
  return {
    imagesUploaded,
    otherFilesUploaded
  };
};

export const contractorQuotationDisabled = (limit) => {
  const filesUploaded = _.get(jobStore, 'info.contractorQuotationFiles.length') || 0;
  const filesCanUpload = limit - filesUploaded;
  return !(filesCanUpload >= 0);
};

export const contractorQuotationCanUpload = (limit) => {
  const filesUploaded = _.get(jobStore, 'info.contractorQuotationFiles.length') || 0;
  const filesCanUpload = limit - filesUploaded;
  return filesCanUpload >= 0 ? filesCanUpload : 0;
};

export const getDropZoneOnsitePicture = (ref, callback, onAddedFile, message = 'กรุณาเลือกรูปภาพและวีดีโอหน้างานจากลูกค้า', disable = false) => {
  let url = `${config.api.sims}/qchang/v1/orders/upload?isPrivate=false`;
  const token = AuthStore.getToken();
  const maxFiles = 10;
  return (
    <EnDropZone
      placeholder={message}
      ref={ref}
      url={url}
      token={token}
      acceptedFiles={'.jpeg,.jpg,.png,.mp4,.mov,.pdf'}
      onAllComplete={callback}
      onAddedFile={onAddedFile}
      onError={onError.bind(this)}
      maxFileSize={10}
      maxFiles={checkFilesCanUpload(maxFiles)}
      disabled={checkOverLimitFilesUpload(maxFiles) || disable}
    />
  );

};

export const getDropZone = (ref, jobId, callback, onAddedFile, message = '', disable = false, isPrivate = true) => {
  let url = `${config.api.sims}/v1/jobs/upload`;

  if (!isPrivate) {
    url = `${config.api.sims}/v1/jobs/upload?isPrivate=false`;
  }

  const contractor = AuthStore.getContractor();
  if (contractor && contractor._id && jobId && isPrivate) {
    url = `${config.api.sims}/v1/contractors/${contractor._id}/upload/jobs/${jobId}`;
  }

  if (contractor && contractor._id && jobId && !isPrivate) {
    url = `${config.api.sims}/v1/contractors/${contractor._id}/upload/jobs/${jobId}?isPrivate=false`;
  }

  const token = AuthStore.getToken();

  return (
    <EnDropZone
      placeholder={message}
      ref={ref}
      url={url}
      token={token}
      acceptedFiles={'image/*,.pdf,.pptx,.ppt,.doc,.docx,.xls,.xlsx,.txt,.zip,.7z,.rar,video/*'}
      onAllComplete={callback}
      onAddedFile={onAddedFile}
      onError={onError.bind(this)}
      maxFileSize={10}
      disabled={disable}
    />
  );
};

export const getContractorQuotationDropzone = (
  ref,
  callback,
  onRemovedFile,
  accept,
  jobId,
  disable,
) => {
  const url = `${config.api.sims}/v1/jobs/upload/contractorQuotation/${jobId}`;
  const token = AuthStore.getToken();
  const maxFiles = 20;

  // onDrop={this.props.onDrop}
  return (
    <EnDropZone
      placeholder={'กรุณาเลือกอัพโหลดไฟล์ได้ไม่เกิน 20 ไฟล์ โดยแบ่งเป็นไฟล์รูปสูงสุด 10 ไฟล์​ และไฟล์อื่น ๆ สูงสุด 10 ไฟล์'}
      ref={ref}
      url={url}
      token={token}
      acceptedFiles={'image/*,.pdf,.pptx,.ppt,.doc,.docx,.xls,.xlsx,.txt,.zip,.7z,.rar,video/*'}
      onAllComplete={callback}
      onRemovedFile={onRemovedFile || ((_file) => { })}
      onError={onError.bind(this)}
      maxFileSize={10}
      maxFiles={contractorQuotationCanUpload(maxFiles)}
      disabled={contractorQuotationDisabled(maxFiles) || disable}
      accept={accept}
    />
  );
};

export const listItemHeaderOpts = {
  title: '',
  size: 3,
  key: 'delete',
  className: 'text-right',
  itemClassName: 'text-right',
};

export const onError = (err) => {
  Swal.fire({
    icon: 'error',
    title: `${err}`,
  });
};

export const doUploadFiles = (dropzone, callback) => {
  let files = dropzone.getFiles();
  for (let file of files) {
    if (file.status === 'error') {
      throw new Error('ไม่สามารถอัพโหลดไฟล์ได้');
    }
  }
  jobStore.setSubmitLoading(true);
  dropzone.upload(callback);
};

export const setUploadedResult = (resultFiles, propName) => {
  const jobInfo = jobStore.toJS().info;
  let files = jobInfo[propName] || [];
  files = _.concat(files, resultFiles.map(f => {
    return {
      name: f.res.data.name,
      date: f.res.data.date,
      path: f.res.data.pathFile,
      url: f.res.data.path,
    };
  }));
  saveInfo(propName, files);
};

export const setUploadedOnsite = (resultFiles) => {
  const onsite = jobStore.toJS().onsitePicture;
  let images = onsite ? onsite[0].file : [];
  let videos = onsite ? onsite[1].file : [];
  const image = resultFiles.filter((i) => _.includes(i.file.type, 'image'));
  const video = resultFiles.filter((i) => _.includes(i.file.type, 'video'));
  images = _.concat(images, image.map(f => {
    return {
      name: f.res.data.name,
      url: f.res.data.path,
      date: f.res.data.date,
    };
  }));
  videos = _.concat(videos, video.map(f => {
    return {
      name: f.res.data.name,
      url: f.res.data.path,
      date: f.res.data.date,
    };
  }));
  setOnsite('image', images);
  setOnsite('video', videos);
};

export const onUploadOnsiteComplete = async (resultFiles, orderId) => {
  setUploadedOnsite(resultFiles);
  await jobStore.saveOnsiteToOrder(orderId);
};

export const onUploadComplete = async (resultFiles, state, contractorMode, onSubmit, arrayFiles = [], notiAdditionalCharge = false) => {
  jobStore.setSubmitLoading(false);
  if (arrayFiles && arrayFiles.length) {
    setUploadedResult(resultFiles, arrayFiles);
  } else {
    setUploadedResult(resultFiles, state.files);
  }

  if (state.isNextStep) {
    await jobStore.saveAndNextState(state.nextStep, {
      isContractor: contractorMode,
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } else if (!state.isNextStep && onSubmit) {
    onSubmit(notiAdditionalCharge);
  }
};

export const uploadAndNextStep = async (dropzone, nestStatus, contractorMode, updateWarranty) => {
  const fnReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  if (dropzone && dropzone.getFiles() && dropzone.getFiles().length) {
    doUploadFiles(dropzone);
  } else {
    await jobStore.saveAndNextState(nestStatus, {
      isContractor: contractorMode,
    });

    if (updateWarranty) {
      await jobStore.createGuaranteeJob();
    }

    fnReload();
  }
};

export const getstatusInfo = (data, currentStep) => {
  const status = data.filter((item, index) => item.value === currentStep);
  return status;
};

export const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY HH:mm:ss');
};

export const filterHistoryStatus = (data, statusfilter) => {
  const history = data.filter((status) => status.value === statusfilter);
  if ('installation_started installation_finished'.includes(statusfilter)) {
    const historyReject = data.filter((status) => status.value === 'installation_rejected');
    if (statusfilter === 'installation_started') {
      if (historyReject && historyReject.length > 0 && history.length - 1 > historyReject.length) {
        history.splice(historyReject.length - 1, history.length - historyReject.length - 1);
      }
    }
    if (historyReject && historyReject.length > 0) {
      for (let i = 0; i < history.length - 1; i++) {
        let h = history[i];
        h.reject_reason = historyReject[i].reject_reason;
        h.reject_files = historyReject[i].files;
      }
    }
  }
  return history;
};

export const sortDataByDate = (arrayData) => {
  arrayData = _.orderBy(arrayData, (o) => {
    return moment(o.date);
  }, ['desc']);

  return arrayData;
};

export const getTitleOptions = (data, index) => {
  const count = data.length;
  if (index === 0) {
    return 'งานล่าสุด';
  }
  return 'งานเก่าครั้งที่ ' + (count - index);
};

export const getActiveClass = (index) => {
  if (index === 0) {
    return true;
  }
  return false;
};

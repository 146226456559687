import React from 'react';
import { observer, inject } from 'mobx-react';
import queryString from 'query-string';
import moment from 'moment';
import Container from '../../layouts/ContainerContractor';
import { FormValidation } from '../../components/form/FormValidation';
import FormGroup from '../../components/form/FormGroup';
import EnToggle from '../../components/form/EnToggle';
import EnButton from '../../components/form/EnButton';
import EnDropDown from '../../components/form/EnDropDown';
import Loader from '../../components/common/Loader';
import datetime from '../../utils/datetime';
import { TypeOfJobs, AvailableTime } from '../../utils/data';
import config from '../../config';
import { toast } from 'react-toastify';

export class ContractorVacation extends React.Component {
  constructor(props) {
    super(props);
    let parsed = queryString.parse(this.props.location.search);
    this.state = {
      id: this.props.match.params.id,
      vacationId: this.props.match.params.vacationId,
      jobType: parsed.job_type ? parsed.job_type : 'S',
      start: parsed.start ? parsed.start : '',
      startDate: parsed.start ? moment(parsed.start, 'YYYYMMDD') : '',
      end: parsed.end ? parsed.end : '',
      endDate: parsed.end ? moment(parsed.end, 'YYYYMMDD') : '',
    };
  }

  onSaveJobTypeInfo(e) {
    if (e.target.value) {
      let val = e.target.value;
      this.setState({
        jobType: val,
      }, () => {
        this.props.calendar.saveInfo('typeOfJob', val);
        this.props.history.push(`${config.publicUrl}/contractors/${this.state.id}/calendar/vacation${this.state.vacationId ? `/${this.state.vacationId}` : ''}?job_type=${this.state.jobType}&start=${this.state.start}&end=${this.state.end}`);
      });
    }
  }

  onSaveUnavailableInfo(item) {
    this.props.calendar.saveInfo('unavailable', item.id);
  }

  clickCancel() {
    const calendarStore = this.props.calendar.toJS();
    const info = calendarStore.info;
    let url = `${config.publicUrl}/contractors/${this.state.id}/calendar?job_type=${info.typeOfJob}`;
    let date = this.state.startDate || this.state.endDate || info.startDate;
    if (date) {
      url += `&date=${moment(date).format('YYYY-MM')}`;
    }
    this.props.history.push(url);
  }

  async clickSubmit() {
    try {
      this.setState({
        isSubmiting: true,
      }, async () => {
        await this.props.calendar.submitVacation(this.state.vacationId);
        toast.success('บันทึกข้อมูลเรียบร้อย');
        setTimeout(() => {
          this.setState({ isSubmiting: false });
          this.clickCancel();
        }, 1000);
      });
    } catch (error) {
      toast.error(error.message);
    }
  }

  async clickDelete() {
    try {
      this.setState({
        isSubmiting: true,
      }, async () => {
        await this.props.calendar.deleteVacation(this.state.vacationId);
        toast.success('ลบข้อมูลเรียบร้อย');
        setTimeout(() => {
          this.setState({
            isSubmiting: false,
          });
          this.clickCancel();
        }, 1000);
      });
    } catch (error) {
      toast.error(error.message);
    }
  }

  componentWillMount() {
    if (this.state.vacationId) {
      const contractor = this.props.auth.getContractor();
      this.props.calendar.getVacation({
        vacationId: this.state.vacationId,
        contractorId: contractor._id,
      });
    } else {
      this.props.calendar.clearInfo();
      this.props.calendar.saveMultiInfo({
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        typeOfJob: this.state.jobType,
      });
    }
  }

  componentWillUnmount() {
    this.props.calendar.clearInfo();
  }

  render() {
    let calendarStore = this.props.calendar.toJS();
    let info = calendarStore.info;
    let showDate = datetime.ConvertISO2DisplayFullMonth(info.startDate);
    let isNotSameDate = false;
    if (!datetime.isEqual(info.startDate, info.endDate)) {
      isNotSameDate = true;
      showDate += ` - ${datetime.ConvertISO2DisplayFullMonth(info.endDate)}`;
    }

    let availableTime = AvailableTime
      .filter(time => (isNotSameDate && time.id === 0) || (!isNotSameDate))
      .map((item, index) => {
        return (
          <EnToggle key={index} checked={item.id === info.unavailable} label={item.title} onChange={this.onSaveUnavailableInfo.bind(this, item)} />
        );
      });

    let typeOfJobs = TypeOfJobs.map((item, idx) => {
      return <option key={idx} value={item.id}>{item.label}</option>;
    });
    if (this.state.vacationId) {
      typeOfJobs = typeOfJobs.slice(1);
    }

    return (
      <Container>
        <div className="row">
          <Loader show={this.state.isSubmiting} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ปฏิทิน</h4>
                <p className="category">{this.state.vacationId ? 'แก้ไข' : ''}รายละเอียดวันที่ไม่สามารถรับงานได้</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <FormGroup containerClassName="col-md-12">
                    <h4>{showDate}</h4>
                    <h4>กรุณาเลือกช่วงเวลาที่ท่านไม่สะดวกรับงาน</h4>
                    {availableTime}
                  </FormGroup>
                  <FormGroup containerClassName="col-md-12">
                    <h4>กรุณาเลือกลักษณะงาน</h4>
                    <EnDropDown value={info.typeOfJob} onChange={this.onSaveJobTypeInfo.bind(this)}>
                      {typeOfJobs}
                    </EnDropDown>
                  </FormGroup>
                  <div className="col-md-12">
                    <div className="col-md-6">
                      {
                        this.state.vacationId &&
                        <EnButton className="btn-danger pull-left" onClick={this.clickDelete.bind(this)}>
                          <i className="fa fa-trash btn-icon" aria-hidden="true" />ลบ
                        </EnButton>
                      }
                    </div>
                    <div className="col-md-6">
                      <EnButton className="btn-info pull-right" onClick={this.clickSubmit.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.clickCancel.bind(this)}>
                        <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                      </EnButton>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('contractor', 'calendar', 'auth')(observer(ContractorVacation));

import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import { FormValidation, required } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnTagSelect from '../../../components/form/EnTagSelect';
import EnButton from '../../../components/form/EnButton';
import config from '../../../config';
import EnToggle from '../../../components/form/EnToggle';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
export class MaterialPage extends React.Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    this.state = {
      id: id,
      isEdit: id && id.length,
      allTypeOfWorks: [],
      typeOfWorkItems: [],
      isErrorUnitPrice: false
    };
  }

  async componentWillMount() {
    try {
      await this.props.typeofwork.getTypeOfWorks();
      if (this.state.isEdit) {
        await this.props.material.getMaterialForEdit(this.state.id);
      } else {
        this.props.material.clearInfo();
      }
      this.onCheckUnitPrice();
      await this.loadTypeOfWorks();

    } catch (err) {
      toast.error(err.message);
    }
  }

  async loadTypeOfWorks() {
    // get mapTypeOfWorks
    const materialInfo = this.props.material.toJS().info;
    const typeOfWorks = this.props.typeofwork.mapIdAndLabel();
    let mapTypeOfWorks = materialInfo.mapTypeOfWorks;

    // //check mapTypeOfWorks
    if (mapTypeOfWorks && mapTypeOfWorks.length) {
      this.props.misc.saveId('miscData.mapTypeOfWorks', mapTypeOfWorks);
    } else {
      this.props.misc.saveId('miscData.mapTypeOfWorks', typeOfWorks);
    }

    const misc = this.props.misc.toJS().miscData;

    // initial Setup Store From database
    if (materialInfo && materialInfo.typeOfWorks && materialInfo.typeOfWorks.length) {
      misc.mapTypeOfWorks['selected_id'] = materialInfo.typeOfWorks;
    } else {
      misc.mapTypeOfWorks['selected_id'] = [];
    }

    this.setState({
      typeOfWorkItems: [misc.mapTypeOfWorks],
      allTypeOfWorks: misc.mapTypeOfWorks
    })
  }

  onCodeChanged(e) {
    this.props.material.saveInfo('code', e.target.value);
  }

  onNameChanged(e) {
    this.props.material.saveInfo('name', { th: e.target.value });
  }

  onCostChanged(e) {
    this.props.material.saveInfo('cost', parseInt(e.target.value));
    this.onCheckUnitPrice();
  }

  onCheckUnitPrice() {
    const materialInfo = this.props.material.toJS().info;
    if (parseInt(materialInfo.unitPrice) < parseInt(materialInfo.cost)) {
      this.setState({ isErrorUnitPrice: true });
    } else {
      this.setState({ isErrorUnitPrice: false });
    }
  }

  onUnitPriceChanged(e) {
    this.props.material.saveInfo('unitPrice', parseInt(e.target.value));
    this.onCheckUnitPrice();
  }

  onUnitChanged(e) {
    this.props.material.saveInfo('unit', e.target.value);
  }

  isValid() {
    const material = this.props.material.toJS();
    const info = material.info;
    const validCode = info.code && info.code.length;
    const validName = info.name && info.name.th && info.name.th.length;
    const typeOfWorks = this.state.typeOfWorkItems;
    let validType = 0;
    if (typeOfWorks[0]
      && typeOfWorks[0].selected_id
      && typeOfWorks[0].selected_id[0] !== ''
      && typeOfWorks[0].selected_id.length) {
      validType = typeOfWorks[0].selected_id.length
    }

    return validCode && validName && validType && !this.state.isErrorUnitPrice;
  }

  setSubmitForm() {
    let selectType = _.map(this.state.typeOfWorkItems[0].selected_id, id => { return parseInt(id) });
    this.props.material.saveInfo('typeOfWorks', selectType);
  }

  async onSubmit() {
    this.setSubmitForm()
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไข ข้อมูลวัสดุ' : 'กรุณายืนยันการเพิ่ม ข้อมูลวัสดุ ใหม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          if (this.state.isEdit) {
            await this.props.material.updateMaterial(this.state.id);
          } else {
            await this.props.material.createMaterial();
          }
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      this.props.material.clearInfo();

      setTimeout(() => {
        this.onCancel();
      }, 500);
    }
  }

  onCancel() {
    this.props.history.push(`${config.publicUrl}/superadmin/material`);
  }


  onTypeOfWorkChange(selectedItem, e) {
    let typeOfWorkItems = _.cloneDeep(this.state.typeOfWorkItems);
    if (e.indexOf('ALL') >= 0) {
      const typeId = this.state.allTypeOfWorks.map(b => b.id);
      let item = typeOfWorkItems.find(i => i.key === selectedItem.key);
      item['selected_id'] = typeId;
    } else {
      let typeId = e.split(',');
      let item = typeOfWorkItems.find(i => i.key === selectedItem.key);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item['selected_id'] = typeId;
      }
    }

    this.setState({
      typeOfWorkItems,
    });

  }

  onToggle(value) {
    this.props.material.saveInfo('status', value ? 'active' : 'inactive');
  }

  showTypeOfWorkSelectors() {
    let typeOfWorkOptions;
    let items = (this.state.typeOfWorkItems || []).map((item, index) => {
      typeOfWorkOptions = (item || []).map(d => {
        return ({
          label: d['label'],
          value: d['id'],
        });
      });

      if (this.state.allTypeOfWorks.length !== this.state.typeOfWorkItems[0].selected_id.length) {
        typeOfWorkOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...typeOfWorkOptions];
      }

      return (
        <div key={`item-${index}`}>
          <div>
            <FormGroup label="เลือกประเภทงาน *">
              <EnTagSelect
                closeOnSelect={false}
                options={typeOfWorkOptions}
                onTagChange={this.onTypeOfWorkChange.bind(this, item)}
                value={item['selected_id']}
                searchable
              />
            </FormGroup>
          </div>
        </div>
      );
    });
    return items;
  }

  render() {
    const material = this.props.material.toJS();
    const item = material.info;
    const title = this.state.isEdit ? 'แก้ไขข้อมูลวัสดุ' : 'เพิ่มข้อมูลวัสดุใหม่';
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{title}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="รหัสวัสดุ"
                      value={item.code}
                      onChange={this.onCodeChanged.bind(this)}
                      validations={[required]} />
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="ชื่อวัสดุ"
                      value={item.name.th}
                      onChange={this.onNameChanged.bind(this)}
                      validations={[required]} />
                  </div>
                  <div className="row">
                    <FormGroup>
                      <EnTextFormGroup
                        containerClassName="col-md-4"
                        type="number"
                        label="ราคาต้นทุนช่าง"
                        onChange={this.onCostChanged.bind(this)}
                        value={item.cost}
                      />
                      <EnTextFormGroup
                        className={this.state.isErrorUnitPrice ? 'is-invalid-input' : ''}
                        containerClassName="col-md-4"
                        type="number"
                        label="ราคาขายต่อหน่วย"
                        value={item.unitPrice}
                        onChange={this.onUnitPriceChanged.bind(this)}
                        isError={this.state.isErrorUnitPrice}
                        errorMessage="ราคาขายต่อหน่วยต้องมากกว่า ราคาต้นทุนช่าง"
                      />
                      <EnTextFormGroup
                        containerClassName="col-md-4"
                        type="text"
                        label="หน่วย"
                        value={item.unit}
                        onChange={this.onUnitChanged.bind(this)}
                      />
                    </FormGroup>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      {this.showTypeOfWorkSelectors()}
                    </div>
                    <div className="col-md-6" style={{ padding: '0px' }}>
                      <FormGroup containerClassName="col-md-12" label="สถานะการใช้งาน" >
                        <EnToggle checked={item.status === 'active'} onChange={this.onToggle.bind(this)} noPadding={true} />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row">
                    <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={this.onSubmit.bind(this)}>
                      <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                    </EnButton>
                    <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                      <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                    </EnButton>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'typeofwork', 'material', 'misc')(observer(MaterialPage));

import * as _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import EnStepProgress from '../../components/form/EnStepProgress';
import JobRequest from './JobRequest';
import EnStepSurveyStart from './step/EnStepSurveyStart';
import EnStepSurveyFinished from './step/EnStepSurveyFinished';
import EnInstalledConfirm from './step/EnInstalledConfirm';
import EnInstalledStart from './step/EnInstalledStart';
import EnInstalledFinished from './step/EnInstalledFinished';
import EnInstalledRequest from './step/EnInstalledRequest';
import EnInstalledAccepted from './step/EnInstalledAccepted';
import '../../assets/css/stepprogress.css';
import Swal from 'sweetalert2';
import EnButton from '../../components/form/EnButton';
import ModalReasonsWithEtc from '../../components/modal/ModalReasonsWithEtc';
import ContractorJobRequest from './ContractorJobRequest';
import config from '../../config';
import { isAllow } from '../../utils/permission';
import history from '../../utils/history';
import EnStepSurveyQuotationManagement from './step/EnStepSurveyQuotationManagement';
import Loader from '../../components/common/Loader';
import { get } from 'lodash';
import { toast } from 'react-toastify';

export class JobWizard extends Component {
  state = {
    stepStatus: [],
    forceQuotationManagement: false,
    isAdminReject: false,
  };

  async componentDidMount() {
    if (this.props.initial) {
      await this.props.initial();
      const jobInfo = this.props.job.toJS().info;
      if (jobInfo && jobInfo.statusHistory && jobInfo.statusHistory.length) {
        const isPaidToContractor =
          jobInfo.statusHistory.filter((s) => s.value === 'paid_to_contractor')
            .length > 0;
        if (isPaidToContractor) {
          await this.props.transfer.getTransferByJobId(jobInfo);
        }
      }
    }
    this.getStepStatus();
  }

  getStatusTitle(statusName) {
    const statusConfigName = {
      created: 'สร้างใหม่',
      assigned: 'มอบหมายงานให้ช่าง',
      survey_started: 'เริ่มการสำรวจ',
      survey_finished: 'สิ้นสุดการสำรวจ',
      quotation_management: 'ใบเสนอราคา',
      installation_accepted: 'ลูกค้ายอมรับหลังจากสิ้นสุดการติดตั้ง',
      rejected: 'ลูกค้าปฏิเสธหลังจากสิ้นสุดการติดตั้ง',
      paid: 'จ่ายเงินช่างแล้ว',
      installation_requested: 'ส่งคำขอนัดหมายการติดตั้ง',
      installation_confirmed: 'ยืนยันนัดหมายการติดตั้ง',
      installation_started: 'เริ่มการติดตั้ง',
      installation_finished: 'สิ้นสุดการติดตั้ง',
    };

    if (statusConfigName[statusName]) {
      return statusConfigName[statusName];
    }
  }

  getStatusIcon(statusName) {
    const statusConfigIcon = {
      created: 'fa fa-pencil-square-o',
      assigned: 'fa fa-street-view',
      survey_started: 'fa fa-search',
      survey_finished: 'fa fa-flag-checkered',
      quotation_management: 'fa fa-file-text-o',
      installation_accepted: 'fa fa-handshake-o',
      rejected: 'fa fa-close',
      paid: 'fa fa-credit-card',
      installation_requested: 'fa fa-calendar-plus-o',
      installation_confirmed: 'fa fa-asl-interpreting',
      installation_started: 'fa fa-tasks',
      installation_finished: 'fa fa-flag-checkered',
    };

    if (statusConfigIcon[statusName]) {
      return statusConfigIcon[statusName];
    }
  }

  getStatusComponent(statuses) {
    return statuses.map((value, index) => {
      return {
        title: this.getStatusTitle(value),
        icon: this.getStatusIcon(value),
        status: value,
        active: false,
        className: 'inactive',
      };
    });
  }

  async onRejectJob(isAdminReject) {
    try {
      if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
        return;
      }
      this.setState({ isAdminReject });
      this.props.modal.setDisplayModalName('adminRejectsJob');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `${error.message}`,
      });
    }
  }

  async adminRejectsJobModalSaved(callbackState) {
    try {
      if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
        return;
      }
      this.props.modal.setIsLoadingJobWizard(true);
      await this.saveRejectJob(callbackState.reason);
      setTimeout(() => {
        if (!this.state.isAdminReject) {
          this.props.onCancel();
        }
        window.location.reload();
      }, 1000);
      this.props.modal.setIsLoadingJobWizard(false);
    } catch (error) {
      this.props.modal.setIsLoadingJobWizard(false);
      Swal.fire({
        icon: 'error',
        title: `${error.message}`,
      });
    }
  }

  async saveRejectJob(reason) {
    try {
      const job = this.props.job.toJS().info;
      let { user, source } = this.props.auth.getUserAndSource();
      if (source === "sims_contractor") {
        user = job.contractorId;
      }
      await this.props.contractor.updateRejectJob(
        job.id,
        job.contractorId,
        reason,
        user,
        source
      );
    } catch (error) {
      toast.error(error.message);
    }
  }

  onShowNoQuotation() {
    Swal.fire({
      input: 'textarea',
      title: 'ระบุเหตุผลที่ไม่ต้องออกใบเสนอราคา',
      inputPlaceholder: 'กรุณากรอกข้อความ',
      showCancelButton: true,
      confirmButtonText: 'บันทึก',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.value) {
        this.props.job.saveReasonNoQuotation(result.value);
      }
    });
  }

  async onAcceptJob() {
    try {
      if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
        return;
      }
      const confirmDialogOptions = {
        icon: 'question',
        title: `ยืนยันการรับงาน ?`,
        footer:
          '<i style="color: rgb(221, 51, 51);">เมื่อกดยืนยันแล้วจะไม่สามารถยกเลิกได้',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            this.saveAcceptJob();
            return true;
          } catch (error) {
            throw error;
          }
        },

        allowOutsideClick: () => !Swal.isLoading(),
      };

      const result = await Swal.fire(confirmDialogOptions);
      if (result.value) {
        Swal.fire({
          icon: 'success',
          title: `ยืนยันการรับงานเรียบร้อย`,
        }).then((result) => {
          if (result.value) {
            setTimeout(() => {
              // this.props.onCancel();
              window.location.reload();
            }, 1000);
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `${error.message}`,
      });
    }
  }

  async saveAcceptJob() {
    try {
      const job = this.props.job.toJS().info;
      let { user, source } = this.props.auth.getUserAndSource();
      if (source === "sims_contractor") {
        user = job.contractorId;
      }
      await this.props.contractor.updateAcceptJob(job.id, job.contractorId, user, source);
    } catch (error) {
      toast.error(error.message);
    }
  }
  async clickedCreateQuatation(data) {
    if (data === true) {
      const jobInfo = this.props.job.toJS().info;
      if (Array.isArray(jobInfo.statusHistory)) {
        jobInfo.statusHistory.push({ value: 'quotation_management' })
      }
      this.props.job.setInfo('statusHistory', jobInfo.statusHistory);
      this.props.job.setInfo('status', 'quotation_management');
      this.getStepStatus();
      this.setState({ forceQuotationManagement: true });
    }
  }

  getStepContent(jobInfo, status, jobId, isContractorJob, transferInfo) {
    const today = moment(moment().add(1, 'days').format('YYYY/MM/DD'));
    const compareDate = moment(jobInfo.startDate).format('YYYY/MM/DD');
    const canReject = today.isBefore(compareDate);
    const isAutoAssign = jobInfo.auto_assign_contractor;
    // const text = canReject ? 'คุณได้รับมอบหมายงานเรียบร้อยแล้ว หากไม่สามารถรับงานได้ กรุณากดปุ่ม “ปฏิเสธงาน”' : 'คุณได้รับมอบหมายงานเรียบร้อยแล้ว หากต้องการเลื่อนวันนัด/ปฏิเสธงาน กรุณากดปุ่ม “โทรติดต่อแอดมิน”';
    const isAccepted = jobInfo.contractorAccept;
    const isAdminReject = true;
    const componentMessage = this.getComponentMessage(
      canReject,
      isAutoAssign,
      isAccepted
    );
    const adminComponentMessage = this.getComponentMessage(
      true,
      isAutoAssign,
      false,
      isAdminReject
    );
    if (jobInfo.jobType === 'S') {
      if (status === 'created') {
        const job = this.props.job.toJS().info;
        let isContractorJob = false;
        if (job) {
          isContractorJob = !job.storeId;
        }
        if (isContractorJob) {
          return <ContractorJobRequest {...this.props} updateMode />;
        }
        return (
          <JobRequest
            {...this.props}
            isUpdated
            stepAt={status}
            isNotClear
            isNotInitial
            showJobStep={this.props.showJobStep}
          />
        );
      }
      if (status === 'assigned') {
        return (
          <JobRequest
            {...this.props}
            onRejectJob={this.onRejectJob.bind(this)}
            componentMessage={componentMessage}
            adminComponentMessage={adminComponentMessage}
            isUpdated
            stepAt={status}
            isNotClear
            isNotInitial
            showJobStep={this.props.showJobStep}
          />
        );
      }
      if (status === 'survey_started') {
        return <EnStepSurveyStart {...this.props} />;
      }
      if (status === 'survey_finished') {
        return <EnStepSurveyFinished {...this.props} transfer={transferInfo} hasClickedCreateQuatation={this.clickedCreateQuatation.bind(this)} />;
      }
      if (status === 'quotation_management') {
        return <EnStepSurveyQuotationManagement {...this.props} transfer={transferInfo} />;
      }
      if (status === 'installation_requested') {
        return (
          <EnInstalledRequest
            {...this.props}
            jobId={jobId}
            transfer={transferInfo}
          />
        );
      }
    }

    if (jobInfo.jobType === 'I') {
      if (status === 'created' || status === 'installation_requested') {
        const job = this.props.job.toJS().info;
        let isContractorJob = false;
        if (job) {
          isContractorJob = !job.storeId;
        }
        if (isContractorJob) {
          return <ContractorJobRequest {...this.props} updateMode />;
        }
        return (
          <JobRequest
            {...this.props}
            isUpdated
            stepAt={status}
            isNotClear
            isNotInitial
            showJobStep={this.props.showJobStep}
            showCancelJobButton={job.status === 'installation_requested'}
            onCancelJob={this.props.onCancelJob}
            componentMessage={componentMessage}
            adminComponentMessage={adminComponentMessage}
            statusClaim={this.props.statusClaim}
            statusDeleted={this.props.statusDeleted}
          />
        );
      }
      if (status === 'assigned' || status === 'installation_confirmed') {
        return (
          <EnInstalledConfirm
            {...this.props}
            onRejectJob={this.onRejectJob.bind(this)}
            componentMessage={componentMessage}
            adminComponentMessage={adminComponentMessage}
          />
        );
      }
      if (status === 'installation_started') {
        return <EnInstalledStart {...this.props} />;
      }
      if (status === 'installation_finished') {
        return <EnInstalledFinished {...this.props} />;
      }
      if (status === 'installation_accepted') {
        return <EnInstalledAccepted {...this.props} transfer={transferInfo} />;
      }
    }
    return <div> สถานะไม่ถูกต้อง </div>;
  }

  getRejectJobComponent(canReject, isAutoAssign, isAccepted, isAdminReject) {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const { contractorId, contractorTeam } = jobInfo;
    const { contractorMode } = this.props;

    let result;
    result = !isAccepted && (
      <div className='row'>
        {isAllow("ACCEPT_JOB") ? (
          <EnButton
            className='btn-success'
             onClick={() => { 
              if(contractorMode && (!contractorTeam || (!contractorTeam.mainContractor && !contractorTeam.subContractor.length))) {
                this.addContractor(contractorTeam, contractorId, 'assigned');
              } else {
                this.onAcceptJob();
              }
             }
           }
          >
            <i className='fa fa-check-circle-o' aria-hidden='true' /> ยอมรับงาน
          </EnButton>
        ) : <div></div>}
        {isAllow("DECLINE_JOB") ? canReject && isAutoAssign && (
          <div>
            <br />
            <EnButton
              className='btn-danger'
              onClick={this.onRejectJob.bind(this, isAdminReject)}
            >
              <i className='fa fa-ban' aria-hidden='true' /> ปฏิเสธงาน
            </EnButton>
          </div>
        ) : <div></div>}
        {!canReject && isAutoAssign && (
          <a
            style={{ fontSize: '100%' }}
            className='btn btn-warning'
            href={`tel:${config.contact.phone}`}
          >
            <i className='fa fa-phone' aria-hidden='true' />
            &nbsp; โทรติดต่อแอดมิน
          </a>
        )}
      </div>
    );
    return result;
  }

  getComponentMessage(
    canReject,
    isAutoAssign,
    isAccepted,
    isAdminReject = false
  ) {
    return (
      !isAccepted && (
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='success-page' style={{ padding: '50px 30px' }}>
                {this.getRejectJobComponent(
                  canReject,
                  isAutoAssign,
                  isAccepted,
                  isAdminReject
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  getStepStatus() {
    const jobStore = this.props.job.toJS();

    const jobInfo = jobStore.info;
    const statusHistory = jobInfo.statusHistory;
    const isContractorJob = this.props.isContractorJob;

    const transferInfo = this.props.transfer.toJS().data;

    if (
      Array.isArray(jobInfo.statusHistory) &&
      jobInfo.statusHistory.length > 0
    ) {
      let steps;
      let specialStatusForStepper = [];
      if (jobInfo.jobType === 'S') {
        let statusSurvey = [
          'created',
          'assigned',
          'survey_started',
          'survey_finished',
          'quotation_management',
          'installation_requested',
        ];
        specialStatusForStepper = [
          'quotation_management',
          'installation_requested'
        ];

        steps = this.getStatusComponent(statusSurvey);
      }

      if (jobInfo.jobType === 'I') {
        let statusInstallation = [];
        if (statusHistory[0].value === 'created') {
          statusInstallation = [
            'created',
            'assigned',
            'installation_started',
            'installation_finished',
            'installation_accepted',
          ];
        }

        if (statusHistory[0].value === 'installation_requested') {
          statusInstallation = [
            'installation_requested',
            'installation_confirmed',
            'installation_started',
            'installation_finished',
            'installation_accepted',
          ];
        }

        steps = this.getStatusComponent(statusInstallation);
      }
      let status = [];
      // check if has status quotation_management
      const isQuotationManagement =
        ['survey_finished',
          'quotation_management',
          'installation_requested', 'paid_to_contractor'].includes(jobInfo.status) && !!statusHistory.find(h => h.value === 'quotation_management')
      for (let i = 0; i < statusHistory.length; i++) {
        status.push(statusHistory[i]);
        if (
          (jobInfo.status === statusHistory[i].value && !isQuotationManagement) ||
          (specialStatusForStepper.length > 0 && specialStatusForStepper[specialStatusForStepper.length - 1] === statusHistory[i].value)
        ) {
          break;
        }
      }
      _.forEach(status, (infoStatus) => {
        const step = (steps || []).find((st) => st.status === infoStatus.value);
        if (step) {
          step.active = true;
          step.className = 'visited';
          let jobId = '';
          if (infoStatus.job_id) {
            jobId = infoStatus.job_id;
          }
          step.content = () =>
            this.getStepContent(
              jobInfo,
              step.status,
              jobId,
              isContractorJob,
              transferInfo
            );
        }
      });

      this.setState({
        stepStatus: steps,
      });
    }
  }

  mapOptions(contractorTeams, contractorTeamFromJob) {
    if (!contractorTeams || !contractorTeams.length) {
      return `
        <div className='col-md-12'>
          <div className='col-md-12'>
            ยังไม่มีช่างที่เข้างาน
          </div>
        </div>
      `
    }

    return contractorTeams.map((item, index) => {
      const isChecked =
        get(contractorTeamFromJob, 'mainContractor._id', '') === item._id.toString() ||
        get(contractorTeamFromJob, 'subContractor', []).find(sub => sub._id === item._id.toString())

      return `
      <div key=${index} class="align-items-start" style="padding: 6px 12px;">
        <div class="d-flex" style="justify-content: space-between;">
          <div class="col-8 d-flex">
            <div class="col-4">
              ${
                item.profile_pic ? (
                  `<img 
                    src=${item.profile_pic}
                    alt='profile_pic'
                    class="img-fluid w-auto"
                    style="border-radius: 50%; object-fit: cover; max-width: 65px; max-height: 65px; min-width: 65px; min-height: 65px;"
                    onError="this.onerror=null"
                  />`
                ) :
                ('<i class="fa fa-user-circle" style="font-size: 65px; color: #ccc;"></i>')
              }
            </div>
            <div class="col-8" style="padding-left: 10px;">
              <p class="d-block m-0 text-left">${item.first_name} ${item.last_name}</p>
              <p class="d-block m-0 text-left">${item.contact_phone_no}</p>
            </div>
          </div>
          <div class="col-4" style="display: grid; justify-items: center; min-width: 60px">
            <input
              ${item.status === 'registered' ? 'disabled' : ''}
              class="checkbox"
              style="width: 25px; height: 25px"
              type='checkbox'
              class="mt-2"
              data-id="${item._id.toString()}"
              ${isChecked ? 'checked' : ''}
            />
            ${item.status === 'registered' ? ('<span>(รออนุมัติ)</span>') : ''}
          </div>
        </div>
      </div>
    `
    }).join('')
  }

  async addContractor(contractorTeam, contractorId, nextState=null) {
    if (!contractorId) {
      toast.error('กรุณาเลือกช่างหลักก่อนเพิ่มช่างเข้าหน้างาน')
      return;
    }

    const newOption = await this.props.subContractor.findTeam(contractorId);

    // Map over the options and generate HTML for each item
    const contractorItems = this.mapOptions(newOption, contractorTeam)

    return (
      Swal.fire({
        title: 'เลือกช่างเข้าหน้างาน',
        // showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ยืนยัน',
        html: `
        <div className='col-md-12'>
          <div className='col-md-12'>
            <input
              type='text'
              id='search'
              className='form-control'
              placeholder='ค้นหาช่าง'
              style="margin-bottom: 10px; width: 100%; height: 40px; color: #495057; border: 1px solid #ced4da; border-radius: .25rem; padding: 6px 12px;"
            />
          </div>
          <div className='col-md-12' style="margin-bottom: -20px;">
            <div id="contractorList" style="width: 100%; max-height: 450px; overflow-y: scroll;">
              ${contractorItems}
            </div>
          </div>
        </div>
      `,
        onOpen: () => {
          const searchInput = document.getElementById('search');
          const contractorList = document.getElementById('contractorList');
        
          searchInput.addEventListener('input', () => {
            const { value } = searchInput;
        
            // filter the options
            const filteredOptions = newOption.filter(item => {
              if (!value) return item;
              return item.first_name.toLowerCase().includes(value.toLowerCase()) ||
                item.last_name.toLowerCase().includes(value.toLowerCase()) ||
                item.contact_phone_no.toLowerCase().includes(value.toLowerCase());
            });
        
            // Update the HTML
            contractorList.innerHTML = this.mapOptions(filteredOptions, contractorTeam);
          })
        },
        preConfirm: async () => {
          // get contractors from the checkbox
          const checkboxes = document.querySelectorAll('.checkbox');
          const selectedContractors = [];
          checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
              const id = checkbox.getAttribute('data-id');
              const contractor = newOption.find(item => item._id.toString() === id);
              if (contractor) {
                selectedContractors.push(contractor);
              }
            }
          });

          // map to contractor_team
          const payload = {
            mainContractor: '',
            subContractor: [],
          }

          selectedContractors.map((item) => {
            if (item._id.toString() === contractorId.toString()) {
              payload.mainContractor = item
            } else {
              payload.subContractor.push(item);
            }
          });
          
          // update the state
          this.props.job.setInfo('contractorTeam', payload);

          // save and next state for contractorMode
          if(this.props.contractorMode && nextState) {
            await this.props.job.updateJob(this.props.jobId);
            await this.saveAcceptJob();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      })
    )
  }

  render() {
    const { stepStatus } = this.state;
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    let isContractorJob = false;
    return (
      <div>
        <EnStepProgress
          options={stepStatus}
          showJobStep={this.props.showJobStep}
          jobId={jobInfo.id}
          isContractorJob={isContractorJob}
          doForceQuotationManagement={this.state.forceQuotationManagement}
          {...this.props}
        />
        <ModalReasonsWithEtc
          modalName="adminRejectsJob"
          title="ยืนยันการปฏิเสธงาน"
          onSave={this.adminRejectsJobModalSaved.bind(this)}
          onCancel={_e => {}}
        />
        <Loader show={this.props.modal.toJS().isLoadingJobWizard} />
      </div>
    );
  }
}

export default inject('job', 'contractor', 'subContractor', 'transfer', 'modal', 'auth')(observer(JobWizard));

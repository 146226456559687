import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { get, isObject, cloneDeep, set, merge, union, groupBy, map, uniq, isEmpty, create } from 'lodash';
import { Buffer } from 'buffer';
import config from '../../config';
import infoCheck from '../../assets/img/icn_check.svg';
import infoWarning from '../../assets/img/icn_warning.svg';
import Loader from '../../components/common/Loader';
import { checkEmoji, FormValidation, maxLength, required } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnButton from '../../components/form/EnButton';
import EnTextAreaV2 from '../../components/form/EnTextAreaV2';
import EnTextAreaEditorFormGroup from '../../components/form/EnTextAreaEditorFormGroup';
import { EnTagSelectValidation } from '../../components/form/EnTagSelect';

import WarrantyTemplateStore from '../../stores/WarrantyTemplateStore';

import history from '../../utils/history';
import { defaultConfirmOption } from '../../utils/common';
import { isRole, ROLE } from '../../utils/permission';

import SaleModelToggleButtonGroup from '../qchang/warranty/components/SaleModelToggle';
import { name } from 'store/storages/cookieStorage';
import Container from '../../layouts/Container';
import momentTz from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import form from 'react-validation/build/form';
import EmptyBox from '../../assets/img/empty-box.svg';
import { toast } from 'react-toastify';

const PreviewPDF = (props) => {
  const renderPDF = (url) => {
    return <iframe src={url} width="100%" height="100%" />;
  };
  return (
    <Modal show={props.show} onHide={props.onClose} dialogClassName="preview-template-modal">
      <Modal.Header>
        <div style={{ cursor: 'pointer', fontSize: '24px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <span onClick={props.onClose}>x</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            height: 'calc(100vh - 232px)',
          }}
        >
          {props.formValues.sale_model === 'marketplace'
            ? renderPDF(props.pdf.marketplace)
            : props.formValues.sale_model === 'retail'
            ? renderPDF(props.pdf.retail)
            : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};


export class GuaranteeFormV2 extends React.Component {
  constructor(props) {
    super(props);
    const formValues = {
      name: '',
      sale_model: 'marketplace',
      warranty_period: {
        days: '',
        months: '',
        years: '',
      },
      warrantyTerms: [],
      warrantyExclusions: [],
    };
    let mode = 'view';

    this.state = {
      _isLoading: false,
      mode,
      config: {},
      pdf: '',
      showPreview: false,
      codeOptions: [],
      versionOptions: [],
      allTemplates: [],
      formValues,
      originalWarranty: {},
      originalFormValues: formValues,
      isNotFound: true,
      initCode: '',
      initVersion: '',
      disableSave: true,
    };
    
  }

  async componentWillMount() {
    await this.initialLoad();
  }

  async initialLoad() {
    try {
      this.setState({
        _isLoading: true,
      });

      await this.fetchWarranty();

    } catch (error) {
      await this.fetchWarrantyEmpty();
    } finally {
      this.setState({
        _isLoading: false,
      });
    }
  }

  generateWarrantyOptions(warrantyTemplates, initWarranty) {
    let mainTemp =  warrantyTemplates
    // .filter((t) => t.is_active)
    // .map((t) => ({ code: t.code, name: t.name, version: t.version, started_date: t.started_date, created_by: t.created_by }));

    //handle case the init selected code is not active
    if (initWarranty) {
      mainTemp = mainTemp.filter((t) => t.is_active || t.code === initWarranty.code);
    } else {
      mainTemp = mainTemp.filter((t) => t.is_active);
    }

    mainTemp = mainTemp.map((t) => ({ code: t.code, name: t.name, version: t.version, started_date: t.started_date, created_by: t.created_by }));

    let groupMainTemp = groupBy(mainTemp, 'code');
    groupMainTemp = map(groupMainTemp, (group, key) => ({
      code: key,
      name: uniq(group.map(item => item.name)).join(', '),
      nameVersion: group.map((t) => t.name),
      started_date: group.map((t) => t.started_date),
      created_by: group.map((t) => t.created_by),
      version: group.map((t) => t.version)
    }));

    const subTemp = warrantyTemplates
      .reduce(( (acc, curr) => [...acc, ...curr.sub_warranty_templates] ), [])
      .filter((t) => t.is_active)
      .map((t) => ({ code: t.code, name: t.name, version: t.version, started_date: t.started_date, created_by: t.created_by }));

    let groupSubTemp = groupBy(subTemp, 'code');
    groupSubTemp = map(groupSubTemp, (group, key) => ({
      code: key,
      name: uniq(group.map(item => item.name)).join(', '),
      nameVersion: group.map((t) => t.name),
      started_date: group.map((t) => t.started_date),
      created_by: group.map((t) => t.created_by),
      version: group.map((t) => t.version)
    }));

    let allTemp = [...groupMainTemp, ...groupSubTemp];
    
    //check if gua code is deleted then add to opt
    if(initWarranty) {
      const isInitDeleted = !allTemp.some((t) => t.code === initWarranty.code);
      if(isInitDeleted){
        allTemp = [
          ...allTemp,
          { code: initWarranty.code, name: initWarranty.name, version: initWarranty.version, started_date: initWarranty.started_date, created_by: initWarranty.created_by }
        ]
      }
    }

    return allTemp;
  }

  async fetchWarrantyByCode(warrantyCode) {
    
    try {
      this.setState({
        _isLoading: true,
      });

      const jobId = this.props.match.params.id;
      const jobStore = this.props.job

      const response = await jobStore.getWarrantyByJob(jobId, warrantyCode);

      if(response && response.isError){
        throw new Error(response.message);
      }
      // const response = await jobStore.getWarrantyByCodeSaleModel(jobId, res.code, res.version);

      let warranty = {
        ...this.state.originalFormValues,
        name: get(response, 'name', ''),
        code: get(response, 'code', ''),
        version: response.version,
        created_by: get(response, 'created_by', ''),
        started_date: get(response, 'started_date', ''),
      }

      let warranty_period = {
        days: '0',
        months: '0',
        years: '0',
      };
      if (response.warrantyPeriod) {
        warranty_period = {
          days: String(response.warrantyPeriod.days),
          months: String(response.warrantyPeriod.months),
          years: String(response.warrantyPeriod.years),
        };
      }
      warranty.warranty_period = warranty_period;
      if (response.content && response.content.warranty_terms && response.content.warranty_exclusions) {
        warranty.warrantyTerms = response.content.warranty_terms.map((t, i) => ({ key: new Date().getTime() + i, value: t }));
        warranty.warrantyExclusions = response.content.warranty_exclusions.map((t, i) => ({ key: new Date().getTime() + i, value: t }));
      }

      this.setState({
        config: response.template,
        formValues: warranty,
        originalFormValues: warranty,
        originalWarranty: response,
        isNotFound: false,
      })

    } catch (error) {
      toast.error(error.message || 'โหลดข้อมูลไม่สําเร็จ');
      this.setState((prevState) => {
        return {    
          formValues: {
            ...prevState.formValues,
            code: ''
          }
        }
      });
    } finally {
      this.setState({
        _isLoading: false,
      });
    }

  }

  async fetchWarrantyEmpty() {
    try {
      const wtyOpt = await this.props.guarantee.getWarrantyOptions(`isActive=true&limit=10000`);
      const warrantyTemplates = get(wtyOpt, 'warranty_templates', [])
      const allTemp = this.generateWarrantyOptions(warrantyTemplates);

      let codeOptions = [];
      // let versionOptions = [];

      codeOptions = allTemp.map((t) => ({ label: `${t.code} - ${t.name}`, value: t.code })); 

      this.setState({
        codeOptions,
        allTemplates: allTemp,
      });
        
    } catch (error) {
      toast.error(error.message || 'โหลดข้อมูลไม่สําเร็จ');
    } 
  }

  async fetchWarranty() {
    let warranty = {};
    let warrantyTerms = [];
    let warrantyExclusions = [];
    // this.setState({
    //   _isLoading: true,
    // });

    const jobId = this.props.match.params.id;

    const jobStore = this.props.job

    await jobStore.getJobsById(jobId)
    const info = jobStore.toJS().info;
    const response = await jobStore.getWarrantyByCodeSaleModel(jobId, info.warranty.code, info.warranty.version);
    const saleModel = info.saleModel.toLowerCase();
      
    const wtyOpt = await this.props.guarantee.getWarrantyOptions(`isActive=true&limit=10000`);
    const warrantyTemplates = get(wtyOpt, 'warranty_templates', [])
    const allTemp = this.generateWarrantyOptions(warrantyTemplates, response);

    // const selectTemplate = allTemp.find((i) => (i.code === response.code));
    // const findIdx = selectTemplate.version.findIndex((i) => (i === response.version));

    warranty = {
      name: get(response, 'name', ''),
      code: get(response, 'code', ''),
      version: get(response, 'version', ''),
      sale_model: saleModel,
      warrantyTerms,
      warrantyExclusions,
      started_date: get(response, 'started_date', ''),
      created_by: get(response, 'created_by', ''),
    };

    let warranty_period = {
      days: '0',
      months: '0',
      years: '0',
    };
    if (response.warrantyPeriod) {
      warranty_period = {
        days: String(response.warrantyPeriod.days),
        months: String(response.warrantyPeriod.months),
        years: String(response.warrantyPeriod.years),
      };
    }
    warranty.warranty_period = warranty_period;
    if (response.content && response.content.warranty_terms && response.content.warranty_exclusions) {
      warranty.warrantyTerms = response.content.warranty_terms.map((t, i) => ({ key: new Date().getTime() + i, value: t }));
      warranty.warrantyExclusions = response.content.warranty_exclusions.map((t, i) => ({ key: new Date().getTime() + i, value: t }));
    }

    let codeOptions = [];
    // let versionOptions = [];

    codeOptions = allTemp.map((t) => ({ label: `${t.code} - ${t.name}`, value: t.code })); 
    // versionOptions = allTemp.find((t) => t.code === warranty.code).version.map((t) => ({ label: t, value: t }));

    this.setState({
      config: response.template,
      // _isLoading: false,
      initCode: response.code,
      initVersion: response.version,
      formValues: warranty,
      originalFormValues: warranty,
      codeOptions,
      // versionOptions,
      originalWarranty: response,
      allTemplates: allTemp,
      isNotFound: false,
      initWarranty: warranty
    });
  }


  async onSubmit() {

    this.setState({ _isLoading: true });

    try {
      const { formValues } = this.state;

      const jobStore = this.props.job
      const info = jobStore.toJS().info;
      const { id } = info;

      const payload = {
        code: formValues['code'],
        version: formValues['version'],
      }

      await jobStore.updateWarranty(id, payload);
      toast.success('บันทึกข้อมูลสำเร็จแล้ว')
      window.location.reload();
    } catch (error) {
      this.setState({ _isLoading: false });
      toast.error(error.message || 'เกิดข้อผิดพลาด')
      return false;
    } finally {
      this.setState({ _isLoading: false });
    }
  }

  handleChangFieldForm(key, value) {
    if(key === 'sale_model')return;

    const { originalWarranty, allTemplates, formValues } = this.state;
    if (key == 'code' || key == 'version') {
      const code = key == 'code' ? value : this.state.formValues.code;
      let version = 1;
      if (key == 'version') {
        version = value;
      }

      //trigger refresh
      if (key === 'code'){
        this.fetchWarrantyByCode(value)
      }
    }
    this.setState((prevState) => {
      const updatedFormValues = cloneDeep(prevState.formValues);
      set(updatedFormValues, key, value);

      return { formValues: updatedFormValues, disableSave: false };
    });
  }

  onAddNotGuaranteeItems = () => {
    const warrantyExclusions = cloneDeep(this.state.formValues.warrantyExclusions);
    warrantyExclusions.push({
      key: new Date().getTime(),
      value: '',
    });
    this.setState({
      formValues: {
        ...this.state.formValues,
        warrantyExclusions,
      },
    });
  };

  isDirty() {
    const { formValues: info, originalFormValues } = this.state;
    function isEqual(value1, value2) {
      if (isObject(value1) && isObject(value2)) {
        const keys1 = Object.keys(value1);
        const keys2 = Object.keys(value2);

        if (keys1.length !== keys2.length) {
          return false;
        }

        return keys1.every((key) => isEqual(value1[key], value2[key]));
      }

      return value1 === value2;
    }

    const excludes = ['sale_model'];
    return Object.keys(info).some((key) => !isEqual(info[key], originalFormValues[key]) && !excludes.includes(key));
  }

  onTermsChange = (range, e) => {
    const warrantyTerms = cloneDeep(this.state.formValues.warrantyTerms);
    if (e && e.target) {
      let item = (warrantyTerms || []).filter((r) => {
        if (r.key === range.key) {
          r.value = e.target.value;
        }
        return r;
      });
      this.setState({
        formValues: {
          ...this.state.formValues,
          warrantyTerms,
        },
      });
    }
  };

  onExclusionsChange = (range, e) => {
    const warrantyExclusions = cloneDeep(this.state.formValues.warrantyExclusions);
    if (e && e.target) {
      let item = (warrantyExclusions || []).filter((r) => {
        if (r.key === range.key) {
          r.value = e.target.value;
        }
        return r;
      });
      this.setState({
        formValues: {
          ...this.state.formValues,
          warrantyExclusions,
        },
      });
    }
  };

  deleteItem(selectedItem, e, stateKey) {
    if (e && e.target) {
      let items = [...this.state.formValues[stateKey]];
      let filteredItems = items.filter((r) => r.key !== selectedItem.key);
      this.setState({
        formValues: {
          ...this.state.formValues,
          [stateKey]: filteredItems,
        },
      });
    }
  }

  deleteGuarantee(selectedItem, e) {
    this.deleteItem(selectedItem, e, 'warrantyTerms');
  }

  deleteNotGuarantee(selectedItem, e) {
    this.deleteItem(selectedItem, e, 'warrantyExclusions');
  }

  renderRow(info, onChangeFunc, deleteFunc) {
    const readOnly = this.state.mode == 'view';
    return info.map((r, index) => {
      const inputClass = info.length > 1 ? 'col-md-10 col-xs-8' : 'col-md-11 col-xs-9';
      let value = r.value;
      if (!r.key) {
        const redText = r.replace(/\{([^}]+)\}/g, (_, content) => `<span style="color: red;">${content}</span>`);
        value = redText;
      }

      function onChange(e) {
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';

        onChangeFunc(r, e);
      }

      return (
        <div key={r.key || index}>
          <div className="row">
            <div style={{ marginTop: '8px' }} className="col-md-1 col-xs-1">
              {index + 1}
            </div>
            <div className={inputClass} style={{ marginTop: '8px' }}>
              {r.key ? (
                <EnTextAreaV2
                  style={{
                    resize: 'none',
                    overflow: 'hidden',
                  }}
                  rows="1"
                  placeholder="กรุณาระบุ"
                  value={r.value}
                  onChange={onChange}
                  disabled={readOnly}
                  autoHeight={true}
                  validations={[required, checkEmoji]}
                />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: value }}></div>
              )}
            </div>
            {info.length > 1 && r.key && !readOnly && (
              <EnButton className="col-xs-1 col-md-1 btn-danger" onClick={deleteFunc.bind(this, r)} style={{ margin: '16px 0 0 0' }}>
                <i className="fa fa-trash" aria-hidden="true" />
              </EnButton>
            )}
          </div>
        </div>
      );
    });
  }

  getTitle() {
    let title = 'สร้างใบรับประกันใหม่';
    if (this.state.mode === 'view') {
      title = 'ดูข้อมูลใบรับประกัน';
    }

    return title;
  }

  beforeSubmit = async (isGuarantee) => {
    const result = await swal.fire({
      title: 'ยืนยันการบันทึกข้อมูล',
      html: 'คุณต้องการบันทึกข้อมูลหรือไม่',
      imageWidth: 60,
      imageHeight: 60,
      width: 400,
      height: 400,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      ...defaultConfirmOption(),
    });

    if (!result.value) return;
    await this.onSubmit()
  };

  async onSubmitGuarantee() {
    const result = await swal.fire({
      title: 'ยืนยันออกใบรับประกันใหม่',
      html: 'คุณต้องการออกใบรับประกันใหม่หรือไม่?',
      imageWidth: 60,
      imageHeight: 60,
      width: 400,
      height: 400,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      ...defaultConfirmOption(),
    });

    if (!result.value) return;
    const { initCode, initVersion, initWarranty } = this.state;

    if( initCode === '' || initVersion === '' ) {
      await swal.fire({
        title: 'ไม่พบข้อมูลรหัสใบรับประกัน สำหรับงานนี้',
        html: 'โปรดบันทึกรหัสรับประกัน',
        imageWidth: 60,
        imageHeight: 60,
        width: 400,
        height: 400,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'ยืนยัน',
        // cancelButtonText: 'ยกเลิก',
        ...defaultConfirmOption(),
      });

      return;
    }

    const jobId = this.props.match.params.id;
    const jobStore = this.props.job
    const info = jobStore.toJS().info;  

    const { warranty_period } = initWarranty;
    const { years, months, days } = warranty_period;

    const installationFinished = get(info, 'statusHistory', []).find((s) => s.value === 'installation_finished');
    const finishDate = get(installationFinished, 'date', '');

    // cal total day
    const start_date = moment(finishDate)
    let end_date = moment(finishDate)

    if(years) {
      end_date.add( parseInt(years), 'year');
    }

    if(months){
      end_date.add( parseInt(months), 'month');
    }

    if(days){
      end_date.add( parseInt(days), 'day');
    }

    const totalDay = end_date.diff(start_date, 'days');

    const response = await jobStore.getWarrantyByCodeSaleModel(jobId, initCode, initVersion);

    const { template, content } = response;

    let terms = [...get(template, 'warranty_terms.header', []), ...get(content, 'warranty_terms', []), ...get(template, 'warranty_terms.footer', [])];
    let exclusions = [...get(template, 'warranty_exclusions.header', []), ...get(content, 'warranty_exclusions', [])];

    terms = terms.map((i) => ({value: i}));
    exclusions = exclusions.map((i) => ({value: i}));

    try {
      const data = await jobStore.saveGuarantee(
        jobId,
        {
          guarantee: terms,
          not_guarantee: exclusions,
          total_date: totalDay,
          is_from_template: true,
          warranty_period: warranty_period
        }
      )

      // await this.saveGuaranteeActivityLog();
      await jobStore.updateWarranty(jobId);


      toast.success('ออกใบรับประกันสําเร็จ');

    } catch (error) {
      toast.error(error.message || 'บันทึกข้อมูลไม่สําเร็จ');
    }

  }

  getWarrantyList(saleModel) {
    const { formValues, config } = this.state;
    // if (!config &&  !config.warranty_terms && !config.warranty_exclusions) return { terms: [], exclusions: [] };
    if ( !config && !get(config, 'warranty_terms', false) && !get(config, 'warranty_exclusions', false)){
      return {
        terms: [ ...formValues.warrantyTerms, ...get(warranty_terms, 'footer', []) ], 
        exclusions: [ ...formValues.warrantyExclusions ]
      }
    }

    const { warranty_terms, warranty_exclusions } = config;
    return { 
      terms: [ ...get(warranty_terms, 'header', []), ...formValues.warrantyTerms, ...get(warranty_terms, 'footer', []) ], 
      exclusions: [ ...get(warranty_exclusions, 'header', []), ...formValues.warrantyExclusions ] 
    };
  }

  getBlobUrl(base64) {
    try {
      const binaryData = Buffer.from(base64, 'base64').toString('binary');
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      return blobUrl;
    } catch (error) {
      console.log('error: ----> ', error);
    }
  }

  async previewPDF() {
    const { formValues } = this.state;
    const marketplaceList = this.getWarrantyList('marketplace');
    const retailList = this.getWarrantyList('retail');

    const jobInfo = this.props.job.toJS().info;
    const { firstName, lastName, phone, jobCode, address, addressInfo, description, contractorId} = jobInfo;
    const { district, subDistrict, province, postCode, landmark } = addressInfo;

    let payload = {
      name: formValues.name,
      warranty_period: {
        days: Number(formValues.warranty_period.days || 0),
        months: Number(formValues.warranty_period.months || 0),
        years: Number(formValues.warranty_period.years || 0),
      },
      customer: {
        customerName: `${firstName} ${lastName}`,
        jobCode: jobCode,
        customerPhone: phone,
        customerAddress: `${address} ${subDistrict} ${district} ${province} ${postCode}`,
        customerLandmark: landmark,
        sku: description,
        contractorId
      }
    };

    this.setState({ _isLoading: true });

    const marketplace = await WarrantyTemplateStore.preview({
      ...payload,
      sale_model: 'marketplace',
      warranty_terms: marketplaceList.terms.map((t) => (t.key ? t.value : t)),
      warranty_exclusions: marketplaceList.exclusions.map((t) => (t.key ? t.value : t)),
    });
    const retail = await WarrantyTemplateStore.preview({
      ...payload,
      sale_model: 'retail',
      warranty_terms: retailList.terms.map((t) => (t.key ? t.value : t)),
      warranty_exclusions: retailList.exclusions.map((t) => (t.key ? t.value : t)),
    });

    this.setState({
      showPreview: true,
      pdf: {
        marketplace: this.getBlobUrl(marketplace),
        retail: this.getBlobUrl(retail),
      },
      _isLoading: false
    });
  }

  render() {
    const { formValues, showPreview, pdf, originalWarranty, codeOptions, versionOptions, mode, _isLoading, isNotFound } = this.state;
    const readOnly = mode === 'view';
    const isEdit = mode === 'edit';

    const warranties = this.getWarrantyList.bind(this)();
    const guarantee = this.renderRow(warranties.terms, this.onTermsChange, this.deleteGuarantee);
    const notGuarantee = this.renderRow(warranties.exclusions, this.onExclusionsChange, this.deleteNotGuarantee);

    const { info, isLoading } = this.props.job.toJS();

    const disableWarranty = !!!get(info, 'statusHistory', []).find((s) => s.value === 'installation_finished');

    return (
      <Container isAdmin>
        <div className="row">
          <PreviewPDF
            show={showPreview}
            onClose={() => this.setState({ showPreview: false })}
            pdf={pdf}
            formValues={formValues}
            handleChangFieldForm={this.handleChangFieldForm.bind(this)}
          />
          <Loader show={isLoading || _isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{this.getTitle()}</h4>
              </div>
              <div className="card-content">
                <div className="card-content">
                  <FormValidation>
                    {readOnly || isEdit ? (
                      <div
                        style={{
                          backgroundColor: '#fdead6',
                          borderRadius: '8px',
                          padding: '16px 16px',
                          marginBottom: '12px',
                        }}
                      >
                        <div className="row" style={{ height: '100%' }}>
                          <div className="col-md-3" style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <span>รหัส</span>
                            <div style={{ width: '100%' }}>
                              <EnTagSelectValidation
                                placeholder="กรุณาเลือก"
                                closeOnSelect={true}
                                multi={false}
                                searchable
                                options={codeOptions}
                                value={formValues['code']}
                                onTagChange={(e) => this.handleChangFieldForm('code', e)}
                                validations={[required]}
                              />
                            </div>
                          </div>
                          <div className="col-md-2" style={{ display: 'flex', alignItems: 'center', gap: '20px', height: '58px' }}>
                            <span>วันที่เริ่มต้น</span>
                            <span style={{ fontWeight: 'bold' }}>
                              {formValues.started_date && moment(formValues.started_date).add(543, 'year').format('DD MMM YYYY')}
                            </span>
                          </div>
                          <div className="col-md-2" style={{ display: 'flex', alignItems: 'center', gap: '20px', height: '58px' }}>
                            {originalWarranty.ended_date ? (
                              <>
                                <span>วันที่สิ้นสุด</span>
                                <span style={{ fontWeight: 'bold' }}>
                                  {originalWarranty.ended_date && moment(originalWarranty.ended_date).add(543, 'year').format('DD MMM YYYY')}
                                </span>
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-3" style={{ display: 'flex', alignItems: 'center', gap: '20px', height: '58px' }}>
                            สร้างโดย <span style={{ fontWeight: 'bold' }}> {formValues.created_by}</span>
                          </div>
                          <div
                            className="col-md-2"
                            style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '20px', height: '58px' }}
                          >
                            <span>เวอร์ชัน</span>
                            <div style={{ width: '100%' }}>
                              {
                                formValues['version']
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    

                    { isNotFound ?
                      (
                        <div className="row">
                          <div className='col-md-12'>
                            <div className="search-jobs-page__empty-container">
                              <img className="search-jobs-page__empty-img" src={EmptyBox} alt="EmptyBox" />
                              <div className="search-jobs-page__empty-text">สินค้าไม่มีการรับประกัน</div>
                            </div>
                          </div>
                        </div>
                      )
                      :
                      (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <span>
                                ชื่อใบรับประกัน <span className="text-danger">*</span>
                              </span>
                              <div style={{ marginTop: '6px' }}>
                                <EnTextAreaEditorFormGroup
                                  maxLength={150}
                                  showMaxLength
                                  value={formValues['name']}
                                  onChange={(e) => this.handleChangFieldForm('name', e.target.value)}
                                  validations={[required, checkEmoji, maxLength(150)]}
                                  placeholder="กรุณาระบุ"
                                  disabled={readOnly}
                                />
                              </div>
                            </div>

                            <span>
                              จำนวนวันที่รับประกัน <span className="text-danger">*</span>
                            </span>
                            <div className="col-md-6 row" style={{ paddingRight: 0 }}>
                              <EnTextFormGroup
                                containerClassName="col-md-4 "
                                type="number"
                                value={formValues.warranty_period['years']}
                                suffix="ปี"
                                onChange={(e) => this.handleChangFieldForm('warranty_period.years', e.target.value)}
                                validations={[maxLength(2)]}
                                placeholder="0"
                                disabled={readOnly}
                              />
                              <EnTextFormGroup
                                containerClassName="col-md-4"
                                type="number"
                                value={formValues.warranty_period['months']}
                                suffix="เดือน"
                                onChange={(e) => this.handleChangFieldForm('warranty_period.months', e.target.value)}
                                validations={[maxLength(2)]}
                                placeholder="0"
                                disabled={readOnly}
                              />
                              <EnTextFormGroup
                                containerClassName="col-md-4"
                                type="number"
                                style={{ paddingRight: 0 }}
                                value={formValues.warranty_period['days']}
                                suffix="วัน"
                                onChange={(e) => this.handleChangFieldForm('warranty_period.days', e.target.value)}
                                validations={[maxLength(3)]}
                                placeholder="0"
                                disabled={readOnly}
                              />
                            </div>
                          </div>
                          <div
                            className="header-duration"
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 8px', minHeight: '66px' }}
                          >
                            เงื่อนไขและขอบเขตการรับประกัน
                          </div>
                          {/* <SaleModelToggleButtonGroup formValues={formValues} handleChangFieldForm={this.handleChangFieldForm.bind(this)} /> */}
                          {guarantee}
                          <br />
                          <div
                            className="header-duration"
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 8px', minHeight: '66px' }}
                          >
                            ขอสงวนสิทธิ์ไม่รับประกันในกรณีต่อไปนี้
                          </div>
                          {/* <SaleModelToggleButtonGroup formValues={formValues} handleChangFieldForm={this.handleChangFieldForm.bind(this)} /> */}
                          {notGuarantee}
                        </>
                      )
                    }

                    <br />
                    <div className="row">

                      <EnButton
                        className="btn-danger pull-left" 
                        onClick={() => window.history.back()}
                      >
                        กลับ
                      </EnButton>

                      <EnButton
                        className="btn-primary pull-right"
                        style={{
                          background: 'none',
                          boxShadow: 'none',
                          color: 'var(--text-info-color)',
                          border: '1px solid var(--text-info-color)',
                        }}
                        disabled={isNotFound}
                        onClick={this.previewPDF.bind(this)}
                      >
                        <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />
                        ดูตัวอย่าง
                      </EnButton>
                      <EnButton 
                        className="btn-primary pull-right" 
                        disabled={isNotFound || this.state.disableSave}
                        style={{
                          backgroundColor: 'var(--text-info-color)',
                          color: 'white',
                        }}
                        onClick={() => this.beforeSubmit(false)}
                      >
                        บันทึก
                      </EnButton>
                      <EnButton
                        className="btn-primary pull-right"
                        style={{
                          backgroundColor: 'var(--text-info-color)',
                          color: '#ffffff',
                          border: '1px solid var(--text-info-color)',
                        }}
                        disabled={isNotFound || disableWarranty}
                        onClick={this.onSubmitGuarantee.bind(this)}
                      >
                        <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />
                        ออกใบรับประกัน
                      </EnButton>
                    </div>
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('job', 'guarantee', 'permission', 'auth')(observer(GuaranteeFormV2));

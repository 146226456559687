import * as _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import FormGroup from '../../components/form/FormGroup';
import EnTagSelect from '../../components/form/EnTagSelect';
import authStore from '../../stores/AuthStore';
import { isAllow } from '../../utils/permission';

export class AdminShareStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  async componentWillMount() {
    try {
      await this.props.admin.getStore();
      const job = this.props.job.toJS().info;
      const store = authStore.getStore();
      const storeInfo = this.props.admin.toJS().store;
      if (store) {
        const data = storeInfo.filter(s => s._id !== store._id);
        if (job && job.shareStore && job.shareStore.length) {
          data['_id'] = job.shareStore;
        }
        this.setState({ items: [data] });
      } else if (this.props.superAdminMode && job && job.storeId) {
        let data = storeInfo.filter(s => s._id !== job.storeId);
        if (job && job.shareStore && job.shareStore.length) {
          data['_id'] = job.shareStore;
        }
        this.setState({ items: [data] });
      }
    } catch (error) {
      throw error;
    }
  }

  onStoreChange(selectedItem, e) {
    let storeId = e.split(',');
    let items = _.cloneDeep(this.state.items);
    let item = items.find(i => i.key === selectedItem.key);
    if (!_.isNil(item) && !_.isEmpty(item)) {
      item['_id'] = storeId;
    }
    this.props.onStoreChange(items[0]._id);
    this.setState({
      items,
    });
  }

  showItem() {
    let storeOptions;
    let items = (this.state.items || []).map((item, index) => {
      storeOptions = (item || []).map(d => {
        return ({
          label: d['name'],
          value: d['_id'],
        });
      });
      return (
        <div key={`item-${index}`}>
          <FormGroup label="แชร์งานให้ร้านค้า">
            <EnTagSelect
              closeOnSelect={false}
              options={storeOptions}
              onTagChange={this.onStoreChange.bind(this, item)}
              value={item['_id']}
              searchable={false}
              placeholder={'เลือกร้าน'}
              disabled={this.props.statusClaim || this.props.statusDeleted}
            />
          </FormGroup>
        </div>
      );
    });
    return items;
  }

  render() {
    return isAllow("SHARE_JOB_TO_THE_STORE") && (
      <div className="card-content">
        {this.showItem()}
      </div>
    );
  }
}

export default inject('admin', 'contractor', 'auth', 'job')(observer(AdminShareStore));

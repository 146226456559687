import React, { useState, useMemo } from 'react';
import { FormControl, Checkbox, TextField, Autocomplete, Typography, Chip } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import _ from 'lodash';
import styled from 'styled-components';
import { CloseOutlined } from '@mui/icons-material';

const CodeStyle = styled.span`
  color: #999;
  padding-right: 2.5px;
`;

const NameStyle = styled.span`
  color: #555;
  padding-left: 2.5px;
`;

function AutocompleteComponent({
  label = '',
  disabled = false,
  options,
  values,
  onChange,
  onInputChange,
  onClose,
  backgroundColor = null , // ไม่ตั้งค่าเริ่มต้นเป็นสีใด ๆ
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useMemo(() => {
    if (values && values.length !== selectedOptions.length) {
      const selected = options.filter((item) => values.includes(item.value));
      const uniqueSelected = _.uniqBy([...selectedOptions, ...selected], 'value');
      setSelectedOptions(uniqueSelected);
    }
  }, [values, options]);

  const debouncedInputChange = useMemo(
    () =>
      _.debounce((input) => {
        if (input.length > 2 && onInputChange) {
          onInputChange(input);
          setError(false);
          setHelperText('');
        } else if (input.length >= 1 && input.length <= 2) {
          setError(true);
          setHelperText('กรุณากรอกอย่างน้อย 3 ตัวอักษร');
        } else {
          setError(false);
          setHelperText('');
        }
      }, 1000),
    [onInputChange],
  );

  const handleChange = (event, value) => {
    const uniqueSelected = _.uniqBy(value, 'value');
    setSelectedOptions(uniqueSelected);
    if (onChange) {
      onChange(uniqueSelected.map((item) => item.value || item));
    }
  };

  const handleDelete = (value) => {
    const newSelectedOptions = selectedOptions.filter((item) => item.code !== value.code);
    setSelectedOptions(newSelectedOptions);
    if (onChange) {
      onChange(newSelectedOptions.map((item) => item.value || item));
    }
  };

  const handleInputChange = (event, value) => {
    debouncedInputChange(value);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <FormControl fullWidth>
      <Typography variant="h7" fontWeight={600} sx={{ marginBottom: 1 }}>
        {label}
      </Typography>
      <Autocomplete
        multiple
        disableCloseOnSelect
        disabled={disabled}
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onClose={handleClose}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={selectedOptions.length === 0 ? 'กรุณาเลือก' : ''}
            error={error}
            helperText={helperText}
            sx={{
              ...(backgroundColor && { backgroundColor: backgroundColor }), // กำหนดสีเฉพาะเมื่อมี backgroundColor
              '& .MuiInputBase-root': {
                padding: '0.25rem 0.5rem',
                minHeight: '2rem',
              },
              '& .MuiFormHelperText-root': {
                paddingTop: '2px',
                margin: 0,
              },
            }}
          />
        )}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <CodeStyle>{option.code}</CodeStyle> - <NameStyle>{option.name}</NameStyle>
            </li>
          );
        }}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              variant="outlined"
              onDelete={() => handleDelete(option)}
              deleteIcon={<CloseOutlined fontSize="small" />}
              label={option.label}
              sx={{
                backgroundColor: '#FFEEEE',
                color: '#F54E41',
                borderRadius: '4px',
                borderColor: 'transparent',
                '& .MuiChip-deleteIcon': {
                  marginLeft: '5px',
                  marginRight: '-5px',
                  order: -1,
                  backgroundColor: 'transparent',
                  color: '#F54E41',
                },
                '&.Mui-disabled': {
                  opacity: 0.9,
                },
              }}
            />
          ));
        }}
      />
    </FormControl>
  );
}

export default AutocompleteComponent;

/* global FormData */
import * as _ from 'lodash';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';

export class CreditProviderStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      isSubmitting: false,
      info: this.createDefaultInfo(),
      dropzoneBillOpen: false,
      creditProvider: {},
    });
  }

  createDefaultInfo() {
    return {
      companyName: '',
      creditAmount: 0.00,
      creditFee: 0.00,
      billingFile: [],
      deletedBy: '',
      submitedBy: '',
    };
  }

  async uploadBillingFile(file, jobId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const formData = new FormData();
      for (let i = 0; i < file.length; i++) {
        formData.append(file[i].name, file[i]);
      }
      formData.append('jobId', jobId);
      const response = await http.postMultipartFormData(`${config.api.sims}/v1/jobs/uploadCreditProvider`, formData);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  clearCreditProvider() {
    this.creditProvider = {};
  }

  saveInfo(key, value) {
    this.info[key] = value;

    if (key === 'creditAmount' || key === 'creditFee') {
      this.info[key] = value.replace(/[^\0-9]/ig, '');
      this.calculateFee();
    }
    if (key === 'creditFee') {
      this.info[key] = value.replace(/[^\0-9]/ig, '');
    }
  }

  openDropZone(isOpen) {
    this.dropzoneBillOpen = isOpen;
  }

  calculateFee() {
    const info = this.info;
    info.creditFee = (info.creditAmount * 0.02).toFixed(2);
    info.creditFee = !isNaN(info.creditFee) ? info.creditFee : 0.00;
  }

  async generatePdf() {
    const { info } = this.toJS();
    let body = this.toSaveData(info);
    body.job_info = info.jobInfo;
    try {
      const res = await http.post(`${config.api.sims}/v1/pdf/CreditProvider`, body);
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  toCreditProviderInfo(job) {
    if (job) {
      if (job.credit_provider) {
        const cp = job.credit_provider;
        this.info = {
          companyName: cp.company_name,
          creditAmount: cp.credit_amount,
          creditFee: cp.credit_fee,
          billingFile: cp.billing_file,
        };
      } else {
        this.info = this.createDefaultInfo();
      }
    }
  }

  async loadCreditProviderByJobId(jobId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const res = await http.get(`${config.api.sims}/v1/jobs/${jobId}`, {
        params: {
          is_show_contractor_cost: true,
        },
      });
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        this.toCreditProviderInfo(data);
      } else {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async saveCreditProvider(jobId) {
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    const { info } = this.toJS();
    try {
      const res = await http.put(`${config.api.sims}/v1/jobs/${jobId}/CreditProvider`, info);
      this.isSubmitting = false;
      if (res && res.data && res.data.isError) {
        return false;
      }
      return true;
    } catch (error) {
      this.isSubmitting = false;
      throw new Error(error);
    }
  }

  async deleteCreditProvider(jobId) {
    try {
      const info = this.toJS().info;
      const res = await http.delete(`${config.api.sims}/v1/jobs/${jobId}/creditProvider`, { deleted_by: info.deletedBy });
      if (res && res.data && res.data.message !== 'Success') {
        return false;
      }

      return true;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default new CreditProviderStore();

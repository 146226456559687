import React from 'react';
import EnButton from '../form/EnButton';
import TextArea from '../../componentsV2/input/textArea';

const BenefitItem = ({ benefit, index, onChange, onDelete, disabled }) => {
  const handleInputChange = (e) => {
    onChange(index, e.target.value);
  };

  const handleDelete = () => {
    onDelete(index);
  };

  return (
      <tr style={{border: 'none'}}>
        <td className="text-center">{index + 1}</td>
        <td className="text-center">{benefit.code}</td>
        <td className="text-center">{benefit.name}</td>
        <td>
          <TextArea
            resizeAble={false}
            value={benefit.description}
            onChange={handleInputChange}
            disabled={disabled}
          />
        </td>
        <td>
          <EnButton onClick={handleDelete} disabled={disabled}>
            <i className="fa fa-trash" aria-hidden="true" />
          </EnButton>  
        </td> 
      </tr>
  );
};

export default BenefitItem;

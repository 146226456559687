import React from 'react';
import { observer, inject } from 'mobx-react';
import PhonePad from '../../components/pad/PhonePad';
import PinPad from '../../components/pad/PinPad';
import Loader from '../../components/common/Loader';
import history from '../../utils/history';
import config from '../../config';
import { toast } from 'react-toastify';

export class ContractorSignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      phone: '',
      pin: '',
    };
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1,
    });
  }

  prevStep = () => {
    this.setState({
      step: this.state.step - 1,
    });
  }

  savePhone = (phone) => {
    this.setState({
      phone: phone,
    });
  }

  savePin = (pin) => {
    this.setState({
      pin: pin,
    });
  }

  async checkPhoneNo() {
    try {
      if (!this.state.phone) throw new Error('กรุณาระบุเบอร์โทรศัพท์');

      let obj = await this.props.contractor.getMobileAvailable(this.state.phone);
      if (obj) {
        this.nextStep();
      } else {
        throw new Error('ไม่พบเบอร์โทรศัพท์ในระบบ');
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  async submitPin() {
    try {
      if (!this.state.pin) throw new Error('กรุณาระบุรหัสผ่าน');

      let redirectUrl = await this.props.auth.contractorAuthenticate(this.state.phone, this.state.pin);
      if (redirectUrl) {
        setTimeout(() => {
          history.replace(redirectUrl);
        }, 500);
      }
    } catch (err) {
      let msg = '';
      if (err.message === 'AUTHORIZATION_REQUIRED' || err.message === 'BAD_CREDENTIALS') {
        msg = 'ข้อมูลไม่ถูกต้อง';
      } else if (err.message === 'CONTRACTOR_NOT_FOUND') {
        msg = 'ไม่พบบัญชีผู้ใช้';
      } else if (err.message === 'INVALID_PIN') {
        msg = 'รหัสผ่านไม่ถูกต้อง';
      } else {
        msg = err.message;
      }
      toast.error(msg);
    }
  }

  goToNewRegister() {
    this.props.auth.removeContractor();
    history.push(`${config.publicUrl}/contractors/register`);
  }

  goToLandingPage() {
    this.props.auth.removeContractor();
    history.push(`${config.publicUrl}/contractors/landing`);
  }

  getPhonePad() {
    return (
      <PhonePad
        title="กรุณากดเบอร์โทรศัพท์"
        phone={this.state.phone}
        limitDisplayNumber={10}
        savePhone={this.savePhone}
        onPressNext={this.checkPhoneNo.bind(this)}
        onPressBack={this.goToLandingPage.bind(this)} />
    );
  }

  getPinPad() {
    return (
      <PinPad
        title="กรุณากดรหัสผ่าน"
        pin={this.state.pin}
        limitDisplayNumber={6}
        savePin={this.savePin}
        onPressBack={this.prevStep}
        onPressSubmit={this.submitPin.bind(this)} />
    );
  }

  showPadByStep() {
    switch (this.state.step) {
      case 1: return this.getPhonePad();
      case 2: return this.getPinPad();
      default: return this.getPhonePad();
    }
  }

  render() {
    let contractor = this.props.contractor.toJS();
    let auth = this.props.auth.toJS();

    return (
      <div>
        <Loader show={contractor.isLoading || auth.isLoading} />
        {this.showPadByStep()}
      </div>
    );
  }
}

export default inject('contractor', 'auth')(observer(ContractorSignIn));

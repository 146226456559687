import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import * as _ from 'lodash';
// import Swal from 'sweetalert2';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Select from 'react-select';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import Pagination from '../../components/common/Pagination';
import EnButton from '../../components/form/EnButton';
import EnDatePicker from '../../components/form/EnDatePicker';
import EnToggle from '../../components/form/EnToggle';
import FormGroup from '../../components/form/FormGroup';
import AuthStore from '../../stores/AuthStore';
import config from '../../config';
import { JobStatuses } from '../../utils/data';

import '../../assets/css/joblist.css';
import { isAllowPermission } from '../../utils/permission';
import { toast } from 'react-toastify';

export class JobList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      store_id: AuthStore.getStoreId(),
      statuses: JobStatuses,
      contractors: [],
      // channel: ''
    };
  }

  async componentWillMount() {
    try {
      let user = this.props.auth.getUserInfo();
      const isBuAccount = this.props.auth.isBuAccount();
      const channel = this.props.auth.getChannelName() || '';
      if (this.state.store_id) {
        this.props.joblist.setQueryValue('store_id', this.state.store_id);
      }
      if (isBuAccount && channel) {
        this.props.joblist.setQueryValue('channel', channel);
      }
      await this.props.typeofwork.getTypeOfWorksByStoreId(this.state.store_id);
      const contractorGetAllData = await this.props.contractor.getAll({ filterByArea: false, jobList: true });
      this.setState({contractors: contractorGetAllData})
    } catch (err) {
      toast.error(err.message);
    }
  }

  componentDidMount = async () => {
    const { contractorgrade: contractorGradeStore } = this.props;
    await contractorGradeStore.initGradeMap();
  }

  onJobCodeClick(id, e) {
    e.preventDefault();
    let uri;
    if (AuthStore.isSuperAdmin()) {
      uri = `${config.publicUrl}/superadmin/jobs/${id}`;
    } else {
      uri = `${config.publicUrl}/admin/calendar/jobs/${id}`;
    }
    this.props.history.push(uri);
  }

  onEditJobClick(id, e) {
    e.preventDefault();
    this.props.history.push(`${config.publicUrl}/admin/jobs/${id}/note`);
  }

  onInstallationRequestDate(id, e) {
    e.preventDefault();
    this.props.history.push(`${config.publicUrl}/admin/jobs/${id}/installationdate`);
  }

  async onPageClick(pageNum) {
    this.props.joblist.setQueryValue('page', pageNum);
    await this.props.joblist.searchJobs();
  }

  async onSearchClick(e) {
    this.props.joblist.setQueryValue('page', 1);
    await this.props.joblist.searchJobs();
  }

  async onClearSearchClick(e) {
    this.props.joblist.setDefaultQuery(this.state.store_id);
    await this.props.joblist.searchJobs();
  }

  handleQueryChange(key, event) {
    this.props.joblist.setQueryValue(key, event.target.value);
  }

  handleActiveCheck(key, value) {
    this.props.joblist.setQueryValue(key, value);
  }

  handleFreeSurveyPaidCheck(key, event) {
    const value = event ? false : undefined;
    this.props.joblist.setQueryValue(key, value);
    this.props.joblist.setQueryValue('type_of_job', 'S');
  }

  handleDateChange(key, date) {
    this.props.joblist.setQueryValue(key, date.toDate());
  }

  getTypeOfJob(typeOfJob) {
    return typeOfJob === 'S'
      ? <div>
        <i className="fa fa-search" aria-hidden="true" /> สำรวจ
      </div>
      : <div>
        <i className="fa fa-gavel" aria-hidden="true" /> ติดตั้ง
      </div>;
  }

  getTypeOfWorkText(typeOfWorks, typeOfWorkItems) {
    if (Array.isArray(typeOfWorks) && typeOfWorks.length) {
      const typeOfWork = +typeOfWorks[0];
      const item = typeOfWorkItems.find(tow => tow.value === typeOfWork);
      if (item) return item.name;
    }
    return '';
  }

  getAvailableTime(available) {
    switch (available) {
      case 0:
        return (
          <div>
            <i className="fa fa-clock-o" aria-hidden="true" style={{ color: '#A569BD' }} /> ทั้งวัน
          </div>
        );
      case 1:
        return (
          <div>
            <i className="fa fa-clock-o" aria-hidden="true" style={{ color: '#5DADE2' }} /> เช้า
          </div>
        );
      case 2:
        return (
          <div>
            <i className="fa fa-clock-o" aria-hidden="true" style={{ color: '#F5B041' }} /> บ่าย
          </div>
        );
      default: return '';
    }
  }

  getStatus(status) {
    switch (status) {
      case 'created': return <span className="status status-created">สร้างใหม่</span>;
      case 'assigned': return <span className="status status-assigned">ได้รับมอบหมาย</span>;
      case 'survey_started': return <span className="status status-survey-started">เริ่มสำรวจ</span>;
      case 'survey_finished': return <span className="status status-survey-finished">สิ้นสุดการสำรวจ</span>;
      case 'quotation_management': return <span className="status status-survey-finished">ใบเสนอราคา</span>;
      case 'installation_requested': return <span className="status status-installation-requested">นัดติดตั้ง</span>;
      case 'installation_confirmed': return <span className="status status-installation-confirmed">ยืนยันการติดตั้ง</span>;
      case 'installation_started': return <span className="status status-installation-started">เริ่มการติดตั้ง</span>;
      case 'installation_finished': return <span className="status status-installation-finished">สิ้นสุดการติดตั้ง</span>;
      case 'installation_accepted': return <span className="status status-installation-accepted">ลูกค้ายอมรับ</span>;
      case 'installation_rejected': return <span className="status status-installation-rejected">ลูกค้าปฏิเสธ</span>;
      case 'paid': return <span className="status status-paid">ชำระเงิน</span>;
      case 'deleted': return <span className="status status-deleted">ย้ายงาน/Inactive</span>;
      case 'paid_to_contractor': return <span className="status status-paid-to-contractor">จ่ายเงินให้ช่างแล้ว</span>;
      case 'request_quotation': return <span className="status status-request-quotation">รอทำใบเสนอราคา</span>;
      case 'exists_quotation': return <span className="status status-exists-quotation">สร้างใบเสนอราคาแล้ว</span>;
      case 'contractor_no_view': return <span className="status status-contractor_no_view">ช่างยังไม่ทราบ</span>;
      default: return '';
    }
  }

  displaySearchForm(query, statuses = [], typeOfWorks = [], contractors = []) {
    const tow = _.orderBy(typeOfWorks, ['name']).map((item) => {
      return {
        label: item.name,
        value: item.value,
      };
    });
    const con = _.orderBy(contractors, ['team_name']).map((item) => {
      return {
        label: item.team_name,
        value: item._id,
      };
    });

    return (
      <form className="form-horizontal">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-md-2 control-label" htmlFor="jobCode">รหัสอ้างอิง</label>
              <div className="col-md-10">
                <input type="text"
                  className="form-control"
                  id="jobCode"
                  placeholder="Q-xxxxxxxxxx"
                  value={query.code}
                  onChange={this.handleQueryChange.bind(this, 'code')}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="col-md-2 control-label" htmlFor="customerName">ชื่อลูกค้า</label>
              <div className="col-md-10">
                <input type="text"
                  className="form-control"
                  id="customerName"
                  placeholder=""
                  value={query.customer_name}
                  onChange={this.handleQueryChange.bind(this, 'customer_name')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-md-2 control-label" htmlFor="typeOfJob">ลักษณะงาน</label>
              <div className="col-md-10">
                <select className="form-control" id="typeOfJob" value={query.type_of_job} onChange={this.handleQueryChange.bind(this, 'type_of_job')}>
                  <option value="">--- เลือก ---</option>
                  <option value="S">สำรวจ</option>
                  <option value="I">ติดตั้ง</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-md-2 control-label" htmlFor="typeOfJob">ประเภทงาน</label>
              <div className="col-md-10">
                <Select
                  multi={false}
                  value={tow.find((item) => item.value === query.type_of_work)}
                  options={tow}
                  className="select-custom1"
                  onChange={(item) => { this.props.joblist.setQueryValue('type_of_work', (item || {}).value || ''); }}
                  placeholder="เลือก..."
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-md-2 control-label" htmlFor="contractor">ทีมช่าง</label>
              <div className="col-md-10">
                <Select
                  multi={false}
                  value={con.find((item) => item.value === query.contractor_id)}
                  options={con}
                  className="select-custom1"
                  onChange={(item) => { this.props.joblist.setQueryValue('contractor_id', (item || {}).value); }}
                  placeholder={con.length > 0 ? "เลือก..." : "กำลังค้นหาทีมช่าง..."}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-md-2 control-label" htmlFor="status">สถานะ</label>
              <div className="col-md-10">
                <select className="form-control" id="status" value={query.status} onChange={this.handleQueryChange.bind(this, 'status')}>
                  <option value="">--- เลือก ---</option>
                  {statuses.map((s, index) => <option key={`js-${index}`} value={s.value}>{s.name}</option>)}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-md-2 control-label" htmlFor="startDate">วันที่เริ่ม</label>
              <div className="col-md-10">
                <EnDatePicker id="startDate"
                  initialDate={query.start_date ? moment(query.start_date) : undefined}
                  onDateChange={this.handleDateChange.bind(this, 'start_date')}
                  isOutsideRange={() => false}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-md-2 control-label" htmlFor="endDate">วันที่สิ้นสุด</label>
              <div className="col-md-10">
                <EnDatePicker id="endDate"
                  initialDate={query.end_date ? moment(query.end_date) : undefined}
                  onDateChange={this.handleDateChange.bind(this, 'end_date')}
                  isOutsideRange={() => false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-md-2 control-label" htmlFor="availableTime">ช่วงเวลา</label>
              <div className="col-md-10">
                <select className="form-control" id="availableTime" value={query.available_time} onChange={this.handleQueryChange.bind(this, 'available_time')}>
                  <option value="">--- เลือก ---</option>
                  <option value="0">ทั้งวัน</option>
                  <option value="1">เช้า</option>
                  <option value="2">บ่าย</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="col-md-2 control-label" htmlFor="saleModel">Model การขาย</label>
              <div className="col-md-10">
                <select className="form-control" id="saleModel" value={query.sale_model} onChange={this.handleQueryChange.bind(this, 'sale_model')}>
                  <option value="">--- เลือก ---</option>
                  <option value="MarketPlace">MarketPlace</option>
                  <option value="Retail">Retail</option>
                  <option value="Claim">Claim</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row col-md-12 col-md-offset-1">
            <FormGroup containerClassName="col-md-2" label="Active" >
              <EnToggle checked={query.is_active} onChange={this.handleActiveCheck.bind(this, 'is_active')} />
            </FormGroup>
            <FormGroup containerClassName="col-md-2" label="ยังไม่ทำใบเคลม" >
              <EnToggle checked={query.is_no_claim_doc} onChange={this.handleActiveCheck.bind(this, 'is_no_claim_doc')} />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div className="col-md-offset-2 col-md-10">
                {/* <label>
                  Active: &nbsp;
                  <input
                    name="activeFlag"
                    type="checkbox"
                    defaultChecked={query.is_active}
                    // checked={query.is_active}
                    onChange={this.handleActiveCheck.bind(this, 'is_active')}
                  />
                </label> */}
                <EnButton className="btn-warning pull-right" onClick={this.onClearSearchClick.bind(this)}>
                  <i className="fa fa-refresh btn-icon" aria-hidden="true" />เคลียร์
                </EnButton>
                <EnButton className="btn-info pull-right" onClick={this.onSearchClick.bind(this)}>
                  <i className="fa fa-search btn-icon" aria-hidden="true" />ค้นหา
                </EnButton>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

  getDisplayedGradeByTypeOfWork = ({ contractorId, contractorGrades = [], jobTypeOfWorks }) => {
    const { contractorgrade: contractorGradeStore } = this.props;
    let originalGrade = '';

    if (contractorId && Array.isArray(contractorGrades)) {
      const contractorGrade = contractorGrades.find(({ type_of_work }) => type_of_work === _.head(jobTypeOfWorks));
      originalGrade = contractorGrade ? contractorGrade.grade : '';
    }

    return contractorGradeStore.getDisplayedGrade(originalGrade);
  };

  displayTableRows(jobs, typeOfWorks, statusSearch) {
    let prefixUri;
    if (AuthStore.isSuperAdmin()) {
      prefixUri = `${config.publicUrl}/superadmin/jobs`;
    } else {
      prefixUri = `${config.publicUrl}/admin/calendar/jobs`;
    }

    return jobs.map((j, index) => {
      const displayedGrade = this.getDisplayedGradeByTypeOfWork({
        contractorId: j.contractor_id,
        contractorGrades: j.contractor_grade,
        jobTypeOfWorks: j.type_of_work,
      });
      // switch (statusSearch) {
      //   case 'request_quotation': j.status = 'request_quotation'; break;
      //   case 'paid_to_contractor': j.status = 'paid_to_contractor'; break;
      // }
      return (
        <tr key={index}>
          <td className="text-center">
            {
              isAllowPermission(j.is_active, 'VISIT_JOB_CALENDAR_BY_REF_NO')
                // ? (<a href="" title={j.job_code} target="_blank" onClick={this.onJobCodeClick.bind(this, j._id)}>{j.job_code || j._id}</a>)
                ? (<a href={`${prefixUri}/${j._id}`} title={j.job_code} target="_blank">{j.job_code || j._id}</a>)
                : <span>{j.job_code || j._id}</span>
            }
          </td>
          <td className="text-center">{j.sale_model}</td>
          <td className="text-center">{this.getTypeOfJob(j.type_of_job)}</td>
          <td>{this.getTypeOfWorkText(j.type_of_work, typeOfWorks)}</td>
          <td>{j.contractor_name || ''}</td>
          <td className="text-center">{displayedGrade}</td>
          <td className="text-center">{j.contractor_phone || ''}</td>
          <td>{`${j.customer_firstname || ''} ${j.customer_lastname || ''}`}</td>
          <td className="text-center">{j.customer_phone || ''}</td>
          <td className="text-center">{this.getAvailableTime(+j.available)} {j.time_label || ''}</td>
          <td className="text-center">{momentTz(j.start_date).utcOffset(7).format('ddd Do/M/YYYY')}</td>
          <td className="text-center">{momentTz(j.end_date).utcOffset(7).format('ddd Do/M/YYYY')}</td>
          <td className="text-center">{j.product_qty || '-'}</td>
          <td className="text-center">
            {this.getStatus(j.status)}
          </td>
          <td className="text-center">
            { j.effective_date ? momentTz(j.effective_date).utcOffset(7).format('ddd Do/M/YYYY') : ''}<br></br>
            { j.payout_channel ? j.payout_channel : ''}
          </td>
          <td className="text-center">
            {(j.status !== 'deleted' && j.is_active) &&
              <a href="" title="แก้ไข" onClick={this.onEditJobClick.bind(this, j._id)}>
                <i className="fa fa-pencil-square icon-button" aria-hidden="true" />
              </a>
            }{
              (j.status === 'request_quotation' && !j.is_active) &&
              <a href="" title="กู้คืน" onClick={this.onInstallationRequestDate.bind(this, j._id)}>
                <i className="fa fa-undo" aria-hidden="true" />
              </a>
            }
          </td>
        </tr>
      );
    });
  }

  render() {
    const { data, query, pagination, isLoading, statusSearch } = this.props.joblist.toJS();
    const { statuses } = this.state;
    const typeOfWorks = this.props.typeofwork.toJS().items;

    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ค้นหาคิวงาน</h4>
              </div>
              <div className="card-content">
                {this.displaySearchForm(query, statuses, typeOfWorks, this.state.contractors)}
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="10%" className="text-center" style={{ minWidth: '110px' }}>เลขอ้างอิง</th>
                            <th width="10%" className="text-center" style={{ minWidth: '100px' }}>Model การขาย</th>
                            <th width="7%" className="text-center" style={{ minWidth: '80px' }}>ลักษณะงาน</th>
                            <th width="7%" className="text-center" style={{ minWidth: '150px' }}>ประเภทงาน</th>
                            <th width="7%" className="text-center" style={{ minWidth: '125px' }}>ชื่อทีมช่าง</th>
                            <th width="7%" className="text-center" style={{ minWidth: '50px' }}>เกรดช่าง</th>
                            <th width="7%" className="text-center" style={{ minWidth: '100px' }}>เบอร์ติดต่อช่าง</th>
                            <th width="7%" className="text-center" style={{ minWidth: '125px' }}>ชื่อลูกค้า</th>
                            <th width="7%" className="text-center" style={{ minWidth: '110px' }}>เบอร์ติดต่อลูกค้า</th>
                            <th width="7%" className="text-center" style={{ minWidth: '100px' }}>ช่วงเวลา</th>
                            <th width="7%" className="text-center" style={{ minWidth: '100px' }}>วันที่เริ่ม</th>
                            <th width="7%" className="text-center" style={{ minWidth: '100px' }}>วันที่สิ้นสุด</th>
                            <th width="7%" className="text-center" style={{ minWidth: '90px' }}>จำนวนสินค้า</th>
                            <th width="15%" className="text-center" style={{ minWidth: '130px' }}>สถานะ</th>
                            <th width="7%" className="text-center" style={{ minWidth: '100px' }}>วันที่ช่างได้รับเงิน</th>
                            <th width="5%" className="text-center" style={{ minWidth: '50px' }} />
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayTableRows(data, typeOfWorks, statusSearch)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      pagination={pagination}
                      handlePageClick={this.onPageClick.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('joblist', 'typeofwork', 'contractor', 'job', 'auth', 'contractorgrade')(observer(JobList));

import React from 'react';
import { observer, inject } from 'mobx-react';
import { FormValidation } from '../../../components/form/FormValidation';
import EnPicture from '../../../components/form/EnPicture';
import EnButton from '../../../components/form/EnButton';
import Loader from '../../../components/common/Loader';
import config from '../../../config';
import { toast } from 'react-toastify';

export class ProfilePicture extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: undefined,
    };
  }

  savePicture(file) {
    this.setState({
      file,
    });
  }

  validation() {
    if (this.picture) {
      let obj = this.picture.getFile();

      return obj && obj.file;
    } else {
      return false;
    }
  }

  async clickSubmit(e) {
    try {
      this.props.contractor.validateInput(this)
        .then(async () => {
          try {
            let contractorId = await this.props.contractor.uploadImage(this.state.file);
            toast.success('ลงทะเบียนเรียบร้อย');
            if (contractorId) {
              setTimeout(() => {
                this.props.history.replace(`${config.publicUrl}/contractors/${contractorId}/calendar`);
              }, 1000);
            } else {
              this.props.history.replace(`${config.publicUrl}/contractors/signin`);
            }
          } catch (error) {
            toast.error(error.message || 'บันทึกข้อมูลล้มเหลว');
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (err) {
      toast.error(err.message);
    }
  }

  render() {
    let contractor = this.props.contractor.toJS();
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-offset-2 col-md-8">
              <div className="card card-profile" style={{ marginTop: 80 }}>
                <Loader show={contractor.isRegistering} />
                <FormValidation ref="FormValidation">
                  <div className="card-avatar">
                    <EnPicture ref={(e) => { this.picture = e; }} onChange={this.savePicture.bind(this)} />
                  </div>
                  <div className="content">
                    <h4>กดที่รูปเพื่ออัพโหลดรูปภาพ</h4>
                    <p className="text-center">*โดยรูปนี้จะถูกนำไปใช้เพื่อนำไปใช้แสดงในหน้าจอของเจ้าของบ้าน เมื่อท่านรับงานผ่านระบบ</p>
                    <EnButton className="btn btn-info center" onClick={this.clickSubmit.bind(this)}>
                      <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                    </EnButton>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('contractor')(observer(ProfilePicture));

import React from 'react';
import { observer, inject } from 'mobx-react';
import { Tab } from 'react-bootstrap';
import Select from 'react-select';
import swal from 'sweetalert2';
import { get, isUndefined } from 'lodash';

import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';

import { EnTab } from '../../components/form/EnTab';
import { FormValidation, phone, idcard } from '../../components/form/FormValidation';

import config from '../../config';
import EnButton from '../../components/form/EnButton';
import moment from 'moment';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import { isAllow, isRole, ROLE } from '../../utils/permission';
import { 
  getDayOfBirthOptions,
  getMonthOfBirthOptions, 
  getYearOfBirthOptions, 
  getDateOfBirthFlag
} from '../../utils/data';
import UploadFileV2 from '../../components/form/UploadFileV2';
import { ToggleApprove } from '../admin/AdminApproveContractor';
import { toast } from 'react-toastify';

export class SubContractorEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      activeIndex: 0,
      editMode: isRole(ROLE.INSTALLER_MANAGEMENT),
      hasDraft: false,
      disabledSaveButton: true,
      disabledApproveRejectDraft: true,
      disabledAllField: true,
      dayOfBirth: '',
      monthOfBirth: '',
      yearOfBirth: '',
    };
  }

  async componentWillMount() {
    this.props.subContractor.clearQuery();
    this.props.subContractor.clearState();

    const { id } = this.state;

    if (!id) {
      this.props.subContractor.clearInfo();
      this.props.history.push(`${config.publicUrl}/superadmin/sub-contractors/`);
    }

    await this.props.misc.getAllProvince();
    await this.props.shirt.getAllShirtSize();

    const province = this.props.misc.toJS().miscData.province || [];
    const setProvince = province.map((item) => ({ label: item.name, value: item.code }));
    this.props.subContractor.setOptions('province', [{ label: 'กรุณาเลือกจังหวัด', value: '' }, ...setProvince]);

    const subContractor = await this.props.subContractor.fetchById(id);
    if (subContractor) {
      //select date of birth draft
      const { selectedInfoDraft } = this.props.subContractor.toJS();
      const dateOfBirth = get(selectedInfoDraft, 'draft_data.date_of_birth', subContractor.date_of_birth);

      this.setState({ 
        hasDraft: subContractor.has_draft, 
        dayOfBirth: subContractor.date_of_birth_flag === 1 || subContractor.date_of_birth_flag === 2 ? '--' : moment(dateOfBirth).format('DD'),
        monthOfBirth: subContractor.date_of_birth_flag === 2 || subContractor.date_of_birth_flag === 3 ? '--' : moment(dateOfBirth).format('MM'),
        yearOfBirth: subContractor.date_of_birth ? moment(dateOfBirth).format('YYYY') : ''
      });
    }

    const validate = this.isValidInfo();
    this.setState({
      disabledAllField: isAllow('VIEW_SUBCONTRACTOR_DATA') && !isAllow('EDIT_SUBCONTRACTOR_DATA'), 
      disabledSaveButton: !validate || !isAllow('EDIT_SUBCONTRACTOR_DATA'), 
      disabledApproveRejectDraft: !isAllow('APPROVE_OR_REJECT_SUBCONTRACTOR_DATA'),
    });
  }

  // VALIDATION
  isValidInfo = () => {
    const { selectedInfo } = this.props.subContractor.toJS();
    if (!selectedInfo) {
      toast.error('ไม่พบข้อมูล');
      return false;
    }

    if (!selectedInfo.profile_pic) {
      toast.error('กรุณาเลือกรูปประจำตัว');
      return false;
    }

    if (!selectedInfo.first_name) {
      toast.error('กรุณากรอกชื่อ');
      return false;
    }

    if (!selectedInfo.last_name) {
      toast.error('กรุณากรอกนามสกุล');
      return false;
    }

    if (!selectedInfo.id_card_no) {
      toast.error('กรุณากรอกหมายเลขบัตรประชาชน');
      return false;
    }

    if (!selectedInfo.date_of_birth) {
      toast.error('กรุณากรอกวัน/เดือน/ปีเกิด');
      return false;
    }

    if (!selectedInfo.nationality) {
      toast.error('กรุณาเลือกสัญชาติ');
      return false;
    }

    if (!selectedInfo.contact_phone_no) {
      toast.error('กรุณากรอกเบอร์ติดต่อ');
      return false;
    }

    if (!selectedInfo.criminal_record_check_status) {
      toast.error('กรุณาเลือกสถานะการตรวจประวัติอาชญากรรม');
      return false;
    }

    // File
    if (selectedInfo.criminal_record_check_status === '0') {
      const CriminalRecordCheck = selectedInfo.file.filter((item) => item.type === 'criminal_record_check');
      if (!CriminalRecordCheck.length) {
        toast.error('กรุณาอัพโหลดไฟล์หลักฐานการตรวจประวัติอาชญากรรม');
        return false;
      }
    }

    const IdCard = selectedInfo.file.filter((item) => item.type === 'id_card');
    if (!IdCard.length) {
      toast.error('กรุณาอัพโหลดไฟล์บัตรประชาชน');
      return false;
    }

    // validate format
    let regex = new RegExp('^[0-9]{10,10}$');
    if (!regex.test(selectedInfo.contact_phone_no)) {
      return false;
    }

    regex = new RegExp('^[0-9]{13,13}$');
    if (!regex.test(selectedInfo.id_card_no)) {
      return false;
    }

    return true;
  };

  // EVENT
  onChange = (key, event) => {
    const value = event.target ? event.target.value : event;

    if (key === 'date_of_birth') {
      this.props.subContractor.setSelectedInfo(key, value.format('YYYY-MM-DD'));
    } else {
      this.props.subContractor.setSelectedInfo(key, value);
    }

    if (value.length <= 1) {
      const validate = this.isValidInfo();
      this.setState({ disabledSaveButton: !validate });
    }
  };

  onSelect = (value, type) => {
    if (type === 'carIdProvince') {
      const provinces = this.props.misc.toJS().miscData.province || [];
      const provinceSelected = provinces.find((item) => item.name === value);
      this.props.subContractor.onChangeAddressType({
        name: provinceSelected ? provinceSelected.name : '',
        code: provinceSelected ? provinceSelected.code : '',
      });
    }

    if (type === 'criminalStatusType') {
      this.props.subContractor.setSelectedInfo('criminal_record_check_status', value);
    }

    if (type === 'nationality') {
      this.props.subContractor.setSelectedInfo('nationality', value);
    }

    if(type === 'shirt_size'){
      this.props.subContractor.setSelectedInfo('shirt_size', value);
    }

    if(type === 'dayOfBirth' || type === 'monthOfBirth' || type === 'yearOfBirth') {
      this.setState({[type]: value});
    }

    const validate = this.isValidInfo();
    this.setState({ disabledSaveButton: !validate });
  };

  onCancel = () => {
    this.props.history.go(-1);
  };

  onApproveProfilePic = (value) => {
    this.props.subContractor.setSelectedInfo('is_profile_pic_approved', value);
  }

  onSubmit = async () => {
    try {
      const user = this.props.auth.getUserInfo();
      const { selectedInfo } = this.props.subContractor.toJS();
      const { id } = this.state;

      const { dayOfBirth, monthOfBirth } = this.state;
      this.state.dayOfBirth = this.state.dayOfBirth === '--'? '01': this.state.dayOfBirth;
      this.state.monthOfBirth = this.state.monthOfBirth === '--'? '01': this.state.monthOfBirth;
      const dateOfBirth = moment(`${this.state.dayOfBirth}-${this.state.monthOfBirth}-${this.state.yearOfBirth}`, 'DD-MM-YYYY');
    
      if (!this.isValidInfo()) {
        return;
      }
      await this.props.subContractor.update(id, { 
        ...selectedInfo, 
        user: user.email, 
        date_of_birth: dateOfBirth.format('YYYY-MM-DD'),
        date_of_birth_flag: getDateOfBirthFlag(dayOfBirth, monthOfBirth),
      });
      toast.success('บันทึกข้อมูลสำเร็จ');
      window.location.reload();
    } catch (error) {
      toast.error(error.message || 'เกิดข้อมผิดพลาด');
    }
  };

  onApproveOrReject = (status) => {
    try {
      const user = this.props.auth.getUserInfo();

      const { selectedInfoDraft } = this.props.subContractor.toJS();
      const { id } = this.state;

      if (status === 'approved') {
        return swal
          .fire({
            title: 'อนุมัติการแก้ไข',
            text: 'ท่านต้องการอนุมัติการแก้ไขข้อมูลของช่างคนดังกล่าวใช่หรือไม่',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'อนุมัติการแก้ไข',
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#d33',
          })
          .then(async (result) => {
            if (result.value) {
              await this.props.subContractor.approveOrRejectDraft(selectedInfoDraft._id, {
                draft_status: status,
                updated_by: user.email || 'Admin-Qchang',
              });
              toast.success('บันทึกข้อมูลสำเร็จ');
              window.location.reload();
            }
          });
      }

      if (status === 'rejected') {
        return swal
          .fire({
            title: 'ปฏิเสธการแก้ไข',
            text: 'กรุณาระบุเหตุผลในการปฏิเสธการแก้ไขข้อมูลของช่างคนดังกล่าว',
            input: 'textarea',
            inputPlaceholder: 'กรุณาระบุเหตุผล',
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#d33',
            onRender: (dom) => {
              // if textarea is empty, disable confirm button
              const textarea = dom.querySelector('textarea');
              const confirmButton = dom.querySelector('.swal2-confirm');
              confirmButton.setAttribute('disabled', 'disabled');

              textarea.addEventListener('input', () => {
                if (textarea.value) {
                  confirmButton.removeAttribute('disabled');
                } else {
                  confirmButton.setAttribute('disabled', 'disabled');
                }
              });
            },
          })
          .then(async (result) => {
            if (result.value) {
              await this.props.subContractor.approveOrRejectDraft(selectedInfoDraft._id, {
                draft_status: status,
                updated_by: user.email || 'Admin-Qchang',
                reason: result.value,
              });
              toast.success('บันทึกข้อมูลสำเร็จ');
              window.location.reload();
            }
          });
      }
    } catch (error) {
      toast.error(error.message || 'เกิดข้อมผิดพลาด');
    }
  };

  onBlur = () => {
    const validate = this.isValidInfo();
    this.setState({ disabledSaveButton: !validate });
  }

  // ADD OR DELETE FILE
  onAddFile(files, type) {
    if (!files || !files.length) return;

    const isOverLimit = this.limitFileSize(files);
    if (isOverLimit) {
      return toast.error('ไฟล์ทั้งหมดมีขนาดใหญ่เกิน 10 MB');
    }

    for (const file of files) {
      const reader = new FileReader();
      reader.onload = async () => {
       await this.props.subContractor.uploadFile(this.state.id, file, type);

       const validate = this.isValidInfo();
       this.setState({ disabledSaveButton: !validate });
      };
      reader.readAsDataURL(file);
    }

    //set toggle to false if upload new profile pic
    if (type === this.props.subContractor.fileTypes.profile_pic) {
      this.props.subContractor.setSelectedInfo('is_profile_pic_approved', false);
    }
  }

  onDeleteFile = (file) => {
    const { selectedInfo } = this.props.subContractor.toJS();
    const files = selectedInfo.file.filter((item) => item.url !== file.url);
    this.props.subContractor.removeFile(files);

    // clear file input value
    const input = document.getElementsByName(file.type);
    if (input && input.length) {
      input[0].value = '';
    }

    const validate = this.isValidInfo();
    this.setState({ disabledSaveButton: !validate });
  };

  limitFileSize(files) {
    // 10 mb limit
    const limitSize = 10 * 1024 * 1024;
    let isOverLimit = 0;

    for (const file of files) {
      isOverLimit += file.size;
    }

    return isOverLimit > limitSize;
  }

  // MAPPING
  mapCriminalStatusType = (types, value) => {
    return types.find((item) => item.value === value);
  };

  genShirtSizeOptions = () => {
    const { firstSelectShirt } = this.props.subContractor.toJS().selectedInfo;
    if( isUndefined(firstSelectShirt) ) return;
    
    const options = (this.props.shirt.toJS().info || []).map((s) => {
      return {
        label: `${s.size} ( ${s.desc} )`,
        value: s.size,
      };
    });

    return [
      firstSelectShirt && { label: 'กรุณาระบุไซส์เสื้อ', value: '' },
      ...options,
    ];
  }

  render() {
    const { isLoading, selectedInfo, selectedInfoDraft, fileTypes, options } = this.props.subContractor.toJS();

    const { editMode, hasDraft, disabledApproveRejectDraft } = this.state;

    const draftData = (selectedInfoDraft && selectedInfoDraft.draft_data) || {};

    const saveDisabled = isLoading || this.state.disabledSaveButton;
    const fieldDisabled = hasDraft || this.state.disabledAllField;

    const approveProfilePic = draftData.is_profile_pic_approved ? draftData.is_profile_pic_approved : get(selectedInfo, 'is_profile_pic_approved', false);

    if (!selectedInfo) {
      return (
        <Container isAdmin>
          <Loader show={true} />;
        </Container>
      )
    }

    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แก้ไขข้อมูลช่าง</h4>
              </div>

              <div className="card-content">
                <FormValidation>
                  <EnTab activeIndex={this.state.activeIndex} lazyLoad={true}>
                    <Tab title="ข้อมูลส่วนตัว">
                      <div className="card-content" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <div className="seo">
                          <div className="header-seo">ข้อมูลส่วนตัว</div>
                          <div className="seo-form">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="col-md-5 form-group">
                                  <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                      <label className="control-label">รูปประจำตัว *</label>

                                      {/* DRAFT MODE */}
                                      {hasDraft && draftData.profile_pic && (
                                        <i style={{ paddingLeft: '5px' }} className="fa fa-thumb-tack" aria-hidden="true"></i>
                                      )}
                                    </div>

                                    {/* EDIT MODE */}
                                    {!hasDraft && editMode && (
                                      <label className="control-label" style={{ cursor: 'pointer', color: '#265ed6' }}>
                                        <span>แก้ไข </span>
                                        <i className="fa fa-pencil" aria-hidden="true" />
                                        <input
                                          name={fileTypes.profile_pic}
                                          type="file"
                                          multiple={false}
                                          accept="image/*,image/x-raw,image/heic,.raw"
                                          style={{ display: 'none' }}
                                          onChange={(event) => this.onAddFile(event.target.files, fileTypes.profile_pic)}
                                        />
                                      </label>
                                    )}
                                  </div>
                                  <div className="" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <ToggleApprove
                                      isApprove={approveProfilePic}
                                      disabled={!isAllow("APPROVE_OR_REJECT_PROFILE_PIC")}
                                      checked={approveProfilePic}
                                      onChange={this.onApproveProfilePic.bind(this)}
                                    />
                                    <img
                                      style={{ width: '250px', height: '250px' }}
                                      src={draftData.profile_pic ? draftData.profile_pic : selectedInfo.profile_pic}
                                      className=""
                                      alt="profile"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-1"></div>

                                <div className="col-md-6">
                                  <EnTextFormGroup
                                    style={{ padding: '0px' }}
                                    type="text"
                                    containerClassName="col-md-12"
                                    label="เลขประจำตัวช่าง"
                                    value={draftData.ctr_code ? draftData.ctr_code : selectedInfo.ctr_code || ''}
                                    disabled
                                  />

                                  <EnTextFormGroup
                                    icon={draftData.prefix && 'fa fa-thumb-tack'}
                                    style={{ padding: '0px' }}
                                    type="text"
                                    containerClassName="col-md-12"
                                    label="คำนำหน้า *"
                                    value={draftData.prefix ? draftData.prefix : selectedInfo.prefix}
                                    disabled
                                    // onChange={(event) => this.onChange('prefix', event)}
                                    onBlur={this.onBlur}
                                  />

                                  <EnTextFormGroup
                                    icon={draftData.first_name && 'fa fa-thumb-tack'}
                                    style={{ padding: '0px' }}
                                    type="text"
                                    containerClassName="col-md-12"
                                    label="ชื่อ *"
                                    value={draftData.first_name ? draftData.first_name : selectedInfo.first_name}
                                    onChange={(event) => this.onChange('first_name', event)}
                                    onBlur={this.onBlur}
                                    disabled={fieldDisabled}
                                  />

                                  <EnTextFormGroup
                                    icon={draftData.last_name && 'fa fa-thumb-tack'}
                                    style={{ padding: '0px' }}
                                    type="text"
                                    containerClassName="col-md-12"
                                    label="นามสกุล *"
                                    value={draftData.last_name ? draftData.last_name : selectedInfo.last_name}
                                    onChange={(event) => this.onChange('last_name', event)}
                                    onBlur={this.onBlur}
                                    disabled={fieldDisabled}
                                  />
                                </div>
                              </div>

                              {/* <div className="col-md-12">
                                <EnTextFormGroup
                                  icon={draftData.id_card_no && 'fa fa-thumb-tack'}
                                  type="text"
                                  containerClassName="col-md-6"
                                  label="หมายเลขบัตรประชาชน *"
                                  value={draftData.id_card_no ? draftData.id_card_no : selectedInfo.id_card_no}
                                  onChange={(event) => this.onChange('id_card_no', event)}
                                  onBlur={this.onBlur}
                                  disabled={!editMode || hasDraft}
                                />

                                <div className="form-group col-md-6">
                                  <label>วัน/เดือน/ปีเกิด *</label>
                                  {draftData.date_of_birth && <i style={{ paddingLeft: '5px' }} className="fa fa-thumb-tack" aria-hidden="true"></i>}

                                  <EnDatePicker
                                    id="date_of_birth"
                                    containerClassName="col-md-6"
                                    initialDate={draftData.date_of_birth ? moment(draftData.date_of_birth) : moment(selectedInfo.date_of_birth)}
                                    onDateChange={(event) => this.onChange('date_of_birth', event)}
                                    isOutsideRange={() => false}
                                    isBuddhistYear={false}
                                    disabled
                                  />
                                </div>
                              </div> */}


                              <div className="col-md-12">
                                <EnTextFormGroup
                                  icon={draftData.id_card_no && 'fa fa-thumb-tack'}
                                  type="text"
                                  containerClassName="col-md-6"
                                  label="หมายเลขบัตรประชาชน"
                                  value={draftData.id_card_no ? draftData.id_card_no : selectedInfo.id_card_no}
                                  onChange={(event) => this.onChange('id_card_no', event)}
                                  onBlur={this.onBlur}
                                  disabled={fieldDisabled}
                                  validations={[idcard]}
                                />
                                <div className="form-group col-md-2">
                                  <label>วันเกิด *</label>
                                  {draftData.date_of_birth && (
                                      <i style={{ paddingLeft: '5px' }} className="fa fa-thumb-tack" aria-hidden="true"></i>
                                  )}
                                  <Select
                                    className="approve-tow-select"
                                    searchable={false}
                                    options={getDayOfBirthOptions(this.state.monthOfBirth, this.state.yearOfBirth)}
                                    value={this.state.dayOfBirth}
                                    onChange={(event) => this.onSelect(event.value, 'dayOfBirth')}
                                    disabled={fieldDisabled}
                                  />
                                </div>
                                <div className="form-group col-md-2">
                                  <label>เดือนเกิด *</label>
                                  {draftData.date_of_birth && (
                                      <i style={{ paddingLeft: '5px' }} className="fa fa-thumb-tack" aria-hidden="true"></i>
                                  )}
                                  <Select
                                    className="approve-tow-select"
                                    searchable={false}
                                    options={getMonthOfBirthOptions()}
                                    value={this.state.monthOfBirth}
                                    onChange={(event) => this.onSelect(event.value, 'monthOfBirth')}
                                    disabled={fieldDisabled}
                                  />
                                </div>
                                <div className="form-group col-md-2">
                                  <label>ปีเกิด *</label>
                                  {draftData.date_of_birth && (
                                      <i style={{ paddingLeft: '5px' }} className="fa fa-thumb-tack" aria-hidden="true"></i>
                                  )}
                                  <Select
                                    className="approve-tow-select"
                                    searchable={false}
                                    options={getYearOfBirthOptions()}
                                    value={this.state.yearOfBirth}
                                    onChange={(event) => this.onSelect(event.value, 'yearOfBirth')}
                                    disabled={fieldDisabled}
                                  />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group col-md-6">
                                  <label>สัญชาติ *</label>

                                  {/* DRAFT MODE */}
                                  {draftData.nationality && <i style={{ paddingLeft: '5px' }} className="fa fa-thumb-tack" aria-hidden="true"></i>}

                                  <Select
                                    className="approve-tow-select"
                                    searchable={false}
                                    options={options.nationality}
                                    value={draftData.nationality ? draftData.nationality : selectedInfo.nationality}
                                    onChange={(event) => this.onSelect(event.value, 'nationality')}
                                    disabled={fieldDisabled}
                                  ></Select>
                                </div>

                                <EnTextFormGroup
                                  icon={draftData.main_contractor && 'fa fa-thumb-tack'}
                                  type="text"
                                  containerClassName="col-md-6"
                                  label="ชื่อทีม/ชื่อบริษัท *"
                                  value={draftData.main_contractor ? draftData.main_contractor.team_name : selectedInfo.main_contractor.team_name}
                                  onBlur={this.onBlur}
                                  disabled
                                />
                              </div>

                              <div className="col-md-12">
                                <EnTextFormGroup
                                  icon={draftData.phone && 'fa fa-thumb-tack'}
                                  type="text"
                                  containerClassName="col-md-6"
                                  label="เบอร์โทรศัพท์บัญชีผู้ใช้งาน *"
                                  value={draftData.phone ? draftData.phone : selectedInfo.phone}
                                  onChange={(event) => this.onChange('phone', event)}
                                  onBlur={this.onBlur}
                                  disabled
                                />

                                <EnTextFormGroup
                                  icon={draftData.contact_phone_no && 'fa fa-thumb-tack'}
                                  type="text"
                                  containerClassName="col-md-6"
                                  label="เบอร์ติดต่อ"
                                  value={draftData.contact_phone_no ? draftData.contact_phone_no : selectedInfo.contact_phone_no}
                                  onChange={(event) => this.onChange('contact_phone_no', event)}
                                  onBlur={this.onBlur}
                                  disabled={fieldDisabled}
                                  validations={[phone]}
                                />
                              </div>

                              <div className="col-md-12">
                                <EnTextFormGroup
                                  icon={get(draftData, 'car_id', null) !== null && 'fa fa-thumb-tack'}
                                  type="text"
                                  containerClassName="col-md-6"
                                  label="ทะเบียนรถ"
                                  value={get(draftData, 'car_id', null) !== null ? draftData.car_id : selectedInfo.car_id}
                                  onChange={(event) => this.onChange('car_id', event)}
                                  onBlur={this.onBlur}
                                  disabled={fieldDisabled}
                                />

                                <div className="form-group col-md-6">
                                  <label>จังหวัดทะเบียนรถ</label>

                                  {/* DRAFT MODE */}
                                  {draftData.car_id_province && (
                                    <i style={{ paddingLeft: '5px' }} className="fa fa-thumb-tack" aria-hidden="true"></i>
                                  )}

                                  <Select
                                    className="approve-tow-select"
                                    searchable={false}
                                    options={options.province}
                                    value={draftData.car_id_province ? draftData.car_id_province.code : selectedInfo.car_id_province.code || ''}
                                    onChange={(event) => this.onSelect(event.value, 'carIdProvince')}
                                    disabled={fieldDisabled}
                                  ></Select>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group col-md-6">
                                  <label>สถานะการตรวจประวัติอาชญากรรม *</label>

                                  {/* DRAFT MODE */}
                                  {draftData.criminal_record_check_status && (
                                    <i style={{ paddingLeft: '5px' }} className="fa fa-thumb-tack" aria-hidden="true"></i>
                                  )}

                                  <Select
                                    className="approve-tow-select"
                                    searchable={false}
                                    options={options.criminal_status.filter((item) => item.value !== '')}
                                    value={
                                      draftData.criminal_record_check_status
                                        ? this.mapCriminalStatusType(options.criminal_status, draftData.criminal_record_check_status)
                                        : this.mapCriminalStatusType(options.criminal_status, selectedInfo.criminal_record_check_status)
                                    }
                                    onChange={(event) => this.onSelect(event.value, 'criminalStatusType')}
                                    disabled={fieldDisabled}
                                  ></Select>
                                </div>
                                <div className="form-group col-md-6">
                                  <label>ไซส์เสื้อ</label>

                                  {draftData.shirt_size && (
                                      <i style={{ paddingLeft: '5px' }} className="fa fa-thumb-tack" aria-hidden="true"></i>
                                  )}

                                  <Select
                                    className="approve-tow-select"
                                    searchable={false}
                                    options={this.genShirtSizeOptions()}
                                    value={draftData.shirt_size ? draftData.shirt_size : get(selectedInfo, 'shirt_size', '')}
                                    onChange={(event) => this.onSelect(event.value, 'shirt_size')}
                                    disabled={fieldDisabled}
                                  />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="col-md-6">
                                  <UploadFileV2
                                    label="รูปบัตรประชาชน *"
                                    selectedInfo={selectedInfo}
                                    draftData={draftData}
                                    editMode={editMode}
                                    hasDraft={hasDraft}
                                    fileType={fileTypes.id_card}
                                    onAddFile={(files, type) => this.onAddFile(files, type)}
                                    onDeleteFile={(file) => this.onDeleteFile(file)}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <UploadFileV2
                                    label={`หลักฐานการตรวจประวัติอาชญากรรม ${selectedInfo.criminal_record_check_status === '0' ? '*' : ''}`}
                                    selectedInfo={selectedInfo}
                                    draftData={draftData}
                                    editMode={editMode}
                                    hasDraft={hasDraft}
                                    fileType={fileTypes.criminal_record_check}
                                    onAddFile={(files, type) => this.onAddFile(files, type)}
                                    onDeleteFile={(file) => this.onDeleteFile(file)}
                                  />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="col-md-6">
                                  <UploadFileV2
                                    label="หลักฐานงานไฟฟ้า"
                                    selectedInfo={selectedInfo}
                                    draftData={draftData}
                                    editMode={editMode}
                                    hasDraft={hasDraft}
                                    fileType={fileTypes.electricity_certificate}
                                    onAddFile={(files, type) => this.onAddFile(files, type)}
                                    onDeleteFile={(file) => this.onDeleteFile(file)}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <UploadFileV2
                                    label="เอกสารอื่นๆ"
                                    selectedInfo={selectedInfo}
                                    draftData={draftData}
                                    editMode={editMode}
                                    hasDraft={hasDraft}
                                    fileType={fileTypes.other_certificate}
                                    onAddFile={(files, type) => this.onAddFile(files, type)}
                                    onDeleteFile={(file) => this.onDeleteFile(file)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 d-flex" style={{ justifyContent: 'space-between' }}>
                            <EnButton className="btn btn-default" type="button" onClick={this.onCancel}>
                              ย้อนกลับ
                            </EnButton>

                            {hasDraft && (
                              <div>
                                <EnButton
                                  className="btn btn-danger"
                                  type="submit"
                                  // disabled={saveDisabled || !editMode}
                                  disabled={disabledApproveRejectDraft}
                                  onClick={() => this.onApproveOrReject('rejected')}
                                >
                                  ปฏิเสธการแก้ไข
                                </EnButton>
                                <EnButton
                                  className="btn btn-primary"
                                  type="submit"
                                  // disabled={saveDisabled || !editMode}
                                  disabled={disabledApproveRejectDraft}
                                  onClick={() => this.onApproveOrReject('approved')}
                                >
                                  อนุมัติการแก้ไข
                                </EnButton>
                              </div>
                            )}

                            {!hasDraft && (
                              <EnButton className="btn btn-primary" type="submit" onClick={this.onSubmit} disabled={saveDisabled}>
                                บันทึก
                              </EnButton>
                            )}
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </EnTab>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'contractor', 'subContractor', 'misc', 'shirt')(observer(SubContractorEdit));

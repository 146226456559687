import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import numeral from 'numeral';
import moment from 'moment';
import MobileDetect from 'mobile-detect';
// import { Checkbox } from 'react-bootstrap/lib';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import { FormValidation } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnButton from '../../components/form/EnButton';
import FormGroup from '../../components/form/FormGroup';
import EnDropDown from '../../components/form/EnDropDown';
import history from '../../utils/history';
import config from '../../config';
import common from '../../utils/common';
import authStore from '../../stores/AuthStore';
import * as _ from 'lodash';
import TextArea from '../../componentsV2/input/textArea';

export class PurchaseOrderForm extends React.Component {
  constructor(props) {
    super(props);
    const md = new MobileDetect(window.navigator.userAgent);
    this.state = {
      jobId: this.props.match.params.id,
      pdf: undefined,
      isMobileOrTablet: !!(md.mobile() || md.tablet()),
      requestTax: false,
      paymentChannel: '',
      typeEventClick: '',
      isEdit: false
    };
  }

  async componentWillMount() {
    const job = this.props.job.toJS().info.jobType;
    if (!job) {
      await this.props.job.getJobsById(this.state.jobId);
    }
    this.props.po.saveInfo('jobInfo', this.props.job.toJS().info);
    this.props.po.clearPoes();
    this.props.po.resetSelectedPoIndex();
    await this.props.po.loadPoByJobId(this.state.jobId);
    await this.props.po.calculateTotal();
    if (this.props.po.toJS().info.details[0].code !== '') {
      this.setState({ isEdit: true })
    }
  }

  async componentWillUnmount() {
    await this.props.po.clearItems();
  }

  validateForm(info) {
    const { contractor } = info;
    const nameError = contractor ? _.isNull(contractor.companyName) : true;
    const taxIdError = contractor ? _.isNull(contractor.taxNo) : true;
    const branchError = contractor ? _.isNull(contractor.branchNo) : true;
    const addrNoError = contractor ? _.isNull(contractor.address) : true;
    const phoneError = contractor ? _.isNull(contractor.phone) : true;
    return !nameError &&
      !taxIdError &&
      !addrNoError &&
      !phoneError;
    // !branchError &&
  }

  saveUam(data) {
    if (!this.state.isEdit) {
      let no = ""
      if (data.type_of_job === 'I') {
        no = data.po_installation[0].no
      } else {
        no = data.po_survey[0].no
      }
      const { userName } = this.props.permission.toJS()
      const typeJob = no.slice(0, 2).toLowerCase()
      const remark = `[${no}] is created manually by ${userName}`
      this.props.permission.setInfoLogActivity([this.saveModelEventLogs(`create_${typeJob}`, no, remark)])
    }
    this.props.permission.saveUamActivityLogs()
  }

  async onSubmit(verify) {
    // e.preventDefault();
    let status = false
    if (verify) {
      status = this.props.permission.getUserFromEmail()
      if (!status) {
        return
      }
    }
    const info = this.props.po.toJS().info;
    const isFormValid = this.validateForm(info);
    if (!isFormValid) {
      swal.fire({
        icon: 'error',
        title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        confirmButtonText: 'ตกลง',
      });
      return;
    }

    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขใบสั่งซื้อสินค้า' : 'กรุณายืนยันการสร้างใบสั่งซื้อสินค้า',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const data = await this.props.po.savePo(
            this.state.jobId,
            false,
            authStore.getUsernameOrEmail()
          );
          if (data && status) {
            this.saveUam(data.data)
          }
          return data;
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      this.onCancel();
    }
  }

  onCancel() {
    const isAdmin = authStore.getStore();
    if (!_.isNil(isAdmin) && !_.isEmpty(isAdmin)) {
      history.push(`${config.publicUrl}/calendar/admin/jobs/${this.state.jobId}`);
    } else {
      history.push(`${config.publicUrl}/superadmin/jobs/${this.state.jobId}`);
    }
  }

  onInfoChanged(key, e) {
    if (e.target) {
      this.props.po.saveInfo(key, e.target.value);
    }
  }

  onColumnInputChanged(index, key, e) {
    if (e.target) {
      this.props.po.saveDetail(index, key, e.target.value);
    }
  }

  onInputChangedAndCalculate(index, key, e) {
    if (e.target) {
      this.props.po.saveDetailAndCalculate(index, key, e.target.value || 0.00);
    }
  }

  onDiscountCouponChanged(e) {
    if (e.target) {
      const value = e.target.value ? parseFloat(e.target.value) : 0;
      this.props.po.saveDiscountCoupon(value);
    }
  }

  onInputNumberFocus(e) {
    if (e.target) {
      e.target.select();
    }
  }

  onInputNumberClick(e) {
    if (e.target) {
      e.target.select();
    }
  }

  onSelectedPoChange(e) {
    this.props.po.saveSelectedPoIndex(e.target.value);
    this.props.permission.setInfoLogActivity([])
  }

  onAddRow() {
    this.props.po.addDetail();
  }

  onDeleteRow(index, e) {
    this.props.po.deleteDetail(index);
  }

  onColumnInputNumberChanged(index, key, e) {
    if (e.target) {
      const value = common.isNumeric(e.target.value) ? parseFloat(e.target.value) : 0;
      this.props.po.saveDetail(index, key, value);
    }
  }

  displayTableRow(details) {
    const { contractor } = this.props.po.toJS().info;

    return (details || []).map((d, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td>
            <input
              type="text"
              id={`code-${index}`}
              name={`code-${index}`}
              value={d.code}
              size="15"
              onChange={this.onColumnInputChanged.bind(this, index, 'code')}
            />
          </td>
          <td>
            <input
              type="text"
              id={`name-${index}`}
              name={`name-${index}`}
              value={d.name}
              size="40"
              onChange={this.onColumnInputChanged.bind(this, index, 'name')}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`unit-${index}`}
              name={`unit-${index}`}
              className="text-center"
              size="6"
              value={d.unit}
              onChange={this.onColumnInputChanged.bind(this, index, 'unit')}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`qty-${index}`}
              name={`qty-${index}`}
              className="text-right"
              size="4"
              value={d.qty}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'qty')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`contractor-cost-${index}`}
              name={`contractor-cost-${index}`}
              className="text-right"
              size="10"
              value={d.contractor_cost}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'contractor_cost')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
            />
          </td>
          <td className="text-right">
            <input
              type="text"
              id={`contractor_discount-${index}`}
              name={`contractor_discount-${index}`}
              className="text-right"
              size="10"
              value={d.contractor_discount}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'contractor_discount')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
            />
          </td>
          <td className="text-right">
            {numeral(d.contractor_amount || 0).format('0,0.00')}
          </td>
          <td className="text-center" >
            <input
              // disabled={!contractor.isJuristic}
              type="checkbox"
              id={`wht-${index}`}
              name={`wht-${index}`}
              size="4"
              checked={d.wh_flag}
              value={d.wh_flag}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'wh_flag')}
              onClick={this.onInputNumberClick.bind(this)}
            />
          </td>
          <td className="text-center">
            {
              (details.length > 1) && (
                <EnButton
                  className="btn-primary btn-xs"
                  style={{ padding: '5px 10px', marginTop: '0' }}
                  onClick={this.onDeleteRow.bind(this, index)}>
                  <i className="fa fa-trash" aria-hidden="true" />
                </EnButton>
              )
            }
          </td>
        </tr>
      );
    });
  }

  displayContractorInfo(data) {
    const { companyName, phone, address, taxNo, branchNo } = data;
    return (
      <div>
        <div className="row">
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-12"
            type="text"
            label="ชื่อทีม"
            value={companyName}
            disabled
          />
        </div>
        <div className="row">
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="ทีอยู่"
            value={address}
            disabled
          />
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="โทรศัพท์"
            value={phone}
            disabled
          />
        </div>
        <div className="row">
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="เลขประจำตัวผู้เสียภาษีอากร"
            value={taxNo}
            disabled
          />
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="สถานประกอบการ"
            value={branchNo}
            disabled
          />
        </div>
      </div>
    );
  }

  async checkChangeData(type) {
    const { initInfo, info, poes, selectedPoIndex } = this.props.po.toJS()
    const total = info.netTotal - (info.discountCoupon || 0) - (info.whTax || 0)
    const isValid = await this.checkOtherExpense(total, info.jobInfo, selectedPoIndex)
    if (isValid) {
      this.setState({ typeEventClick: type });
      if (await this.props.job.showJobUpdatedDialog(info)) {
        return;
      }
      if (!this.state.isEdit) {
        this.onSubmit(true)
      } else {
        const permission = [
          { key: 'code', name: 'รหัสสินค้า' },
          { key: 'name', name: 'รายการสินค้า' },
          { key: 'unit', name: 'หน่วย' },
          { key: 'qty', name: 'จำนวน' },
          { key: 'contractor_cost', name: 'ต้นทุนช่างต่อหน่วย' },
          { key: 'contractor_discount', name: 'ส่วนลด' },
          { key: 'wh_flag', name: 'WHT' },
        ]
        const typeJob = poes[0].no.slice(0, 2).toLowerCase()
        let permissionFilter = []
        permissionFilter = initInfo.items.map((item, index) => {
          if (!info.details[index]) {
            const remark = `[${initInfo.no}] is remove at “sku ${item.code} , รายการสินค้า ${item.name}” by `
            return [this.saveModelEventLogs(`update_${typeJob}`, initInfo.no, remark)]
          }
          return permission.map((data) => {
            const key = data.key
            if (info.details[index][key] !== item[key]) {
              const remark = `[${initInfo.no}] is edited at “${data.name} from ${item[key]} to ${info.details[index][key]}” by `
              return this.saveModelEventLogs(`update_${typeJob}`, initInfo.no, remark)
            }
          }).filter(notUndefined => notUndefined !== undefined);
        }).filter(notUndefined => notUndefined !== undefined);
        if (info.details.length > initInfo.items.length) {
          for (let i = initInfo.items.length; i < info.details.length; i++) {
            const remark = `${initInfo.no} is added product at “sku ${info.details[i].code} , รายการสินค้า ${info.details[i].name}” by `
            permissionFilter.push([this.saveModelEventLogs(`update_${typeJob}`, initInfo.no, remark)])
          }
        }
        if (initInfo.remark !== info.remark) {
          const remark = `[${initInfo.no}] is edited at “remark from ${initInfo.remark} to ${info.remark}” by `
          permissionFilter.push([this.saveModelEventLogs(`update_${typeJob}`, initInfo.no, remark)])
        }
        const sumArray = permissionFilter.reduce(function (previousValue, currentValue) {
          return previousValue.concat(currentValue);
        });

        if (sumArray.length > 0) {
          this.props.permission.setInfoLogActivity(sumArray)
          this.onSubmit(true)
        } else {
          if (type === 'save') {
            this.onSubmit(false)
          } else {
            this.onSubmitAndSendMail(false)
          }
        }
      }
    } else {
      const users = (info && info.jobInfo && info.jobInfo.activity_other_extends_logs && info.jobInfo.activity_other_extends_logs.map(item => item && `[${item.user}]`)) || []
      const userUniq = _.uniq(users)
      const userList = userUniq.join(' ')
      swal.fire({
        icon: 'warning',
        title: 'แจ้งเตือนในการแก้ไขเอกสาร PO',
        html: `ไม่สามารถบันทึกได้ เนื่องจากมีรายการหักค่าใช้จ่าย JO และ รายการค่าใช้จ่ายมีเกินกว่ารายการล่าสุดจะหักได้ <br/><br/>กรุณาติดต่อ ${userList} ผู้ทำรายการหักค่าใช้จ่าย JO`,
        confirmButtonText: 'ตกลง',
      });
    }
  }

  saveModelEventLogs(event, referenceCode, remark) {
    const dataPermission = {}
    dataPermission.event = event
    dataPermission.function_name = event
    dataPermission.reference_code = referenceCode
    dataPermission.remark = remark
    return dataPermission
  }

  handleClick() {
    if (this.state.typeEventClick === 'save') {
      this.onSubmit(true)
    } else {
      this.onSubmitAndSendMail(true)
    }
  }

  async onSubmitAndSendMail(verify) {
    // e.preventDefault();
    // const isFormValid = this.frm.isValid();
    let status = false
    if (verify) {
      status = await this.props.permission.verifyPassword()
      if (!status) {
        return
      }
    }
    const info = this.props.po.toJS().info;
    const isFormValid = this.validateForm(info);
    if (!isFormValid) {
      swal.fire({
        icon: 'error',
        title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        confirmButtonText: 'ตกลง',
      });
      return;
    }

    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขใบสั่งซื้อสินค้า และส่งเมล' : 'กรุณายืนยันการสร้างใบสั่งซื้อสินค้า และส่งเมล',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const data = await this.props.po.savePo(
            this.state.jobId,
            true,
            authStore.getUsernameOrEmail()
          );
          if (data && status) {
            this.saveUam(data.data)
          }
          return data;
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      this.onCancel();
    }
  }

  checkOtherExpense(grandTotal, jobInfo, selectedPo) {
    if (Number(selectedPo) !== 0) {
      return true
    }
    const otherExpense = jobInfo.other_expense
    const limitGrandTotal = grandTotal * 0.7
    const totalExpense = (otherExpense && otherExpense.total_expense) || 0
    return totalExpense <= limitGrandTotal
  }

  render() {
    const poStore = this.props.po.toJS();
    const { info, poes, selectedPoIndex } = poStore;
    let isSurvey;
    if (info && info.jobInfo && info.jobInfo.type_of_job) {
      isSurvey = info.jobInfo.type_of_job === 'S';
    }
    const showDropDownPoList = Array.isArray(poes) && !!poes.length;
    const otherExpense = info && info.jobInfo && info.jobInfo.other_expense
    // const totalExpense = ( otherExpense && otherExpense.total_expense ) || 0;
    const totalExpense = selectedPoIndex === 0
      ? otherExpense && otherExpense.total_expense || 0
      : 0;
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={poStore.isLoading || poStore.isSubmitting} />
          <div className="card">
            <div className="col-md-12">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แบบฟอร์มใบสั่งซื้อสินค้า</h4>
                <p className="category">{isSurvey ? 'สำรวจ' : 'ติดตั้ง'}</p>
              </div>
              <div className="card-content">
                {showDropDownPoList && (
                  <div className="row">
                    <FormGroup containerClassName="col-md-6" label="ใบสั่งซื้อสินค้า">
                      <EnDropDown onChange={this.onSelectedPoChange.bind(this)} value={selectedPoIndex}>
                        {poes.map((po, index) => {
                          const { files } = po;
                          const name = Array.isArray(files) && files.length ? files[files.length - 1].name : po.no;
                          return (
                            <option key={`po-${index}`} value={index}>{name}</option>
                          );
                        })}
                      </EnDropDown>
                    </FormGroup>
                  </div>
                )}
                {info && info.contractor && (
                  this.displayContractorInfo(info.contractor)
                )
                }
                <FormValidation ref={(el) => {
                  this.frm = el;
                }}>
                  <div className="row" style={{ paddingTop: '20px' }}>
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-hover col-md-12">
                          <thead>
                            <tr>
                              <th width="10%" className="text-center">#</th>
                              <th width="15%" className="text">รหัสสินค้า</th>
                              <th width="20%" className="text-center">รายการสินค้า</th>
                              <th width="10%" className="text-center">หน่วย</th>
                              <th width="10%" className="text-center">จำนวน</th>
                              {/* <th width="10%" className="text-center">ราคา</th> */}
                              <th width="10%" className="text-center">ต้นทุนช่างต่อหน่วย</th>
                              <th width="10%" className="text-center">ส่วนลด</th>
                              <th className="text-right">จำนวนเงิน</th>
                              <th width="10%" className="text-center">WHT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.displayTableRow(info.details)}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="9">
                                <EnButton
                                  className="btn-success btn-xs"
                                  style={{ padding: '5px 10px' }}
                                  onClick={this.onAddRow.bind(this)}>
                                  <i className="fa fa-plus" aria-hidden="true" />
                                </EnButton>
                              </td>
                            </tr>
                            <tr>
                              <td rowSpan="8" colSpan="5">
                                <TextArea
                                  label="หมายเหตุ"
                                  rows={5}
                                  value={info.remark}
                                  onChange={this.onInfoChanged.bind(this, 'remark')}
                                />
                              </td>
                              <th colSpan="2">
                                ยอดรวมสินค้าและบริการ
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.subTotal).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                ยอดส่วนลด
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.discountTotal).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                ยอดเงินก่อนภาษี
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.netExcludeVat).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                ภาษีมูลค่าเพิ่ม {info.vatRate}%
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.vat).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                หักภาษี ณ ที่จ่าย 3%
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.whTax).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                จำนวนเงินรวมทั้งสิ้นของค่าสินค้าและบริการ
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.beforeOtherExpense).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                จำนวนเงินรวมรายการหักค่าใช้จ่าย
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(totalExpense).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                จำนวนเงินสุทธิได้
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.grandTotal).format('0,0.00')}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <EnButton className="btn-warning pull-right" disabled={!this.validateForm(info)} onClick={this.checkChangeData.bind(this, 'saveAndEmail')}>
                        <i className="fa fa-paper-plane-o btn-icon" aria-hidden="true" />บันทึกและส่งเมล
                      </EnButton>
                      <EnButton className="btn-info pull-right" disabled={!this.validateForm(info)} onClick={this.checkChangeData.bind(this, 'save')}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                        <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                      </EnButton>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('po', 'job', 'permission')(observer(PurchaseOrderForm));

import React from 'react';
import { inject, observer } from 'mobx-react';
import { DeviceFrameset } from 'react-device-frameset';
import iphoneBg from '../../../assets/img/iphone-bg.png';
import qchangAppBg from '../../../assets/img/qchang-app.svg';
import iconQchang from '../../../assets/img/icn_qchang.svg';
import '../../../assets/css/iphone.css';

const IphoneBroadcastPreview = ({ title, detail, thumbnailUrl }) => {
  const getPlainText = (value) => {
    const result = value
      .replace(/<span\s+class="remove-badge"\s*>x<\/span>/gi, '')
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/&nbsp;/g, '');

    return result;
  };

  return (
    <div className="device-container row">
      <div className="device-column col-md-6">
        <DeviceFrameset device="iPhone X" color="black">
          <div className="device-background" style={{ backgroundImage: `url("${qchangAppBg}")` }}>
            <div className="notification-container">
              <div className="icon-container d-flex">
                {thumbnailUrl ? (
                  <img src={thumbnailUrl} alt="iconQchang" className="iphone-badge-icon" />
                ) : (
                  <img src={iconQchang} alt="iconQchang" className="iphone-badge-icon" />
                )}
                <div className="notification-dot"></div>
              </div>
              <div className="content-container">
                <div className="content-row row p-0">
                  <div className="col-md-9" style={{ paddingBottom: 0 }}>
                    <span className="title">{getPlainText(title)}</span>
                  </div>
                  <small className="time col-md-3">10:00</small>
                </div>
                <div className="content-row row p-0">
                  <div className="col-md-10">
                    <span className="message">{getPlainText(detail)}</span>
                  </div>
                  <div className="col-md-2 p-0"></div>
                </div>
              </div>
            </div>
          </div>
        </DeviceFrameset>
      </div>
      <div className="device-column col-md-6">
        <DeviceFrameset device="iPhone X" color="black">
          <div className="device-background" style={{ backgroundImage: `url("${iphoneBg}")` }}>
            <div className="notification-container iphone-notification">
              <div className="icon-container d-flex">
                <img src={iconQchang} alt="iconQchang" className="iphone-badge-icon" />
              </div>
              <div className="content-container">
                <div className="content-row row p-0">
                  <div className="col-md-9" style={{ paddingBottom: 0 }}>
                    <span className="title">{getPlainText(title)}</span>
                  </div>
                  <div className="iphone-time col-md-3">34m ago</div>
                </div>
                <div className="content-row row p-0">
                  <div className="col-md-10">
                    <span className="message iphone-message">{getPlainText(detail)}</span>
                  </div>
                  <div className="col-md-2" style={{ display: 'flex', justifyContent: 'center' }}>
                    {thumbnailUrl && <img src={thumbnailUrl} alt="iconQchang" style={{ height: '28px', width: '28px', borderRadius: '6px' }} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DeviceFrameset>
      </div>
    </div>
  );
};

export default inject()(observer(IphoneBroadcastPreview));

import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

export class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.value || '',
    };
  }

  onChange = (e) => {
    const value = e.target.value || '';
    this.setState({ search: value });

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  handleSearch = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder={this.props.placeholder || 'Search...'}
          value={this.state.search}
          onChange={this.onChange}
        />
        <SearchIcon className="search-icon" />
      </div>
    );
  }
}

export default SearchBar;

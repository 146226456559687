import React, { Component, Fragment } from 'react';
import swal from 'sweetalert2';
import _, { get } from 'lodash';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import { couponHeader, FormValidation, limitText100, limitText25, required } from '../../../components/form/FormValidation';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnToggle from '../../../components/form/EnToggle';
import EnDateRangePicker from '../../../components/form/EnDateRangePicker';
import EnButton from '../../../components/form/EnButton';
import EnTagSelect from '../../../components/form/EnTagSelect';
import EnCKEditor from '../../../components/form/EnCKEditor';
import EnDropzoneDialog from '../../../components/form/EnDropzoneDialog';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import { EnTab } from '../../../components/form/EnTab';
import { Tab } from 'react-bootstrap';
import { EnDropDown } from '../../../components/form/EnDropDown';
import { config } from '../../../config';
import { DateCheckBox } from './components/DateCheckBox';
import { getBackboneSkuFromCategory } from '../../../utils/category';
import { isAllow } from '../../../utils/permission';
import { CHANNEL_GROUP, channelGroupOptions } from '../../../utils/data';
import common from '../../../utils/common';
import EnCheckBox from '../../../components/form/EnCheckBox';
import Autocomplete from '../../../componentsV2/select/autocomplete';
import { toast } from 'react-toastify';
// import { BenefitSearchFromGroup } from './components/BenefitSearchFromGroup';
// import BenefitSelect from './components/BenefitSelect';

export class CouponPage extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    this.state = {
      activeIndex: 0,
      id: id,
      isEdit: id && id.length,
      isCopyPromotionCode: false,
      products: [],
      newProducts: [],
      items: [],
      selectedProduct: [],
      allProvince: [],
      allBranch: [],
      provinceItems: [],
      branchItems: [],
      isSelectBranch: false,
      limitedUsePerMemberError: false,
      adBannerDropzoneOpen: false,
      adHeroBannerDropzoneOpen: false,
      adCardPromotionBannerDropzoneOpen: false,
      adPromotionBottomBannerDropzoneOpen: false,
      adPromotionThumbnailDropzoneOpen: false,
      isOnlyPromotionDetail: false,
      isBannerPromotion: false,
      mainCategoryOptions: {}, // { 'key': [] }
      subCategoryOptions: {}, // { 'key': [] }
      selectedMainCategory: {}, // { 'key': [] }
      selectedSubCategory: {}, // { 'key': [] }
      selectedSkuCode: {}, // { 'key': [] }
      displayChannelGroupErrorMessage: false,
      selectedBenefits: [],
    };
  }

  async componentDidMount() {
    try {
      await this.props.product.getProductList(undefined, undefined, true, 'coupon');
      await this.props.channel.getAllChannel();
      await this.props.model.getAllModel();
      await this.props.misc.getAllProvince();

      if (this.state.isEdit) {
        const result = await this.props.coupon.getCouponById(this.state.id);
        this.setState({
          isOnlyPromotionDetail: _.get(result, 'information.isOnlyPromotionDetail', false),
          isBannerPromotion: _.get(result, 'isBannerPromotion', false)
        })
        this.props.coupon.initialSelectBranch();
      } else {
        this.props.coupon.clearInfo();
      }

      const info = this.props.coupon.toJS().info;
      const channel = this.props.channel.toJS().info;
      const selectedChannel = channel.filter(c => info.channel.includes(c.channel_name));
      const contactChannel = selectedChannel && selectedChannel.length ? selectedChannel[0].contact_channel : '';
      const creditTerm = selectedChannel && selectedChannel.length ? selectedChannel[0].credit_term : 1;
      this.props.coupon.saveInfo('contactChannel', contactChannel);
      this.props.coupon.saveInfo('creditTerm', creditTerm);

      // get Stores
      const branch = await this.props.coupon.getStoreByID({ channel: info.channel, field: 'coupon' });

      //check Branchs
      this.props.misc.saveId('miscData.branch', branch || []);

      const productItems = this.props.product.toJS().items;
      let items = [];
      if (info && Array.isArray(info.items) && info.items.length) {
        info.items.map((i, index) => {
          let range = [];
          const selectedItems = i.products.map(p => {
            const product = productItems.find(x => x.id === p.id);
            if (product) {
              const name = _.get(product, 'name.th')
              const channel = _.get(product, 'skuDetail[0].channel') ? `| Channel ${_.get(product, 'skuDetail[0].channel')}` : ''
              const price = _.get(product, 'calculator.unitPrice', 0);
              return {
                label: `${_.get(product, 'skuCode')} ${name} ${channel}`,
                value: p.id,
                price: price,
              };
            }
          });
          if (info && Array.isArray(i.range) && i.range.length) {
            range = i.range.map((r, index) => {
              const contractor_discount = r.contractorDiscount || 0
              const bu_discount = r.buDiscount || 0
              const partner_discount = r.partnerDiscount || 0
              let platform_discount = r.platformDiscount || 0
              const sumDiscount = contractor_discount + bu_discount + partner_discount + platform_discount
              if ((r.value !== sumDiscount) && (platform_discount == 0)) {
                platform_discount = r.value - contractor_discount
              }
              return {
                key: r.key,
                min: r.min,
                max: r.max,
                value: r.value,
                contractor_discount,
                bu_discount,
                partner_discount,
                platform_discount,
                date: r.date || [],
              };
            });

          };
          let promotionRange = false;
          if (i.promotionRange) {
            promotionRange = i.promotionRange;
          } else {
            promotionRange = range.length > 0 ? true : false;
          }
          items.push({
            key: index,
            selectedId: selectedItems,
            product: productItems,
            value: i.value,
            range: range,
            daily_promotion: i.dailyPromotion || false,
            date: i.date || [],
            promotion_range: promotionRange,
          });
        });
      } else {
        items.push({
          key: (new Date()).getTime(),
          selectedId: [],
          product: productItems,
          range: [],
          value: null,
          daily_promotion: false,
          date: [],
          promotion_range: false,
        });
      }
      let productId = [];
      _.forEach(items, (item, index) => {
        if (item.selectedId && item.selectedId.length) {
          const id = item.selectedId.map(i => {
            if (typeof i === 'object') {
              return i.value;
            }
            return i;
          });
          productId.push(...id);
        }
      });
      const misc = this.props.misc.toJS().miscData;
      if (info && info.provinceId && info.provinceId.length) {
        misc.province['selected_id'] = info.provinceId;
      } else {
        misc.province['selected_id'] = [];
      }

      // initial Setup Store From database
      if (info && info.stores && info.stores.length) {
        misc.branch['selected_id'] = info.stores;
        this.setState({ isSelectBranch: true });
      } else {
        misc.branch['selected_id'] = [];
      }

      const { mainCategoryOptions: m, subCategoryOptions: s } = this.getCategoryOptions([])
      let mainCategoryOptions = {}, subCategoryOptions = {};
      for (let i = 0; i < items.length; i++) {
        mainCategoryOptions = { ...mainCategoryOptions, [items[i].key]: m }
        subCategoryOptions = { ...subCategoryOptions, [items[i].key]: s }
      }


      if (info && info.benefitId && info.benefitId.length > 0) {
        this.setState({
          selectedBenefits: [...info.benefitId]
        })
      }

      // if(info && info.benefits && info.benefits.length > 0) {

      //   const initBenefitSelected = info.benefits.map((item) => ({
      //     label: `${item.code} - ${item.name}`,
      //     value: item.id
      //   }))
        
      //   this.setState({
      //     selectedBenefits: initBenefitSelected
      //   })
      // }

      this.setState({
        products: [productItems],
        items,
        selectedProduct: productId,
        provinceItems: [misc.province],
        allProvince: misc.province,
        branchItems: [misc.branch],
        allBranch: misc.branch,
        backboneCategory: this.props.product.toJS().backboneCategory,
        mainCategoryOptions,
        subCategoryOptions,
      });
    } catch (error) {
      toast.error(error.message || 'โหลดข้อมูลล้มเหลว');
    }
  }

  onPromotionRange = (index) => {
    const items = _.cloneDeep(this.state.items);
    const item = items[index];
    if (item.promotion_range && item.range.length > 0) {
      swal.fire({
        title: 'หากคุณเปลี่ยนแปลงเป็นโปรโมชั่นเดียว \n ข้อมูลช่วงราคาจะถูก Reset',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        if (result.value) {
          item.range = [];
          items[index].promotion_range = !items[index].promotion_range;
          this.setState({
            items,
            promotionRange: !this.state.promotionRange
          });
        }
      });
    } else {
      items[index].promotion_range = !items[index].promotion_range;
      this.setState({
        items,
        promotionRange: !this.state.promotionRange
      });
    }
  }

  onTextChange(key, e) {
    const value = e.target.value || '';

    if (value) {
      this.props.coupon.saveInfo(key, value);
    }

    if (key === 'useLimit') {
      const { isLimitedUseMember, limitedUsePerMember } = this.props.coupon.toJS().info;
      if (isLimitedUseMember && value < limitedUsePerMember) {
        this.setState({ limitedUsePerMemberError: true })
      } else {
        this.setState({ limitedUsePerMemberError: false })
      }
    }
  }

  onValueChange(key, e) {
    if (e.target) {
      this.props.coupon.saveInfo(key, +e.target.value);
    }
  }

  onValueChangeLmitUseMember(e) {
    let value = e.target.value
    const info = this.props.coupon.toJS().info;
    if (value < 1) value = 1
    this.props.coupon.saveInfo('limitedUsePerMember', +value);

    if (info.isLimit && +value > +info.useLimit) {
      this.setState({ limitedUsePerMemberError: true })
    } else {
      this.setState({ limitedUsePerMemberError: false })
    }
  }

  onDateChange(startDate, endDate) {
    this.props.coupon.saveInfo('startDate', startDate ? startDate.toDate() : startDate);
    this.props.coupon.saveInfo('endDate', endDate ? endDate.toDate() : endDate);
  }

  onToggle = (value) => {
    this.props.coupon.saveInfo('status', value ? 'active' : 'inactive');
  }

  onTogglePrivateCode = (value) => {
    this.props.coupon.saveInfo('isPrivateCode', value);
  }

  onLimitCouponToggle = (value) => {
    if (value) {
      this.props.coupon.saveInfo('isLimit', value);
      const {useLimit, isLimitedUseMember, limitedUsePerMember} = +this.props.coupon.toJS().info;
      if (isLimitedUseMember && useLimit < +limitedUsePerMember) {
        return this.setState({ limitedUsePerMemberError: true })
      }
    } else {
      this.props.coupon.saveInfo('useLimit', 0);
      this.props.coupon.saveInfo('isLimit', value);
      this.setState({ limitedUsePerMemberError: false })
    }
  }

  onRequestPaymentToggle = (value) => {
    this.props.coupon.saveInfo('requestPayment', value);
  }

  onRequestStorePaymentToggle = (value) => {
    this.props.coupon.saveInfo('requestStorePayment', value);
  }

  onRequestNotiToggle = (value) => {
    this.props.coupon.saveInfo('requestNotification', value);
    // set request noti when request payment
    if (value === false) {
      this.props.coupon.saveInfo('requestPayment', value);
    }
  }

  onSelectBranch = (value) => {
    this.setState({ 'isSelectBranch': value });
  }

  onSelectIsMember = (value) => {
    if (value) {
      this.props.coupon.saveInfo('isMember', value)
      const { useLimit, isLimit, isLimitedUseMember, limitedUsePerMember } = this.props.coupon.toJS().info
      if (isLimitedUseMember && isLimit && +useLimit < limitedUsePerMember) {
        this.setState({ limitedUsePerMemberError: true })
      }
    } else {
      this.props.coupon.saveInfo('isMember', value)
      this.props.coupon.saveInfo('isLimitedUseMember', false)
      this.props.coupon.saveInfo('limitedUsePerMember', 1)
      this.setState({ limitedUsePerMemberError: false })
    }
  }

  isValid() {
    const info = this.props.coupon.toJS().info;
    const validName = !_.isEmpty(info.name);
    const validCode = !_.isEmpty(info.code);
    const startDate = info.startDate;
    const endDate = info.endDate;
    const termsBrief = _.get(info, 'information.termsBrief.length', 0) > 25
    const termsUse = _.get(info, 'information.termsUse.length', 0) > 100
    const selectedBranch = this.state.branchItems[0];
    const isSelectBranch = this.state.isSelectBranch
    const isMemberLimitrConficAllLimit = info.isLimit && info.isLimitedUseMember && +info.useLimit < +info.limitedUsePerMember
    if (isSelectBranch && selectedBranch && selectedBranch.length && selectedBranch.selected_id) {
      // if type is BizAccount (it can Select Branch) , then must select products
      return validName && validCode && endDate && startDate && (selectedBranch.selected_id.length != 0 && selectedBranch.selected_id[0] !== '') && !termsBrief && !termsUse
    }
    else {
      return validName && validCode && endDate && startDate && !termsBrief && !termsUse && !isMemberLimitrConficAllLimit
    }
  }

  getProductName = (id) => {
    const product = this.state.products[0].find(p => p.id === id);
    if (product) {
      return product.name.th;
    }
    return '';
  }

  convertItemsToDb = (options) => {
    const productItems = (options || []).map((i) => {
      const products = i.selectedId
        .filter((s) => s !== undefined)
        .map((s) => {
          if (typeof s === 'string') {
            return {
              id: s,
              label: this.getProductName(s),
            };
          }
          return {
            id: s.value,
            label: s.label,
          };
        });
      const ranges = i.range.map((r) => ({ ...r, date: i.date }));

      return {
        products,
        value: i.value,
        range: ranges,
        daily_promotion: i.daily_promotion || false,
        date: i.date || [],
        promotion_range: i.promotion_range || false,
      };
    });
    return productItems;
  }

  validationCondition() {
    const items = this.state.items;
    let rangeItem = (items || []).filter((r) => {
      if (r.promotion_range) {
        return r.range.find(i => (i.min <= 0) || (i.max <= 0) || (i.value <= 0) || !i.min || !i.max || !i.value);
      }
    });

    if (rangeItem.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async submitForm() {
    if (this.validationCondition()) {
      try {
        let selectedBranch = this.state.branchItems[0].selected_id;
        // if (!this.state.isSelectBranch) {
        //   selectedBranch = [];
        //   _.forEach(this.state.allBranch, item => {
        //     selectedBranch.push(item._id);
        //   })
        // }

        if (!this.state.isSelectBranch) {
          selectedBranch = [];
        }

        let productOptions = {
          selectProductIds: this.convertItemsToDb(this.state.items),
          provinceItems: this.state.provinceItems[0].selected_id,
          branchItems: selectedBranch,
          isOnlyPromotionDetail: this.state.isOnlyPromotionDetail,
          isBannerPromotion: this.state.isBannerPromotion,
        };

        if (this.state.selectedBenefits) {
          productOptions = {
            ...productOptions,
            benefits: this.state.selectedBenefits || [],
          }
        }

        if (!this.validate.isValid()) {
          let error = 'เลือกวันซ้ำ';
          throw error;
        }
        if (this.state.isEdit && !this.state.isCopyPromotionCode) {
          await this.props.coupon.updateCoupon(this.state.id, productOptions);
        } else {
          const result = await this.props.coupon.createCoupon(productOptions);
          const { _id } = result
          const url = `${config.publicUrl}/qchang/coupon/${_id}/edit`;
          this.props.history.replace(url);
        }
      } catch (error) {
        throw error;
      }
    } else {
      let error = 'กรุณากรอกช่วงราคาให้ครบถ้วน';
      throw error;
    }
  }

  async onSubmit() {
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขส่วนลด' : 'กรุณายืนยันการเพิ่มส่วนลดใหม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.submitForm();
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }

  async onCancel() {
    let url;
    if (this.state.isCopyPromotionCode) {
      const id = this.props.coupon.toJS().idNewCouponCopy;
      url = `${config.publicUrl}/qchang/coupon/${id}/edit`;
      this.props.history.replace(url);
      this.setState({
        isCopyPromotionCode: false,
      });
    } else {
      url = `${config.publicUrl}/qchang/coupon`;
      this.props.history.replace(url);
      // window.location.reload()
    }
  }

  async selectProductAll(key) {
    try {
      const { coupon: couponStore } = this.props;
      const { productChannel: channelGroup, channel: selectedChannelNames } = couponStore.toJS().info;
      const items = _.cloneDeep(this.state.items);
      let item = items.find(i => i.key === key);
      const products = get(item, 'product', []);
      const channelNames = this.getChannelOptions().map(({ value }) => value);
      let productId = [];

      if (selectedChannelNames.length === 0) {
        return;
      } else if (channelGroup === CHANNEL_GROUP.V1) {
        const filteredProducts = products.filter(({ productChannel }) => common.hasCommonElements(channelNames, productChannel));
        const productIds = filteredProducts.map(({ id }) => id);
        productId = [...productIds];
        item.selectedId = [...productIds];
      } else {
        let selectedSkuCode = _.get(this.state.selectedSkuCode, [`${key}`], [])
        if (_.isEmpty(this.state.selectedMainCategory) && _.isEmpty(this.state.selectedSubCategory)) {
          const backboneCategory = this.props.product.toJS().backboneCategory;
          const mainCategoryOptions = _.get(backboneCategory, ['categories'], []).map(({ cateUUID }) => cateUUID);
          const skuList = await getBackboneSkuFromCategory({ cateUUID: mainCategoryOptions });
          selectedSkuCode = skuList.map(({ skuCode }) => skuCode);
          this.setState({
            selectedSkuCode: {
              ...this.state.selectedSkuCode,
              [key]: selectedSkuCode
            }
          })
        }
        const filteredProducts = products.filter(({ productChannel, skuCode }) => {
          const isInChannels = common.hasCommonElements(channelNames, productChannel);
          const isInSkuList = selectedSkuCode.length ? selectedSkuCode.includes(skuCode) : true;
          return isInChannels && isInSkuList;
        });
        const productIds = filteredProducts.map(({ id }) => id);
        productId = [...productIds];
        item.selectedId = [...productIds];
      }
      this.setState({
        items,
        selectedProduct: productId,
      });
    } catch (error) {
      console.log('selectProductAll/error', error)
    }
  }

  getCategoryOptions = (mainCate) => {
    const backboneCategory = this.props.product.toJS().backboneCategory;
    let mainCategoryOptions = _.get(backboneCategory, ['categories'], []).map(({ cateUUID, cateNameLocal }) => ({
      disabled: false,
      value: cateUUID,
      label: cateNameLocal,
    }));
    mainCategoryOptions = [
      { label: 'เลือกทั้งหมด', value: 'ALL' },
      ...mainCategoryOptions,
    ]
    let subCategoryOptions = []
    if (mainCate && mainCate.length > 0) {
      subCategoryOptions.push({ label: 'เลือกทั้งหมด', value: 'ALL' })
      for (let i = 0; i < mainCate.length; i++) {
        const mainCategory = _.get(backboneCategory, ['categories'], []).find(({ cateUUID }) => cateUUID === mainCate[i]);
        const subCategories = _.get(mainCategory, ['subCategories'], []);
        if (subCategories && subCategories.length > 0) {
          for (let j = 0; j < subCategories.length; j++) {
            const subCategory = subCategories[j];
            subCategoryOptions.push({
              disabled: false,
              value: subCategory.subCateUUID,
              label: subCategory.subCateNameLocal,
            })
          }
        }
      }
    } else {
      subCategoryOptions = [];
    }
    return { mainCategoryOptions, subCategoryOptions };
  }

  onChangeMainCategory = (selectedItem) => async (e) => {
    const ids = _.isEmpty(e) ? [] : e.split(',');
    let selectedMainCategory;
    if (ids.includes('ALL')) {
      const backboneCategory = this.props.product.toJS().backboneCategory;
      const mainCategoryOptions = _.get(backboneCategory, ['categories'], []).map(({ cateUUID }) => cateUUID);
      selectedMainCategory = mainCategoryOptions;
    } else {
      selectedMainCategory = ids;
    }
    const { mainCategoryOptions, subCategoryOptions } = this.getCategoryOptions(selectedMainCategory);
    const validSubCate = subCategoryOptions.map(({ value }) => value);

    let selectedSkuCode = []
    const validSelectedSubCate = _.intersection(validSubCate, this.state.selectedSubCategory[selectedItem.key]);

    if (selectedMainCategory.length > 0) {
      let skuList = [];
      if (validSelectedSubCate.length === 0) {
        skuList = await getBackboneSkuFromCategory({ cateUUID: selectedMainCategory });
      } else {
        skuList = await getBackboneSkuFromCategory({ subCateUUID: validSelectedSubCate });
      }
      skuList = skuList.map(({ skuCode }) => skuCode);
      selectedSkuCode = skuList;
    }
    this.setState((prev) => ({
      selectedMainCategory: { ...prev.selectedMainCategory, [selectedItem.key]: selectedMainCategory },
      selectedSubCategory: { ...prev.selectedSubCategory, [selectedItem.key]: validSelectedSubCate },
      selectedSkuCode: { ...prev.selectedSkuCode, [selectedItem.key]: selectedSkuCode },
      subCategoryOptions: { ...prev.subCategoryOptions, [selectedItem.key]: subCategoryOptions },
    }));
  }

  onChangeSubCategory = (selectedItem) => async (e) => {
    const ids = _.isEmpty(e) ? [] : e.split(',');
    let selectedSubCategory;
    if (ids.includes('ALL')) {
      let subCategoryOptions = [];
      const backboneCategory = this.props.product.toJS().backboneCategory;
      for (let i = 0; i < this.state.selectedMainCategory[selectedItem.key].length; i++) {
        const mainCategoryId = this.state.selectedMainCategory[selectedItem.key][i];
        const mainCategory = _.get(backboneCategory, ['categories'], []).find(({ cateUUID }) => cateUUID === mainCategoryId);
        const subCategories = _.get(mainCategory, ['subCategories'], []);
        if (subCategories && subCategories.length > 0) {
          for (let j = 0; j < subCategories.length; j++) {
            const subCategory = subCategories[j];
            subCategoryOptions.push(subCategory.subCateUUID);
          }
        }
      }
      selectedSubCategory = subCategoryOptions;
    } else {
      selectedSubCategory = ids;
    }
    let selectedSkuCode = [];
    if (selectedSubCategory.length > 0 || this.state.selectedMainCategory[selectedItem.key].length > 0) {
      let skuList = [];
      if (selectedSubCategory.length > 0) {
        skuList = await getBackboneSkuFromCategory({ subCateUUID: selectedSubCategory });
      } else {
        skuList = await getBackboneSkuFromCategory({ cateUUID: this.state.selectedMainCategory[selectedItem.key] });
      }
      skuList = skuList.map(({ skuCode }) => skuCode);
      selectedSkuCode = skuList;
    }
    this.setState((prev) => ({
      selectedSubCategory: { ...prev.selectedSubCategory, [selectedItem.key]: selectedSubCategory },
      selectedSkuCode: { ...prev.selectedSkuCode, [selectedItem.key]: selectedSkuCode },
    }));
  }

  onChangeProduct(selectedItem, e) {
    const info = this.props.coupon.toJS().info;
    const promotionType = info.type
    const ids = _.isEmpty(e) ? [] : e.split(',');
    const items = _.cloneDeep(this.state.items);
    let item = items.find(i => i.key === selectedItem.key);
    if (e.indexOf('ALL') >= 0) {
      this.selectProductAll(selectedItem.key)
      return
    }
    if (!_.isNil(item) && !_.isEmpty(item)) {
      item.selectedId = ids;
    }

    let productId = [];
    _.forEach(items, (item, index) => {
      if (item.selectedId && item.selectedId.length) {
        const id = item.selectedId.map(i => {
          if (typeof i === 'object') {
            return i.value;
          }
          return i;
        });
        productId.push(...id);

        if (item.promotion_range && item.selectedId.length > 1 && promotionType === "I") {
          swal.fire({
            title: 'หากคุณเปลี่ยนแปลงสินค้าที่ร่วมรายการ \n ข้อมูลช่วงราคาจะถูก Reset',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
          }).then(async (result) => {
            if (result.value) {
              item.range = [];
              item.promotion_range = false;
              this.setState({
                items,
                selectedProduct: productId,
              });
            }
          });
        } else {
          this.setState({
            items,
            selectedProduct: productId,
          });
        }
      } else {
        item.value = 0;
        item.date = [];
        item.daily_promotion = false;
        item.range = [];
        item.promotion_range = false;
        this.setState({
          items,
          selectedProduct: productId,
        });
      }
    });
  }

  deleteItems(item, index) {
    const data = this.state.items.filter(d => d.key !== item.key);
    let selectedProduct = [];
    _.forEach(data, (item, index) => {
      if (item.selectedId && item.selectedId.length) {
        const id = item.selectedId.map(i => {
          if (typeof i === 'object') {
            return i.value;
          }
          return i;
        });
        selectedProduct.push(...id);
      }
    });
    this.setState({
      items: data,
      selectedProduct,
    });
  }

  deleteItemsRange(range, e) {
    const items = _.cloneDeep(this.state.items);
    if (e && e.target) {
      let rangeItem = (items || []).map((r) => {
        r.range = r.range.filter(i => i.key !== range.key);
        return r;
      });
      this.setState({
        items: rangeItem,
      });
    }
  }

  onAddItems = () => {
    const { coupon: couponStore } = this.props;
    const { productChannel: channelGroup } = couponStore.toJS().info;
    let items = _.cloneDeep(this.state.items);
    const productItems = this.state.products[0];
    const key = (new Date()).getTime()
    items.unshift({
      key,
      selectedId: [],
      product: productItems,
      value: 0,
      range: [],
      daily_promotion: false,
      date: [],
      promotion_range: false,
    });
    if (channelGroup === CHANNEL_GROUP.V2) {
      const { mainCategoryOptions: m, subCategoryOptions: s } = this.getCategoryOptions([])
      this.setState((prev) => ({
        items,
        mainCategoryOptions: { ...prev.mainCategoryOptions, [key]: m },
        subCategoryOptions: { ...prev.subCategoryOptions, [key]: s },
      }));
    } else {
      this.setState({ items });
    }
  }

  onAddRange = (selectItem, e) => {
    const items = _.cloneDeep(this.state.items);
    if (e && e.target) {
      let item = items.find(i => i.key === selectItem.key);
      item.range.push({
        key: (new Date()).getTime(),
        min: 0,
        max: 0,
        date: [0, 1, 2, 3, 4, 5, 6],
      });
    }
    this.setState({ items });
  }

  onContractorDiscountChange = (range, e) => {
    if (!e?.target) {
      return;
    }

    const items = _.cloneDeep(this.state.items);
    
    items.forEach(item => {
      const rangeItem = item.range.find(i => i.key === range.key);
      if (rangeItem) {
        rangeItem.contractor_discount = +e.target.value;
      }
    });

    this.setState({ items });
  }

  onDateCheckBoxChange(dateIndex, itemIndex, e) {
    const dateValue = +e.target.value;
    let items = _.cloneDeep(this.state.items);
    if (items[itemIndex].date.indexOf(dateValue) >= 0) {
      items[itemIndex].date = items[itemIndex].date.filter(i => i !== dateValue);
    } else {
      items[itemIndex].date.push(dateValue);
    }
    this.setState({ items });
  }

  onPromotionCheck = (index) => {
    const items = _.cloneDeep(this.state.items);
    items[index].daily_promotion = !items[index].daily_promotion;
    if (!items[index].daily_promotion) {
      items[index].range = items[index].range.map(r => {
        return {
          ...r,
          date: [],
        };
      });
    }
    this.setState({ items });
  }

  async filterProductsDup() {
    const items = _.cloneDeep(this.state.items);
    const productOptions = {
      selectProductIds: this.convertItemsToDb(this.state.items),
      provinceItems: this.state.provinceItems[0].selected_id,
    };
    let productId = [];
    try {
      let productsDup = []
      productsDup = await this.props.coupon.getProductsDup(productOptions)

      _.forEach(items, (item, index) => {
        if (item.product && item.product.length) {
          const newProducts = []

          item.product.forEach(i => {
            if (productsDup.findIndex((p) => p.id === i.id) === -1) {
              if (typeof i === 'object') {
                newProducts.push(i)
              }
            }
          });
          item.product = newProducts
        }
        if (item.selectedId && item.selectedId.length) {

          const id = []
          item.selectedId.forEach(i => {
            if (productsDup.findIndex((p) => p.id === i) === -1) {
              if (typeof i === 'object') {
                id.push(i.value)
              }
              id.push(i)
            }
          });

          item.selectedId = id
          productId.push(...id);
        }
      });

      swal.fire({
        icon: 'success',
        title: 'กรองสินค้าสำเร็จ',
        showConfirmButton: false,
        timer: 2000
      })

    } catch (error) {
      swal.fire({
        icon: 'warning',
        title: 'กรองสินค้าไม่สำเร็จ',
        text: `${error}`
      })

    }
    this.setState({
      items,
      selectedProduct: productId,
    });
  }

  showItem() {
    const info = this.props.coupon.toJS().info;
    const promotionType = info.type
    const channels = this.props.channel.toJS().info;
    const v2Channels = channels && channels.filter(item => item.product_channel.toLowerCase() === 'v2').map(item => item.channel_name);

    const items = (this.state.items || []).map((item, index) => {
      const products = get(item, 'product', []);
      const channelNames = this.getChannelOptions().map(({ value }) => value);
      let productOptions = [];

      if (info.channel.length && info.productChannel === CHANNEL_GROUP.V1) {
        productOptions = products.filter(({ productChannel }) => common.hasCommonElements(channelNames, productChannel));
      } else if (info.channel.length && info.productChannel === CHANNEL_GROUP.V2) {
        const selectedSkuCode = _.get(this.state.selectedSkuCode, [`${item.key}`], []);
        productOptions = products.filter(({ id, productChannel, skuCode }) => {
          const isAlreadySelected = (item.selectedId || []).includes(id);
          const isInChannels = common.hasCommonElements(channelNames, productChannel);
          const isInSkuList = selectedSkuCode.length ? selectedSkuCode.includes(skuCode) : true;
          return isAlreadySelected || (isInChannels && isInSkuList);
        });
      }
      productOptions = (productOptions || []).map(p => {
        return ({
          label: `${_.get(p, 'skuCode', '')} ${_.get(p, 'name.th', '')}`,
          value: p.id,
          disabled: _.indexOf(this.state.selectedProduct, p.id) >= 0,
        });
      });
      productOptions = _.orderBy(productOptions, ['label'])
      if (item.product.length !== this.state.selectedProduct.length) {
        productOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...productOptions];
      }
      const orderProduct = [
        ...productOptions
      ]
      let price = _.get(item, `selectedId.0.price`);
      if (!price) {
        const id = _.get(item, `selectedId.0`, false);
        const productList = _.get(item, 'product', []);
        const product = productList.find(x => x.id === id);
        price = _.get(product, 'calculator.unitPrice', 0);
      }

      const priceRange = (item.range || []).map((r, indexRange) => {
        const minPriceSurvey = indexRange === 0 ? 1 : (1000 * indexRange) + 1
        const maxPriceSurvey = indexRange === 0 ? 1000 : 1000 * (indexRange + 1)
        const platform_discount = r.platform_discount || 0
        const bu_discount = r.bu_discount || 0
        const partner_discount = r.partner_discount || 0
        const contractor_discount = r.contractor_discount || 0
        const sumDiscount = r.value || 0
        r.min = promotionType === "S"
          ? r.min || minPriceSurvey
          : r.min || (price * indexRange) + 1

        r.max = promotionType === "S"
          ? r.max || maxPriceSurvey
          : r.max || (price * (indexRange + 1))

        return (
          <div className="duration-range" key={`range-${index}-${indexRange}`} >
            <h4>ช่วงราคาที่ {indexRange + 1}</h4>
            <div className="row">
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="เริ่มต้น">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={r.min}
                      onChange={this.onStartChange.bind(this, r)}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="สิ้นสุด">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={r.max}
                      onChange={this.onEndChange.bind(this, r)}
                    />
                  </div>
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="ส่วนลด Platform">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={platform_discount}
                      onChange={this.onValueChange.bind(this, r, 'platform_discount')}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="ส่วนลด  BU">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={bu_discount}
                      onChange={this.onValueChange.bind(this, r, 'bu_discount')}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="ส่วนลด Partner">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={partner_discount}
                      onChange={this.onValueChange.bind(this, r, 'partner_discount')}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="ส่วนลดช่าง">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={contractor_discount}
                      onChange={this.onValueChange.bind(this, r, 'contractor_discount')}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="ส่วนลดรวม">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={sumDiscount || 0}
                      disabled
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <EnButton
                  className="pull-left btn-danger"
                  onClick={this.deleteItemsRange.bind(this, r)}
                  style={{ marginTop: '35px' }}
                  disabled={indexRange + 1 < item.range.length ? true : false}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                </EnButton>
              </div>
            </div >
            {/* {
              item.daily_promotion &&
              <Fragment>
                <div className="row" style={{ paddingLeft: '15px' }}>
                  <DateCheckBox items={item.range} index={indexRange} onDateCheckBoxChange={this.onDateCheckBoxChange.bind(this, indexRange, index)} />
                </div>
                {indexRange !== (item.range.length - 1) ? <hr style={{ height: 2 }} /> : <hr style={{
                  clear: 'both',
                  visibility: 'hidden',
                }} />}
              </Fragment>
            } */}
          </div >
        );
      });

      return (
        <div key={`item-${index}`} className="card item-card" style={{ border: '1px solid  #BCC5CC' }} >
          <div className="card-content">
            {/* show main & sub category */}
            {common.hasCommonElements(v2Channels, info.channel) && (
              <>
                <FormGroup containerClassName="col-md-6" label="หมวดหมู่หลัก">
                  <EnTagSelect
                    withHeight
                    placeholder="กรุณาเลือกหมวดหมู่หลัก"
                    closeOnSelect={false}
                    options={_.get(this.state.mainCategoryOptions, [`${item.key}`], [])}
                    value={_.get(this.state.selectedMainCategory, [`${item.key}`], [])}
                    onTagChange={this.onChangeMainCategory(item)}
                    searchable
                  />
                </FormGroup>
                <FormGroup containerClassName="col-md-6" label="หมวดหมู่ย่อย">
                  <EnTagSelect
                    withHeight
                    placeholder="กรุณาเลือกหมวดหมู่ย่อย"
                    closeOnSelect={false}
                    options={_.get(this.state.subCategoryOptions, [item.key], [])}
                    value={_.get(this.state.selectedSubCategory, [item.key], [])}
                    onTagChange={this.onChangeSubCategory(item)}
                    searchable
                  />
                </FormGroup>
              </>
            )}
            <FormGroup containerClassName="col-md-8" label="สินค้าที่ร่วมรายการ">
              <EnTagSelect
                withHeight
                placeholder="กรุณาเลือกสินค้า"
                disabled={isAllow("EDIT_PROMOTIONAL_PRODUCT_DETAILS") ? false : true}
                closeOnSelect={false}
                options={orderProduct}
                value={item.selectedId}
                onTagChange={this.onChangeProduct.bind(this, item)}
                searchable={false}
              />
            </FormGroup>
            <FormGroup containerClassName="col-md-4" label="ราคาส่วนลด default">
              <div style={{ paddingTop: '5px' }}>
                <EnTextFormGroup
                  type="number"
                  value={item.value}
                  onChange={this.onPriceChange.bind(this, item)}
                // disabled={disabled}
                />
              </div>
            </FormGroup>
            <div className="col-md-12 switch-field">
              <input
                type="radio"
                key={`switch_right-${index}`}
                id={`switch_right-${index}`}
                name={`switch_right-${index}`}
                value={!item.promotion_range}
                onChange={this.onPromotionRange.bind(this, index)}
                checked={!item.promotion_range}
              />
              <label htmlFor={`switch_right-${index}`}>
                {!item.promotion_range && <i className="fa fa-check" aria-hidden="true" />} โปรโมชั่นเดียว
              </label>
              <input
                type="radio"
                key={`switch_left-${index}`}
                id={`switch_left-${index}`}
                name={`switch_left-${index}`}
                value={item.promotion_range}
                onChange={this.onPromotionRange.bind(this, index)}
                checked={item.promotion_range}
                disabled={item.selectedId.length > 1 && promotionType === "I" ? 1 : 0}
              />
              <label htmlFor={`switch_left-${index}`}>
                {item.promotion_range && <i className="fa fa-check" aria-hidden="true" />} โปรโมชั่นช่วงราคา
              </label>
            </div>
            <FormGroup containerClassName="col-md-12">
              <EnToggle checked={item.daily_promotion} onChange={this.onPromotionCheck.bind(this, index)} label={'โปรโมชั่นรายวัน'} />
            </FormGroup>
            {item.daily_promotion && (
              <Fragment>
                <div className="col-md-12" style={{ paddingLeft: '10px', paddingBottom: '25px' }}>
                  <DateCheckBox items={item} index={index} onDateCheckBoxChange={this.onDateCheckBoxChange.bind(this, index, index)} />
                </div>
              </Fragment>
            )}

            {(item.promotion_range && (item.selectedId.length < 2 || promotionType === "S")) && (
              <Fragment>
                <div className="" style={{ float: 'left', width: '100%', marginBottom: '20px' }}>
                  {/* <div className="header-duration" style={{ height: '70px' }}>
                    ช่วงราคา <span style={{ color: 'red' }}>( * หมายเหตุ ในกรณีช่วงราคาซ้ำ จะใช้ราคาส่วนลดมากที่สุด )"</span>
                    <EnButton className="btn-success pull-right" onClick={this.onAddRange.bind(this, item)} style={{ margin: '0 10px 0 0' }}>
                      <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่มช่วงราคา
                    </EnButton>
                  </div> */}
                  <div className=" duration-form">
                    {priceRange}
                  </div>
                </div>
                <div className="col-md-12 text-center" style={{ margin: '0 0 30px 0' }}>
                  <EnButton className="btn-success" onClick={this.onAddRange.bind(this, item)}>
                    <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่มช่วงราคา
                  </EnButton>
                </div>
              </Fragment>
            )}
            {this.state.items.length > 1 &&
              <EnButton className="pull-right" onClick={this.deleteItems.bind(this, item)} >
                <i className="fa fa-trash btn-icon" aria-hidden="true" />ลบ
              </EnButton>
            }
          </div>
        </div >
      );
    });
    return items;
  }

  onTypeChange = (e) => {
    if (e && e.target) {
      this.props.coupon.saveInfo('type', e.target.value);

      const productItems = this.props.product.toJS().items;
      let items = [
        {
          key: (new Date()).getTime(),
          selectedId: [],
          product: productItems,
          range: [],
          value: null,
          daily_promotion: false,
          date: [],
          promotion_range: false,
        }
      ];

      const { mainCategoryOptions: m, subCategoryOptions: s } = this.getCategoryOptions([])
      let mainCategoryOptions = {}, subCategoryOptions = {};
      for (let i = 0; i < items.length; i++) {
        mainCategoryOptions = { ...mainCategoryOptions, [items[i].key]: m }
        subCategoryOptions = { ...subCategoryOptions, [items[i].key]: s }
      }
      this.setState({
        items,
        mainCategoryOptions,
        subCategoryOptions,
      })
    }
  }

  onPriceChange = (selectedItems, e) => {
    const items = _.cloneDeep(this.state.items);
    if (e && e.target) {
      let item = items.find(i => i.key === selectedItems.key);
      item.value = e.target.value;
    }
    this.setState({ items });
  }

  onStartChange = (range, e) => {
    if (!e?.target) {
      return;
    }

    const items = _.cloneDeep(this.state.items);
    items.forEach(item => {
      const rangeItem = item.range.find(r => r.key === range.key);
      if (rangeItem) {
        rangeItem.min = +e.target.value;
      }
    });

    this.setState({ items });
  }

  onEndChange = (range, e) => {
    if (!e?.target) {
      return;
    }

    const items = _.cloneDeep(this.state.items);
    items.forEach(item => {
      const rangeItem = item.range.find(r => r.key === range.key);
      if (rangeItem) {
        rangeItem.max = +e.target.value;
      }
    });

    this.setState({ items });
  }

  calculateSumDiscount(rangeItem) {
    let sumDiscount = 0
    const listDiscount = ["contractor_discount", 'bu_discount', 'partner_discount', 'platform_discount']

    _.forEach(listDiscount, key => {
      if (rangeItem[key]) {
        sumDiscount += rangeItem[key] || 0
      }
    })
    return sumDiscount
  }

  onValueChange = (range, key = 'value', e) => {
    if (!e?.target) {
      return;
    }

    const items = _.cloneDeep(this.state.items);
    items.forEach(item => {
      const rangeItem = item.range.find(i => i.key === range.key);
      if (rangeItem && key) {
        rangeItem[key] = +e.target.value;
        rangeItem.value = this.calculateSumDiscount(rangeItem);
      }
    });

    this.setState({ items });
  }

  showProvinceItem() {
    let provinceOptions;
    let items = (this.state.provinceItems || []).map((item, index) => {
      provinceOptions = (item || []).map(d => {
        return ({
          label: d['name'],
          value: d['code'],
        });
      });

      if (this.state.allProvince.length !== this.state.provinceItems[0].selected_id.length) {
        provinceOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...provinceOptions];
      }

      return (
        <div key={`item-${index}`}>
          <div>
            <FormGroup label="เลือกจังหวัดที่เข้าร่วมโปรโมชั่น">
              <EnTagSelect
                disabled={isAllow("EDIT_PROMOTIONAL_PROVINCE") ? false : true}
                closeOnSelect={false}
                options={provinceOptions}
                onTagChange={this.onProvinceChange.bind(this, item)}
                value={item['selected_id']}
                searchable
              />
            </FormGroup>
          </div>
        </div>
      );
    });
    return items;
  }

  showBranchItem() {
    let branchOptions;
    let items = (this.state.branchItems || []).map((item, index) => {
      branchOptions = (item || []).map(d => {
        return ({
          label: d['name'],
          value: d['_id'],
        });
      });

      if (this.state.allBranch.length !== this.state.branchItems[0].selected_id.length) {
        branchOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...branchOptions];
      }

      return (
        <div key={`item-${index}`}>
          <div>
            <FormGroup label="สาขา *">
              <EnTagSelect
                className={this.state.branchItems[0]
                  && this.state.branchItems[0].selected_id[0]
                  && this.state.branchItems[0].selected_id.length
                  ? '' : 'error-select-branch'}
                closeOnSelect={false}
                options={branchOptions}
                onTagChange={this.onBranchChange.bind(this, item)}
                value={item['selected_id']}
                searchable
              />
            </FormGroup>
          </div>
        </div>
      );
    });
    return items;
  }

  onProvinceChange(selectedItem, e) {
    let provinceItems = _.cloneDeep(this.state.provinceItems);
    if (e.indexOf('ALL') >= 0) {
      const code = this.state.allProvince.map(p => p.code);
      let item = provinceItems.find(i => i.key === selectedItem.key);
      item['selected_id'] = code;
    } else {
      let code = e.split(',');
      let item = provinceItems.find(i => i.key === selectedItem.key);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item['selected_id'] = code;
      }

    }
    this.setState({
      provinceItems,
    });
  }

  onBranchChange(selectedItem, e) {
    let branchItems = _.cloneDeep(this.state.branchItems);
    if (e.indexOf('ALL') >= 0) {
      const branchId = this.state.allBranch.map(b => b._id);
      let item = branchItems.find(i => i.key === selectedItem.key);
      item['selected_id'] = branchId;
    } else {
      let branchId = e.split(',');
      let item = branchItems.find(i => i.key === selectedItem.key);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item['selected_id'] = branchId;
      }
    }

    this.setState({
      branchItems,
    });
  }

  async onCopyPromotionCode() {
    const info = this.props.coupon.toJS().info;
    let confirmDialogOptions = {
      title: 'คัดลอกข้อมูล',
      text: 'กรุณายืนยันการคัดลอกส่วนลดนี้',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          let nameCopy = info.name + ' (Copy)';
          this.props.coupon.saveInfo('name', nameCopy);
          this.props.coupon.saveInfo('adBanner', null);
          this.props.coupon.saveInfo('heroBanner', null);
          this.props.coupon.saveInfo('heroBannerSquare', null);
          this.props.coupon.saveInfo('promotionBottomBanner', null);
          this.props.coupon.saveInfo('promotionThumbnail', null);
          // this.props.coupon.saveInfo('code', '');
          this.props.coupon.deleteInfo('id');
          this.props.coupon.deleteInformation();
          this.setState({
            isCopyPromotionCode: true,
          });
          // await this.submitForm();
          this.props.history.push(`${config.publicUrl}/qchang/coupon/add`);
          return true;
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          let nameCopy = info.name;
          this.props.coupon.saveInfo('name', nameCopy);
          this.setState({
            isCopyPromotionCode: false,
          });
          return false;
          // swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      }).then((result) => {
        if (result.value) {
          setTimeout(() => {
            // this.onCancel();
            window.scrollTo(0, 0);
          }, 200);
        } else {
          setTimeout(() => {
            // this.onCancel();
            window.scrollTo(0, 0);
          }, 200);
        }
      });
    }
  }

  async onSetMisc(selectedChannelNames) {
    const branch = await this.props.coupon.getStoreByID({ channel: selectedChannelNames });
    
    this.props.misc.saveId('miscData.branch', branch || []);

    const misc = this.props.misc.toJS().miscData;
    misc.branch['selected_id'] = [];

    this.setState({
      branchItems: [misc.branch],
      allBranch: misc.branch
    });
  }

  onSelectChannel = async (selectedOptions) => {
    const selectedChannelNames = selectedOptions.map(({ value }) => value);
    this.props.coupon.saveInfo('channel', selectedChannelNames);
    const channel = this.props.channel.toJS().info;
    const selectedChannel = channel.filter(c => selectedChannelNames.includes(c.channel_name));
    const contactChannel = selectedChannel && selectedChannel.length ? selectedChannel[0].contact_channel : '';
    const creditTerm = selectedChannel && selectedChannel.length ? selectedChannel[0].credit_term : 1;
    this.props.coupon.saveInfo('contactChannel', contactChannel);
    this.props.coupon.saveInfo('creditTerm', creditTerm);
    await this.onSetMisc(selectedChannelNames);
  }

  handleBenefitChange = (selectedBenefits) => {
    this.setState({ selectedBenefits });
  };

  onSelectModel = async ({ value }) => {
    this.props.coupon.saveInfo('model', value);
    this.props.coupon.saveInfo('requestStorePayment', false);
    this.props.coupon.saveInfo('storePaymentNotiEmails', []);
  }

  handleSelectChannelGroup = ({ value: selectedChannelGroup }) => {
    const { coupon: couponStore } = this.props;
    const currentChannelGroup = couponStore.toJS().info.productChannel;

    if (currentChannelGroup !== selectedChannelGroup) {
      couponStore.saveInfo('productChannel', selectedChannelGroup);
      couponStore.saveInfo('channel', []);
      this.clearSelectedProducts();
    }
  };

  handleFocusChannel = () => {
    const { coupon: couponStore } = this.props;
    const { productChannel: channelGroup } = couponStore.toJS().info;
    this.setState({ displayChannelGroupErrorMessage: !channelGroup });
  };

  validate = {
    isValid: () => {
      const items = this.state.items;
      let isValid = true;
      items.map((item, index) => {
        // check if daily promotion
        if (item.daily_promotion) {
          // find duplicate min max in range
          item.range.map(i => {
            const min = i.min;
            const max = i.max;
            const findDuplicate = item.range.filter(i => i.min === min && i.max === max);
            if (findDuplicate && findDuplicate.length > 1) {
              let result = [];
              findDuplicate.map(i => {
                if (i.date && i.date.length) {
                  result.push(...i.date);
                }
              });
              // check duplicate
              const isDuplicate = this.validate.isDuplicate(result);
              if (isDuplicate) {
                isValid = false;
              }
            }
          });
        }
      });
      return isValid;
    },
    isDuplicate: (array) => {
      return (new Set(array)).size !== array.length;
    },
  }

  /**
   * Get channel options depends on selected channel group.
   */
  getChannelOptions = () => {
    const { coupon: couponStore, channel: channelStore } = this.props;
    const { productChannel: channelGroup } = couponStore.toJS().info;
    const channels = channelStore.toJS().info || [];
    const v2Channels = channels.filter(item => item.product_channel.toLowerCase() === 'v2').map(item => item.channel_name);

    if (channelGroup === CHANNEL_GROUP.V1) {
      return channels.reduce((prevVal, { channel_name: channelName }) => {
        if (!v2Channels.includes(channelName)) {
          prevVal.push({ label: channelName, value: channelName });
        }
        return prevVal;
      }, []);
    } else if (channelGroup === CHANNEL_GROUP.V2) {
      return v2Channels.map((channelName) => ({ label: channelName, value: channelName }));
    }
    return [];
  };

  getModelOptions = () => {
    const model = this.props.model.toJS().info;

    return (model || []).map((c, index) => {
      return {
        label: c.model_name,
        value: c.model_name,
      };
    });

  };

  disableBtnFilterProducts() {
    let disabled = true
    const { name = '', code = '' } = this.props.coupon.toJS().info
    if (name && code) {
      disabled = false
    }
    return disabled
  }

  openAdBannerDropzone() {
    this.setState({
      adBannerDropzoneOpen: true,
    });
  }

  renderAdBannerImage(adBanner) {
    if (!adBanner) {
      return (
        <div className="no-ad-banner-image">ไม่มีรูป Ad Banner</div>
      );
    }
    return (
      <div>
        <div
          className="ad-banner-delete"
          onClick={() => { this.props.coupon.saveInfo('adBanner', null); }}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <img src={adBanner} className="ad-banner-img" />
      </div>
    );
  }

  async onSaveAdBannerDropzone(files) {
    try {
      const result = await this.props.coupon.uploadBannerImage(files[0]);
      this.props.coupon.saveInfo('adBanner', result.path);
    } catch (error) {
      swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
    } finally {
      this.setState({
        adBannerDropzoneOpen: false,
      });
    }
  }

  closeAdBannerDropzone() {
    this.setState({
      adBannerDropzoneOpen: false,
    });
  }

  openHeroBannerDropzone() {
    this.setState({
      adHeroBannerDropzoneOpen: true,
    });
  }

  renderHeroBannerImage(heroBanner) {
    if (!heroBanner) {
      return (
        <div className="no-ad-banner-image">ไม่มีรูป Hero Banner</div>
      );
    }
    return (
      <div>
        <div
          className="ad-banner-delete"
          onClick={() => { this.props.coupon.saveInfo('heroBanner', null); }}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <img src={heroBanner} className="ad-banner-img" />
      </div>
    );
  }

  async onSaveAdHeroBannerDropzone(files) {
    try {
      const result = await this.props.coupon.uploadBannerImage(files[0]);
      this.props.coupon.saveInfo('heroBanner', result.path);
    } catch (error) {
      swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
    } finally {
      this.setState({
        adHeroBannerDropzoneOpen: false,
      });
    }
  }

  closeAdHeroBannerDropzone() {
    this.setState({
      adHeroBannerDropzoneOpen: false,
    });
  }

  openCardPromotionBannerDropzone() {
    this.setState({
      adCardPromotionBannerDropzoneOpen: true,
    });
  }

  renderCardPromotionBannerImage(heroBannerSquare) {
    if (!heroBannerSquare) {
      return (
        <div className="no-ad-banner-image">ไม่มีรูป Card Promotion Banner</div>
      );
    }
    return (
      <div>
        <div
          className="ad-banner-delete"
          onClick={() => { this.props.coupon.saveInfo('heroBannerSquare', null); }}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <img src={heroBannerSquare} className="ad-banner-img" />
      </div>
    );
  }

  async onSaveAdCardPromotionBannerDropzone(files) {
    try {
      const result = await this.props.coupon.uploadBannerImage(files[0]);
      this.props.coupon.saveInfo('heroBannerSquare', result.path);
    } catch (error) {
      swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
    } finally {
      this.setState({
        adCardPromotionBannerDropzoneOpen: false,
      });
    }
  }

  closeAdCardPromotionBannerDropzone() {
    this.setState({
      adCardPromotionBannerDropzoneOpen: false,
    });
  }

  openPromotionBottomBannerDropzone() {
    this.setState({
      adPromotionBottomBannerDropzoneOpen: true,
    });
  }

  openPromotionThumbnailDropzone() {
    this.setState({
      adPromotionThumbnailDropzoneOpen: true,
    });
  }

  renderPromotionBottomBannerImage(promotionBottomBanner) {
    if (!promotionBottomBanner) {
      return (
        <div className="no-ad-banner-image">ไม่มีรูป Promotion Bottom Banner</div>
      );
    }
    return (
      <div>
        <div
          className="ad-banner-delete"
          onClick={() => { this.props.coupon.saveInfo('promotionBottomBanner', null); }}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <img src={promotionBottomBanner} className="ad-banner-img" />
      </div>
    );
  }

  renderPromotionThumbnailImage(promotionThumbnail) {
    if (!promotionThumbnail) {
      return (
        <div className="no-ad-banner-image">ไม่มีรูป Promotion Thumbnail</div>
      );
    }
    return (
      <div>
        <div
          className="ad-banner-delete"
          onClick={() => { this.props.coupon.saveInfo('promotionThumbnail', null); }}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <img src={promotionThumbnail} className="ad-banner-img" />
      </div>
    );
  }

  async onSaveAdPromotionBottomBannerDropzone(files) {
    try {
      const result = await this.props.coupon.uploadBannerImage(files[0]);
      this.props.coupon.saveInfo('promotionBottomBanner', result.path);
    } catch (error) {
      swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
    } finally {
      this.setState({
        adPromotionBottomBannerDropzoneOpen: false,
      });
    }
  }

  async onSaveAdPromotionThumbnailDropzone(files) {
    try {
      const result = await this.props.coupon.uploadBannerImage(files[0]);
      this.props.coupon.saveInfo('promotionThumbnail', result.path);
    } catch (error) {
      swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
      console.error('onSaveAdPromotionThumbnailDropzone', error.message)
    } finally {
      this.setState({
        adPromotionThumbnailDropzoneOpen: false,
      });
    }
  }

  closeAdPromotionBottomBannerDropzone() {
    this.setState({
      adPromotionBottomBannerDropzoneOpen: false,
    });
  }

  closeAdPromotionThumbnailDropzone() {
    this.setState({
      adPromotionThumbnailDropzoneOpen: false,
    });
  }

  renderInformations(info) {
    const index = 1;
    return (
      <div className="coupon-information">
        <div className="row" style={{ paddingLeft: '15px' }} >
          <label>{`ข้อมูลและรายละเอียด`}</label>
        </div>
        {/* <div className="row">
          <EnTextFormGroup
            containerClassName="col-sm-3 col-md-3"
            type="number"
            placeholder="seq.No"
            value={info.seqNo}
            onChange={this.onInfomationChange.bind(this, 'seqNo')}
            validations={[required, positiveIntegers]}
          />
        </div> */}
        <div className="row">

          <EnTextFormGroup
            containerClassName="col-sm-10 col-md-11"
            type="text"
            placeholder="หัวข้อ"
            value={info.title}
            onChange={this.onInfomationChange.bind(this, 'title')}
            validations={[required]}
            label="หัวข้อ"
            notRequired
          />
          <div className="col-sm-2 col-md-1 information-button-desktop">
            <EnButton
              className="btn-danger"
              style={{ marginTop: '0' }}
              onClick={this.onDeleteInfomationClick.bind(this)}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </EnButton>
          </div>
        </div>
        <div className="row">
          <EnTextFormGroup
            containerClassName="col-md-12"
            type="text"
            placeholder="รายละเอียด"
            value={info.description}
            onChange={this.onInfomationChange.bind(this, 'description')}
            validations={[required]}
            label="รายละเอียด"
            notRequired
          />
        </div>

        <div className="row">
          <EnTextFormGroup
            containerClassName="col-md-12"
            type="text"
            placeholder="หัวข้อคูปอง"
            value={info.couponHeader}
            onChange={this.onInfomationChange.bind(this, 'couponHeader')}
            validations={[couponHeader]}
            label={<span>หัวข้อคูปอง <span style={{ color: 'red' }}> *จำกัด 12ตัวอักษร</span></span>}
            notRequired
            maxLength={12}
          />
        </div>
        <div className="row">

          <EnTextFormGroup
            containerClassName="col-md-12"
            type="text"
            placeholder="เงื่อนไขโดยย่อ"
            value={info.termsBrief}
            onChange={this.onInfomationChange.bind(this, 'termsBrief')}
            validations={[limitText25]}
            label="เงื่อนไขโดยย่อ"
            notRequired
          />
        </div>
        <div className="row">
          <EnTextFormGroup
            containerClassName="col-md-12"
            type="text"
            placeholder="เงื่อนไขการใช้งาน"
            value={info.termsUse}
            onChange={this.onInfomationChange.bind(this, 'termsUse')}
            validations={[limitText100]}
            label="เงื่อนไขการใช้งาน"
            notRequired
          />
        </div>
        <div className="row">
          <div className="col-md-6" style={{ padding: '0px' }}>
            <FormGroup containerClassName="col-md-6" label="ใช้สำหรับหน้า promotion detail" >
              <EnToggle
                checked={this.state.isOnlyPromotionDetail}
                onChange={(value) => this.setState({ isOnlyPromotionDetail: value })}
                noPadding={true}
              />
            </FormGroup>
            <FormGroup containerClassName="col-md-6" label="สื่อส่งเสริมการขาย" >
              <EnToggle
                checked={this.state.isBannerPromotion}
                onChange={(value) =>this.setState({ isBannerPromotion: value })}
                noPadding={true}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <FormGroup containerClassName="col-md-12">
            <EnCKEditor
              type="coupon"
              value={info.content}
              onChange={(event, editor) => {
                this.props.coupon.saveInformation('content', editor.getData());
              }}
            />
          </FormGroup>
        </div>
        <div className="row information-button-mobile">
          <div className="col-md-12">
            <EnButton
              className="btn-danger"
              style={{ marginTop: '0' }}
              onClick={this.onDeleteInfomationClick.bind(this, index)}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </EnButton>
          </div>
        </div>
      </div>
    );
  }

  onInfomationChange(key, e) {
    const value = _.get(e, 'target.value', e);
    this.props.coupon.saveInformation(key, value);
  }

  onDeleteInfomationClick(e) {
    e.preventDefault();
    this.props.coupon.deleteInformation();
  }

  async onSubmitBanner() {
    try {
      const { information, ...info } = this.props.coupon.toJS().info;
      const updateInfo = {
        ...info,
        isBannerPromotion: this.state.isBannerPromotion,
        information: {
          ...information,
          isOnlyPromotionDetail: this.state.isOnlyPromotionDetail,
        }
      }
      await this.props.coupon.updateBannerInfo(this.state.id, updateInfo);
      toast.success('แก้ไขข้อมูลเรียบร้อย');
      this.setState({ activeIndex: 2 });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error(error.message);
    }
  }

  onChangeEmails(tags) {
    let emails = tags.map(item => item.split(','));
    emails = _.uniq(_.flatten(emails)).filter(item => item);
    this.props.coupon.saveInfo('storePaymentNotiEmails', emails);
  }

  handleLimitedUseMemberToggle(value) {
    const { useLimit, isLimit, limitedUsePerMember } = this.props.coupon.toJS().info
    if (value && isLimit && +limitedUsePerMember > +useLimit) {
      this.setState({ limitedUsePerMemberError: true })
    } else {
      this.setState({ limitedUsePerMemberError: false })
    }

    if (!value) {
      // reset limited use per member
      this.props.coupon.saveInfo('limitedUsePerMember', 1);
    }

    this.props.coupon.saveInfo('isLimitedUseMember', value);
  }

  clearSelectedProducts = () => {
    const { product: productStore } = this.props;
    const { mainCategoryOptions, subCategoryOptions } = this.getCategoryOptions([]);
    const key = Date.now();
    const newItems = [
      {
        key,
        selectedId: [],
        product: productStore.toJS().items,
        value: 0,
        range: [],
        daily_promotion: false,
        date: [],
        promotion_range: false,
      },
    ];

    this.setState({
      items: newItems,
      mainCategoryOptions: { [key]: mainCategoryOptions },
      subCategoryOptions: { [key]: subCategoryOptions },
      selectedProduct: [],
    });
  };

  onSearchBenefit = (value) => {
    this.props.coupon.searchBenefit(value);
  }

  onClose = () => {
    this.props.coupon.clearBenefitOption();
  }

  render() {
    const { isEdit, displayChannelGroupErrorMessage, selectedBenefits } = this.state;
    const info = this.props.coupon.toJS().info;
    const { isLoading, couponType, benefitOption } = this.props.coupon.toJS();
    const title = this.state.isEdit ? 'แก้ไขส่วนลด' : 'เพิ่มส่วนลดใหม่';
    const type = couponType.map((item, index) => {
      return (<option key={`coupon-${index}`} value={item.value}>{item.label}</option>);
    });
    const disabledBtnFilterProducts = this.disableBtnFilterProducts()
    const isSaveBtnDisabled = (isEdit && !isAllow('EDIT_COUPON_DETAILS')) || !this.isValid();

    let prepareBenefitOptions = benefitOption &&
    benefitOption.map((item) => ({
      label: `${item.code} (${item.name})`,
      code: item.code,
      name: item.name,
      value: item._id
    }));

    //init exist benefit
    if(prepareBenefitOptions.length === 0 && selectedBenefits.length > 0) {
      prepareBenefitOptions = info && info.benefits ? info.benefits.map((item) => ({
        label: `${item.code} (${item.name})`,
        code: item.code,
        name: item.name,
        value: item.id
      })) : [];
    }

    return (
      <Container isAdmin className="coupon-container">
        <div className="row">
          <Loader show={isLoading} />
          <div className="col-md-12 coupon-page">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{title}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <div className="card-content">
                  <FormValidation>
                    <EnTab index={this.state.activeIndex}>
                      <Tab title="รายละเอียดส่วนลด">
                        <div className="row">
                          <EnTextFormGroup
                            containerClassName="col-md-6"
                            type="text"
                            label="ชื่อส่วนลด"
                            value={info.name}
                            onChange={this.onTextChange.bind(this, 'name')}
                            validations={[required]} />
                          <FormGroup label="ประเภทส่วนลด" containerClassName="col-md-6">
                            <EnDropDown value={info.type} onChange={this.onTypeChange.bind(this)}>
                              {type}
                            </EnDropDown>
                          </FormGroup>
                        </div>
                        <div className="row">
                          <FormGroup containerClassName={`col-md-6`}>
                            <EnTextFormGroup
                              // containerClassName="col-md-6"
                              type="text"
                              label="รหัสส่วนลด"
                              value={info.code}
                              onChange={this.onTextChange.bind(this, 'code')}
                              validations={[required]} />
                          </FormGroup>
                          <FormGroup containerClassName={`col-md-6`} label="วันที่" validations={[required]} >
                            <EnDateRangePicker
                              withPortal
                              numberOfMonths={1}
                              // disabled={jobInfo.status !== 'survey_finished' || isReadOnly}
                              ref={(e) => { this.instDateRange = e; }}
                              openDirection="up"
                              minimumNights={0}
                              startDate={info.startDate ? moment(info.startDate) : null}
                              endDate={info.endDate ? moment(info.endDate) : null}
                              // isDayBlocked={this.checkInstDayBlock.bind(this)}
                              onDateChange={this.onDateChange.bind(this)}
                              startDatePlaceholderText="วันที่เริ่มต้น"
                              endDatePlaceholderText="วันที่สิ้นสุด"
                              isOutsideRange={(day) => moment().startOf('day').isAfter(day)}
                            />
                          </FormGroup>
                        </div>

                        <hr />

                        <div className="row">
                          <div className="col-md-12" style={{ padding: '0px' }}>
                            <FormGroup label="ประเภทสินค้า" containerClassName="col-md-3" containerStyle={{ paddingRight: '5px' }}>
                              <Select
                                className="select-custom-height"
                                clearable={false}
                                options={channelGroupOptions}
                                placeholder="เลือกประเภทสินค้า"
                                searchable={true}
                                value={info.productChannel}
                                onChange={this.handleSelectChannelGroup}
                              />
                            </FormGroup>

                            <FormGroup label="ช่องทางการขาย" containerClassName="col-md-3" containerStyle={{ paddingRight: '5px' }}>
                              <Select
                                className="p4-select pt-0"
                                clearable={false}
                                multi
                                options={this.getChannelOptions()}
                                placeholder="เลือกช่องทางการขาย"
                                searchable={true}
                                style={displayChannelGroupErrorMessage ? { backgroundColor: 'rgba(236,88,64,.1)', borderColor: '#e53935' } : undefined}
                                value={info.channel.join()}
                                onChange={this.onSelectChannel}
                                onFocus={this.handleFocusChannel}
                              />
                              <div hidden={!displayChannelGroupErrorMessage} style={{ color: '#e53935' }}>
                                กรุณาเลือกประเภทสินค้า
                              </div>
                            </FormGroup>
                          </div>
                          <div className="col-md-12" style={{ padding: '0px' }}>
                            <FormGroup label="Model การขาย" containerClassName="col-md-3" containerStyle={{ paddingRight: '5px' }}>
                              <Select
                                className="select-custom-height"
                                searchable={false}
                                clearable={false}
                                onChange={this.onSelectModel}
                                options={this.getModelOptions()}
                                value={info.model}
                              />
                            </FormGroup>
                          </div>
                        </div>

                        {/* <BenefitSelect
                          disabled={false}
                          label='สิทธิประโยชน์'
                          options={prepareBenefitOptions || []}
                          value={selectedBenefits}
                          onChange={this.handleBenefitChange}
                          onInputChange={(value) => this.onSearchBenefit(value)}
                          onClose={() => this.onClose()}
                        /> */}

                        <Autocomplete
                          label="สิทธิประโยชน์"
                          options={prepareBenefitOptions || []}
                          values={selectedBenefits}
                          onChange={this.handleBenefitChange}
                          onInputChange={(value) => this.onSearchBenefit(value)}
                          onClose={() => this.onClose()}
                        />

                        {/* <BenefitSearchFromGroup 
                          selectedBenefits={selectedBenefits}
                          onChange={this.handleBenefitChange}
                          notiRef={toast}
                        /> */}

                        <div className='row'>
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup
                              containerClassName="col-md-12"
                              label="โค้ดลับ"
                            >
                              <span> (โค้ดจะไม่ถูกแสดงในหน้า PDP) </span>
                              <EnToggle
                                checked={info.isPrivateCode}
                                onChange={this.onTogglePrivateCode.bind(this)}
                                noPadding={true}
                              />
                            </FormGroup>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-12" label="เลือกสาขา" >
                              <EnToggle
                                checked={this.state.isSelectBranch}
                                onChange={this.onSelectBranch.bind(this)}
                                noPadding={true}
                              />
                            </FormGroup>
                          </div>
                        </div>

                        {this.state.isSelectBranch &&
                          <div className="row">
                            <div className="col-md-12">
                              {this.showBranchItem()}
                              {!(this.state.branchItems[0]
                                && this.state.branchItems[0].selected_id[0]
                                && this.state.branchItems[0].selected_id.length)
                                && <span className="input-error">กรุณาเลือกสาขาอย่างน้อย 1 สาขา</span>
                              }
                            </div>
                          </div>
                        }

                        <div className="row">
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-12" label="สถานะการใช้งาน" >
                              <EnToggle checked={info.status === 'active'} onChange={this.onToggle.bind(this)} noPadding={true} />
                            </FormGroup>
                          </div>
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-4" label="จำกัดจำนวนการใช้">
                              <EnToggle
                                disabled={isAllow("EDIT_COUPON_TIMES_LIMIT") ? false : true}
                                checked={info.isLimit}
                                onChange={this.onLimitCouponToggle.bind(this)}
                                noPadding={true}
                              />
                            </FormGroup>
                            <EnTextFormGroup
                              disabled={!info.isLimit}
                              label="จำนวนการใช้ (ครั้ง)"
                              containerClassName="col-md-8"
                              type="text"
                              value={info.useLimit}
                              onChange={this.onTextChange.bind(this, 'useLimit')}
                            />
                          </div>
                        </div>

                        <div className="row">
                          {/* QBC-296
                            <div className="col-md-6" style={{ padding: '0px' }}>
                              <FormGroup containerClassName="col-md-12" label="Marketplace เงินสด" >
                                <EnToggle disabled={isAllow("EDIT_COUPON_TERM_OF_PAYMENT") ? !info.requestNotification : true} checked={info.requestPayment} onChange={this.onRequestPaymentToggle.bind(this)} noPadding={true} />
                              </FormGroup>
                            </div>
                           end QBC-296 */}
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-12" label="การแจ้งเตือน (SMS/Email)" >
                              <EnToggle disabled={isAllow("EDIT_COUPON_STATUS_OF_NOTIFICATION") ? false : true} checked={info.requestNotification} onChange={this.onRequestNotiToggle.bind(this)} noPadding={true} />
                            </FormGroup>
                          </div>

                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-4" label="ใช้สำหรับสมาชิกเท่านั้น" >
                              <EnToggle
                                checked={info.isMember}
                                onChange={this.onSelectIsMember.bind(this)}
                                noPadding={true}
                              />
                            </FormGroup>
                            <EnTextFormGroup
                              disabled={!info.isMember || !info.isLimitedUseMember}
                              label="จำนวนการใช้ต่อคน"
                              containerClassName="col-md-8"
                              type="number"
                              value={info.limitedUsePerMember}
                              onChange={this.onValueChangeLmitUseMember.bind(this)}
                              isError={this.state.limitedUsePerMemberError}
                              errorMessage={'จำนวนการใช้ต่อสมาชิก มากกว่า จำนวนคูปองทั้งหมดที่ใช้ได้'}
                            />
                            <FormGroup label="" containerClassName="col-md-4"></FormGroup>
                              <FormGroup label="" containerClassName="col-md-6">
                                <EnCheckBox
                                  style={{ margin: '0', padding: '0' }}
                                  disabled={!info.isMember}
                                  checked={info.isLimitedUseMember}
                                  onChange={(event) => {
                                    this.handleLimitedUseMemberToggle(event.target.checked)
                                  }}
                                  text="จำกัดสิทธิการใช้งาน"
                                />
                              </FormGroup>
                          </div>

                        </div>

                        {info.model !== "Retail" && (
                          <div className="row">
                            <div className="col-md-6" style={{ padding: '0px' }}>
                              {/* QBC-296
                              <FormGroup containerClassName="col-md-4" label="Marketplace เงินเชื่อ">
                                <EnToggle checked={info.requestStorePayment} onChange={this.onRequestStorePaymentToggle.bind(this)} noPadding={true} />
                              </FormGroup>
                              end QBC-296 */}
                              {
                                info.requestStorePayment &&
                                <FormGroup containerClassName="col-md-8" label="อีเมลที่จะใช้เรียกเก็บเงิน">
                                  <TagsInput
                                    width="150px"
                                    value={info.storePaymentNotiEmails || []}
                                    inputProps={{
                                      placeholder: 'Enter Emails...',
                                    }}
                                    onChange={this.onChangeEmails.bind(this)}
                                    addOnBlur={this.onChangeEmails.bind(this)}
                                  />
                                </FormGroup>
                              }
                            </div>
                          </div>
                        )}

                        <hr />

                        <div className="row">
                          <FormGroup>
                            <EnTextFormGroup
                              containerClassName="col-md-12"
                              type="text"
                              label="ชื่อ ที่อยู่สำหรับติดต่อ"
                              placeholder="ชื่อ นามสกุล โทร. อีเมล์."
                              value={info.contactChannel}
                              onChange={this.onTextChange.bind(this, 'contactChannel')}
                            />
                          </FormGroup>
                          <FormGroup>
                            <EnTextFormGroup
                              containerClassName="col-md-4"
                              type="number"
                              label="Credit Term"
                              value={info.creditTerm}
                              onChange={this.onTextChange.bind(this, 'creditTerm')}
                            />
                          </FormGroup>
                          {/* <FormGroup containerClassName="col-md-4" label="ราคาส่วนลด default">
                            <div style={{ paddingTop: '5px' }}>
                              <EnTextFormGroup
                                type="number"
                                value={info.creditTerm}
                                onChange={this.onPriceChange.bind(this, item)}
                              />
                            </div>
                          </FormGroup> */}
                        </div>
                        

                        <div className="row">
                          <div className="col-md-12">
                            {this.showProvinceItem()}
                          </div>
                        </div>

                        <hr />

                        <div className="row">
                          <div className="col-md-12">
                            <EnButton className="btn btn-success pull-left" onClick={this.filterProductsDup.bind(this)} disabled={disabledBtnFilterProducts}>
                              <i className="fa fa-filter btn-icon" aria-hidden="true" />กรองสินค้า
                            </EnButton>
                            <EnButton className="btn btn-success pull-right" onClick={this.onAddItems.bind(this)}>
                              <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่ม
                            </EnButton>
                            {this.showItem()}
                          </div>
                        </div>
                        <div className="row coupon-button-box" >
                          {
                            (this.state.isEdit) && (
                              <EnButton id="open-copy-promotion-button" className="btn-success pull-left" onClick={this.onCopyPromotionCode.bind(this)}>
                                <i className="fa fa-copy btn-icon" aria-hidden="true" />คัดลอกส่วนลดนี้
                              </EnButton>
                            )}
                          <EnButton className="btn-info pull-right" disabled={isSaveBtnDisabled} onClick={this.onSubmit.bind(this)}>
                            <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                          </EnButton>
                          <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                            <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                          </EnButton>
                        </div>
                      </Tab>
                      {
                        !this.state.isEdit
                          ? <Tab eventKey="2" title="Banner"> <h4>คุณยังไม่ได้สร้างคูปอง จะสร้าง Banner ไม่ได้</h4></Tab>
                          : isAllow("EDIT_COUPON_BANNER") ? <Tab title="Banner">
                            <div className="row">
                              <div className="col-md-5">
                                <EnButton
                                  className="btn-success"
                                  onClick={this.openAdBannerDropzone.bind(this)}
                                >
                                  <i className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลดรูป Ad Banner
                                </EnButton>
                              </div>
                              <div className="col-md-6">
                                {this.renderAdBannerImage(info.adBanner)}
                              </div>
                              <EnDropzoneDialog
                                dialogTitle="กรุณาอัพโหลดรูปขนาด 960x190"
                                open={this.state.adBannerDropzoneOpen}
                                onSave={this.onSaveAdBannerDropzone.bind(this)}
                                onClose={this.closeAdBannerDropzone.bind(this)}
                              />
                            </div>

                            <div className="row">
                              <div className="col-md-5">
                                <EnButton
                                  className="btn-success"
                                  onClick={this.openHeroBannerDropzone.bind(this)}
                                >
                                  <i className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลดรูปสำหรับการแสดงผลบน Hero banner
                                </EnButton>
                              </div>
                              <div className="col-md-6">
                                {this.renderHeroBannerImage(info.heroBanner)}
                              </div>
                              <EnDropzoneDialog
                                dialogTitle="กรุณาอัพโหลดรูปขนาด 1920 x 650"
                                open={this.state.adHeroBannerDropzoneOpen}
                                onSave={this.onSaveAdHeroBannerDropzone.bind(this)}
                                onClose={this.closeAdHeroBannerDropzone.bind(this)}
                              />
                            </div>

                            <div className="row">
                              <div className="col-md-5">
                                <EnButton
                                  className="btn-success"
                                  onClick={this.openCardPromotionBannerDropzone.bind(this)}
                                >
                                  <i className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลดรูปสำหรับการแสดงผลบน Card promotion
                                </EnButton>
                              </div>
                              <div className="col-md-6">
                                {this.renderCardPromotionBannerImage(info.heroBannerSquare)}
                              </div>
                              <EnDropzoneDialog
                                dialogTitle="กรุณาอัพโหลดรูปขนาด 900 x 900"
                                open={this.state.adCardPromotionBannerDropzoneOpen}
                                onSave={this.onSaveAdCardPromotionBannerDropzone.bind(this)}
                                onClose={this.closeAdCardPromotionBannerDropzone.bind(this)}
                              />
                            </div>

                            <div className="row">
                              <div className="col-md-5">
                                <EnButton
                                  className="btn-success"
                                  onClick={this.openPromotionBottomBannerDropzone.bind(this)}
                                >
                                  <i className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลดรูปสำหรับการแสดงผลบน Promotion Bottom
                                </EnButton>
                              </div>
                              <div className="col-md-6">
                                {this.renderPromotionBottomBannerImage(info.promotionBottomBanner)}
                              </div>
                              <EnDropzoneDialog
                                dialogTitle="กรุณาอัพโหลดรูปขนาด 1122 x 488"
                                open={this.state.adPromotionBottomBannerDropzoneOpen}
                                onSave={this.onSaveAdPromotionBottomBannerDropzone.bind(this)}
                                onClose={this.closeAdPromotionBottomBannerDropzone.bind(this)}
                              />
                            </div>
                            {/* Thumbnail */}
                            <div className="row">
                              <div className="col-md-5">
                                <EnButton
                                  className="btn-success"
                                  onClick={this.openPromotionThumbnailDropzone.bind(this)}
                                >
                                  <i className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลดรูปสำหรับการแสดงผลบน Promotion Thumbnail
                                </EnButton>
                              </div>
                              <div className="col-md-6">
                                {this.renderPromotionThumbnailImage(info.promotionThumbnail)}
                              </div>
                              <EnDropzoneDialog
                                dialogTitle="กรุณาอัพโหลดรูปขนาด 900 x 900"
                                open={this.state.adPromotionThumbnailDropzoneOpen}
                                onSave={this.onSaveAdPromotionThumbnailDropzone.bind(this)}
                                onClose={this.closeAdPromotionThumbnailDropzone.bind(this)}
                                maxFileSize={1000000}
                              />
                            </div>
                            <hr />
                            {this.renderInformations(info.information)}
                            <div className="row">
                              <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={this.onSubmitBanner.bind(this)}>
                                <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                              </EnButton>
                            </div>
                          </Tab> : <div></div>
                      }
                    </EnTab>
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('coupon', 'product', 'misc', 'channel', 'model')(observer(CouponPage));

import React from 'react';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import EnTextFormGroup from '../../../components/form/EnText';
import FormGroup from '../../../components/form/FormGroup';
import { isAllow } from '../../../utils/permission';
import { toast } from 'react-toastify';

export class SetupCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      date: new Date(),
      holidayName: '',
      focused: false
    };
  }

  async componentWillMount() {
    try {
      if (!isAllow('VIEW_LIST_OF_DAY_OFF')) {
        return;
      }
      await this.props.setupCalendarStore.getCalendarDayOff();
    } catch (err) {
      toast.error(err.message);
    }
  }

  clear() {
    this.setState({ date: new Date(), holidayName: '' })
  }

  openModal() {
    this.setState({ showModal: true })
  }

  async deleteHoliday(item) {
    await this.props.setupCalendarStore.deleteCalendarDayOff(item)
    this.props.setupCalendarStore.getCalendarDayOff();

  }

  formatDate(date) {
    return moment(date).format('DD MMMM');
  }

  getTableRows(items) {
    return items.map((item, index) => {
      return (
        <tr key={item._id}>
          <td className="text-center">{index + 1}</td>
          <td className="text-center">{this.formatDate(item.day_off)}</td>
          <td className="text-center">
            {item.day_name}
          </td>
          <td className="text-center">
            <button
              className="btn btn-danger btn-xs"
              title="ลบ"
              data-tip="ลบ"
              onClick={this.deleteHoliday.bind(this, item)}
              disabled={!isAllow('DELETE_DAY_OFF')}
            >
              <span className="fa fa-trash" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  onChangeDate(date) {
    this.setState({ date: date.toDate() })
  }
  async onSave() {
    let { info } = this.props.setupCalendarStore.toJS();
    let findDate = info.find(data => this.formatDate(data.day_off) === this.formatDate(this.state.date))
    if (!findDate) {
      let item = {
        dayOff: this.state.date,
        dayName: this.state.holidayName
      }
      await this.props.setupCalendarStore.saveCalendarDayOff(item)
      this.props.setupCalendarStore.getCalendarDayOff()
      this.closeModal()
      swal.fire({
        icon: 'success',
        title: 'บันทึกข้อมูลสำเร็จ',
      });
    } else {
      swal.fire({
        icon: 'error',
        title: 'ข้อมูลวันที่ซ้ำกับในระบบ',
      });
    }

  }

  closeModal() {
    this.setState({ showModal: false })
    this.clear()
  }

  render() {
    let { info, isLoading } = this.props.setupCalendarStore.toJS();
    let rows = info && this.getTableRows(info);
    const { date } = this.state
    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <div className="row">
          <Loader show={this.state.isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ตั้งค่าปฏิทินสำหรับให้บริการลูกค้า</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="5%" className="text-center">ลำดับ</th>
                            <th width="15%" className="text-center">วันที่</th>
                            <th width="55%" className="text-center">ชื่อวันหยุด</th>
                            <th width="10%" className="text-center">
                              <button
                                className="btn btn-success"
                                title="สร้างใหม่"
                                data-tip="สร้างใหม่"
                                onClick={this.openModal.bind(this)}
                                disabled={!isAllow('CREATE_DAY_OFF')}
                              >
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showModal}
          onHide={e => { this.closeModal() }}
        >
          <Modal.Header closeButton={false} style={{ paddingTop: 12, paddingBottom: 12 }}>
            <Modal.Title style={{ fontWeight: 'bold', fontSize: 32 }}>สร้างวันหยุด</Modal.Title>
          </Modal.Header>
          <div style={{ height: '25vh', paddingLeft: 12, paddingRight: 12 }}>
            <label className="col-md-12 control-label">วันที่</label>
            <div style={{ marginTop: 5 }} className="col-md-12">
              <SingleDatePicker
                id="createStartDate"
                hideKeyboardShortcutsPanel
                isOutsideRange={() => false}
                onDateChange={this.onChangeDate.bind(this)}
                displayFormat={'DD MMMM'}
                date={date ? moment(date) : undefined}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                numberOfMonths={1}
                placeholder="เลือก.." />
            </div>
            <label style={{ marginTop: 12 }} className="col-md-12 control-label">ชื่อวันหยุด</label>
            <div style={{ marginTop: 5 }} className="col-md-12">
              <FormGroup  >
                <EnTextFormGroup
                  id="holidayName"
                  className="col-md-12"
                  type="text"
                  label={'รายละเอียด *'}
                  value={this.state.holidayName}
                  onChange={e => {
                    this.setState({
                      holidayName: e.target.value
                    })
                  }}
                />
              </FormGroup>
            </div>
          </div>
          <div style={{ justifyContent: 'end', display: 'flex' }}>
            <button
              className="btn btn-info"
              title="สร้างใหม่"
              data-tip="สร้างใหม่"
              onClick={e => { this.onSave() }}
              disabled={this.state.holidayName && isAllow('CREATE_DAY_OFF') ? false : true}>
              <span className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
            </button>
            <button className="btn btn-danger" title="ยกเลิก" data-tip="ยกเลิก" onClick={e => { this.closeModal() }} >
              <span className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
            </button>
          </div>
        </Modal>
      </Container >
    );
  }
}
export default inject('setupCalendarStore')(observer(SetupCalendar));
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import qs from 'query-string';
import swal from 'sweetalert2';
import StarRatings from 'react-star-ratings';

import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import { FormValidation, required } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnButton from '../../../components/form/EnButton';
import EnDropZone from '../../../components/form/EnDropZone';
import EnDropDown from '../../../components/form/EnDropDown';
import EnToggle from '../../../components/form/EnToggle';
import ImagePicker from '../../../components/form/ImagePicker';
import AuthStore from '../../../stores/AuthStore';
import { config } from '../../../config';
import common from '../../../utils/common';
import ImagesModal from './ImagesModal';
import { isAllow } from '../../../utils/permission';
import TextArea from '../../../componentsV2/input/textArea';
import { toast } from 'react-toastify';

export class ProjectPage extends Component {
  constructor(props) {
    super(props);
    const query = qs.parse(this.props.history.location.search);
    const { id } = this.props.match.params;
    this.state = {
      id,
      solutionId: query.solution_id,
      isEdit: id && id.length,
      files: [],
      showImagesModal: false,
    };
  }

  async componentDidMount() {
    const { id, solutionId, isEdit } = this.state;
    try {
      let _solutionId;
      if (isEdit) {
        await this.props.project.getProjectById(id);
        const { info } = this.props.project.toJS();
        if (info) {
          _solutionId = info.solutionId;
        }
      } else {
        this.props.project.resetInfo();
        this.props.project.saveInfo('solutionId', solutionId);
        _solutionId = solutionId;
      }

      await this.props.solution.getSolutionById(_solutionId);

      let productIds;
      let productGroupIds;
      const { info } = this.props.solution.toJS();
      if (info) {
        productIds = info.productIds;
        productGroupIds = info.productGroupIds;
      }
      await this.props.product.getProductList(productIds, productGroupIds);
      this.setState({ solutionId: _solutionId });
    } catch (error) {
      toast.error(error.message || 'เกิดข้อผิดพลาด');
    }
  }

  onInfoChange(key, e) {
    this.props.project.saveInfo(key, e.target.value);
  }

  onSeoChange(key, e) {
    if (e.target) {
      if (key === 'slug') {
        this.props.project.saveSeoInfo(key, common.slugify(e.target.value));
      }
      this.props.project.saveSeoInfo(key, e.target.value);
    }
  }

  onRatingPointChange(value) {
    this.props.project.saveInfo('ratingPoint', value);
  }

  onActiveToggle(checked) {
    this.props.project.saveInfo('active', checked);
  }

  async submitForm() {
    const { id, isEdit } = this.state;
    try {
      if (isEdit) {
        await this.props.project.updateProject(id);
      } else {
        await this.props.project.createProject();
      }
    } catch (error) {
      throw error;
    }
  }

  doUploadFiles = (dropzone) => {
    return new Promise((resolve, reject) => {
      let files = dropzone.getFiles();
      for (let file of files) {
        if (file.status === 'error') {
          throw new Error('ไม่สามารถอัพโหลดไฟล์ได้');
        }
      }
      dropzone.upload(async () => {
        try {
          await this.submitForm();
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    });
  }

  async uploadAndSubmit() {
    if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
      await this.doUploadFiles(this.dropzone);
    } else {
      await this.submitForm();
    }
  }

  async onSubmitClick() {
    const options = {
      title: 'บันทึกข้อมูล',
      text: 'กรุณายืนยันการบันทึกข้อมูล',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.uploadAndSubmit();
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    const result = await swal.fire(options);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      setTimeout(() => {
        this.onCancelClick();
      }, 500);
    }
  }

  onCancelClick() {
    this.props.history.replace(`${config.publicUrl}/qchang/project?solution_id=${this.state.solutionId}`);
  }

  onAddNewImagesClick() {
    this.setState({
      showImagesModal: true,
    });
  }

  onAddedFile() {
    if (this.dropzone) {
      this.setState({
        files: this.dropzone.getFiles(),
      });
    }
  }

  onUploadError(message) {
    swal.fire({
      icon: 'error',
      title: `${message}`,
    });
  }

  isValid(info) {
    const hasTitle = !_.isEmpty(info.title);
    const hasDescription = !_.isEmpty(info.description);
    const hasSolution = !_.isEmpty(info.solutionId);
    const hasProduct = !_.isEmpty(info.productId);
    const hasLocation = !_.isEmpty(info.location);
    const hasDuration = !_.isEmpty(info.duration);
    const hasArea = !_.isEmpty(info.area);
    const hasMainImage = !_.isEmpty(info.imageUrl) || !_.isEmpty(this.state.files);
    const hasPermission = this.state.isEdit ? isAllow('EDIT_PROJECT_SOLUTIONS') : isAllow('CREATE_PROJECT_SOLUTIONS');
    return hasTitle &&
      hasDescription &&
      hasSolution &&
      hasProduct &&
      hasLocation &&
      hasDuration &&
      hasArea &&
      hasMainImage &&
      hasPermission;
  }

  displayProductOptions(products) {
    const productOpts = (products || []).map((p, index) => (
      <option key={index + 1} value={p.id}>{p.name.th}</option>
    ));
    const opts = [
      <option key={0} value="">กรุณาเลือกสินค้า</option>,
    ];
    return opts.concat(productOpts);
  }

  async onUploadComplete(files) {
    if (files && files.length) {
      this.props.project.saveInfo('imageUrl', files[0].res.data.path);
    }
  }

  getDropZone(ref, callback) {
    const url = `${config.api.qchang}/v1/projects/upload`;
    const token = AuthStore.getToken();
    return (
      <EnDropZone
        ref={ref}
        url={url}
        token={token}
        acceptedFiles={'image/*'}
        onAllComplete={callback}
        maxFileSize={3}
        maxFiles={1}
        onAddedFile={this.onAddedFile.bind(this)}
        // onRemovedFile={this.onRemovedFile.bind(this)}
        onError={this.onUploadError.bind(this)}
      />
    );
  };

  displayMainImage(imageUrl) {
    if (imageUrl) {
      return (
        <a href={imageUrl} target="_blank">
          <img src={imageUrl} style={{ width: '20%', height: '20%' }} alt="" />
        </a>
      );
    }
    return (
      <div>
        <em>
          <small>ไม่มีรูปภาพหลัก</small>
        </em>
      </div>
    );
  }

  deleteSubImage(index) {
    const { info } = this.props.project.toJS();
    const { images } = info;
    images.splice(index, 1);
    this.props.project.saveInfo('images', images);
  }

  displaySubImages(images) {
    if (Array.isArray(images) && !images.length) {
      return (
        <div className="col-md-3" key={`image-no`}>
          ยังไม่มีรูปภาพอื่นๆ
        </div>
      );
    }

    return images.map((image, index) => {
      return (
        <div className="col-md-3" key={`image-${index + 1}`}>
          <ImagePicker
            src={image.thumbnail}
            id={`image-${index + 1}`}
            onDelete={this.deleteSubImage.bind(this, index)}
          />
        </div>
      );
    });
  }

  onCloseImagesModalClick() {
    this.setState({ showImagesModal: false });
  }

  getRatingText(rating) {
    switch (rating) {
      case 1: return 'แย่มาก';
      case 2: return 'แย่';
      case 3: return 'พอใช้';
      case 4: return 'ดี';
      case 5: return 'ดีมาก';
      default: return 'ยังไม่ได้ให้คะแนน';
    }
  }

  render() {
    const { isEdit } = this.state;
    const { info, isLoading } = this.props.project.toJS();
    const { info: solutionInfo } = this.props.solution.toJS();
    const { items: productItems } = this.props.product.toJS();
    const { title, slug, keywords, description } = info.seo;
    const dropzone = this.getDropZone(e => { this.dropzone = e; }, (e) => {
      this.onUploadComplete(e);
    });
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{isEdit ? 'แก้ไขข้อมูลผลงาน' : 'เพิ่มผลงานใหม่'}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <div className="card-content">
                  <FormValidation>
                    <div className="row">
                      <h5 className="md-col-12">{`โซลูชั่น${solutionInfo.name.th}`}</h5>
                    </div>
                    <div className="row">
                      <EnTextFormGroup
                        containerClassName="col-md-12"
                        type="text"
                        label="ชื่อผลงาน"
                        value={info.title}
                        onChange={this.onInfoChange.bind(this, 'title')}
                        validations={[required]}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextArea
                          label="คำอธิบาย"
                          minRow={3}
                          required
                          value={info.description}
                          onChange={(event) => this.onInfoChange('description', event)}
                        />
                      </div>
                    </div>
                    {
                      // <div className="row">
                      //   <FormGroup containerClassName="col-md-12" label="ชื่อโซลูชั่น">
                      //     <h5>{solutionInfo.name.th}</h5>
                      //   </FormGroup>
                      // </div>
                    }
                    <div className="row">
                      <div className="col-md-12">
                        <span className="form-label">สินค้า *</span>
                        <EnDropDown
                          value={info.productId}
                          onChange={this.onInfoChange.bind(this, 'productId')}>
                          {this.displayProductOptions(productItems)}
                        </EnDropDown>
                      </div>
                    </div>
                    <div className="row">
                      <EnTextFormGroup
                        containerClassName="col-md-12"
                        type="text"
                        label="สถานที่"
                        value={info.location}
                        onChange={this.onInfoChange.bind(this, 'location')}
                        validations={[required]}
                      />
                    </div>
                    <div className="row">
                      <EnTextFormGroup
                        containerClassName="col-md-12"
                        type="text"
                        label="ระยะเวลา"
                        value={info.duration}
                        onChange={this.onInfoChange.bind(this, 'duration')}
                        validations={[required]}
                      />
                    </div>
                    <div className="row">
                      <EnTextFormGroup
                        containerClassName="col-md-12"
                        type="text"
                        label="ขนาดพื้นที่"
                        value={info.area}
                        onChange={this.onInfoChange.bind(this, 'area')}
                        validations={[required]}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="seo">
                          <div className="header-seo"><span>SEO</span></div>
                          <div className="seo-form">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <EnTextFormGroup
                                    containerClassName="col-md-6"
                                    type="text"
                                    label="SEO Title"
                                    value={title}
                                    onChange={this.onSeoChange.bind(this, 'title')} />
                                  <EnTextFormGroup
                                    containerClassName="col-md-6"
                                    type="text"
                                    label="Slug"
                                    value={slug}
                                    onChange={this.onSeoChange.bind(this, 'slug')} />
                                </div>
                                <div className="row">
                                  <EnTextFormGroup
                                    containerClassName="col-md-12"
                                    type="text"
                                    label="Keyword"
                                    placeholder="รางน้ำฝน, รางน้ำฝนพร้อมบริการติดตั้ง"
                                    value={keywords}
                                    onChange={this.onSeoChange.bind(this, 'keywords')} />
                                </div>
                                <div className="row">
                                  <EnTextFormGroup
                                    containerClassName="col-md-12"
                                    type="text"
                                    label="Meta Description"
                                    placeholder="ผลิตภัณฑ์รางน้ำไวนิล เอสซีจี มีให้เลือกสองรุ่นคือ รุ่นเดอลุกซ์ (Deluxe) และ รุ่นสมาร์ท (Smart) ซึ่งทั้งสองรุ่นจะแตกต่างกันที่รูปทรง"
                                    value={description}
                                    onChange={this.onSeoChange.bind(this, 'description')} />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <label>ตัวอย่าง</label>
                                <div className="seo seo-form">
                                  <div className="seo-preview-title">{title || ''}</div>
                                  <div className="seo-preview-link">
                                    <cite>
                                      {'https://partner.q-chang.com/projects/'}
                                      <mark>
                                        {slug || ''}
                                      </mark>
                                    </cite>
                                  </div>
                                  <div className="seo-preview-description">
                                    <div>
                                      <span>{description || ''}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <FormGroup containerClassName="col-md-12" label="รูปภาพหลัก" >
                        <div className="col-md-12">
                          {this.displayMainImage(info.imageUrl)}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="row">
                      <FormGroup containerClassName="col-md-12" label="อัพโหลดรูปภาพหลัก" >
                        {dropzone}
                      </FormGroup>
                    </div>
                    <div className="row">
                      <FormGroup containerClassName="col-md-12" label="รูปภาพอื่นๆ" >
                        <div className="col-md-12">
                          {this.displaySubImages(info.images)}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <EnButton
                          className="btn-info pull-left"
                          onClick={this.onAddNewImagesClick.bind(this)}
                        >
                          <i className="fa fa-plus btn-icon" aria-hidden="true" />รูปภาพอื่นๆ
                        </EnButton>
                      </div>
                      {this.state.showImagesModal && (
                        <ImagesModal
                          show={this.state.showImagesModal}
                          images={info.images}
                          onClose={this.onCloseImagesModalClick.bind(this)}
                        />
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="panel panel-default">
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-md-12">
                                <label style={{ display: 'block' }}>คะแนน</label>
                                <StarRatings
                                  rating={info.ratingPoint}
                                  starRatedColor="#f3873a"
                                  changeRating={this.onRatingPointChange.bind(this)}
                                  numberOfStars={5}
                                  name="ratingPoint"
                                  starDimension="35px"
                                />
                                <p>({this.getRatingText(info.ratingPoint)})</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <TextArea
                                  label="ความคิดเห็น"
                                  placeholder="ความคิดเห็น..."
                                  value={info.reviewMessage}
                                  onChange={(event) => this.onInfoChange('reviewMessage', event)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      // <div className="row">
                      //   <FormGroup containerClassName="col-md-12" label={info.active ? 'เปิด' : 'ปิด'}>
                      //     <EnToggle
                      //       checked={info.active}
                      //       onChange={this.onActiveToggle.bind(this)}
                      //     />
                      //   </FormGroup>
                      // </div>
                    }
                    <div className="row">
                      <FormGroup containerClassName="col-md-12" label={info.active ? 'เปิด' : 'ปิด'}>
                        <EnToggle
                          checked={info.active}
                          onChange={this.onActiveToggle.bind(this)}
                        />
                      </FormGroup>
                    </div>
                    <div className="row">
                      <EnButton
                        className="btn-info pull-right"
                        disabled={!this.isValid(info)}
                        onClick={this.onSubmitClick.bind(this)}
                      >
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton
                        className="btn-danger pull-right"
                        onClick={this.onCancelClick.bind(this)}
                      >
                        <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                      </EnButton>
                    </div>
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('solution', 'product', 'project')(observer(ProjectPage));

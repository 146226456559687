import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { FormValidation } from '../../../components/form/FormValidation';
import EnButton from '../../../components/form/EnButton';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import config from '../../../config';
import MyModal from './MyModal';
import authStore from '../../../stores/AuthStore';
import ModalAuth from '../../../components/common/ModalAuth';
import { toast } from 'react-toastify';
// import swal from 'sweetalert2';

export class ManageContractorCost extends React.Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    const store = authStore.getStore();
    this.state = {
      showModal: false,
      contractorId: id,
      contractors: [],
      sku: [],
      contractorName: '',
      isLoading: false,
      storeName: store.name,
      storeId: store._id,
      oldSku: [],
    };
  }

  async componentWillMount() {
    try {
      const contractor = this.props.contractor.toJS();
      let typeWorkId;
      const contractors = await this.props.contractor.getContractor({
        id: this.state.contractorId,
      });
      let typeOfWorkApproved = (!contractors.type_of_work_approved || contractors.type_of_work_approved.length === 0) ? contractors.type_of_work : contractors.type_of_work_approved;
      typeWorkId = contractors.type_of_work.filter(type => (typeOfWorkApproved || []).indexOf(type) !== -1);
      const costItem = (contractors.cost || []).find(c => c.store_id === this.state.storeId);
      if (costItem && costItem.items.length) {
        let skus = await this.props.sku.getSkuByMultiType(typeWorkId);
        skus = skus.map(sku => {
          const item = costItem.items.filter(item => item.code === sku.code);
          if (item && item.length) {
            sku.survey_cost = item[0].survey_cost;
            sku.wage = item[0].wage;
            sku.wage_with_price = item[0].wage_with_price;
          } else {
            sku.survey_cost = '';
            sku.wage = '';
            sku.wage_with_price = '';
          }
          return sku;
        });
        this.props.contractor.saveInfo('oldCost', skus);
        this.setState({ sku: skus });
      } else {
        if(!typeWorkId.length) return
        let sku = await this.props.sku.getSkuByMultiType(typeWorkId);
        sku = sku.map(s => {
          return {
            ...s,
            wage: '',
            survey_cost: '',
            wage_with_price: '',
          };
        });
        this.props.contractor.saveInfo('oldCost', sku);
        this.setState({ sku });
      }
      this.setState({ contractors, contractorName: contractors.team_name });
    } catch (error) {
      throw error;
    }
  }

  onCancel(item) {
    let items = this.state.sku[item.key];
    items.isEdit = !items.isEdit;
    const sku = this.state.sku;
    this.setState({ sku });
  }

  onRowClick(index) {
    const sku = this.state.sku;
    if (Array.isArray(sku) && sku.length) {
      const skus = sku[index];
      skus.isEdit = !skus.isEdit;
      this.setState({ sku, showModal: true });
    }
  }

  onWageWithPriceChange = (e, index) => {
    const sku = this.state.sku;
    sku[index].wage_with_price = e;
    this.setState({ sku });
  }

  onWageChange = (e, index) => {
    const sku = this.state.sku;
    sku[index].wage = e;
    this.setState({ sku });
  }

  onSurveyCostChange = (e, index) => {
    const sku = this.state.sku;
    sku[index].survey_cost = e;
    this.setState({ sku });
  }

  getTextKey(key) {
    switch(key) {
      case "survey_cost":
        return "Survey fee (included VAT)"
      case "wage":
        return "Installation fee (included VAT)"
      case "wage_with_price":
        return "Material with installation"
      default:
        return ""
    }
  }

  checkChangeManageCost(item, index){
    const { info } = this.props.contractor.toJS()
    const permission = [
      { key:'survey_cost', name: 'Survey fee' },
      { key:'wage', name: 'Installation fee' },
      { key:'wage_with_price', name: 'Material with installation' }]
    const permissionFilter = permission.map((data)=> {
      const dataPermission = {}
      const header = data.name
      const key = data.key
        if(info.oldCost[index][key] !== item[key]) {
          dataPermission.event = "SKU for shop"
          dataPermission.function_name = "price"
          dataPermission.reference_code = this.state.storeName
          dataPermission.remark = `SKU ${item.code} from “${header}: ${info.oldCost[index][key]} to ${item[key]}” by `
          return dataPermission
        }
      }).filter(notUndefined => notUndefined !== undefined) || [];
      if(permissionFilter.length > 0) {
        this.props.permission.setInfoLogActivity(permissionFilter)
        this.props.permission.setShowModal(true)
      } else {
        this.onSubmit({ key: index, ...item }, false)
      }
  }

  onSubmit = async (item, verify) => {
    if(verify) {
      let status = await this.props.permission.verifyPassword()
      if (!status) {
        return
      } else {
        this.props.permission.saveUamActivityLogs()
      }
    }
    this.setState({ isLoading: true });
    const response = await this.props.contractor.setContractorCost(this.state.sku, this.state.contractorId, this.state.storeId);
    if (response.message === 'success') {
      toast.success('บันทึกข้อมูลเรียบร้อย');
    } else {
      toast.error('ไม่สามารถบันทึกข้อมูลได้');
    }
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1500);
    this.props.contractor.saveInfo('oldCost', this.state.sku);
    this.onCancel(item);
  }

  onGoBack() {
    this.props.history.push(`${config.publicUrl}/admin/contractor/cost`);
  }

  handleHide = (item) => {
    let items = this.state.sku[item.key];
    const oldCost = this.props.contractor.toJS().info.oldCost;
    if (oldCost && oldCost.length) {
      items.survey_cost = oldCost[item.key].survey_cost;
      items.wage = oldCost[item.key].wage;
      items.wage_with_price = oldCost[item.key].wage_with_price;
    }
    items.isEdit = !items.isEdit;
    const sku = this.state.sku;
    this.setState({ sku, showModal: false });
  }

  genRow = () => {
    const sku = this.state.sku;
    return sku.map((item, index) => {
      return (
        <tr key={`sku ${index}`}>
          <td style={{ textAlign: 'center' }}>{index + 1}</td>
          <td>{item.code}</td>
          <td>{item.name.th}</td>
          <td style={{ textAlign: 'center' }}>{item.survey_cost}</td>
          {item.isEdit && <MyModal
            costType={item.cost_type}
            name={item.name ? item.name.th : ''}
            item={item}
            onWageChange={this.onWageChange.bind(this)}
            onWageWithPriceChange={this.onWageWithPriceChange.bind(this)}
            onSurveyCostChange={this.onSurveyCostChange.bind(this)}
            onSubmit={()=> this.checkChangeManageCost(item, index)}
            // onSubmit={this.onSubmit.bind(this, { key: index, ...item })}
            index={index}
            showModal={this.state.showModal}
            handleHide={this.handleHide} />
          }
          {item.isEdit && <ModalAuth onSubmit={(e)=> this.onSubmit({ key: index, ...item }, true)} />}
          <td style={{ textAlign: 'center' }}>{item.wage}</td>
          <td style={{ textAlign: 'center' }}>{item.cost_type ? item.wage_with_price : 'ไม่มี'}</td>
          {/* <td className="text-center">
            <EnButton title="แก้ไข" className="btn btn-xs" onClick={(e) => { e.preventDefault(); this.onRowClick(index); }}>
              <i className="fa fa-pencil" aria-hidden="true" />
            </EnButton>
          </td> */}
        </tr >

      );
    });
  }

  render() {
    return (
      <Container isAdmin>
        <Loader show={this.props.sku.isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">กรุณาระบุราคาจ่ายช่างแต่ละงาน</h4>
                <p className="category">เพื่อให้ระบบออกเอกสาร PO ให้อัตโนมัติ</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="card-content">
                    <h5>ทีมช่าง {this.state.contractorName}</h5>
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="5%" className="text-center">ลำดับที่</th>
                            <th width="10%" className="text-center">รหัส SKU</th>
                            <th className="text-center">สินค้า</th>
                            <th width="10%" className="text-center">ค่าแรงสำรวจ (รวม VAT)</th>
                            <th width="10%" className="text-center">ค่าแรงติดตั้ง (รวม VAT)</th>
                            <th width="15%" className="text-center">ค่าของพร้อมติดตั้ง (รวม VAT)</th>
                            {/* <th width="5%" className="text-center" /> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.genRow()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <br />
                  <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onGoBack.bind(this)}>
                    <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                  </EnButton>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('admin', 'misc', 'auth', 'contractor', 'sku', 'permission')(observer(ManageContractorCost));

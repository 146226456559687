import React from 'react';
import Swal from 'sweetalert2';
import momentTz from 'moment-timezone';
import { observer, inject } from 'mobx-react';
import Container from '../../layouts/Container';
import { FormValidation } from '../../components/form/FormValidation';
import Loader from '../../components/common/Loader';
import EnButton from '../../components/form/EnButton';
import * as moment from 'moment';
import 'moment/locale/th';
import config from '../../config';
import EnDateRangePicker from '../../components/form/EnDateRangePicker';
import datetime from '../../utils/datetime';
import common from '../../utils/common';
import { toast } from 'react-toastify';

export class AdminInstallationRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      jobId: this.props.match.params.jobId,
      instContractors: undefined,
      unavailableInstDate: undefined,
      isLoading: true,
    };
  }

  async componentWillMount() {
    await this.props.job.clearInfo();
    await this.props.job.getInactiveJobsById(this.state.jobId);

    const instDate = moment(new Date()).add(1, 'days').toDate();
    const jobInfo = this.props.job.toJS().info;
    let instCriteria = {
      contractorId: jobInfo.contractorId,
      workTypeId: jobInfo.typeOfWork[0],
      startInstDate: instDate,
      endInstDate: instDate,
      jobType: 'I',
      available: 0,
    };
    // const options
    await this.props.contractor.getContractor({ id: jobInfo.contractorId });
    const instResult = await this.props.job.getUnavailableDateForInstallation(instCriteria);
    // console.log('instResult ==> ', instResult);

    if (instResult) {
      this.setState({
        instContractors: instResult.contractors,
        unavailableInstDate: instResult.unavailableDate || [],
        isLoading: false,
      });
    }
  }

  checkInstDayBlock(date) {
    const jobInfo = this.props.job.toJS().info;
    let { unavailableInstDate, instContractors } = this.state;
    if (jobInfo.contractorId) {
      const contractor = (instContractors || []).find(c => c._id === jobInfo.contractorId);
      if (contractor) {
        unavailableInstDate = common.unavailableDate([contractor], contractor.unavailable_date);
      }
    }
    if (Array.isArray(unavailableInstDate) && unavailableInstDate.length) {
      const dateBlock = unavailableInstDate.find(u => datetime.isEqual(date, u));
      return !!dateBlock;
    } else {
      return false;
    }
  }

  // checkInstDayBlock(date) {
  //   console.log('date ==> ', date);
  //   if (this.props.checkInstDayBlock) {
  //     return this.props.checkInstDayBlock(date);
  //   } else {
  //     const { unavailableInstDate } = this.props;
  //     if (Array.isArray(unavailableInstDate) && unavailableInstDate.length) {
  //       const dateBlock = unavailableInstDate.find(u => datetime.isEqual(date, u));
  //       return !!dateBlock;
  //     } else {
  //       return false;
  //     }
  //   }
  // }

  validateSubmitButton() {

    //const job = this.props.job.toJS().info;
    //const currentDate = moment();
    const { instContractors } = this.state;
    if (instContractors && instContractors.length) {
      return true;
    }
  }

  onChangeDateRange(startDate, endDate) {
    this.props.job.saveMultiInfo({
      startDate,
      endDate,
    });
  }

  onSubmitClick = async (jobId) => {
    // e.preventDefault();
    try {
      Swal.fire({
        title: 'คุณต้องการกู้คืนคิวช่างนี้ใช่หรือไม่?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        if (result.value) {
          const jobStore = this.props.job.toJS();
          const jobInfo = jobStore.info;
          const response = await this.props.job.cancelAndRecoverJob(jobInfo.jobCode, true);
          if (response.message === 'success') {
            toast.success('กู้คืนสำเร็จ');
            this.props.history.push(`${config.publicUrl}/admin/jobs/list`)
          } else {
            toast.error('เกิดข้อผิดพลาด');
          }
        }
      });
    } catch (error) {
      toast.error(error.message || 'เกิดข้อผิดพลาด');
    }
  }

  onBackClick(e) {
    this.props.history.push(`${config.publicUrl}/admin/jobs/list`);
  }

  render() {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const contractor = this.props.contractor.toJS().info;
    const installationDateRangePicker = (
      <EnDateRangePicker
        withPortal
        numberOfMonths={1}
        ref={(e) => { this.instDateRange = e; }}
        openDirection="up"
        minimumNights={0}
        startDate={jobInfo.startDate ? moment(jobInfo.startDate) : null}
        endDate={jobInfo.endDate ? moment(jobInfo.endDate) : null}
        isDayBlocked={this.checkInstDayBlock.bind(this)}
        onDateChange={this.onChangeDateRange.bind(this)}
        startDatePlaceholderText="วันที่เริ่มต้น"
        endDatePlaceholderText="วันที่สิ้นสุด" />
    );
    return (
      <Container isAdmin>
        <Loader show={this.state.isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">กู้คืนงานติดตั้ง</h4>
              </div>
              <div className="card-content">
                <FormValidation ref={(el) => { this.frm = el; }}>
                  {/* <div className="row">
                    {installationDateRangePicker}
                  </div> */}

                  {/* <div className="row"> */}
                  <h4>รายละเอียดงาน</h4>
                  <div className="row">
                    <div className="col-md-12">
                      ชื่อลูกค้า: คุณ {jobInfo.firstName} {jobInfo.lastName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      เบอร์โทรลูกค้า: {jobInfo.phone}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      ที่อยู่ลูกค้า: {jobInfo.address} {jobInfo.addressInfo.subDistrict} {jobInfo.addressInfo.district} {jobInfo.addressInfo.province} {jobInfo.addressInfo.postCode}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      ทีมช่าง: {contractor.teamName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      ชื่อช่าง: {contractor.firstName} {contractor.lastName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      เบอร์โทรช่าง: {contractor.phone}
                    </div>
                  </div>
                  <br />
                  {/* {installationDateRangePicker} */}
                  {/* </div> */}
                  <div className="row">
                    <div className="col-md-12">
                      <EnButton className="btn-info pull-right" disabled={!this.validateSubmitButton()} onClick={this.onSubmitClick.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.onBackClick.bind(this)}>
                        <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                      </EnButton>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container >
    );
  }
}

export default inject('contractor', 'job')(observer(AdminInstallationRequest));

import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import { FormValidation, required } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnDropzoneDialog from '../../components/form/EnDropzoneDialog';
import EnButton from '../../components/form/EnButton';
import history from '../../utils/history';
import config from '../../config';
import * as _ from 'lodash';
import authStore from '../../stores/AuthStore';
import ListItem from '../../components/common/ListItem';
import common from '../../utils/common';
import Swal from 'sweetalert2';

export class CreditProviderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobId: this.props.match.params.id,
      showModal: false,
      files: [],
      dropzoneBillOpen: false,
    };
  }

  async componentWillMount() {
    this.props.creditProvider.clearCreditProvider();
    await this.props.creditProvider.loadCreditProviderByJobId(this.state.jobId);
    await this.props.po.loadPoByJobId(this.state.jobId);
    await this.props.po.calculateTotal();
  }

  validateForm(info) {
    const companyName = required(info.companyName);
    const creditAmount = required(info.creditAmount);

    return !companyName &&
      !creditAmount;
  }

  async updatePODetail() {
    const info = this.props.creditProvider.toJS().info;
    let po = this.props.po.toJS().info;
    const dupIndex1 = po.details.findIndex((a) => a.name.includes('รายการสำรองจ่าย'));
    if (dupIndex1 > 0) {
      this.props.po.deleteDetail(dupIndex1);
      po = this.props.po.toJS().info;
      const dupIndex2 = po.details.findIndex((a) => a.name.includes('ค่าธรรมเนียมรายการสำรองจ่าย'));
      this.props.po.deleteDetail(dupIndex2);
    }

    const isJuristic = !!po.contractor.isJuristic;
    const vatRate = isJuristic ? 1 : 1 + (7 / 100);
    const creditAmount = (parseFloat(info.creditAmount) * vatRate).toFixed(2);
    const creditFee = (parseFloat(info.creditFee) * vatRate).toFixed(2);

    const newDetail = po.details.filter(e => e.is_credit_provider !== true);
    newDetail.push({
      code: '',
      name: `รายการสำรองจ่ายจาก บ.${info.companyName}`,
      unit: 'รายการ',
      qty: 1,
      price: 0.00,
      contractor_discount: parseFloat(creditAmount).toFixed(2),
      contractor_amount: 0.00,
      contractor_cost: 0.00,
      is_credit_provider: true,
    });
    newDetail.push({
      code: '',
      name: `ค่าธรรมเนียมรายการสำรองจ่าย`,
      unit: 'รายการ',
      qty: 1,
      price: 0.00,
      contractor_discount: parseFloat(creditFee),
      contractor_amount: 0.00,
      contractor_cost: 0.00,
      is_credit_provider: true,
    });
    await this.props.po.saveInfo('details', newDetail);
    await this.props.po.calculateTotal();

  }

  async deletePODetail() {
    let po = this.props.po.toJS().info;
    const deleteIndex1 = po.details.findIndex((value) => value.name.includes('รายการสำรองจ่าย'));
    this.props.po.deleteDetail(deleteIndex1);
    po = this.props.po.toJS().info;
    const deleteIndex2 = po.details.findIndex((value) => value.name.includes('ค่าธรรมเนียมรายการสำรองจ่าย'));
    this.props.po.deleteDetail(deleteIndex2);
    await this.props.po.calculateTotal();
  }

  async onDelete() {
    this.props.creditProvider.saveInfo('deletedBy', this.props.auth.getUsernameOrEmail());
    const confirmDialogOptions = {
      title: 'การลบข้อมูล',
      text: 'กรุณายืนยันการลบ Credit ซื้อของ',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        if(!await this.props.creditProvider.deleteCreditProvider(this.state.jobId)){
          Swal.fire({
            icon: 'error',
            title: 'การลบข้อมูลล้มเหลว',
            confirmButtonText: 'ตกลง',
          });
        }
        await this.deletePODetail();
        await this.props.po.savePo(this.state.jobId, false, authStore.getUsernameOrEmail());
        history.goBack();
      },
    };
   
    const result = await Swal.fire(confirmDialogOptions);
    if (result.value) {
      Swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const info = this.props.creditProvider.toJS().info;
    const poInfo = this.props.po.toJS().info;
    this.props.creditProvider.saveInfo('submitedBy', this.props.auth.getUsernameOrEmail());
    const isFormValid = this.validateForm(info);
    if (!isFormValid) {
      Swal.fire({
        icon: 'error',
        title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        confirmButtonText: 'ตกลง',
      });
      return;
    }
    if (await this.props.job.showJobUpdatedDialog(poInfo)) {
      return;
    }

    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไข credit ซื้อของ' : 'กรุณายืนยันการสร้าง credit ซื้อของ',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const data = await this.props.creditProvider.saveCreditProvider(this.state.jobId);
          if (!data && poInfo.jobInfo.other_expense) {
            const userEmail = poInfo.jobInfo.other_expense && poInfo.jobInfo.other_expense.detail[0].user_email || 'ADMIN';
            return Swal.fire({
              icon: 'warning',
              title: 'มีรายการหักค่าใช้จ่ายเกินกำหนด',
              html: `
              <div>
                <p> ไม่สามารถบันทึกได้ เนื่องจากมีรายการหักค่าใช้จ่าย JO และรายการค่าใช้จ่ายมีเกินกว่ารายการล่าสุดจะหักได้ </p>
                <br/>
                <p> กรุณาติดต่อ [${userEmail}] ผู้ทำรายการหักค่าใช้จ่าย JO </p>
              </div>
              `
            });
          }
          await this.updatePODetail();
          await this.props.po.savePo(this.state.jobId, false, authStore.getUsernameOrEmail());
          return data;
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    };

    let result = await Swal.fire(confirmDialogOptions);
    if (result.value) {
      Swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      this.onCancel();
    }
  }

  onCancel() {
    const isAdmin = authStore.getStore();
    if (!_.isNil(isAdmin) && !_.isEmpty(isAdmin)) {
      history.push(`${config.publicUrl}/calendar/admin/jobs/${this.state.jobId}`);
    } else {
      history.push(`${config.publicUrl}/superadmin/jobs/${this.state.jobId}`);
    }
  }

  async onSaveDropzone(type, dropzoneFile) {
    try {
      const result = await this.props.creditProvider.uploadBillingFile(dropzoneFile, this.state.jobId);
      const info = this.props.creditProvider.toJS().info;
      let files = info.billingFile || [];
      files = _.concat(files, result.map(f => {
        return {
          name: f.name,
          type,
          url: f.path,
          date: f.date,
        };
      }));
      this.props.creditProvider.saveInfo('billingFile', files);
    } catch (error) {
      Swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
    } finally {
      this.props.creditProvider.openDropZone(false);
    }
  }

  closeDropzone(key) {
    this.props.creditProvider.openDropZone(false);
  }

  onOpenDropzone(key) {
    this.props.creditProvider.openDropZone(true);
  }

  saveInfo(key, e) {
    this.props.creditProvider.saveInfo(key, e.target.value);
  }

  initListItemHeaderOption = (title) => {
    return [
      {
        title: title,
        size: 9,
        key: 'filename',
        showIcon: true,
        downloadLink: true,
        appendDescription: true,
        descriptionKey: 'date',
      }, {
        title: '',
        size: 3,
        key: 'delete',
        className: 'text-right',
        itemClassName: 'text-right',
      },
    ];
  };

  getDisplayEmpty = (message) => {
    return <div><em><small>{message}</small></em></div>;
  };

  getListItem = (itemHeader, itemData, callback) => {
    return (
      <div>
        <ListItem
          header={itemHeader}
          data={itemData}
          onClick={callback}
          target="_blank" />
        {!itemData || !itemData.length ? this.getDisplayEmpty('ไม่มีรูปถ่ายหรือไฟล์แนบ') : ''}
      </div>
    );
  };

  toListItemData(files, longFileName) {
    if (Array.isArray(files) && files.length) {
      return files.filter(f => (f && f.name)).map((f, idx) => {
        return {
          filename: (idx + 1) + '_' + (longFileName ? f.name : f.name.substring(14, f.name.length)),
          name: f.name,
          url: f.url,
          date: f.date ? `${moment(f.date).format('DD/MM/YYYY HH:mm:ss')}` : '',
          delete: <i className="fa fa-trash" />,
          icon: common.getFileIcon(f.name),
          downloadHref: f.url,
          type: f.type,
        };
      });
    }
    return [];
  };

  onDeleteBilling = (propName, info, key, data) => {
    let result = info[propName].filter(f => f.name !== data.name);
    this.props.creditProvider.saveInfo(propName, result);
  };

  render() {
    const creditProviderStore = this.props.creditProvider.toJS();
    const { info, dropzoneBillOpen } = creditProviderStore;
    const fileData = this.toListItemData(info.billingFile);
    const billing = this.initListItemHeaderOption('เอกสารการวางบิล');
    const billingFile = this.getListItem(billing, fileData, this.onDeleteBilling.bind(this, 'billingFile', info));

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={creditProviderStore.isLoading || creditProviderStore.isSubmitting} />
          <EnDropzoneDialog
            dialogTitle="ใบวางบิล"
            dropzoneText="เลือกไฟล์"
            filesLimit={20}
            open={dropzoneBillOpen}
            onSave={this.onSaveDropzone.bind(this, 'billing')}
            onClose={this.closeDropzone.bind(this, 'dropzoneBillOpen')}
          />
          <div className="card">
            <div className="col-md-12">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แบบฟอร์ม Credit Provider</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <EnTextFormGroup
                    id="jobCode"
                    containerClassName="col-md-6"
                    label="ชื่อบริษัท"
                    value={info.companyName}
                    onChange={this.saveInfo.bind(this, 'companyName')}
                  />
                </div>
                <div className="row">
                  <EnTextFormGroup
                    id="creditAmount"
                    containerClassName="col-md-6"
                    label="ยอดที่สำรองจ่าย"
                    pattern="^[0-9]*$"
                    value={info.creditAmount}
                    onChange={this.saveInfo.bind(this, 'creditAmount')}
                  />
                </div>
                <div className="row">
                  <EnTextFormGroup
                    id="creditFee"
                    containerClassName="col-md-6"
                    label="ค่าธรรมเนียม 2%"
                    value={info.creditFee}
                    onChange={this.saveInfo.bind(this, 'creditFee')}
                  />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {billingFile}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <EnButton
                      className="btn-secondary"
                      onClick={this.onOpenDropzone.bind(this, 'dropzoneBillOpen')}                    >
                      อัพโหลดใบวางบิลกับเน็กซ์เตอร์
                    </EnButton>
                  </div>
                </div>
                <FormValidation ref={(el) => {
                  this.frm = el;
                }}>
                  <div className="row">
                    <div className="col-md-12">
                      <EnButton className="btn-danger pull-left" disabled={!this.validateForm(info)} onClick={this.onDelete.bind(this)}>
                        <i className="fa fa-trash btn-icon" aria-hidden="true" />ลบ
                      </EnButton>
                      <EnButton className="btn-info pull-right" disabled={!this.validateForm(info)} onClick={this.onSubmit.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                        <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                      </EnButton>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container >
    );
  }
}

export default inject('creditProvider', 'po', 'job', 'auth')(observer(CreditProviderForm));

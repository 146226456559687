import React from 'react';

import swal from 'sweetalert2';
import moment from 'moment';
import readXlsxFile from 'read-excel-file';
import { get, isNumber, isString, toUpper } from 'lodash';
import { observer, inject } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

import AuthStore from '../../stores/AuthStore';
import Container from '../../layouts/Container';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnDropZone from '../../components/form/EnDropZone';
import FormGroup from '../../components/form/FormGroup';
import Loader from '../../components/common/Loader';
import { config } from '../../config';
import { isAllow } from '../../utils/permission';
import { UPDATE_GRADE_METHOD, updateGradeMethodOptions } from '../../utils/data';
import common from '../../utils/common';

export class AdminUploadContractorGrade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      rows: [],
      uploadType: 'contractor_grade',
      files: [],
      fileName: '',
      description: '',
      errorMessage: '',
      updateGradeMethod: null,
    };
  }

  checkPermissionAdminUploadContractorGrade() {
    if (isAllow('DOWNLOAD_GRADING_TEMPLATE_AND_UPLOAD_GRADE')) {
      this.props.history.push(`${config.publicUrl}/superadmin/approve/upload`);
    } else {
      //Go Back to Main Menu
      this.props.history.push(`${config.publicUrl}`);
    }
  }

  async componentDidMount() {
    const { uploadType } = this.state;
    const { contractorgrade: contractorGradeStore } = this.props;

    this.checkPermissionAdminUploadContractorGrade();
    contractorGradeStore.initGradeMap();

    switch (uploadType) {
      case 'contractor_grade':
        await this.props.download.getUploadeFiles(uploadType);
        break;
      default:
        await this.props.download.getUploadeFiles();
        break;
    }
  }

  initState() {
    this.setState({
      rows: [],
      files: [],
      fileName: '',
      description: '',
      errorMessage: '',
      updateGradeMethod: null,
    });
  }

  async onClickDownload(fileName, filePath, fileUrl = '') {
    const signUrl = await this.props.download.getSignUrl(fileName, filePath);
    if (signUrl && signUrl.fileUrl) {
      window.open(signUrl.fileUrl);
    } else {
      window.open(fileUrl);
    }
  }

  getTableRows(items) {
    return items.map((item, index) => {
      const updateGradeMethodLabel = item.update_grade_method ? UPDATE_GRADE_METHOD[item.update_grade_method.toUpperCase()].label : '-';
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td className="text-center">{item.file_name}</td>
          <td className="text-center">{updateGradeMethodLabel}</td>
          {item.start_date ? (
            <td className="text-center">
              {moment(item.start_date).format('DD-MM-YYYY')}
              <br />
              {moment(item.start_date).format('HH:mm:ss')}
            </td>
          ) : (
            <td className="text-center">-</td>
          )}
          <td className="text-center">{item.description}</td>
          <td className="text-center" style={{ verticalAlign: 'middle' }}>
            <button
              className="btn btn-xs"
              title="ดาวน์โหลด"
              data-tip="ดาวน์โหลด"
              onClick={this.onClickDownload.bind(this, item.file_name, item.path, item.file_url)}
            >
              <span className="fa fa-download" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  getDropZone(ref, callback) {
    const url = `${config.api.sims}/v1/contractors/upload/grade`;
    const token = AuthStore.getToken();

    return (
      <EnDropZone
        ref={ref}
        url={url}
        token={token}
        acceptedFiles={'*,.xls,.xlsx,.csv'}
        onAllComplete={callback}
        maxFileSize={3}
        maxFiles={1}
        onError={this.displayErrorAlert}
      />
    );
  }

  async onUpload() {
    this.setState({
      showModal: true,
      file: null,
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
    });

    this.initState();
  }

  isValidRows = (rows) => {
    const { updateGradeMethod } = this.state;
    const { contractorgrade: contractorGradeStore } = this.props;
    const { gradeMap } = contractorGradeStore.toJS();
    const validGradeValue = Object.keys(gradeMap).map((g) => (g === '' ? 'NEW' : g));
    const validDisplayedGrade = Object.values(gradeMap).map((g) => g.toUpperCase());
    const METHOD_ERROR_MESSAGE_TITLE = 'อัปโหลดไฟล์ไม่สำเร็จ';
    const METHOD_ERROR_MESSAGE_TEXT = 'ไม่สามารถอัปโหลดไฟล์ได้เนื่องจากประเภทที่เลือก และไฟล์ที่อัปโหลดไม่ตรงกัน';
    let modalErrorMessage;

    if (!updateGradeMethod || rows.length < 2) {
      const error = new Error(METHOD_ERROR_MESSAGE_TITLE);
      error.description = METHOD_ERROR_MESSAGE_TEXT;
      throw error;
    }
    if (updateGradeMethod === UPDATE_GRADE_METHOD.TEAM.value) {
      for (let index = 0; index < rows.length; index++) {
        const [contractorId, _teamName, phoneNumber, teamGrade, displayGrade] = rows[index];
        const prefixErrorMessage = `ไม่ผ่านที่ record no.${index + 1}`;

        if (index === 0 && rows[index].length !== 5) {
          const error = new Error(METHOD_ERROR_MESSAGE_TITLE);
          error.description = METHOD_ERROR_MESSAGE_TEXT;
          throw error;
        }
        if (index !== 0 && !common.isValidObjectId(contractorId)) {
          modalErrorMessage = `${prefixErrorMessage} เพราะ Contractor ID ช่างไม่ถูกต้อง`;
          break;
        }
        if (index !== 0 && !common.isValidPhoneNumber(phoneNumber)) {
          modalErrorMessage = `${prefixErrorMessage} เพราะเบอร์ไม่ถูกต้อง`;
          break;
        }
        if (index !== 0 && !validGradeValue.includes(toUpper(teamGrade))) {
          modalErrorMessage = `${prefixErrorMessage} เพราะเกรดไม่ถูกต้อง`;
          break;
        }
        if (index !== 0 && !validDisplayedGrade.includes(toUpper(displayGrade))) {
          modalErrorMessage = `${prefixErrorMessage} เพราะ Display Grade ไม่ถูกต้อง`;
          break;
        }
      }
    } else if (updateGradeMethod === UPDATE_GRADE_METHOD.WORK.value) {
      for (let index = 0; index < rows.length; index++) {
        const [contractorId, _teamName, phoneNumber, typeOfWork, grade, displayGrade, reason] = rows[index];
        const prefixErrorMessage = `ไม่ผ่านที่ record no.${index + 1}`;

        if (index === 0 && rows[index].length !== 7) {
          const error = new Error(METHOD_ERROR_MESSAGE_TITLE);
          error.description = METHOD_ERROR_MESSAGE_TEXT;
          throw error;
        }
        if (index !== 0 && !common.isValidObjectId(contractorId)) {
          modalErrorMessage = `${prefixErrorMessage} เพราะ Contractor ID ช่างไม่ถูกต้อง`;
          break;
        }
        if (index !== 0 && !common.isValidPhoneNumber(phoneNumber)) {
          modalErrorMessage = `${prefixErrorMessage} เพราะเบอร์ไม่ถูกต้อง`;
          break;
        }
        if (index !== 0 && !isNumber(typeOfWork)) {
          modalErrorMessage = `${prefixErrorMessage} เพราะประเภทงานไม่ถูกต้อง`;
          break;
        }
        if (index !== 0 && !validGradeValue.includes(toUpper(grade))) {
          modalErrorMessage = `${prefixErrorMessage} เพราะเกรดไม่ถูกต้อง`;
          break;
        }
        if (index !== 0 && !validDisplayedGrade.includes(toUpper(displayGrade))) {
          modalErrorMessage = `${prefixErrorMessage} เพราะ Display Grade ไม่ถูกต้อง`;
          break;
        }
        if (index !== 0 && !isString(reason)) {
          modalErrorMessage = `${prefixErrorMessage} เพราะเหตุผลไม่ถูกต้อง`;
          break;
        }
      }
    }

    if (modalErrorMessage) {
      this.setState({ errorMessage: modalErrorMessage });
      return false;
    }
    return true;
  };

  async onSave() {
    try {
      const { auth: authStore, contractor: contractorStore, download: downloadStore } = this.props;
      const { updateGradeMethod, description, file, rows } = this.state;
      const username = authStore.getUsernameOrEmail();

      if (!this.isValidRows(rows)) {
        return;
      }
      if (isString(description) && description.length < 5) {
        this.setState({ errorMessage: 'รายละเอียดต้องมีอย่างน้อย 5 ตัวอักษร' });
        return;
      }

      const { errors } = await contractorStore.updateGrades({
        updateMethod: updateGradeMethod,
        rows,
        updatedBy: username,
        filePath: get(file, 'res.data[0].pathFile'),
        fileName: get(file, 'res.data[0].name'),
        fileDescription: description,
      });

      if (errors.length > 0) {
        const errorRecordNumbers = errors.map(({ row }) => row).join(', ');
        const error = new Error('บันทึกไม่สำเร็จ');
        error.description = `บันทึกไม่สำเร็จที่ record no.${errorRecordNumbers}`;
        throw error;
      } else {
        swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูลเรียบร้อย',
        });
      }

      await downloadStore.getUploadeFiles('contractor_grade');
      this.closeModal();
    } catch ({ message, description }) {
      this.displayErrorAlert(message, description);
    }
  }

  async onUploadComplete(files) {
    if (files && files.length) {
      try {
        const rows = await readXlsxFile(files[0].file);
        const { name } = (files[0].res.data && files[0].res.data[0]) || {};

        this.setState({
          file: files[0],
          fileName: name,
          rows,
        });
      } catch (error) {
        this.setState({
          errorMessage: error.message,
        });
      }
    }
  }

  validateSave() {
    const { updateGradeMethod } = this.state;
    if (this.state.rows && this.state.file && this.state.description && !this.state.errorMessage && updateGradeMethod) {
      return false;
    }
    return true;
  }

  displayErrorAlert(title, text) {
    swal.fire({
      icon: 'error',
      title,
      text: text || 'กรุณาลองใหม่อีกครั้ง',
    });
  }

  handleUpdateGradeMethodSelect = ({ value }) => {
    this.setState({ updateGradeMethod: value });
  };

  render() {
    const { contractorgrade: contractorGradeStore } = this.props;
    const { updateGradeMethod } = this.state;
    const { listFileUploade, isLoading } = this.props.download.toJS();
    const { isLoading: isLoadingUploadGrade } = this.props.contractor.toJS();
    const isGradeMapLoading = Boolean(!contractorGradeStore.toJS().gradeMap);

    const isValidSubmitButton = this.validateSave();
    const rows = this.getTableRows(listFileUploade);
    const dropzone = this.getDropZone(
      (e) => {
        this.dropzone = e;
      },
      (e) => {
        this.onUploadComplete(e);
      },
    );
    return (
    <>
      <Loader show={isLoading || isLoadingUploadGrade || isGradeMapLoading} />
      <Container isAdmin>
        <Modal
          show={this.state.showModal}
          onHide={(e) => {
            this.closeModal();
          }}
        >
          <Modal.Header closeButton={false} style={{ backgroundColor: '#f3873a', paddingTop: 12, paddingBottom: 12 }}>
            <Modal.Title style={{ color: 'white' }}>{'อัพโหลดไฟล์'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <FormGroup containerClassName="col-md-12" label="ประเภท" validations>
                <Select
                  options={updateGradeMethodOptions}
                  placeholder="เลือก"
                  searchable={false}
                  value={updateGradeMethod}
                  onChange={this.handleUpdateGradeMethodSelect}
                />
              </FormGroup>
              {this.state.fileName && (
                <FormGroup containerClassName="col-md-12" label="ไฟล์ที่อัพโหลด">
                  <div>
                    <i className="fa fa-file-excel-o"></i>
                    &nbsp;&nbsp;{this.state.fileName}
                  </div>
                </FormGroup>
              )}
              <FormGroup containerClassName="col-md-12" label="อัพโหลดไฟล์">
                {dropzone}
              </FormGroup>
              <EnTextFormGroup
                id="courseDetail"
                containerClassName="col-md-12"
                type="text"
                label={'รายละเอียด *'}
                value={this.state.description}
                onChange={(e) => {
                  if (this.state.errorMessage && e.target.value.length >= 5) {
                    this.setState({ errorMessage: '' });
                  }

                  this.setState({ description: e.target.value });
                }}
              />
              {this.state.errorMessage && (
                <span style={{ marginLeft: 15 }} className="input-error">
                  {this.state.errorMessage}
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              title="ยกเลิก"
              data-tip="ยกเลิก"
              onClick={(e) => {
                this.closeModal();
              }}
            >
              <span className="fa fa-times btn-icon" aria-hidden="true" />
              ยกเลิก
            </button>
            <button
              className="btn btn-info"
              title="สร้างใหม่"
              data-tip="สร้างใหม่"
              onClick={(e) => {
                this.onSave();
              }}
              disabled={isValidSubmitButton}
            >
              <span className="fa fa-floppy-o btn-icon" aria-hidden="true" />
              บันทึก
            </button>
          </Modal.Footer>
        </Modal>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">อัพโหลดเกรดช่าง</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="7%" className="text-center">
                              ลำดับที่
                            </th>
                            <th width="25%" className="text-center">
                              ชื่อไฟล์
                            </th>
                            <th width="15%" className="text-center">
                              ประเภท
                            </th>
                            <th width="13%" className="text-center">
                              เวลา
                            </th>
                            <th width="30%" className="text-center">
                              รายละเอียด
                            </th>
                            <th width="10%" className="text-center">
                              <button className="btn btn-success" title="อัพโหลด" data-tip="อัพโหลด" onClick={this.onUpload.bind(this)}>
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />
                                อัพโหลด
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
    );
  }
}

export default inject('download', 'contractor', 'auth', 'contractorgrade')(observer(AdminUploadContractorGrade));

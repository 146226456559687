import * as _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { FormValidation, required } from '../../../components/form/FormValidation';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnToggle from '../../../components/form/EnToggle';
import EnButton from '../../../components/form/EnButton';
import Loader from '../../../components/common/Loader';
import EnStickyFooter from '../../../components/form/EnStickyFooter';
import config from '../../../config';
import { toast } from 'react-toastify';

export class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 2,
      stepName: 'ประเภทงาน',
    };
  }

  saveInfo(key, e) {
    this.props.contractor.saveInfo(key, e.target.value);
  }

  saveTypeOfWork(item, e) {
    if (item) {
      this.props.contractor.saveInfo('typeOfWork', item.id);
    }
  }

  async clickNext() {
    try {
      this.props.contractor.validateInput(this)
        .then(async () => {
          // await this.props.contractor.validateTeamName();
          // await this.props.contractor.checkMobileAvailable();
          this.props.history.push(`${config.publicUrl}/contractors/register/workarea`);
        })
        .catch((err) => {
          toast.error(err.message || 'เกิดข้อผิดพลาด');
        });
    } catch (err) {
      toast.error(err.message || 'เกิดข้อผิดพลาด');
    }
  }

  validation() {
    return this.props.contractor.isValidRegisterInfo(2);
  }

  async componentDidMount() {
    await this.props.typeofwork.getTypeOfWorks({ sort: 'name' });
  }

  render() {
    let contractorStore = this.props.contractor.toJS();
    let contractor = contractorStore.info;
    const { typeOfWork: conTypeOfWorks = [] } = contractor;
    const checkHideContractor = true;
    const typeOfWorks = this.props.typeofwork.mapIdAndLabel(checkHideContractor);
    const sortTypeOfWork = _.sortBy(typeOfWorks, ['label']);
    let options = sortTypeOfWork.map((item, index) => {
      return (
        <EnToggle
          key={index}
          checked={_.indexOf(conTypeOfWorks, item.id) >= 0}
          label={item.label}
          onChange={this.saveTypeOfWork.bind(this, item)}
        />
      );
    });
    let otherWork = conTypeOfWorks.filter((i) => i === 0);
    let button = (
      <EnButton id="register-nextbutton" className="btn btn-info btn-next" onClick={this.clickNext.bind(this)}>
        <i className="fa fa-arrow-right btn-icon" aria-hidden="true" />ถัดไป
      </EnButton>
    );

    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <Loader show={contractorStore.isLoading} />
            <div className="col-md-offset-2 col-md-8">
              <div className="card info">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">ลงทะเบียน</h4>
                  <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
                </div>
                <div className="card-content">
                  <FormValidation ref="FormValidation">
                    <div className="row is-invalid-input">
                      <FormGroup containerClassName="col-md-12" >
                        <span id="register-type" className="form-label">ประเภทงานที่รับ</span> <span className="text-danger">(เลือกอย่างน้อย 1 ข้อ)</span>
                        {options}
                      </FormGroup>
                    </div>
                    <div className="row">
                      {
                        otherWork.length > 0 &&
                        <EnTextFormGroup id="register-remark" containerClassName="col-md-12" label="เพิ่มเติม" value={contractor.otherWork} onChange={this.saveInfo.bind(this, '')} validations={[required]} />
                      }
                    </div>
                    <EnStickyFooter step={this.state.step} stepName={this.state.stepName} button={button} />
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default inject('contractor', 'typeofwork')(observer(Info));

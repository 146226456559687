
import React from 'react';
import _ from 'lodash'
import { observer, inject } from 'mobx-react';
import Swal from 'sweetalert2';
import MobileDetect from 'mobile-detect';
import EnRadio from '../../components/form/EnRadioScore';
import EnButton from '../../components/form/EnButton';
import { History } from '../../utils/history';
import { config } from '../../config';
import '../../assets/css/stepprogress.css';
import '../../assets/css/homeowner-acceptance.css';
import { Fragment } from 'react'
import TextArea from '../../componentsV2/input/textArea';


export class NpsDetailPage extends React.Component {
  constructor(props) {
    super(props);
    const md = new MobileDetect(window.navigator.userAgent);

    this.state = {
      isMobileOrTablet: !!(md.mobile() || md.tablet()),
    };
  }

  onScoreChange(value) {
    this.props.nps.setNpsInfo('score', value);
  }

  onCommentChange(key, e) {
    e.preventDefault();
    this.props.nps.setNpsInfo(key, e.target.value);
  }

  async onSubmitClick() {
    const { nps } = this.props.nps.toJS();
    try {
      if (_.isEmpty(nps.score)) {
        Swal.fire({
          icon: 'warning',
          title: 'กรุณาระบุ\nคะแนนความพึงพอใจ',
          confirmButtonText: 'ตกลง',
        });
        return;
      }

      await this.props.nps.updateNps(this.props.jobId);
      Swal.fire({
        title: 'ขอบคุณสำหรับคะแนนและความคิดเห็นของท่าน',
        icon: 'success',
      }).then(async (result) => {
        History.replace(`${config.publicUrl}/nps/${this.props.jobId}/thankyou`);
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: `${err.message}`,
      });
    }
  }

  render() {
    const { nps } = this.props.nps.toJS();
    const { jobId, npsData, customerFullName, hideScore } = this.props;
    const size = this.state.isMobileOrTablet ? '45px' : '60px';

    let statusOptions = [
      {
        index: 0,
        label: '0',
        label2: 'ไม่แนะนำ',
        value: '0',
        icon: <i className="fa fa-frown-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-reject pa-5',
        selectedClassName: 'btn-reject-selected pa-5',
      },
      {
        index: 1,
        label: '1',
        value: '1',
        icon: <i className="fa fa-frown-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-reject pa-5',
        selectedClassName: 'btn-reject-selected pa-5',
      },
      {
        index: 2,
        label: '2',
        value: '2',
        icon: <i className="fa fa-frown-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-reject pa-5',
        selectedClassName: 'btn-reject-selected pa-5',
      },
      {
        index: 3,
        label: '3',
        value: '3',
        icon: <i className="fa fa-frown-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-reject pa-5',
        selectedClassName: 'btn-reject-selected pa-5',
      },
      {
        index: 4,
        label: '4',
        value: '4',
        icon: <i className="fa fa-frown-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-reject pa-5',
        selectedClassName: 'btn-reject-selected pa-5',
      },
      {
        index: 5,
        label: '5',
        value: '5',
        icon: <i className="fa fa-frown-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-reject pa-5',
        selectedClassName: 'btn-reject-selected pa-5',
      },
      {
        index: 6,
        label: '6',
        value: '6',
        icon: <i className="fa fa-frown-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-reject pa-5',
        selectedClassName: 'btn-reject-selected pa-5',
      },
      {
        index: 7,
        label: '7',
        value: '7',
        icon: <i className="fa fa-meh-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-warn pa-5',
        selectedClassName: 'btn-warn-selected pa-5',
      },
      {
        index: 8,
        label: '8',
        value: '8',
        icon: <i className="fa fa-meh-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-warn pa-5',
        selectedClassName: 'btn-warn-selected pa-5',
      },
      {
        index: 9,
        label: '9',
        value: '9',
        icon: <i className="fa fa-smile-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-accept pa-5',
        selectedClassName: 'btn-accept-selected pa-5',
      },
      {
        index: 10,
        label: '10',
        label2: 'แนะนำอย่างยิ่ง',
        value: '10',
        icon: <i className="fa fa-smile-o" aria-hidden="true" style={{ fontSize: size }} />,
        defaultClassName: 'btn-accept pa-5',
        selectedClassName: 'btn-accept-selected pa-5',
      },
    ];

    const chunkList = _.chunk(statusOptions, 5);

    return (
      <div className="nps-page content" style={{ padding: '30px 20px' }}>
        {!hideScore && (
          <Fragment>
            <h4>{`คุณ${customerFullName} อยากแนะนำ Q-Chang ให้เพื่อน หรือคนรู้จักของคุณมากแค่ไหน`}</h4>
            {this.state.isMobileOrTablet ? (<Fragment>
              <div className="row button justify-content-md-center">
                {chunkList[0] && <EnRadio
                  containerClassName="col-md-12 col-xs-12 text-center px-0"
                  label={''}
                  value={nps.score}
                  options={chunkList[0]}
                  onClick={this.onScoreChange.bind(this)}
                />}
              </div>
              <div className="row button justify-content-md-center">
                {chunkList[1] && <EnRadio
                  containerClassName="col-md-12 col-xs-12 text-center px-0"
                  label={''}
                  value={nps.score}
                  options={chunkList[1]}
                  onClick={this.onScoreChange.bind(this)}
                />}
              </div>
              <div className="row button justify-content-md-center">
                {chunkList[2] && <EnRadio
                  containerClassName="text-center px-0"
                  label={''}
                  value={nps.score}
                  options={chunkList[2]}
                  onClick={this.onScoreChange.bind(this)}
                />}
              </div>
            </Fragment>) : <div className="row button">
              <EnRadio
                containerClassName="col-md-12 col-xs-12 text-center px-0"
                label={''}
                value={nps.score}
                options={statusOptions}
                onClick={this.onScoreChange.bind(this)}
              />
            </div>
            }
          </Fragment>
        )}

        <div className="row comment pl-45 pr-45">
          <div className="col-md-12 col-xs-12 text-left">
            <h4>ความคิดเห็นหรือข้อแนะนำ เพื่อทำให้คุณชอบ Q-Chang และอยากใช้บริการกับเรามากยิ่งขึ้น</h4>
            <TextArea
              label="ความคิดเห็น"
              placeholder='ความคิดเห็น...'
              value={nps.comment}
              onChange={this.onCommentChange.bind(this, 'comment')}
            />
          </div>
        </div>
        {/* {nps.showBtnSubmit && */}
        <div className="row submit-button" >
          <EnButton
            id="submit-button"
            className="btn-success col-md-12 col-xs-12 "
            onClick={this.onSubmitClick.bind(this)}>
            ส่งความเห็น
          </EnButton>
        </div>
        {/* } */}
      </div>
    );
  }
}

export default inject('nps')(observer(NpsDetailPage));

import React from 'react';
import _, { curry } from 'lodash';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import Pagination from '../../components/common/Pagination';
import EnText from '../../components/form/EnText';
import EnButton from '../../components/form/EnButton';

import CustomToggle from './components/toggle';
import CustomDatePicker from './components/datePicker';

import config from '../../config';
import { isAllow } from '../../utils/permission';

import successIcon from '../../assets/img/icn_check.svg';
import warningIcon from '../../assets/img/icn_warning.svg';
import errorIcon from '../../assets/img/icn_error.svg';
import infoIcon from '../../assets/img/icn_info.svg';

import './style.css';
import { toast } from 'react-toastify';

import emptySearchImg from '../../assets/img/empty-search.svg';
import emptyBox from '../../assets/img/empty-box.svg'

// Responsive Flex สำหรับ layout
const ResponsiveFlex = styled.div`
  display: flex;
  padding: 5px;

  .date-picker {
    margin-left: 5px;
  }

  @media (max-width: 991.98px) {
    .code,
    .name {
      margin-left: 15px;
    }
  }

  @media (max-width: 767.98px) {
    display: block;

    .code,
    .name {
      margin-left: 0;
    }

    .date-picker {
      margin-left: 15px;
    }
  }
`;

export class BenefitPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    try {
      await this.props.benefit.getBenefitList();
    } catch (error) {
      toast.error('เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง');
    }
  }

  onChange = (key, value) => {
    if (key === 'start_date') {
      const date = moment(value).startOf('day').toISOString();
      this.props.benefit.onChangeQuery('start_date', date);
    } else if (key === 'end_date') {
      const date = moment(value).endOf('day').toISOString();
      this.props.benefit.onChangeQuery('end_date', date);
    } else {
      this.props.benefit.onChangeQuery(key, value);
    }
  };

  async onSearch() {
    try {
      await this.props.benefit.getBenefitList();
    } catch (error) {
      toast.error('เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง');
    }
  }

  onClear() {
    this.props.benefit.clearQuery();
    this.props.benefit.getBenefitList();
  }

  onClick(action, id) {
    try {
      if (action === 'create') {
        this.props.history.push(`${config.publicUrl}/superadmin/benefit/create`);
      }
  
      if (action === 'edit') {
        this.props.history.push(`${config.publicUrl}/superadmin/benefit/${id}/edit`);
      }
  
      if (action === 'delete') {
        Swal.fire({
          imageUrl: infoIcon,
          title: 'ยืนยันการลบข้อมูล',
          text: 'คุณต้องการลบข้อมูลหรือไม่?',
          reverseButtons: true,
          showCancelButton: true,
  
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            icon: 'swal-icon-delete-benefit',
            container: 'swal-custom-container-benefit',
            popup: 'swal-custom-popup-benefit',
            title: 'swal-custom-title-benefit',
            htmlContainer: 'swal-custom-text-benefit',
            actions: 'swal-buttons-right-benefit',
            confirmButton: 'swal-confirm-button-benefit',
            cancelButton: 'swal-cancel-button-benefit',
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const user = this.props.auth.getUserInfo();
              await this.props.benefit.deleteBenefit(id, _.get(user, 'email', ''));
              toast.success('ลบข้อมูลสำเร็จ')
            } catch (error) {
              toast.error('เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง')
            }
          }
        });
      }
    } catch (error) {
      toast.error('เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง')
    }
  }

  onChangeStatus = async (id, value) => {
    try {
      const user = this.props.auth.getUserInfo();
      await this.props.benefit.updateBenefitStatus(id, { is_active: value, user: _.get(user, 'email', '') });
      toast.success('อัพเดทสถานะสำเร็จ')
    } catch (error) {
      toast.error('เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง')
    }
  };

  onChangePage = (page) => {
    try {
      this.props.benefit.onChangePage(page);
      this.props.benefit.getBenefitList();
    } catch (error) {
      toast.error('เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง')
    }
  };

  renderTable(benefits, months, query) {
    const columns = [
      { title: 'รหัส', key: 'code', width: '10%' },
      { title: 'ชื่อสิทธิประโยชน์', key: 'name', width: '25%' },
      { title: 'วันที่เริ่มต้น-สิ้นสุด', key: 'startDate', width: '20%' },
      { title: 'สถานะการใช้งาน', key: 'isActive', width: '10%' },
      { title: 'วันที่แก้ไขล่าสุด', key: 'updatedDate', width: '20%' },
      { title: '', key: 'icon', width: '15%' },
    ];

    const mapMonth = (date) => {
      const month = moment(date).format('MM');
      const year = moment(date).add(543, 'years').format('YYYY');
      const day = moment(date).format('DD');
      const monthName = months.find((m) => parseInt(m.value) === parseInt(month)).short;
      return `${day} ${monthName} ${year}`;
    };

    const isFilterSearch = !_.isEmpty(query.code) || !_.isEmpty(query.name) || !_.isEmpty(query.start_date) || !_.isEmpty(query.end_date);

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover has-pagination">
              <thead>
                <tr>
                  {columns.map((col) => {
                    if (col.key === 'icon') {
                      return (
                        <th key={col.key} style={{ width: col.width, textAlign: 'center' }}>
                          <EnButton className="btn-success" onClick={() => this.onClick('create')} disabled={!isAllow('CREATE_BENEFITS')}>
                            <i className="fa fa-plus btn-icon" aria-hidden="true" />
                            สร้างใหม่
                          </EnButton>
                        </th>
                      );
                    }
                    return (
                      <th key={col.key} style={{ width: col.width, textAlign: 'center' }}>
                        {col.title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {
                  // if filter and benefits is empty
                  isFilterSearch && _.isEmpty(benefits) && (
                    <tr key={0}>
                      <td colSpan="12" style={{ height: '270px' }}>
                        <div className=" d-flex w-100 justify-content-center justify-items-center" style={{ height: '100%' }}>
                          <div className="text-center">
                            <img className="additional-products-page__empty-img" src={emptySearchImg} />
                            <div style={{ fontSize: '24px', marginTop: '16px', color: '#014672' }}>ไม่พบข้อมูลที่ค้นหา</div>
                            <div style={{ color: '#838799' }}>กรุณาลองใหม่อีกครั้ง</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                }

                {
                  // if not filter and benefits is empty
                  !isFilterSearch && _.isEmpty(benefits) && (
                    <tr key={0}>
                      <td colSpan="12" style={{ height: '270px' }}>
                        <div className=" d-flex w-100 justify-content-center justify-items-center" style={{ height: '100%' }}>
                          <div className="text-center">
                            <img className="additional-products-page__empty-img" src={emptyBox} />
                            <div style={{ marginTop: '16px', color: '#838799' }}>ยังไม่มีข้อมูลสิทธิประโยชน์</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                }

                {_.isArray(benefits) &&
                  benefits.map((item, index) => {
                    return (
                      <tr key={index}>
                        {columns.map((col) => {
                          if (col.key === 'code') {
                            return (
                              <td key={col.key} style={{ textAlign: 'center' }}>
                                <p>{item[col.key]}</p>
                              </td>
                            );
                          }

                          if (col.key === 'name') {
                            return (
                              <td key={col.key} style={{ textAlign: 'left' }}>
                                <p>{item[col.key]}</p>
                              </td>
                            );
                          }

                          if (col.key === 'isActive') {
                            return (
                              <td key={col.key} style={{ textAlign: 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <CustomToggle
                                    checked={item[col.key]}
                                    onChange={(value) => this.onChangeStatus(item._id, value)}
                                    disabled={!isAllow('EDIT_BENEFIT_DETAILS')}
                                  />
                                </div>
                              </td>
                            );
                          }

                          if (col.key === 'startDate') {
                            return (
                              <td key={col.key} style={{ textAlign: 'center' }}>
                                <p>
                                  {mapMonth(item.startDate)} - {mapMonth(item.endDate)}
                                </p>
                              </td>
                            );
                          }

                          if (col.key === 'updatedDate') {
                            return (
                              <td key={col.key} style={{ textAlign: 'center' }}>
                                <p>{mapMonth(item.updatedDate)}</p>
                              </td>
                            );
                          }

                          if (col.key === 'icon') {
                            return (
                              <td
                                key={col.key}
                                className="text-center"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                <button
                                  className="btn btn-sm"
                                  title="แก้ไข"
                                  data-tip="แก้ไข"
                                  onClick={() => this.onClick('edit', item._id)}
                                >
                                  <i className="fa fa-pencil" aria-hidden="true" />
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  title="ลบ"
                                  data-tip="ลบ"
                                  onClick={() => this.onClick('delete', item._id)}
                                  disabled={!isAllow('DELETE_BENEFITS')}
                                >
                                  <i className="fa fa-trash" aria-hidden="true" />
                                </button>
                              </td>
                            );
                          }

                          return null; // Handle other cases if needed
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { query, pagination, months, benefits, isLoading } = this.props.benefit.toJS();

    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">สิทธิประโยชน์</h4>
              </div>

              <div className="card-content">
                <div className="form-group col-md-12">
                  <ResponsiveFlex className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">รหัส</label>
                    <EnText
                      className="col-md-8 col-sm-12 code"
                      placeholder="ค้นหาด้วยรหัส"
                      value={query.code}
                      onChange={(e) => this.onChange('code', e.target.value)}
                    />
                  </ResponsiveFlex>
                  <ResponsiveFlex className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">ชื่อสิทธิประโยชน์</label>
                    <EnText
                      className="col-md-8 col-sm-12 name"
                      placeholder="ค้นหาด้วยชื่อสิทธิประโยชน์"
                      value={query.name}
                      onChange={(e) => this.onChange('name', e.target.value)}
                    />
                  </ResponsiveFlex>
                </div>

                <div className="form-group col-md-12">
                  <ResponsiveFlex className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">วันที่เริ่มต้น</label>
                    <CustomDatePicker
                      isPlaceholder="วันที่"
                      initialDate={query.start_date ? moment(query.start_date) : null}
                      isOutsideRange={(day) =>
                        query.end_date && moment(query.end_date).isBefore(day)
                      }
                      onDateChange={(date) => this.onChange('start_date', date)}
                    />
                  </ResponsiveFlex>
                  <ResponsiveFlex className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">วันที่สิ้นสุด</label>
                    <CustomDatePicker
                      isPlaceholder="วันที่"
                      initialDate={query.end_date ? moment(query.end_date) : null}
                      isOutsideRange={(day) =>
                        query.start_date && moment(query.start_date).isAfter(day)
                      }
                      onDateChange={(date) => this.onChange('end_date', date)}
                    />
                  </ResponsiveFlex>
                </div>

                <div
                  className="col-md-12 d-flex align-items-center"
                  style={{ padding: '1rem', justifyContent: 'end' }}
                >
                  <EnButton className="btn-info pull-right" onClick={() => this.onSearch()}>
                    <i className="fa fa-search btn-icon" aria-hidden="true" />
                    ค้นหา
                  </EnButton>
                  <EnButton className="btn-warning pull-right" onClick={() => this.onClear()}>
                    <i className="fa fa-refresh btn-icon" aria-hidden="true" />
                    เคลียร์
                  </EnButton>
                </div>

                <div className="col-md-12" style={{ paddingTop: '2rem' }}>
                  {this.renderTable(benefits, months, query)}
                </div>

                <div className="col-md-12">
                  <Pagination
                    pagination={pagination}
                    handlePageClick={(value) => this.onChangePage(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'benefit')(observer(BenefitPage));

import React from 'react';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { FormValidation, required, password, passwordConfirm } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnButton from '../../../components/form/EnButton';
import FormGroup from '../../../components/form/FormGroup';
import EnTagSelect from '../../../components/form/EnTagSelect';
import './store.css';
import config from '../../../config';
import { toast } from 'react-toastify';

class EditProduct extends React.Component {
    constructor(props) {
        super(props);
        const storeId = props.storeId
        this.state = {
            id: storeId,
            productIds: []
        };
      }
    
    async componentDidMount() {
        await this.setup()
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.admin.toJS().storeInfo.storeId !== prevProps.store.storeId) {
           await this.setup()
        }
    }

    setup = (async () => {
        const info = this.props.admin.toJS().storeInfo 
        await this.props.admin.getStoreById(info.storeId);
        await this.props.product.getProductByChannel(info.channel)
        this.setState({productIds: this.props.admin.toJS().storeInfo.productIds})
    })

    productChannel = (()=> {
        const product = this.props.product.toJS().productChannel || [];
        if(product.length > 0) {
            return product.map((item) => {
                return ({
                    id: item.id,
                    label: item.name.th,
                    value: item.id,
                })
            });
        }
    })

    onSaveProduct = (async()=> {
        try {
            await this.props.admin.updateProductStore();
            toast.success('เปลี่ยนรหัสผ่านเรียบร้อย');
            window.location.reload(false)
        } catch (error) {
            if (error.message === 'USER_NOT_EXIST') {
            toast.error('ไม่พบ้อมูล user');
            } else {
            toast.error(error.message || 'เกิดข้อผิดพลาด');
            }
        }
    })

    onTagChangeProduct = ((value)=> {
        const ids = value ? value.split(',') : [];
        this.props.admin.setStoreInfo('productIds', ids);
    })

    render() {
        const info = this.props.admin.toJS().storeInfo;
        const product = this.props.product.toJS().productChannel
        return(
            <div>
                <FormValidation>
                    <div className="col-sm-6">
                        <label>ชื่อร้าน</label>
                        <EnTextFormGroup disabled="true" type="text" value={info.name}/>
                    </div>
                    <div className="col-sm-6">
                        <label>channel</label>
                        <EnTextFormGroup disabled="true" type="text" value={info.channel}/>
                    </div>
                    <div  style={{ marginLeft: '15px' }}>
                        <FormGroup container ClassName="col-md-12">
                            <div className='row'>
                                <span className="col-xs-6">รายการสินค้า</span>
                                <span className="col-xs-6 text-right">
                                    Total: {_.get(this.state,'productIds', []).length } from {product.length } 
                                </span>
                            </div>
                            <EnTagSelect
                            withHeight
                            placeholder="กรุณาเลือกสินค้า"
                            closeOnSelect={false}
                            options={ this.productChannel() }
                            value={info.productIds}
                            onTagChange={(value) => this.onTagChangeProduct(value)}
                            searchable={false}
                            />
                        </FormGroup>
                    </div>
                    <div className="div_EnButton row">
                    <EnButton className="btn-info pull-right" validations={[required]} onClick={()=> this.onSaveProduct()}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                    </EnButton>
                    <EnButton className="btn-danger pull-right" onClick={this.props.onCancel()}>
                        <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                    </EnButton>
                    </div>
                </FormValidation>
            </div>
        )
    }

}

export default inject('admin', 'typeofwork','product')(observer(EditProduct));
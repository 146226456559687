import React, { Fragment } from 'react';
import _ from 'lodash'
import { observer, inject } from 'mobx-react';
import swal from 'sweetalert2';
import config from '../../config';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import Pagination from '../../components/common/Pagination';
import Axios from 'axios'
import moment from 'moment';
import AuthStore from '../../stores/AuthStore'
import EnButton from '../../components/form/EnButton';
import Payment2C2P, { submitPaymentForm } from '../../components/payment2C2P/Payment2C2P';

const PAGE_SIZE = 20;

export class PaymentSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      channel: null,
      data: [],
      orderId: [],
      outstandingBalance: 0,
      tab: 'customer-tab',
      sotresSelected: [],
      productsSelected: [],
      typeOfJobsSelected: [],
      isActive: true,
      optionStores: [],
      optionProducts: [],
      optionTypeOfJob: [
        { label: "สำรวจ", value: "S" },
        { label: "ติดตั้ง", value: "I" },
      ],
      pagination: {
        "page": "1",
        "page_range": "5",
        "items_per_page": "7",
      },
      query: {},
      searchText: '',
    };
  }

  async componentWillMount() {
    this.getJobsPaymentSummary()
    // try {
    //   const channelName = this.props.auth.getChannelName();
    //   this.setState({ channel: channelName })
    //   await this.props.employee.getAllEmployeeForChannel(channelName);
    // } catch (err) {
    //   throw new Error('โหลดข้อมูลล้มเหลว');
    // }
  }

  onChangeActiveToggle(index, item) {
    this.props.employee.changeActiveToggle(index, item.id, !item.isActive);
  }

  onEditData(item) {
    if (item && item.id) {
      this.props.history.push(
        `${config.publicUrl}/employee/${item.id}/edit`
      );
    }
  }

  async onDelete(index, item) {
    let swalOption = {
      title: 'ลบข้อมูล',
      text: 'คุณต้องการลบ รายชื่อพนักงาน นี้ใช่หรือไม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.props.employee.deleteEmployee(item.id);
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    swal.fire(swalOption).then((result) => {
      if (result && result.value) {
        swal.fire({
          title: `ลบข้อมูลเรียบร้อย`,
          icon: 'success',
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  }

  setLoading(VALUE) {
    this.setState({
      isLoading: VALUE || false
    })
  }
  async onPageClick(pageNum) {
    await this.getJobsPaymentSummary(pageNum);
  }

  async getJobsPaymentSummary(page) {
    this.setLoading(true)

    const channel = this.props.auth.getChannelName() || '';
    const { isActive, typeOfJobsSelected, sotresSelected, productsSelected } = this.state

    try {
      let body = {}
      body.is_active = isActive
      if (channel) {
        body.channel = channel
      }
      body = {
        ...body,
        "page": page || this.state.pagination.page,
        "page_range": this.state.pagination.page_range,
        "page_size": this.state.pagination.items_per_page,
      }
      const storeUser = AuthStore.getStore()
      body.bu_store_id = storeUser._id
      const url = `${config.api.sims}/qchang/v1/orders/jobs/paymentSummary`;
      const res = await Axios.post(url, body)
      if (res.data) {
        this.setState({
          data: res.data.data,
          pagination: res.data.pagination,
          outstandingBalance: res.data.outstandingBalance,
          orderId: res.data.orderId
        })
      }
    } catch (error) {
      console.error('error', error)
    }
    this.setLoading(false)

  }

  getContentTable() {
    let prefixUri;
    prefixUri = `${config.qchang.url}/tracking`;
    if (this.state.data.length === 0) {
      return (
        <tr key={0}>
          <td colSpan='9' className="text-center">
            ไม่มีข้อมูล
          </td>
        </tr>
      );
    }
    const items = this.state.data.map((item, index) => {
      return (
        <tr key={index}>
          <td className="text-center">
            {
              item.is_active
                ? (<a href={`${prefixUri}/${item.orderId}`} title={item.job_code} target="_blank">{item.job_code || item._id}</a>)
                : <a>{item.job_code || item._id}</a>
            }
          </td>
          <td className="text-center">
            {item.customer_firstname + ' ' + item.customer_lastname}
          </td>
          <td className="text-center">{item.customer_phone}</td>
          <td>{item.description}</td>
          <td className="text-center">{item.price.toLocaleString()}</td>
          <td className="text-center">{item.discount.toLocaleString() || 0}</td>
          <td className="text-center">{item.total_price.toLocaleString()}</td>
          <td className="text-center">{moment(item.create_job.date).format('DD-MM-YYYY')}</td>
          <td className="text-center">
            {
              item.store_payment_info && item.store_payment_info.is_paid ? 'ชำระเงินแล้ว' :
                <div className="btn-info btn-danger">หน้าร้านยังไม่ชำระ</div>
            }
          </td>
        </tr>
      );
    });
    return items;
  }

  async payment() {
    this.setLoading(true)

    try {
      let body = {}
      body.orderCodes = this.state.orderId;
      body.frontend_url = `${config.payment2c2p.frontendResultUrl}/paymentSummary`;
      const url = `${config.api.sims}/qchang/v1/orders/shop-payment`;
      const res = await Axios.post(url, body)
      if (res.data) {
        submitPaymentForm(res.data.payment2c2pInfo, res.data.paymentUrl)
      }
    } catch (error) {
      console.error('error', error)
    }
    this.setLoading(false)
  }

  render() {
    return (
      <Container isAdmin>
        <Loader show={this.state.isLoading} />
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-header' data-background-color='orange'>
                <h4 className='title'>สรุปการชำระเงินทั้งหมด</h4>
              </div>
              <div className='card-content'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='table-responsive'>
                      <table className='table table-bordered table-hover has-pagination'>
                        <thead>
                          <tr>
                            <th width='8%' className='text-center'>เลขอ้างอิง</th>
                            <th width='10%' className='text-center'>ชื่อลูกค้า</th>
                            <th width='8%' className='text-center'>เบอร์ติดต่อลูกค้า</th>
                            <th width='14%' className='text-center'>รายการสินค้า</th>
                            <th width='5%' className='text-center'>ราคาขาย</th>
                            <th width='5%' className='text-center'>ส่วนลดทั้งหมด</th>
                            <th width='5%' className='text-center'>ยอดสุทธิ</th>
                            <th width='10%' className='text-center'>วันที่สร้างงาน</th>
                            <th width='10%' className='text-center'>สถานะการชำระเงิน</th>
                          </tr>
                        </thead>
                        <tbody>{this.getContentTable()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Pagination
              pagination={this.state.pagination}
              handlePageClick={this.onPageClick.bind(this)}
            />
          </div>
          <div className="col-md-3" style={{ textAlign: 'end', marginTop: '1%' }}>
            <h4>ยอดค้างชำระ
              &nbsp;&nbsp;&nbsp;&nbsp;{this.state.outstandingBalance.toLocaleString()}
              &nbsp;&nbsp;&nbsp;&nbsp;บาท
            </h4>
          </div>
          <div className="col-md-3" >
            <EnButton className="btn-warning" disabled={this.state.outstandingBalance === 0 || false} onClick={this.payment.bind(this)} style={{ marginLeft: '2%', width: '100%' }}>
              ชำระเงิน
            </EnButton></div>
          <Payment2C2P />
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'employee')(observer(PaymentSummary));

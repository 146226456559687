import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'mobx-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer, Slide } from 'react-toastify';

import App from './App';
import mobxStore from './stores/store.js';
import TagManager from 'react-gtm-module';
import config from './config';

import 'react-toastify/dist/ReactToastify.css';
import './assets/css/react-toastify.css';
import 'ckeditor5/ckeditor5.css';
import './assets/css/ckeditor.css';
import 'react-toggle/style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-select/dist/react-select.css';
import 'react-quill/dist/quill.snow.css';
import 'react-tagsinput/react-tagsinput.css';
import './assets/css/material-dashboard.css';
import './assets/css/app.css';
import './assets/css/dropzone.css';
import './assets/css/month-picker.css';
import './assets/css/coupon.css';
import './assets/css/input.css';
import './assets/css/set-banner.css';
import './assets/css/autocomplete.css';
import './assets/css/tags-input-custom.css';
import 'react-device-frameset/lib/css/marvel-devices.min.css';
import './assets/css/search-bar.css';
import './assets/css/checkbox.css';
import './pages/benefit/style.css';

const tagManagerArgs = {
  gtmId: config.gtm.id,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#f47e29',
    },
    secondary: {
      main: '#1976d2',
    },
    error: {
      main: '#f44336',
    },
    success: {
      main: '#4caf50',
    },
    warning: {
      main: '#ff9800',
    },
  },
  typography: {
    fontFamily: 'DB Helvethaica X',
    fontSize: 28,
  },
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: {
  //       body: {
  //         fontFamily: 'DB Helvethaica X',
  //         fontSize: 26,
  //       },
  //     },
  //   },
  // },
});

TagManager.initialize(tagManagerArgs);

// React 18.3.1
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider {...mobxStore}>
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
      <App />
    </ThemeProvider>
  </Provider>,
);

import React, { Component, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import * as XLSX from 'xlsx-js-style';
import _ from 'lodash';
import { Col, Row } from 'react-bootstrap';

import Container from '../../layouts/Container';
import Pagination from '../../components/common/Pagination';
import EnTagSelect from '../../components/form/EnTagSelect';
import common from '../../utils/common';
import Loader from '../../components/common/Loader';
import DocumentIcon from '../../assets/img/document-icon.svg';
import '../../assets/css/additional-products-page.css';

const ERROR_TAG_OPTIONS = [
  { label: 'แสดงทั้งหมด', value: '' },
  { label: 'รหัสสินค้าซ้ำ', value: 'รหัสสินค้าซ้ำ' },
  { label: 'รายการสินค้าซ้ำ', value: 'รายการสินค้าซ้ำ' },
  { label: 'รหัสสินค้าไม่ถูกต้อง', value: 'รหัสสินค้าไม่ถูกต้อง' },
];

class AdditionalProductsErrorsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloading: false,
      isLoading: true,
      pageNumber: 1,
      searchText: undefined,
      selectedTag: undefined,
    };
    this.inputRef = createRef();
  }

  componentDidMount = () => {
    const { additionalProducts: additionalProductsStore, history } = this.props;
    if (!additionalProductsStore.errorCount) {
      history.replace('/sims/admin/additional-products');
      return;
    }
    this.setState({ isLoading: false });
  };

  handleBackIconClick = () => {
    const { history } = this.props;
    history.goBack();
  };

  exportToExcel = () => {
    this.setState({ isDownloading: true });

    const { additionalProducts: additionalProductsStore } = this.props;
    const { toExportErrorList, toExportRedColorList } = additionalProductsStore.toJS();
    const headerAddresses = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1'];
    const headerStyle = {
      alignment: { vertical: 'center', horizontal: 'center' },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
      },
      font: { bold: true, size: 12, name: 'Prompt' },
    };
    const dataStyle = {
      border: {
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
      },
      font: { size: 10, name: 'Prompt' },
    };
    const errorStyle = {
      ...dataStyle,
      font: { ...dataStyle.font, color: { rgb: 'ff0000' } },
    };

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(toExportErrorList);
    Object.keys(workSheet).forEach((address) => {
      let cell;
      if (address.startsWith('!')) {
        cell = workSheet[address];
      } else if (headerAddresses.includes(address)) {
        cell = { ...workSheet[address], s: headerStyle };
      } else if (toExportRedColorList.includes(address)) {
        cell = { ...workSheet[address], s: errorStyle };
      } else {
        cell = { ...workSheet[address], s: dataStyle };
      }
      workSheet[address] = cell;
    });
    XLSX.utils.book_append_sheet(workBook, workSheet, 'รายการสินค้าส่วนเพิ่ม');
    XLSX.writeFile(workBook, 'additional-products-error-list.xlsx');

    this.setState({ isDownloading: false });
  };

  getErrorList = () => {
    const { additionalProducts: additionalProductsStore } = this.props;
    const { pageNumber, searchText, selectedTag } = this.state;
    let errorList = additionalProductsStore.errorList || [];

    if (selectedTag) {
      console.log(selectedTag);
      errorList = errorList.filter(({ errorTags }) => errorTags.includes(selectedTag));
    }
    if (searchText) {
      errorList = errorList.filter(({ productCode, productName }) => productCode.includes(searchText) || productName.includes(searchText));
    }
    const sortedErrorList = _.sortBy(errorList, ['productCode']);
    const { paginated_data, pagination } = common.paginate(sortedErrorList, pageNumber, 10);

    return { errorList: paginated_data, pagination };
  };

  handleSelectChange = (selectedTag) => {
    this.setState({ selectedTag, pageNumber: 1 });
  };

  handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleSearch();
    }
  };

  handleSearch = () => {
    this.setState({ searchText: this.inputRef.current.value, pageNumber: 1 });
  };

  getTextStyle = (errorTags, targets) => {
    const isInErrorTags = errorTags.some((errorTag) => targets.includes(errorTag));
    if (isInErrorTags) {
      return { color: '#d91616' };
    }
    return undefined;
  };

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber });
  };

  render = () => {
    const { additionalProducts: additionalProductsStore } = this.props;
    const { isDownloading, isLoading, selectedTag } = this.state;
    const { errorList, pagination } = this.getErrorList();

    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <div className="card">
          <div className="card-header additional-products-page__card-header" data-background-color="orange">
            <i className="fa fa-chevron-left additional-products-page__card-header-icon" onClick={this.handleBackIconClick} />
            <h4 className="title additional-products-page__card-header-title">รายการที่ผิดพลาด ({additionalProductsStore.errorCount} รายการ)</h4>
          </div>
          <div className="card-content">
            <div className="additional-products-page__header-row">
              <div className="additional-products-page__header-col additional-products-page__margin-items">
                <span className="additional-products-page__note-text">หมายเหตุ:</span>
                <div>
                  <div>1. รหัสสินค้าและชื่อรายการสินค้าจะต้องไม่ซ้ำกันในแต่ละรายการ</div>
                  <div>2. กรุณาตั้งรหัสสินค้าตามรูปแบบนี้: AA-000 (เช่น CA-012, RT-075)</div>
                </div>
              </div>
              <div className="additional-products-page__header-col">
                <button
                  className="additional-products-page__btn additional-products-page__secondary-btn"
                  disabled={isDownloading}
                  type="button"
                  onClick={this.exportToExcel}
                >
                  <span>ดาวน์โหลดเอกสาร</span>
                  <img className="additional-products-page__btn-icon" src={DocumentIcon} alt="DocumentIcon" />
                </button>
              </div>
            </div>
            <div className="additional-products-page__header-row">
              <div className="additional-products-page__header-col additional-products-page__margin-items">
                <span>ตัวกรอง:</span>
                <EnTagSelect
                  className="additional-products-page__selector"
                  multi={false}
                  options={ERROR_TAG_OPTIONS}
                  placeholder="เลือกสาเหตุความผิดพลาด"
                  value={selectedTag}
                  onTagChange={this.handleSelectChange}
                />
              </div>
              <div className="input-group additional-products-page__header-col">
                <input className="form-control" placeholder="ค้นหารายการสินค้า" ref={this.inputRef} type="text" onKeyDown={this.handleInputKeyDown} />
                <div className="input-group-btn additional-products-page__search-btn-wrapper">
                  <button type="button" className="btn btn-primary additional-products-page__search-btn" onClick={this.handleSearch}>
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <Row>
              <Col md={12}>
                <table className="table table-bordered table-hover has-pagination">
                  <thead>
                    <tr>
                      <th>รหัสสินค้า</th>
                      <th>รายการสินค้า</th>
                      <th>สาเหตุความผิดพลาด</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorList &&
                      errorList.map((error, index) => (
                        <tr key={error.productCode + index}>
                          <td style={this.getTextStyle(error.errorTags, ['รหัสสินค้าซ้ำ', 'รหัสสินค้าไม่ถูกต้อง'])}>{error.productCode}</td>
                          <td style={this.getTextStyle(error.errorTags, ['รายการสินค้าซ้ำ'])}>{error.productName}</td>
                          <td className="additional-products-page__margin-items">
                            {error.errorTags.map((errorTag, index) => (
                              <span key={errorTag + index} className="additional-products-page__error-tag">
                                {errorTag}
                              </span>
                            ))}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Col>
            </Row>
            {pagination && (
              <Row>
                <Col md={12}>
                  <Pagination pagination={pagination} handlePageClick={this.handlePageChange} />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Container>
    );
  };
}

export default inject('additionalProducts')(observer(AdditionalProductsErrorsPage));

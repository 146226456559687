import React, { Component } from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import momentTz from 'moment-timezone';
import numeral from 'numeral';
import swal from 'sweetalert2';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import EnButton from '../../components/form/EnButton';
import EnDatePicker from '../../components/form/EnDatePicker';
import EnToggle from '../../components/form/EnToggle';
import config from '../../config';
import Select from 'react-select';
import { toast } from 'react-toastify';

export class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      paidDate: undefined,
      isUpdate: false,
      selectedAll: false,
      selectedIds: [],
      data: [],
    };
  }

  async componentWillMount() {
    try {
      await this.props.typeofwork.getTypeOfWorks({ visible: true });
      await this.props.contractor.getAllWithFields();
      this.props.ordersummary.setDefaultQuery();
      const data = await this.props.ordersummary.search();
      this.setState({ data });
    } catch (err) {
      toast.error(err.message);
    }
  }

  async componentWillUnmount() {
    this.setState({ paidDate: undefined });
  }

  async onToggleAdminApprovePaidClick(job) {
    const flag = !!(job.admin_approve_paid === false);
    let confirmDialogOptions = {
      title: flag ? 'ยืนยันเปิดการจ่ายเงิน' : 'ยืนยันระงับการจ่ายเงิน',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
    };
    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      const updatedData = await this.props.ordersummary.updateFlagAdminApprovePaid(job._id, flag);
      const { data } = this.state;
      const index = data.findIndex(d => d._id === updatedData._id);
      data[index].admin_approve_paid = updatedData.admin_approve_paid;
      this.setState({ data });
    }
  }

  async onSearchClick(e) {
    const data = await this.props.ordersummary.search();
    this.setState({ data });
  }

  async onResetSearchClick(e) {
    this.props.ordersummary.setDefaultQuery();
    this.setState({
      selectedAll: false,
      selectedIds: [],
    });
    const data = await this.props.ordersummary.search();
    this.setState({ data });
  }

  async onDownloadClick(isUpdate, e) {
    e.preventDefault();
    const hasSomeSelected = this.state.selectedIds.length > 0;
    if (!hasSomeSelected) {
      swal.fire({
        icon: 'error',
        title: 'กรุณาเลือกรายการสำหรับดาวน์โหลดอย่างน้อย 1 รายการ',
        confirmButtonText: 'ตกลง',
      });
      return;
    }
    this.setState({ showModal: true, isUpdate: isUpdate });
  }

  async onDownloadSCBClick(isUpdate, e) {
    e.preventDefault();
    const hasSomeSelected = this.state.selectedIds.length > 0;
    if (!hasSomeSelected) {
      swal.fire({
        icon: 'error',
        title: 'กรุณาเลือกรายการสำหรับดาวน์โหลดอย่างน้อย 1 รายการ',
        confirmButtonText: 'ตกลง',
      });
      return;
    }
    this.setState({ showModal: true, isUpdate: isUpdate });
  }

  async showDownloadFileDialog(isUpdate) {
    const { downloadFileUrl } = this.props.ordersummary.toJS();
    let fileTag = '';
    downloadFileUrl.forEach(file => {
      if (file) {
        const fileNames = file.split('/');
        const fileName = fileNames[fileNames.length - 1];
        fileTag += ` <a href="${file}" style={{ marginLeft: '15px' }}
        <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />${fileName}
      </a><p>`;
      }
    });
    const downloadDialogOptions = {
      title: `ไฟล์ดาวน์โหลด${isUpdate ? 'และอัพเดท' : ''}ล่าสุด`,
      html: fileTag,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ปิด',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
    };

    await swal.fire(downloadDialogOptions);
  }
  async onReDownloadClick(e) {
    e.preventDefault();
    this.showDownloadFileDialog();
  }

  onSelectAllClick() {
    let flag = !(this.state.selectedAll);
    let selectedItems = [];
    if (flag) {
      // const { data } = this.props.ordersummary.toJS();
      this.state.data.map(d => {
        if (!(d.admin_approve_paid === false)) {
          selectedItems.push(d._id);
        }
        return true;
      });
    }
    this.setState({ selectedIds: selectedItems, selectedAll: flag });
  }

  onSelectOneClick(index, data) {
    // this.props.ordersummary.toggleSelectOne(index);
    let { selectedIds } = this.state;
    const found = selectedIds.findIndex(i => i === data._id);
    if (found !== -1) {
      selectedIds.splice(found, 1);
    } else {
      selectedIds.push(data._id);
    }
    this.setState({ selectedIds });
  }

  handleQueryChange(key, e) {
    this.props.ordersummary.setQueryValue(key, e.target.value);
  }

  handleDateChange(key, date) {
    this.props.ordersummary.setQueryValue(key, date.toDate());
  }

  displaySearchForm(query, typeOfWorks = [], contractors = []) {
    const tow = _.orderBy(typeOfWorks, 'name').map((item) => {
      return {
        label: item.name,
        value: item.value,
      };
    });
    const ctr = _.orderBy(contractors, ['team_name']).map((con) => {
      return {
        label: con.team_name,
        value: con._id,
      };
    });

    return (
      <div className="row" style={{ marginTop: '15px' }}>
        <div className="col-md-12">
          <div className="form-horizontal">
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-md-3 control-label" htmlFor="jobCode">รหัสอ้างอิง</label>
                <div className="col-md-8">
                  <input type="text"
                    className="form-control"
                    id="jobCode"
                    placeholder="Q-xxxxxxxxxx"
                    value={query.job_code || ''}
                    onChange={this.handleQueryChange.bind(this, 'job_code')}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="col-md-3 control-label" htmlFor="typeOfJob">ลักษณะงาน</label>
                <div className="col-md-8">
                  <select
                    className="form-control"
                    id="typeOfJob"
                    value={query.type_of_job || ''}
                    onChange={this.handleQueryChange.bind(this, 'type_of_job')}>
                    <option value="">--- เลือก ---</option>
                    <option value="S">สำรวจ</option>
                    <option value="I">ติดตั้ง</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="col-md-3 control-label" htmlFor="typeOfJob">ประเภทงาน</label>
                <div className="col-md-8">
                  <Select
                    multi={false}
                    value={(tow || []).find((item) => item.value === query.type_of_work || '')}
                    options={tow}
                    className="select-custom1"
                    onChange={(selected) => {
                      const value = (selected) ? selected.value : '';
                      this.handleQueryChange('type_of_work', { target: { value } });
                    }}
                    placeholder="เลือก..."
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="col-md-3 control-label" htmlFor="contractor">ทีมช่าง</label>
                <div className="col-md-8">
                  {/* <select className="form-control" id="contractor" value={query.contractor_id} onChange={this.handleQueryChange.bind(this, 'contractor_id')}>
                    <option value={undefined}>--- เลือก ---</option>
                    {_.orderBy(contractors, ['team_name']).map((con, index) => <option key={`con-${index}`} value={con._id}>{con.team_name}</option>)}
                  </select> */}
                  <Select
                    multi={false}
                    value={(ctr || []).find((item) => item.value === query.contractor_id || '')}
                    options={ctr}
                    className="select-custom1"
                    onChange={(selected) => {
                      const value = (selected) ? selected.value : '';
                      this.handleQueryChange('contractor_id', { target: { value } });
                    }}
                    placeholder="เลือก..."
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="col-md-3 control-label" htmlFor="startDate">วันที่ลูกค้ายอมรับ</label>
                <div className="col-md-8">
                  <EnDatePicker
                    id="endDate"
                    initialDate={query.end_date ? moment(query.end_date) : undefined}
                    onDateChange={this.handleDateChange.bind(this, 'end_date')}
                    isOutsideRange={() => false}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="col-md-3 control-label" htmlFor="saleModel">Model การขาย</label>
                <div className="col-md-8">
                  <select
                    className="form-control"
                    id="saleModel"
                    value={query.sale_model}
                    onChange={this.handleQueryChange.bind(this, 'sale_model')}>
                    <option value="">--- เลือก ---</option>
                    <option value="MarketPlace">MarketPlace</option>
                    <option value="Retail">Retail</option>
                    <option value="Claim">Claim</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <div className="col-md-12" style={{ paddingTop: '0', paddingBottom: '0' }}>
                  <EnButton
                    className="btn-warning pull-right"
                    onClick={this.onResetSearchClick.bind(this)}
                    style={{ marginBottom: '0' }}>
                    <i className="fa fa-refresh btn-icon" aria-hidden="true" />เคลียร์
                  </EnButton>
                  <EnButton
                    className="btn-info pull-right"
                    onClick={this.onSearchClick.bind(this)}
                    style={{ marginBottom: '0' }}>
                    <i className="fa fa-search btn-icon" aria-hidden="true" />ค้นหา
                  </EnButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getTypeOfJob(typeOfJob) {
    return typeOfJob === 'S'
      ? <div>
        <i className="fa fa-search" aria-hidden="true" /> สำรวจ
      </div>
      : <div>
        <i className="fa fa-gavel" aria-hidden="true" /> ติดตั้ง
      </div>;
  }

  getTypeOfWorkText(typeOfWorks, typeOfWorkItems) {
    if (Array.isArray(typeOfWorks) && typeOfWorks.length) {
      const typeOfWork = +typeOfWorks[0];
      const item = typeOfWorkItems.find(tow => tow.value === typeOfWork);
      if (item) return item.name;
    }
    return '';
  }

  getHasFileIcon(hasFile) {
    return hasFile ? (
      <div>
        <i className="fa fa-check" aria-hidden="true" style={{ color: '#28a745' }} />
      </div>
    )
      : (
        <div>
          <i className="fa fa-times" aria-hidden="true" style={{ color: '#dc3545' }} />
        </div>
      );
  }

  getCanPaidToContractor(job) {
    let allowTransfer = true;
    if (job.transfers && job.transfers.length) {
      // กรณีมีการเช็คค่า subsidize มาแล้ว
      if (job.subsidize_promotion && job.subsidize_promotion.is_paid) {
        allowTransfer = true;
      } else {
        const filterJob = job.transfers.filter(t => t.paid_to_nexter <= 0);
        if (filterJob && filterJob.length) {
          allowTransfer = false;
        } else if (job.sale_model === 'Retail' && (job.contractor_detail && !job.contractor_detail.is_juristic && !job.contractor_detail.signature)) {
          allowTransfer = false;
        }
      }
    } else {
      allowTransfer = false;
    }

    return allowTransfer === true ? (
      <div>
        <i className="fa fa-check" aria-hidden="true" style={{ color: '#28a745' }} />
      </div>)
      : (
        <div>
          <i className="fa fa-times" aria-hidden="true" style={{ color: '#dc3545' }} />
        </div>
      );
  }

  getAllExistFiles(job, poPropKey) {
    const result = {
      hasPoFile: false,
      hasRcFile: false,
      hasGuaranteeFile: false,
    };
    //* * set quotqtion array */
    let quotationList = [];
    if (Array.isArray(job.quotation)) {
      quotationList = job.quotation;
    } else {
      if (job.quotation) {
        quotationList.push(job.quotation);
      }
    }

    let poList = [];
    if (Array.isArray(job[poPropKey])) {
      poList = job[poPropKey];
    } else {
      if (job[poPropKey]) {
        poList.push(job[poPropKey]);
      }
    }

    let invoiceList = [];
    if (Array.isArray(job.invoice)) {
      invoiceList = job.invoice;
    } else {
      if (job.invoice) {
        invoiceList.push(job.invoice);
      }
    }

    if (quotationList.length > 0) {
      //* * count po for check hasPoFile*/
      result.hasPoFile = quotationList.length <= poList.length;

      //* * count po for check hasRcFile*/
      for (let i = 0; i < quotationList.length; i++) {
        let countItems = invoiceList.filter(item => item.quotation_no === quotationList[i].no);
        result.hasRcFile = quotationList[i].periods.length === countItems.length;
        if (result.hasRcFile === false) {
          break;
        }
      }
    } else {
      result.hasPoFile = poList.length > 0;
      result.hasRcFile = invoiceList.length > 0;
    }
    result.hasGuaranteeFile = (job.guarantee && job.guarantee.length);
    return result;
  }

  getCustomerPaidAmount(job) {
    let sumCustomerPaid = 0;
    if (job.transfers && job.transfers.length) {
      job.transfers.map(t => {
        sumCustomerPaid += t.actual_paid;
      });
    }
    return sumCustomerPaid === 0 ? '-' : numeral(sumCustomerPaid).format('0,0.00');
  }

  getContractorAmount(job, poPropKey) {
    let sumContractorAmount = job[poPropKey].length > 0 ? _.sumBy(job[poPropKey], 'amount_exclude_wht') : 0;
    return sumContractorAmount > 0 ? numeral(sumContractorAmount).format('0,0.00') : '-';
  }

  displayTableRows(data, typeOfWorks) {
    return data.map((d, index) => {
      const no = index + 1;
      const poPropKey = d.type_of_job === 'S' ? 'po_survey' : 'po_installation';
      const existFile = this.getAllExistFiles(d, poPropKey);
      const customerPaidAmount = this.getCustomerPaidAmount(d);
      const contractorAmount = this.getContractorAmount(d, poPropKey);
      const adminApprovePaid = !(d.admin_approve_paid === false);
      const disabledRow = !adminApprovePaid;
      const displayRow = disabledRow ? 'row-disabled' : '';
      return (
        <tr key={`row-${index}`} className={displayRow}>
          <td className="text-center">
            <input
              id={`select-item-${no}`}
              name={`select-item-${no}`}
              type="checkbox"
              disabled={disabledRow}
              checked={this.state.selectedIds.find(i => i === d._id)}
              onChange={this.onSelectOneClick.bind(this, index, d)}
            />
          </td>
          <td className="text-center">{no}</td>
          <td className="text-center"><EnToggle checked={adminApprovePaid} onChange={this.onToggleAdminApprovePaidClick.bind(this, d)} /></td>
          <td className="text-center">
            {
              d.is_active
                ? (
                  <a href={`${config.publicUrl}/superadmin/jobs/${d._id}`}
                    target="_blank"
                    title={d.job_code}
                  >
                    {d.job_code || d._id}
                  </a>
                ) : <span>{d.job_code || d._id}</span>
            }
          </td>
          <td className="text-center">{d.sale_model}</td>
          <td>{`${d.customer_firstname} ${d.customer_lastname}`}</td>
          <td>{d.contractor_team_name}</td>
          <td className="text-center">{this.getCanPaidToContractor(d)}</td>
          {/* <td /> */}
          <td className="text-right">{customerPaidAmount}</td>
          <td className="text-right">{contractorAmount}</td>
          <td>{this.getTypeOfWorkText(d.type_of_work, typeOfWorks)}</td>
          <td className="text-center">{this.getTypeOfJob(d.type_of_job)}</td>
          <td className="text-center">{d.customer_approved_date ? momentTz(d.customer_approved_date).utcOffset(7).format('ddd Do/M/YYYY') : '-'}</td>
          <td className="text-center">{this.getHasFileIcon(existFile.hasPoFile)}</td>
          <td className="text-center">{this.getHasFileIcon(existFile.hasRcFile)}</td>
          <td className="text-center">{this.getHasFileIcon(existFile.hasGuaranteeFile)}</td>
          <td className="text-center">{d.contractor_detail && !d.contractor_detail.is_juristic ? this.getHasFileIcon(d.contractor_detail.signature) : '-'}</td>
          <td className="text-center">{d.contractor_bank_account_no}</td>
        </tr>
      );
    });
  }

  validateSubmit() {
    if (!this.state.paidDate) {
      swal.fire({
        icon: 'error',
        title: 'กรุณาเลือกวันที่ต้องการจ่ายเงินให้กับช่าง',
        confirmButtonText: 'ตกลง',
      });
    } else {
      this.saveAndClose();
    }
  }

  saveAndClose = async () => {
    if (!this.state.paidDate) {
      swal.fire({
        icon: 'error',
        title: 'กรุณาเลือกวันที่ต้องการจ่ายเงินให้กับช่าง',
        confirmButtonText: 'ตกลง',
      });
    } else {
      this.setState({ showModal: false });
      try {
        await this.props.ordersummary.download(this.state.isUpdate, this.state.paidDate, this.state.selectedIds);
        swal.fire({
          title: 'ปรับสถานะเรียบร้อย',
          icon: 'success',
        });
        this.setState({
          paidDate: undefined,
        });
        this.showDownloadFileDialog(this.state.isUpdate);
        if (this.state.isUpdate) {
          this.setState({
            selectedAll: false,
            selectedIds: [],
          });
          await this.props.ordersummary.search();
        }
        // return true;
      } catch (error) {
        this.setState({ paidDate: undefined });
        swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
      }
    }
  }

  onHide = () => {
    this.setState({ showModal: false });
    this.setState({ paidDate: undefined });
  }

  handlePaidDateChange(e) {
    this.setState({ paidDate: e.toDate() });
  }

  showConfirmCalendar() {
    return (
      <div>
        <Modal
          style={{ textAlign: 'center' }}
          show={this.state.showModal}
          onHide={this.onHide}
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="ModalHeader">ยืนยันปรับสถานะการจ่ายเงินให้ช่าง</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label style={{ paddingTop: 6, textAlign: 'right', paddingRight: 0 }} className="col-md-4 control-label" htmlFor="startDate">วันที่ช่างจะได้รับเงิน</label>
              <div className="col-md-6">
                <EnDatePicker id="startDate"
                  initialDate={this.state.paidDate ? moment(this.state.paidDate) : undefined}
                  onDateChange={this.handlePaidDateChange.bind(this)}
                // isOutsideRange={() => false}
                />
              </div>
              <div className="col-md-2" />
            </div>
          </Modal.Body>
          <Modal.Footer style={{ textAlign: 'center', paddingBottom: 20, paddingTop: 50 }}>
            <EnButton
              style={{
                marginRight: 4,
                paddingLeft: 30,
                paddingRight: 30,
              }}
              className="btn-success"
              onClick={this.saveAndClose}>
              บันทึก
            </EnButton>
            <EnButton
              style={{
                paddingLeft: 30,
                paddingRight: 30,
              }}
              className="btn-danger"
              onClick={this.onHide}>
              ยกเลิก
            </EnButton>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  render() {
    const { isLoading, query, downloadFileUrl } = this.props.ordersummary.toJS();
    const showDownloadFile = !!(downloadFileUrl && downloadFileUrl.length);
    const typeOfWorks = this.props.typeofwork.toJS().items;
    const contractors = this.props.contractor.toJS().list;

    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <div className="row">
          {this.showConfirmCalendar()}
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายการ Order</h4>
              </div>
              <div className="card-content">
                {this.displaySearchForm(query, typeOfWorks, contractors)}
                <div className="row">
                  <div className="col-md-12">
                    <a href=""
                      onClick={this.onDownloadClick.bind(this, false)}
                      style={{ marginLeft: '15px', marginRight: '15px' }}
                    >
                      <i className="fa fa-download btn-icon" aria-hidden="true" />ดาวน์โหลด
                    </a>
                    <a href=""
                      onClick={this.onDownloadClick.bind(this, true)}
                      style={{ marginLeft: '15px', marginRight: '15px' }}
                    >
                      <i className="fa fa-download btn-icon" aria-hidden="true" />อัพเดทสถานะและดาวน์โหลด
                    </a>
                    {(showDownloadFile) && (
                      <a href="" style={{ marginLeft: '15px' }}
                        onClick={this.onReDownloadClick.bind(this)}>
                        <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />ไฟล์ดาวน์โหลดล่าสุด
                      </a>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive" style={{ maxHeight: 500 }}>
                      <div className="table-fix-head">
                        <table className="table table-bordered table-hover" style={{ minWidth: '180%' }}>
                          <thead>
                            <tr>
                              <th width="40" className="text-center">
                                <input
                                  id="select-all"
                                  name="select-all"
                                  type="checkbox"
                                  checked={this.state.selectedAll}
                                  onChange={this.onSelectAllClick.bind(this)}
                                />
                              </th>
                              <th width="20">ลำดับ</th>
                              <th width="70" className="text-center">พร้อมจ่ายเงิน</th>
                              <th width="100" className="text-center">เลขที่อ้างอิง</th>
                              <th width="70" className="text-center">Model การขาย</th>
                              <th width="120" className="text-center">ชื่อลูกค้า</th>
                              <th width="120" className="text-center">ชื่อทีมช่าง</th>
                              <th width="80" className="text-center">จ่ายเงินช่างได้</th>
                              <th width="90" className="text-center">ราคาที่ลูกค้าจ่าย</th>
                              <th width="90" className="text-center">ราคาจ่ายช่าง</th>
                              <th width="180" className="text-center">ประเภทงาน</th>
                              <th width="80" className="text-center">ลักษณะงาน</th>
                              <th width="100" className="text-center">วันที่ลูกค้ายอมรับ</th>
                              <th width="60" className="text-center">ใบ PO</th>
                              <th width="60" className="text-center">ใบรับเงิน</th>
                              <th width="80" className="text-center">ใบรับประกัน</th>
                              <th width="60" className="text-center">ลายเซ็น</th>
                              <th width="100" className="text-center">เลขที่บัญชี</th>
                              {
                                // <th width="50" className="text-center" />
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {this.displayTableRows(this.state.data, typeOfWorks)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('ordersummary', 'typeofwork', 'contractor')(observer(OrderSummary));

import React from 'react';
import { isNil } from 'lodash';

import { fieldsEnum, initAddress } from './Finder';
import AddressTypeahead from './AddressTypeahead';
import { observer } from 'mobx-react';
import FormGroup from '../form/FormGroup';

class AddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addressObj: undefined,
      values: {
        acode: '',
        dcode: '',
        pcode: '',
        zcode: '',
        aname: '',
        dname: '',
        pname: '',
        zname: '',
      },
    };
    this.setAddressObj = this.setAddressObj.bind(this);
  }

  async componentDidMount() {

    if(!this.props.isEditJob){
      await initAddress();
    }
    
    let addressData = this.props.addressData;
    let addressObj = {
      aname: addressData.district || '',
      dname: addressData.subDistrict || '',
      pname: addressData.province || '',
      zname: addressData.postCode || '',
      acode: addressData.districtCode || '',
      dcode: addressData.subDistrictCode || '',
      pcode: addressData.provinceCode || '',
      zcode: addressData.postCode || '',
    };
    this.setState({ addressObj });
  }

  componentWillReceiveProps(nextProps) {
    let addressData = nextProps.addressData;
    let addressObj = {
      aname: addressData.district || '',
      dname: addressData.subDistrict || '',
      pname: addressData.province || '',
      zname: addressData.postCode || '',
      acode: addressData.districtCode || '',
      dcode: addressData.subDistrictCode || '',
      pcode: addressData.provinceCode || '',
      zcode: addressData.postCode || '',
    };
    this.setState({ addressObj });
  }

  setAddressObj(addressObj) {
    this.setState({ addressObj });
  }

  /**
   * Checks if a field key is in the list of not required fields.
   */
  isNotRequiredField = (fieldKey) => {
    const { notRequiredFields = [] } = this.props;
    return notRequiredFields.includes(fieldKey);
  };

  render() {
    const { addressObj } = this.state;
    const { disabled, validations, colSize } = this.props;
    let styleName = {};
    let options = {
      validations: !isNil(validations) && Array.isArray(validations),
    };

    return (
      <div>
        {
          Object.keys(fieldsEnum)
            .filter(key => (!this.props.displayFields || this.props.displayFields.includes(key)))
            .map((key) => {
              let name;
              let id;
              switch (fieldsEnum[key]) {
                case 'd': id = 'sub-district'; name = 'ตำบล'; styleName = {}; break;
                case 'a': id = 'district'; name = 'อำเภอ'; styleName = { paddingRight: 15 }; break;
                case 'p': id = 'province'; name = 'จังหวัด'; styleName = { paddingLeft: 15 }; break;
                case 'z': id = 'post-code'; name = 'รหัสไปรษณีย์'; styleName = {}; break;
                default: name = ''; break;
              }
              return (
                <div key={key} className={`form-group ${colSize || 'col-md-6'}`} style={styleName}>
                  <FormGroup label={name} notRequired={this.isNotRequiredField(fieldsEnum[key])} {...options}>
                    <AddressTypeahead
                      id={id}
                      disabled={disabled}
                      renderResult={this.props.renderResult}
                      onOptionSelected={(result) => {
                        this.setAddressObj(result);
                        this.props.onAddressSelected(result);
                      }}
                      onChange={(data) => {
                        if (this.props.onChange) {
                          this.props.onChange(data);
                        }
                      }}
                      value={addressObj ? addressObj[fieldsEnum[key] + 'name'] : ''}
                      fieldType={fieldsEnum[key] + 'name'}
                    />
                  </FormGroup>
                </div>
              );
            })
        }
      </div>
    );
  }
}
export default (observer(AddressForm));

import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import queryString from 'query-string';
import moment from 'moment';
import { Modal, Tab } from 'react-bootstrap';

import Container from '../../layouts/ContainerContractor';
import { FormValidation } from '../../components/form/FormValidation';
import FormGroup from '../../components/form/FormGroup';
import EnToggle from '../../components/form/EnToggle';
import EnButton from '../../components/form/EnButton';
import EnDropDown from '../../components/form/EnDropDown';
import EnNumeric from '../../components/form/EnNumeric';
import Loader from '../../components/common/Loader';
import datetime from '../../utils/datetime';
import { TypeOfJobs, AvailableTime } from '../../utils/data';
import config from '../../config';
import { EnTab } from '../../components/form/EnTab';
import ContractorCreateJob from '../contractors/ContractorCreateJob';

import '../../assets/css/vacation-page.css';
import { toast } from 'react-toastify';

export class ContractorEvent extends React.Component {
  constructor(props) {
    super(props);
    const { id: contractorId } = props.match.params;
    const { vacation_id, job_type, start, end } = queryString.parse(props.location.search);
    this.state = {
      showWorkCalendarTab: true,
      contractorId,
      vacationId: vacation_id,
      jobType: job_type,
      startDate: moment(start, 'YYYYMMDD'),
      endDate: moment(end, 'YYYYMMDD'),
      isInitVacation: true,
      vacation: undefined,
      eventVacations: [],
      selectedTime: 0,
      selectedJobType: job_type,
      isLoading: false,
      showDuplicatedModal: false,
      showConfirmModal: false,
      isConfirmedUpsertOverJobs: false,
      isEditableVacation: false,
    };
  }

  /**
   * @legacy This function needs refactoring.
   */
  async componentWillMount() {
    const { _id: contractorId } = this.props.auth.getContractor();
    const contractor = await this.props.contractor.getContractor({ id: contractorId });

    if (this.state.vacationId) {
      this.props.calendar.getVacation({
        vacationId: this.state.vacationId,
        contractorId,
      });
    } else {
      this.props.calendar.clearInfo();
      this.props.calendar.saveMultiInfo({
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        typeOfJob: this.state.jobType,
      });
    }

    let options = {
      contractorId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      jobType: this.state.jobType,
      contractorInfo: contractor,
    };

    await this.props.contractor.getContractorWorkCalendar(options);
    const validateTab = await this.props.contractor.validateWorkCalendarEvent(options);

    if (validateTab) {
      this.setState({ showWorkCalendarTab: false });
    }
  }

  componentDidMount = () => {
    this.loadVacation();
    this.checkVacationEditable();
  };

  /**
   * @legacy This function needs refactoring.
   */
  componentWillUnmount() {
    this.props.calendar.clearInfo();
    this.props.contractor.clearInfo();
  }

  loadVacation = async () => {
    const { contractor: contractorStore } = this.props;
    const { contractorId, vacationId } = this.state;
    if (vacationId) {
      const { calendar, event_calendars } = await contractorStore.getVacation({ contractorId, vacationId });
      this.setState({
        vacation: calendar,
        eventVacations: event_calendars,
        selectedTime: calendar.unavailable,
        selectedJobType: calendar.type_of_job,
      });
    }
    this.setState({ isInitVacation: false });
  };

  checkVacationEditable = () => {
    const { vacationId, startDate, endDate } = this.state;
    const currentDate = moment().startOf('day');
    const isCreateWithPastDate = !vacationId && startDate.isSameOrAfter(currentDate);
    const isUpdateWithOngoingDate = vacationId && endDate.isSameOrAfter(currentDate);
    if (isCreateWithPastDate || isUpdateWithOngoingDate) {
      this.setState({ isEditableVacation: true });
    }
  };

  /**
   * @deprecated
   */
  clickCancel() {
    const calendarStore = this.props.calendar.toJS();
    const info = calendarStore.info;
    let url = `${config.publicUrl}/contractors/${this.state.contractorId}/calendar?job_type=${info.typeOfJob}`;
    let date = this.state.startDate || this.state.endDate || info.startDate;
    if (date) {
      url += `&date=${moment(date).format('YYYY-MM')}`;
    }
    this.props.history.push(url);
  }

  submitVacation = async () => {
    try {
      this.setState({ isLoading: true });

      const { calendar: calendarStore } = this.props;
      const { contractorId, vacationId, selectedTime, selectedJobType } = this.state;
      const { ids, startDate, endDate } = this.getUpsertDetails();

      const { status } = await calendarStore.validateVacation({
        contractorId,
        startDate,
        endDate,
        jobType: selectedJobType,
        excludeIds: ids,
      });
      if (status === 200) {
        if (vacationId) {
          await calendarStore.updateVacation({
            contractorId,
            vacationIds: ids,
            newTime: selectedTime,
            newJobType: selectedJobType,
          });
        } else {
          await calendarStore.createVacation({
            contractorId,
            startDate,
            endDate,
            time: selectedTime,
            jobType: selectedJobType,
          });
        }
        toast.success('บันทึกข้อมูลเรียบร้อย');
        setTimeout(this.goToCalendarPage, 1000);
      } else if (status === 409) {
        this.setState({ showDuplicatedModal: true });
      } else if (status === 422) {
        this.setState({ showConfirmModal: true });
      }
    } catch ({ message }) {
      toast.error(message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  goToCalendarPage = () => {
    const { history } = this.props;
    const { contractorId, jobType, startDate } = this.state;
    const url = `${config.publicUrl}/contractors/${contractorId}/calendar`;
    const queryString = `job_type=${jobType}&date=${startDate.format('YYYY-MM')}`;

    history.replace(`${url}?${queryString}`);
  };

  getUpsertDetails = () => {
    const { vacationId, vacation, eventVacations, startDate, endDate } = this.state;

    // Create
    if (!vacationId) {
      return { ids: [], startDate, endDate };
    }
    // Update
    else if (vacation && eventVacations.length > 0) {
      const currentDate = moment().startOf('day');
      let earliestDate;
      let latestDate;
      const ids = [];

      eventVacations.forEach((eventVacation) => {
        const isSameJobType = eventVacation.type_of_job === vacation.type_of_job;
        const isFutureVacation = moment(eventVacation.start_date).isSameOrAfter(currentDate);

        if (isSameJobType && isFutureVacation) {
          const startDate = moment(eventVacation.start_date);
          const endDate = moment(eventVacation.end_date);

          if (!earliestDate || (earliestDate && startDate.isBefore(earliestDate))) {
            earliestDate = startDate;
          }
          if (!latestDate || (latestDate && endDate.isAfter(latestDate))) {
            latestDate = endDate;
          }

          ids.push(eventVacation._id);
        }
      });

      return { ids, startDate: earliestDate, endDate: latestDate };
    }

    // Deprecated
    return { ids: [vacationId], startDate, endDate };
  };

  deleteBtnClick = async () => {
    try {
      this.setState({ isLoading: true });

      const { calendar: calendarStore } = this.props;
      const { contractorId } = this.state;
      const { ids: toDeleteIds } = this.getUpsertDetails();

      await calendarStore.deleteVacation({ contractorId, vacationIds: toDeleteIds });

      toast.success('ลบข้อมูลเรียบร้อย');
      setTimeout(this.goToCalendarPage, 1000);
    } catch ({ message }) {
      toast.error(message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getDateRangeString = () => {
    const { location } = this.props;
    const { vacation, eventVacations } = this.state;
    const { start, end } = queryString.parse(location.search);
    const startDate = moment(start, 'YYYYMMDD');
    const endDate = moment(end, 'YYYYMMDD');

    if (vacation && eventVacations.length) {
      const filteredEventVacation = eventVacations.filter(({ type_of_job }) => type_of_job === vacation.type_of_job);
      const eventDateStrings = filteredEventVacation.map(({ start_date }) => start_date);
      return datetime.formatDatesInMonth(eventDateStrings);
    }

    const startDateString = datetime.ConvertISO2DisplayFullMonth(startDate);
    if (startDate.isSame(endDate)) {
      return startDateString;
    }

    const endDateString = datetime.ConvertISO2DisplayFullMonth(endDate);
    return `${startDateString} - ${endDateString}`;
  };

  handleSelectJobType = (event) => {
    this.setState({ selectedJobType: event.target.value });
  };

  /**
   * @legacy This function needs refactoring.
   */
  saveContractorJobPerDay(time, value) {
    if (value) {
      this.props.contractor.saveContractorJobPerDay(time, value);
    }
  }

  /**
   * @legacy This function needs refactoring.
   */
  async clickSubmitJobPerday() {
    try {
      const contractor = this.props.auth.getContractor();
      const { jobPerDay, workCalendarInfo, info: contractorInfo } = this.props.contractor.toJS();
      const { jobType, startDate, endDate } = this.state;

      const defaultSurveyPerDay = contractorInfo.surveyPerDay;
      const defaultInstallationPerDay = contractorInfo.installationPerDay;

      const options = {
        contractorId: contractor._id,
        startDate,
        endDate,
        surveyPerDay: jobType === 'S' ? jobPerDay : defaultSurveyPerDay,
        installationPerDay: jobType === 'I' ? jobPerDay : defaultInstallationPerDay,
        jobType,
      };

      let response;
      if (workCalendarInfo.length) {
        const workPerDay = workCalendarInfo[0];

        if (jobType === 'S') {
          options.installationPerDay = workPerDay.installation_per_day;
        } else {
          options.surveyPerDay = workPerDay.survey_per_day;
        }

        response = await this.props.contractor.updateContractorWorkCalendar(options);
      } else {
        response = await this.props.contractor.saveContractorWorkCalendar(options);
      }

      if (response.status === 200) {
        toast.success('บันทึกข้อมูลเรียบร้อย');
        setTimeout(() => {
          this.clickCancel();
        }, 1000);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  /**
   * @legacy This function needs refactoring.
   */
  getDisplayDate = () => {
    let calendarStore = this.props.calendar.toJS();
    let info = calendarStore.info;
    let showDate = datetime.ConvertISO2DisplayFullMonth(info.startDate);
    if (!datetime.isEqual(info.startDate, info.endDate)) {
      showDate += ` - ${datetime.ConvertISO2DisplayFullMonth(info.endDate)}`;
    }
    return showDate;
  };

  closeDuplicatedModal = () => {
    this.setState({ showDuplicatedModal: false });
  };

  closeConfirmModal = () => {
    this.setState({ showConfirmModal: false, isConfirmedUpsertOverJobs: false });
  };

  handleCheckboxChange = () => {
    const { isConfirmedUpsertOverJobs } = this.state;
    this.setState({ isConfirmedUpsertOverJobs: !isConfirmedUpsertOverJobs });
  };

  confirmUpsertVacation = async () => {
    try {
      this.setState({ isLoading: true, isConfirmedUpsertOverJobs: false, showConfirmModal: false });

      const { calendar: calendarStore } = this.props;
      const { contractorId, vacationId, selectedTime, selectedJobType } = this.state;
      const { ids, startDate, endDate } = this.getUpsertDetails();

      await calendarStore.acknowledgeVacation({
        contractorId,
        startDate,
        endDate,
        unavailable: selectedTime,
        jobType: selectedJobType,
      });
      if (vacationId) {
        await calendarStore.updateVacation({
          contractorId,
          vacationIds: ids,
          newTime: selectedTime,
          newJobType: selectedJobType,
        });
      } else {
        await calendarStore.createVacation({
          contractorId,
          startDate,
          endDate,
          time: selectedTime,
          jobType: selectedJobType,
        });
      }

      toast.success('บันทึกข้อมูลเรียบร้อย');
      setTimeout(this.goToCalendarPage, 1000);
    } catch (error) {
      toast.error(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      isInitVacation,
      vacation,
      selectedTime,
      selectedJobType,
      isLoading,
      showDuplicatedModal,
      showConfirmModal,
      isConfirmedUpsertOverJobs,
      isEditableVacation,
    } = this.state;
    const jobTypes = vacation ? TypeOfJobs.filter(({ id }) => id !== 'A') : TypeOfJobs;
    let contractor = this.props.contractor.toJS();
    const jobTypeLabel = this.state.jobType === 'I' ? 'ติดตั้ง' : 'สำรวจ';

    return (
      <Container>
        <div className="row">
          <Loader show={isInitVacation || isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ปฏิทิน</h4>
              </div>
              <div className="card-content">
                <FormValidation>
                  <EnTab>
                    <Tab title="ไม่รับงาน">
                      {!isInitVacation && (
                        <Fragment>
                          <FormGroup containerClassName="col-md-12">
                            <h4>{this.getDateRangeString()}</h4>
                            <h4>กรุณาเลือกช่วงเวลาที่ท่านไม่สะดวกรับงาน</h4>
                            {AvailableTime.map(({ id, title }) => (
                              <EnToggle key={id} checked={id === selectedTime} label={title} onChange={() => this.setState({ selectedTime: id })} />
                            ))}
                          </FormGroup>
                          <FormGroup containerClassName="col-md-12">
                            <h4>กรุณาเลือกลักษณะงาน</h4>
                            <EnDropDown value={selectedJobType} onChange={this.handleSelectJobType}>
                              {jobTypes.map(({ id, label }) => (
                                <option key={id} value={id}>
                                  {label}
                                </option>
                              ))}
                            </EnDropDown>
                          </FormGroup>
                          <div className="col-md-12">
                            <div className="col-md-6">
                              {vacation && (
                                <EnButton className="btn-danger pull-left" disabled={!isEditableVacation} onClick={this.deleteBtnClick}>
                                  <i className="fa fa-trash btn-icon" aria-hidden="true" />
                                  ลบ
                                </EnButton>
                              )}
                            </div>
                            <div className="col-md-6">
                              <EnButton className="btn-info pull-right" disabled={!isEditableVacation} onClick={this.submitVacation}>
                                <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
                                บันทึก
                              </EnButton>
                              <EnButton className="btn-danger pull-right" onClick={this.goToCalendarPage}>
                                <i className="fa fa-times btn-icon" aria-hidden="true" />
                                ยกเลิก
                              </EnButton>
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </Tab>
                    <Tab title="แก้ไขงานต่อวัน">
                      <FormGroup containerClassName="col-md-12">
                        <h4>{this.getDisplayDate()}</h4>
                        <h4>แก้ไขงาน{jobTypeLabel}ที่รับได้ต่อวัน</h4>
                        <div>
                          <h5>ช่วงเช้า</h5>
                          <div className="p4-font-input time-work">
                            <EnNumeric min={1} onChange={this.saveContractorJobPerDay.bind(this, 'morning')} value={contractor.jobPerDay.morning} />
                          </div>
                          <h5>ช่วงบ่าย</h5>
                          <div className="p4-font-input time-work">
                            <EnNumeric
                              min={1}
                              onChange={this.saveContractorJobPerDay.bind(this, 'afternoon')}
                              value={contractor.jobPerDay.afternoon}
                            />
                          </div>
                          <br />
                          <br />
                          <br />
                          <div className="col-md-12">
                            <EnButton className="btn-info pull-right" onClick={this.clickSubmitJobPerday.bind(this)}>
                              <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
                              บันทึก
                            </EnButton>
                            <EnButton className="btn-danger pull-right" onClick={this.clickCancel.bind(this)}>
                              <i className="fa fa-times btn-icon" aria-hidden="true" />
                              กลับ
                            </EnButton>
                          </div>
                        </div>
                      </FormGroup>
                    </Tab>
                    <Tab title="งานส่วนตัว">
                      <ContractorCreateJob
                        start={this.state.startDate}
                        end={this.state.endDate}
                        type={this.state.jobType}
                        contractorId={this.state.contractorId}
                        onCancel={this.clickCancel.bind(this)}
                      />
                    </Tab>
                  </EnTab>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
        <Modal className="vacation-page__modal-container" show={showDuplicatedModal}>
          <Modal.Header className="vacation-page__modal-header">
            <i className="fa fa-times-circle vacation-page__icon vacation-page__error-icon" />
            <Modal.Title className="vacation-page__modal-title">บันทึกวันหยุดรับงานไม่สำเร็จ</Modal.Title>
          </Modal.Header>
          <Modal.Body className="vacation-page__modal-body">
            วันที่คุณเลือกซ้ำกับวันหยุดรับงานที่มีในปฏิทินแล้ว กรุณายกเลิกวันหยุดรับงานเดิม และสร้างวันหยุดรับงานใหม่อีกครั้ง
          </Modal.Body>
          <Modal.Footer className="vacation-page__modal-footer">
            <button className="vacation-page__btn vacation-page__primary-btn" onClick={this.closeDuplicatedModal}>
              ตกลง
            </button>
          </Modal.Footer>
        </Modal>
        <Modal className="vacation-page__modal-container" show={showConfirmModal}>
          <Modal.Header className="vacation-page__modal-header">
            <i className="fa fa-exclamation-triangle vacation-page__icon vacation-page__warning-icon" />
            <Modal.Title className="vacation-page__modal-title">คุณมีงานที่นัดหมายลูกค้าแล้ว</Modal.Title>
          </Modal.Header>
          <Modal.Body className="vacation-page__modal-body">
            <div>กรุณาดำเนินงานตามที่ได้นัดหมายลูกค้าไว้ หรือติดต่อแอดมิน หากต้องการความช่วยเหลือ</div>
            <div className="vacation-page__modal-checkbox-container">
              <input
                id="vacation-page__existing-jobs-checkbox"
                type="checkbox"
                value={isConfirmedUpsertOverJobs}
                onChange={this.handleCheckboxChange}
              />
              <label htmlFor="vacation-page__existing-jobs-checkbox">ฉันได้รับทราบว่ายังมีงานที่ต้องทำให้แล้วเสร็จในวันหยุดรับงานที่ฉันเลือก</label>
            </div>
          </Modal.Body>
          <Modal.Footer className="vacation-page__modal-footer">
            <button
              className="vacation-page__btn vacation-page__primary-btn"
              disabled={!isConfirmedUpsertOverJobs}
              onClick={this.confirmUpsertVacation}
            >
              ตกลง
            </button>
            <button className="vacation-page__btn vacation-page__secondary-btn" onClick={this.closeConfirmModal}>
              ยกเลิก
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default inject('contractor', 'calendar', 'auth')(observer(ContractorEvent));

import _ from 'lodash';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import swal from 'sweetalert2';
import queryString from 'query-string';

import Loader from '../../components/common/Loader';
import Container from '../../layouts/Container';
import common from '../../utils/common';
import datetime from '../../utils/datetime';
import JobWizard from './JobWizard';
import config from '../../config';
import authStore from '../../stores/AuthStore';
import { toast } from 'react-toastify';

export class AdminUpdateJobRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobId: this.props.match.params.id,
      criteria: undefined,
      contractors: undefined,
      unavailableDate: undefined,
      instContractors: undefined,
      unavailableInstDate: undefined,
      isReadOnly: false,
      isPoLoading: false,
      statusClaim: false,
      statusDeleted: false,
    };
  }

  componentWillMount() {
    this.props.job.saveMultiInfo({
      workingAt: moment(),
    });
  }
  componentWillUnmount() {
    this.props.job.clearInfo();
  }

  async initData() {
    // Setting up job status
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.status) {
      this.setState({ statusClaim: parsed.status === 'claim' ? true : false })
      this.setState({ statusDeleted: parsed.status === 'deleted' ? true : false })

      const result = await this.props.job.getJobsById(this.state.jobId);
      if (!result) {
        swal.fire({
          icon: 'error',
          title: 'ไม่พบข้อมูลรายการนี้',
          confirmButtonText: 'ตกลง',
        });
        return;
      }
    } else {
      // await this.props.job.getJobsById(this.state.jobId);
      const result = await this.props.job.getJobsByIdAndCurrentActive(this.state.jobId);
      if (!result) {
        window.location.reload();
      }
      const { data, current_active_data: currentActiveData } = result;
      if (!data && !currentActiveData) {
        swal.fire({
          icon: 'error',
          title: 'ไม่พบข้อมูลรายการนี้',
          confirmButtonText: 'ตกลง',
        });
        return;
      }

      if (!data && currentActiveData) {
        this.props.history.replace(`${config.publicUrl}/admin/calendar/jobs/${currentActiveData._id}`);
        window.location.reload();
      }
    }

    await this.props.typeofwork.getTypeOfWorks();
    const jobInfo = this.props.job.toJS().info;
    const store = authStore.getStore();
    if (store && store._id && jobInfo && jobInfo.storeId) {
      if (store._id !== jobInfo.storeId) {
        this.setState({ isReadOnly: true });
      }
    }
    await this.checkAndBlockInstallationDate(jobInfo);
  }

  async checkAndBlockInstallationDate(jobInfo) {
    if (jobInfo) {
      if (jobInfo.jobType === 'S' && jobInfo.status === 'quotation_management') {
        const instDate = moment(new Date()).add(1, 'days').toDate();
        let instCriteria = {
          contractorId: jobInfo.contractorId,
          workTypeId: jobInfo.typeOfWork[0],
          startInstDate: instDate,
          endInstDate: instDate,
          jobType: 'I',
          available: 0,
        };
        const instResult = await this.props.job.getUnavailableDateForInstallation(instCriteria);
        if (instResult) {
          this.setState({
            instContractors: instResult.contractors,
            unavailableInstDate: instResult.unavailableDate || [],
          });
        }
      }
    }
  }

  async onCriteriaChange(criteria) {
    try {
      if (!_.isEqual(this.state.criteria, criteria)) {
        if (this.state.criteria && this.state.criteria.workTypeId !== criteria.workTypeId) {
          const data = await this.props.sku.getSkuByType(criteria.workTypeId);
          const sku = data.toJS();
          if (sku && sku.length) {
            this.props.job.setInfo('sku', sku[0].code);
          } else {
            this.props.job.setInfo('sku', '');
          }
        }
        const store = authStore.getStore();
        const jobInfo = this.props.job.toJS().info;
        if (jobInfo.isAssigned) {
          criteria.contractorId = jobInfo.contractorId;
        } else {
          criteria.contractorId = jobInfo.contractorId || '';
        }

        const result = await this.props.job.getDataForAdminJobRequest(criteria);
        if (result) {
          this.setState({
            criteria,
            contractors: result.contractors,
            unavailableDate: result.unavailableDate || [],
          });
        }
        await this.checkAndBlockInstallationDate(jobInfo);

        if (store && store.po_require && criteria.contractorId && criteria.skuCode) {
          const team = (this.state.contractors || []).filter(c => c._id === criteria.contractorId);
          if (team && team.length && team[0].cost && team[0].cost.length) {
            const cost = team[0].cost.filter(c => c.store_id === store._id).map(d => {
              return d.items;
            });
            if (cost[0] && cost[0].length) {
              const surveyCost = cost[0].filter(s => s.code === criteria.skuCode).find(result => result.survey_cost);
              const installationCost = cost[0].filter(s => s.code === criteria.skuCode).find(result => result.wage);
              const contractorSurveyCost = surveyCost && surveyCost.survey_cost;
              const contractorInstallationCost = installationCost && installationCost.wage;
              if (contractorSurveyCost || contractorInstallationCost) {
                this.props.job.setInfo('cost', {
                  survey: (criteria.jobType === 'S' && surveyCost && surveyCost.survey_cost) ? surveyCost.survey_cost : '',
                  installation: (criteria.jobType === 'I' && installationCost && installationCost.wage) ? installationCost.wage : '',
                });
              } else {
                this.props.job.setInfo('cost', {
                  survey: '',
                  installation: '',
                });
              }
            }
          }
        }
      }
    } catch (err) {
      this.setState({
        criteria,
        contractors: [],
        unavailableDate: [],
        instContractors: [],
        unavailableInstDate: [],
      });
    }
  }

  checkDayBlock(date) {
    const jobInfo = this.props.job.toJS().info;
    let { unavailableDate, contractors, criteria } = this.state;

    // if (criteria && jobInfo.contractorId) {
    //   const contractor = contractors.find(c => c._id === jobInfo.contractorId);
    //   if (contractor) {
    //     unavailableDate = common.unavailableDate([contractor], contractor.unavailable_date);
    //   }
    // }

    if (Array.isArray(unavailableDate) && unavailableDate.length) {
      const dateBlock = unavailableDate.find(u => datetime.isEqual(date, u));
      return !!dateBlock;
    } else {
      return false;
    }
  }

  checkInstDayBlock(date) {
    const jobInfo = this.props.job.toJS().info;
    let { unavailableInstDate, instContractors, criteria } = this.state;
    if (criteria && jobInfo.contractorId && instContractors) {
      const contractor = instContractors.find(c => c._id === jobInfo.contractorId);
      if (contractor) {
        unavailableInstDate = common.unavailableDate([contractor], contractor.unavailable_date);
      }
    }

    if (Array.isArray(unavailableInstDate) && unavailableInstDate.length) {
      const dateBlock = unavailableInstDate.find(u => datetime.isEqual(date, u));
      return !!dateBlock;
    } else {
      return false;
    }
  }

  validateJobInfo() {
    const options = { updateMode: true };
    return this.props.job.validateJobInfo(options);
  }

  onCancel() {
    const jobInfo = this.props.job.toJS().info;
    const calendarFilter = this.props.calendar.toJS().calendarFilter;
    let qs;
    if (calendarFilter) {
      qs = calendarFilter;
    } else {
      qs = {
        workTypeId: jobInfo.typeOfWork[0],
        jobType: jobInfo.jobType,
        contractorId: jobInfo.contractorId,
        date: moment(jobInfo.startDate || jobInfo.endDate).format('YYYY-MM'),
      };
    }
    this.props.job.clearInfo();
    this.props.history.push(`${config.publicUrl}/admin/calendar?${common.urlBuilder(qs)}`);
  }

  async onSubmit() {
    try {
      await this.props.job.updateJob(this.state.jobId, { isAdmin: true });
      toast.success('บันทึกข้อมูลคิวช่างเรียบร้อย');
      window.location.reload();
    } catch (error) {
      toast.error(error.message || 'พบข้อผิดพลาด');
    }
  }

  async onDelete(reason = null, userByPasscode = null) {
    try {
      await this.props.job.deleteJob(this.state.jobId, { reason, user: userByPasscode });
      toast.success('ลบคิวช่างเรียบร้อย');
      setTimeout(() => {
        this.onCancel();
      }, 1000);
    } catch (error) {
      toast.error(error.message);
    }
  }

  async goToManageCost(id) {
    // ASSIGN TEAM LATER
    if (this.state.criteria && this.state.criteria.eventName === 'TEAM_CHANGED') {
      await this.props.job.setInfo('contractorId', '');
      this.setState({ criteria: undefined });
      document.getElementById('contractor').focus();
      window.open(`${config.publicUrl}/admin/contractor/cost/${id}`, '_blank');
    } else {
      await this.props.job.setInfo('sku', '');
      this.setState({ criteria: undefined });
      document.getElementById('contractor').focus();
      window.open(`${config.publicUrl}/admin/contractor/cost/${id}`, '_blank');
    }
  }

  onUpdatePoSurvey = async (jobInfo) => {
    try {
      this.setState({ isPoLoading: true });
      const response = await this.props.job.sendPurchaseOrder();
      if (response && response.message === 'success') {
        toast.success('ส่ง PO เรียบร้อย');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error('พบข้อผิดพลาด');
      }
    } catch (error) {
      toast.error(error.message || 'พบข้อผิดพลาด');
    } finally {
      this.setState({ isPoLoading: false });
    }
  }

  onCancelJob = async (job) => {
    try {
      const jobInfo = this.props.job.toJS().info;
      const calendarFilter = this.props.calendar.toJS().calendarFilter;
      const response = await this.props.job.cancelAndRecoverJob(job.jobCode);
      if (response.message === 'success') {
        toast.success('บันทึกข้อมูลคิวช่างเรียบร้อย');
        setTimeout(() => {
          let qs;
          if (calendarFilter) {
            qs = calendarFilter;
          } else {
            qs = {
              workTypeId: jobInfo.typeOfWork[0],
              jobType: jobInfo.jobType,
              date: moment(jobInfo.startDate || jobInfo.endDate).format('YYYY-MM'),
            };
          }
          this.props.history.push(`${config.publicUrl}/admin/calendar?${common.urlBuilder(qs)}`);
        }, 1000);
      } else {
        toast.error('พบข้อผิดพลาด');
      }
    } catch (error) {
      toast.error(error.message || 'พบข้อผิดพลาด');
    }
  }

  async onContinueOrBreakJob(id, flag, doReload = true) {
    try {
      await this.props.job.continueOrBreakJob(id, flag);
      toast.success('บันทึกข้อมูลคิวช่างเรียบร้อย');
      if (doReload) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  render() {
    const jobStore = this.props.job.toJS();
    const exportdata = this.props.exportdata.toJS();
    const { isPoLoading } = this.state;
    let showJobStep = true;

    return (
      <Container isAdmin>
        <Loader show={jobStore.isSubmitting || jobStore.isLoading || exportdata.isLoading || isPoLoading} />
        <div className="row">
          <div className="card">
            <div className="card-header" data-background-color="orange">
              <h4 className="title">ปฏิทิน</h4>
              <p className="category">รายละเอียดการใช้บริการ</p>
            </div>
            <div className="card-content">
              <div className="card-content">
                <JobWizard
                  showJobStep={showJobStep}
                  showQuestionnaire
                  contractors={this.state.contractors}
                  isReadOnly={this.state.isReadOnly}
                  instContractors={this.state.instContractors}
                  unavailableDate={this.state.unavailableDate}
                  unavailableInstDate={this.state.unavailableInstDate}
                  statusClaim={this.state.statusClaim}
                  statusDeleted={this.state.statusDeleted}
                  validation={this.validateJobInfo()}
                  checkDayBlock={this.checkDayBlock.bind(this)}
                  checkInstDayBlock={this.checkInstDayBlock.bind(this)}
                  initial={this.initData.bind(this)}
                  onSubmit={this.onSubmit.bind(this)}
                  onCancel={this.onCancel.bind(this)}
                  onCriteriaChange={this.onCriteriaChange.bind(this)}
                  onDelete={this.onDelete.bind(this)}
                  goToManageCost={this.goToManageCost.bind(this)}
                  onUpdatePoSurvey={this.onUpdatePoSurvey.bind(this)}
                  onCancelJob={this.onCancelJob.bind(this)}
                  onContinueOrBreakJob={this.onContinueOrBreakJob.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'job', 'calendar', 'exportdata', 'typeofwork', 'sku')(observer(AdminUpdateJobRequest));

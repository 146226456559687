import * as _ from 'lodash';
import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import FormGroup from '../../components/form/FormGroup';
import EnButton from '../../components/form/EnButton';
import EnTagSelect from '../../components/form/EnTagSelect';
import Container from '../../layouts/Container';
import authStore from '../../stores/AuthStore';
import Loader from '../../components/common/Loader';
import { isAllow } from '../../utils/permission';
import EnText from '../../components/form/EnText';
import SimplePagination from '../../components/common/SimplePagination';
import { toast } from 'react-toastify';

export class StoreContractors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      items: [],
      contractors: [],
      selectedContractors: '',
      loading: true,
      isEdit: false,
      countDelete: 0,
      countInsert: 0,
      query: {
        search: '',
        limit: 10,
        page: 1,
        totalPages: 1,
        totalItems: 1
      },
    };
  }

  async componentWillMount() {
    try {
      if (!isAllow('VIEW_AND_EDIT_CONTRACTOR_TEAM')) {
        return;
      };
      const store = authStore.getStore();
      this.getContractorsByStoreId()
      this.getAvailableContractors()

    } catch (error) {
      throw error;
    }
  }

  async clickSubmit() {
    let confirmDialogOptions = {
      title: 'ยืนยันการบันทึกเลือกทีมช่าง',
      text: `คุณมีการเพิ่มทีมช่างทั้งหมด ${this.state.selectedContractors.split(',').length} ทีม ต้องการยืนยันการบันทึกรายการหรือไม่`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          this.saveContractor();
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };


    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
    }
  }


  async saveContractor() {
    this.setState({ loading: true })
    try {
      let store = authStore.getStore();
      await this.props.contractor.updateStoreContractors(store._id, this.state.selectedContractors.split(','));
      await this.getContractorsByStoreId()
      await this.getAvailableContractors()

      toast.success('แก้ไขข้อมูลเรียบร้อย');
      this.resetSelection()

    } catch (error) {
      toast.error(error.message);
    } finally {
      this.setState({ loading: false })
    }
  }

  async removeContractor(i) {
    const confirmDialogOptions = {
      title: 'ยืนยันการลบทีมช่าง',
      text: `คุณต้องการลบทีมช่าง '${this.state.items[i].team_name}' หรือไม่?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
    };

    // this.setState({ loading: true })

    try {
      let store = authStore.getStore();
      const result = await swal.fire(confirmDialogOptions)
      if (result.dismiss) throw new Error('dismiss')
      await this.props.contractor.removeStoreContractorById(store._id, this.state.items[i]._id);
      await this.getContractorsByStoreId()
      await this.getAvailableContractors()
      swal.fire({
        title: 'แก้ไขข้อมูลเรียบร้อย',
        icon: 'success',
      });

      toast.success('แก้ไขข้อมูลเรียบร้อย');
    } catch (e) {
      if (e.message !== 'dismiss') toast.error(e.message)
    } finally {
      this.setState({ loading: false })
    }
  }

  validation() {
    let check = this.state.items.map(d => {
      return !(_.isEmpty(d['_id']));
    });
    let isValid = _.indexOf(check, false);
    return isValid < 0 && this.state.isEdit;
  }

  async getAvailableContractors() {
    try {
      const store = authStore.getStore();
      const items = await this.props.contractor.getAllStoreContractor(store._id);
      this.setState({ contractors: items.map((item) => ({ label: item.team_name, value: item._id })) })
    } catch (e) {
      console.error(e)
    }
  }

  async getContractorsByStoreId() {
    this.setState({ loading: true })
    try {
      const store = authStore.getStore();
      const { items, limit, page, totalPages, totalItems } = await this.props.admin.getStoreByIdForValidate(store._id, this.state.query);
      this.setState({
        items: items,
        query: {
          ...this.state.query,
          limit,
          page,
          totalPages,
          totalItems
        }
      })
    } finally {
      this.setState({ loading: false })
    }
  }

  resetSelection() {
    this.setState({
      selectedContractors: ''
    })
  }

  onUpdateSearch = (search) => {
    this.setState({
      search: search
    })
  }

  onUpdateQuery = (key, value) => {
    const query = this.state.query
    _.set(query, key, value)
    this.setState(query)
  }

  onClickSearch = () => {
    this.onUpdateQuery('search', this.state.search)
    this.onUpdateQuery('page', 1)
    this.getContractorsByStoreId()
  }

  onSetPage = (page) => {
    this.onUpdateQuery('page', page)
    this.getContractorsByStoreId()
  }

  onUpdateSelectedContractors = (items) => {
    this.setState({
      selectedContractors: items
    })
  }

  genTableRows() {
    return this.state.items.map((item, i) => {
      return (
        <tr key={item._id}>
          <td>{item.team_name}</td>
          <td>
            <EnButton className="btn-danger btn-xs" onClick={() => this.removeContractor(i)}>
              <i className="fa fa-trash" aria-hidden="true" />
            </EnButton>
          </td>
        </tr>
      );
    })
  }

  render() {
    const rows = this.genTableRows()

    return (
      <Container isAdmin>
        <Loader show={this.state.loading} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">เลือกทีมช่าง</h4>
                <p className="category">กรุณาเลือกทีมช่างที่ท่านต้องการใช้บริการ</p>
              </div>
              <div className="card-content">
                <div className="row">
                  <FormGroup label="ค้นหาทีมช่าง:" containerClassName="col-md-6">
                    <div className='form-group has-feedback has-clear' style={{ margin: 0 }}>
                      <div className='input-group'>
                        <EnText className="form-control" placeholder="ค้นหาด้วยชื่อทีมช่าง" value={this.state.search} onChange={(e) => this.onUpdateSearch(e.target.value)} onKeyPress={(e) => { if (e.key === 'Enter') this.onClickSearch() }} />
                        <span className='input-group-btn'>
                          <button id="search-button" type="button" className="btn btn-primary btn-outline-secondary btn-custom" onClick={() => { this.onClickSearch() }}>
                            <i className='fa fa-search' aria-hidden="true" />
                          </button>
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup label="เลือกทีมช่างเข้าร้านค้า:" containerClassName="col-md-6">
                    <div className='form-group has-feedback has-clear' style={{ margin: 0 }}>
                      <div className='input-group' style={{ display: 'flex', margin: 0 }}>
                        <EnTagSelect multi={true} options={this.state.contractors} placeholder="ค้นหาด้วยชื่อทีมช่าง" className="coupon-search-select" value={this.state.selectedContractors} style={"width:100%"} onTagChange={(items) => { this.onUpdateSelectedContractors(items) }} searchable closeOnSelect />
                      </div>
                    </div>
                  </FormGroup>
                </div>
                <div className="row" style={{ "margin-bottom": '2.25rem' }}>
                  <div className="col-md-12" style={{ display: 'flex' }}>
                    <EnButton className="btn-danger" style={{ margin: 0, 'margin-left': 'auto' }} disabled={!this.state.selectedContractors.length} onClick={() => this.resetSelection()}>
                      ยกเลิก
                    </EnButton>
                    <EnButton className="btn-info" style={{ margin: 0, 'margin-left': '1.25rem' }} disabled={!this.state.selectedContractors.length} onClick={() => this.clickSubmit()}>
                      <i className="fa fa-save btn-icon" aria-hidden="true" />
                      บันทึก
                    </EnButton>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th className='text-center'>ทีมช่าง</th>
                            <th width="1%" className='text-center'>ลบช่าง</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <SimplePagination pagination={this.state.query} onSetPage={this.onSetPage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('admin', 'calendar', 'contractor', 'auth')(observer(StoreContractors));

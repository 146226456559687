import _ from 'lodash';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import queryString from 'query-string';
import Loader from '../../components/common/Loader';
import Container from '../../layouts/Container';
import common from '../../utils/common';
import datetime from '../../utils/datetime';
import JobRequest from './JobRequest';
import config from '../../config';
import authStore from '../../stores/AuthStore';
import { toast } from 'react-toastify';

export class AdminAsaJobRequest extends Component {
  constructor(props) {
    super(props);
    const storeId = authStore.getStoreId();
    const parsed = queryString.parse(this.props.location.search);
    this.state = {
      criteria: undefined,
      contractors: undefined,
      unavailableDate: undefined,
      storeId,
      jobType: parsed && parsed.job_type ? parsed.job_type : undefined,
      workTypeId: parsed && parsed.work_type_id ? parsed.work_type_id : undefined,
    };
  }

  async componentWillMount() {
    let defaultSelectedTypeOfWork = 0;
    try {
      await this.props.typeofwork.getTypeOfWorksByStoreId(this.state.storeId);
      const { items } = this.props.typeofwork.toJS();
      if (Array.isArray(items) && items.length) {
        defaultSelectedTypeOfWork = items[0].value;
      }
      const parsed = queryString.parse(this.props.location.search);
      // console.log('parsed >>>>>>>>', parsed);
      // this.props.job.clearInfo();
      this.props.job.saveMultiInfo({
        jobType: parsed.job_type && parsed.job_type === 'A' ? 'S' : parsed.job_type,
        typeOfWork: [+(parsed.work_type_id || defaultSelectedTypeOfWork)],
        contractorId: parsed.contractor_id || '',
        startDate: parsed.start ? moment(parsed.start, 'YYYYMMDD') : moment(),
        endDate: parsed.end ? moment(parsed.end, 'YYYYMMDD') : moment(),
        startedAt: moment(),
      });
      // console.log('this.props.job.toJS().info >>>>>>>>', this.props.job.toJS().info);
    } catch (error) {
      toast.error(error.message || 'เกิดข้อผิดพลาด');
    }
  }

  checkDayBlock(date) {
    let { unavailableDate, contractors, criteria } = this.state;
    if (criteria && criteria.contractorId) {
      const contractor = contractors.find(c => c._id === criteria.contractorId);
      if (contractor) {
        unavailableDate = common.unavailableDate([contractor], contractor.unavailable_date);
      }
    }

    if (Array.isArray(unavailableDate) && unavailableDate.length) {
      const dateBlock = unavailableDate.find(u => datetime.isEqual(date, u));
      return !!dateBlock;
    } else {
      return false;
    }
  }

  validateJobInfo() {
    const options = { createMode: true };
    return this.props.job.validateJobInfo(options);
  }

  onCancel() {
    const jobInfo = this.props.job.toJS().info;
    const calendarFilter = this.props.calendar.toJS().calendarFilter;
    let qs;
    if (calendarFilter) {
      qs = calendarFilter;
    } else {
      qs = {
        workTypeId: jobInfo.typeOfWork[0],
        jobType: jobInfo.jobType,
        contractorId: jobInfo.contractorId,
        date: moment(jobInfo.startDate || jobInfo.endDate).format('YYYY-MM'),
      };
    }
    // this.props.job.clearInfo();
    this.props.history.push(`${config.publicUrl}/admin/calendar?${common.urlBuilder(qs)}`);
  }

  async onSubmit() {
    try {
      await this.props.job.createJob();
      toast.success('สร้างตารางนัดหมายช่างเรียบร้อย');
      setTimeout(() => {
        this.onCancel();
      }, 1000);
    } catch (error) {
      toast.error(error.message || 'เกิดข้อผิดพลาด');
    }
  }

  async onSkuChange(criteria) {
    
    try {
      if (!_.isEqual(this.state.criteria, criteria)) {
        if (this.state.criteria && this.state.criteria.workTypeId !== criteria.workTypeId) {
          const data = await this.props.sku.getSkuByType(criteria.workTypeId);
          const sku = data.toJS();
          if (sku && sku.length) {
            this.props.job.setInfo('sku', sku[0].code);
          } else {
            this.props.job.setInfo('sku', '');
          }
        }
        const store = authStore.getStore();
        if (store.po_require && criteria.contractorId && criteria.skuCode) {
          const team = (this.state.contractors || []).filter(c => c._id === criteria.contractorId);
          if (team && team.length && team[0].cost && team[0].cost.length) {
            const cost = team[0].cost.filter(c => c.store_id === store._id).map(d => {
              return d.items;
            });

            if (cost[0] && cost[0].length) {
              const surveyCost = cost[0].filter(s => s.code === criteria.skuCode).find(result => result.survey_cost);
              const installationCost = cost[0].filter(s => s.code === criteria.skuCode).find(result => result.wage);
              if ((surveyCost && surveyCost.survey_cost) || (installationCost && installationCost.wage)) {
                this.props.job.setInfo('cost', {
                  survey: (surveyCost && surveyCost.survey_cost && criteria.jobType === 'S') ? surveyCost.survey_cost : '',
                  installation: (installationCost && installationCost.wage && criteria.jobType === 'I') ? installationCost.wage : '',
                });
              } else {
                this.props.job.setInfo('cost', {
                  survey: '',
                  installation: '',
                });
              }
            }
          }
        }
      }
    } catch (error) {
      this.setState({
        criteria,
        contractors: [],
        unavailableDate: [],
      });
    }
  }

  async onCriteriaChange(criteria) {
    try {
      if (!_.isEqual(this.state.criteria, criteria)) {
        if (this.state.criteria && this.state.criteria.workTypeId !== criteria.workTypeId) {
          const data = await this.props.sku.getSkuByType(criteria.workTypeId);
          const sku = data.toJS();
          if (sku && sku.length) {
            this.props.job.setInfo('sku', sku[0].code);
          } else {
            this.props.job.setInfo('sku', '');
          }
        }
        const store = authStore.getStore();
        let contractorId = (criteria.contractorId) ? criteria.contractorId : '';
        criteria.contractorId = '';
        criteria.jobType = this.state.jobType;
        
        const result = await this.props.job.getDataForAdminJobRequest(criteria);
        criteria.contractorId = contractorId;
        this.setState({
          criteria,
          contractors: result.contractors,
          unavailableDate: result.unavailableDate || [],
        });

        if (store.po_require && criteria.contractorId && criteria.skuCode) {
          const team = (this.state.contractors || []).filter(c => c._id === criteria.contractorId);
          if (team && team.length && team[0].cost && team[0].cost.length) {
            const cost = team[0].cost.filter(c => c.store_id === store._id).map(d => {
              return d.items;
            });

            if (cost[0] && cost[0].length) {
              const surveyCost = cost[0].filter(s => s.code === criteria.skuCode).find(result => result.survey_cost);
              const installationCost = cost[0].filter(s => s.code === criteria.skuCode).find(result => result.wage);
              if ((surveyCost && surveyCost.survey_cost) || (installationCost && installationCost.wage)) {
                this.props.job.setInfo('cost', {
                  survey: (surveyCost && surveyCost.survey_cost && criteria.jobType === 'S') ? surveyCost.survey_cost : '',
                  installation: (installationCost && installationCost.wage && criteria.jobType === 'I') ? installationCost.wage : '',
                });
              } else {
                this.props.job.setInfo('cost', {
                  survey: '',
                  installation: '',
                });
              }
            }
          }
        }
      }
    } catch (error) {
      this.setState({
        criteria,
        contractors: [],
        unavailableDate: [],
      });
    }
  }

  goToManageCost(id) {
    this.props.job.setInfo('contractorId', '');
    this.setState({ criteria: undefined });
    document.getElementById('contractor').focus();
    window.open(`${config.publicUrl}/admin/contractor/cost/${id}`, '_blank');
  }

  render() {
    const jobStore = this.props.job.toJS();
    const exportdata = this.props.exportdata.toJS();
    return (
      <Container isAdmin>
        <Loader show={jobStore.isSubmitting || jobStore.isLoading || exportdata.isLoading} />
        <div className="row">
          <div className="card">
            <div className="card-header" data-background-color="orange">
              <h4 className="title">ปฏิทิน</h4>
              <p className="category">รายละเอียดการใช้บริการ</p>
            </div>
            <div className="card-content">
              <div className="card-content">
                <JobRequest
                  isCreated
                  showQuestionnaire
                  contractors={this.state.contractors}
                  unavailableDate={this.state.unavailableDate}
                  validation={this.validateJobInfo()}
                  checkDayBlock={this.checkDayBlock.bind(this)}
                  onSubmit={this.onSubmit.bind(this)}
                  onCancel={this.onCancel.bind(this)}
                  onCriteriaChange={this.onCriteriaChange.bind(this)}
                  onSkuChange={this.onSkuChange.bind(this)}
                  goToManageCost={this.goToManageCost.bind(this)}
                  workTypeId={this.state.workTypeId}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('job', 'calendar', 'exportdata', 'typeofwork', 'sku')(observer(AdminAsaJobRequest));

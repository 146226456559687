import React, { Component } from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Select from 'react-select';
import swal from 'sweetalert2';

import EnTagSelect from '../../../../components/form/EnTagSelect';
import EnDatePicker from '../../../../components/form/EnDatePicker';
import EnButton from '../../../../components/form/EnButton';
import Loader from '../../../../components/common/Loader';
import AuthStore from '../../../../stores/AuthStore';
import { JobStatuses } from '../../../../utils/data';
import { isAllow } from '../../../../utils/permission';

export class JobSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChannelLoading: false,
      isTypeOfWorkLoading: false,
      isContractorLoading: false,
      channelOptions: [],
      typeOfWorkOptions: [],
      contractorOptions: [],
    };
    this.saleModelOptions = [
      { label: 'MarketPlace', value: 'MarketPlace' },
      { label: 'Retail', value: 'Retail' },
      { label: 'Claim', value: 'Claim' },
    ];
    this.typeOfJobOptions = [
      { label: 'สำรวจ', value: 'S' },
      { label: 'ติดตั้ง', value: 'I' },
    ];
    this.statuses = JobStatuses.map((status) => {
      return {
        label: status.name,
        value: status.value,
      };
    });
  }

  componentDidMount() {
    this.initOptions();
  }

  async initOptions() {
    this.getChannelOptions();
    this.getTypeOfWorkOptions();
    this.getContractorOptions();
  }

  async getChannelOptions() {
    let channelOptions = [];
    try {
      this.setState({
        isChannelLoading: true,
      });
      const isSuperAdmin = AuthStore.isSuperAdmin();
      if (isSuperAdmin) {
        await this.props.channel.getAllChannel();

        let channel = this.props.channel.toJS().info || [];
        channelOptions = channel.map((item) => {
          return {
            label: item.channel_name,
            value: item.channel_name,
          };
        });
      }
    } catch (err) {
      console.log(err);
      channelOptions = [];
    }

    this.setState({
      channelOptions,
      isChannelLoading: false,
    });
  }

  async getTypeOfWorkOptions() {
    let typeOfWorkOptions = [];
    try {
      this.setState({
        isTypeOfWorkLoading: true,
      });
      const { storeId } = this.props;
      if (storeId) {
        await this.props.typeofwork.getTypeOfWorksByStoreId(storeId);
      } else {
        await this.props.typeofwork.getTypeOfWorks({ visible: true });
      }
      const items = this.props.typeofwork.toJS().items;
      typeOfWorkOptions = _.orderBy(items, ['name']).map((tow) => {
        return {
          label: tow.name,
          value: tow.value,
        };
      });
    } catch (err) {
      console.log(err);
      typeOfWorkOptions = [];
    }
    this.setState({
      typeOfWorkOptions,
      isTypeOfWorkLoading: false,
    });
  }

  async getContractorOptions() {
    let contractorOptions = [];
    try {
      this.setState({
        isContractorLoading: true,
      });
      let list = await this.props.contractor.getAll({ filterByArea: false });
      if ((list || []).length > 0) {
        list = list.filter((item) => item.team_name);
        contractorOptions = _.orderBy(list, ['team_name']).map((item) => {
          return {
            label: item.team_name,
            value: item._id,
          };
        });
      }
    } catch (err) {
      console.log(err);
      contractorOptions = [];
    }

    this.setState({
      contractorOptions,
      isContractorLoading: false,
    });
  }

  onQueryChange(key, value) {
    this.props.download.setQuery(key, value);
  }

  onDateChange(key, date) {
    let errorMessage = ''
    // validate input date
    if (key === 'start_date') {
      const { query } = this.props.download.toJS();
      if (!this.checkIsValidDateRange(date, query.end_date)) {
        errorMessage = 'กรุณาตรวจสอบวันที่';
      }
    } else if (key === 'end_date') {
      const { query } = this.props.download.toJS();
      if (!this.checkIsValidDateRange(query.start_date, date)) {
        errorMessage = 'กรุณาตรวจสอบวันที่';
      }
    } else if (key === 'create_start_date') {
      const { query } = this.props.download.toJS();
      if (!this.checkIsValidDateRange(date, query.create_end_date)) {
        errorMessage = 'กรุณาตรวจสอบวันที่';
      }
    } else if (key === 'create_end_date') {
      const { query } = this.props.download.toJS();
      if (!this.checkIsValidDateRange(query.create_start_date, date)) {
        errorMessage = 'กรุณาตรวจสอบวันที่';
      }
    }
    // display error message
    if (errorMessage === '') {
      this.props.download.setQuery(key, date.toDate());
    } else {
      swal.fire({
        icon: 'error',
        title: errorMessage,
        confirmButtonText: 'ตกลง',
      });
    }
  }

  onSubmit(query) {
    query.channel = this.props.channelName;
    query.store_id = this.props.storeId;
    this.props.onSubmit(query);
  }

  checkIsValidDateRange(fromDate, toDate) {
    if (fromDate && toDate) {
      return moment(fromDate).valueOf() <= moment(toDate).valueOf();
    }
    return true;
  }

  isInputSomeField(fields) {
    return (
      fields.channel_list ||
      fields.contractor_id_list ||
      fields.sale_model_list ||
      fields.status_list ||
      fields.type_of_job ||
      fields.type_of_work_list ||
      (fields.start_date && fields.end_date && fields.create_start_date && fields.create_end_date) ||
      (fields.start_date && fields.end_date && !fields.create_start_date && !fields.create_end_date) ||
      (!fields.start_date && !fields.end_date && fields.create_start_date && fields.create_end_date)
    )
  }

  render() {
    const { channelName, disabledDownloadBtn } = this.props;
    const { isChannelLoading, isTypeOfWorkLoading, isContractorLoading } = this.state;
    const { channelOptions, typeOfWorkOptions, contractorOptions } = this.state;
    const { query } = this.props.download.toJS();
    const isLoading = isChannelLoading || isTypeOfWorkLoading;

    const isDisabledDownload = disabledDownloadBtn || !this.isInputSomeField(query);

    return (
      <div style={{ marginTop: '15px' }}>
        <Loader show={isLoading} />
        <div className="row">
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">ช่องทาง</label>
            <div className="col-md-9">
              {channelName ? (
                <div>{channelName || ''}</div>
              ) : (
                <EnTagSelect
                  multi={true}
                  disabled={isChannelLoading}
                  closeOnSelect
                  options={channelOptions}
                  onTagChange={(selectedItem) => {
                    this.onQueryChange('channel_list', selectedItem);
                  }}
                  value={query.channel_list}
                  searchable
                  className="coupon-search-select"
                  placeholder={isChannelLoading ? 'กำลังโหลด...' : 'เลือก...'}
                />
              )}
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">Model การขาย</label>
            <div className="col-md-9">
              <EnTagSelect
                multi={true}
                closeOnSelect
                options={this.saleModelOptions}
                onTagChange={(selectedItem) => {
                  this.onQueryChange('sale_model_list', selectedItem);
                }}
                value={query.sale_model_list}
                searchable
                className="coupon-search-select"
                placeholder="เลือก..."
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">ลักษณะงาน</label>
            <div className="col-md-9">
              <Select
                multi={false}
                value={this.typeOfJobOptions.find((item) => item.value === query.type_of_job)}
                options={this.typeOfJobOptions}
                onChange={(selectedItem) => {
                  this.onQueryChange('type_of_job', (selectedItem || {}).value || null);
                }}
                placeholder="เลือก..."
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">ประเภทงาน</label>
            <div className="col-md-9">
              <EnTagSelect
                multi={true}
                disabled={isTypeOfWorkLoading}
                closeOnSelect
                options={typeOfWorkOptions}
                onTagChange={(selectedItem) => {
                  this.onQueryChange('type_of_work_list', selectedItem);
                }}
                value={query.type_of_work_list}
                searchable
                className="coupon-search-select"
                placeholder={isTypeOfWorkLoading ? 'กำลังโหลด...' : 'เลือก...'}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">ทีมช่าง</label>
            <div className="col-md-9">
              <EnTagSelect
                multi={true}
                disabled={isContractorLoading}
                closeOnSelect
                options={contractorOptions}
                onTagChange={(selectedItem) => {
                  this.onQueryChange('contractor_id_list', selectedItem);
                }}
                value={query.contractor_id_list}
                searchable
                className="coupon-search-select"
                placeholder={isContractorLoading ? 'กำลังโหลด...' : 'เลือก...'}
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">สถานะ</label>
            <div className="col-md-9">
              <EnTagSelect
                multi={true}
                closeOnSelect
                options={this.statuses}
                onTagChange={(selectedItem) => {
                  this.onQueryChange('status_list', selectedItem);
                }}
                value={query.status_list}
                searchable
                className="coupon-search-select"
                placeholder="เลือก..."
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">วันที่ติดตั้ง<br />(จาก-ถึง):</label>
            <div style={{ marginTop: 5 }} className="col-md-4">
              <EnDatePicker id="startDate" initialDate={query.start_date ? moment(query.start_date) : undefined} onDateChange={this.onDateChange.bind(this, 'start_date')} isOutsideRange={(day) => query.end_date ? day.isBefore(moment(query.end_date).subtract(1, 'M')) : undefined} placeholder="เลือก.." />
            </div>
            <div style={{ padding: 0, marginTop: 10 }} className="text-center col-md-1">-</div>
            <div style={{ marginTop: 5 }} className="col-md-4">
              <EnDatePicker id="endDate" initialDate={query.end_date ? moment(query.end_date) : undefined} onDateChange={this.onDateChange.bind(this, 'end_date')} isOutsideRange={(day) => query.start_date ? day.isAfter(moment(query.start_date).add(1, 'M')) : undefined} placeholder="เลือก.." />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">วันที่สร้างงาน<br />(จาก-ถึง):</label>
            <div style={{ marginTop: 5 }} className="col-md-4">
              <EnDatePicker id="createStartDate" initialDate={query.create_start_date ? moment(query.create_start_date) : undefined} onDateChange={this.onDateChange.bind(this, 'create_start_date')} isOutsideRange={(day) => query.create_end_date ? day.isBefore(moment(query.create_end_date).subtract(1, 'M')) : undefined} placeholder="เลือก.." />
            </div>
            <div style={{ padding: 0, marginTop: 10 }} className="text-center col-md-1">-</div>
            <div style={{ marginTop: 5 }} className="col-md-4">
              <EnDatePicker id="createEndDate" initialDate={query.create_end_date ? moment(query.create_end_date) : undefined} onDateChange={this.onDateChange.bind(this, 'create_end_date')} isOutsideRange={(day) => query.create_start_date ? day.isAfter(moment(query.create_start_date).add(1, 'M')) : undefined} placeholder="เลือก.." />
            </div>
          </div>
          <div className="form-group col-md-6" style={{ textAlign: 'right' }}>
            <button
              className="btn btn-success"
              title="ดาวน์โหลด"
              data-tip="ดาวน์โหลด"
              onClick={this.onSubmit.bind(this, query)}
              disabled={isDisabledDownload || (
                this.props.downloadType == 'job' && !AuthStore.isAdminQChang() && !AuthStore.isSuperAdmin()
              )}
            // disabled={isDisabledDownload || !isAllow('DOWNLOAD_CONTRACTORS_SCHEDULES')}
            // disabled={!isAllow('VIEW_AND_DOWNLOAD_LIST_OF_Q_CHANG_FILES')}
            >
              <span className="fa fa-plus btn-icon" aria-hidden="true" />
              ดาวน์โหลด
            </button>
            <EnButton className="btn-warning" onClick={this.props.onClearSearch.bind(this)}>
              <i className="fa fa-refresh btn-icon" aria-hidden="true" />
              เคลียร์
            </EnButton>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('auth', 'channel', 'typeofwork', 'contractor', 'download')(observer(JobSearchForm));

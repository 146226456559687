import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';
import qs from 'query-string';
import { pick, pickBy } from 'lodash';

export class TypeOfWork extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      itemGroupTypeOfWork: [],
      info: this.initInfo(),
      data: this.initData(),
    });
  }

  initData() {
    return {
      label: 'เลือกช่วงเวลารับงาน',
      value: '',
      timeText: '',
      job_ratio: 0.01,
      surveyText: '',
      max_job_per_contractor_morning: 1,
      max_job_per_contractor_afternoon: 1,
      max_job_per_day_morning: 1,
      max_job_per_day_afternoon: 1,
    };
  }

  initInfo() {
    return {
      name: '',
      time: [],
      value: '',
      code_group: '',
      group_name: '',
      job_ratio: 1,
      surveys: [],
      contractor_visible: true,
      channel: '',
      max_job_per_contractor_morning: 1,
      max_job_per_contractor_afternoon: 1,
      max_job_per_day_morning: 10,
      max_job_per_day_afternoon: 10,
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  clearData() {
    this.data = this.initData();
  }

  saveName(name) {
    this.info.name = name;
  }

  saveData(key, value) {
    this.data[key] = value;
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  mapIdAndLabel(checkHideContractor) {
    const items = this.toJS().items;
    // filter contractor visible
    if (checkHideContractor) {
      return items.filter(type => type.contractor_visible).map(i => {
        return { id: i.value, label: i.name };
      });
    }
    return items.map(i => {
      return { id: i.value, label: i.name };
    });
  }

  deleteTime(index) {
    this.info.time.splice(index, 1);
  }

  deleteSurvey(index) {
    this.info.surveys.splice(index, 1);
  }

  addTime() {
    let result = {};
    if (this.data.value && this.data.timeText) {
      this.info.time.push({
        value: this.data.value.toString(),
        label: this.data.timeText,
      });
      result.status = true;
    } else {
      result.label = this.data.timeText ? '' : 'ข้อความช่วงเวลา';
      result.value = this.data.value ? '' : 'ช่วงเวลารับงาน';
      result.status = false;
    }
    return result;
  }

  addSurvey() {
    let result = {};
    if (this.data.surveyText) {
      // console.log(this.data.surveyText);
      // console.log(this.info.surveys);
      this.info.surveys.push(this.data.surveyText);
      result.status = true;
    } else {
      result.message = this.data.surveyText ? '' : 'หัวข้อที่ต้องการสำรวจ';
      result.status = false;
    }
    return result;
  }

  async getTypeOfWorks(query) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/typeofworks?${qs.stringify(query)}`);
      if (response.status === 200 && response.data) {
        this.items = response.data.data;
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getPaginatedTypeOfWorks(query = { limit: 10, page: 1 }) {
    query = pickBy(query, (value) => !!value)
    const qstring = qs.stringify(pick(query, ['limit', 'page', 'typeofwork', 'visible']))

    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/typeofworks/pagination?${qstring}`);
      if (response.status === 200 && response.data) {
        this.items = response.data.items;
        return Promise.resolve(response.data)
      }

    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getTypeOfWorkById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(
        `${config.api.sims}/v1/typeofworks/${id}`
      );
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getTypeOfWorksByValue(value) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/typeofworks/value/${value}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      this.items = [response.data.data];
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getTypeOfWorksByStoreId(storeId, isAdminCalendar = false) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/typeofworks/store/${storeId}?isAdminCalendar=${isAdminCalendar}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      this.items = response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getTypeOfWorkForEdit(id) {
    try {
      const data = await this.getTypeOfWorkById(id);
      data.group_name = data.group_name || '';
      data.surveys = data.surveys || [];
      this.info = data;
    } catch (error) {
      throw error;
    }
  }

  async getGroupTypeOfWorkFromApp() {
    const res = await http.get(`${config.qchangApp.baseUrl.contractor}/group-work-type`);
    if (res && res.data) {
      const data = res.data;
      this.itemGroupTypeOfWork = data;
      return data;
    }
  }

  async createTypeOfWork() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      await http.post(
        `${config.api.sims}/v1/typeofworks`,
        info
      );
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateTypeOfWork(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      info.max_job_per_contractor = info.max_job_per_contractor ? +info.max_job_per_contractor : undefined;
      info.max_job_per_day = info.max_job_per_day ? +info.max_job_per_day : undefined;
      info.job_ratio = info.job_ratio ? +info.job_ratio : undefined;
      await http.put(
        `${config.api.sims}/v1/typeofworks/${id}`,
        info
      );
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async toggleContractorVisible(index, id, value) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.put(`${config.api.sims}/v1/typeofworks/${id}/visiblecontractor`, { contractor_visible: value });
      if (res.status === 200 && res.data) {
        // this.items[index] = res.data.data;
        this.items = this.items.map(item => {
          const data = res.data.data;
          if (item._id === id) item = data;
          return item;
        });
        return this.items;
      } else {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new TypeOfWork();

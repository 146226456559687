import * as _ from 'lodash';
import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import LiffHelper from './utils/liffHelper';
import config from './config';
import history from './utils/history';
import datetime from './utils/datetime';
import AuthStore from './stores/AuthStore';
import contractorStore from './stores/ContractorStore';
import AdminSignin from './pages/auth/SignIn';
import AdminCalendar from './pages/calendar/Admin';
// import AdminCalendarAsa from './pages/calendar/AdminAsa';
import AdminApproveContractor from './pages/admin/AdminApproveContractor';
import AdminJobRequest from './pages/calendar/AdminJobRequest';
import AdminUpdateJobRequest from './pages/calendar/AdminUpdateJobRequest';
// import AdminAsaJobRequest from './pages/calendar/AdminAsaJobRequest';
// import AdminAsaUpdateJobRequest from './pages/calendar/AdminAsaUpdateJobRequest';
import AdminStoreArea from './pages/admin/StoreArea';
import AdminExportJob from './pages/admin/ExportJob';
import AdminStoreContractors from './pages/admin/StoreContractors';
import AdminStoreManage from './pages/admin/store/StoreManage';
import AdminStoreAddForm from './pages/admin/store/StoreAddForm';
import AdminStoreEditForm from './pages/admin/store/StoreEditForm';
import AdminQuotation from './pages/admin/AdminQuotation';
import AdminInvoiceForm from './pages/admin/InvoiceForm';
import AdminPoForm from './pages/admin/PurchaseOrderForm';
import AdminGuaranteeForm from './pages/admin/GuaranteeFormV2';
import AdminContractorReceiptForm from './pages/admin/ContractorReceiptForm';
import AdminCreditSaleForm from './pages/admin/CreditSaleForm';
import JoExpense from './pages/calendar/JoExpense';
import ClaimForm from './pages/admin/ClaimForm';
import CreditProviderForm from './pages/admin/CreditProviderForm';
import AdminSearchJobV2 from './pages/admin/SearchJobsV2';
import AdminSearchUser from './pages/admin/SearchUser'
import AdminInstallationRequest from './pages/admin/AdminInstallationRequest';
import ManageSku from './pages/admin/sku/ManageSku';
import UploadeSku from './pages/admin/sku/UploadeSku';
import SkuPage from './pages/admin/sku/SkuPage';
import ManageMaterial from './pages/admin/material/ManageMaterial';
import UploadMaterial from './pages/admin/material/UploadMaterial';
import MaterialPage from './pages/admin/material/MaterialPage';
import ManageChannel from './pages/admin/channel/manageChannel';
import ChannelPage from './pages/admin/channel/channelPage';
import TypeOfWork from './pages/admin/TypeOfWork';
import TypeOfWorkForm from './pages/admin/TypeOfWorkForm';
import OrderSummary from './pages/admin/OrderSummary';
import OrderSummarySCB from './pages/admin/OrderSummarySCB';
import AdminDownloadJob from './pages/admin/download/manageDownload';
import AdminDownloadContractor from './pages/admin/download/contractor';
import AdminUploade from './pages/admin/uploade/manageUploade';
import AdminUploadContractorGrade from './pages/admin/AdminUploadContractorGrade';
import QocBroadcastListPage from './pages/qocBroadcast/BroadcastListPage';
import BroadcastListPage from './pages/broadcast/BroadcastListPage';
import SuperAdminUpdateJobRequest from './pages/calendar/SuperAdminUpdateJobRequest';
import ManageProduct from './pages/qchang/products/ManageProduct';
import ProductPage from './pages/qchang/products/ProductPage';
import ManageProductGroup from './pages/qchang/productgroup/ManageGroupProduct';
import ProductGroupPage from './pages/qchang/productgroup/ProductGroupPage';
import ManageSolution from './pages/qchang/solutions/ManageSolution';
import SolutionPage from './pages/qchang/solutions/SolutionPage';
import ManageCoupon from './pages/qchang/coupons/ManageCoupon';
import CouponPage from './pages/qchang/coupons/CouponPage';
import ManageCouponGroup from './pages/qchang/coupons/ManageCouponGroup';
import CouponGroupPage from './pages/qchang/coupons/CouponGroupPage';
import SetBannerPage from './pages/qchang/set-banner/SetBannerPage';
import UploadProduct from './pages/qchang/products/UploadProduct';
import SetupCalendar from './pages/admin/calendar/setupCalendar'
import AdditionalProductsPage from './pages/admin/AdditionalProducts';
import AdditionalProductsErrorsPage from './pages/admin/AdditionalProductsErrors';

import AddReview from './pages/qchang/reviews/AddReview';
import ManageProject from './pages/qchang/projects/ManageProject';
import ProjectPage from './pages/qchang/projects/ProjectPage';
import ProjectConfigPage from './pages/qchang/projects/ProjectConfigPage';
import ManageGuarantee from './pages/qchang/guarantee/ManageGuarantee';
import GuaranteePage from './pages/qchang/guarantee/GuaranteePage';

import ContractorSignIn from './pages/contractors/SignIn';
import ContractorRegisterInfo from './pages/contractors/register/Info';
import ContractorLandingPage from './pages/contractors/landing';
import ContractorRegisterLocation from './pages/contractors/register/Location';
import ContractorRegisterWorkArea from './pages/contractors/register/WorkArea';
import ContractorRegisterPin from './pages/contractors/register/Pin';
import ContractorRegisterProfilePicture from './pages/contractors/register/ProfilePicture';
import ContractorProfile from './pages/contractors/profile/Profile';
import ContractorLocation from './pages/contractors/profile/Location';
import ContractorTaxAddressV2 from './pages/contractors/profile/TaxAddressV2';
import ContractorWorkArea from './pages/contractors/profile/WorkArea';
import ContractorChangePin from './pages/contractors/profile/ChangePin';
import ContractorAgreement from './pages/contractors/profile/Agreement';
import ContractorProfilePicture from './pages/contractors/profile/Profilepicture';
import ContractorGrade from './pages/contractors/profile/Grade';
import ContractorCalendar from './pages/calendar/Contractor';
import ContractorVacation from './pages/calendar/ContractorVacation';
import ContractorJob from './pages/calendar/ContractorJob';
import ContractorJobList from './pages/contractors/ContractorJobList';
import ContractorJobRequest from './pages/calendar/ContractorJobRequest';
import ContractorEvent from './pages/calendar/ContractorEvent';
import ContractorRegisterTypeOfWork from './pages/contractors/register/TypeOfWork';
import ContractorTypeOfWork from './pages/contractors/profile/TypeOfWork';
import ContractorTransferSummary from './pages/contractors/transfer/TransferSummary';
import ContractorTransferDetail from './pages/contractors/transfer/TransferDetail';

// import ExportContractor from './pages/export/ExportContractor';
// import ExportContractorVacation from './pages/export/ExportContractorVacation';
// import ExportJob from './pages/export/ExportJob';
import AdminExport from './pages/admin/Export';
import JobAcceptance from './pages/homeowner/JobAcceptance';
import JobAcceptanceResult from './pages/homeowner/JobAcceptanceResult';
import AdminContractorCost from './pages/admin/cost/ContractorsCost';
import AdminManageContractorCost from './pages/admin/cost/ManageCost';
import JobList from './pages/admin/JobList';
import JobNote from './pages/admin/JobNote';
import ContractorList from './pages/admin/ContractorList';
import ManageTrainingCourse from './pages/qchang/training/ManageTrainingCourse';
import ManageTrainingRegister from './pages/qchang/training/ManageTrainingRegister';
import PinConfirm from './pages/qchang/training/PinConfirm';
import NpsPage from './pages/nps/NpsPage';
import NpsThankyouPage from './pages/nps/NpsThankyouPage';
import ManageEmployeePage from './pages/employee/ManageEmployeePage';
import EmployeePage from './pages/employee/EmployeePage';
import PaymentSummaryPage from './pages/payment/PaymentSummary'

import ManageMainCourse from './pages/qchang/training/ManageMainCourse'
import CreateSubCourse from './pages/qchang/training/CreateSubCourse'
import ManageMainCourseOld from './pages/qchang/training/ManageTrainingCourseOld'

import JobsTracking from './pages/buaccount/JobsTracking'
import ManageQocBroadcastPage from './pages/qocBroadcast/ManageBroadcastPage';
import ManageBroadcastPage from './pages/broadcast/ManageBroadcastPage';

import SubContractorPage from './pages/subContractor';
import SubContractorEditPage from './pages/subContractor/edit';

import WarrantyListPage from './pages/qchang/warranty/WarrantyListPage';
import WarrantyCreatePage from './pages/qchang/warranty/WarrantyCreatePage';
import WarrantyViewPage from './pages/qchang/warranty/WarrantyViewPage';
import WarrantyEditPage from './pages/qchang/warranty/WarrantyEditPage';

import BenefitPage from './pages/benefit';
import BenefitEditPage from './pages/benefit/edit';

const ContractorAuthRoute = ({ component: Component, ...routeProps }) => (
  <Route
    {...routeProps}
    render={(props) =>
      AuthStore.checkContractorSignedIn(props.match.params.id)
        ? (<Component {...props} />)
        : (<Redirect to={`${config.publicUrl}/contractors/signin`} />)
    }
  />
);

const ContractorRegisterAuthRoute = ({ component: Component, ...routeProps }) => {
  let url = '';
  let contractor = AuthStore.getContractor();
  if (contractor && contractor._id) {
    url = `${config.publicUrl}/calendar/contractor/${contractor._id}`;
  } else if (routeProps.registerStep) {
    if (!contractorStore.isValidRegisterInfo(routeProps.registerStep)) {
      url = `${config.publicUrl}/contractors/register`;
    }
  }

  return (
    <Route
      {...routeProps}
      render={(props) => url ? (<Redirect to={url} />) : (<Component {...props} />)}
    />
  );
};

const AdminAuthRoute = ({ component: Component, ...routeProps }) => {
  const isAdmin = AuthStore.checkAdminSignedIn();
  const isSuperAdmin = AuthStore.isSuperAdmin();
  const isAdminBU = AuthStore.isAdminBU();
  const isBuAccount = AuthStore.isBuAccount();
  const defaultPath = [`${config.publicUrl}`, `${config.publicUrl}/`, `${config.publicUrl}/signin`, `${config.publicUrl}/admin`];

  // if is super admin
  if (isSuperAdmin) {
    if (defaultPath.indexOf(routeProps.path) > 0) {
      return (<Route {...routeProps} render={props => (<Redirect to={`${config.publicUrl}/superadmin/jobs`} />)} />);
    } else {
      return (<Route {...routeProps} render={props => (<Component {...props} />)} />);
    }
  } else if (isBuAccount) {
    if (defaultPath.indexOf(routeProps.path) > 0) {
      return (<Route {...routeProps} render={props => (<Redirect to={`${config.publicUrl}/buaccount/jobTracking`} />)} />);
    } else {
      return (<Route {...routeProps} render={props => (<Component {...props} />)} />);
    }
  } else if (isAdmin) { // if is admin
    if (defaultPath.indexOf(routeProps.path) > 0) {
      return (<Route {...routeProps} render={props => (<Redirect to={`${config.publicUrl}/admin/calendar`} />)} />);
    } else {
      return (<Route {...routeProps} render={props => (<Component {...props} />)} />);
    }
  } else if (isAdminBU) { // if is admin bu
    if (defaultPath.indexOf(routeProps.path) > 0) {
      return (<Route {...routeProps} render={props => (<Redirect to={`${config.publicUrl}/admin/jobs/export`} />)} />);
    } else {
      return (<Route {...routeProps} render={props => (<Component {...props} />)} />);
    }
  } else { // if not signin
    if (routeProps.path === `${config.publicUrl}/signin`) {
      return (<Route {...routeProps} render={props => (<Component {...props} />)} />);
    } else {
      return (<Route {...routeProps} render={props => (<Redirect to={`${config.publicUrl}/signin`} />)} />);
    }
  }
};

const RedirectRoute = ({ component: Component, ...routeProps }) => {
  let { to } = routeProps;
  if (routeProps.computedMatch && !_.isNil(routeProps.computedMatch.params) && !_.isEmpty(routeProps.computedMatch.params)) {
    const { params } = routeProps.computedMatch;
    const keys = Object.keys(params);
    _.forEach(keys, key => {
      to = to.replace(`:${key}`, params[key]);
    });
  }

  return <Route {...routeProps} render={props => (<Redirect to={to} />)} />;
};

const ClearLocalStorage = ({ component: Component, ...routeProps }) => {
  const contractor = AuthStore.getContractor();
  let url = `${config.publicUrl}/signin`;
  if (!_.isNil(contractor) && !_.isEmpty(contractor)) {
    url = `${config.publicUrl}/contractors/signin`;
  }

  AuthStore.clearToken();

  return <Route {...routeProps} render={props => (<Redirect to={url} />)} />;
};

class App extends Component {
  constructor(props) {
    super(props);

    datetime.registerRegion();
  }

  componentWillMount() {
    if (LiffHelper.isInAppBrowser()) {
      const sepString = window.location.search.indexOf('?') > 0 ? '&' : '?';
      const url = `${window.location.origin}${window.location.pathname}${sepString}openExternalBrowser=1`;
      window.location.replace(url);
    }
  }

  render() {
    return (
      <div className="wrapper">
        <Router history={history} basename={config.publicUrl}>
          <Switch>
            {/* for Contractor */}
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/signin`} component={ContractorSignIn} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/register/profilepicture`} component={ContractorRegisterProfilePicture} registerStep={4} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/register/pin`} component={ContractorRegisterPin} registerStep={3} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/register/workarea`} component={ContractorRegisterWorkArea} registerStep={2} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/register/typeofwork`} component={ContractorRegisterTypeOfWork} registerStep={1} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/register`} component={ContractorRegisterInfo} registerStep={0} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/profile`} component={ContractorProfile} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/location`} component={ContractorLocation} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/typeofwork`} component={ContractorTypeOfWork} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/workarea`} component={ContractorWorkArea} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/pin`} component={ContractorChangePin} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/agreement`} component={ContractorAgreement} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/grade`} component={ContractorGrade} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/profilepicture`} component={ContractorProfilePicture} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/calendar/vacation/:vacationId`} component={ContractorVacation} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/calendar/vacation`} component={ContractorVacation} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/calendar/jobs/:jobId`} component={ContractorJob} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/calendar`} component={ContractorCalendar} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/jobs/list`} component={ContractorJobList} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/calendar/create/`} component={ContractorJobRequest} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/event`} component={ContractorEvent} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/transfer/summary`} component={ContractorTransferSummary} />
            <ContractorAuthRoute exact path={`${config.publicUrl}/contractors/:id/transfer/detail`} component={ContractorTransferDetail} />

            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/signin`} component={ContractorSignIn} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/register/profilepicture`} component={ContractorRegisterProfilePicture} registerStep={4} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/register/pin`} component={ContractorRegisterPin} registerStep={3} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/register/workarea`} component={ContractorRegisterWorkArea} registerStep={2} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/register/location`} component={ContractorRegisterLocation} registerStep={1} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/register`} component={ContractorRegisterInfo} registerStep={0} />
            <ContractorRegisterAuthRoute exact path={`${config.publicUrl}/contractors/landing`} component={ContractorLandingPage} registerStep={0} />

            {/* for Administrator */}
            <AdminAuthRoute exact path={`${config.publicUrl}/`} component={AdminCalendar} />
            <AdminAuthRoute exact path={`${config.publicUrl}/signin`} component={AdminSignin} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin`} component={AdminCalendar} />
            <AdminAuthRoute exact path={`${config.publicUrl}/signout`} component={AdminSignin} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/calendar`} component={AdminCalendar} />
            {/* <AdminAuthRoute exact path={`${config.publicUrl}/admin/calendarasa`} component={AdminCalendarAsa} /> */}
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/calendar/jobs`} component={AdminJobRequest} />
            {/* <AdminAuthRoute exact path={`${config.publicUrl}/admin/calendarasa/jobs`} component={AdminAsaJobRequest} /> */}
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/jobs/export`} component={AdminExportJob} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/jobs/list`} component={AdminSearchJobV2} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/contractors/list`} component={ContractorList} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/jobs/:jobId/note`} component={JobNote} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/jobs/:jobId/installationdate`} component={AdminInstallationRequest} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/calendar/jobs/:id`} component={AdminUpdateJobRequest} />
            {/* <AdminAuthRoute exact path={`${config.publicUrl}/admin/calendarasa/jobs/:id`} component={AdminAsaUpdateJobRequest} /> */}
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/store/area`} component={AdminStoreArea} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/store/contractors`} component={AdminStoreContractors} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/contractor/cost`} component={AdminContractorCost} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/contractor/cost/:id`} component={AdminManageContractorCost} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/download/:type`} component={AdminDownloadJob} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/additional-products`} component={AdditionalProductsPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/admin/additional-products/errors`} component={AdditionalProductsErrorsPage} />

            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/benefit`} component={BenefitPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/benefit/:id/edit`} component={BenefitEditPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/benefit/create`} component={BenefitEditPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/sub-contractors`} component={SubContractorPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/sub-contractors/:id/edit`} component={SubContractorEditPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/approve`} component={AdminApproveContractor} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/approve/upload`} component={AdminUploadContractorGrade} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/typeofwork`} component={TypeOfWork} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/typeofwork/add`} component={TypeOfWorkForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/typeofwork/:id/edit`} component={TypeOfWorkForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/sku`} component={ManageSku} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/sku/uploade`} component={UploadeSku} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/sku/add`} component={SkuPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/sku/:id/edit`} component={SkuPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/material`} component={ManageMaterial} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/material/upload`} component={UploadMaterial} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/material/add`} component={MaterialPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/material/:id/edit`} component={MaterialPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/channel`} component={ManageChannel} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/channel/add`} component={ChannelPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/channel/:id/edit`} component={ChannelPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/store`} component={AdminStoreManage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/store/add`} component={AdminStoreAddForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/store/:id/edit`} component={AdminStoreEditForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs`} component={AdminSearchJobV2} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id`} component={SuperAdminUpdateJobRequest} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id/quotation`} component={AdminQuotation} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id/quotation/:retuenid`} component={AdminQuotation} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id/invoice`} component={AdminInvoiceForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id/po`} component={AdminPoForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id/guarantee`} component={AdminGuaranteeForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id/contractorReceipt`} component={AdminContractorReceiptForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id/claim`} component={ClaimForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id/creditProvider`} component={CreditProviderForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id/creditSale`} component={AdminCreditSaleForm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/jobs/:id/joExpense`} component={JoExpense} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/userInfo`} component={AdminSearchUser} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/contractors/:id/taxaddress`} component={ContractorTaxAddressV2} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/export`} component={AdminExport} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/download/contractor`} component={AdminDownloadContractor} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/download/:type`} component={AdminDownloadJob} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/download/:type/:storeid/:channel`} component={AdminDownloadJob} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/uploade/:type`} component={AdminUploade} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/ordersummary`} component={OrderSummary} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/ordersummarySCB`} component={OrderSummarySCB} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/training/:id/register`} component={ManageTrainingRegister} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/training`} component={ManageTrainingCourse} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/mainCrouse`} component={ManageMainCourse} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/createSubCourse`} component={CreateSubCourse} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/createSubCourse/:mainCourseId`} component={CreateSubCourse} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/subCourse/:id`} component={CreateSubCourse} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/createSub`} component={ManageMainCourse} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/training/pin`} component={PinConfirm} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/trainingOld`} component={ManageMainCourseOld} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/qoc-broadcasts`} component={QocBroadcastListPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/qoc-broadcasts/add`} component={ManageQocBroadcastPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/qoc-broadcasts/:id/edit`} component={ManageQocBroadcastPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/broadcasts`} component={BroadcastListPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/broadcasts/add`} component={ManageBroadcastPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/broadcasts/:id/edit`} component={ManageBroadcastPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/superadmin/setupCalendar`} component={SetupCalendar} />
            {/* qchang */}

            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/product/`} component={ManageProduct} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/product/upload`} component={UploadProduct} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/product/add`} component={ProductPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/product/:id/edit`} component={ProductPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/productgroup/`} component={ManageProductGroup} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/productgroup/add`} component={ProductGroupPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/productgroup/:id/edit`} component={ProductGroupPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/review/add`} component={AddReview} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/solution/`} component={ManageSolution} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/solution/add`} component={SolutionPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/solution/:id/edit`} component={SolutionPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/coupon/`} component={ManageCoupon} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/coupon/add`} component={CouponPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/coupon/:id/edit`} component={CouponPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/coupongroup/`} component={ManageCouponGroup} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/coupongroup/add`} component={CouponGroupPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/coupongroup/:id/edit`} component={CouponGroupPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/set-banner`} component={SetBannerPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/project/`} component={ManageProject} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/project/add`} component={ProjectPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/project/:id/edit`} component={ProjectPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/project/config`} component={ProjectConfigPage} />

            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/warranty`} component={WarrantyListPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/warranty/add`} component={WarrantyCreatePage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/warranty/:id/edit`} component={WarrantyEditPage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/qchang/warranty/:id`} component={WarrantyViewPage} />

            <AdminAuthRoute exact path={`${config.publicUrl}/employee`} component={ManageEmployeePage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/employee/add`} component={EmployeePage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/employee/:id/edit`} component={EmployeePage} />
            <AdminAuthRoute exact path={`${config.publicUrl}/paymentSummary`} component={PaymentSummaryPage} />

            <AdminAuthRoute exact path={`${config.publicUrl}/buaccount/jobTracking`} component={JobsTracking} />

            {/* Admin Exports */}
            {/* <Route exact path={`${config.publicUrl}/export/contractor`} component={ExportContractor} /> */}
            {/* <Route exact path={`${config.publicUrl}/export/contractor/vacation`} component={ExportContractorVacation} /> */}
            {/* <Route exact path={`${config.publicUrl}/export/job`} component={ExportJob} /> */}

            {/* Home Owner */}
            <Route exact path={`${config.publicUrl}/homeowner/job/:jobId/acceptance`} component={JobAcceptance} />
            <Route exact path={`${config.publicUrl}/homeowner/job/:jobId/result`} component={JobAcceptanceResult} />

            {/* NPS */}
            <Route exact path={`${config.publicUrl}/nps/:jobId`} component={NpsPage} />
            <Route exact path={`${config.publicUrl}/nps/:jobId/thankyou`} component={NpsThankyouPage} />

            {/* clear */}
            <ClearLocalStorage exact path={`${config.publicUrl}/clear`} />

            {/* old Route */}
            <RedirectRoute exact path={`${config.publicUrl}/calendar/admin`} to={`${config.publicUrl}/admin/calendar`} />
            <RedirectRoute exact path={`${config.publicUrl}/calendar/admin/storearea`} to={`${config.publicUrl}/admin/store/area`} />
            <RedirectRoute exact path={`${config.publicUrl}/calendar/admin/jobs/request`} to={`${config.publicUrl}/admin/calendar/jobs`} />
            <RedirectRoute exact path={`${config.publicUrl}/calendar/admin/jobs/:id`} to={`${config.publicUrl}/admin/calendar/jobs/:id`} />
            <RedirectRoute exact path={`${config.publicUrl}/register/contractor/profilepicture`} to={`${config.publicUrl}/contractors/register/profilepicture`} />
            <RedirectRoute exact path={`${config.publicUrl}/register/contractor/pin`} to={`${config.publicUrl}/contractors/register/pin`} />
            <RedirectRoute exact path={`${config.publicUrl}/register/contractor/workarea`} to={`${config.publicUrl}/contractors/register/workarea`} />
            <RedirectRoute exact path={`${config.publicUrl}/register/contractor/location`} to={`${config.publicUrl}/contractors/register/location`} />
            <RedirectRoute exact path={`${config.publicUrl}/register/contractor`} to={`${config.publicUrl}/contractors/register`} />
            <RedirectRoute exact path={`${config.publicUrl}/calendar/contractor/:id/jobs/:jobId`} to={`${config.publicUrl}/contractors/:id/calendar/jobs/:jobId`} />
            <RedirectRoute exact path={`${config.publicUrl}/calendar/contractor/:id/vacation/:vacationId`} to={`${config.publicUrl}/contractors/:id/calendar/vacation/:vacationId`} />
            <RedirectRoute exact path={`${config.publicUrl}/calendar/contractor/:id/vacation`} to={`${config.publicUrl}/contractors/:id/calendar/vacation`} />
            <RedirectRoute exact path={`${config.publicUrl}/calendar/contractor/:id`} to={`${config.publicUrl}/contractors/:id/calendar`} />



            {/* Buddy */}
            <Route exact path={`${config.publicUrl}/buddy/training/pin`} component={PinConfirm} />



          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
